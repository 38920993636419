<template>
  <TransitionRoot appear as="template" :show="open">
    <Dialog
      as="div"
      :static="true"
      class="fixed z-50 inset-0"
      :class="overflow"
      :open="open"
      :unmount="true"
      @keyup.esc.prevent="onClose"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            @click="dialogOverlay ? onClose : false"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="bg-white inline-block align-bottom rounded-lg text-left shadow-xl transform transition-all sm:align-middle"
            :class="[maxWidth, width, minWidth, height]"
          >
            <!-- title -->
            <div
              class="bg-primary border-b border-gray-200 px-4 py-2 sm:px-6 rounded-t-lg flex items-center justify-between select-none"
            >
              <!-- title slot -->
              <DialogTitle
                as="h3"
                class="text-base leading-6 font-medium text-gray-900"
                style="
                  border: none;
                  -ms-user-select: none;
                  -moz-user-select: none;
                  -webkit-user-select: none;
                  -khtml-user-select: none;
                  user-select: none;
                  -webkit-user-drag: none;
                  -khtml-user-drag: none;
                  -moz-user-drag: none;
                  -o-user-drag: none;
                  user-drag: none;
                "
              >
                <slot name="title" />
              </DialogTitle>
              <!-- close button -->
              <div class="flex space-x-4">
                <button
                  v-if="close"
                  type="button"
                  class="rounded-md text-white focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  @click="onClose"
                >
                  <span
                    class="sr-only select-none"
                    style="
                      border: none;
                      -ms-user-select: none;
                      -moz-user-select: none;
                      -webkit-user-select: none;
                      -khtml-user-select: none;
                      user-select: none;
                      -webkit-user-drag: none;
                      -khtml-user-drag: none;
                      -moz-user-drag: none;
                      -o-user-drag: none;
                      user-drag: none;
                    "
                    >{{ t('GModal.닫기') }}</span
                  >
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
            <!-- content -->
            <div>
              <!-- class="bg-white pt-2 mb-2 sm:pt-4 sm:mb-4 px-2 sm:px-4 md:px-6 xl:px-8" -->
              <slot name="content" />
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue';
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';

export default {
  name: 'GModal',
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XMarkIcon,
  },

  props: {
    width: {
      type: String,
      default: 'w-2/3',
    },
    minWidth: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: String,
      default: 'sm:max-w-lg',
    },
    height: {
      type: String,
      default: '',
    },
    changed: {
      type: Boolean,
      default: true,
    },
    close: {
      type: Boolean,
      default: true,
    },
    overflow: {
      type: String,
      default: 'overflow-y-auto',
    },
    dialogOverlay: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['close'],

  setup(props, { emit }) {
    const open = ref(true);
    const { t } = useI18n();

    // const { proxy } = getCurrentInstance();

    const onClose = () => {
      if (props.changed) {
        swal
          .fire({
            title: '저장되지 않은 변경사항이 있음',
            text: '저장되지 않은 변경사항을 삭제하시겠습니까?',
            showCancelButton: true,
          })
          .then(result => {
            if (result.isConfirmed) {
              emit('close');
            }
          });
        return;
      }
      emit('close');
    };

    return {
      t,
      open,
      onClose,
    };
  },
};
</script>
