import { AxiosInstance } from 'axios';

const PREFIX = '/auth';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 사용자 로그인
     * @param {Object} loginData - 로그인에 필요한 데이터
     * @param {number} loginData.chDivId - 지역사 코드
     * @param {string} loginData.pwd - 패스워드
     * @param {string} loginData.userId - ID
     * @returns Object - { accessToken }
     */
    login: async (params: Object) => {
      return $axios
        .post(`${PREFIX}/login`, params)
        .then(response => response.data);
    },

    /**
     * 사용자 로그아웃
     */
    logout: async () => {
      return $axios.post(`${PREFIX}/logout`).then(response => response.data);
    },
  };
};
