<template>
  <GModal
    :changed="false"
    :dialog-overlay="false"
    min-width="min-w-[calc(100vw-710px)]"
    max-width="sm:max-w-full"
    height="h-[calc(100vh-64px)]"
    :width="width"
    @close="onClose"
  >
    <!-- title -->
    <template #title>
      <span class="text-white font-D2CodingBold">{{ setTitle }}</span>
    </template>

    <!-- 내용 -->
    <template #content>
      <div class="p-5">
        <CuesheetHistoryList
          :history-list="cuesheetHistoryList"
          height="h-[calc(100vh-250px)]"
        />

        <div class="space-x-3 flex justify-end pt-4">
          <GButton type="secondary" @click="onClose">취소</GButton>
        </div>
      </div>
    </template>
  </GModal>
</template>

<script setup>
import { defineProps, defineEmits, computed, onMounted, ref } from 'vue';
import { GButton, GModal } from '@/components/ui';
import { useMarsLApi } from '@/apis/mars-l-api';
import CuesheetHistoryList from '@/components/cueSheet/CuesheetHistoryList.vue';

const props = defineProps({
  width: {
    type: String,
    default: '',
  },
  cuesheetId: {
    type: [String, Number],
    default: '',
  },
  cuesheetName: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['close']);

const marsLApi = useMarsLApi();

const cuesheetHistoryList = ref([]);

const setTitle = computed(() => {
  return `큐시트 이력 조회 - ${props.cuesheetName}`;
});

const onClose = () => {
  emit('close');
};

const getCuesheetHistory = async () => {
  const response = await marsLApi.cueSheet.basic.finOneHistory(
    props.cuesheetId,
  );
  if (response.success) {
    cuesheetHistoryList.value = response.data.map(item => {
      return {
        ...item,
        historyMessage: parsingToJSONData(item.cueSheetHistoryData)
          ? parsingToJSONData(item.cueSheetHistoryData).message
          : '',
        historyTitle: parsingToJSONData(item.cueSheetHistoryData)
          ? parsingToJSONData(item.cueSheetHistoryData).itemTitle ||
            parsingToJSONData(item.cueSheetHistoryData).message
          : '',
      };
    });
  }
};

const parsingToJSONData = data => {
  try {
    return JSON.parse(data);
  } catch (error) {
    return data;
  }
};

onMounted(() => {
  getCuesheetHistory();
});
</script>

<style scoped></style>
