import { AxiosInstance } from 'axios';

const PREFIX = '/symbol';

const headers = {
  'Content-Type': 'multipart/form-data',
};

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 아이콘 조회
     * @param  params
     * @returns
     */
    findAll: async (params: any) => {
      return $axios
        .get(`${PREFIX}`, { params })
        .then(response => response.data);
    },

    /**
     * 아이콘 상세조회
     * @param {number} symbolId
     */
    findOne: async (symbolId: number) => {
      return $axios
        .get(`${PREFIX}/${symbolId}`)
        .then(response => response.data);
    },

    /**
     * 아이콘 생성
     * @param params
     * @returns
     */
    create: async (params: any) => {
      return $axios
        .post(`${PREFIX}/upload-symbol`, params, { headers })
        .then(response => response.data);
    },

    /**
     * 아이콘 수정
     * @param symbolId
     * @param params
     * @returns
     */
    update: async (symbolId: Number | String, params: any) => {
      return $axios
        .put(`${PREFIX}/${symbolId}/info`, params)
        .then(response => response.data);
    },

    /**
     * 업로드 파일 수정
     * @param symbolId
     * @param params
     * @returns
     */
    updateFile: async (symbolId: Number | String, params: any) => {
      return $axios
        .post(`${PREFIX}/${symbolId}/file`, params, { headers })
        .then(response => response.data);
    },

    /**
     * 아이콘 삭제
     * @param symbolId
     * @returns
     */
    delete: async (symbolId: String | Number) => {
      return $axios
        .delete(`${PREFIX}/${symbolId}`)
        .then(response => response.data);
    },

    /**
     * 아이콘 순서 편집
     * @param symbolId
     * @param params
     * @returns
     */
    order: async (symbolId: String | Number, params: any) => {
      return $axios
        .put(`${PREFIX}/${symbolId}/order-number`, params)
        .then(response => response.data);
    },
  };
};
