import { AxiosInstance } from 'axios';

const PREFIX = '/category';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 카테고리 목록 조회
     * @param {String} cateNm - 카테고리 이름
     */
    findAll: async (params: any) => {
      return $axios
        .get(`${PREFIX}`, { params })
        .then(response => response.data);
    },

    /**
     * 카테고리 상세 조회
     * @param {Number} categoryId - 카테고리 ID
     */
    findOne: async (categoryId: number) => {
      return $axios
        .get(`${PREFIX}/${categoryId}`)
        .then(response => response.data);
    },

    /**
     * 카테고리 생성
     * @param {Object} options - 생성에 필요한 값
     * @param {String} options.cateNm - 카테고리 이름
     */
    create: async (params: any) => {
      return $axios.post(`${PREFIX}`, params).then(response => response.data);
    },

    /**
     * 카테고리 수정
     * @param {Number} categoryId - 카테고리 ID
     * @param {Object} options - 수정에 필요한 값
     * @param {String} options.cateNm - 카테고리 이름
     * @param {String} options.useYn - 사용여부
     */
    update: async (categoryId: number, params: any) => {
      return $axios
        .put(`${PREFIX}/${categoryId}`, params)
        .then(response => response.data);
    },

    /**
     * 카테고리 삭제
     * @param {Number} categoryId - 카테고리 ID
     */
    delete: async (categoryId: number) => {
      return $axios
        .delete(`${PREFIX}/${categoryId}`)
        .then(response => response.data);
    },

    /**
     * 카테고리 순서 변경
     * @param {Number} categoryId - 카테고리 ID
     * @param {Object} options - Request body
     * @param {Number} options.cateOrd - 변경할 순서
     */
    order: async (categoryId: number, params: any) => {
      return $axios
        .put(`${PREFIX}/${categoryId}/order-number`, params)
        .then(response => response.data);
    },
  };
};
