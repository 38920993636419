import { STATE } from './state';
import { GETTER } from './getter';
import { ACTIONS } from './action';
import { MUTATION } from './mutation';

export const MODULE_NAME = 'app';

export default {
  namespaced: true,
  state: STATE,
  getters: GETTER,
  actions: ACTIONS,
  mutations: MUTATION,
};
