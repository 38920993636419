import * as Sentry from '@sentry/vue';
// import { EventHint } from '@sentry/vue';
import { useUserInfo } from '@/hooks/use-user-info';
import { AxiosError, sendAxiosErrorMessageToSlack } from '@/classes/AxiosError';
import axios from 'axios';

/**
 * api호출 에러시 Sentry 설정
 * @param error 커스텀 에러
 */
export const useAxiosErrorLog = (error: AxiosError) => {
  if (error.response) {
    const {
      config,
      response: { data, status },
    } = error;

    // 이슈 하위 문서 내용에 정보 추가
    Sentry.setContext('Api Response Detail', {
      status,
      data,
    });
    // 커스텀 태그 형성
    Sentry.withScope(async scope => {
      scope.setTag('type', 'api');
      scope.setTag(
        'userName',
        `${useUserInfo().userName}(${useUserInfo().userId})`,
      );
      scope.setTag(
        'chDivId',
        useUserInfo().companyId ? `${useUserInfo().companyId}` : 'no value',
      );
      scope.setTag('api-status', status || 'no value');
      scope.setTag(
        'api-params',
        config.params ? JSON.stringify(config.params) : 'no value',
      );
      scope.setTag('api-data', data ? JSON.stringify(data) : 'no value');

      // 이슈를 그룹화 시켜줌
      scope.setFingerprint([
        config.method as string,
        String(status),
        config.url as string,
      ]);

      // 커스텀 에러 클래스 인스턴스
      const ApiErrorInstance = new AxiosError(error);

      // axios 에러 로그 슬랙에 전송
      await sendAxiosErrorMessageToSlack(error);

      // 센트리로 오류 전송
      Sentry.captureException(ApiErrorInstance, {
        level: 'error',
        extra: {
          header: error.config.headers || 'no value',
          params: config.params || 'no value',
          request: error.request || 'no value',
          response: error.response?.data || 'no value',
          type: 'network',
        },
      });
    });
  } else {
    const { method, url, params, data, headers } = error.config;

    // 이슈 하위 문서 내용에 정보 추가
    Sentry.setContext('Api Request Detail', {
      message: '네크워크 요청은 갔으나 응답이 오지 않음',
      method,
      url,
      params,
      data,
      headers,
    });

    // 커스텀 태그 형성
    Sentry.withScope(async scope => {
      scope.setTag('type', 'api');
      scope.setTag('api-method', method || 'no value');
      scope.setTag('api-url', url || 'no value');
      scope.setTag('api-params', params ? JSON.stringify(params) : 'no value');
      scope.setTag('api-data', data ? JSON.stringify(data) : 'no value');
      scope.setTag(
        'api-headers',
        headers ? JSON.stringify(headers) : 'no value',
      );

      // 이슈를 그룹화 시켜줌
      scope.setFingerprint([method as string, url as string]);

      // axios 에러 로그 슬랙에 전송
      await sendAxiosErrorMessageToSlack(error);

      // 센트리로 오류 전송
      Sentry.captureException(new Error('API Not Found Error'));
    });
  }
};

/**
 * 클라이언트 에러 설정
 * @param event
 * @param hint
 * @returns
 */
export const useClientErrorLog = async (event: any, hint: any) => {
  const error = hint.originalException;

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    // 'Content-Type': 'application/json',
  };

  const payload = {
    attachments: [
      {
        color: 'danger',
        pretext: `<https://gemiso-br.sentry.io/issues/?referrer=sidebar&statsPeriod=14d|Sentry Issues>`,
        text: error.name,
        fields: [
          {
            title: error.message,
            value: error.stack,
            short: false,
          },
        ],
        // ts: dayjs().format('YYYY-MM-DD HH:mm'),
      },
    ],
  };

  await axios({
    method: 'POST',
    url: process.env.VUE_APP_SENTRY_WEB_HOOK_URL,
    headers: headers,
    data: JSON.stringify(payload), // event.message 등
  });

  return event;
};
