
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import { Stomp } from '@stomp/stompjs';
import { RabbitMQ } from '@/enums';
import { useUserInfo } from '@/hooks/use-user-info';
import { captureException } from '@sentry/vue';
import axios from 'axios';

interface TakerPlayEventItem {
  cueItemId: number;
  status: string;
}

interface EventMessage {
  event: string;
  cueId: number;
  cueVer?: number;
  cueOderVer?: number;
  artclId?: number;
  lckDtm?: Date;
  lckrId?: string;
  lckrNm?: string;
  userId?: string;
  userNm?: string;
  body?: TakerPlayEventItem[];
}

export default defineComponent({
  setup() {
    const { chMqCd } = useUserInfo();
    // const destination = process.env.VUE_APP_WEBSOCKET_DEST;
    const url = process.env.VUE_APP_WEBSOCKET_URL;
    const rabbitMqId = process.env.VUE_APP_WEBSOCKET_ID;
    const rabbitMqPw = process.env.VUE_APP_WEBSOCKET_PASSWORD;
    let client = Stomp.client(url as string);
    client.heartbeat.outgoing = 20000;
    client.heartbeat.incoming = 0;
    client.reconnect_delay = 5000;
    const isConnected = ref(false);
    let userDisconnect = false;
    // client.debug = () => {};

    const onConnect = () => {
      isConnected.value = client.connected;
      console.log('connected', isConnected.value, client.connected);

      // TO-BE
      RabbitMQ.getSubscribes(chMqCd).forEach((v: any) => {
        console.log('getSubscribe :: ', v);

        client.subscribe(`${v.url}`, message => {
          const eventMessage: EventMessage = JSON.parse(message.body);
          console.log('eventMessage', eventMessage);
          // window.EventBus.emit(v.eventBus, eventMessage);

          window.EventBus.emit(
            RabbitMQ.getMqEventBusEmitName(v.exchange, eventMessage.event),
            eventMessage,
          );
        });
      });
    };

    const onError = (error: any) => {
      console.log('error', error.body);
    };

    const connect = async () => {
      console.log('connect!!!');
      try {
        client.disconnect();
      } catch (error) {
        console.error('Fail to disconnect in connect.');
        // sentry 전송
        captureException(error);

        // slack 전송
        const headers = {
          'Content-Type': 'application/x-www-form-urlencoded',
          // 'Content-Type': 'application/json',
        };

        const payload = {
          attachments: [
            {
              color: 'danger',
              pretext: `<https://gemiso-br.sentry.io/issues/?referrer=sidebar&statsPeriod=14d|Sentry Issues>`,
              text: `웹소켓 연결 끊김`,
              fields: [
                {
                  // title: error.message,
                  value: error,
                  short: false,
                },
              ],
            },
          ],
        };

        await axios({
          method: 'POST',
          url: process.env.VUE_APP_SENTRY_WEB_HOOK_URL,
          headers: headers,
          data: JSON.stringify(payload), // event.message 등
        });
      }
      client = Stomp.client(url as string);

      client.connect(
        rabbitMqId,
        rabbitMqPw,
        onConnect,
        onError,
        onDisconnect,
        '/',
      );
    };

    const onDisconnect = () => {
      isConnected.value = client.connected;
      console.log('disconnected', isConnected.value, client.connected);

      if (userDisconnect) {
        userDisconnect = false;
        return;
      }

      // window.Notify.info({
      //   message: '웹소켓 연결이 끊어졌습니다.',
      //   duration: 2000,
      //   icon: '<svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"></path></svg>',
      // });

      setTimeout(() => {
        connect();
      }, 5000);
    };

    const disconnect = () => {
      userDisconnect = true;
      if (client.connected) {
        console.log('disconnect!!!');
        client.disconnect();
      }
    };

    onMounted(() => {
      connect();
    });

    onUnmounted(() => {
      disconnect();
    });
    return { isConnected, client, connect, disconnect };
  },
});
