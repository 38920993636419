import * as yup from 'yup';

yup.setLocale({
  mixed: {
    default: '사용할 수 없는 값입니다.',
    required: '필수 입력입니다.',
    oneOf: '다음 값 중 하나여야 합니다.: ${values}',
    notOneOf: '다음 값 중 하나가 아니어야 합니다.: ${values}',
    notType: _ref => {
      const { path, type } = _ref;

      let msg = '';
      if (type === 'number') {
        msg = '숫자만 입력해주세요.';
      } else if (type === 'date') {
        msg = '날짜 형식으로 입력해주세요.';
      } else if (type === 'string') {
        msg = '필수 입력입니다.';
      } else {
        msg = `${path} 항목은 ${type} 형식으로 입력해주세요.`;
      }

      return msg;
    },
    defined: '정의되지 않았습니다.',
  },
  string: {
    length: '${length}자로 입력해주세요.',
    min: '${min}자 이상 입력해주세요.',
    max: '${max}자 까지 입력됩니다.',
    email: '이메일 형식이 아닙니다.',
    // required: '필수 입력입니다.',
  },
  number: {
    min: '최소 ${min}까지 입력해주세요.',
    max: '최대 ${max}까지 입력해주세요.',
  },
});

export default yup;
