import { AxiosInstance } from 'axios';

const PREFIX = '/my-info';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 나의 정보 권한 코드 목록 조회
     */
    findAllCode: async () => {
      return $axios.get(`${PREFIX}/authority`).then(response => response.data);
    },
  };
};
