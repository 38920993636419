<template>
  <GSingleSelect
    v-model="model"
    name="reporter"
    :item-label="itemLabel ? t(`${itemLabelName}`) : ''"
    :options="options"
    track-by="userId"
    label="userNm"
    :allow-empty="false"
    :searchable="false"
    :preselect-first="allOption"
    :placeholder="t('UserSelect.기자')"
  />
</template>

<script>
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useModelWrapper } from '@/hooks/useModelWrapper';
import { GSingleSelect } from '@/components/ui';

export default {
  name: 'UserSelect',
  components: {
    GSingleSelect,
  },
  props: {
    allOption: {
      type: Boolean,
      default: false,
    },
    itemLabel: {
      type: Boolean,
      default: false,
    },
    itemLabelName: {
      type: String,
      default: '기자',
    },
  },
  emits: ['select'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const model = useModelWrapper(props, emit, 'modelValue');
    let options = store.getters['user/findAllUsers'];

    if (props.allOption) {
      options.unshift({
        id: '',
        userId: '',
        userNm: t('UserSelect.기자_전체'),
      });
    }

    return { t, model, options };
  },
};
</script>

<style scoped></style>
