
import { defineComponent } from 'vue';
import { useField } from 'vee-validate';

export default defineComponent({
  name: 'GRadioButton',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tabIndex: {
      type: Number,
      default: 0,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.value,
    });

    const onChange = (value: any) => {
      props.options.forEach((item: any) => {
        if (item.value === value) {
          item.checked = true;
          inputValue.value = value;
        } else {
          item.checked = false;
        }
      });

      emit('update:modelValue', value);
    };

    return {
      onChange,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    };
  },
});
