<template>
  <div v-if="!isShowDetail">
    <div class="flex space-x-2 py-2">
      <RangeDatePickerEx
        v-model="dates"
        v-tooltip="`${dates[0]} ~ ${dates[1]}`"
        width="w-56"
      />

      <div class="flex space-x-2">
        <GInput
          v-model="search"
          :placeholder="t('CueSheetSearch.검색어')"
          width="w-44"
          @keydown.enter="onSearchCueSheet"
        />
        <GButton
          v-tooltip="t('CueSheetSearch.검색')"
          icon="magnifying-glass"
          @click="onSearchCueSheet"
        />
      </div>
    </div>
    <div>
      <CueSheetSearchList
        :cuesheet-search-list="getCurrentPageList"
        :pagination="paginationInfo"
        :is-loading="isLoading"
        @on-row-db-clicked="goCueSheetDetail"
        @on-changed-page="onChangePage"
      />
    </div>
  </div>
  <CueSheetSearchDetail
    v-if="isShowDetail"
    :cuesheet-id="cuesheetId"
    :table-group="tableGroup"
    :mode="mode"
    :selected-row-index="selectedRowIndex"
    @clicked-back-cuesheet-search="onBackCueSearchDetail"
    @is-finished-drag="endDragging"
    @on-row-db-clicked="onRowDbClicked"
  />
</template>

<script>
import { defineComponent, ref, onMounted, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import RangeDatePickerEx from '@/components/common/RangeDatePickerEx';
import { GInput, GButton } from '@/components/ui';
import CueSheetSearchList from '@/components/cueSheet/CueSheetSearchList.vue';
import CueSheetSearchDetail from '@/components/cueSheet/CueSheetSearchDetail.vue';
import dayjs from 'dayjs';
import { useMarsLApi } from '@/apis/mars-l-api';
import { useUserInfo } from '@/hooks/use-user-info';
import { defaultDate } from '@/utils/format.ts';
import { PageConst } from '@/enums';

export default defineComponent({
  name: 'CueSheetSearch',
  components: {
    RangeDatePickerEx,
    GInput,
    GButton,
    CueSheetSearchList,
    CueSheetSearchDetail,
  },
  props: {
    tableGroup: {
      type: Object,
      default: () => {
        return null;
      },
    },
    //편집모드
    mode: {
      type: String,
      default: () => {
        return '';
      },
    },
    selectedRowIndex: {
      type: Number,
    },
  },
  emits: ['is-finished-drag', 'on-row-db-clicked'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const dates = ref([
      defaultDate(dayjs().subtract(6, 'day')),
      defaultDate(dayjs().add(1, 'day')),
    ]);
    const search = ref('');
    const marsLApi = useMarsLApi();
    const { companyId } = useUserInfo();
    const isLoading = ref(false);
    const isShowDetail = ref(false);
    const cuesheetId = ref();
    const paginationInfo = ref(null);
    const page = ref(1);

    const cuesheetSearchList = ref([]);

    const getCueSheets = async () => {
      try {
        isLoading.value = true;
        const params = {
          sdate: dates.value[0],
          edate: dates.value[1],
          chDivIds: companyId,
          searchWord: search.value,
        };

        const response = await marsLApi.cueSheet.basic.findAll(params);

        cuesheetSearchList.value = response.data.map((item, idx) => {
          return {
            ...item,
            index: idx + 1,
          };
        });

        paginationInfo.value = {
          isShow: true,
          page: page.value,
          totalPage: getTotalPages,
          totalCount: cuesheetSearchList.value.length,
        };
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    // 큐시트 목록 총 페이지 수
    const getTotalPages = computed(() => {
      return Math.max(
        Math.ceil(cuesheetSearchList.value.length / PageConst.size),
        1,
      );
    });

    // 현재 페이지의 아이템들만 필터링
    const getCurrentPageList = computed(() => {
      const start = (page.value - 1) * PageConst.size;
      const end = start + PageConst.size;
      return cuesheetSearchList.value.slice(start, end);
    });

    // 페이지 변경
    const onChangePage = changePage => {
      page.value = changePage;
      paginationInfo.value.page = changePage;
    };

    const onSearchCueSheet = () => {
      getCueSheets();
    };

    // 큐시트 상세로 이동
    const goCueSheetDetail = row => {
      cuesheetId.value = row.id; // 큐시트 아이디
      isShowDetail.value = true;
    };

    // 큐시트 상세에서 "<" 버튼 눌렀을 경우
    const onBackCueSearchDetail = async () => {
      isShowDetail.value = false;
      await getCueSheets();
    };

    const init = async () => {
      await getCueSheets();
    };

    // 드래그 끝나는 시점
    const endDragging = params => {
      console.log('endd', params.event.oldIndex);
      emit('is-finished-drag', params);
    };

    const onRowDbClicked = row => {
      emit('on-row-db-clicked', row);
    };

    watch(
      () => [dates.value],
      async () => {
        await getCueSheets();
      },
    );

    onMounted(() => {
      init();
    });

    return {
      t,
      dates,
      cuesheetSearchList,
      getCueSheets,
      isLoading,
      search,
      isShowDetail,
      goCueSheetDetail,
      cuesheetId,
      onBackCueSearchDetail,
      endDragging,
      onSearchCueSheet,
      onRowDbClicked,
      paginationInfo,
      onChangePage,
      getCurrentPageList,
    };
  },
});
</script>

<style scoped></style>
