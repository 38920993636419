<template>
  <div class="space-y-1">
    <span v-if="itemLabel" class="text-xs inline-block">{{ itemLabel }}</span>
    <VueMultiselect
      v-model="tags"
      :multiple="true"
      :taggable="true"
      :track-by="TRACKBY"
      :label="LABEL"
      :options="tagList"
      :placeholder="disabled || readonly ? '' : placeholder"
      :loading="isLoading"
      :disabled="disabled || readonly"
      :allow-empty="allowEmpty"
      :select-label="selectLabel"
      :selected-label="allowEmpty ? selectedLabel : ''"
      :deselect-label="allowEmpty ? selectedLabel : deselectLabel"
      :searchable="searchable"
      :close-on-select="closeOnSelect"
      :tagPlaceholder="tagPlaceholder"
      @tag="addTag"
    >
      <template #noOptions>{{
        i18n.global.t('GTagSelect.데이터가_존재하지_않습니다')
      }}</template>
    </VueMultiselect>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import VueMultiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';
import i18n from '@/i18n';
import { useMarsLApi } from '@/apis/mars-l-api';
import { useModelWrapper } from '@/hooks/useModelWrapper';
// import { sweetAlert } from '@/hooks/use-sweet-alert';

const LABEL = 'tagName';
const TRACKBY = 'id';

export default defineComponent({
  name: 'GTagSelect',
  components: {
    VueMultiselect,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => {
        return [];
      },
    },
    name: {
      type: String,
      required: true,
    },
    itemLabel: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    selectLabel: {
      type: String,
      default: i18n.global.t('GTagSelect.선택'),
    },
    selectedLabel: {
      type: String,
      default: i18n.global.t('GTagSelect.취소'),
    },
    deselectLabel: {
      type: String,
      default: '', // 선택되어 있는 아이템에 hover하면 나오는 값
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    errors: {
      type: String,
      default: '',
    },
    closeOnSelect: {
      type: Boolean,
      default: false,
    },
    tagPlaceholder: {
      type: String,
      default: i18n.global.t('GTagSelect.추가'),
    },
  },
  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const marsLApi = useMarsLApi();
    // const { swalError } = sweetAlert();

    const isLoading = ref(false);
    const tagList = ref([]);
    const tags = useModelWrapper(props, emit, 'modelValue');

    // 태그 목록 조회
    const getTags = async () => {
      isLoading.value = true;

      try {
        const params = {
          delYn: 'N',
        };
        const response = await marsLApi.tag.basic.findAll(params);

        if (response.success) {
          tagList.value = response.data.tagDetailList;
        }
      } catch (error) {
        console.error('error', error);
      } finally {
        isLoading.value = false;
      }
    };

    // 태그 추가
    const addTag = value => {
      const tag = {
        [LABEL]: value,
      };
      tags.value.push(tag);
      emit('update:modelValue', tags.value);
    };

    onMounted(() => {
      // disabled일 경우 목록 조회 X
      if (!props.disabled) {
        getTags();
      }
    });

    return {
      isLoading,
      LABEL,
      TRACKBY,
      tagList,
      tags,
      addTag,
      i18n,
      // errorCls,
    };
  },
});
</script>
<style scoped>
.multiselect {
  min-height: 34px;
}
.multiselect:deep(.multiselect__tags) {
  min-height: 34px;
  font-size: 0.75rem;
  padding: 6px 40px 0 8px;
}
.multiselect:deep(.multiselect__option),
.multiselect:deep(.multiselect__input) {
  font-size: 0.75rem;
}
.multiselect:deep(.multiselect__placeholder) {
  margin-bottom: 2px;
}
.multiselect:deep(.multiselect__tag) {
  margin-bottom: 0;
}
.multiselect:deep(.multiselect__select) {
  height: 34px;
}

.multiselect:deep(.multiselect__spinner) {
  height: 31px;
}

.multiselect--disabled {
  opacity: 1;
  border-radius: 0.375rem;
  background-color: rgb(229 231 235);
  /* border: 1px solid rgb(209 213 219); */
}
.multiselect--disabled:deep(.multiselect__select) {
  display: none;
}
.multiselect--disabled:deep(.multiselect__tags),
.multiselect--disabled:deep(.multiselect__single) {
  background-color: rgb(229 231 235);
}
.multiselect--disabled:deep(.multiselect__spinner) {
  background: none;
}
.multiselect--disabled:deep(.multiselect__tag-icon) {
  display: none;
}
.multiselect--disabled:deep(.multiselect__tag) {
  padding: 4px 12px 4px 10px;
}
</style>
