import { Code, EnumEntry } from './base';

class coverageCode extends EnumEntry<Code> {
  // 취재 종류
  coverageClassReport: Code = {
    id: '001',
    name: '리포트',
    label: '리포트',
  };

  coverageClassStraight: Code = {
    id: '002',
    name: '스트레이트',
    label: '스트레이트',
  };

  coverageClassRequest: Code = {
    id: '003',
    name: '취재의뢰',
    label: '취재의뢰',
  };

  // 취재 형식
  coverageTypeSketch: Code = {
    id: '001',
    name: '스케치',
    label: '스케치',
  };

  coverageTypeInterview: Code = {
    id: '002',
    name: '인터뷰 or 녹취',
    label: '인터뷰 or 녹취',
  };

  coverageTypeStandUp: Code = {
    id: '003',
    name: 'Stand up',
    label: 'Stand up',
  };

  coverageTypeEtc: Code = {
    id: '004',
    name: '기타',
    label: '기타',
  };

  // 동행 여부
  withY: Code = {
    id: '001',
    name: '동행',
    label: '동행',
  };

  withJoin: Code = {
    id: '002',
    name: '현장 합류',
    label: '현장 합류',
  };

  withN: Code = {
    id: '003',
    name: '동행 안함',
    label: '동행 안함',
  };

  // 취재 일시 타입
  dateTypeStart: Code = {
    id: '001',
    name: '출발',
    label: '출발',
  };

  dateTypeTo: Code = {
    id: '002',
    name: '까지',
    label: '까지',
  };

  // 출장 타입
  businessTripTypedDomestic: Code = {
    id: '001',
    name: '국내 출장',
    label: '국내 출장',
  };

  businessTripTypeAbroad: Code = {
    id: '002',
    name: '해외 출장',
    label: '해외 출장',
  };

  values: Array<Code> = [
    this.coverageClassReport,
    this.coverageClassStraight,
    this.coverageClassRequest,
    this.coverageTypeSketch,
    this.coverageTypeInterview,
    this.coverageTypeStandUp,
    this.coverageTypeEtc,
    this.withY,
    this.withJoin,
    this.withN,
    this.dateTypeStart,
    this.dateTypeTo,
    this.businessTripTypedDomestic,
    this.businessTripTypeAbroad,
  ];
}

export default new coverageCode();
