import {
  RouterPath,
  parentRouterPath,
  parentRouterName,
  RouterName,
  RouterIcon,
} from '@/types/router';
import NotFound from '@/views/common/NotFound.vue';
import { adminRoutes } from './admin-routes';

export const appRoutes = [
  {
    path: '/login',
    name: RouterName.Login,
    component: () => import('@/views/Login.vue'),
    meta: {
      requiresAuth: false,
      layout: 'LoginLayout',
    },
  },
  {
    path: '/',
    redirect: `/${parentRouterPath.Article}/${RouterPath.MyArticle}`,
    meta: {
      requiresAuth: true,
      layout: 'AppLayout',
    },
  },
  // 기사작성 하위 메뉴 START
  {
    path: `/${parentRouterPath.Article}/${RouterPath.MyArticle}`,
    name: RouterName.CreateMyArticle,
    component: () => import('@/views/article/MyArticle.vue'),
    meta: {
      parent: parentRouterName.Article,
      layout: 'AppLayout',
      requiresAuth: true,
      menu: {
        icon: RouterIcon.MyArticle,
      },
      isShowCreateArticleModal: true,
    },
  },
  {
    path: `/${parentRouterPath.Article}/${RouterPath.MyArticle}`,
    name: RouterName.MyArticle,
    component: () => import('@/views/article/MyArticle.vue'),
    meta: {
      parent: parentRouterName.Article,
      layout: 'AppLayout',
      requiresAuth: true,
      menu: {
        default: true,
        icon: RouterIcon.MyArticle,
      },
      isShowCreateArticleModal: false,
    },
  },

  // {
  //   path: `/${parentRouterPath.Article}/${RouterPath.TemporaryArticle}`,
  //   name: RouterName.TemporaryArticle,
  //   component: () => import('@/views/article/TemporaryArticle.vue'),
  //   meta: {
  //     parent: parentRouterName.Article,
  //     layout: 'AppLayout',
  //     requiresAuth: true,
  //     menu: {
  //       icon: RouterIcon.TemporaryArticle,
  //     },
  //   },
  // },
  // 기사작성 하위 메뉴 END
  // 취재 데스크 하위 메뉴 START
  {
    path: `/${parentRouterPath.CoverDesk}/${RouterPath.MyRegionArticle}`,
    name: RouterName.MyRegionArticle,
    component: () => import('@/views/coverDesk/MyRegionArticle.vue'),
    meta: {
      parent: parentRouterName.CoverDesk,
      layout: 'AppLayout',
      requiresAuth: true,
      menu: {
        default: true,
        icon: RouterIcon.MyRegionArticle,
      },
    },
  },
  {
    path: `/${parentRouterPath.CoverDesk}/${RouterPath.AreaArticle}`,
    name: RouterName.AreaArticle,
    component: () => import('@/views/coverDesk/AreaArticle.vue'),
    meta: {
      parent: parentRouterName.CoverDesk,
      layout: 'AppLayout',
      requiresAuth: true,
      menu: {
        icon: RouterIcon.AreaArticle,
      },
    },
  },
  {
    path: `/${parentRouterPath.CoverDesk}/${RouterPath.SeoulMBCArticle}`,
    name: RouterName.SeoulMBCArticle,
    component: () => import('@/views/coverDesk/SeoulMBCArticle.vue'),
    meta: {
      parent: parentRouterName.CoverDesk,
      layout: 'AppLayout',
      requiresAuth: true,
      menu: {
        icon: RouterIcon.SeoulMBCArticle,
      },
    },
  },
  {
    path: `/${parentRouterPath.CoverDesk}/${RouterPath.CreateArticle}`,
    name: RouterName.CreateArticle,
    component: () => import('@/views/article/CreateArticle.vue'),
    meta: {
      parent: parentRouterName.CoverDesk,
      layout: 'AppLayout',
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: `/${parentRouterPath.CoverDesk}/${RouterPath.EditArticle}`,
    name: RouterName.EditArticle,
    component: () => import('@/views/article/EditArticle.vue'),
    meta: {
      parent: parentRouterName.CoverDesk,
      layout: 'AppLayout',
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: `/${parentRouterPath.CoverDesk}/${RouterPath.ViewArticle}`,
    name: RouterName.ViewArticle,
    component: () => import('@/views/article/ViewArticle.vue'),
    meta: {
      parent: parentRouterName.CoverDesk,
      layout: 'AppLayout',
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: `/${parentRouterPath.CoverDesk}/${RouterPath.BottomRoll}`,
    name: RouterName.BottomRoll,
    component: () => import('@/views/cueSheet/BottomRoll.vue'),
    // component: () => import('@/views/common/Develop.vue'),
    meta: {
      parent: parentRouterName.CoverDesk,
      layout: 'AppLayout',
      requiresAuth: true,
      menu: {
        icon: RouterIcon.BottomRoll,
      },
    },
  },
  // 취재 데스크 하위 메뉴 END
  // 큐시트 하위 메뉴 START
  {
    path: `/${parentRouterPath.CueSheets}/${RouterPath.CueSheet}`,
    name: RouterName.CueSheet,
    component: () => import('@/views/cueSheet/CueSheet.vue'),
    meta: {
      parent: parentRouterName.CueSheets,
      layout: 'AppLayout',
      requiresAuth: true,
      menu: {
        default: true,
        icon: RouterIcon.CueSheet,
      },
    },
  },
  {
    path: `/${parentRouterPath.CueSheets}/${RouterPath.ShowCueSheetDetail}`,
    name: RouterName.ShowCueSheetDetail,
    component: () => import('@/views/cueSheet/CueSheetDetail.vue'),
    meta: {
      parent: parentRouterName.CueSheets,
      requiresAuth: true,
      layout: 'AppLayout',
    },
    props: true,
  },

  {
    path: `/${parentRouterPath.CueSheets}/${RouterPath.AreaCueSheet}`,
    name: RouterName.AreaCueSheet,
    component: () => import('@/views/cueSheet/AreaCueSheet.vue'),
    meta: {
      parent: parentRouterName.CueSheets,
      layout: 'AppLayout',
      requiresAuth: true,
      menu: {
        icon: RouterIcon.AreaCueSheet,
      },
    },
  },
  {
    path: `/${parentRouterPath.CueSheets}/${RouterPath.ShowAreaCueSheetDetail}`,
    name: RouterName.ShowAreaCueSheetDetail,
    component: () => import('@/views/cueSheet/AreaCueSheetDetail.vue'),
    meta: {
      parent: parentRouterName.CueSheets,
      requiresAuth: true,
      layout: 'AppLayout',
    },
    props: true,
  },
  {
    path: `/${parentRouterPath.CueSheets}/${RouterPath.SeoulMBCCueSheet}`,
    name: RouterName.SeoulMBCCueSheet,
    component: () => import('@/views/cueSheet/SeoulMBCCuehseet.vue'),
    meta: {
      parent: parentRouterName.CueSheets,
      layout: 'AppLayout',
      requiresAuth: true,
      menu: {
        icon: RouterIcon.AreaCueSheet,
      },
    },
  },
  {
    path: `/${parentRouterPath.CueSheets}/${RouterPath.ShowSeoulMBCCueSheetDetail}`,
    name: RouterName.ShowSeoulMBCCueSheetDetail,
    component: () => import('@/views/cueSheet/SeoulMBCCuehseetDetail.vue'),
    // component: () => import('@/views/common/Develop.vue'),
    meta: {
      parent: parentRouterName.CueSheets,
      requiresAuth: true,
      layout: 'AppLayout',
    },
    props: true,
  },

  {
    path: `/${parentRouterPath.CueSheets}/${RouterPath.CueSheetTemplate}`,
    name: RouterName.CueSheetTemplate,
    component: () => import('@/views/cueSheet/CueSheetTemplate.vue'),
    meta: {
      parent: parentRouterName.CueSheets,
      layout: 'AppLayout',
      requiresAuth: true,
      menu: {
        icon: RouterIcon.CueSheetTemplate,
      },
    },
  },
  {
    path: `/${parentRouterPath.CueSheets}/${RouterPath.ShowCueSheetTemplateDetail}`,
    name: RouterName.ShowCueSheetTemplateDetail,
    component: () => import('@/views/cueSheet/CueSheetTemplateDetail.vue'),
    // component: () => import('@/views/common/Develop.vue'),
    meta: {
      parent: parentRouterName.CueSheets,
      requiresAuth: true,
      layout: 'AppLayout',
    },
    props: true,
  },
  // 큐시트 하위 메뉴 END
  // 게시판 하위 메뉴 START
  {
    path: `/${parentRouterPath.Board}/${RouterPath.Notice}`,
    name: RouterName.Notice,
    component: () => import('@/views/board/Notice.vue'),
    // component: () => import('@/views/common/Develop.vue'),
    meta: {
      parent: parentRouterName.Board,
      layout: 'AppLayout',
      requiresAuth: true,
      menu: {
        default: true,
        icon: RouterIcon.Board,
      },
    },
  },
  {
    path: `/${parentRouterPath.Board}/${RouterPath.ShowNoticeDetail}`,
    name: RouterName.ShowNoticeDetail,
    component: () => import('@/views/board/NoticeDetail.vue'),
    meta: {
      parent: parentRouterName.Board,
      requiresAuth: true,
      layout: 'AppLayout',
    },
    props: true,
  },
  {
    path: `/${parentRouterPath.Board}/${RouterPath.EditNoticeDetail}`,
    name: RouterName.EditNoticeDetail,
    component: () => import('@/views/board/EditNotice.vue'),
    meta: {
      parent: parentRouterName.Board,
      requiresAuth: true,
      layout: 'AppLayout',
    },
    props: true,
  },
  {
    path: `/${parentRouterPath.Board}/${RouterPath.Board}`,
    name: RouterName.Board,
    component: () => import('@/views/board/Board.vue'),
    // component: () => import('@/views/common/Develop.vue'),
    meta: {
      parent: parentRouterName.Board,
      layout: 'AppLayout',
      requiresAuth: true,
      menu: {
        icon: RouterIcon.Board,
      },
    },
  },
  {
    path: `/${parentRouterPath.Board}/${RouterPath.ShowBoardDetail}`,
    name: RouterName.ShowBoardDetail,
    component: () => import('@/views/board/BoardDetail.vue'),
    meta: {
      parent: parentRouterName.Board,
      requiresAuth: true,
      layout: 'AppLayout',
    },
    props: true,
  },
  {
    path: `/${parentRouterPath.Board}/${RouterPath.EditBoardDetail}`,
    name: RouterName.EditBoardDetail,
    component: () => import('@/views/board/EditBoard.vue'),
    meta: {
      parent: parentRouterName.Board,
      requiresAuth: true,
      layout: 'AppLayout',
    },
    props: true,
  },
  {
    path: `/${parentRouterPath.Board}/${RouterPath.Journalist}`,
    name: RouterName.Journalist,
    component: () => import('@/views/board/Journalist.vue'),
    // component: () => import('@/views/common/Develop.vue'),
    meta: {
      parent: parentRouterName.Board,
      layout: 'AppLayout',
      requiresAuth: true,
      menu: {
        icon: RouterIcon.Board,
      },
    },
  },
  {
    path: `/${parentRouterPath.Board}/${RouterPath.ShowJournalistDetail}`,
    name: RouterName.ShowJournalistDetail,
    component: () => import('@/views/board/JournalistDetail.vue'),
    meta: {
      parent: parentRouterName.Board,
      requiresAuth: true,
      layout: 'AppLayout',
    },
    props: true,
  },
  {
    path: `/${parentRouterPath.Board}/${RouterPath.EditJournalistDetail}`,
    name: RouterName.EditJournalistDetail,
    component: () => import('@/views/board/EditJournalist.vue'),
    meta: {
      parent: parentRouterName.Board,
      requiresAuth: true,
      layout: 'AppLayout',
    },
    props: true,
  },
  // 게시판 하위 메뉴 END
  // 정보공유 하위 메뉴 START
  {
    path: `/${parentRouterPath.ShareInfo}/${RouterPath.NewsSource}`,
    name: RouterName.NewsSource,
    component: () => import('@/views/shareInformation/NewsSource.vue'),
    meta: {
      parent: parentRouterName.ShareInfo,
      layout: 'AppLayout',
      requiresAuth: true,
      menu: {
        default: true,
      },
    },
  },
  {
    path: `/${parentRouterPath.ShareInfo}/${RouterPath.RequestCover}`,
    name: RouterName.RequestCover,
    component: () => import('@/views/shareInformation/RequestCover.vue'),
    meta: {
      parent: parentRouterName.ShareInfo,
      layout: 'AppLayout',
      requiresAuth: true,
      menu: {
        // default: true,
        icon: RouterIcon.RequestCover,
      },
    },
  },
  {
    path: `/${parentRouterPath.ShareInfo}/${RouterPath.ExternalLink}`,
    name: RouterName.ExternalLink,
    component: () => import('@/views/shareInformation/ExternalLink.vue'),
    meta: {
      parent: parentRouterName.ShareInfo,
      layout: 'AppLayout',
      requiresAuth: true,
      menu: {
        icon: RouterIcon.Schedule,
      },
    },
  },
  {
    path: `/${parentRouterPath.ShareInfo}/${RouterPath.Monitoring}`,
    name: RouterName.Monitoring,
    component: () => import('@/views/coverDesk/Monitoring.vue'),
    // component: () => import('@/views/common/Develop.vue'),
    meta: {
      parent: parentRouterName.ShareInfo,
      layout: 'AppLayout',
      requiresAuth: true,
      menu: {
        icon: RouterIcon.SeoulMBCArticle,
      },
    },
  },

  {
    path: `/${parentRouterPath.ShareInfo}/${RouterPath.Schedule}`,
    name: RouterName.Schedule,
    component: () => import('@/views/shareInformation/Schedule.vue'),
    meta: {
      parent: parentRouterName.ShareInfo,
      layout: 'AppLayout',
      requiresAuth: true,
      menu: {
        icon: RouterIcon.Schedule,
      },
    },
  },
  // 정보공유 하위 메뉴 END
  // 제보 관리 하위 메뉴 START
  {
    path: `/${RouterPath.ContactUs}`,
    name: RouterName.ContactUsManagement,
    component: () => import('@/views/contactUs/ContactUsManagement.vue'),
    meta: {
      layout: 'AppLayout',
      requiresAuth: true,
      menu: {
        default: true,
        icon: RouterIcon.ContactUs,
      },
    },
  },
  // 제보 관리 하위 메뉴 END
  // 관리자 메뉴 START
  {
    path: '/admin',
    name: RouterName.Admin,
    redirect: '/admin/user',
    component: () => import('@/views/admin/Admin.vue'),
    meta: {
      layout: 'AdminLayout',
      requiresAuth: true,
      menu: { target: '_blank' },
    },
    children: adminRoutes,
  },
  // 관리자 메뉴 END
  {
    path: '/:pathMatch(.*)*',
    // eslint-disable-next-line
    component: NotFound,
    meta: {
      layout: 'LoginLayout',
    },
  },
];
