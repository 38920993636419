<template>
  <div class="border rounded-md bg-white overflow-auto">
    <GTable
      :list="seoulCuesheetList"
      :draggable="false"
      :is-loading="isLoading"
      :pagination="pagination"
      :multiselect="false"
      :height="height"
      :no-data-message="'데이터가 없습니다.'"
      @on-row-clicked="onRowClicked"
      @on-row-db-clicked="onRowDbClicked"
      @on-changed-page="onChangedPage"
    >
      <template #header>
        <th class="p-3">NO</th>
        <th class="p-2">구분</th>
        <th class="p-2">방송상태</th>
        <th class="p-2">방송일시</th>
        <th class="p-2">뉴스구분</th>
        <th class="p-2">등록자</th>
        <th class="p-2">등록일시</th>
        <th class="p-2">PD1</th>
        <th class="p-2">PD2</th>
        <th class="p-2">앵커1</th>
        <th class="p-2">앵커2</th>
        <th class="p-2">비고</th>
        <th class="p-2">편성분량</th>
      </template>

      <template #list="{ row: row }">
        <td class="px-3">{{ row?.order }}</td>
        <td class="p-2">{{ row.newsDivNm }}</td>
        <td class="p-2">
          <GTaskChip
            :text="row.brdcStNm"
            :background-color="setBackgroundColor(row.brdcStCd)"
          />
        </td>
        <td class="p-2">{{ defaultDate(row.brdcDt) }}</td>
        <td class="p-2">{{ row.brdcPgmNm }}</td>
        <td class="p-2">{{ row.inputrNm }}</td>
        <td class="p-2">
          {{ defaultDateTimeHm(row.inputDtm) }}
        </td>
        <td class="p-2">{{ row.pd1Nm }}</td>
        <td class="p-2">{{ row.pd2Nm }}</td>
        <td class="p-2">{{ row.anc1Nm }}</td>
        <td class="p-2">{{ row.anc2Nm }}</td>
        <td class="p-2">{{ row.rmk }}</td>
        <!-- TODO: 서울 데이터 확인 후 편성 분량 수정 -->
        <td class="p-2">{{ row.편성분량 }}</td>
      </template>
    </GTable>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { GTable, GTaskChip } from '@/components/ui';
import { defaultDate, defaultDateTimeHm } from '@/utils/format';

export default defineComponent({
  name: 'SeoulMBCCuesheetList',
  components: {
    GTable,
    GTaskChip,
  },
  props: {
    seoulCuesheetList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    pagination: {
      type: Object,
      default: () => {
        return null;
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: 'h-[calc(100vh-213px)]',
    },
  },
  emits: ['on-row-clicked', 'on-row-db-clicked', 'on-changed-page'],
  setup(props, { emit }) {
    const onRowClicked = rowData => {
      emit('on-row-clicked', rowData?.row);
    };

    const onRowDbClicked = rowData => {
      emit('on-row-db-clicked', rowData?.row);
    };
    // 페이지 변경
    const onChangedPage = changePage => {
      emit('on-changed-page', changePage);
    };

    const setBackgroundColor = brdcStCd => {
      let color = '';

      switch (brdcStCd) {
        case `01`:
          color = 'bg-primary';
          break;
        case `02`:
          color = 'bg-blue-400';
          break;
        case `03`:
          color = 'bg-green-400';
          break;
        case `04`:
          color = 'bg-gray-400';
          break;
      }
      return color;
    };

    return {
      defaultDate,
      defaultDateTimeHm,
      setBackgroundColor,
      onRowClicked,
      onRowDbClicked,
      onChangedPage,
    };
  },
});
</script>

<style scoped></style>
