<template>
  <GModal
    :changed="false"
    :dialog-overlay="false"
    @close="onClose"
    max-width="w-[36%]"
    height="h-[580px]"
  >
    <!-- title -->
    <template #title>
      <span class="text-white font-D2CodingBold">
        {{ t('Modal.Article.SpecialCharModal.특수_기호') }}
      </span>
    </template>

    <!-- 내용 -->
    <template #content>
      <div class="h-[540px]">
        <div class="flex flex-col h-full p-2">
          <!-- 탭 -->
          <div class="w-full flex flex-wrap gap-1 text-sm">
            <div
              v-for="tab in tabs"
              :key="tab.id"
              class="border rounded-lg p-2 px-4 mb-0.5 cursor-pointer hover:text-black hover:bg-primary-bright shadow"
              :class="
                tab.id === selectedTab.id
                  ? 'bg-primary-bright text-black font-D2CodingBold'
                  : ''
              "
              @click="onClickedTab(tab)"
            >
              {{ tab.name }}
            </div>
          </div>

          <!-- 탭 내용 -->
          <div
            class="min-h-[255px] max-h-[50%] mt-2 overflow-y-auto grid grid-cols-10 grid-rows-[min-content] scrollbar border border-slate-300"
          >
            <div
              v-for="(char, index) in selectedTab.chars"
              :key="index"
              class="h-12 text-lg border border-gray-200 p-2 text-center cursor-pointer hover:bg-gray-200"
              :class="[
                !char ? 'invisible' : '',
                char === selectedChar ? 'bg-gray-200' : '',
              ]"
              @click="onClickedChar(char)"
            >
              <span>{{ char }}</span>
            </div>
          </div>
          <!-- 미리보기 -->
          <div class="mt-2">
            <!--            <div class="text-sm">-->
            <!--              {{ t('Modal.Article.SpecialCharModal.미리보기') }}-->
            <!--            </div>-->
            <div
              class="flex items-center justify-center w-48 h-32 rounded-md shadow bg-gray-100 mx-auto"
            >
              <div class="text-6xl">{{ selectedChar }}</div>
            </div>
          </div>

          <!-- 버튼 -->
          <div class="space-x-2 flex justify-end mt-2 pr-2">
            <GButton type="secondary" @click="onClose">{{
              t('Modal.Article.SpecialCharModal.취소')
            }}</GButton>
            <GButton @click="onSubmit">{{
              t('Modal.Article.SpecialCharModal.선택')
            }}</GButton>
          </div>
        </div>
      </div>
    </template>
  </GModal>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import { GModal, GButton } from '@/components/ui';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'SpecialCharModal',
  components: {
    GModal,
    GButton,
  },
  props: {},
  emits: ['close', 'selected-char'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const tabs = ref([
      {
        id: 1,
        name: t('Modal.Article.SpecialCharModal.기본부호'),
        chars: [
          /* 2*/
          '@',
          '。',
          '·',
          '‥',
          '…',
          '¨',
          '〃',
          '―',
          '∥',
          '＼',
          /* 3*/
          '∼',
          '‘',
          '’',
          '“',
          '”',
          '〔',
          '〕',
          '〈',
          '〉',
          '《',
          /* 4*/
          '》',
          '「',
          '」',
          '『',
          '』',
          '【',
          '】',
          '±',
          '×',
          '÷',
          /* 5*/
          '≠',
          '≤',
          '≥',
          '∞',
          '∴',
          '°',
          '′',
          '″',
          '℃',
          'Å',
          /* 6*/
          '￠',
          '￡',
          '￥',
          '♂',
          '♀',
          '∠',
          '⊥',
          '⌒',
          '∂',
          '∇',
          /* 7*/
          '≡',
          '≒',
          '§',
          '※',
          '☆',
          '★',
          '○',
          '●',
          '◎',
          '◇',
          /* 8*/
          '◆',
          '□',
          '■',
          '△',
          '▲',
          '▽',
          '▼',
          '→',
          '←',
          '↑',
          /* 9*/
          '↓',
          '↔',
          '〓',
          '≪',
          '≫',
          '√',
          '∽',
          '∝',
          '∵',
          '∫',
          /*10*/
          '∬',
          '∈',
          '∋',
          '⊆',
          '⊇',
          '⊂',
          '⊃',
          '∪',
          '∩',
          '∧',
          /*11*/
          '∨',
          '￢',
        ],
      },
      {
        id: 2,
        name: t('Modal.Article.SpecialCharModal.약물부호'),
        chars: [
          /*12*/
          '⇒',
          '⇔',
          '∀',
          '∃',
          '´',
          '～',
          'ˇ',
          '˘',
          '˝',
          '˚',
          /*13*/
          '˙',
          '¸',
          '˛',
          '¡',
          '¿',
          'ː',
          '∮',
          '∑',
          '∏',
          '¤',
          /*14*/
          '℉',
          '‰',
          '◁',
          '◀',
          '▷',
          '▶',
          '♤',
          '♠',
          '♡',
          '♥',
          /*15*/
          '♧',
          '♣',
          '⊙',
          '◈',
          '▣',
          '◐',
          '◑',
          '▒',
          '▤',
          '▥',
          /*16*/
          '▨',
          '▧',
          '▦',
          '▩',
          '♨',
          '☏',
          '☎',
          '☜',
          '☞',
          '¶',
          /*17*/
          '†',
          '‡',
          '↕',
          '↗',
          '↙',
          '↖',
          '↘',
          '♭',
          '♩',
          '♪',
          /*18*/
          '♬',
          '㉿',
          '㈜',
          '№',
          '㏇',
          '™',
          '㏂',
          '㏘',
          '℡',
        ],
      },
      {
        id: 3,
        name: t('Modal.Article.SpecialCharModal.단위부호'),
        chars: [
          /*19*/
          '㎕',
          '㎖',
          '㎗',
          'ℓ',
          '㎘',
          '㏄',
          '㎣',
          '㎤',
          '㎥',
          '㎦',
          /*20*/
          '㎙',
          '㎚',
          '㎛',
          '㎜',
          '㎝',
          '㎞',
          '㎟',
          '㎠',
          '㎡',
          '㎢',
          /*21*/
          '㏊',
          '㎍',
          '㎎',
          '㎏',
          '㏏',
          '㎈',
          '㎉',
          '㏈',
          '㎧',
          '㎨',
          /*22*/
          '㎰',
          '㎱',
          '㎲',
          '㎳',
          '㎴',
          '㎵',
          '㎶',
          '㎷',
          '㎸',
          '㎹',
          /*23*/
          '㎀',
          '㎁',
          '㎂',
          '㎃',
          '㎄',
          '㎺',
          '㎻',
          '㎼',
          '㎽',
          '㎾',
          /*24*/
          '㎿',
          '㎐',
          '㎑',
          '㎒',
          '㎓',
          '㎔',
          'Ω',
          '㏀',
          '㏁',
          '㎊',
          /*25*/
          '㎋',
          '㎌',
          '㏖',
          '㏅',
          '㎭',
          '㎮',
          '㎯',
          '㏛',
          '㎩',
          '㎪',
          /*26*/
          '㎫',
          '㎬',
          '㏝',
          '㏐',
          '㏓',
          '㏃',
          '㏉',
          '㏜',
          '㏆',
        ],
      },
      {
        id: 4,
        name: t('Modal.Article.SpecialCharModal.원형부호'),
        chars: [
          /*27*/
          'Æ',
          'Ð',
          'ª',
          'Ħ',
          '?',
          'Ĳ',
          'Ŀ',
          'Ł',
          'Ø',
          /*28*/
          'Œ',
          'º',
          'Þ',
          'Ŧ',
          'Ŋ',
          '㉠',
          '㉡',
          '㉢',
          '㉣',
          /*29*/
          '㉤',
          '㉥',
          '㉦',
          '㉧',
          '㉨',
          '㉩',
          '㉪',
          '㉫',
          '㉬',
          '㉭',
          /*30*/
          '㉮',
          '㉯',
          '㉰',
          '㉱',
          '㉲',
          '㉳',
          '㉴',
          '㉵',
          '㉶',
          '㉷',
          /*31*/
          '㉸',
          '㉹',
          '㉺',
          '㉻',
          'ⓐ',
          'ⓑ',
          'ⓒ',
          'ⓓ',
          'ⓔ',
          'ⓕ',
          /*32*/
          'ⓖ',
          'ⓗ',
          'ⓘ',
          'ⓙ',
          'ⓚ',
          'ⓛ',
          'ⓜ',
          'ⓝ',
          'ⓞ',
          'ⓟ',
          /*33*/
          'ⓠ',
          'ⓡ',
          'ⓢ',
          'ⓣ',
          'ⓤ',
          'ⓥ',
          'ⓦ',
          'ⓧ',
          'ⓨ',
          'ⓩ',
          /*34*/
          '①',
          '②',
          '③',
          '④',
          '⑤',
          '⑥',
          '⑦',
          '⑧',
          '⑨',
          '⑩',
          /*35*/
          '⑪',
          '⑫',
          '⑬',
          '⑭',
          '⑮',
          '½',
          '⅓',
          '⅔',
          '¼',
          '¾',
          /*36*/
          '⅛',
          '⅜',
          '⅝',
          '⅞',
        ],
      },
      {
        id: 5,
        name: t('Modal.Article.SpecialCharModal.그리스로마문자'),
        chars: [
          /*37*/
          'ⅰ',
          'ⅱ',
          'ⅲ',
          'ⅳ',
          'ⅴ',
          'ⅵ',
          'ⅶ',
          'ⅷ',
          'ⅸ',
          'ⅹ',
          /*38*/
          'Ⅰ',
          'Ⅱ',
          'Ⅲ',
          'Ⅳ',
          'Ⅴ',
          'Ⅵ',
          'Ⅶ',
          'Ⅷ',
          'Ⅸ',
          'Ⅹ',
          /*39*/
          'Α',
          'Β',
          'Γ',
          'Δ',
          'Ε',
          'Ζ',
          'Η',
          'Θ',
          'Ι',
          'Κ',
          /*40*/
          'Λ',
          'Μ',
          'Ν',
          'Ξ',
          'Ο',
          'Π',
          'Ρ',
          'Σ',
          'Τ',
          'Υ',
          /*41*/
          'Φ',
          'Χ',
          'Ψ',
          'Ω',
          'α',
          'β',
          'γ',
          'δ',
          'ε',
          'ζ',
          /*42*/
          'η',
          'θ',
          'ι',
          'κ',
          'λ',
          'μ',
          'ν',
          'ξ',
          'ο',
          'π',
          /*43*/
          'ρ',
          'σ',
          'τ',
          'υ',
          'φ',
          'χ',
          'ψ',
          'ω',
        ],
      },
      {
        id: 6,
        name: t('Modal.Article.SpecialCharModal.자주쓰는_특수문자'),
        chars: [
          /*44*/
          '韓',
          '中',
          '日',
          '美',
          '北',
          '故',
          '前',
          '與',
          '野',
          '‥',
          /*44*/
          '…',
          '⋯',
        ],
      },
    ]);
    const selectedTab = ref(tabs.value[0]);
    const selectedChar = ref(null);

    // 특수기호 탭 클릭
    const onClickedTab = tab => {
      selectedTab.value = tab;
    };

    // 특수문자 선택
    const onClickedChar = char => {
      if (selectedChar.value === char) {
        emit('selected-char', selectedChar.value);
        return;
      }
      selectedChar.value = char;
    };

    // 선택한 특수문자 보내기
    const onSubmit = () => {
      emit('selected-char', selectedChar.value);
      onClose();
    };

    const onCharDBClick = () => {
      emit('selected-char', selectedChar.value);
    };

    // 모달 닫기
    const onClose = () => {
      emit('close');
    };

    // 초기화
    const initData = () => {};

    onMounted(() => {
      initData();
    });

    return {
      t,
      tabs,
      selectedTab,
      selectedChar,
      onSubmit,
      onClickedTab,
      onClickedChar,
      onCharDBClick,
      onClose,
    };
  },
});
</script>
