<template>
  <div class="flex space-x-3 items-center justify-end pr-1.5">
    <div class="flex items-center space-x-1">
      <!-- 전체 갯수 -->
      <span class="sm:text-xs text-xs font-D2CodingBold mr-4"
        >Total: {{ toComma(pagination?.totalCount) }}</span
      >

      <!-- 첫 페이지 -->
      <div v-tooltip="'첫페이지'" class="flex items-center">
        <GIconButton
          :class="'bg-gray-300 py-2 px-2'"
          icon="chevron-double-left"
          :icon-size="4"
          :disabled="pagination?.page === 1"
          @click="onChangePage('firstPage')"
        />
      </div>
      <!-- 이전 버튼 -->
      <div class="flex items-center">
        <GIconButton
          :class="'bg-gray-300 py-2 px-2'"
          icon="chevron-left"
          :disabled="pagination?.page === 1 || pagination?.page > totalPage + 1"
          @click="onChangePage('prevPage')"
        />
      </div>
      <!-- Input 현재 페이지 -->
      <input
        v-model="currentPage"
        class="text-center w-12 h-9 border border-gray-200 shadow-sm focus:ring-primary focus:border-primary block sm:text-xs rounded-md px-2 focus:outline-none"
        type="number"
        name="current_page"
        :min="1"
        :max="totalPage"
        @keydown.enter.prevent="onChangeInputPage"
        @blur="onBlurInputPage"
      />
      <!-- 다음 버튼 -->
      <div class="flex items-center">
        <GIconButton
          :class="'bg-gray-300 py-2 px-2'"
          icon="chevron-right"
          :disabled="pagination?.page >= totalPage"
          @click="onChangePage('nextPage')"
        />
      </div>
      <!-- 마지막 페이지 -->
      <div v-tooltip="'마지막페이지'" class="flex items-center">
        <GIconButton
          :class="'bg-gray-300 py-2 px-2'"
          icon="chevron-double-right"
          :icon-size="4"
          :disabled="pagination?.page === totalPage"
          @click="onChangePage('lastPage')"
        />
      </div>
      <div class="sm:text-xs pl-2 text-gray-500">
        / {{ toComma(totalPage) }}
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { GIconButton } from '../ui';
import { toComma } from '@/utils/format';

export default defineComponent({
  name: 'PaginationView',
  components: {
    GIconButton,
  },
  props: {
    pagination: {
      type: Object,
      default: () => {
        return {
          page: 1,
          totalPage: 1,
          totalCount: 0,
        };
      },
    },
  },
  emits: ['on-changed-page'],
  setup(props, { emit }) {
    const currentPage = ref(props.pagination?.page);
    const totalPage = ref(
      props.pagination?.totalPage ? props.pagination?.totalPage : 1,
    );

    const onChangePage = changeType => {
      switch (changeType) {
        case 'firstPage':
          currentPage.value = 1;
          break;
        case 'prevPage':
          currentPage.value = currentPage.value - 1;
          break;
        case 'nextPage':
          currentPage.value = currentPage.value + 1;
          break;
        case 'lastPage':
          currentPage.value = totalPage.value;
          break;
      }

      emit('on-changed-page', currentPage.value);
    };

    const onChangeInputPage = () => {
      if (currentPage.value <= 0 || currentPage.value > totalPage.value) {
        currentPage.value = props.pagination.page;
      } else if (currentPage.value <= totalPage.value) {
        onChangePage();
      }
    };

    const onBlurInputPage = () => {
      if (
        !currentPage.value ||
        currentPage.value <= 0 ||
        currentPage.value > totalPage.value
      ) {
        currentPage.value = props.pagination.page;
      }
    };

    return {
      currentPage,
      totalPage,
      toComma,
      onChangePage,
      onChangeInputPage,
      onBlurInputPage,
    };
  },
});
</script>

<style scoped>
/* Hide Arrows From Input Number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
