import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './index.css';
import i18n from './i18n';

import { VTooltip } from 'floating-vue';
import 'floating-vue/dist/style.css';

import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Options from './utils/sweetalertOptions';

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import '@/vue-datepicker.css';

import mitt, { Emitter, EventType } from 'mitt';
import Notify from './utils/notify';

import VueCountdown from '@chenfengyuan/vue-countdown';
import * as Sentry from '@sentry/vue';
import { useClientErrorLog } from '@/hooks/use-sentry';

const app = createApp(App);
Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  enabled: process.env.NODE_ENV === 'production', // 운영서버에서만 활성화
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    // new Sentry.Replay(),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: true, // 텍스트 데이터를 마스킹하는 기능 (민감한 정보가 들어있을 경우 보호할 수 있다.)
      blockAllMedia: true, // 미디어 콘텐츠를 차단하는 옵션
    }),
  ],
  attachProps: true, // 로깅을 위해 모든 Vue 구성 요소의 props를 보기
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend: (event, hint) => useClientErrorLog(event, hint), // 에러를 Sentry에게 전달하기 전 처리할 수 있는 hook
});
Sentry.setTag('releaseDate', process.env.VUE_APP_RELEASE_DATE);

declare global {
  // eslint-disable-next-line
  interface Window {
    EventBus: Emitter<Record<EventType, unknown>>;
    Notify: any;
    Cookie: any;
    popStateDetected: boolean;
    navigationBlocked: boolean;
  }
}
window.EventBus = mitt();
window.Notify = Notify;

app
  .use(i18n)
  .use(store)
  .use(router)
  .use(VueSweetalert2, Options)
  .use(Toast)
  .use(require('vue-three-shortkey'))
  .directive('tooltip', VTooltip)
  .component('Datepicker', Datepicker)
  .component(VueCountdown.name, VueCountdown)
  .mount('#app');
