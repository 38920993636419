import { AxiosInstance } from 'axios';

const PREFIX = '/mbc-cueSheet';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 서울 MBC 큐시트 목록 조회
     * @param {Object} option query string
     * @param {string} option.sdate 검색 시작일
     * @param {string} option.edate 검색 종료일
     * @param {number} option.startPosition 페이지
     * @param {number} option.returnCount 데이터 개수
     * @param {string} option.search_word 검색키워드(큐시트명, 방송 프로그램명, PD1명, PD2명에 적용)
     * @param {string} option.brdc_pgm_div_cd 프로그램 구분 코드(01(TV), 02(사전 제작), 03(라디오))
     */
    findAll: async (params: any) => {
      return $axios
        .get(`${PREFIX}`, { params })
        .then(response => response.data);
    },

    /**
     * 서울 MBC 큐시트 상세 조회
     * @param {string} cuesheetId 큐시트 ID
     */
    findOne: async (cuesheetId: string) => {
      return $axios
        .get(`${PREFIX}/${cuesheetId}`)
        .then(response => response.data);
    },
  };
};
