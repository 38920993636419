import {
  flatMapDeep,
  isEmpty,
  isArray,
  max,
  repeat,
  flattenDeep,
  cloneDeep,
  trimEnd,
} from 'lodash';
import { sliceByByte } from '@/utils/str';
import ArticleTypeCode from '@/codes/article-type-code';
import articleDivisionCode from '@/codes/article-division-code';
import { cuesheetItemCode } from '@/codes';
import { secondToMs } from '@/utils/format';

interface CaptionType {
  anchor: string;
  report: string;
}

/**
 * 배열 n개씩 나누기
 * 페이지 수 계산하기위해 사용
 * @param array 큐시트 아이템 리스트
 * @param chunkSize n개씩 잘라낼 사이즈
 * @returns
 */
const splitArrayIntoChunks = (
  array: Array<Object>,
  chunkSize: Number | number,
) => {
  const result = [];
  for (let i = 0; i < array.length; i += Number(chunkSize)) {
    const chunk = array.slice(i, i + Number(chunkSize));
    result.push(chunk);
  }
  return result;
};

/**
 * 길이에 따라 문자열 "..." 처리
 * @param str 문자열
 * @param maxLength 잘라낼 사이즈
 * @returns
 */
const truncateString = (str: String, maxLength: number) => {
  if (str?.length > maxLength) {
    return str.substring(0, maxLength) + '...';
  } else {
    return str;
  }
};

const clampTextByByte = (text: string, maxBytes: any) => {
  let content = text;

  const byteArray = new TextEncoder().encode(content);
  const byteLength = byteArray.length;

  if (byteLength > maxBytes) {
    let truncatedText = '';
    let currentBytes = 0;
    for (const char of content) {
      const charBytes = new TextEncoder().encode(char).length;
      if (currentBytes + charBytes <= maxBytes) {
        truncatedText += char;
        currentBytes += charBytes;
      } else {
        break;
      }
    }
    content = truncatedText + '...';
    return content;
  }

  return content;
};

/**
 * 한자리 숫자 앞에 0 붙이기
 * @param number
 * @returns
 */
const addLeadingZero = (number: number) => {
  return number < 10 ? `0${number}` : number.toString();
};

/**
 * 빈 배열은 "\n"으로 치환하고 배열 평탄화
 * @param arr
 * @returns
 */
const processArray = (arr: Array<Object>) => {
  const flattenedArr: Array<Object> = flatMapDeep(arr, item => {
    if (isEmpty(item)) {
      return '\n';
    } else if (isArray(item)) {
      return processArray(item);
    } else {
      return item;
    }
  });

  return flattenedArr;
};

/**
 * 엥커멘트 기사 내용과 리포트멘트 기사내용 하나의 배열로 합치기
 * @param array1 앵커멘트 내용
 * @param array2 리포트멘트 내용
 * @param articleType 기사 형식
 * @returns
 */
const concatenateArrays = (
  array1: any,
  array2: any,
  articleType: String,
  // chunkSize: number = 33,
) => {
  const combinedArray = [];

  const maxLength = Math.max(array1.length, array2.length);

  for (let i = 0; i < maxLength; i++) {
    /**
     * 2023-07-03 스트레이트나 하단롤도 앵커멘트 작성가능, 인쇄에도 출력할수있게 (성민효 차장님)
     * 2023-07-18 스트레이트, 하단롤은 앵커멘트 노출 x
     */
    if (
      ArticleTypeCode.Report.id === articleType ||
      ArticleTypeCode.CrossTalk.id === articleType
    ) {
      if (i < array1.length) {
        const anchorMent = getInitialArticleTextArray(array1[i]);

        combinedArray.push(...anchorMent.split('\n'));
        // combinedArray.push(`<hr/>`);
      }
    }

    if (i < array2.length) {
      const reportMent = getInitialArticleTextArray(array2[i]);

      combinedArray.push(...reportMent.split('\n'));
    }
  }

  // let totalLine = 0;
  // combinedArray.forEach(text => {
  //   totalLine += sliceByByte(text, 44).length || 1;
  // });

  // 하나의 문자열로 합치기
  // const concatenatedString = combinedArray.join('\n');

  return processArray(combinedArray.map(text => sliceByByte(text, 44)));
};

/**
 *
 * @param anchorArray 앵커자막리스트
 * @param reportArray 리포트자막리스트
 * @param artclTypCd 기사형식
 * @returns
 */
const concatArrays = (
  anchorArray: any,
  reportArray: any,
  artclTypCode: string,
) => {
  const combinedArray = [];

  const maxLength = Math.max(anchorArray.length, reportArray.length);

  for (let i = 0; i < maxLength; i++) {
    if (
      ArticleTypeCode.Report.id === artclTypCode ||
      ArticleTypeCode.CrossTalk.id === artclTypCode
    ) {
      if (i < anchorArray.length) {
        combinedArray.push(anchorArray[i]);
        // combinedArray.push(`<hr/>`);
      }
    }

    if (i < reportArray.length) {
      combinedArray.push(reportArray[i]);
    }
  }

  return combinedArray;
};

/**
 * 기사내용을 바이트기준으로 잘라내기 (라인 수 체크로 사용)
 * @param content 앵커멘트 or 리포트멘트 내용
 * @returns
 */
const getArticleLineCount = (content: string) => {
  let contentArr = content.split('\n');

  return processArray(contentArr.map((text: string) => sliceByByte(text, 44)));
};

/**
 * 캡션의 라인넘버와 기사 리인수를 비교해서 최대 라인 수 구하는 함수
 * @param captions 캡션정보
 * @param aritcleLineCount 기사 라인 수
 * @returns
 */
const getCaptionLineCount = (captions: any, aritcleLineCount: any) => {
  const editorViewLineCount = aritcleLineCount;

  const captionLineLastNumber = max(captions.map((v: any) => v?.lineNo)) ?? 0;

  return Math.ceil(max([editorViewLineCount, captionLineLastNumber]));
};

/**
 * 문자열 속 "\n" 갯수 체크
 * @param str 문자열
 * @returns
 */
const countNewLines = (str: String) => {
  let count = 0;
  let isInNewLine = false;

  for (let i = 0; i < str.length; i++) {
    if (str[i] === '\n') {
      if (!isInNewLine) {
        count++;
        isInNewLine = true;
      }
    } else {
      isInNewLine = false;
    }
  }

  return count;
};

/**
 * 기사 내용 뒤에 개행문자 trim
 * @param articleText
 * @param defaultRows
 * @returns
 */
const getInitialArticleText = (articleText: string, defaultRows: number) => {
  return articleText || repeat('\n', defaultRows - 1);
};

/**
 * 기사 내용 뒤에 개행문자 trim
 * @param articleText
 * @param defaultRows
 * @returns
 */
const getInitialArticleTextArray = (
  articleText: string,
  // defaultRows: number,
) => {
  return articleText;
  // return trimEnd(articleText) || repeat('\n', defaultRows - 1);
};

/**
 * 자막내용 배열로 가공
 * @param data
 * @param chunkSize 잘라낸 배열 사이즈
 * @returns
 */
const processCaptions = (data: any /*chunkSize: number = 33*/) => {
  const result: Array<Object> = [];

  data.forEach((caption: any) => {
    const { captions, articleLineCount } = caption;
    for (let i = 0; i < articleLineCount; i++) {
      let foundMatch = false; // 일치하는 캡션을 찾았는지 여부를 나타내는 플래그 변수
      for (let j = 0; j < captions.length; j++) {
        // captions[j].lineNo - 1 : lieNo 1부터 시작
        if (captions[j].lineNo === i) {
          result.push(captions[j].text);
          foundMatch = true; // 일치하는 캡션을 찾았음을 표시
          break;
        }
        // else {
        //   // result[i] = '\n';
        //   result.push('\n');
        // }
      }
      if (!foundMatch) {
        // 일치하는 캡션이 없을 경우 '\n'을 추가
        result.push('\n');
      }
    }
  });

  return result;
};

/**
 *
 * @param data 자막 데이터
 * @param textArray 기사 내용 배열
 * @param type 자막 타입: anchor or report
 * @returns
 */
const processCaption = (data: any, textArray: any, type: CaptionType) => {
  const resultArray: Array<Object> = [];

  data.forEach((caption: any, index: number) => {
    const combinedArray: Array<string> = []; // 기사 내용
    const result: Array<Object> = [];
    const { captions, articleLineCount } = caption;
    for (let i = 0; i < articleLineCount; i++) {
      let foundMatch = false; // 일치하는 캡션을 찾았는지 여부를 나타내는 플래그 변수
      for (let j = 0; j < captions.length; j++) {
        // captions[j].lineNo - 1 : lieNo 1부터 시작
        if (captions[j].lineNo === i) {
          result.push(captions[j].text);
          foundMatch = true; // 일치하는 캡션을 찾았음을 표시
          break;
        }
        // else {
        //   // result[i] = '\n';
        //   result.push('\n');
        // }
      }
      if (!foundMatch) {
        // 일치하는 캡션이 없을 경우 '\n'을 추가
        result.push('\n');
      }
    }

    combinedArray.push(...textArray[index].split('\n'));

    resultArray.push({
      caption: flattenDeep(splitArrayIntoChunks(result, 35)).join(''),
      captions: flattenDeep(splitArrayIntoChunks(result, 35)),
      content: textArray[index],
      articles: processArray(combinedArray.map(text => sliceByByte(text, 44))),
      type: type,
    });
  });

  return resultArray;
};

/**
 * 기사 압축(+) 프린트 데이터 가공
 * @param captionList 자막 정보
 * @param textList 기사 내용 정보
 * @param type 앵커멘트 or 리포트멘트
 * @returns 가공된 프린트 데이터
 */
const setArticleCompressedPlusData = (
  captionList: any,
  textList: any,
  type: CaptionType,
) => {
  const resultArray: Array<Object> = [];

  const maxLength = Math.max(captionList.length, textList.length);

  // 앵커 + 리포트가 1세트, c/t타입 때문
  for (let i = 0; i < maxLength; i++) {
    const tempCaptionArray: Array<any> = [];
    const captions: Array<Object> = [];
    let maxLineNo = 0;
    /**
     * 개행 문자 기준으로 split 후
     * 작성기 기준 44byte로 한번 더 나누어서 작성기 UI에 보이는대로
     * 한줄씩 배열로 만든다.
     */
    const articles = flattenDeep(
      textList[i].split('\n').map((v: any) => sliceByByte(v, 44)),
    );

    /**
     * @param tempCaptionArray:
     * 한 라인에 입력된 자막 내용에 포함된 \n문자 기준으로 split을 한 후
     * 작성기 자막 ui에 표시되는대로 41바이트로 나누어 배열을 만든다.
     * 한라인에 입력된 자막을 나눈 후에 새롭게 lineNo를 추가해준다.
     *
     * @param maxLineNo:자막의 최대 라인넘버를 구한다.(기사내용의 최대 길이와 비교하기위해 사용)
     */
    captionList[i].forEach((item: any) => {
      const textArr = item.text.split('\n');
      const newTextArr = flattenDeep(
        textArr.map((v: any) => sliceByByte(trimEnd(v), 41)),
      );
      newTextArr.forEach((v: any, index: number) => {
        maxLineNo = item.lineNo + index + 1;
        tempCaptionArray.push({
          text: v,
          lineNo: item.lineNo + index,
        });
      });
    });

    /**
     * 기사의 길이와 자막의 lineNo중 큰 값을 구한다.
     * (기사의 내용보다 자막의 내용이 더 긴 경우가 간혹 있기때문)
     */
    const maxLineLength = Math.max(articles.length, maxLineNo);

    // 자막이 없는 영역은 개행문자를 넣어준다.
    for (let i = 0; i < maxLineLength; i++) {
      let foundMatch = false; // 일치하는 캡션을 찾았는지 여부를 나타내는 플래그 변수
      for (let j = 0; j < tempCaptionArray.length; j++) {
        if (tempCaptionArray[j].lineNo === i) {
          captions.push(tempCaptionArray[j].text);
          foundMatch = true; // 일치하는 캡션을 찾았음을 표시
          break;
        }
      }
      if (!foundMatch) {
        // 일치하는 캡션이 없을 경우 '\n'을 추가
        captions.push('\n');
      }
    }

    resultArray.push({
      articles: articles,
      captions,
      type,
    });
  }

  return resultArray;
};

const initCaptions = (article: any) => {
  let anchorCaptions: any[] = [];
  let reporterCaptions: any[] = [];
  const editorArray = Array.from(
    {
      length: Math.max(
        article.anchorTextArray.length,
        article.reporterTextArray.length,
      ),
    },
    (_, idx) => idx,
  );

  anchorCaptions = editorArray.map(() => []);
  reporterCaptions = editorArray.map(() => []);

  let fromLineNo = 0;
  let endLineNo = 0;

  editorArray.forEach(idx => {
    const anchorTextLineCount = getArticleLineCount(
      article.anchorTextArray[idx] ?? '',
    ).length;

    // fromLineNo = idx === 0 ? 0 : endLineNo + 1;
    fromLineNo = 0;
    endLineNo = fromLineNo + anchorTextLineCount - 1;

    const findAnchorCaptions = article.reporterCaptionArray
      ?.filter(
        (v: any) =>
          fromLineNo <= v.lineNo &&
          v.lineNo <= endLineNo &&
          v.lineOrd === idx &&
          (article.artclTypCd === ArticleTypeCode.Report.id ||
            article.artclTypCd === ArticleTypeCode.CrossTalk.id),
      )
      .map((caption: any) => {
        const _caption = cloneDeep(caption);
        return {
          ..._caption,
          lineNo: _caption.lineNo - fromLineNo,
        };
      });

    if (!isEmpty(findAnchorCaptions)) {
      anchorCaptions[idx] = findAnchorCaptions;
    }

    // 리포터 또는 CT 형식일 경우만 anchorEditor 영역이 존재 하여 endLineNo 증가
    if (
      article.artclTypCd === ArticleTypeCode.Report.id ||
      article.artclTypCd === ArticleTypeCode.CrossTalk.id
    ) {
      endLineNo++;
    }

    const reporterTextLineCount = getArticleLineCount(
      article.reporterTextArray[idx] ?? '',
    ).length;

    fromLineNo = endLineNo;
    endLineNo += reporterTextLineCount;

    const findReporterCaptions = article.reporterCaptionArray
      ?.filter(
        (v: any) =>
          fromLineNo <= v.lineNo && v.lineNo <= endLineNo && v.lineOrd === idx,
      )
      .map((caption: any) => {
        const _caption = cloneDeep(caption);
        return {
          ..._caption,
          lineNo: _caption.lineNo - fromLineNo,
        };
      });

    if (!isEmpty(findReporterCaptions)) {
      reporterCaptions[idx] = findReporterCaptions;
    }
  });

  return {
    ...article,
    totalArticleTime: secondToMs(
      article?.ancMentCttTime + article?.artclCttTime + article?.artclExtTime,
    ),
    anchorCaptionArray: anchorCaptions,
    reporterCaptionArray: reporterCaptions,
  };
};

// 기사 아이템 중 엠바고 기사가 있는지 체크하는 함수
const checkedEmbargo = (list: Array<any>) => {
  return list.some(
    (item: any) => item?.artclDivCd === articleDivisionCode.embargo.id,
  );
};

const getReporterName = (content: any) => {
  let reporter = '';
  if (
    cuesheetItemCode.TEMPLATE.id !== content?.cueItemDivCd ||
    cuesheetItemCode.ITEM.id !== content?.cueItemDivCd
  ) {
    if (content.isSameChannel) {
      reporter = `${content?.rptrNm}`;
      reporter += content?.deptNm ? `(${content?.deptNm})` : '';
    } else {
      reporter = `${content?.orgRptrNm}(${content?.orgChNm})`;
    }
  } else {
    reporter = '';
  }
  return reporter;
};

export {
  splitArrayIntoChunks,
  truncateString,
  addLeadingZero,
  processArray,
  concatenateArrays,
  getCaptionLineCount,
  countNewLines,
  getInitialArticleText,
  getInitialArticleTextArray,
  processCaptions,
  processCaption,
  getArticleLineCount,
  concatArrays,
  clampTextByByte,
  initCaptions,
  checkedEmbargo,
  setArticleCompressedPlusData,
  getReporterName,
};
