<template>
  <GSingleSelect
    v-model="model"
    name="category"
    :item-label="t('ArticleCategorySelect.분야')"
    :options="getCategorySelectOptions"
    track-by="id"
    label="name"
    width="w-full"
    :allow-empty="allowEmpty"
    :searchable="false"
    :placeholder="t('ArticleCategorySelect.분야')"
  />
</template>

<script>
import { onMounted, computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { concat } from 'lodash';
import { useModelWrapper } from '@/hooks/useModelWrapper';
import { GSingleSelect } from '@/components/ui';
import { MODULE_NAME as CATEGORY_MODULE_NAME } from '@/store/modules/category';
import { GETTER_NAMES as CATEGORY_GETTER_NAMES } from '@/store/modules/category/getter';
import { ACTION_NAMES as CATEGORY_ACTION_NAMES } from '@/store/modules/category/action';

export default {
  name: 'ArticleCategorySelect',
  components: {
    GSingleSelect,
  },
  props: {
    companyCode: {
      type: [String, Number],
      required: true,
    },
    allOption: {
      type: Boolean,
      default: false,
    },
    itemLabel: {
      type: Boolean,
      default: false,
    },
    preselectFirst: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['select', 'update:modelValue'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const model = useModelWrapper(props, emit, 'modelValue');
    const isLoading = ref(false);

    /**
     * category select options 정보 반환
     * @returns {*[]}
     */
    const getCategorySelectOptions = computed(() => {
      let options = [];
      if (props.allOption) {
        options.push({
          id: '',
          name: t('ArticleCategorySelect.분야_전체'),
          label: t('ArticleCategorySelect.분야_전체'),
        });
      }

      return concat(
        options,
        store.getters[
          `${CATEGORY_MODULE_NAME}/${CATEGORY_GETTER_NAMES.GET_CATEGORY_CODES}`
        ],
      );
    });

    /**
     * 지역사별 카테고리 목록 조회
     * @param chDivId - 지역사 코드(ID)
     * @returns {Promise<void>}
     */
    const fetchCategories = async chDivId => {
      try {
        isLoading.value = true;
        await store.dispatch(
          `${CATEGORY_MODULE_NAME}/${CATEGORY_ACTION_NAMES.FETCH_CATEGORIES}`,
          {
            chDivId,
          },
        );
      } catch (e) {
        console.error(e);
      } finally {
        isLoading.value = false;
      }
    };

    const onSelectValue = value => {
      emit('select', value);
    };

    onMounted(async () => {
      await fetchCategories(props.companyCode);
    });

    watch(
      () => props.companyCode,
      async newValue => {
        await fetchCategories(newValue);
      },
    );

    return {
      t,
      model,
      getCategorySelectOptions,
      isLoading,
      onSelectValue,
    };
  },
};
</script>

<style scoped></style>
