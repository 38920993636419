import store from '@/store';

export const useUserInfo = () => {
  return {
    info: store.getters['user/getUserInfo'],
    userMngId: store.getters['user/getUserInfo'].id,
    userId: store.getters['user/getUserInfo'].userId,
    userName: store.getters['user/getUserInfo'].userNm,
    chMqCd: store.getters['user/getUserInfo'].chMqCd,
    companyId: store.getters['user/getChDivId'],
    editorType: store.getters['user/getEditorType'],
    authorityCodes: store.getters['auth/getAuthorityCodes'],
    can(permission: string): boolean {
      return store.getters['auth/getAuthorityCodes'].includes(permission);
    },
  };
};
