import { AxiosInstance } from 'axios';

const PREFIX = '/scroll-news';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 하단롤 목록 조회
     * @param params
     * @returns
     */
    findAll: async (params: any) => {
      return $axios
        .get(`${PREFIX}`, { params })
        .then(response => response.data);
    },

    /**
     * 하단롤 저장
     * @param params
     * @returns
     */
    create: async (params: any) => {
      return $axios.post(`${PREFIX}`, params).then(response => response.data);
    },

    /**
     * 하단롤 상세 조회
     * @param scrollNewsId
     * @returns
     */
    findOne: async (scrollNewsId: String | number) => {
      return $axios
        .get(`${PREFIX}/${scrollNewsId}`)
        .then(response => response.data);
    },

    /**
     * 하단롤 수정
     * @param scrollNewsId
     * @returns
     */
    update: async (scrollNewsId: String | number, params: any) => {
      return $axios
        .put(`${PREFIX}/${scrollNewsId}`, params)
        .then(response => response.data);
    },

    /**
     * 하단롤 삭제
     * @param scrollNewsId
     * @returns
     */
    delete: async (scrollNewsId: string | number) => {
      return $axios
        .delete(`${PREFIX}/${scrollNewsId}`)
        .then(response => response.data);
    },

    /**
     * 하단롤 다운로드
     * @param scrollNewsId
     * @returns
     */
    download: async (scrollNewsId: string | number) => {
      return $axios
        .get(`${PREFIX}/${scrollNewsId}/download`)
        .then(response => response.data);
    },
  };
};
