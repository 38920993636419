<template>
  <div class="flex space-x-2">
    <input
      v-model="inputValue"
      :id="value"
      type="radio"
      :name="name"
      :value="value"
      :disabled="disabled"
      :title="title"
      :checked="isChecked"
      @change="onChange"
    />
    <label
      v-if="label"
      class="block text-gray-700"
      :class="[fontSize, disabled ? 'text-gray-400' : 'cursor-pointer']"
      :for="value"
      >{{ label }}</label
    >
    <p v-if="errors" class="mt-2 text-xs text-red-600">
      {{ errors || '' }}
    </p>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';

export default defineComponent({
  name: 'GRadioButton',

  props: {
    modelValue: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    default: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: String,
      default: 'text-xs',
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue', 'change'],

  setup(props, { emit }) {
    const inputValue = ref(props.modelValue);
    const isChecked = computed(() => {
      if (props.default) {
        return true;
      } else if (props.checked) {
        return props.checked;
      }

      return false;
    });

    const onChange = event => {
      emit('change', event, props.name);
      emit('update:modelValue', event.target.value);
    };

    return {
      inputValue,
      isChecked,
      onChange,
    };
  },
});
</script>
