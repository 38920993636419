import { AxiosInstance } from 'axios';

const PREFIX = '/cuesheet-template-item';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 템플릿 아이템 생성
     * @param params
     * @returns
     */
    create: async (params: Object) => {
      return $axios.post(`${PREFIX}`, params).then(response => response.data);
    },

    /**
     * 템플릿 아이템 수정
     * @param templateItemId - 템플릿 아이템 ID
     * @param params - 수정 내용
     */
    update: async (templateItemId: String, params: Object) => {
      return $axios
        .put(`${PREFIX}/${templateItemId}`, params)
        .then(response => response.data);
    },

    /**
     * 템플릿 아이템 삭제
     * @param templateItemId - 템플릿 아이템 ID
     */
    delete: async (templateItemId: String) => {
      return $axios
        .delete(`${PREFIX}/${templateItemId}`)
        .then(response => response.data);
    },

    /**
     * 큐시트 템플릿 아이템 목록 조회
     * @param params
     * @returns
     */
    findAll: async (params: any) => {
      return $axios
        .get(`${PREFIX}`, { params })
        .then(response => response.data);
    },

    /**
     * 템플릿 아이템 상세조회
     * @param templateItemId - 템플릿 ID
     */
    findOne: async (templateItemId: String | Number) => {
      return $axios
        .get(`${PREFIX}/${templateItemId}`)
        .then(response => response.data);
    },

    /**
     * 템플릿 아이템 순서 변경
     * @param templateItemId 템플릿 아이템 아이디
     * @param params 변경할 order
     * @returns
     */
    updateOrder: async (templateItemId: String | Number, params: any) => {
      return $axios
        .put(`${PREFIX}/${templateItemId}/order-number`, params)
        .then(response => response.data);
    },

    /**
     * 템플릿 심볼 삭제
     * @param templateItemId 템플릿 아이템 아이디
     * @param params
     * @returns
     */
    deleteSymbol: async (templateItemId: String | Number, params: any) => {
      return $axios
        .delete(`${PREFIX}/${templateItemId}/symbol`, {
          params,
        })
        .then(response => response.data);
    },
  };
};
