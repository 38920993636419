import { Code, EnumEntry } from './base';

interface IArticleTypeCode extends Code {
  seoulId: '01' | '02' | '03' | '04';
}

class ArticleDivisionCode extends EnumEntry<IArticleTypeCode> {
  basic: IArticleTypeCode = {
    id: 'div_001',
    seoulId: '01',
    name: '일반',
    label: '일반',
  };

  expected: IArticleTypeCode = {
    id: 'div_002',
    seoulId: '02',
    name: '예정',
    label: '예정',
  };

  embargo: IArticleTypeCode = {
    id: 'div_003',
    seoulId: '03',
    name: '엠바고',
    label: '엠바고',
  };

  values: Array<IArticleTypeCode> = [this.basic, this.expected, this.embargo];
}

export default new ArticleDivisionCode();
