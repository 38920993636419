<template>
  <!-- 순번 -->
  <td
    class="px-2 py-1.5"
    :title="`ARTICLE ID: ${cuesheetItem.artclId} \nORIGIN ID: ${cuesheetItem.orgArtclId} \nCUESHEET ITEM ID: ${cuesheetItem.id}`"
    :class="setRowStyle(cuesheetItem)"
  >
    {{ index + 1 }}
  </td>

  <!-- 앵커멘트 -->
  <td class="px-2 py-1.5" :class="setRowStyle(cuesheetItem)">
    <AnchorChip
      @dblclick="onAnchorMent($event, cuesheetItem)"
      v-if="cuesheetItem.cueItemDivCd === cuesheetItemCode.ARTICLE.id"
      :color="setAnchorColor"
    />
  </td>

  <td
    class="px-2 py-1.5"
    :class="setRowStyle(cuesheetItem)"
    :disabled="isConfirmed || mode === 'view'"
  >
    <GTaskChip
      :text="setCuesheetState"
      :background-color="setBackgroundColor"
    />
  </td>

  <!-- 형식 -->
  <td class="px-2 py-1.5" :class="setRowStyle(cuesheetItem)">
    <CuesheetFormIconContextMenu
      :icon-list="formIcons"
      :grid-size="10"
      :row="cuesheetItem"
      :symbol="cuesheetItem.cueItemTypCd"
      :temp-form="tempForm"
      :disabled="isConfirmed || mode === 'view'"
      @on-icon-handle="onFormIconHandle"
      @on-icon-delete="onFormIconDeleteHandle"
      @close="isShowFormIcon = false"
    />
  </td>

  <!-- 길이 -->
  <td class="px-2 py-1.5" :class="setRowStyle(cuesheetItem)">
    <!-- v-if="cuesheetItem?.cueItemDivCd !== cuesheetItemCode.ITEM.id" -->
    <span>
      {{ articleTime }}
    </span>
  </td>

  <!-- 합계 -->
  <td class="px-2 py-1.5" :class="setRowStyle(cuesheetItem)">
    <!-- v-if="cuesheetItem?.cueItemDivCd !== cuesheetItemCode.ITEM.id" -->
    <span>
      {{ accumulateTime }}
    </span>
  </td>
  <td>
    <ArticleTypeUI :item="cuesheetItem.artclTypCd" />
  </td>
  <!-- 제목 -->
  <td class="px-2 py-1.5" :class="setRowStyle(cuesheetItem)">
    <div v-if="cuesheetItem.cueItemDivCd === cuesheetItemCode.ITEM.id">
      <input
        v-model="cuesheetItem.cueItemTitl"
        class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-xs border-gray-200 rounded-md px-1 py-1.5 bg-white"
        :rows="2"
        :disabled="isConfirmed || mode === 'view'"
        @blur="onEdit(cuesheetItem)"
      />
    </div>
    <div v-else class="w-96 truncate" :title="cuesheetItem.cueItemTitl">
      {{ cuesheetItem.cueItemTitl }}
    </div>
  </td>

  <!-- 자막여부 -->
  <td class="px-2 py-1.5" :class="setRowStyle(cuesheetItem)">
    <CheckCircleIcon
      v-if="cuesheetItem.cueItemDivCd === cuesheetItemCode.ARTICLE.id"
      class="w-5 h-5"
      :class="
        cuesheetItem.artclApprvTypCd ===
        articleApproveCode.articleCaptionApprove.id
          ? `text-green-500`
          : cuesheetItem.artclApprvTypCd === articleApproveCode.articleApprov.id
          ? 'text-yellow-500'
          : 'text-red-500'
      "
      @dblclick="onCaption($event, cuesheetItem)"
    />
  </td>

  <!-- 비디오 아이콘 -->
  <td
    class="px-2 py-1.5"
    :class="setRowStyle(cuesheetItem)"
    :disabled="isConfirmed || mode === 'view'"
  >
    <GIconContextMenu
      :icon-list="icons"
      :grid-size="5"
      :row="cuesheetItem"
      :symbol="cuesheetItem.videoSymbol"
      :disabled="isConfirmed || mode === 'view'"
      :temp-symbol="tempSymbol"
      @on-icon-handle="onVideoIconHandle"
      @on-icon-delete="onVideoIconDeleteHandle"
      @close="isShowVideoIcon = false"
    />
  </td>

  <!-- 오디오 아이콘 -->
  <td
    class="px-2 py-1.5"
    :class="setRowStyle(cuesheetItem)"
    :disabled="isConfirmed || mode === 'view'"
  >
    <GIconContextMenu
      :icon-list="icons"
      :grid-size="5"
      :row="cuesheetItem"
      :symbol="cuesheetItem.audioSymbol"
      :disabled="isConfirmed || mode === 'view'"
      :temp-symbol="tempSymbol"
      @on-icon-handle="onAudioIconHandle"
      @on-icon-delete="onAudioIconDeleteHandle"
      @close="isShowAudioIcon = false"
    />
  </td>

  <!-- 담당(작성자) -->
  <td class="px-2 py-1.5" :class="setRowStyle(cuesheetItem)">
    <div
      v-if="cuesheetItem.cueItemDivCd === cuesheetItemCode.ARTICLE.id"
      class="flex flex-col text-left"
    >
      <div class="truncate" :title="setReporterName">
        {{ setReporterName }}
      </div>
    </div>
  </td>

  <!-- 참조사항 -->
  <td class="px-2 py-1.5 w-24" :class="setRowStyle(cuesheetItem)">
    <textarea
      v-model="cuesheetItem.rmk"
      class="border shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-xs border-gray-200 bg-white rounded-md p-[0.1rem]"
      :rows="1"
      :disabled="isConfirmed || mode === 'view'"
      @blur="onEdit(cuesheetItem)"
    />
  </td>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';
// import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
// import { CheckIcon } from '@/components/ui/icon/';
import { CheckCircleIcon } from '@heroicons/vue/24/outline';
import { AnchorChip, ArticleTypeUI } from '@/components/article/commonUI';
import { GIconContextMenu, GTaskChip } from '@/components/ui';
// import CuesheetIconMenu from '@/components/cueSheet/CuesheetIconMenu.vue';
import { useModelWrapper } from '@/hooks/useModelWrapper';
import { secondToMs } from '@/utils/format';
import {
  cuesheetStateCode,
  cuesheetItemCode,
  articleApproveCode,
} from '@/codes';
import CuesheetFormIconContextMenu from './CuesheetFormIconContextMenu.vue';

export default defineComponent({
  name: 'CueSheetDetailListItem',
  components: {
    // CheckIcon,
    AnchorChip,
    // CuesheetIconMenu,
    GIconContextMenu,
    CheckCircleIcon,
    GTaskChip,
    CuesheetFormIconContextMenu,
    ArticleTypeUI,
  },

  props: {
    cuesheetDetail: {
      type: Object,
      default: () => {
        return {};
      },
    },
    icons: {
      type: Array,
      default: () => {
        return [];
      },
    },
    index: {
      type: Number,
      default: null,
    },
    isConfirmed: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: '',
    },
    selectedRow: {
      type: Object,
      default: () => {
        return null;
      },
    },
    formIcons: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tempSymbol: {
      type: Object,
      default: () => {
        return null;
      },
    },
    tempForm: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },

  emits: [
    'on-edit-rank',
    'on-edit-caption',
    'on-edit-icon',
    'on-edit-form',
    'on-edit-anchor',
    'on-edit-item',
    'on-delete-icon',
    'on-delete-form-icon',
  ],

  setup(props, { emit }) {
    const { t } = useI18n();
    const audioIcon = ref([]);
    const cuesheetItem = useModelWrapper(props, emit, 'cuesheetDetail'); // 큐시트 아이템

    const isShowRankIcon = ref(false);
    const isShowFormIcon = ref(false);
    const isShowVideoIcon = ref(false);
    const isShowAudioIcon = ref(false);

    /**
     *
     * 앵커멘트 상태 색상
     */
    const setAnchorColor = computed(() => {
      let color = '';
      // ancMentAssignYn는 앵커멘트 확정 여부
      if (
        props.cuesheetDetail.artclApprvTypCd ===
        articleApproveCode.noneApprove.id
      ) {
        color = 'bg-red-400';
      } else if (props.cuesheetDetail.ancMentAssignYn === 'N') {
        color = 'bg-yellow-400';
      } else if (props.cuesheetDetail.ancMentAssignYn === 'Y') {
        color = 'bg-green-400';
      }
      return color;
    });

    /**
     * 기사 내용 시간
     */
    const articleTime = computed(() => {
      let sec = 0;
      sec =
        cuesheetItem.value.cueItemTime + cuesheetItem.value.artclExtTime || 0;
      return secondToMs(sec);
    });

    /**
     * 기사 내용 시간 합계
     */
    const accumulateTime = computed(() => {
      let sec = 0;
      sec = cuesheetItem.value.acc || 0;
      return secondToMs(sec);
    });

    const setRowStyle = row => {
      let cls = 'font-D2CodingBold';
      if (row.cueItemDivCd !== cuesheetItemCode.ARTICLE.id) {
        cls += ' text-[#ff00ff]';
      }
      return cls;
    };

    // 앵커멘트
    const onAnchorMent = (event, row) => {
      if (props.isConfirmed) {
        return;
      }
      event.stopPropagation();
      emit('on-edit-anchor', row);
    };

    // 자막
    const onCaption = (event, row) => {
      if (props.isConfirmed) {
        return;
      }
      event.stopPropagation();
      emit('on-edit-caption', row);
    };

    // 비디오 아이콘
    const openIconMenu = (event, type) => {
      if (props.isConfirmed || props.mode === 'view') {
        return;
      }
      event.stopPropagation();
      if (type === 'item-type') {
        isShowFormIcon.value = true;
      }
      if (type === 'video') {
        isShowVideoIcon.value = true;
      }
      if (type === 'audio') {
        isShowAudioIcon.value = true;
      }
      // emit('on-edit-icon', row, type);
    };

    // 형식
    const onForm = (event, row) => {
      console.log('row', row);
      if (props.isConfirmed || props.mode === 'view') {
        return;
      }
      event.stopPropagation();
      isShowFormIcon.value = true;
      emit('on-edit-form', row);
    };

    // 형식 아이콘 핸들링
    const onFormIconHandle = item => {
      emit('on-edit-icon', { cueItem: item, index: props.index, type: 'form' });
    };

    // 비디오 아이콘 핸들링
    const onVideoIconHandle = item => {
      emit('on-edit-icon', {
        cueItem: item,
        index: props.index,
        type: 'video',
      });
    };

    // 오디오 아이콘 핸들링
    const onAudioIconHandle = item => {
      emit('on-edit-icon', {
        cueItem: item,
        index: props.index,
        type: 'audio',
      });
    };

    // 비디오 아이콘 삭제 핸들링
    const onVideoIconDeleteHandle = item => {
      emit('on-delete-icon', {
        cueItem: item,
        index: props.index,
        type: 'video',
      });
    };

    // 오디오 아이콘 삭제 핸들링
    const onAudioIconDeleteHandle = item => {
      emit('on-delete-icon', {
        cueItem: item,
        index: props.index,
        type: 'audio',
      });
    };

    // 형식 아이콘 삭제 핸들링
    const onFormIconDeleteHandle = item => {
      emit('on-delete-form-icon', item);
    };

    // 아이템 수정
    const onEdit = row => {
      emit('on-edit-item', row);
    };

    /**
     * 큐시트 상태 표시
     */
    const setCuesheetState = computed(() => {
      let status = '';

      switch (cuesheetItem.value?.brdcStCd) {
        case cuesheetStateCode.NoneConfirmed.id:
          status = '';
          break;
        case cuesheetStateCode.Confirmed.id:
          status = '';
          break;
        case cuesheetStateCode.OnAir.id:
          // status = '방송중';
          status = '';
          break;
        case cuesheetStateCode.OffAir.id:
          status = '방송완료';
          break;
      }
      return status;
    });

    const setBackgroundColor = computed(() => {
      let color = '';

      switch (cuesheetItem.value?.brdcStCd) {
        case cuesheetStateCode.NoneConfirmed.id:
          color = '';
          // color = 'bg-primary';
          break;
        case cuesheetStateCode.Confirmed.id:
          // color = 'bg-blue-400';
          color = '';
          break;
        case cuesheetStateCode.OnAir.id:
          // color = 'bg-green-400';
          color = '';
          break;
        case cuesheetStateCode.OffAir.id:
          color = 'bg-gray-400';
          break;
      }
      return color;
    });

    const setReporterName = computed(() => {
      return cuesheetItem.value?.isSameChannel
        ? cuesheetItem.value?.rptrNm
        : `${cuesheetItem.value?.orgRptrNm}(${cuesheetItem.value?.orgChNm})`;
    });

    return {
      t,
      onCaption,
      openIconMenu,
      onForm,
      onAnchorMent,
      setAnchorColor,
      audioIcon,
      onFormIconHandle,
      isShowRankIcon,
      isShowFormIcon,
      isShowVideoIcon,
      cuesheetItemCode,
      setRowStyle,
      cuesheetItem,
      onEdit,
      articleTime,
      accumulateTime,
      onVideoIconHandle,
      isShowAudioIcon,
      onAudioIconHandle,
      onVideoIconDeleteHandle,
      onAudioIconDeleteHandle,
      setCuesheetState,
      setBackgroundColor,
      articleApproveCode,
      onFormIconDeleteHandle,
      setReporterName,
    };
  },
});
</script>
