import { onMounted, onUnmounted } from 'vue';

export const useEventListener = (
  target: HTMLElement | EventTarget,
  event: string,
  callback: () => void,
) => {
  onMounted(() => target.addEventListener(event, callback));
  onUnmounted(() => target.removeEventListener(event, callback));
};
