const makeCompanyCode = (list: any = []) => {
  let companyCode = [];

  companyCode = list?.map((item: any) => {
    return item.chDivId;
  });

  return companyCode?.join(',') || '';
};

export { makeCompanyCode };
