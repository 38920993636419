<template>
  <div class="border rounded-md bg-white overflow-auto">
    <GTable
      :list="areaCueSheetList"
      :draggable="false"
      :multiselect="false"
      :is-loading="isLoading"
      :pagination="pagination"
      :height="height"
      :no-data-message="t('AreaCueSheetList.데이터가_없습니다')"
      @on-row-clicked="onRowClicked"
      @on-row-db-clicked="onRowDbClicked"
      @on-changed-page="onChangedPage"
    >
      <template #header>
        <th class="p-3">{{ t('AreaCueSheetList.순번') }}</th>
        <th class="p-2">{{ t('AreaCueSheetList.지역') }}</th>
        <th class="p-2">{{ t('AreaCueSheetList.방송상태') }}</th>
        <th class="p-2">{{ t('AreaCueSheetList.방송일시') }}</th>
        <th class="p-2">{{ t('AreaCueSheetList.구분') }}</th>
        <th class="p-2">{{ t('AreaCueSheetList.뉴스구분') }}</th>
        <th class="p-2">{{ t('AreaCueSheetList.등록일시') }}</th>
        <th class="p-2">{{ t('AreaCueSheetList.PD1') }}</th>
        <th class="p-2">{{ t('AreaCueSheetList.PD2') }}</th>
        <th class="p-2">{{ t('AreaCueSheetList.앵커1') }}</th>
        <th class="p-2">{{ t('AreaCueSheetList.앵커2') }}</th>
        <th class="p-2">{{ t('AreaCueSheetList.비고') }}</th>
        <th class="p-2">{{ t('AreaCueSheetList.편성분량') }}</th>
      </template>

      <template #list="{ row: row, index }">
        <AreaCueSheetListItem :cuesheet="row" :index="index" />
      </template>
    </GTable>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { GTable } from '@/components/ui';
import { useI18n } from 'vue-i18n';
import AreaCueSheetListItem from './AreaCueSheetListItem.vue';

export default defineComponent({
  name: 'AreaCueSheetList',
  components: {
    GTable,
    AreaCueSheetListItem,
  },
  props: {
    areaCueSheetList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    pagination: {
      type: Object,
      default: () => {
        return null;
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: 'h-[calc(100vh-220px)]',
    },
  },
  emits: ['on-row-clicked', 'on-row-db-clicked', 'on-changed-page'],
  setup(props, { emit }) {
    const { t } = useI18n();

    const onRowClicked = rowData => {
      emit('on-row-clicked', rowData?.row);
    };

    const onRowDbClicked = rowData => {
      emit('on-row-db-clicked', rowData?.row);
    };
    // 페이지 변경
    const onChangedPage = changePage => {
      emit('on-changed-page', changePage);
    };
    return {
      t,
      onRowClicked,
      onRowDbClicked,
      onChangedPage,
    };
  },
});
</script>

<style scoped></style>
