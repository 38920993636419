import { AxiosInstance } from 'axios';
import createAxios from '@/utils/http';
import store from '@/store';
import { useMarsLApi } from '@/apis/mars-l-api';
import { useAxiosErrorLog } from '@/hooks/use-sentry';

let lock = false;
// eslint-disable-next-line
let subscribers: ((token: string) => void)[] = [];

// eslint-disable-next-line
const subscribeTokenRefresh = (callback: (token: string) => void) => {
  subscribers.push(callback);
};

const onRefreshed = (token: string) => {
  subscribers.forEach(callback => callback(token));
};

const getRefreshToken = async (): Promise<string | void> => {
  try {
    const marsLApi = useMarsLApi();
    const refreshRes = await marsLApi.auth.tokenApi.tokenRenew();

    // refresh 토큰 시간이 남아있는 경우, access 토큰 재발급
    if (refreshRes.success) {
      const { accessToken } = refreshRes.data;
      lock = false;
      onRefreshed(accessToken);
      subscribers = [];

      store.dispatch('auth/SET_TOKEN', accessToken);
      store.dispatch('auth/FETCH_REFRESH_TOKEN_REMAINING_TIME');

      return accessToken;
    }
  } catch (e) {
    lock = false;
    subscribers = [];
  }
};

/* eslint-disable */
export default (): AxiosInstance => {
  const _axios = createAxios({
    baseURL: process.env.VUE_APP_ZODIAC_API_BASE_URL,
    headers: {
      'content-type': 'application/json',
    },
  });

  /*
      1. 요청 인터셉터를 작성합니다.
      2개의 콜백 함수를 받습니다.

      1) 요청 바로 직전 - 인자값: axios config
      2) 요청 에러 - 인자값: error
  */
  _axios.interceptors.request.use(
    (config: any) => {
      // 요청 바로 직전
      // axios 설정값에 대해 작성합니다.
      const accessToken = store.getters['auth/getToken'];
      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error: any) => {
      // 요청 에러 처리를 작성합니다.
      return Promise.reject(error);
    },
  );

  /*
      2. 응답 인터셉터를 작성합니다.
      2개의 콜백 함수를 받습니다.

      1) 응답 정성 - 인자값: http response
      2) 응답 에러 - 인자값: http error
  */
  _axios.interceptors.response.use(
    (response: any) => {
      /*
         http status가 200인 경우
         응답 바로 직전에 대해 작성합니다.
         .then() 으로 이어집니다.
      */
      return response;
    },

    async (error: any) => {
      console.error('Response error', error);
      /*
          http status가 200이 아닌 경우
          응답 에러 처리를 작성합니다.
          .catch() 으로 이어집니다.
      */

      if (error.response) {
        const {
          config,
          response: { data, status },
        } = error;
        const originalRequest = config;

        /**
         * refresh 중복 요청 해결
         * race condition and subscription 방식 사용
         *
         * https://gusrb3164.github.io/web/2022/08/07/refresh-with-axios-for-client/
         */
        // access 토큰 만료
        if (status === 401) {
          // DB에 저장된 refresh 토큰이 만료되었을 경우 로그인 화면으로 이동
          if (config?.url === '/auth/token-renew') {
            // 존재하는 토큰 삭제
            store.dispatch('auth/CLEAR_TOKEN');
            // 로그인 페이지로 이동
            window.location.href = '/login';
          } else {
            const accessToken = store.getters['auth/getToken'];
            if (!accessToken) {
              // 로그인 페이지로 이동
              window.location.href = '/login';
            } else {
              if (lock) {
                return new Promise((resolve) => {
                  subscribeTokenRefresh((token: string) => {
                    originalRequest.headers.Authorization = `Bearer ${token}`;
                    resolve(_axios(originalRequest));
                  });
                });
              }
              lock = true;
              const accessToken = await getRefreshToken();
              config.headers['Authorization'] = 'Bearer ' + accessToken;
              return _axios(config);
            }
          }
        } else if (status === 403) {
          window.Notify.error({
            message: '접근 권한이 없습니다. 관리자에게 문의해주세요.',
            duration: 3000,
          });
        } else if (data.errorCode === 10000) {
          window.Notify.error({message: '입력하신 정보를 확인해주세요.'});
        } else if (status === 500) {
          window.Notify.error({
            message: '장애가 발생했습니다. 관리자에게 문의해주세요.',
          });
          
          /**
          * axios error Sentry 전송
          */
         useAxiosErrorLog(error);
        } 
      } else {
        useAxiosErrorLog(error);
      }
      return Promise.reject(error);
    },
  );

  return _axios;
};
