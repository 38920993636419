
import { defineComponent, computed } from 'vue';
import { Switch, SwitchLabel, SwitchGroup } from '@headlessui/vue';

export default defineComponent({
  name: 'GSwitch',
  components: { Switch, SwitchLabel, SwitchGroup },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue', 'change'],

  setup(props, { emit }) {
    const enabled = computed({
      get() {
        return props.modelValue;
      },
      set(checked) {
        emit('update:modelValue', checked);
        emit('change', checked);
      },
    });

    return { enabled };
  },
});
