import { AxiosInstance } from 'axios';

const PREFIX = '/article';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 기사 액션로그 조회
     * @param {number} articleId 기사 아이디
     * @returns
     */
    findAll: async (articleId: number) => {
      return $axios
        .get(`${PREFIX}/${articleId}/actionlog`)
        .then(response => response.data);
    },
  };
};
