<template>
  <div class="relative flex flex-col space-y-1" :class="[width]">
    <label
      v-if="label"
      class="block sm:text-xs text-xs font-medium text-gray-700"
      :for="name"
      >{{ label }}
      <span v-if="required" class="text-red-600">*</span>
    </label>
    <input
      ref="inputElement"
      class="p-2 border border-gray-300 shadow-sm focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary block w-full rounded-md"
      :class="setCls"
      :type="type"
      :name="name"
      :value="inputValue"
      :readonly="readonly"
      :disabled="disabled"
      :placeholder="setPlaceholder"
      :min="min"
      :max="max"
      :maxlength="maxlength"
      :tabindex="tabIndex"
      :title="title"
      :autocomplete="autocomplete"
      @input="handleChange"
      @blur="handleBlur"
    />
    <p
      v-if="
        (errorMessage && errorMessage !== '필수 입력입니다.') ||
        (errors && errors !== '필수 입력입니다.')
      "
      class="text-xs text-red-600"
    >
      {{ errorMessage ? errorMessage : errors ? errors : '' }}
    </p>
  </div>
</template>

<script>
import { useField } from 'vee-validate';
import { ref, computed, watch, onMounted, nextTick } from 'vue';

export default {
  name: 'GInput',

  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    focus: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    maxlength: {
      type: Number,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: String,
      default: '',
    },
    tabIndex: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: 'w-full',
    },
    bgColor: {
      type: String,
      default: '',
    },
    textSize: {
      type: String,
      default: 'text-xs',
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.modelValue,
    });

    const inputElement = ref(null);

    watch(
      () => props.modelValue,
      currentValue => {
        let tmpCurrentValue = currentValue;

        if (currentValue === undefined) {
          tmpCurrentValue = '';
        }

        inputValue.value = tmpCurrentValue;
      },
    );

    watch(inputValue, currentValue => {
      emit('update:modelValue', currentValue);
    });

    const setCls = computed(() => {
      let cls = '';
      if (props.bgColor) {
        cls = props.bgColor;
      } else if (props.readonly || props.disabled) {
        cls = cls + 'focus:ring-0 bg-gray-200';
      }
      if (props.textSize) {
        cls = cls + ' ' + props.textSize;
      }
      if (errorMessage.value || props.errors) {
        cls =
          cls +
          ' border-error focus:border-error focus:ring-error placeholder:text-red-500';
      }

      return cls;
    });

    const setFocus = () => {
      inputElement.value?.focus();
    };

    const setPlaceholder = computed(() => {
      if (errorMessage.value) {
        if (errorMessage.value === '필수 입력입니다.') {
          return errorMessage.value;
        }
      } else if (props.errors) {
        return props.errors;
      } else if (props.placeholder) {
        return props.placeholder;
      }

      return props.label;
    });

    const onselectionStart = () => {
      return inputElement.value.selectionStart;
    };

    onMounted(() => {
      if (props.focus) {
        nextTick(() => {
          setFocus();
        });
      }
    });

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
      setCls,
      setFocus,
      inputElement,
      setPlaceholder,
      onselectionStart,
    };
  },
};
</script>
<style scoped>
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
