import { ref } from 'vue';
import { useEventListener } from './use-event';

export const useWindowSize = () => {
  // 너비와 높이를 저장하는 리액티브 변수
  const windowWidth = ref(window.innerWidth);
  const windowHeight = ref(window.innerHeight);

  // 창 크기가 변경될 때 실행되는 이벤트 핸들러
  const handleResize = () => {
    windowWidth.value = window.innerWidth;
    windowHeight.value = window.innerHeight;
  };

  useEventListener(window, 'resize', handleResize);

  // 현재 윈도우 창의 너비와 높이를 반환
  return {
    windowWidth,
    windowHeight,
  };
};
