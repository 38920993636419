import { AxiosInstance } from 'axios';
import groupApi from './groupApi';
import groupUserApi from './groupUserApi';

export default ($axios: AxiosInstance) => {
  return {
    basic: groupApi($axios),
    groupUser: groupUserApi($axios),
  };
};
