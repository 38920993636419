import { AxiosInstance } from 'axios';

const PREFIX = '/journalist';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 전국기자 조회
     * @param params
     * @returns
     */
    findAll: async (params: any) => {
      return $axios
        .get(`${PREFIX}`, { params })
        .then(response => response.data);
    },

    /**
     * 전국기자 상세 조회
     * @param params
     * @returns
     */
    findOne: async (journBbId: number | string) => {
      return $axios
        .get(`${PREFIX}/${journBbId}`)
        .then(response => response.data);
    },

    /**
     * 전국기자 등록
     * @param params
     * @returns
     */
    create: async (params: any) => {
      return $axios.post(`${PREFIX}`, params).then(response => response.data);
    },

    /**
     * 전국기자 수정
     * @param journBbId 전국기자 ID
     * @param params
     * @returns
     */
    update: async (journBbId: number | string, params: any) => {
      return $axios
        .put(`${PREFIX}/${journBbId}`, params)
        .then(response => response.data);
    },

    /**
     * 전국기자 삭제
     * @param journBbId 전국기자 ID
     * @returns
     */
    delete: async (journBbId: number) => {
      return $axios
        .delete(`${PREFIX}/${journBbId}`)
        .then(response => response.data);
    },
  };
};
