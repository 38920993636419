<template>
  <div class="border rounded-md bg-white overflow-auto">
    <GTable
      :list="articles"
      :draggable="false"
      :multiselect="false"
      :isLoading="isLoading"
      :pagination="pagination"
      :height="height"
      :selected-row-index="selectedRowIndex"
      @on-row-clicked="onRowClicked"
      @on-row-db-clicked="onRowDoubleClicked"
      @on-changed-page="onChangedPage"
    >
      <template #header>
        <th class="p-3">{{ t('MyRegionArticleList.순번') }}</th>
        <th class="p-2 w-5"></th>
        <th class="pl-3">
          <LockClosedIcon class="inline-block w-4 h-4" />
        </th>
        <th class="p-2">{{ t('MyRegionArticleList.송고일시') }}</th>
        <th class="p-2">{{ t('MyRegionArticleList.형식') }}</th>
        <th class="p-2">{{ t('MyRegionArticleList.자막') }}</th>
        <th class="p-2">{{ t('MyRegionArticleList.제목') }}</th>
        <th class="p-2">{{ t('MyRegionArticleList.출고일시') }}</th>
        <th class="p-2">{{ t('MyRegionArticleList.부서') }}</th>
        <th class="p-2">{{ t('MyRegionArticleList.기자') }}</th>
        <th class="p-2">{{ t('MyRegionArticleList.뉴스') }}</th>
        <th class="p-2">{{ t('MyRegionArticleList.길이') }}</th>
        <th class="p-2">{{ t('MyRegionArticleList.첨부파일') }}</th>
        <th class="p-2">{{ t('MyRegionArticleList.엠바고') }}</th>
        <th class="p-2">{{ t('MyRegionArticleList.외부전송') }}</th>
        <!-- <th class="p-2">Actions</th> -->
      </template>
      <template #list="{ row, index }">
        <MyRegionArticleListItem
          :row="row"
          :index="index"
          @on-unlock="$emit('on-unlock')"
        />
      </template>
    </GTable>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, toRefs } from 'vue';
import { GTable /*GIconButton*/ } from '@/components/ui';
import { LockClosedIcon } from '@heroicons/vue/24/outline';
import { useI18n } from 'vue-i18n';
import MyRegionArticleListItem from './MyRegionArticleListItem.vue';

const { t } = useI18n();

const props = defineProps({
  articles: {
    type: Array,
    default: () => {
      return [];
    },
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  pagination: {
    type: Object,
    default: () => {
      return null;
    },
  },
  height: {
    type: String,
    default: '',
  },
  selectedRowIndex: {
    type: Number,
  },
});

const { articles } = toRefs(props);
const emit = defineEmits([
  'on-row-clicked',
  'on-row-db-clicked',
  'on-changed-page',
  'on-unlock',
]);

// 행 클릭
const onRowClicked = rowData => {
  emit('on-row-clicked', rowData);
};

// 행 더블클릭
const onRowDoubleClicked = rowData => {
  emit('on-row-db-clicked', rowData);
};

// 페이지 변경
const onChangedPage = changePage => {
  emit('on-changed-page', changePage);
};
</script>
