import { Module } from 'vuex';
import { RootState } from '..';
import { useMarsLApi } from '@/apis/mars-l-api';

export type WorkerState = {
  workers: any[];
};

export const WorkerModule: Module<WorkerState, RootState> = {
  namespaced: true,
  state: () => ({
    workers: [],
  }),
  getters: {
    findAllWorkers: state => {
      return state.workers.map((v: any) => {
        return {
          ...v,
          id: v.id,
          name: v.userNm,
          label: v.userNm,
        };
      });
    },
  },
  actions: {
    FIND_ALL_WORKERS: async ({ commit }, payload) => {
      const marsLApi = useMarsLApi();

      try {
        const workerList = await marsLApi.user.basic.findAll(payload);
        commit('FIND_ALL_WORKERS', workerList);
      } catch (error) {
        console.error('error', error);
      }
    },
  },
  mutations: {
    async FIND_ALL_WORKERS(state, list) {
      state.workers = list?.data?.content;
    },
  },
};
