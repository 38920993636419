<template>
  <div class="" :class="!isModal ? 'px-2 py-1' : ''">
    <!-- 버튼 -->
    <div
      class="flex border-b py-1 px-1.5 justify-end items-center space-x-1.5"
      :class="!isModal ? 'pb-2' : ''"
    >
      <!-- 출고상태 -->
      <div class="pr-2">
        <ArticleStatusUI
          :approve-code="approveTypeCd"
          :is-cuesheet-matching="isMatchingCuesheet"
        />
      </div>
      <!-- 수정 -->
      <GButton
        v-if="!isCuesheet && isMyCompany(articleChannelId)"
        v-tooltip="'기사 편집'"
        icon="pencil"
        :disabled="
          !can(AppAuthorityEnum.ARTICLE_WRITE.code) ||
          approveTypeCd === articleApproveCode.articleCaptionApprove.id
        "
        @click="onUpdateArticle"
      />
      <!-- 출고 -->
      <GConfirmButton
        v-if="!isCuesheet && isMyCompany(articleChannelId)"
        v-tooltip="'출고'"
        icon="chul-go"
        :disabled="
          !can(AppAuthorityEnum.ARTICLE_RELEASE.code) ||
          approveTypeCd === articleApproveCode.articleCaptionApprove.id ||
          lock?.lckYn === 'Y'
        "
        :title="setConfirmTitle"
        :html="setConfirmHtml('approve')"
        text="출고"
        :show-confirm-button="setConfirmButton"
        confirm-button-text="출고(기사)"
        :show-deny-button="true"
        deny-button-text="출고(기사+자막)"
        deny-button-color="#22C55E"
        confirm-button-color="#dc3741"
        :input="isShowSendHomePageCheckBox"
        :input-placeholder="`홈페이지 전송`"
        :input-value="false"
        :return-input-value-on-deny="useValueOnDeny"
        @on-confirm="onApproveArticle"
        @on-deny="onApproveArticleCaption"
      />
      <!-- 출고 취소 -->
      <GConfirmButton
        v-if="!isCuesheet && isMyCompany(articleChannelId)"
        v-tooltip="'출고 취소'"
        icon="archive-box-x-mark"
        :disabled="
          !can(AppAuthorityEnum.ARTICLE_RELEASE.code) ||
          approveTypeCd === articleApproveCode.noneApprove.id ||
          lock?.lckYn === 'Y'
        "
        :title="setConfirmTitle"
        :html="setConfirmHtml('cancelApprove')"
        text="출고 취소"
        @on-confirm="onCancelApprove"
      />

      <!-- 인쇄 -->
      <GButton
        v-if="route.query.target !== 'seoul'"
        v-tooltip="'인쇄'"
        icon="printer"
        :disabled="!can(AppAuthorityEnum.ARTICLE_PRINT.code) || isLoading"
        @click="onClickedPrint"
      />

      <!-- 홈페이지 전송  -->
      <GConfirmButton
        v-if="!isShowSendHomePageButton"
        v-tooltip="t('MyRegionArticle.홈페이지_전송')"
        icon="cloud-arrow-up"
        :disabled="isDisabledSendHomePageButton"
        :title="title"
        :html="setConfirmHtml('homepage')"
        @on-confirm="onSendHomePage"
      />

      <!-- 홈페이지 전송 취소 -->
      <GConfirmButton
        v-if="isShowSendHomePageButton"
        v-tooltip="t('MyRegionArticle.홈페이지_전송_취소')"
        icon="cloud"
        :title="title"
        :disabled="isDisabledUnSendHomePageButton"
        :html="setConfirmHtml('cancelHomepage')"
        @on-confirm="onUnSendHomePage"
      />
    </div>
    <!-- 내용 -->
    <div v-if="isLoading" class="pt-20">
      <PageLoader />
    </div>
    <div
      v-if="!isLoading"
      class="flex h-[calc(100vh-146px)] justify-center border-gray-200 bg-slate-100"
    >
      <div class="flex">
        <!-- 기사 작성 영역 -->
        <div class="flex-1 border-r border-gray-300 bg-white">
          <div class="p-1.5 space-y-1">
            <!-- 제목 -->
            <div class="flex space-x-1 text-xs pb-1">
              <GInput
                v-model="title"
                name="title"
                :title="title"
                :disabled="true"
                placeholder="제목을 입력하세요."
                text-size="text-[15px]"
              />
              <!-- 맞춤법 -->
              <GButton
                v-tooltip="'맞춤법'"
                icon="spellcheck"
                :disabled="isLoading"
                @click="isShowSpellCheckModal = true"
              />

              <!-- 자막 복사 -->
              <GButton
                v-tooltip="'자막 복사'"
                icon="clipboard-document-list"
                @click="onClickedCaptionCopy"
              />

              <!-- 기사 복사 -->
              <GButton
                v-tooltip="'기사 복사'"
                icon="clipboard-document"
                @click="onClickedArticleCopy"
              />
            </div>

            <!-- 작성기 영역 -->
            <div class="overflow-y-auto h-[calc(100vh-200px)]">
              <ArticleEditor
                ref="articleEditor"
                v-if="type"
                :type-id="type"
                v-model:reporter-text-array="
                  articleEditorState.reporterTextArray
                "
                v-model:anchor-text-array="articleEditorState.anchorTextArray"
                v-model:caption-array="articleEditorState.reporterCaptionArray"
                :reporter-editor-read-only="true"
                :reporter-caption-read-only="true"
                :anchor-editor-read-only="true"
                :anchor-caption-read-only="true"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- 메타 데이터 영역 -->
      <div class="w-[27.438rem] bg-slate-200 p-2">
        <GTabPanel :tabs="tabs" :selected-tab-id="selectedTabId">
          <TabPanel>
            <template v-slot="{ selected }">
              <ArticleMetaData
                v-if="selected"
                v-model:article-type="articleType"
                v-model:article-reporter="reporter"
                v-model:article-program="program"
                v-model:total-article-time="totalArticleTime"
                v-model:article-division="division"
                v-model:article-embargo="embargo"
                v-model:article-category="category"
                v-model:article-tag="tag"
                v-model:article-extra-time="extraTime"
                v-model:article-extra-time-type="extraTimeType"
                v-model:article-inputer="inputer"
                v-model:article-transfer-date="transferDate"
                v-model:article-approve-date="approveDate"
                v-model:article-updateDate="updateDate"
                :article-channel-id="articleChannelId"
                :is-same-channel="isSameChannel"
                :origin-channel="originChannelName"
                article-mode="view"
                height="h-[calc(100vh-214px)]"
              />
            </template>
          </TabPanel>
          <TabPanel v-if="articleChannelId !== 1">
            <template v-slot="{ selected }">
              <div class="h-[calc(100vh-214px)] bg-white rounded-md p-3">
                <ViewAttachFile
                  v-if="selected"
                  :route-name="setAttachRouteName"
                  :item-id="id"
                  :label="false"
                >
                  <template #noData>
                    <GNoData />
                  </template>
                </ViewAttachFile>
              </div>
            </template>
          </TabPanel>
          <TabPanel v-if="articleChannelId !== 1">
            <template v-slot="{ selected }">
              <ArticleLink
                v-if="selected"
                height="h-[calc(100vh-214px)]"
                truncate-width="w-[23rem]"
                :article-id="id"
                is-readonly
                :is-show-label="false"
              />
            </template>
          </TabPanel>
          <TabPanel v-if="articleChannelId !== 1">
            <template v-slot="{ selected }">
              <ArticleActions
                v-if="selected"
                :article-id="id"
                height="h-[calc(100vh-214px)]"
              />
            </template>
          </TabPanel>
        </GTabPanel>
      </div>
    </div>

    <!-- 프린트 옵션 모달 -->
    <ArticlePrintModal
      v-if="isShowPrintOptionModal"
      :print-data="printData"
      @close="isShowPrintOptionModal = false"
    />

    <!-- 맞춤법 검사 모달 -->
    <SpellCheckModal
      v-if="isShowSpellCheckModal"
      :editor-data="articleEditorState"
      :article-type="type"
      @close="isShowSpellCheckModal = false"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { RouterName } from '@/types/router';
import {
  GButton,
  GTabPanel,
  GInput,
  GConfirmButton,
  GNoData,
} from '@/components/ui';
import { useI18n } from 'vue-i18n';
import { TabPanel } from '@headlessui/vue';
import {
  ArticleMetaData,
  ArticleLink,
  ArticleActions,
} from '@/components/article';
import ArticleEditor from '@/components/article/ArticleEditor';
import ViewAttachFile from '@/components/common/ViewAttachFile.vue';
import {
  articleTypeCode,
  articleDivisionCode,
  articleApproveCode,
} from '@/codes';
import { useMarsLApi } from '@/apis/mars-l-api';
import { useUserInfo } from '@/hooks/use-user-info';
import { setEditorValues } from '@/utils/editor-helper';
import {
  defaultDate,
  defaultDateTime,
  displayDateTimeHm,
  secondToMs,
} from '@/utils/format';
import { calculateArticleTime, isValidEmbargo } from '@/utils/article-helper';
import PageLoader from '@/components/common/PageLoader.vue';
import { useStore } from 'vuex';
import { cloneDeep } from 'lodash';
import { ArticleStatusUI } from '@/components/article/commonUI';
import { isMyCompany } from '@/utils/compare-helper';
import ArticlePrintModal from '@/components/modal/article/ArticlePrintModal.vue';
import { useSweetAlert } from '@/hooks/use-sweet-alert';
import { AppAuthorityEnum } from '@/enums/app-authority.ts';
import embargoAlert from '@/utils/embargo-alert.ts';
import { initCaptions } from '@/utils/print';
import { convertKeysToCamelCase } from '@/utils/str';
import { find } from 'lodash';
import SpellCheckModal from '../../components/modal/article/SpellCheckModal.vue';

export default defineComponent({
  name: RouterName.ViewArticle,
  components: {
    SpellCheckModal,
    ArticleEditor,
    GButton,
    GTabPanel,
    TabPanel,
    GInput,
    ArticleMetaData,
    ArticleLink,
    ArticleActions,
    PageLoader,
    ArticleStatusUI,
    ArticlePrintModal,
    GConfirmButton,
    ViewAttachFile,
    GNoData,
  },
  props: {
    id: {
      type: [String, Number],
      default: 0,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    isCuesheet: {
      type: Boolean,
      default: false,
    },
  },
  emits: [],

  setup(props) {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const marsLApi = useMarsLApi();
    const { swalError } = useSweetAlert();
    const { can } = useUserInfo();

    const isLoading = ref(false);
    const isSameChannel = ref(false);
    const originChannelName = ref();
    const articleEditor = ref();
    const article = ref(null);
    const articleEditorState = ref({
      anchorTextArray: [''],
      anchorCaptionArray: [[]], // c/t형태: [[{}],[{}],[{}]...]
      reporterTextArray: [''],
      reporterCaptionArray: [[]],
    });
    const title = ref(); // 제목
    const type = ref(null); // 초기값은 null로 설정해야 한다.
    const articleType = ref(null); // 형식
    const reporter = ref(); // 기자
    const program = ref(); // 프로그램
    const division = ref(); // 구분
    const category = ref(); // 카테고리
    const embargo = ref(); // 엠바고
    const tag = ref([]); // 태그
    const extraTime = ref('00:00');
    const extraTimeType = ref('+');
    const inputer = ref(null); // 작성자
    const transferDate = ref(null); // 송고일시
    const approveDate = ref(null); // 출고일시
    const updateDate = ref(); // 작성일시
    const lock = ref(null); // 락
    const approveTypeCd = ref(null);
    const isMatchingCuesheet = ref(false);
    let speed = null;

    let selectedTabId = ref(0);
    const files = ref([]);
    const isShowReferenceModal = ref(false);
    const isShowSpecialCharModal = ref(false);
    const totalArticleTime = ref(0);
    const articleChannelId = ref(0);
    const isSendHomepage = ref(false);

    const printData = ref([]);
    const isShowPrintOptionModal = ref(false);
    const isShowSpellCheckModal = ref(false);

    const tabs = computed(() => {
      let tabList = [
        { id: 1, name: '메타데이터' },
        { id: 2, name: '첨부파일' },
        { id: 3, name: '링크' },
        { id: 4, name: '이력' },
      ];

      if (articleChannelId.value === 1) {
        tabList = [{ id: 1, name: '메타데이터' }];
      }

      return tabList;
    });

    const setConfirmTitle = computed(() => {
      if (
        division.value?.id === articleDivisionCode.embargo.id &&
        isValidEmbargo(embargo.value)
      ) {
        return `<div class="space-y-2"><div><span class="text-red-600 font-D2CodingBold text-2xl">엠바고 기사</span> 입니다.</div><div>${title.value}</div></div>`;
      }

      return title.value;
    });

    // 첨부파일 탭에서 가져오려는 첨부파일의 api를 사용하기 위함
    const setAttachRouteName = computed(() => {
      let name = '';

      if (
        route?.name === RouterName.MyArticle ||
        route?.name === RouterName.CreateMyArticle
      ) {
        name = 'myArticle';
      } else if (
        route?.name === RouterName.EditArticle ||
        route?.name === RouterName.ViewArticle ||
        route?.name === RouterName.ShowCueSheetDetail ||
        route?.name === RouterName.ShowAreaCueSheetDetail
      ) {
        name = 'coverDesk';
      }

      return name;
    });

    // 기사 상세 가져오기
    const getArticle = async () => {
      try {
        isLoading.value = true;
        let response = null;
        // 취재데스크
        if (
          route.query.target === 'my-region' ||
          route.query.target === 'area' ||
          route.name === RouterName.ShowCueSheetDetail ||
          route.name === RouterName.ShowAreaCueSheetDetail
        ) {
          response = await marsLApi.coverDesk.basic.findOne(parseInt(props.id));
        }
        // 서울 MBC
        else if (
          route.query.target === 'seoul' ||
          route.name === 'seoulMBCCueSheetDetail'
        ) {
          response = await marsLApi.seoulMBC.article.findOne(props.id);
        }

        return response;
      } catch (error) {
        console.error('error', error);
      } finally {
        isLoading.value = false;
      }
    };

    // 기사 구분에 따른 날짜 포멧
    const setEmbargoDate = (articleDiv, articleDivDate) => {
      let date = null;

      if (articleDiv === articleDivisionCode.embargo.id) {
        date = articleDivDate ? defaultDateTime(articleDivDate) : null;
      } else {
        date = articleDivDate ? defaultDate(articleDivDate) : null;
      }

      return date;
    };

    // 기사 수정모드로 변경
    const onUpdateArticle = async () => {
      // 기사 잠금 api 실패시 알림 창 띄움
      try {
        const response = await marsLApi.coverDesk.basic.lock(
          parseInt(props.id),
        );

        if (response.success) {
          // 현재 활성화된 기사 보기 탭을 담아놓는다.
          const activeTab = cloneDeep(store.getters['tab/activeTab']);

          // 기사 보기 탭을 수정 탭으로 변경
          store.dispatch('tab/CHANGE_TAB_MODE', {
            to: 'view',
            mode: 'edit',
            currentTab: activeTab,
            title: title.value,
          });
        }
      } catch (error) {
        swalError({
          showCancelButton: false,
          title: `<div>기사를 수정할 수 없습니다.</div>`,
          html: `<div>${error?.response?.data?.message}</div>`,
        });
      }
    };

    const setConfirmHtml = type => {
      if (type === 'approve') {
        return '기사를 출고하시겠습니까?';
      } else if (type === 'cancelApprove') {
        return '기사를 출고 취소하시겠습니까?';
      } else if (type === 'homepage') {
        return '<div>기사를 전송하시겠습니까?</div>';
      } else if (type === 'cancelHomepage') {
        return '<div>기사를 전송 취소하시겠습니까?</div>';
      }
    };

    const setConfirmButton = computed(() => {
      return approveTypeCd.value === articleApproveCode.articleApprov.id
        ? false
        : true;
    });

    // 기사 출고
    const onApproveArticle = async result => {
      try {
        const response = await marsLApi.coverDesk.basic.updateApprove(
          parseInt(props.id),
          {
            apprvTypCd: articleApproveCode.articleApprov.id,
          },
        );

        if (response.success) {
          window.Notify.success({
            message: `"${title.value}" 이(가) 출고 되었습니다.`,
          });
          // initData();
          store.dispatch('refresh/REFRESH_MY_REGION_ARTICLE_STATUS', {
            reason: 'approveArticle',
          });

          // 현재 활성화된 기사 수정 탭을 담아놓는다.
          const activeTab = cloneDeep(store.getters['tab/activeTab']);

          store.dispatch('tab/DELETE_TAB', activeTab);
          router.push({ name: RouterName.MyRegionArticle });

          // 홈페이지 전송
          if (result === 1) {
            await onSendHomePage({ isRefreshArticles: false });
          }
        }
      } catch (error) {
        swalError({
          showCancelButton: false,
          title: '<div>기사를 출고할 수 없습니다.</div>',
          html: `<div>${error?.response?.data?.message}</div>`,
        });
      }
    };

    // 기사+자막 출고
    const onApproveArticleCaption = async result => {
      try {
        const response = await marsLApi.coverDesk.basic.updateApprove(
          parseInt(props.id),
          {
            apprvTypCd: articleApproveCode.articleCaptionApprove.id,
          },
        );

        if (response.success) {
          window.Notify.success({
            message: `"${title.value}" 이(가) 기사 + 자막 출고되었습니다.`,
          });
          // initData();
          store.dispatch('refresh/REFRESH_MY_REGION_ARTICLE_STATUS', {
            reason: 'approveArticleCaption',
          });

          // 현재 활성화된 기사 수정 탭을 담아놓는다.
          const activeTab = cloneDeep(store.getters['tab/activeTab']);

          store.dispatch('tab/DELETE_TAB', activeTab);
          router.push({ name: RouterName.MyRegionArticle });

          // 홈페이지 전송
          if (result === 1) {
            await onSendHomePage({ isRefreshArticles: false });
          }
        }
      } catch (error) {
        swalError({
          showCancelButton: false,
          title: '<div>기사를 출고할 수 없습니다.</div>',
          html: `<div>${error?.response?.data?.message}</div>`,
        });
      }
    };

    // 기사 출고 취소
    const onCancelApprove = async () => {
      try {
        const response = await marsLApi.coverDesk.basic.updateApprove(
          parseInt(props.id),
          {
            apprvTypCd: articleApproveCode.noneApprove.id,
          },
        );

        if (response.success) {
          window.Notify.success({
            message: '[출고 취소] 완료되었습니다.',
          });
          initData();
          store.dispatch('refresh/REFRESH_MY_REGION_ARTICLE_STATUS', {
            reason: 'cancelApprove',
          });
        }
      } catch (error) {
        swalError({
          showCancelButton: false,
          title: '<div>기사를 출고 취소할 수 없습니다.</div>',
          html: `<div>${error?.response?.data?.message}</div>`,
        });
      }
    };

    // 기사 인쇄시 엠바고 기사일 경우 알림창 띄우기
    const onClickedPrint = async () => {
      if (division.value.id === articleDivisionCode.embargo.id) {
        const result = await embargoAlert(embargo.value, 'print');

        // 엠바고 날짜가 지나지 않아서 alert 창에서 확인 버튼 누른경우 OR 엠바고 날짜가 지난 경우
        if (result === 'confirm' || result === 'finished') {
          isShowPrintOptionModal.value = true;
        }
        return;
      }
      isShowPrintOptionModal.value = true;
    };

    const setData = response => {
      // 내지역, 타지역
      if (
        route.query.target === 'my-region' ||
        route.query.target === 'area' ||
        route.name === RouterName.ShowCueSheetDetail ||
        route.name === RouterName.ShowAreaCueSheetDetail
      ) {
        tag.value = response.articleTags?.map(tag => {
          return {
            ...tag.tag,
            tagName: tag.tag.tagNm,
          };
        });
        transferDate.value = displayDateTimeHm(response?.inputDtm); // 송고일시
        approveDate.value = response?.apprvTypDtm
          ? displayDateTimeHm(response?.apprvTypDtm)
          : null; // 출고일시
        isMatchingCuesheet.value = response?.cueMatchCnt > 0 ? true : false;

        // 에디터에 값 대입
        articleEditorState.value = setEditorValues({
          artclTypCd: response.artclTypCd,
          anchorCapList: response.articleAnchorCaps,
          articleCapList: response.articleCaps,
          ancMentCtt: response.ancMentCtt || JSON.stringify(['\n']),
          artclCtt: response.artclCtt || JSON.stringify(['\n']),
        });

        type.value = null;
        type.value = response.artclTypCd;
        title.value = response.artclTitl; // 제목
        // TODO :: 형식 ArticleMEtadata 컴포넌트에서 response.artclTypCd만 보냈을때 감지 안되는건 나중에..
        articleType.value = articleTypeCode.values.find(
          item => item.id === response.artclTypCd,
        );

        articleChannelId.value = response.chDivId;
        isSameChannel.value = response.isSameChannel;
        originChannelName.value = response.orgChNm;
        reporter.value = response.isSameChannel
          ? response.rptrNm
          : `${response.orgRptrNm}(${response.orgChNm})`;

        // 프로그램 (내 지역사이면 id, 다른 지역사이면 이름을 보낸다.)
        program.value = isMyCompany(articleChannelId.value)
          ? response.brdcPgmId
          : response.brdcPgmNm;
        // TODO :: 구분 ArticleMEtadata 컴포넌트에서 response.artclDivCd만 보냈을때 감지 안되는건 나중에..
        division.value = articleDivisionCode.values.find(
          item => item.id === response.artclDivCd,
        );
        // 엠바고
        embargo.value = setEmbargoDate(
          response.artclDivCd,
          response.artclDivDtm,
        );
        extraTime.value = secondToMs(Math.abs(response.artclExtTime));
        extraTimeType.value = response.artclExtTime >= 0 ? '+' : '-';
        category.value = response.artclCateNm; // 카테고리
        inputer.value = response.isSameChannel
          ? response.inputrNm
          : `${response.orgInputrNm}(${response.orgChNm})`; // 작성자
        updateDate.value = displayDateTimeHm(response.updtDtm); // 업데이트 일시
        // 기사 시간
        totalArticleTime.value = secondToMs(
          calculateArticleTime(
            articleEditorState.value.anchorTextArray,
            articleEditorState.value.reporterTextArray,
            speed,
          ),
        );
        lock.value = {
          lckYn: response.lckYn,
          lckrId: response.lckrId,
        };
        approveTypeCd.value = response.apprvTypCd;

        isSendHomepage.value = response.exportYn === 'Y';
        // 데이터 원본
        const cloneArticleEditor = cloneDeep(articleEditorState.value);
        printData.value = [
          initCaptions({ ...response, ...cloneArticleEditor }),
        ];
      }
      // 서울 MBC
      else if (
        route.query.target === 'seoul' ||
        route.name === 'seoulMBCCueSheetDetail'
      ) {
        let seoulNewsDetail = convertKeysToCamelCase(response.data);

        // 서울 기사 형식이 지역사 기사 형식에는 없는 경우 형식을 스트레이트로 보여준다.
        seoulNewsDetail.type =
          find(
            articleTypeCode.values,
            v => v.seoulId === seoulNewsDetail.artclTypCd,
          )?.id || articleTypeCode.Straight.id;

        // 타입을 mars-l 기준으로 변경처리
        type.value = seoulNewsDetail.type;

        tag.value = [];
        transferDate.value = displayDateTimeHm(seoulNewsDetail?.inputDtm); // 송고일시

        // 출고일시
        approveDate.value = seoulNewsDetail?.artclRelseDtm
          ? displayDateTimeHm(seoulNewsDetail?.artclRelseDtm)
          : null;
        isMatchingCuesheet.value =
          seoulNewsDetail?.cueLinkYn === 'Y' ? true : false;

        // 에디터에 값 대입
        articleEditorState.value = setEditorValues({
          artclTypCd: seoulNewsDetail.type,
          anchorCapList: [''],
          articleCapList: seoulNewsDetail.caps.map(caption => {
            return {
              capCtt: caption.capCtt,
              capTypCd: 'cap_002',
              lnNo: caption.lnNo,
              lnOrd: caption.capOrd,
              icon: '',
            };
          }),
          ancMentCtt: seoulNewsDetail.ancMents
            ? JSON.stringify([...seoulNewsDetail.ancMents])
            : JSON.stringify(['']),
          artclCtt: seoulNewsDetail.artclCtts
            ? JSON.stringify([...seoulNewsDetail.artclCtts])
            : JSON.stringify(['']),
        });

        type.value = null;
        type.value = seoulNewsDetail.type;
        articleType.value = seoulNewsDetail.type;
        title.value = seoulNewsDetail.artclTitl; // 제목
        reporter.value = seoulNewsDetail.rptrNm;
        program.value = seoulNewsDetail.brdcPgmNm;
        division.value = articleDivisionCode.values.find(
          item => item.seoulId === seoulNewsDetail.artclDivCd,
        );
        // 엠바고
        embargo.value = setEmbargoDate(
          division.value.id,
          division.value.id === articleDivisionCode.embargo.id
            ? seoulNewsDetail.embgDtm
            : seoulNewsDetail.brdcSchdDt,
        );
        extraTime.value = '00:00';
        category.value = seoulNewsDetail.artclCateNm; // 카테고리
        inputer.value = seoulNewsDetail.inputrNm; // 작성자
        updateDate.value = displayDateTimeHm(seoulNewsDetail.updtDtm); // 업데이트 일시 ////////////
        totalArticleTime.value = secondToMs(seoulNewsDetail?.artclReqdTime);
        lock.value = {
          lckYn: 'N',
          lckrId: '',
        };

        // 기사 출고 + 자막 출고
        if (
          seoulNewsDetail.artclStCd === '02' &&
          seoulNewsDetail.capStCd === '02'
        ) {
          approveTypeCd.value = articleApproveCode.articleCaptionApprove.id;
        }
        // 기사 출고 + 자막 미출고
        else if (
          seoulNewsDetail.artclStCd === '02' &&
          seoulNewsDetail.capStCd === '01'
        ) {
          approveTypeCd.value = articleApproveCode.articleApprov.id;
        }
        // 기사 미출고 + 자막 미출고
        else if (
          seoulNewsDetail.artclStCd === '01' &&
          seoulNewsDetail.capStCd === '01'
        ) {
          approveTypeCd.value = articleApproveCode.noneApprove.id;
        }
        articleChannelId.value = 1;
        // 데이터 원본
        const cloneArticleEditor = cloneDeep(articleEditorState.value);
        printData.value = [
          initCaptions({ ...seoulNewsDetail, ...cloneArticleEditor }),
        ];
      }
    };

    // 초기화
    const initData = async () => {
      const response = await getArticle();
      setData(response);
    };

    const onClickedCaptionCopy = () => {
      articleEditor.value.onCopyToCaption();
    };

    const onClickedArticleCopy = () => {
      articleEditor.value.onCopyToArticle();
    };

    /**
     * sweetAlert 체크박스 사용여부 - 홈페이지 전송 되었으면 사용하지 않음
     * @type {ComputedRef<string>}
     */
    const isShowSendHomePageCheckBox = computed(() => {
      return !can(AppAuthorityEnum?.ARTICLE_HOMEPAGE_SEND.code) ||
        isSendHomepage.value
        ? ''
        : `checkbox`;
    });

    /**
     * sweetAlert input 타입 사용하지 않을시 true로 값을 주면 에러가 나기때문에
     * 홈페이지 전송이 되었을 경우에 input타입을 사용하지 않기때문에 return-input-value-on-deny 값도 false로 변경해준다
     * @type {ComputedRef<boolean>}
     */
    const useValueOnDeny = computed(() => {
      return (
        can(AppAuthorityEnum?.ARTICLE_HOMEPAGE_SEND.code) &&
        !isSendHomepage.value
      );
    });

    const isShowSendHomePageButton = computed(() => {
      return isSendHomepage.value;
    });

    const isDisabledSendHomePageButton = computed(() => {
      let disabled = false;
      disabled =
        !can(AppAuthorityEnum?.ARTICLE_HOMEPAGE_SEND.code) ||
        approveTypeCd.value === articleApproveCode.noneApprove.id;

      return disabled;
    });

    const isDisabledUnSendHomePageButton = computed(() => {
      let disabled = false;
      disabled = !can(AppAuthorityEnum?.ARTICLE_HOMEPAGE_SEND.code);

      return disabled;
    });

    /**
     * 홈페이지 전송
     */
    const onSendHomePage = async ({ isRefreshArticles = true } = {}) => {
      try {
        const response = await marsLApi.coverDesk.basic.sendHomePage(
          parseInt(props.id),
        );
        if (response.success) {
          if (isRefreshArticles) {
            await initData();
          }
        }
      } catch (error) {
        console.error('send homepage error', error);
      }
    };

    /**
     * 홈페이지 전송 취소
     */
    const onUnSendHomePage = async ({ isRefreshArticles = true } = {}) => {
      try {
        const response = await marsLApi.coverDesk.basic.unSendHomePage(
          parseInt(props.id),
        );
        if (response.success) {
          if (isRefreshArticles) {
            await initData();
          }
        }
      } catch (error) {
        console.error('unsend homepage error', error);
      }
    };

    onMounted(async () => {
      initData();
      await store.dispatch('systemInfo/FIND_MY_SYSTEM_INFO');
      speed = store.getters['systemInfo/readSpeed'];
    });

    return {
      t,
      route,
      isLoading,
      article,
      articleTypeCode,
      articleApproveCode,
      articleEditorState,
      articleEditor,
      tabs,
      selectedTabId,
      isShowReferenceModal,
      isShowSpecialCharModal,
      isShowPrintOptionModal,
      type,
      totalArticleTime,
      title,
      articleType,
      reporter,
      program,
      division,
      category,
      embargo,
      tag,
      extraTime,
      inputer,
      approveDate,
      transferDate,
      updateDate,
      approveTypeCd,
      isMatchingCuesheet,
      articleChannelId,
      isMyCompany,
      files,
      onUpdateArticle,
      initData,
      setConfirmHtml,
      setConfirmButton,
      onApproveArticle,
      onApproveArticleCaption,
      onCancelApprove,
      setAttachRouteName,
      printData,
      AppAuthorityEnum,
      can,
      onClickedPrint,
      setConfirmTitle,
      lock,
      extraTimeType,
      isSameChannel,
      originChannelName,
      onClickedCaptionCopy,
      onClickedArticleCopy,
      isShowSendHomePageCheckBox,
      useValueOnDeny,
      isShowSendHomePageButton,
      isDisabledSendHomePageButton,
      isDisabledUnSendHomePageButton,
      onSendHomePage,
      onUnSendHomePage,
      isShowSpellCheckModal,
    };
  },
});
</script>
