<template>
  <GTable
    id="cueSheetDetailSBList"
    :list="cueSheetDetailSbList"
    :is-loading="isLoading"
    :multiselect="false"
    :no-data-message="'데이터가 없습니다.'"
    :draggable="draggable"
    :group="group"
    @is-dragging="isDragging"
    @is-finished-drag="finishedDrag"
  >
    <template #header>
      <th class="p-3">순번</th>
      <th class="p-2">앵커멘트</th>
      <th class="p-2">상태</th>
      <th class="p-2">형식</th>
      <th class="p-2">아이템</th>
      <th class="p-2">기사</th>
      <th class="p-2">자막</th>
      <th class="p-2">V</th>
      <th class="p-2">A</th>
      <th class="p-2">담당</th>
      <th class="p-2">시간</th>
      <th class="p-2">합계</th>
      <th class="p-2">참조사항</th>
    </template>

    <template #list="{ row: row, index }">
      <CueSheetDetailListItem :cuesheet-detail="row" :index="index" />
    </template>
  </GTable>
</template>

<script>
import { defineComponent } from 'vue';
import { GTable } from '@/components/ui';
import CueSheetDetailListItem from './CueSheetDetailListItem';

export default defineComponent({
  name: 'CueSheetDetailSBList',
  components: {
    GTable,
    CueSheetDetailListItem,
  },
  props: {
    cueSheetDetailSbList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  emits: ['is-finished-drag'],
  setup(props, { emit }) {
    const isDragging = () => {
      console.log('????');
    };

    const finishedDrag = params => {
      console.log('zzz', params);
      emit('is-finished-drag', params);
    };
    return {
      isDragging,
      finishedDrag,
    };
  },
});
</script>

<style scoped></style>
