
import { computed, defineComponent, PropType, ref } from 'vue';
import { capitalize } from '@/utils/str';
import GSpinner from './GSpinner.vue';
import GConfirmModal from './GConfirmModal.vue';
import { IconType } from '@/types/ui';
import {
  ArrowDownTrayIcon,
  CheckIcon,
  XMarkIcon,
  DocumentMagnifyingGlassIcon,
} from '@heroicons/vue/24/outline';

export default defineComponent({
  name: 'GSaveButton',
  components: {
    GSpinner,
    ArrowDownTrayIcon,
    CheckIcon,
    XMarkIcon,
    GConfirmModal,
    DocumentMagnifyingGlassIcon,
  },
  props: {
    type: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String as PropType<IconType>,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'bg-primary',
    },
    tooltip: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    text: {
      type: String,
    },
    textColor: {
      type: String,
      default: '',
    },
    confirmData: {
      type: Object,
      default: null,
    },
  },
  emits: [],

  setup(props) {
    const isShowModal = ref(false);

    const setCls = computed(() => {
      let cls: string;

      if (props.icon) {
        cls = 'p-1.5';
      } else {
        cls = 'px-6 py-1.5';
      }

      if (props.type === 'primary') {
        cls =
          cls +
          ' bg-primary outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary hover:bg-primary-darkest ';
        cls += props.textColor ? props.textColor : 'text-white';
      } else if (props.type === 'secondary') {
        cls =
          cls +
          ' bg-white hover:bg-gray-200  border border-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-primary shadow-sm ';
        cls += props.textColor ? props.textColor : 'text-gray-600';
      }

      if (props.disabled) {
        cls = cls + ' opacity-50 hover:opacity-50 cursor-not-allowed';
      }

      cls = cls + ` ${props.width}`;

      return cls;
    });

    const iconClass = computed(() => {
      let iconSize = 5;
      return `w-${iconSize} h-${iconSize}`;
    });

    // 아이콘 컴포넌트 동적 생성
    const iconComponent = computed(() => {
      return `${capitalize(props.icon)}Icon`;
    });

    return {
      isShowModal,
      iconComponent,
      iconClass,
      setCls,
    };
  },
});
