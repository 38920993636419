import { Module } from 'vuex';
import { RootState } from '..';

export type ReferencesState = {
  references: any[];
};

interface ReferenceType {
  articleType: 'myArticle' | 'myRegion';
  articleId: number | string;
  refTitle: string;
  refContent: string;
}

export const ReferenceModule: Module<ReferencesState, RootState> = {
  namespaced: true,
  state: () => ({
    references: [],
  }),
  getters: {
    findReference: state => (articleId: number | string) => {
      return state.references.find(item => {
        return Number(item.articleId) === Number(articleId);
      });
    },
  },
  actions: {
    ADD_REFERENCE({ commit }, payload) {
      commit('SET_REFERENCE', payload);
    },
    DELETE_REFERENCE({ commit }, payload) {
      commit('DELETE_REFERENCE', payload);
    },
  },
  mutations: {
    SET_REFERENCE(state, reference: ReferenceType) {
      state.references.push(reference);
    },
    DELETE_REFERENCE(state, reference: ReferenceType) {
      const deleteIndex = state.references.findIndex(item => {
        return (
          Number(item.articleId) === Number(reference.articleId) &&
          item.articleType === reference.articleType
        );
      });
      state.references.splice(deleteIndex, 1);
    },
  },
};
