import store from '@/store';
import router from '@/router';
import { RouterName } from '@/types/router';

/**
 * 기사 작성
 */
const createArticle = () => {
  const nextSequence = store.getters['articleWrite/nextSequence'];
  const articleOpenInfo = {
    openMode: 'new',
    id: nextSequence,
    sequence: parseInt(nextSequence),
  };

  store.dispatch('articleWrite/ADD_ARTICLE', articleOpenInfo);
  router.push({
    name: RouterName.CreateArticle,
    params: {
      sequence: articleOpenInfo.sequence,
    },
  });
};

/**
 * 기사 수정
 * @param {Number} articleId
 * @param {string} title
 */
const editArticle = (articleId, title) => {
  const articleOpenInfo = {
    openMode: 'edit',
    id: parseInt(articleId),
  };

  store.dispatch('articleWrite/EDIT_ARTICLE', articleOpenInfo);
  router.replace({
    name: RouterName.EditArticle,
    params: {
      id: articleId,
    },
    query: {
      title,
    },
  });
};

/**
 * 기사 보기
 * @param {Number} articleId - 기사 ID
 * @param {string} title - 기사 제목
 * @param {string} target - 내기사: my-article , 내지역사: my-region, 지역사: area, 서울: seoul
 */
const viewArticle = (articleId, title, target) => {
  router.replace({
    name: RouterName.ViewArticle,
    params: {
      id: articleId,
      target,
    },
    query: {
      target,
      title,
    },
  });
};

/**
 * 큐시트 상세
 * @param {Number} cuesheetId 큐시트 아이디
 * @param {string} brdcStartTime 방송 시간
 * @param {string} mode 뷰모드 or 수정모드
 * @param {string} title 탭에 보여질 제목
 */
const goShowCueSheetDetail = (cuesheetId, brdcStartTime, mode, title) => {
  const cueSheetOpenInfo = {
    openMode: 'new',
    id: cuesheetId,
  };
  store.commit('cueSheetWrite/ADD_CUESHEET', cueSheetOpenInfo);
  router.push({
    name: RouterName.ShowCueSheetDetail,
    params: {
      id: cueSheetOpenInfo.id,
    },
    query: {
      startTime: brdcStartTime?.substring(0, 5),
      mode,
      title,
    },
  });
};

/**
 * 지역사 큐시트 상세
 * @param {Number} cuesheetId
 * @param {string} title 탭에 보여질 제목
 */
const goShowAreaCueSheetDetail = (cuesheetId, title) => {
  const cueSheetOpenInfo = {
    openMode: 'new',
    id: cuesheetId,
  };

  // store.commit('articleWrite/ADD_ARTICLE', cueSheetOpenInfo);
  router.push({
    name: RouterName.ShowAreaCueSheetDetail,
    params: {
      id: cueSheetOpenInfo.id,
    },
    query: {
      title,
    },
  });
};

/**
 * 서울 MBC 큐시트 상세
 * @param {string} cuesheetId
 * @param {string} title 탭에 보여질 제목
 */
const goShowSeoulCueSheetDetail = (cuesheetId, title) => {
  const cueSheetOpenInfo = {
    openMode: 'new',
    id: cuesheetId,
  };

  // store.commit('articleWrite/ADD_ARTICLE', cueSheetOpenInfo);
  router.push({
    name: RouterName.ShowSeoulMBCCueSheetDetail,
    params: {
      id: cueSheetOpenInfo.id,
    },
    query: {
      title,
    },
  });
};

/**
 * 큐시트 템플릿 상세
 * @param {Number} cueTmpltId
 */
const goShowCueSheetTmpltDetail = cueTmpltId => {
  const templateOpenInfo = {
    openMode: 'new',
    id: cueTmpltId,
  };

  // store.commit('articleWrite/ADD_ARTICLE', cueSheetOpenInfo);
  router.push({
    name: RouterName.ShowCueSheetTemplateDetail,
    params: {
      id: templateOpenInfo.id,
    },
  });
};

/**
 * 게시판 상세 view
 * @param {Object} board
 */
const goShowBoardDetail = board => {
  const boardOpenInfo = {
    openMode: 'new',
    id: board.bbmId,
    bbId: board.bbId,
  };

  // store.commit('articleWrite/ADD_ARTICLE', cueSheetOpenInfo);
  router.push({
    name: RouterName.ShowBoardDetail,
    params: {
      id: boardOpenInfo.id,
      subId: board.bbId,
    },
  });
};

/**
 * 게시판 상세 edit
 * @param {Object} board
 */
const goEditBoardDetail = board => {
  const boardOpenInfo = {
    openMode: 'new',
    id: board.bbmId,
    bbId: board.bbId,
  };

  // store.commit('articleWrite/ADD_ARTICLE', cueSheetOpenInfo);
  router.push({
    name: RouterName.EditBoardDetail,
    params: {
      id: boardOpenInfo.id,
      subId: board.bbId,
    },
  });
};

/**
 * 공지사항 상세
 * @param {Number} noticeId
 */
const goShowNoticeDetail = noticeId => {
  const noticeOpenInfo = {
    openMode: 'new',
    id: noticeId,
  };

  // store.commit('articleWrite/ADD_ARTICLE', cueSheetOpenInfo);
  router.push({
    name: RouterName.ShowNoticeDetail,
    params: {
      id: noticeOpenInfo.id,
    },
  });
};

/**
 * 공지사항 상세 edit
 * @param {Object} notiId
 */
const goEditNoticeDetail = notiId => {
  const noticeOpenInfo = {
    openMode: 'new',
    id: notiId,
  };

  // store.commit('articleWrite/ADD_ARTICLE', cueSheetOpenInfo);
  router.push({
    name: RouterName.EditNoticeDetail,
    params: {
      id: noticeOpenInfo.id,
    },
  });
};

/**
 * 전국기자 게시판 상세
 * @param {Number} journBbId
 */
const goShowJournalistDetail = journBbId => {
  const journalistOpenInfo = {
    openMode: 'new',
    id: journBbId,
  };
  console.log(
    'RouterName.goShowJournalistDetail',
    RouterName.ShowJournalistDetail,
  );

  // store.commit('articleWrite/ADD_ARTICLE', cueSheetOpenInfo);
  router.push({
    name: RouterName.ShowJournalistDetail,
    params: {
      id: journalistOpenInfo.id,
    },
  });
};

/**
 * 전국기자 게시판 상세
 * @param {Number} journBbId
 */
const goEditJournalistDetail = journBbId => {
  const journalistOpenInfo = {
    openMode: 'new',
    id: journBbId,
  };

  // store.commit('articleWrite/ADD_ARTICLE', cueSheetOpenInfo);
  router.push({
    name: RouterName.EditJournalistDetail,
    params: {
      id: journalistOpenInfo.id,
    },
  });
};

export {
  createArticle,
  editArticle,
  viewArticle,
  goShowCueSheetDetail,
  goShowAreaCueSheetDetail,
  goShowSeoulCueSheetDetail,
  goShowCueSheetTmpltDetail,
  goShowNoticeDetail,
  goShowBoardDetail,
  goEditBoardDetail,
  goEditNoticeDetail,
  goShowJournalistDetail,
  goEditJournalistDetail,
};
