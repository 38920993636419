<template>
  <div></div>
</template>

<script>
import { computed, defineComponent, watch, ref } from 'vue';
import Swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'GConfirmModal',
  props: {
    confirmData: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },

  emits: ['is-confirm', 'close', 'is-denied'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const setText = ref(null);
    const setTitle = ref(null);
    const defaultConfirmButtonColor = '#2F7097'; // primary-default
    const defaultDenyButtonColor = '#dc3741'; // 빨간색

    // 버튼 타입에 따른 확인 글자 출력
    const confirmBtnText = computed(() => {
      let text = '';

      switch (props.confirmData?.confirmType) {
        case 'delete':
          text = t('GConfirmModal.삭제');
          break;
        case 'save':
          text = t('GConfirmModal.저장');
          break;
        case 'approve':
          text = '출고(기사)';
          break;
        default:
          text = t('GConfirmModal.확인');
      }

      return text;
    });

    // Swal Trigger
    const SwalHandler = () => {
      Swal.fire({
        icon: props.confirmData?.icon || 'warning',
        title: setTitle.value,
        html: setText.value, // text 영역
        allowOutsideClick: props.confirmData?.allowOutsideClick || false,
        showConfirmButton:
          props.confirmData?.showConfirmButton === false ? false : true,
        showCancelButton:
          props.confirmData?.showCancelButton === false ? false : true, // 취소버튼
        showDenyButton: props.confirmData?.showDenyButton || false, // 거절버튼
        confirmButtonText: confirmBtnText.value,
        denyButtonText: denyBtnText.value,
        cancelButtonText: '취소',
        confirmButtonColor:
          props.confirmData?.confirmButtonColor || defaultConfirmButtonColor,
        denyButtonColor:
          props.confirmData?.denyButtonColor || defaultDenyButtonColor,
        cancelButtonColor: '#d1d5db',
        showClass: {
          icon: 'none',
        },
        hideClass: {
          icon: 'none',
        },
        reverseButtons: true,
        customClass: {
          cancelButton: 'order-1',
          confirmButton: 'order-2',
          denyButton: 'order-3',
        },
      }).then(result => {
        const { isConfirmed, isDismissed, isDenied } = result;

        // 확인 버튼 클릭
        if (isConfirmed) {
          emit('is-confirm');
        }
        // 거절 버튼 클릭
        else if (isDenied) {
          emit('is-denied');
        }
        // 취소 버튼 클릭
        else if (isDismissed) {
          emit('close');
        }
      });
    };

    // 버튼 타입에 따른 거절 글자 출력
    const denyBtnText = computed(() => {
      let text = '';

      switch (props.confirmData?.confirmType) {
        case 'save':
          text = t('GConfirmModal.저장_안_함');
          break;
        case 'approve':
          text = '출고(기사+자막)';
          break;
        default:
          text = t('GConfirmModal.거절');
      }

      return text;
    });

    const confirmDataHandler = cofirmValue => {
      // 타이틀 출력
      setTitle.value = cofirmValue?.title
        ? `<span class="text-xl inline-block">${cofirmValue?.title}</span>`
        : '';

      // 타이틀 밑의 내용 출력
      let text = '';
      switch (cofirmValue?.confirmType) {
        case 'delete':
          if (cofirmValue?.item) {
            text = `<div>${t('GConfirmModal.아이템_삭제', {
              item: cofirmValue?.item,
            })}</div>`;
          } else {
            text = `<div>${t('GConfirmModal.을_를_삭제하시겠습니까')}</div>`;
          }
          break;
        case 'save':
          text = `<div>${t(
            'GConfirmModal.변경된_내용이_있습니다_저장하시겠습니까',
          )}</div>`;
          break;
        case 'sending':
          if (cofirmValue?.item) {
            text = `<div>${t('GConfirmModal.아이템_송고', {
              item: cofirmValue?.item,
            })}`;
          } else {
            text = `<div>${t('GConfirmModal.을_를_송고하시겠습니까')}</div>`;
          }
          break;
        case 'reSending':
          if (cofirmValue?.item) {
            text = `<div>${t('GConfirmModal.아이템_재송고', {
              item: cofirmValue?.item,
            })}</div><div>${t('GConfirmModal.재_송고_하시겠습니까')}</div>`;
          } else {
            text = `<div>${t(
              '은_는_이미_송고되었습니다_재_송고_하시겠습니까',
            )}</div>`;
          }
          break;
        case 'approve':
          if (cofirmValue?.item) {
            text = `${cofirmValue?.item}를 출고하시겠습니까?`;
          } else {
            text = `을/를 출고하시겠습니까?`;
          }
          break;
        case 'cancel-approve':
          if (cofirmValue?.item) {
            text = `출고된 ${cofirmValue?.item}를 취소하시겠습니까?`;
          } else {
            text = `을/를 출고 취소하시겠습니까?`;
          }
          break;
        case 'unlock':
          text = `<div class="space-y-1">
              <div>${cofirmValue?.writerNm}(${cofirmValue?.writerId})가 <span class="text-red-500 font-D2CodingBold">작성중인 ${cofirmValue?.item}</span> 입니다.</div>
              <div class="pt-2">편집 상태를 강제로 해제할 경우, 작성된 데이터가 모두 사라질 수 있습니다.</div>
              <div class="text-blue-800 font-D2CodingBold pt-4">진행하시겠습니까?</div>
            </div>`;
          break;
        case 'confirm':
          text = cofirmValue?.message;
          break;
        default:
          text = cofirmValue?.text;
      }
      setText.value = text;

      SwalHandler();
    };

    watch(
      () => props.confirmData,
      currentValue => {
        if (currentValue) {
          confirmDataHandler(currentValue);
        }
      },
      { deep: true, immediate: true },
    );

    return {
      t,
    };
  },
});
</script>
<style scoped>
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
</style>
