<template>
  <!-- 순번 -->
  <td class="px-2 py-1.5" :class="setRowStyle(cuesheetItem)">
    {{ index + 1 }}
  </td>

  <!-- 앵커멘트 -->
  <td class="px-2 py-1.5" :class="setRowStyle(cuesheetItem)" :disabled="true">
    <AnchorChip
      v-if="cuesheetItem.cueItemDivCd === cuesheetItemCode.ARTICLE.id"
      :color="setAnchorColor"
    />
  </td>

  <td class="px-2 py-1.5" :class="setRowStyle(cuesheetItem)" :disabled="true">
    <GTaskChip
      :text="setCuesheetState"
      :background-color="setBackgroundColor"
    />
  </td>

  <!-- 형식 -->
  <!-- TODO :: 형식 아이콘 정의되면 수정해야함 -->
  <td class="px-2 py-1.5" :class="setRowStyle(cuesheetItem)">
    <CuesheetFormIconContextMenu
      :grid-size="10"
      :row="cuesheetItem"
      :symbol="cuesheetItem.cueItemTypCd"
      :disabled="true"
    />
  </td>

  <!-- 시간 -->
  <td class="px-2 py-1.5" :class="setRowStyle(cuesheetItem)">
    <span>
      {{ articleTime }}
    </span>
  </td>

  <!-- 합계 -->
  <td class="px-2 py-1.5" :class="setRowStyle(cuesheetItem)">
    <span>
      {{ accumulateTime }}
    </span>
  </td>

  <!-- 제목 -->
  <td class="px-2 py-1.5" :class="setRowStyle(cuesheetItem)">
    <div v-if="cuesheetItem.cueItemDivCd === cuesheetItemCode.ITEM.id">
      <input
        v-model="cuesheetItem.cueItemTitl"
        class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-xs border-gray-200 rounded-md px-1 py-1.5 bg-white"
        :rows="2"
        :disabled="true"
      />
    </div>
    <div v-else>
      {{ cuesheetItem.cueItemTitl }}
    </div>
  </td>

  <!-- 자막여부 -->
  <td class="px-2 py-1.5" :class="setRowStyle(cuesheetItem)" :disabled="true">
    <CheckIcon v-if="cuesheetItem.capStCd === 'Y'" class="text-yellow-400" />
  </td>

  <!-- 비디오 아이콘 -->
  <td class="px-2 py-1.5" :class="setRowStyle(cuesheetItem)" :disabled="true">
    {{ cuesheetItem.videoSymbolId }}
    <GIconContextMenu
      :icon-list="icons"
      :grid-size="5"
      :row="cuesheetItem"
      :symbol="cuesheetItem.videoSymbol"
      :disabled="true"
    />
  </td>

  <!-- 오디오 아이콘 -->
  <!-- TODO :: 약물 아이콘 정의되면 수정해야함 -->
  <td class="px-2 py-1.5" :class="setRowStyle(cuesheetItem)" :disabled="true">
    {{ cuesheetItem.audioSymbolId }}
    <GIconContextMenu
      :icon-list="icons"
      :grid-size="5"
      :row="cuesheetItem"
      :symbol="cuesheetItem.audioSymbol"
      :disabled="true"
    />
  </td>

  <!-- 담당(작성자) -->
  <td class="px-2 py-1.5" :class="setRowStyle(cuesheetItem)">
    <div
      v-if="cuesheetItem.cueItemDivCd === cuesheetItemCode.ARTICLE.id"
      class="flex flex-col text-left"
    >
      <span class="sm:text-xs w-[5rem] pb-1 border-b border-gray-50">
        <div class="truncate" :title="setReporterName">
          {{ setReporterName }}
        </div>
      </span>
      <!-- <span class="sm:text-xs w-[5rem] pt-0.5">
        <div class="truncate" :title="cuesheetItem?.chrgrUserId">
          {{ cuesheetItem?.chrgrUserId }}
        </div>
      </span> -->
    </div>
  </td>

  <!-- 참조사항 -->
  <td class="px-2 py-1.5 w-24" :class="setRowStyle(cuesheetItem)">
    <textarea
      v-model="cuesheetItem.rmk"
      class="border shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-xs border-gray-200 bg-white rounded-md p-0.5"
      :rows="1"
      :disabled="true"
    />
  </td>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';
// import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
import { CheckIcon } from '@/components/ui/icon/';
import { AnchorChip } from '@/components/article/commonUI';
import { GIconContextMenu, GTaskChip } from '@/components/ui';
// import CuesheetIconMenu from '@/components/cueSheet/CuesheetIconMenu.vue';
import { cuesheetItemCode, cuesheetStateCode } from '@/codes/';
import { useModelWrapper } from '@/hooks/useModelWrapper';
import { secondToMs } from '@/utils/format';
import CuesheetFormIconContextMenu from './CuesheetFormIconContextMenu.vue';

export default defineComponent({
  name: 'CueSheetSearchDetailListItem',
  components: {
    CheckIcon,
    AnchorChip,
    // CuesheetIconMenu,
    GIconContextMenu,
    GTaskChip,
    CuesheetFormIconContextMenu,
  },

  props: {
    cuesheetDetail: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: null,
    },
    isConfirmed: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: '',
    },
    selectedRow: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },

  emits: [
    'on-edit-rank',
    'on-edit-caption',
    'on-edit-icon',
    'on-edit-form',
    'on-edit-anchor',
    'on-edit-item',
  ],

  setup(props, { emit }) {
    const { t } = useI18n();
    const audioIcon = ref([]);
    const cuesheetItem = useModelWrapper(props, emit, 'cuesheetDetail'); // 큐시트 아이템

    const isShowRankIcon = ref(false);
    const isShowFormIcon = ref(false);
    const rankIconList = ref([
      {
        url: 'bg-white',
      },
      {
        url: 'bg-red-400',
      },
      {
        url: 'bg-green-400',
      },
      {
        url: 'bg-yellow-400',
      },
      {
        url: 'bg-blue-400',
      },
    ]);

    /**
     * yellow 이런식으로 color만 props로 내리면 tailwindcss가 적용안될 때가 있어서
     * 클래스명 자체로 내려줌
     */
    const setAnchorColor = computed(() => {
      let color = '';
      // ancMentAssignYn는 앵커멘트 확정 여부
      if (props.cuesheetDetail.ancMentAssignYn === 'N') {
        color = 'bg-yellow-400';
      } else if (props.cuesheetDetail.ancMentAssignYn === 'Y') {
        color = 'bg-green-500';
      }
      return color;
    });

    /**
     * 기사 내용 시간
     */
    const articleTime = computed(() => {
      let sec = 0;
      sec =
        cuesheetItem.value.cueItemTime + cuesheetItem.value.artclExtTime || 0;
      return secondToMs(sec);
    });

    /**
     * 기사 내용 시간 합계
     */
    const accumulateTime = computed(() => {
      let sec = 0;
      sec = cuesheetItem.value.acc || 0;
      return secondToMs(sec);
    });

    const setRowStyle = row => {
      let cls = 'font-D2CodingBold';
      if (row.cueItemDivCd !== cuesheetItemCode.ARTICLE.id) {
        cls += ' text-[#ff00ff]';
      }
      return cls;
    };

    /**
     * 큐시트 상태 표시
     */
    const setCuesheetState = computed(() => {
      let status = '';

      switch (cuesheetItem.value?.brdcStCd) {
        case cuesheetStateCode.NoneConfirmed.id:
          status = '';
          break;
        case cuesheetStateCode.Confirmed.id:
          status = '';
          break;
        case cuesheetStateCode.OnAir.id:
          // status = '방송중';
          status = '';
          break;
        case cuesheetStateCode.OffAir.id:
          status = '방송완료';
          break;
      }
      return status;
    });

    const setBackgroundColor = computed(() => {
      let color = '';

      switch (cuesheetItem.value?.brdcStCd) {
        case cuesheetStateCode.NoneConfirmed.id:
          color = '';
          // color = 'bg-primary';
          break;
        case cuesheetStateCode.Confirmed.id:
          // color = 'bg-blue-400';
          color = '';
          break;
        case cuesheetStateCode.OnAir.id:
          // color = 'bg-green-400';
          color = '';
          break;
        case cuesheetStateCode.OffAir.id:
          color = 'bg-gray-400';
          break;
      }
      return color;
    });

    const setReporterName = computed(() => {
      return cuesheetItem.value?.isSameChannel
        ? cuesheetItem.value?.rptrNm
        : `${cuesheetItem.value?.orgRptrNm}(${cuesheetItem.value?.orgChNm})`;
    });

    return {
      t,
      setAnchorColor,
      audioIcon,
      rankIconList,
      isShowRankIcon,
      isShowFormIcon,
      cuesheetItemCode,
      setRowStyle,
      cuesheetItem,
      articleTime,
      accumulateTime,
      setCuesheetState,
      setBackgroundColor,
      setReporterName,
    };
  },
});
</script>
