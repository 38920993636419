import { Code, EnumEntry } from './base';

interface IProgramTypeCode extends Code {
  isUse: boolean;
}

class ProgramTypeCode extends EnumEntry<IProgramTypeCode> {
  TV: IProgramTypeCode = {
    id: '01',
    name: 'TV',
    label: 'TV',
    isUse: true,
  };

  PressProduction: IProgramTypeCode = {
    id: '02',
    name: '보도제작',
    label: '보도제작',
    isUse: false,
  };

  Radio: IProgramTypeCode = {
    id: '03',
    name: '라디오',
    label: '라디오',
    isUse: true,
  };

  values: Array<IProgramTypeCode> = [this.TV, this.PressProduction, this.Radio];

  filterValues: () => Array<IProgramTypeCode> = () => {
    let list = this.values;

    return list.filter(item => item.isUse);
  };
}

export default new ProgramTypeCode();
