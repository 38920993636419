<template>
  <GModal :changed="false" :dialog-overlay="false" @close="$emit('close')">
    <!-- title -->
    <template #title>
      <span class="text-white font-D2CodingBold">
        {{ t('Modal.Cuesheet.CuesheetInfoModal.큐시트_정보') }}
      </span>
    </template>

    <!-- 내용 -->
    <template #content>
      <div class="p-6 space-y-4">
        <!-- 뉴스구분 -->
        <div class="flex flex-col space-y-2 w-full">
          <div class="text-xs">
            {{ t('Modal.Cuesheet.CuesheetInfoModal.뉴스_구분') }}
            <span class="text-red-600">*</span>
          </div>
          <!-- 프로그램 -->
          <ProgramSelect
            v-model="cueSheet.program"
            :company-code="userInfo.companyId"
            :disabled="mode === 'view'"
            @select="onSelectedProgram"
          />
          <span class="mt-2 text-xs text-red-600">{{ errorMsg.program }}</span>
        </div>

        <!-- 큐시트 별칭 -->
        <GInput
          v-model="cueSheet.nickname"
          :label="t('Modal.Cuesheet.CuesheetInfoModal.큐시트_별칭')"
          type="text"
          name="nickname"
          :disabled="mode === 'view'"
        />

        <!-- 방송일시 -->
        <div class="flex space-x-2">
          <DatePickerEx
            v-model="cueSheet.date"
            label="방송일시"
            :disabled="mode === 'view'"
            width="w-48"
            @change="onChangeDate"
          />
          <!-- 방송 시간 -->
          <div>
            <!--            <GTimeCodeInput-->
            <!--              v-model="cueSheet.startTime"-->
            <!--              :label="t('Modal.Cuesheet.CuesheetInfoModal.방송시간')"-->
            <!--              name="startTime"-->
            <!--              time-code-type="time"-->
            <!--              placeholder="00:00:00"-->
            <!--              :disabled="mode === 'view'"-->
            <!--              @blur="onSetTime"-->
            <!--            />-->
            <GMaskInput
              v-model="cueSheet.startTime"
              :label="t('Modal.Cuesheet.CuesheetInfoModal.방송시간')"
              name="startTime"
              placeholder="00:00:00"
              :disabled="mode === 'view'"
              mask-format="##:##:##"
              @maska="onSetTime"
            />
            <span class="text-red-600 text-xs">{{ errorMsg.startTime }}</span>
          </div>
        </div>
        <!-- 편성분량 -->
        <div class="flex text-xs space-x-2 items-center">
          <!-- <GTimeCodeInput
            v-model="cueSheet.duration"
            :label="t('Modal.Cuesheet.CuesheetInfoModal.편성분량')"
            name="duration"
            time-code-type="time"
            placeholder="00:00:00"
            :disabled="mode === 'view'"
            @blur="onSetTime"
          /> -->
          <!-- <GInput
            type="number"
            :name="`duration`"
            :min="0"
            :label="t('Modal.Cuesheet.CuesheetInfoModal.편성분량')"
            :disabled="mode === 'view'"
            width="w-56"
          /> -->

          <GMaskInput
            v-model="cueSheet.duration"
            :label="t('Modal.Cuesheet.CuesheetInfoModal.편성분량')"
            name="duration"
            placeholder="편성분량"
            :disabled="mode === 'view'"
            mask-format="0"
            data-maska-tokens="0:\d:multiple"
            width="w-56"
          />
          <span class="text-red-600 text-xs">*분단위로 입력해주세요.</span>
          <!-- <span class="text-red-600 text-xs">{{ errorMsg.duration }}</span> -->
        </div>

        <!-- 담당PD -->
        <div class="flex space-x-2">
          <div class="flex flex-col space-y-2">
            <div class="text-xs">
              {{ t('Modal.Cuesheet.CuesheetInfoModal.PD1') }}
            </div>
            <WorkerSelect
              v-model="cueSheet.pd1"
              name="pd1"
              :company-code="userInfo.companyId"
              :disabled="mode === 'view'"
              width="w-56"
              :placeholder="t('Modal.Cuesheet.CuesheetInfoModal.PD1')"
            />
          </div>

          <div class="flex flex-col space-y-2">
            <div class="text-xs">
              {{ t('Modal.Cuesheet.CuesheetInfoModal.PD2') }}
            </div>
            <WorkerSelect
              v-model="cueSheet.pd2"
              name="pd2"
              :company-code="userInfo.companyId"
              :disabled="mode === 'view'"
              width="w-56"
              :placeholder="t('Modal.Cuesheet.CuesheetInfoModal.PD2')"
            />
          </div>
        </div>

        <!-- 앵커 -->
        <div class="flex space-x-2">
          <div class="flex flex-col space-y-2">
            <div class="text-xs">
              {{ t('Modal.Cuesheet.CuesheetInfoModal.ANCHOR1') }}
            </div>
            <WorkerSelect
              v-model="cueSheet.anchor1"
              name="anchor1"
              :company-code="userInfo.companyId"
              :disabled="mode === 'view'"
              width="w-56"
              :placeholder="t('Modal.Cuesheet.CuesheetInfoModal.ANCHOR1')"
            />
          </div>

          <div class="flex flex-col space-y-2">
            <div class="text-xs">
              {{ t('Modal.Cuesheet.CuesheetInfoModal.ANCHOR2') }}
            </div>
            <WorkerSelect
              v-model="cueSheet.anchor2"
              name="anchor2"
              :company-code="userInfo.companyId"
              :disabled="mode === 'view'"
              width="w-56"
              :placeholder="t('Modal.Cuesheet.CuesheetInfoModal.ANCHOR2')"
            />
          </div>
        </div>

        <GInput
          :label="t('Modal.Cuesheet.CuesheetInfoModal.비고')"
          type="text"
          name="remark"
          :disabled="mode === 'view'"
        />
        <!-- 로그인 정보로 작성자 입력 후 disabled -->
        <!-- <GInput
          :label="t('Modal.Cuesheet.CuesheetInfoModal.작성자')"
          type="text"
          name="writer"
          :disabled="true"
        /> -->
        <!-- 사용여부 -->
        <GCheckbox
          v-if="mode !== 'view'"
          v-model="useYn"
          name="useYn"
          class="hidden"
          :disabled="mode === 'view'"
          :checked="useYn"
          :label="t('Modal.Cuesheet.CuesheetInfoModal.공개여부')"
        />

        <!-- 하단 Submit 버튼 세트 -->
        <div class="sm:flex sm:flex-row-reverse pt-4 pb-2">
          <div class="space-x-3 flex items-center">
            <GButton type="secondary" @click="$emit('close')">{{
              t('GButton.취소')
            }}</GButton>
            <GButton
              v-if="mode !== 'view'"
              :loading="isSubmitting"
              :disabled="canSubmit"
              @click="onSubmit"
            >
              {{ mode === 'create' ? t('GButton.추가') : t('GButton.수정') }}
            </GButton>
          </div>
        </div>
      </div>
    </template>
  </GModal>
</template>

<script>
import {
  defineComponent,
  ref,
  onMounted,
  reactive,
  computed,
  // watch,
} from 'vue';
import {
  GModal,
  GInput,
  GButton,
  // GTimeCodeInput,
  GMaskInput,
  GCheckbox,
} from '@/components/ui';
import DatePickerEx from '@/components/common/DatePickerEx';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import { useForm } from 'vee-validate';
import yup from '@/utils/yup';
import { ProgramSelect, WorkerSelect } from '@/components/select';
import { useUserInfo } from '@/hooks/use-user-info';
import { useMarsLApi } from '@/apis/mars-l-api';
// import TimePickerEx from '@/components/common/TimePickerEx';

export default defineComponent({
  name: 'CuesheetInfoModal',
  components: {
    GModal,
    GInput,
    DatePickerEx,
    GButton,
    ProgramSelect,
    WorkerSelect,
    // TimePickerEx,
    // GTimeCodeInput,
    GMaskInput,
    GCheckbox,
  },
  props: {
    cuesheet: {
      type: Object,
      default: () => {
        return {};
      },
    },
    cuesheetId: {
      type: [String, Number],
      default: () => {
        return '';
      },
    },
    mode: {
      type: String,
      default: () => {
        return 'create';
      },
    },
    isSubmitting: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  emits: ['close', 'on-save-cuesheet'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const userInfo = useUserInfo();
    const marsLApi = useMarsLApi();
    const errorMsg = reactive({
      program: '',
      startTime: '',
      duration: '',
      brdcTime: '',
    });

    const selectType = reactive({
      pd1: {
        user: [],
        isLoading: false,
      },
      pd2: {
        user: [],
        isLoading: false,
      },
      anchor1: {
        user: [],
        isLoading: false,
      },
      anchor2: {
        user: [],
        isLoading: false,
      },
    });

    const cueSheet = ref({
      program: null,
      nickname: null,
      date: dayjs().format('YYYY-MM-DD'),
      startTime: null,
      duration: 0,
      pd1: null,
      pd2: null,
      anchor1: null,
      anchor2: null,
      remark: '',
      writer: '',
    });
    const useYn = ref(true);

    // 큐시트 정보 조회
    const getCuesheetInfo = async () => {
      try {
        // cueSheet.value = {};
        const response = await marsLApi.cueSheet.basic.findOne(
          props.cuesheetId,
        );
        if (response.success) {
          // cueSheet.value = response.data;
          setCuesheetInfo(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    /**
     * 큐시트 정보 세팅
     */
    const setCuesheetInfo = cuesheet => {
      cueSheet.value = {
        program: {
          id: cuesheet.program.id,
          name: cuesheet.program.brdcPgmNm,
          label: cuesheet.program.brdcPgmNm,
        },
        nickname: cuesheet.cueNm,
        date: cuesheet.brdcDt,
        startTime: cuesheet.brdcStartTime,
        duration: cuesheet.brdcDurtn || 0,
        pd1: {
          id: cuesheet.pd1Id,
          name: cuesheet.pd1Nm,
          label: cuesheet.pd1Nm,
        },
        pd2: {
          id: cuesheet.pd2Id,
          name: cuesheet.pd2Nm,
          label: cuesheet.pd2Nm,
        },
        anchor1: {
          id: cuesheet.anc1Id,
          name: cuesheet.anc1Nm,
          label: cuesheet.anc1Nm,
        },
        anchor2: {
          id: cuesheet.anc1Id,
          name: cuesheet.anc2Nm,
          label: cuesheet.anc2Nm,
        },
        remark: cuesheet.rmk,
      };
    };

    /**
     * 프로그램 정보에 따라 큐시트 정보 세팅
     */
    const onSelectedProgram = program => {
      if (program) {
        cueSheet.value = {
          date: cueSheet.value.date,
          program: {
            id: program.id,
            name: program.name,
            label: program.label,
          },
          startTime: program.brdcStartTime,
          duration: program.brdcDurtn || 0,
          pd1: {
            id: program.pd1Id,
            name: program.pd1Nm,
            label: program.pd1Nm,
          },
          pd2: {
            id: program.pd2Id,
            name: program.pd2Nm,
            label: program.pd2Nm,
          },
          anchor1: {
            id: program.anc1Id,
            name: program.anc1Nm,
            label: program.anc1Nm,
          },
          anchor2: {
            id: program.anc1Id,
            name: program.anc2Nm,
            label: program.anc2Nm,
          },
          remark: program.rmk,
        };
      }
    };

    const init = async () => {
      if (props.mode !== 'create') {
        getCuesheetInfo();
        // setCuesheetInfo();
      }
    };

    const validationSchema = yup.object().shape({
      program: yup
        .object()
        .required('프로그램 정보는 필수 입력 입니다.')
        .typeError('프로그램 정보는 필수 입력 입니다.'),
      // nickname: yup.string().required(),
    });

    const onChangeTime = time => {
      cueSheet.value.startTime = time;
    };

    const { handleSubmit, errors } = useForm({
      initialValues: cueSheet,
      validationSchema,
    });
    // submit 할 수 있는지 여부
    const canSubmit = computed(() => {
      // 프로그램, 부조 선택 안했을 경우
      if (!cueSheet.value.program) {
        return true;
      }
      // if (!cueSheet.value.nickname) {
      //   return true;
      // }
      // startTime, endTime이 잘못 입력된 경우
      else if (errorMsg.startTime || errorMsg.duration || errorMsg.brdcTime) {
        return true;
      }

      return false;
    });

    // 시간 설정
    const onSetTime = maska => {
      console.log('maska', maska);
      if (!maska.completed) {
        errorMsg[maska.name] = '시간을 다시 확인해주세요.';
      } else {
        errorMsg[maska.name] = '';
      }
      // GTimeCode
      // console.log('event', event);
      // console.log('name', name);
      // // 아무것도 입력을 안했을 경우
      // if (!event.target.value) {
      //   errorMsg[name] = '';
      // }
      // // 3자리일 이하일 경우 메시지 출력
      // else if (event.target.value.length < 4) {
      //   errorMsg[name] = '시간을 다시 확인해주세요.';
      // }
      // // 4자리일 경우 뒤에 00붙여주기
      // else if (event.target.value.length === 4) {
      //   cueSheet.value[name] = event.target.value + '00';
      //   errorMsg[name] = '';
      // }
      // // 5자리일 경우 메시지 출력
      // else if (event.target.value.length === 5) {
      //   errorMsg[name] = '시간을 다시 확인해주세요.';
      // } else {
      //   errorMsg[name] = '';
      // }
    };

    // watch(
    //   () => cueSheet.value?.program,
    //   newProgram => {
    //     console.log('newProgram', newProgram);
    //     setProgramInfo(newProgram);
    //   },
    //   { deep: true },
    // );

    // 방송일시 변경
    const onChangeDate = changeDate => {
      cueSheet.value.date = changeDate;
    };

    const onSubmit = handleSubmit(async values => {
      values.useYn = useYn.value; // 사용여부 추가
      values.mode = props.mode; // 모드 추가 (신규,수정)
      values.date = cueSheet.value.date;

      // 수정모드면 큐시트 id 추가
      if (props.mode === 'edit') {
        values.id = props.cuesheet.id;
      }
      emit('on-save-cuesheet', values);
    });

    onMounted(() => {
      init();
    });
    return {
      t,
      selectType,
      onSetTime,
      onSubmit,
      canSubmit,
      errorMsg,
      errors,
      cueSheet,
      userInfo,
      onChangeTime,
      useYn,
      onSelectedProgram,
      onChangeDate,
    };
  },
});
</script>

<style scoped></style>
