
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'NotFound',
  setup() {
    const { t } = useI18n();
    const message = ref(t('App.pageNotFound'));
    return { message };
  },
});
