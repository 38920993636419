<template>
  <GModal :changed="false" :dialog-overlay="false" @close="$emit('close')">
    <!-- title -->
    <template #title>
      <span class="text-white font-D2CodingBold">
        {{ t('Modal.Cuesheet.CuesheetTemplateInfoModal.템플릿_정보') }}
      </span>
    </template>

    <!-- 내용 -->
    <template #content>
      <div class="p-6 space-y-4">
        <!-- 뉴스구분 -->
        <div class="flex flex-col space-y-2 w-full">
          <div class="text-xs">
            {{ t('Modal.Cuesheet.CuesheetTemplateInfoModal.뉴스_구분') }}
            <span class="text-red-600">*</span>
          </div>
          <GSingleSelect
            v-model="template.program"
            name="program"
            track-by="id"
            label="name"
            :options="programList"
            :searchable="true"
            :allow-empty="false"
            :disabled="mode === 'view'"
            :placeholder="
              t('Modal.Cuesheet.CuesheetTemplateInfoModal.프로그램')
            "
            @update:modelValue="onChangeProgram"
          />
          <span class="mt-2 text-xs text-red-600">{{ errorMsg.program }}</span>
        </div>

        <!-- 템플릿명 -->
        <GInput
          :label="t('Modal.Cuesheet.CuesheetTemplateInfoModal.템플릿명')"
          type="text"
          name="templateNm"
          :disabled="mode === 'view'"
          required
        />

        <!-- 방송 시간 -->
        <div class="flex">
          <GTimeCodeInput
            v-model="template.brdcTime"
            :label="t('Modal.Cuesheet.CuesheetTemplateInfoModal.방송시간')"
            name="brdcTime"
            time-code-type="time"
            placeholder="00:00:00"
            :disabled="mode === 'view'"
            @blur="onSetTime"
          />
          <span class="text-red-600 text-xs">{{ errorMsg.brdcTime }}</span>
        </div>

        <!-- 편성분량 -->
        <div class="flex">
          <GTimeCodeInput
            v-model="template.totalTime"
            :label="t('Modal.Cuesheet.CuesheetTemplateInfoModal.편성분량')"
            name="totalTime"
            time-code-type="time"
            placeholder="00:00:00"
            :disabled="mode === 'view'"
            @blur="onSetTime"
          />
          <span class="text-red-600 text-xs">{{ errorMsg.totalTime }}</span>
        </div>

        <!-- 편성코드 -->
        <div class="flex space-x-2 w-full">
          <div class="w-1/2">
            <GInput
              :name="
                t('Modal.Cuesheet.CuesheetTemplateInfoModal.편성코드(주중)')
              "
              :label="
                t('Modal.Cuesheet.CuesheetTemplateInfoModal.편성코드(주중)')
              "
              :disabled="mode === 'view'"
            />
          </div>

          <div class="w-1/2">
            <GInput
              :name="
                t('Modal.Cuesheet.CuesheetTemplateInfoModal.편성코드(주말)')
              "
              :label="
                t('Modal.Cuesheet.CuesheetTemplateInfoModal.편성코드(주말)')
              "
              :disabled="mode === 'view'"
            />
          </div>
        </div>

        <!-- 담당PD -->
        <div class="flex space-x-2">
          <div class="flex flex-col space-y-2 w-[220px]">
            <div class="text-xs">
              {{ t('Modal.Cuesheet.CuesheetTemplateInfoModal.PD1') }}
            </div>
            <GSearchSelectUser
              v-model="template.pd1"
              name="pd1"
              track-by="userId"
              label="userNm"
              :placeholder="'이름/아이디를 검색하세요.'"
              :options="selectType.pd1.user"
              :disabled="mode === 'view'"
              @on-search-data="onSearchUser"
            />
          </div>

          <div class="flex flex-col space-y-2 w-[220px]">
            <div class="text-xs">
              {{ t('Modal.Cuesheet.CuesheetTemplateInfoModal.PD2') }}
            </div>
            <GSearchSelectUser
              v-model="template.pd2"
              name="pd2"
              track-by="userId"
              label="userNm"
              :placeholder="'이름/아이디를 검색하세요.'"
              :options="selectType.pd2.user"
              :disabled="mode === 'view'"
              @on-search-data="onSearchUser"
            />
          </div>
        </div>

        <!-- 앵커 -->
        <div class="flex space-x-2">
          <div class="flex flex-col space-y-2 w-[220px]">
            <div class="text-xs">
              {{ t('Modal.Cuesheet.CuesheetTemplateInfoModal.ANCHOR1') }}
            </div>
            <GSearchSelectUser
              v-model="template.anchor1"
              name="anchor1"
              track-by="userId"
              label="userNm"
              :placeholder="'이름/아이디를 검색하세요.'"
              :is-loading="selectType.anchor1.isLoading"
              :options="selectType.anchor1.user"
              :disabled="mode === 'view'"
              @on-search-data="onSearchUser"
            />
          </div>

          <div class="flex flex-col space-y-2 w-[220px]">
            <div class="text-xs">
              {{ t('Modal.Cuesheet.CuesheetTemplateInfoModal.ANCHOR2') }}
            </div>
            <GSearchSelectUser
              v-model="template.anchor2"
              name="anchor2"
              track-by="userId"
              label="userNm"
              :placeholder="'이름/아이디를 검색하세요.'"
              :is-loading="selectType.anchor2.isLoading"
              :options="selectType.anchor2.user"
              :disabled="mode === 'view'"
              @on-search-data="onSearchUser"
            />
          </div>
        </div>

        <GInput
          :label="t('Modal.Cuesheet.CuesheetTemplateInfoModal.비고')"
          type="text"
          name="remark"
          :disabled="mode === 'view'"
        />
        <GInput
          :label="t('Modal.Cuesheet.CuesheetTemplateInfoModal.작성자')"
          type="text"
          name="writer"
          :disabled="mode === 'view'"
        />

        <!-- 하단 Submit 버튼 세트 -->
        <div class="sm:flex sm:flex-row-reverse pt-4 pb-2">
          <div class="space-x-3 flex items-center">
            <GButton type="secondary" @click="$emit('close')">{{
              t('GButton.취소')
            }}</GButton>
            <GButton
              v-if="mode !== 'view'"
              :loading="isSubmitting"
              :disabled="canSubmit"
              @click="onSubmit"
            >
              {{ mode === 'create' ? t('GButton.추가') : t('GButton.수정') }}
            </GButton>
          </div>
        </div>
      </div>
    </template>
  </GModal>
</template>

<script>
import { defineComponent, ref, onMounted, reactive, computed } from 'vue';
import {
  GModal,
  GInput,
  GSingleSelect,
  GTimeCodeInput,
  GSearchSelectUser,
  GButton,
} from '@/components/ui';
// import DatePickerEx from '@/components/common/DatePickerEx';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import { useForm } from 'vee-validate';
import yup from '@/utils/yup';

export default defineComponent({
  name: 'CuesheetTemplateInfoModal',
  components: {
    GModal,
    GInput,
    GSingleSelect,
    // DatePickerEx,
    GTimeCodeInput,
    GSearchSelectUser,
    GButton,
  },
  props: {
    templates: {
      type: Object,
      default: () => {
        return {};
      },
    },
    templateId: {
      type: String,
      default: () => {
        return '';
      },
    },
    mode: {
      type: String,
      default: () => {
        return 'create';
      },
    },
  },
  emits: ['close'],
  setup(props) {
    const { t } = useI18n();

    const errorMsg = reactive({
      program: '',
      startTime: '',
      totalTime: '',
      brdcTime: '',
    });

    const selectType = reactive({
      pd1: {
        user: [],
        isLoading: false,
      },
      pd2: {
        user: [],
        isLoading: false,
      },
      anchor1: {
        user: [],
        isLoading: false,
      },
      anchor2: {
        user: [],
        isLoading: false,
      },
    });

    const template = ref({
      program: null,
      templateNm: null,
      date: dayjs().format('YYYY-MM-DD'),
      startTime: null,
      totalTime: null,
      brdcCode1: null,
      brdcCode2: null,
      pd1: null,
      pd2: null,
      anchor1: null,
      anchor2: null,
      brdcTime: null,
      remark: '',
      writer: '',
    });

    const programList = ref([]);

    // 프로그램 목록 조회
    const getPrograms = async () => {
      programList.value = [];
      // template.value.program = programList.value[0];
    };

    const init = async () => {
      getPrograms();
      if (props.mode !== 'create') {
        setCuesheetInfo();
      }
    };

    const setCuesheetInfo = () => {
      template.value.program = {
        id: props.templates.program.brdcPgmId,
        name: props.templates.program.brdcPgmNm,
      };
      template.value.templateNm = props.templates.program.brdcPgmNm;
      template.value.date = props.templates.brdcDt;
    };

    const validationSchema = yup.object().shape({
      program: yup.object().required(),
      templateNm: yup.string().required(),
    });

    const { handleSubmit, isSubmitting, errors } = useForm({
      initialValues: template,
      validationSchema,
    });
    // submit 할 수 있는지 여부
    const canSubmit = computed(() => {
      // 프로그램, 부조 선택 안했을 경우
      if (!template.value.program) {
        return true;
      }
      // startTime, endTime이 잘못 입력된 경우
      else if (errorMsg.startTime || errorMsg.totalTime || errorMsg.brdcTime) {
        return true;
      }

      return false;
    });

    // 프로그램
    const onChangeProgram = () => {};

    // 시간 설정
    const onSetTime = (event, name) => {
      // 아무것도 입력을 안했을 경우
      if (!event.target.value) {
        errorMsg[name] = '';
      }
      // 3자리일 이하일 경우 메시지 출력
      else if (event.target.value.length < 4) {
        errorMsg[name] = '시간을 다시 확인해주세요.';
      }
      // 4자리일 경우 뒤에 00붙여주기
      else if (event.target.value.length === 4) {
        template.value[name] = event.target.value + '00';
        errorMsg[name] = '';
      }
      // 5자리일 경우 메시지 출력
      else if (event.target.value.length === 5) {
        errorMsg[name] = '시간을 다시 확인해주세요.';
      } else {
        errorMsg[name] = '';
      }
    };

    // 사용자 검색
    const onSearchUser = async (searchQuery, name) => {
      console.log('searchQuery', searchQuery, name);
      //   const getSearchQuery = searchQuery;
      //   selectType[name].isLoading = true;
      //   const params = {
      //     searchWord: getSearchQuery,
      //   };
      // //   const response = await User.getUsers(params);
      //   if (response.success) {
      //     const { data } = response;
      //     selectType[name].user = data;
      //   }
      //   selectType[name].isLoading = false;
    };

    const onSubmit = handleSubmit(async values => {
      //
      console.log('submit val', values);
    });

    onMounted(() => {
      init();
    });
    return {
      t,
      programList,
      selectType,
      onSetTime,
      onChangeProgram,
      onSearchUser,
      onSubmit,
      canSubmit,
      errorMsg,
      isSubmitting,
      errors,
      template,
    };
  },
});
</script>

<style scoped></style>
