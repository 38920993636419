<template>
  <div class="flex flex-col space-y-2 items-center">
    <NoDataIcon />
    <div class="font-D2CodingBold text-xs text-gray-400">{{ message }}</div>
  </div>
</template>

<script>
import { NoDataIcon } from './icon';

export default {
  name: 'GNoData',
  components: {
    NoDataIcon,
  },
  props: {
    message: {
      type: String,
      default: '해당 데이터가 존재하지않습니다.',
    },
  },
};
</script>
