<template>
  <div>
    <GMovableModal
      bottom="90px"
      right="187px"
      height="h-[730px]"
      width="w-[433px]"
      @close="onClose"
    >
      <template #title>
        <!-- title -->
        <span class="text-white font-D2CodingBold">
          {{ t('Modal.Article.SpellCheckModal.맞춤법_검사') }}
        </span>
      </template>

      <template #content>
        <!-- 내용 -->
        <div>
          <div v-if="isLoading">
            <PageLoader />
          </div>
          <div v-if="!isLoading">
            <!-- 경고 문구 -->
            <div
              class="bg-red-50 border-t-4 border-red-300 text-red-500 font-D2CodingBold px-3 py-1 rounded-b-lg mb-2"
              role="alert"
            >
              <div class="flex items-center">
                <div class="py-1">
                  <svg
                    class="fill-current h-6 w-6 text-orange-400 mr-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
                    />
                  </svg>
                </div>
                <div>
                  <p class="text-xs font-D2CodingBold">
                    {{
                      t(
                        'Modal.Article.SpellCheckModal.맞춤법_검사는_최대_500자까지_교정되어_표시됩니다',
                      )
                    }}
                  </p>
                </div>
              </div>
            </div>

            <div class="px-3 space-y-4">
              <GTabPanel :tabs="tabs">
                <!-- 색깔 안내 -->
                <div class="flex space-x-3 text-sm justify-end py-1.5 pt-2">
                  <div class="flex space-x-2 items-center">
                    <div class="rounded-full p-1 bg-[#FF5757]" />
                    <div class="text-xs">
                      {{ t('Modal.Article.SpellCheckModal.맞춤법') }}
                    </div>
                  </div>
                  <div class="flex space-x-2 items-center">
                    <div class="rounded-full p-1 bg-[#B22AF8]" />
                    <div class="text-xs">
                      {{ t('Modal.Article.SpellCheckModal.표준어의심') }}
                    </div>
                  </div>
                  <div class="flex space-x-2 items-center">
                    <div class="rounded-full p-1 bg-[#02C73C]" />
                    <div class="text-xs">
                      {{ t('Modal.Article.SpellCheckModal.띄어쓰기') }}
                    </div>
                  </div>
                  <div class="flex space-x-2 items-center">
                    <div class="rounded-full p-1 bg-[#2FACEA]" />
                    <div class="text-xs">
                      {{ t('Modal.Article.SpellCheckModal.통계적교정') }}
                    </div>
                  </div>
                </div>
                <TabPanel>
                  <template v-slot="{ selected }">
                    <div v-if="selected" class="space-y-2">
                      <div
                        v-show="textList.length"
                        v-for="(text, index) in textList"
                        :key="index"
                      >
                        <div
                          v-if="index === textPage - 1"
                          v-html="text"
                          class="bg-white p-2 flex-1 border h-[27rem] border-gray-300 shadow-sm rounded-md overflow-auto"
                        />
                      </div>
                      <div v-show="!textList.length">
                        <div
                          class="bg-white p-2 flex-1 border h-[27rem] border-gray-300 shadow-sm rounded-md overflow-auto"
                        />
                      </div>
                      <Pagination
                        :pagination="textPageInfo"
                        @on-changed-page="onChangedPageOnText"
                      />
                    </div>
                  </template>
                </TabPanel>
                <TabPanel>
                  <template v-slot="{ selected }">
                    <div v-if="selected" class="space-y-2">
                      <div
                        v-show="capTextList.length"
                        v-for="(text, index) in capTextList"
                        :key="index"
                      >
                        <div
                          v-if="index === capPage - 1"
                          v-html="text"
                          class="bg-white p-2 flex-1 border h-[27rem] border-gray-300 shadow-sm rounded-md overflow-auto"
                        />
                      </div>
                      <div
                        v-show="!capTextList.length"
                        class="bg-white p-2 flex-1 border h-[27rem] border-gray-300 shadow-sm rounded-md overflow-auto"
                      />
                      <Pagination
                        :pagination="capPageInfo"
                        @on-changed-page="onChangedPageOnCap"
                      />
                    </div>
                  </template>
                </TabPanel>
              </GTabPanel>

              <!-- 버튼 -->
              <div class="flex justify-end">
                <GButton @click="onClose">{{
                  t('Modal.Article.SpellCheckModal.닫기')
                }}</GButton>
              </div>
            </div>
          </div>
        </div>
      </template>
    </GMovableModal>
  </div>
</template>

<script setup>
import { onMounted, defineEmits, defineProps, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMarsLApi } from '@/apis/mars-l-api';
import { GButton, GMovableModal } from '@/components/ui';
import { GTabPanel } from '@/components/ui';
import { TabPanel } from '@headlessui/vue';
import PageLoader from '@/components/common/PageLoader.vue';
import Pagination from '@/components/common/Pagination';
import { articleTypeCode } from '@/codes';

const props = defineProps({
  editorData: {
    type: Object,
  },
  mode: {
    type: String,
  },
  articleType: {
    type: String,
  },
});
const emit = defineEmits(['close']);

const { t } = useI18n();
const isLoading = ref(false);
const marsLApi = useMarsLApi();
const capTextList = ref([]);
const textList = ref([]);
const capPage = ref(1);
const textPage = ref(1);
const textPageInfo = ref({
  page: 1,
  totalCount: 0,
  totalPage: 1,
});
const capPageInfo = ref({
  page: 1,
  totalCount: 0,
  totalPage: 1,
});

const tabs = computed(() => {
  if (props.articleType === articleTypeCode.BottomRole.id) {
    return [{ id: 1, name: t('Modal.Article.SpellCheckModal.내용') }];
  }

  return [
    { id: 1, name: t('Modal.Article.SpellCheckModal.내용') },
    { id: 2, name: t('Modal.Article.SpellCheckModal.자막') },
  ];
});

/**
 *
 * @param {Array} list 500자씩 잘라서 담은 리스트
 * @param {Array} targetList api 호출 후 변환된 텍스트를 담을 리스트
 */
const callSpellCheck = async (list, targetList) => {
  for await (const text of list) {
    const params = {
      value: text,
    };
    const response = await marsLApi.spellCheck.basic.findAll(params);
    if (response.success) {
      const convertText = JSON.parse(response.data);

      if (convertText.message.error) {
        return;
      }

      targetList.push(
        convertText.message.result.html
          .replace(/\\&quot;/g, '&quot;')
          .replace(/\\n/g, '<br />'),
      );
    }
  }
};

// 맞춤법 검사 (라이브러리는 총 글자 980자 까지 사용가능 / api에서는 700자까지 허용되도록 설정됨)
const onSpellCheck = async () => {
  try {
    isLoading.value = true;
    const { anchorTextArray, reporterTextArray, reporterCaptionArray } =
      props.editorData;

    // 내용
    let totalText = '';
    for (let i = 0, len = anchorTextArray.length; i < len; i++) {
      totalText =
        totalText + '\n\n' + `${anchorTextArray[i]}\n\n${reporterTextArray[i]}`;
    }

    // 순수하게 글자만 있는가
    const pureText = totalText.replaceAll('\n', '');
    if (pureText) {
      let tempList = [];
      for (let i = 0; i < totalText.length; i += 500) {
        if (totalText.substring(i, i + 500).trim()) {
          tempList.push(totalText.substring(i, i + 500).trim());
        }
      }

      await callSpellCheck(tempList, textList.value);
      textPageInfo.value = {
        page: textPage.value,
        totalCount: textList.value.length,
        totalPage: textList.value.length,
      };
    } else {
      textList.value.push('');
    }

    // 자막
    let totalCaptionText = '';
    if (reporterCaptionArray.length) {
      for (let i = 0, len = reporterCaptionArray.length; i < len; i++) {
        totalCaptionText =
          totalCaptionText + reporterCaptionArray[i].text + '\n\n';
      }

      if (totalCaptionText) {
        let tempList = [];
        for (let i = 0; i < totalCaptionText.length; i += 500) {
          tempList.push(totalCaptionText.substring(i, i + 500));
        }

        await callSpellCheck(tempList, capTextList.value);
        capPageInfo.value = {
          page: capPage.value,
          totalCount: capTextList.value.length,
          totalPage: capTextList.value.length,
        };
      }
    } else {
      capTextList.value.push('');
    }
  } catch (error) {
    console.error('error', error);
  } finally {
    isLoading.value = false;
  }
};

const onChangedPageOnText = page => {
  textPage.value = page;
  textPageInfo.value.page = page;
};

const onChangedPageOnCap = page => {
  capPage.value = page;
  capPageInfo.value.page = page;
};

const onClose = () => {
  emit('close');
};

onMounted(() => {
  onSpellCheck();
});
</script>
