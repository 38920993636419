import { Tab } from '@/types/models';
import { Module } from 'vuex';
import { RootState } from '..';
import i18n from '@/i18n';
import router from '@/router';
import { RouterName } from '@/types/router';
import { editArticle, viewArticle } from '../../utils/change-router.js';
import { useSweetAlert } from '@/hooks/use-sweet-alert';
import store from '@/store';

export type TabState = {
  tabs: Tab[];
};

const routerPush = (routeData: any) => {
  router.push({
    name: routeData?.name,
    params: {
      sequence: routeData?.sequence,
      id: routeData?.id,
    },
  });
};

const findTab = (state: any, routerName: string) => {
  return state.tabs.find((tab: any) => tab.name === routerName);
};

/**
 * 기사 수정/보기 모드 탭을 닫으면 기사 목록 화면으로 이동
 * 큐시트 상세 탭을 닫으면 큐시트 목록 화면으로 이동
 * @param state vuex state
 * @param routerName 탭을 닫았을 때 이동할 라우터 이름 (기사 목록 화면 또는 큐시트 목록 화면)
 * @param deleteTabIdx 삭제한 탭의 인덱스
 */
const routerPushProcess = (
  state: any,
  routerName: string,
  deleteTabIdx: number,
) => {
  const targetFindTab = findTab(state, routerName);

  if (targetFindTab) {
    routerPush({ name: targetFindTab.name, params: { id: null } });
  } else {
    // 이전 탭이 있으면 이전 탭으로
    if (state.tabs[deleteTabIdx - 1]) {
      routerPush(state.tabs[deleteTabIdx - 1]);
    } else {
      if (state.tabs[deleteTabIdx]) {
        routerPush(state.tabs[deleteTabIdx]);
      }
    }
  }
};

export const TabModule: Module<TabState, RootState> = {
  namespaced: true,
  state: () => ({
    tabs: [],
  }),
  getters: {
    activeTab: state => {
      return state.tabs.find(tab => tab.active);
    },
    tabs: state => {
      return state.tabs;
    },
  },
  actions: {
    ADD_TAB({ commit }, payload) {
      commit('ADD_TAB', payload);
    },
    DELETE_TAB({ commit }, payload) {
      const { swalConfirm } = useSweetAlert();

      // 탭으로 닫기를 클릭했는지 여부
      payload.clickedTabClose = true;

      // 탭의 내용이 변경되었다면 알림창 띄운다.
      if (!payload.canClose) {
        if (payload.name === RouterName.CreateArticle) {
          swalConfirm({
            title:
              '<div><div>변경된 데이터가 있습니다.</div><div>저장하시겠습니까?</div></div>',
          }).then(response => {
            if (response === 'confirm') {
              window.EventBus.emit(
                `create-article-from-close-tab-${payload.sequence}`,
              );
            } else {
              payload.canClose = true;
              // 기사 락 해제
              store.dispatch('unLock/UNLOCK_ARTICLE', payload.id);
              commit('DELETE_TAB', payload);
            }
          });
        } else if (payload.name === RouterName.EditArticle) {
          swalConfirm({
            title:
              '<div><div>변경된 데이터가 있습니다.</div><div>저장하시겠습니까?</div></div>',
          }).then(response => {
            if (response === 'confirm') {
              window.EventBus.emit(`edit-article-from-close-tab-${payload.id}`);
            } else {
              payload.canClose = true;
              // 기사 락 해제
              store.dispatch('unLock/UNLOCK_ARTICLE', payload.id);
              commit('DELETE_TAB', payload);
            }
          });
        }
        return;
      }

      if (payload.name.includes(RouterName.ShowCueSheetDetail)) {
        window.EventBus.emit('close-tab-cuesheet');
      } else if (payload.name.includes(RouterName.EditArticle)) {
        // 기사 락 해제
        store.dispatch('unLock/UNLOCK_ARTICLE', payload.id);
      }

      commit('DELETE_TAB', payload);
    },
    DELETE_ALL_TAB({ commit }) {
      commit('DELETE_ALL_TAB');
    },
    ACTIVE_TAB({ commit }, payload) {
      commit('ACTIVE_TAB', payload);
    },
    ADD_META_TAB({ commit }, payload) {
      commit('ADD_META_TAB', payload);
    },
    SET_TAB_MOUNTED({ commit }, payload) {
      commit('SET_TAB_MOUNTED', payload);
    },
    SET_TAB_QUERY({ commit }, payload) {
      commit('SET_TAB_QUERY', payload);
    },
    CHANGE_TAB_NAME({ commit }, payload) {
      commit('CHANGE_TAB_NAME', payload);
    },
    CHANGE_TAB_MODE({ commit }, payload) {
      commit('CHANGE_TAB_MODE', payload);
    },
  },
  mutations: {
    ADD_TAB(state, newTab) {
      // 로그인일 경우 탭 생성되지 않는다.
      if (newTab.name === RouterName.Login) {
        return;
      }
      // 이미 탭이 있는 경우 생성 X
      let tabExists = false;

      if (newTab.name === RouterName.CreateArticle) {
        tabExists = state.tabs.some(tab => {
          return parseInt(newTab.params.sequence) === tab.sequence;
        });
      } else if (
        newTab.name === RouterName.EditArticle ||
        newTab.name === RouterName.ViewArticle ||
        newTab.name === RouterName.ShowCueSheetDetail ||
        newTab.name === RouterName.ShowAreaCueSheetDetail ||
        newTab.name === RouterName.ShowSeoulMBCCueSheetDetail ||
        newTab.name === RouterName.ShowCueSheetTemplateDetail ||
        newTab.name === RouterName.ShowNoticeDetail ||
        newTab.name === RouterName.ShowBoardDetail
      ) {
        tabExists = state.tabs.some(tab => {
          return newTab.params.id == tab.id;
        });
      } else {
        tabExists = state.tabs.some(tab => tab.name === newTab.name);
      }

      if (tabExists) {
        return;
      }

      let title = null;
      let sequence = null;

      if (
        newTab.params &&
        Object.keys(newTab.params).length &&
        newTab.params.sequence
      ) {
        sequence = parseInt(newTab.params.sequence);
      }

      // 신규 기사일 경우 시퀀스 값을 타이틀에 넣어준다.
      if (newTab.name === RouterName.CreateArticle) {
        title = i18n.global.t(`Tab.${newTab.name}`, {
          seq: newTab.params.sequence,
        });
      } else if (
        /**
         * 기사 수정/보기, 큐시트, 지역사 큐시트, 서울 큐시트, 게시판 등 상세 페이지일 경우 id값 입력
         */
        newTab.name === RouterName.EditArticle ||
        newTab.name === RouterName.ViewArticle ||
        newTab.name === RouterName.ShowCueSheetDetail ||
        newTab.name === RouterName.ShowAreaCueSheetDetail ||
        newTab.name === RouterName.ShowSeoulMBCCueSheetDetail ||
        newTab.name === RouterName.ShowCueSheetTemplateDetail ||
        newTab.name === RouterName.ShowBoardDetail ||
        newTab.name === RouterName.EditBoardDetail ||
        newTab.name === RouterName.ShowNoticeDetail
      ) {
        title = i18n.global.t(`Tab.${newTab.name}`, {
          title: newTab.query.title,
        });
      } else {
        title = i18n.global.t(`Tab.${newTab.name}`);
      }

      state.tabs.push({
        name: newTab.name,
        title,
        icon: newTab.icon,
        canClose: true,
        active: true,
        sequence,
        id: newTab.params ? newTab.params.id : null,
        subId: newTab.params.subId || null,
        path: newTab.path,
        query: newTab.query,
        clickedTabClose: false,
        route: newTab.route,
      });
    },
    DELETE_TAB(state, tab) {
      const { name, sequence, id } = tab;

      let deleteTab: any = null;
      let deleteTabIdx: any = null;

      state.tabs.forEach((tabItem, index) => {
        if (name === RouterName.CreateArticle) {
          if (tabItem.sequence === sequence) {
            deleteTab = tabItem;
            deleteTabIdx = index;
          }
        } else if (
          name === RouterName.EditArticle ||
          name === RouterName.ViewArticle ||
          name === RouterName.ShowCueSheetDetail ||
          name === RouterName.ShowAreaCueSheetDetail ||
          name === RouterName.ShowSeoulMBCCueSheetDetail
        ) {
          if (tabItem.id == id) {
            deleteTab = tabItem;
            deleteTabIdx = index;
          }
        } else {
          if (tabItem.name === name) {
            deleteTab = tabItem;
            deleteTabIdx = index;
          }
        }
      });

      // 탭 삭제
      state.tabs.splice(deleteTabIdx, 1);

      /* 삭제한 탭이 비활성화 탭이었다면 라우터 이동 X
         삭제한 탭이 활성화 탭이었다.
            1. 활성화된 탭이 기사 보기/수정 모드였다 -> 기사 목록 탭이 있다 -> 기사 목록탭으로 이동
                                                                   없다 -> 이전 탭으로 이동
            2. 이전 탭 있다. -> 이전 탭 라우터 이동
            3. 이전 탭 없다. -> 다음 탭 라우터 이동
      */
      if (deleteTab.active) {
        let changeRouteName = '';

        // 기사 수정 모드일 때
        if (deleteTab.name === RouterName.EditArticle) {
          changeRouteName = RouterName.MyRegionArticle;
        }
        // 기사 보기 모드일 때
        else if (deleteTab.name === RouterName.ViewArticle) {
          // 내지역 기사일 경우
          if (deleteTab.query.target === 'my-region') {
            changeRouteName = RouterName.MyRegionArticle;
          }
          // 다른지역 기사일 경우
          else if (deleteTab.query.target === 'area') {
            changeRouteName = RouterName.AreaArticle;
          }
          // 서울 기사일 경우
          else if (deleteTab.query.target === 'seoul') {
            changeRouteName = RouterName.SeoulMBCArticle;
          }
        }
        // 내 지역 큐시트 상세일 때
        else if (deleteTab.name === RouterName.ShowCueSheetDetail) {
          changeRouteName = RouterName.CueSheet;
        }
        // 다른 지역 큐시트 상세일 때
        else if (deleteTab.name === RouterName.ShowAreaCueSheetDetail) {
          changeRouteName = RouterName.AreaCueSheet;
        }
        // 서울 큐시트 상세일 때
        else if (deleteTab.name === RouterName.ShowSeoulMBCCueSheetDetail) {
          changeRouteName = RouterName.SeoulMBCCueSheet;
        }
        // 그 외
        else {
          if (state.tabs[deleteTabIdx - 1]) {
            routerPush(state.tabs[deleteTabIdx - 1]);
          } else {
            if (state.tabs[deleteTabIdx]) {
              routerPush(state.tabs[deleteTabIdx]);
            }
          }
          return;
        }

        routerPushProcess(state, changeRouteName, deleteTabIdx);
      }
    },
    DELETE_ALL_TAB(state) {
      state.tabs = [];
    },
    ADD_META_TAB(state, componentDatas) {
      const { name, meta, changed } = componentDatas;
      const findTab: any = state.tabs.find(tab => tab.name === name);

      if (findTab) {
        findTab['meta'] = meta;
        findTab['changed'] = changed;
      }
    },
    SET_TAB_MOUNTED(state, { tabName, onTabClosed }) {
      const findTab: any = state.tabs.find(tab => tab.name === tabName);

      if (findTab) {
        findTab.mounted = true;
        findTab.onTabClosed = onTabClosed;
      }
    },
    SET_TAB_QUERY(state, { tabName, query }) {
      const findTab: any = state.tabs.find(tab => tab.name === tabName);

      if (findTab && findTab?.query) {
        findTab.query = query;
      }
    },
    ACTIVE_TAB(state, tab) {
      const { name, params } = tab;

      state.tabs.forEach(tab => {
        if (name === RouterName.CreateArticle) {
          if (parseInt(params.sequence) !== tab.sequence) {
            tab.active = false;
          } else {
            tab.active = true;
          }
        } else if (
          name === RouterName.EditArticle ||
          name === RouterName.ViewArticle ||
          name === RouterName.ShowBoardDetail ||
          name === RouterName.ShowCueSheetDetail ||
          name === RouterName.ShowAreaCueSheetDetail ||
          name === RouterName.ShowSeoulMBCCueSheetDetail ||
          name === RouterName.ShowCueSheetTemplateDetail ||
          name === RouterName.EditBoardDetail ||
          name === RouterName.ShowNoticeDetail
        ) {
          if (params.id != tab.id) {
            tab.active = false;
          } else {
            tab.active = true;
          }
        } else {
          if (name !== tab.name) {
            tab.active = false;
          } else {
            tab.active = true;
          }
        }
      });
    },
    CHANGE_TAB_NAME(state, payload) {
      const changeTab = state.tabs.find(tab => tab.id == payload.tab.id);
      if (changeTab) {
        changeTab.query = {
          title: payload.title,
        };
        changeTab.title = i18n.global.t(`Tab.${changeTab.name}`, {
          title: payload.title,
        });
      }
    },
    CHANGE_TAB_MODE(state, payload) {
      const { to, mode, currentTab, title } = payload;

      if (to === 'create') {
        const changeTab = state.tabs.find(
          tab => tab.sequence == currentTab.sequence,
        );

        if (changeTab) {
          changeTab.name = RouterName.EditArticle;
          changeTab.id = payload.articleId;
          changeTab.path = currentTab.path.replace(
            payload.sequence,
            payload.articleId,
          );
          changeTab.path = currentTab.path.replace('create', 'edit');
          changeTab.title = i18n.global.t(`Tab.${changeTab.name}`, {
            title: payload.title,
          });

          editArticle(payload.articleId, title);
        }
      } else {
        if (mode === 'edit') {
          const changeTab = state.tabs.find(tab => tab.id == currentTab.id);
          editArticle(currentTab.id, title);

          if (changeTab) {
            changeTab.name = RouterName.EditArticle;
            changeTab.path = currentTab.path.replace('view', 'edit');
            changeTab.title = i18n.global.t(`Tab.${changeTab.name}`, {
              title: payload.title,
            });
          }
        } else if (mode === 'view') {
          const changeTab = state.tabs.find(tab => tab.id == currentTab.id);
          viewArticle(currentTab.id, payload.title, payload.target);

          if (changeTab) {
            changeTab.name = RouterName.ViewArticle;
            changeTab.path = currentTab.path.replace('edit', 'view');
            changeTab.title = i18n.global.t(`Tab.${changeTab.name}`, {
              title: payload.title,
            });
          }
        }
      }
    },
  },
};
