<template>
  <div class="space-y-3 pb-2">
    <div ref="tableWrapperRef" class="overflow-auto" :class="height">
      <div class="select-none" :class="sticky">
        <table
          id="g_table"
          class="min-w-full divide-y divide-gray-200 text-xs whitespace-nowrap"
        >
          <thead
            id="table-header"
            class="text-left leading-4 bg-white uppercase tracking-wider z-20 font-D2CodingBold"
            :class="sticky ? 'sticky top-0' : ''"
          >
            <tr class="">
              <th v-if="draggable" scope="col" />
              <th v-if="multiselect" scope="col">
                <input
                  v-model="allSelected"
                  type="checkbox"
                  class="form-checkbox cursor-pointer focus:outline-none focus:shadow-outline"
                  @change="$emit('on-selected')"
                />
              </th>
              <slot name="header" />
            </tr>
          </thead>
          <draggable
            :id="id"
            v-model="rows"
            v-bind="dragOptions"
            tag="tbody"
            item-key="id"
            class="bg-white"
            :move="onMove"
            @start="isDragging(true, $event)"
            @end="isFinishedDrag"
            @change="onPositionChange($event)"
          >
            <template #header>
              <tr v-if="isLoading">
                <td :colspan="columnCount">
                  <PageLoader />
                </td>
              </tr>
              <tr v-else-if="list?.length === 0">
                <td :colspan="columnCount">
                  <GNoData :message="noDataMessage" />
                </td>
              </tr>
            </template>
            <template #item="{ element: row, index }">
              <tr
                v-if="!isLoading"
                class="draggable border-b"
                :class="[
                  isDisabledRow(index) ||
                  isDisabledRow(index) === 0 ||
                  selectedIdx === index
                    ? selectedBackgroundColor
                    : '',
                  setRowBackgroundColor(row),
                ]"
                @click="onRowClicked($event, row, index)"
                @dblclick="onRowDbClicked($event, row, index)"
                @mouseup="onRowMouseUp($event, row, index)"
              >
                <td
                  v-if="draggable"
                  class="handle-area w-10"
                  :class="[setDisabledDraggable(index)]"
                >
                  <DragIcon v-if="setDisabledDraggable(index) !== ''" />
                </td>
                <td v-if="multiselect" class="">
                  <input
                    type="checkbox"
                    class="form-checkbox cursor-pointer focus:outline-none focus:shadow-outline"
                    :checked="row.selected"
                    :class="
                      isDisabledRow(index) || isDisabledRow(index) === 0
                        ? 'hidden'
                        : ''
                    "
                    @change="onSelected($event, index)"
                    @click.shift="onSelectedWithShift($event, index)"
                  />
                </td>
                <slot name="list" :row="row" :index="index" />
              </tr>
            </template>
          </draggable>
        </table>
      </div>
    </div>

    <!-- 페이징 -->
    <div v-if="!isLoading" class="pr-1">
      <Pagination
        v-if="pagination?.isShow"
        :pagination="pageInfo"
        @on-changed-page="onChangedPage"
      />
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  ref,
  computed,
  onMounted,
  watch,
} from '@vue/runtime-core';
import draggable from 'vuedraggable';
import { DragIcon } from './icon';
import GNoData from './GNoData';
import PageLoader from '@/components/common/PageLoader.vue';
import i18n from '@/i18n';
import renderComponent from '@/utils/renderComponent.js';
import Pagination from '@/components/common/Pagination';
import { useScrollTop } from '@/hooks/use-scroll.ts';
import { setOrder } from '@/utils/pagination.ts';

export default defineComponent({
  name: 'GTable',
  components: {
    draggable,
    GNoData,
    DragIcon,
    PageLoader,
    Pagination,
  },
  props: {
    multiselect: {
      type: Boolean,
      default: false,
    },
    list: {
      type: [Object, Array],
      required: true,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    noDataMessage: {
      type: String,
      default: i18n.global.t('GTable.데이터가_없습니다'),
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    disabledRows: {
      type: Array,
      default: () => {
        return [];
      },
    },
    selectableRows: {
      type: Array,
      default: () => {
        return [];
      },
    },
    sticky: {
      type: Boolean,
      default: true,
    },
    disabledDraggable: {
      type: Array,
      default: () => {
        return [];
      },
    },
    firstDefaultRow: {
      type: Boolean,
      default: true,
    },
    group: {
      type: Object,
      default: () => {
        return null;
      },
    },
    id: {
      type: String,
      default: '',
    },
    sort: {
      type: Boolean,
      default: true,
    },
    selectedBackgroundColor: {
      type: String,
      default: 'bg-gray-100',
    },
    pagination: {
      type: Object,
      default: () => {
        return {
          isShow: true,
          page: 1,
          totalElements: 0,
          totalPages: 1,
        };
      },
    },
    height: {
      type: String,
      default: '',
    },
    selectedRowIndex: {
      type: Number,
    },
  },
  emits: [
    'on-modal-clicked',
    'on-delete-clicked',
    'on-selected',
    'on-position-changed',
    'on-row-clicked',
    'on-row-db-clicked',
    'on-context-menu',
    'is-dragging',
    'is-finished-drag',
    'on-row-mouse-up',
    'on-changed-page',
    'table-scroll-top',
  ],

  setup(props, { emit }) {
    const { appContext } = getCurrentInstance();
    let destroyIconComp = null;

    const rows = ref([]);
    const originRows = ref([]);
    const prevIndex = ref(-1);
    const maxIndex = ref(-1);
    const minIndex = ref(-1);
    const columnCount = ref(0);
    const selectedIdx = ref();
    const dragOptions = {
      animation: 200,
      disabled: false,
      ghostClass: 'ghost',
      draggable: '.draggable', // 드래그드랍 가능하도록 함
      handle: '.handle-area', // 드래그 선택 가능 영역
      scroll: true,
      scrollSensitivity: 200, // 스크롤 감도
      // forceFallback: true, // HTML5 Drage & Drop 동작을 무시하고 대체를 강제로 시작합니다. ( false : 자동 스크롤 기능을 방해 ) , html이 안되서 열이 흐트러짐
      group: props.group,
      sort: props.sort,
    };
    const tableWrapperRef = ref(null);

    const headerEl = computed(() => {
      return document.getElementById('table-header');
    });

    const tableEl = computed(() => {
      return document.getElementById('g_table');
    });

    // 테이블 데이터 감지
    watch(
      () => props.list,
      newVal => {
        rows.value = newVal.map((item, index) => {
          return {
            ...item,
            order: setOrder(props.pagination.page, index),
          };
        });
        originRows.value = newVal;

        createResizableTable(tableEl.value);
        // 첫 번째 행 기본적으로 선택
        if (props.selectedRowIndex !== null) {
          selectedIdx.value = props.selectedRowIndex;
          emit('on-row-clicked', {
            row: newVal[props.selectedRowIndex],
            index: props.selectedRowIndex,
          });
        }
      },
    );

    // 선택한 row 감지
    watch(
      () => props.selectedRowIndex,
      () => {
        // 첫 번째 행 기본적으로 선택
        if (props.selectedRowIndex !== null) {
          selectedIdx.value = props.selectedRowIndex;
        }
      },
    );

    const isSelectableRow = rowIndex => {
      // selectableRows 배열에서 현재 row의 index가 존재하면
      // 현재 row의 check box는 hidden 하는 row이다.
      return props.selectableRows.find(row => row === rowIndex);
    };

    const allSelected = computed({
      get() {
        // 모든 row가 hidden 일 때
        let cntUndefinedSelected = 0;
        for (let i = 0; i < rows.value.length; i += 1) {
          // check box hidden 되어있는 row는 undefined
          // hidden 되어있지 않고 selected도 되어있지 않으면 return false
          if (!rows.value[i].selected && isSelectableRow(i) === undefined) {
            return false;
          }

          if (!rows.value[i].selected) {
            cntUndefinedSelected += 1;
          }
        }
        // 모든 row가 hidden 일 때
        if (cntUndefinedSelected === rows.value.length) {
          return false;
        }
        return true;
      },
      set(v) {
        for (let i = 0; i < rows.value.length; i += 1) {
          if (isSelectableRow(i) === undefined) {
            rows.value[i].selected = v;
          }
        }
      },
    });

    const isDisabledRow = rowIndex => {
      // disabledRows 배열에서 현재 row의 index가 존재하면
      // 현재 row는 disable(bg-gray-100) 해야 하는 row이다.
      return props.disabledRows.find(row => row === rowIndex);
    };

    /**
     * 큐시트에서만 쓰임(임시)
     */
    const setRowBackgroundColor = row => {
      let cls = '';
      if (row?.cueItemDivCd === 'cue_000') {
        cls = 'bg-gray-200';
      }

      return cls;
    };

    const onSelected = (event, index) => {
      rows.value[index].selected = event.target.checked;
      prevIndex.value = index;
      // emit('on-selected');
    };

    const onSelectedWithShift = (event, index) => {
      maxIndex.value = Math.max.apply(Math, [prevIndex.value, index]);
      minIndex.value = Math.min.apply(Math, [prevIndex.value, index]);

      if (event.shiftKey) {
        for (let idx = minIndex.value + 1; idx <= maxIndex.value; idx++) {
          rows.value[idx].selected = event.target.checked;
        }
      }
    };

    // Change 실행
    const onPositionChange = event => {
      if (event.moved) {
        const moveRow = event.moved;
        const newIdx = moveRow.newIndex; // newIndex
        const movedElement = moveRow.element; // element

        emit('on-position-changed', movedElement, newIdx);
      }
    };

    // 드래그 막는 props값이 있을 경우
    const onMove = event => {
      if (props.disabledDraggable.length) {
        for (let i = 0; i < props.disabledDraggable.length; i++) {
          return (
            event.draggedContext.futureIndex !== props.disabledDraggable[i]
          );
        }
      }
    };

    // 드래그 막기
    const setDisabledDraggable = index => {
      if (props.disabledDraggable.length) {
        for (let i = 0; i < props.disabledDraggable.length; i++) {
          if (index === props.disabledDraggable[i]) {
            return '';
          }
        }
      }

      return `handle-area cursor-pointer`;
    };

    // 행 선택
    const onRowClicked = (event, row, index) => {
      selectedIdx.value = index;
      emit('on-row-clicked', { event, row, index });
    };

    // 행 더블클릭
    const onRowDbClicked = (event, row, index) => {
      emit('on-row-db-clicked', { event, row, index });
    };

    // 행 mouseUp
    const onRowMouseUp = (event, row, index) => {
      emit('on-row-mouse-up', { event, row, index });
    };

    // 컬럼 너비 조절
    const createResizableTable = table => {
      if (!table) {
        return;
      }

      const cols = table.querySelectorAll('th');
      // 첫 번째 Header에 resizer 클래스가 있다면 div를 다시 만들지 않는다.
      if (cols.length > 0) {
        for (let i = 0, length = cols[0].children.length; i < length; i++) {
          if (cols[0].children[i].classList.contains('resizer')) {
            return;
          }
        }
      }

      [].forEach.call(cols, col => {
        // Add a resizer element to the column
        const resizer = document.createElement('div');
        resizer.classList.add('resizer');

        // Set the height (테이블 전체 높이만큼 표시됨)
        // resizer.style.height = `${table.offsetHeight}px`;

        col.appendChild(resizer);

        createResizableColumn(col, resizer);

        // 클릭하면 sort 메서드 실행되지 않도록
        resizer.addEventListener('click', event => {
          event.stopPropagation();
        });
      });
    };

    const createResizableColumn = (col, resizer) => {
      let clientX = 0;
      let width = 0;

      const mouseDownHandler = event => {
        clientX = event.clientX;

        const styles = window.getComputedStyle(col);
        width = parseInt(styles.width, 10);

        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);

        resizer.classList.add('resizing');
      };

      const mouseMoveHandler = e => {
        const dx = e.clientX - clientX;
        col.style.width = `${width + dx}px`;
      };

      const mouseUpHandler = () => {
        resizer.classList.remove('resizing');
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
      };

      resizer.addEventListener('mousedown', mouseDownHandler);
    };

    // 데이터 정렬
    const onSortData = (headerIdx, sortType) => {
      const trList = tableEl.value
        .querySelector('tbody')
        .querySelectorAll('tr');
      let tdList = [];

      trList.forEach((tr, index) => {
        const tds = [].slice.call(tr.cells);
        const findTd = tds[headerIdx];
        const tempObj = {
          trIdx: index,
          content: findTd?.innerText,
        };

        tdList.push(tempObj);
      });

      if (!sortType) {
        rows.value = originRows.value;
      } else {
        let res = [];

        if (sortType === 'asc') {
          res = tdList.sort((a, b) => a.content.localeCompare(b.content));
        } else if (sortType === 'desc') {
          res = tdList.sort((a, b) => b.content.localeCompare(a.content));
        }

        // 정렬된 결과 값으로 rows 변경
        let tempList = [];
        for (let i = 0, len = res.length; i < len; i++) {
          for (let j = 0, length = rows.value.length; j < length; j++) {
            if (res[i].trIdx === j) {
              tempList.push(rows.value[j]);
              break;
            }
          }
        }

        rows.value = tempList;
      }
    };

    // 정렬 아이콘 렌더링
    const renderIcon = async (col, comp, props) => {
      destroyIconComp = renderComponent({
        el: col,
        component: (await comp).default,
        props,
        appContext,
      });
    };

    // sort 이벤트
    const onSetSort = () => {
      const headerCols = headerEl.value.querySelectorAll('th');

      headerCols.forEach((col, index) => {
        let comp = null;
        const props = {
          width: 'w-4',
          height: 'w-4',
          color: '#9ca3af', // text-gray-400
          cls: 'ml-1 cursor-pointer',
        };

        col.addEventListener('click', event => {
          const clickedColumn = event.target;
          let dataSetValue = '';

          // 정렬이 없을 때 누르면 오름차순 정렬
          if (!clickedColumn.dataset.sort) {
            comp = import('@/components/ui/icon/SortUpIcon.vue');
            dataSetValue = 'asc';
          }
          // 오름차순 정렬에서 클릭 -> 내림차순 정렬
          else if (clickedColumn.dataset.sort === 'asc') {
            comp = import('@/components/ui/icon/SortDownIcon.vue');
            dataSetValue = 'desc';
          }
          // 내림차순 정렬에서 클릭 => 정렬 없음
          else if (clickedColumn.dataset.sort === 'desc') {
            destroyIconComp?.();
          }

          // 어떤 정렬인지 구분하기 위해 dataset에 값을 넣어줌
          col.dataset.sort = dataSetValue;

          // 오름/내림차순 일때만 아이콘 렌더링
          if (dataSetValue) {
            renderIcon(col, comp, props);
          }

          // 데이터 sort
          onSortData(index, dataSetValue);
        });
      });
    };

    // 헤더 이벤트 등 설정
    const onSetHeader = () => {
      if (!headerEl.value) {
        return;
      }

      // 칼럼 개수
      columnCount.value = headerEl.value.children[0].childElementCount;
      // onSetSort();
    };

    // 드래그 유무
    const isDragging = (isDragging, e) => {
      // selectedIdx.value = e.oldIndex;
      emit('is-dragging', isDragging, e);
    };

    // 드래그가 끝났을때를 알려준다.
    const isFinishedDrag = event => {
      const params = {
        tableId: event.to.id,
        newIndex: event.newIndex,
        draggabledItem: event.item._underlying_vm_,
      };

      selectedIdx.value = event.newIndex;

      emit('is-dragging', false);
      emit('is-finished-drag', { params, event });
    };

    // 페이지 정보
    const pageInfo = computed(() => {
      return props.pagination;
    });

    // 페이지 변경
    const onChangedPage = changePage => {
      emit('on-changed-page', changePage);
    };

    onMounted(() => {
      onSetHeader();

      if (tableWrapperRef.value) {
        useScrollTop(tableWrapperRef.value);
      }
    });

    return {
      rows,
      dragOptions,
      allSelected,
      columnCount,
      selectedIdx,
      isDisabledRow,
      isSelectableRow,
      onSelected,
      onSelectedWithShift,
      onMove,
      onPositionChange,
      setDisabledDraggable,
      onRowClicked,
      onRowDbClicked,
      onRowMouseUp,
      isDragging,
      isFinishedDrag,
      setRowBackgroundColor,
      onChangedPage,
      onSetSort,
      pageInfo,
      tableWrapperRef,
    };
  },
});
</script>
<style>
#g_table {
  border-collapse: collapse;
}
#g_table th {
  position: relative;
  /* padding: 0.5rem; */
}
.resizer {
  position: absolute;
  cursor: col-resize;
  width: 5px;
  top: 0;
  right: 0;
  bottom: 0;
  user-select: none;
}
.resizer:hover,
.resizing {
  border-right: 2px solid rgb(209 213 219);
}
</style>
