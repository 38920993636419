export const MUTATION_NAMES = Object.freeze({
  SET_PROGRAMS: 'SET_PROGRAMS',
});

export const MUTATION = {
  [MUTATION_NAMES.SET_PROGRAMS]: (state: any, programs: any) => {
    console.log('MUTATION_NAMES.SET_PROGRAMS start');
    state.programs = programs;
  },
};
