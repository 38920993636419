<template>
  <svg
    class="animate-spin h-5 w-5 mr-0.5"
    viewBox="0 0 24 24"
    aria-hidden="true"
  >
    <circle
      v-if="fillWhite"
      class="opacity-25"
      cx="12"
      cy="12"
      r="10"
      fill="#ffffff"
      stroke="currentColor"
      stroke-width="4"
    ></circle>
    <circle
      v-else
      class="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      stroke-width="4"
    ></circle>
    <path
      class="opacity-100"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SpinnerIcon',
  props: {
    fillWhite: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {};
  },
});
</script>
