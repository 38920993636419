<template>
  <GModal max-width="sm:max-w-sm" :changed="false" @close="onClose">
    <!-- title -->
    <template #title>
      <span class="text-white font-D2CodingBold">{{
        t('Modal.Cuesheet.CueSheetDuplicateModal.큐시트_복사')
      }}</span>
    </template>
    <!-- content -->
    <template #content>
      <div
        class="bg-white pt-2 mb-2 sm:pt-4 sm:mb-4 px-2 sm:px-4 md:px-6 xl:px-8 space-y-8"
      >
        <div class="flex flex-col space-y-5">
          <div class="flex space-x-2">
            <!-- 날짜 -->
            <DatePickerEx
              v-model="date"
              name="date"
              :label="t('Modal.Cuesheet.CueSheetDuplicateModal.방송_일시')"
              type="date"
              format="yyyy-MM-dd"
              :before-after-icon="true"
              :disabled-button="disabledBtn"
              :disabled-date="disabledDate"
              @change="onChangeDate"
            />
            <!-- 방송시간 -->
            <GTimeCodeInput
              v-model="startTime"
              :label="t('Modal.Cuesheet.CueSheetDuplicateModal.방송시간')"
              name="startTime"
              time-code-type="time"
              placeholder="00:00:00"
              @blur="onSetTime"
            />
          </div>

          <!-- 프로그램 -->
          <div class="w-full space-y-1.5">
            <ProgramSelect
              v-model="program"
              item-label
              :item-label-name="
                t('Modal.Cuesheet.CueSheetDuplicateModal.뉴스_구분')
              "
              :company-code="userInfo.companyId"
            />
          </div>
          <GInput
            v-model="cuesheetName"
            :label="t('Modal.Cuesheet.CueSheetDuplicateModal.큐시트_별칭')"
            type="text"
            name="nickname"
          />

          <span class="mt-2 text-xs text-red-600">{{ errorMsg }}</span>
        </div>

        <!-- 하단 Submit 버튼 세트 -->
        <div class="sm:flex sm:flex-row-reverse pb-2">
          <div class="space-x-4 flex items-center">
            <GButton type="secondary" @click="onClose">{{
              t('Modal.Cuesheet.CueSheetDuplicateModal.취소')
            }}</GButton>
            <GButton :disabled="!canSubmit" @click="onSubmit">{{
              t('Modal.Cuesheet.CueSheetDuplicateModal.복사')
            }}</GButton>
          </div>
        </div>
      </div>
    </template>
  </GModal>
</template>

<script>
import { defineComponent, ref, onMounted, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { GButton, GModal, GInput, GTimeCodeInput } from '@/components/ui';
import DatePickerEx from '@/components/common/DatePickerEx.vue';
import { useFormSubmit } from '@/hooks/use-form';
import { useForm } from 'vee-validate';
import yup from '@/utils/yup';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { ProgramSelect } from '@/components/select';
import { useUserInfo } from '@/hooks/use-user-info';
import { useMarsLApi } from '@/apis/mars-l-api';

export default defineComponent({
  name: 'CueSheetDuplicateModal',

  components: {
    GModal,
    GButton,
    DatePickerEx,
    GInput,
    GTimeCodeInput,
    ProgramSelect,
  },

  props: {
    cuesheetId: {
      type: [String, Number],
      default: () => {
        return '';
      },
    },
  },

  emits: ['close'],

  setup(props, { emit }) {
    const { t } = useI18n();

    dayjs.extend(isSameOrBefore);

    const marsLApi = useMarsLApi();

    // 큐시트 정보 조회
    const getCuesheetInfo = async () => {
      try {
        const response = await marsLApi.cueSheet.basic.findOne(
          props.cuesheetId,
        );
        console.log('response', response);
        if (response.success) {
          setCuesheetInfo(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    // 큐시트 정보 세팅
    const setCuesheetInfo = cuesheet => {
      program.value = {
        id: cuesheet.program.id,
        name: cuesheet.program.brdcPgmNm,
        label: cuesheet.program.brdcPgmNm,
      };
      startTime.value = cuesheet.brdcStartTime;
      cuesheetName.value = cuesheet.cueNm;
    };

    const date = ref();
    const userInfo = useUserInfo();
    const program = ref();
    const startTime = ref();
    const errorMsg = ref(null);
    const cuesheetName = ref();

    // 이전 날짜 선택 버튼 disabled 처리
    const disabledBtn = computed(() => {
      if (dayjs(date.value).isSameOrBefore(dayjs())) {
        // 오늘부터 이전은 버튼 선택 X
        if (date.value === dayjs().format('YYYY-MM-DD')) {
          return true;
        }
      }

      return false;
    });

    // 달력에서 오늘 이전 선택하지 못하도록 disabeld 처리
    const disabledDate = date => {
      // new Date()로 하면 오늘날짜부터 disabled되서 어제날짜로 비교함
      if (date < dayjs().subtract(1, 'day').toDate()) {
        return true;
      }

      return false;
    };

    // 날짜 변경
    const onChangeDate = changeDate => {
      date.value = changeDate;
    };

    const validationSchema = yup.object().shape({
      program: yup.object().required(),
      date: yup.date().required(),
    });

    const cuesheet = ref({
      program: null,
      date: dayjs().format('YYYY-MM-DD'),
    });

    const { handleSubmit, isSubmitting, meta, submitCount, errors } = useForm({
      initialValues: cuesheet,
      validationSchema,
    });

    // submit 할 수 있는지 여부
    const canSubmit = useFormSubmit(meta, submitCount);

    // 큐시트 복사하기
    const onSubmit = () => {
      const params = {
        cueId: props.cuesheetId,
        brdcPgmId: program.value.id,
        // brdcPgmNm: program.value.name,
        brdcDt: dayjs(date.value).format('YYYY-MM-DD'),
        brdcStartTime: startTime.value,
        cueNm: cuesheetName.value,
      };

      // if (response.status === 'CONFLICT') {
      //   errorMsg.value = '이미 같은날짜에 생성된 큐시트가 존재합니다.';
      //   isLoading.value = false;
      //   return;
      // }

      emit('on-copy-cuesheet', params);
    };

    const onClose = () => {
      emit('close');
    };

    // 시간 설정
    const onSetTime = (event, name) => {
      // 아무것도 입력을 안했을 경우
      if (!event.target.value) {
        errorMsg[name] = '';
      }
      // 3자리일 이하일 경우 메시지 출력
      else if (event.target.value.length < 4) {
        errorMsg[name] = t(
          'Modal.Cuesheet.CueSheetDuplicateModal.시간을_다시_확인해주세요',
        );
      }
      // 4자리일 경우 뒤에 00붙여주기
      else if (event.target.value.length === 4) {
        startTime.value = event.target.value + '00';
        errorMsg[name] = '';
      }
      // 5자리일 경우 메시지 출력
      else if (event.target.value.length === 5) {
        errorMsg[name] = t(
          'Modal.Cuesheet.CueSheetDuplicateModal.시간을_다시_확인해주세요',
        );
      } else {
        errorMsg[name] = '';
      }
    };

    watch(
      () => [date.value, program.value],
      ([newDate, newProgram], [oldDate, oldProgram]) => {
        if (newDate !== oldDate || newProgram !== oldProgram) {
          errorMsg.value = null;
        }
      },
    );

    onMounted(async () => {
      await getCuesheetInfo();
    });

    return {
      t,
      program,
      date,
      onClose,
      canSubmit,
      handleSubmit,
      isSubmitting,
      errors,
      onSubmit,
      disabledDate,
      disabledBtn,
      errorMsg,
      onChangeDate,
      cuesheetName,
      startTime,
      userInfo,
      onSetTime,
    };
  },
});
</script>
