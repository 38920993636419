<template>
  <div class="space-y-1" :class="[width]">
    <span
      v-if="itemLabel"
      class="block sm:text-xs text-xs font-medium text-gray-700"
    >
      {{ itemLabel }}<span v-if="required" class="text-red-500"> *</span>
    </span>
    <VueMultiselect
      v-model="inputValue"
      :multiple="false"
      :track-by="trackBy"
      :label="label"
      :options="options"
      :placeholder="placeholder"
      :loading="isLoading"
      :disabled="disabled || readonly"
      :allow-empty="allowEmpty"
      :select-label="selectLabel"
      :selected-label="allowEmpty ? selectedLabel : ''"
      :deselect-label="allowEmpty ? selectedLabel : deselectLabel"
      :searchable="searchable"
      :preselect-first="preselectFirst"
      @select="onSelect"
      @close="onClose"
    >
      <template #noResult>검색결과가 없습니다.</template>
      <template #noOptions>검색결과가 없습니다.</template>
      <template #singleLabel="{ option }">
        <slot name="singleLabel" :option="option" />
      </template>
      <template #option="{ option }">
        <slot name="option" :option="option" />
      </template>
    </VueMultiselect>
    <p v-if="errorMessage || errors" class="mt-2 text-xs text-error">
      {{ errorMessage ? errorMessage : errors ? errors : '' }}
    </p>
  </div>
</template>

<script>
import { computed, defineComponent, watch } from 'vue';
import VueMultiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';
import { useField } from 'vee-validate';
import i18n from '@/i18n';

export default defineComponent({
  name: 'GSingleSelect',
  components: {
    VueMultiselect,
  },
  props: {
    modelValue: {
      type: [String, Number, Object],
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    itemLabel: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    trackBy: {
      type: String,
      default: 'value',
    },
    label: {
      type: String,
      default: 'description',
    },
    selectLabel: {
      type: String,
      default: i18n.global.t('GSingleSelect.선택'),
    },
    selectedLabel: {
      type: String,
      default: i18n.global.t('GSingleSelect.취소'),
    },
    deselectLabel: {
      type: String,
      default: '', // 선택되어 있는 아이템에 hover하면 나오는 값
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    errors: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: 'w-36',
    },
    preselectFirst: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'select'],

  setup(props, { emit }) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.modelValue,
    });

    // object 형태가 아닐 경우, list에서 해당 값을 찾아서 넣어준다.
    watch(
      () => [inputValue.value, props.options],
      ([newValue, newOptions]) => {
        if (newValue && newOptions.length) {
          if (typeof inputValue.value !== 'object') {
            inputValue.value = newOptions.find(option => {
              return option[props.trackBy] == newValue;
            });
          }
        }
      },
    );

    const errorCls = computed(() => {
      if (errorMessage.value || props.error) {
        return {
          '--custom-border': '1px solid red',
        };
      }
      return {
        '--custom-border': '1px solid #d1d5db',
      };
    });

    const onSelect = value => {
      inputValue.value = value;
    };

    const onClose = values => {
      if (inputValue.value === values) {
        return;
      }

      emit('update:modelValue', inputValue.value);
      emit('select', inputValue.value);
    };

    return {
      errorMessage,
      handleBlur,
      handleChange,
      meta,
      inputValue,
      errorCls,
      onSelect,
      onClose,
    };
  },
});
</script>
