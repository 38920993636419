import { Code, EnumEntry } from './base';

class portalCategoryCode extends EnumEntry<Code> {
  POLITICAL: Code = {
    id: 'portal_category_001',
    name: '정치',
    label: '정치',
  };

  ECONOMIC: Code = {
    id: 'portal_category_002',
    name: '경제',
    label: '경제',
  };

  SOCIAL: Code = {
    id: 'portal_category_003',
    name: '사회',
    label: '사회',
  };

  CULTURE: Code = {
    id: 'portal_category_004',
    name: '문화',
    label: '문화',
  };

  IT: Code = {
    id: 'portal_category_005',
    name: 'IT',
    label: 'IT',
  };

  WORLD: Code = {
    id: 'portal_category_006',
    name: '국제',
    label: '국제',
  };

  values: Array<Code> = [
    this.POLITICAL,
    this.ECONOMIC,
    this.SOCIAL,
    this.CULTURE,
    this.IT,
    this.WORLD,
  ];
}

export default new portalCategoryCode();
