
import { defineComponent, PropType, computed } from 'vue';
import {
  XMarkIcon,
  LinkIcon,
  PencilIcon,
  PrinterIcon,
  TrashIcon,
  DocumentDuplicateIcon,
  LockClosedIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  PlusSmallIcon,
  MinusSmallIcon,
  ArrowDownTrayIcon,
  DocumentArrowDownIcon,
  DocumentIcon,
  PlusIcon,
} from '@heroicons/vue/24/outline';
import { capitalize } from '@/utils/str';
import { IconType } from '@/types/ui';
import {
  AnchorIcon,
  CameraIcon,
  EndIcon,
  CgIcon,
  IntIcon,
  SpecialKeyIcon,
  SyncIcon,
  StupIcon,
} from '@/components/ui/icon';

export default defineComponent({
  name: 'GIconButton',
  components: {
    XMarkIcon,
    LinkIcon,
    PencilIcon,
    PrinterIcon,
    TrashIcon,
    DocumentDuplicateIcon,
    LockClosedIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    PlusSmallIcon,
    MinusSmallIcon,
    ArrowDownTrayIcon,
    DocumentArrowDownIcon,
    DocumentIcon,
    AnchorIcon,
    CameraIcon,
    EndIcon,
    CgIcon,
    IntIcon,
    SpecialKeyIcon,
    SyncIcon,
    StupIcon,
    PlusIcon,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    tabIndex: {
      type: Number,
      default: 0,
    },
    icon: {
      type: String as PropType<IconType>,
      default: '',
    },
    cursor: {
      type: Boolean,
      default: true,
    },
    outline: {
      type: Boolean,
      default: true,
    },
    iconColor: {
      type: String,
      default: '',
    },
    iconSize: {
      type: Number,
      default: 5,
    },
    name: {
      type: String,
      default: '',
    },
    hover: {
      type: Boolean,
      default: true,
    },
    padding: {
      type: String,
      default: 'p-1',
    },
    border: {
      type: String,
      default: 'border border-transparent',
    },
    tooltip: {
      type: String,
      default: '',
    },
    text: {
      type: String,
    },
  },
  setup(props) {
    const btnClass = computed(() => {
      let cls = '';

      if (props.disabled) {
        return 'opacity-50 hover:opacity-50 cursor-not-allowed';
      }

      if (props.iconColor) {
        cls = `text-${props.iconColor}`;
      }

      if (props.cursor) {
        cls += ' cursor-pointer';
      } else {
        cls += ' cursor-default';
      }

      if (props.outline) {
        cls += ' focus:outline-none focus:ring-2 focus:ring-primary';
      }

      if (props.hover) {
        cls +=
          ' hover:text-gray-700 hover:bg-gray-100 hover:transition hover:ease-in-out hover:duration-150 focus:ring-2 focus:ring-primary';
      }
      if (props.padding) {
        cls += ` ${props.padding}`;
      }
      if (props.border) {
        cls += ` ${props.border}`;
      }

      return cls;
    });

    const iconClass = computed(() => {
      let iconSize = props.iconSize;
      if (props.icon === 'plus') {
        iconSize = 6;
      }
      const cls = `w-${iconSize} h-${iconSize}`;

      return cls;
    });

    // 아이콘 컴포넌트 동적 생성
    const iconComponent = computed(() => {
      const icon = `${capitalize(props.icon)}Icon`;
      return icon;
    });

    return {
      btnClass,
      iconClass,
      iconComponent,
    };
  },
});
