<template>
  <svg xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
    <path
      d="M2.312 16.646V3.562h7.584v3h7.792v10.084Zm1.084-1.084h5.416v-1.916H3.396Zm0-3h5.416v-1.916H3.396Zm0-3h5.416V7.646H3.396Zm0-3h5.416V4.646H3.396Zm6.5 9h6.708V7.646H9.896Zm1.646-4.916V9.562h3.25v1.084Zm0 3v-1.084h3.25v1.084Z"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CorporateFareIcon',
  props: {},
  setup() {
    return {};
  },
});
</script>
