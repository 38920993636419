<template>
  <GModal
    :changed="false"
    :dialog-overlay="false"
    max-width="w-4/12"
    @close="onClose"
  >
    <!-- title -->
    <template #title>
      <span class="text-white font-D2CodingBold">{{
        t('Modal.Preferences.PreferencesModal.환경설정')
      }}</span>
    </template>

    <!-- 내용 -->
    <template #content>
      <div class="pl-4 pt-4 pb-6 space-y-4 text-sm">
        <GTabPanel :tabs="tabs" :selected-tab-id="selectedTabId">
          <TabPanel>
            <UserInfo @close="onClose" />
          </TabPanel>
          <TabPanel>
            <SystemInfo @close="onClose" />
          </TabPanel>
          <TabPanel>
            <UserLog />
          </TabPanel>
        </GTabPanel>
      </div>
    </template>
  </GModal>
</template>

<script setup>
import { defineEmits, ref } from 'vue';
import { GModal, GTabPanel } from '@/components/ui';
import { TabPanel } from '@headlessui/vue';
import { useI18n } from 'vue-i18n';
import SystemInfo from '@/components/preferences/SystemInfo.vue';
import UserInfo from '@/components/preferences/UserInfo.vue';
import UserLog from '@/components/preferences/UserLog.vue';

const { t } = useI18n();
const tabs = ref([
  { id: 1, name: t('Modal.Preferences.PreferencesModal.사용자_정보_변경') },
  { id: 2, name: t('Modal.Preferences.PreferencesModal.시스템_설정') },
  { id: 3, name: t('Modal.Preferences.PreferencesModal.사용자_로그기록') },
]);
let selectedTabId = ref(0);
const emit = defineEmits(['close']);

// 모달 닫기
const onClose = () => {
  emit('close');
};
</script>
