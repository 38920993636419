<template>
  <div class="text-xs space-y-1.5">
    <div v-if="label">{{ t('ViewAttachFile.첨부파일') }}</div>
    <div v-if="showLoading && isLoading">
      <PageLoader />
    </div>
    <slot v-else-if="!uploadedFiles.length" name="noData" />
    <div
      v-show="!isLoading && uploadedFiles.length"
      v-for="file in uploadedFiles"
      :key="file.id"
      class="flex items-center justify-between rounded-md border p-2"
    >
      <div class="space-x-2 flex">
        <PaperClipIcon class="w-4 h-4" />
        <div class="text-xs">
          {{ file.fileName }}
        </div>
      </div>
      <div
        v-if="downloadLoading && selectedFile?.fileId === file.fileId"
        class="flex items-center justify-center"
      >
        <GSpinner fill-white class="text-primary" />
      </div>
      <InboxArrowDownIcon
        v-if="!downloadLoading || selectedFile?.fileId !== file.fileId"
        v-tooltip="t('ViewAttachFile.다운로드')"
        class="w-5 h-5 text-gray-600 cursor-pointer outline-none"
        @click="onDownloadFile(file)"
      />
    </div>
  </div>
</template>

<script setup>
import { defineProps, watchEffect, ref, onActivated, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useMarsLApi } from '@/apis/mars-l-api';
import { PaperClipIcon, InboxArrowDownIcon } from '@heroicons/vue/24/outline';
import PageLoader from '@/components/common/PageLoader.vue';
import GSpinner from '@/components/ui/GSpinner';
import { useSweetAlert } from '@/hooks/use-sweet-alert';
import fileDownload from '@/utils/file-download.ts';

/**
 * routeName 종류
 * myArticle - 내기사
 * coverDesk - 취재데스크
 * contactUs - 제보관리
 * board - 게시판
 */

const props = defineProps({
  routeName: {
    type: String,
  },
  itemId: {
    type: [String, Number],
  },
  label: {
    type: Boolean,
    default: true,
  },
  showLoading: {
    type: Boolean,
    default: true,
  },
});

const { t } = useI18n();
const store = useStore();
const marsLApi = useMarsLApi();
const { swalError } = useSweetAlert();

const isLoading = ref(false);
const downloadLoading = ref(false);
const uploadedFiles = ref([]);
const selectedFile = ref(null);

const setData = (fileList, setKeyNames) => {
  const { fileName, fileId } = setKeyNames;
  uploadedFiles.value = fileList.map(file => {
    return {
      fileName: file[fileName],
      fileId: file[fileId],
    };
  });
};

// 파일 목록 가져오기
const getFiles = async () => {
  isLoading.value = true;
  let response = null;
  try {
    // 내기사일 경우
    if (props.routeName === 'myArticle') {
      response = await marsLApi.myArticle.file.findAll(parseInt(props.itemId));
      setData(response.data, { fileName: 'fileName', fileId: 'fileId' });
    }
    // 취재데스크일 경우
    else if (props.routeName === 'coverDesk') {
      response = await marsLApi.coverDesk.file.findAll(parseInt(props.itemId));
      setData(response.data, { fileName: 'fileName', fileId: 'fileId' });
    }
    // 제보관리일 경우
    else if (props.routeName === 'contactUs') {
      response = await marsLApi.contactUs.basic.findOne(props.itemId);
      setData(response.data.attcFiles, { fileName: 'fileNm', fileId: 'id' });
    }
  } catch (error) {
    console.error('error', error);
  } finally {
    isLoading.value = false;
  }
};

// 파일 다운로드
const onDownloadFile = async file => {
  selectedFile.value = file;
  downloadLoading.value = true;

  const params = {
    fileId: file.fileId,
  };
  try {
    const response = await marsLApi.file.basic.download(params);
    fileDownload(response, file.fileName);
  } catch (error) {
    console.error('error', error);
    swalError({
      showCancelButton: false,
      title: '<div>첨부파일을 다운로드 할 수 없습니다.</div>',
    });
  } finally {
    downloadLoading.value = false;
  }
};

watchEffect(() => {
  if (props.itemId) {
    getFiles();
  }
});

onActivated(async () => {
  const refreshStatus = store.getters['refresh/myRegionArticle'];

  if (refreshStatus?.isRefresh) {
    getFiles();

    if (props.routeName === 'coverDesk') {
      store.dispatch('refresh/DELETE_MY_REGION_ARTICLE_REFRESH_STATUS');
    }
  }
});

// 내기사의 경우 onActivated가 계속 살아있는 상태이기 때문에 여기서 처리함
watch(
  () => store.getters['refresh/myArticle'],
  async refreshStatus => {
    if (!refreshStatus?.isRefresh) {
      return;
    }

    getFiles();
    store.dispatch('refresh/DELETE_MY_ARTICLE_REFRESH_STATUS');
  },
);
</script>
