import { Code, EnumEntry } from './base';

interface ExtendsCode extends Code {
  code: string;
}

class appAuthCode extends EnumEntry<ExtendsCode> {
  myArticleWrite: ExtendsCode = {
    id: 1,
    code: 'my_article_write',
    name: '작성',
    label: '작성',
  };

  myArticleSend: ExtendsCode = {
    id: 2,
    code: 'my_article_send',
    name: '송고',
    label: '송고',
  };

  articleRead: ExtendsCode = {
    id: 3,
    code: 'article_read',
    name: '전체 조회',
    label: '전체 조회',
  };

  articleWrite: ExtendsCode = {
    id: 4,
    code: 'article_write',
    name: '작성',
    label: '작성',
  };

  articleCopy: ExtendsCode = {
    id: 5,
    code: 'article_copy',
    name: '복사',
    label: '복사',
  };

  articleRelease: ExtendsCode = {
    id: 6,
    code: 'article_release',
    name: '출고',
    label: '출고',
  };

  articleHomepageSend: ExtendsCode = {
    id: 7,
    code: 'article_homepage_send',
    name: '포탈 전송',
    label: '포탈 전송',
  };

  articlePrint: ExtendsCode = {
    id: 8,
    code: 'article_print',
    name: '인쇄',
    label: '인쇄',
  };

  articleChannelRead: ExtendsCode = {
    id: 9,
    code: 'article_channel_read',
    name: '지역사 조회',
    label: '지역사 조회',
  };

  articleSeoulRead: ExtendsCode = {
    id: 10,
    code: 'article_seoul_read',
    name: '서울 MBC 조회',
    label: '서울 MBC 조회',
  };

  cuesheetRead: ExtendsCode = {
    id: 11,
    code: 'cuesheet_read',
    name: '전체 조회',
    label: '전체 조회',
  };

  cuesheetWrite: ExtendsCode = {
    id: 12,
    code: 'cuesheet_write',
    name: '작성',
    label: '작성',
  };

  cuesheetChannelRead: ExtendsCode = {
    id: 13,
    code: 'cuesheet_channel_read',
    name: '지역사 조회',
    label: '지역사 조회',
  };

  cuesheetSeoulRead: ExtendsCode = {
    id: 14,
    code: 'cuesheet_seoul_read',
    name: '서울 MBC 조회',
    label: '서울 MBC 조회',
  };

  cuesheetDetailItemWrite: ExtendsCode = {
    id: 15,
    code: 'cuesheet_detail_item_write',
    name: '기사 아이템 작성',
    label: '기사 아이템 작성',
  };

  cuesheetDetailPrint: ExtendsCode = {
    id: 16,
    code: 'cuesheet_detail_print',
    name: '인쇄',
    label: '인쇄',
  };

  cuesheetDetailAnchorWrite: ExtendsCode = {
    id: 17,
    code: 'cuesheet_detail_anchor_write',
    name: '앵커 멘트 수정',
    label: '앵커 멘트 수정',
  };

  cuesheetTemplateRead: ExtendsCode = {
    id: 18,
    code: 'cuesheet_template_read',
    name: '조회',
    label: '조회',
  };

  cuesheetTemplateWrite: ExtendsCode = {
    id: 19,
    code: 'cuesheet_template_write',
    name: '작성',
    label: '작성',
  };

  reportRead: ExtendsCode = {
    id: 20,
    code: 'report_read',
    name: '조회',
    label: '조회',
  };

  reportWrite: ExtendsCode = {
    id: 21,
    code: 'report_write',
    name: '작성',
    label: '작성',
  };

  monitoringRead: ExtendsCode = {
    id: 22,
    code: 'monitoring_read',
    name: '조회',
    label: '조회',
  };

  monitoringWrite: ExtendsCode = {
    id: 23,
    code: 'monitoring_write',
    name: '작성',
    label: '작성',
  };

  boardRead: ExtendsCode = {
    id: 24,
    code: 'board_read',
    name: '조회',
    label: '조회',
  };

  boardWrite: ExtendsCode = {
    id: 25,
    code: 'board_write',
    name: '작성',
    label: '작성',
  };

  boardReplyWrite: ExtendsCode = {
    id: 26,
    code: 'board_reply_write',
    name: '댓글 작성',
    label: '댓글 작성',
  };

  boardNoticeWrite: ExtendsCode = {
    id: 27,
    code: 'board_notice_write',
    name: '공지 작성',
    label: '공지 작성',
  };

  scrollnewsRead: ExtendsCode = {
    id: 28,
    code: 'scrollnews_read',
    name: '조회',
    label: '조회',
  };

  scrollnewsWrite: ExtendsCode = {
    id: 29,
    code: 'scrollnews_write',
    name: '작성',
    label: '작성',
  };

  scrollnewsSend: ExtendsCode = {
    id: 30,
    code: 'scrollnews_send',
    name: '전송',
    label: '전송',
  };

  adminView: ExtendsCode = {
    id: 31,
    code: 'admin_view',
    name: '관리자 페이지 접근',
    label: '관리자 페이지 접근',
  };

  values: Array<ExtendsCode> = [
    this.myArticleWrite,
    this.myArticleSend,
    this.articleRead,
    this.articleWrite,
    this.articleCopy,
    this.articleRelease,
    this.articleHomepageSend,
    this.articlePrint,
    this.articleChannelRead,
    this.articleSeoulRead,
    this.cuesheetRead,
    this.cuesheetWrite,
    this.cuesheetChannelRead,
    this.cuesheetSeoulRead,
    this.cuesheetDetailItemWrite,
    this.cuesheetDetailPrint,
    this.cuesheetDetailAnchorWrite,
    this.cuesheetTemplateRead,
    this.cuesheetTemplateWrite,
    this.reportRead,
    this.reportWrite,
    this.monitoringRead,
    this.monitoringWrite,
    this.boardRead,
    this.boardWrite,
    this.boardReplyWrite,
    this.boardNoticeWrite,
    this.scrollnewsRead,
    this.scrollnewsWrite,
    this.scrollnewsSend,
    this.adminView,
  ];
}

export default new appAuthCode();
