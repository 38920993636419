<template>
  <div class="pt-7 space-y-56 h-[28.625rem]">
    <div class="px-11 space-y-5">
      <div class="space-y-2">
        <div>{{ t('SystemInfo.기사_읽기_속도') }}</div>
        <div class="flex space-x-7 bg-gray-100 px-3 py-4 rounded-lg">
          <GRadioButton
            v-model="speed"
            name="speed"
            label="느림"
            value="001"
            :checked="speed === '001'"
          />
          <GRadioButton
            v-model="speed"
            name="speed"
            label="보통"
            value="002"
            :checked="speed === '002'"
          />
          <GRadioButton
            v-model="speed"
            name="speed"
            label="빠름"
            value="003"
            :checked="speed === '003'"
          />
        </div>
      </div>
      <div class="space-y-2">
        <div>{{ t('SystemInfo.기사_자동_저장_여부') }}</div>
        <div class="flex space-x-5 bg-gray-100 px-3 py-4 rounded-lg">
          <GCheckbox
            v-model="isSaveAuto"
            name="autoSave"
            label="자동 저장"
            :checked="isSaveAuto"
            :disabled="true"
          />
        </div>
      </div>
    </div>

    <div class="flex px-8 justify-end space-x-3">
      <GButton type="secondary" @click="close">{{
        t('UserInfo.취소')
      }}</GButton>
      <GButton @click="submit">{{ t('UserInfo.수정') }}</GButton>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref, onMounted } from 'vue';
import { GButton, GRadioButton, GCheckbox } from '@/components/ui';
import { useI18n } from 'vue-i18n';
import { useMarsLApi } from '@/apis/mars-l-api';

const { t } = useI18n();
const marsLApi = useMarsLApi();

/**
 * 001: 느림
 * 002: 보통
 * 003: 빠름
 */
const speed = ref('002');
const isSaveAuto = ref(false);
defineProps({});

const emit = defineEmits(['close']);

// 조회
const getSystemInfo = async () => {
  try {
    const response = await marsLApi.myInfo.system.findAll();

    if (response.success) {
      speed.value = response.data.speedSetting.code;
      isSaveAuto.value = response.data.isAutoSave;
    }
  } catch (error) {
    console.error('error', error);
  }
};

// 수정
const submit = async () => {
  try {
    const params = {
      userSpeedSettingEnum: speed.value,
      isAutoSave: isSaveAuto.value,
    };

    const response = await marsLApi.myInfo.system.update(params);

    if (response.success) {
      window.Notify.success({
        message: '[시스템 설정 변경] 완료되었습니다.',
      });
      getSystemInfo();
    }
  } catch (error) {
    console.error('error', error);
  }
};

// 닫기 버튼 클릭
const close = () => {
  emit('close');
};

onMounted(() => {
  getSystemInfo();
});
</script>
