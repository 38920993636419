const CuesheetFormIconType = Object.freeze({
  CUESHEET_FORM_001: 'cuesheet-form-001',
  CUESHEET_FORM_002: 'cuesheet-form-002',
  CUESHEET_FORM_003: 'cuesheet-form-003',
  CUESHEET_FORM_004: 'cuesheet-form-004',
  CUESHEET_FORM_005: 'cuesheet-form-005',
  CUESHEET_FORM_006: 'cuesheet-form-006',
  CUESHEET_FORM_007: 'cuesheet-form-007',
  CUESHEET_FORM_008: 'cuesheet-form-008',
  CUESHEET_FORM_009: 'cuesheet-form-009',
  CUESHEET_FORM_010: 'cuesheet-form-010',
  CUESHEET_FORM_EMPTY: 'cuesheet-form-empty',
});

export default CuesheetFormIconType;
