<template>
  <div class="space-y-1" :class="[width]">
    <span v-if="itemLabel" class="text-xs inline-block">{{ itemLabel }}</span>
    <VueMultiselect
      v-model="groups"
      :multiple="true"
      :taggable="false"
      :track-by="TRACKBY"
      :label="LABEL"
      :options="groupList"
      :placeholder="disabled || readonly ? '' : placeholder"
      :loading="isLoading"
      :disabled="disabled || readonly"
      :allow-empty="allowEmpty"
      :select-label="selectLabel"
      :selected-label="allowEmpty ? selectedLabel : ''"
      :deselect-label="allowEmpty ? selectedLabel : deselectLabel"
      :searchable="searchable"
      :close-on-select="closeOnSelect"
    >
      <template #noOptions>{{
        i18n.global.t('UserGroupSelect.데이터가_존재하지_않습니다')
      }}</template>
      <template #noResult>{{
        i18n.global.t('UserGroupSelect.데이터가_존재하지_않습니다')
      }}</template>
    </VueMultiselect>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import VueMultiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';
import i18n from '@/i18n';
import { useMarsLApi } from '@/apis/mars-l-api';
import { useModelWrapper } from '@/hooks/useModelWrapper';

const LABEL = 'groupName';
const TRACKBY = 'groupId';

export default defineComponent({
  name: 'UserGroupSelect',
  components: {
    VueMultiselect,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => {
        return [];
      },
    },
    name: {
      type: String,
      required: true,
    },
    itemLabel: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: i18n.global.t('UserGroupSelect.부서를_선택해주세요'),
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    selectLabel: {
      type: String,
      default: i18n.global.t('UserGroupSelect.선택'),
    },
    selectedLabel: {
      type: String,
      default: i18n.global.t('UserGroupSelect.취소'),
    },
    deselectLabel: {
      type: String,
      default: '', // 선택되어 있는 아이템에 hover하면 나오는 값
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    errors: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: 'min-w-full',
    },
    closeOnSelect: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const marsLApi = useMarsLApi();
    const isLoading = ref(false);
    const groupList = ref([]);
    const groups = useModelWrapper(props, emit, 'modelValue');

    // 그룹 목록 가져오기
    const getGroups = async () => {
      try {
        const params = {
          isUse: true,
          size: 10000,
        };
        const response = await marsLApi.group.basic.findAll(params);
        if (response.success) {
          groupList.value = response.data.content.map(group => {
            return {
              ...group,
              isChecked: false,
            };
          });
        }
      } catch (error) {
        console.error('error', error);
      }
    };

    onMounted(() => {
      // disabled일 경우 목록 조회 X
      if (!props.disabled) {
        getGroups();
      }
    });

    return {
      isLoading,
      LABEL,
      TRACKBY,
      groupList,
      groups,
      i18n,
      // errorCls,
    };
  },
});
</script>
<style scoped>
.multiselect {
  min-height: 34px;
}
.multiselect:deep(.multiselect__tags) {
  min-height: 34px;
  font-size: 0.75rem;
  padding: 6px 40px 0 8px;
}
.multiselect:deep(.multiselect__option),
.multiselect:deep(.multiselect__input) {
  font-size: 0.75rem;
}
.multiselect:deep(.multiselect__placeholder) {
  margin-bottom: 2px;
}
.multiselect:deep(.multiselect__tag) {
  margin-bottom: 0;
}
.multiselect:deep(.multiselect__select) {
  height: 34px;
}

.multiselect:deep(.multiselect__spinner) {
  height: 31px;
}

.multiselect--disabled {
  opacity: 1;
  border-radius: 0.375rem;
  background-color: rgb(229 231 235);
  border: 1px solid rgb(209 213 219);
}
.multiselect--disabled:deep(.multiselect__select) {
  display: none;
}
.multiselect--disabled:deep(.multiselect__tags),
.multiselect--disabled:deep(.multiselect__single) {
  background-color: rgb(229 231 235);
}
.multiselect--disabled:deep(.multiselect__spinner) {
  background: none;
}
.multiselect--disabled:deep(.multiselect__tag-icon) {
  display: none;
}
.multiselect--disabled:deep(.multiselect__tag) {
  padding: 4px 12px 4px 10px;
}
</style>
