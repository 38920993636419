<template>
  <div class="border rounded-md bg-white overflow-auto">
    <GTable
      ref="bottomRollEditorRef"
      :list="list"
      :draggable="true"
      :multiselect="false"
      :isLoading="isLoading"
      :firstDefaultRow="false"
      :pagination="{ isShow: false }"
      :height="height"
      @is-finished-drag="isFinishedDrag"
    >
      <template #header>
        <th class="p-3 w-28">{{ t('BottomRollEditor.분야') }}</th>
        <th class="p-2">{{ t('BottomRollEditor.내용') }}</th>
        <th class="p-2 w-10">
          <div><TrashIcon class="w-5 h-5" /></div>
        </th>
      </template>
      <template #list="{ row, index }">
        <BottomRollEditorItem
          :row="row"
          :index="index"
          @on-delete-item="onDeleteItem"
        />
      </template>
    </GTable>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { GTable } from '@/components/ui';
import { useI18n } from 'vue-i18n';
import { useModelWrapper } from '@/hooks/useModelWrapper';
import { TrashIcon } from '@heroicons/vue/24/outline';
import BottomRollEditorItem from './BottomRollEditorItem.vue';

export default defineComponent({
  name: 'BottomRollEditor',
  components: {
    GTable,
    TrashIcon,
    BottomRollEditorItem,
  },
  props: {
    bottomRollList: {
      type: Array,
      default: () => {
        return [
          {
            categoryName: '',
            content: '',
            order: '',
          },
        ];
      },
    },
    isLoading: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    height: {
      type: String,
      default: () => {
        return `h-[calc(100vh-180px)]`;
      },
    },
  },

  emits: [],
  setup(props, { emit }) {
    const { t } = useI18n();
    const list = useModelWrapper(props, emit, 'bottomRollList');
    const bottomRollEditorRef = ref();

    /**
     * 아이템 삭제 버튼 클릭
     */
    const onDeleteItem = index => {
      console.log('index', index);
      list.value.splice(index, 1);
      console.log('list.value', list.value);
    };

    /**
     * 순서 편집
     */
    const isFinishedDrag = () => {
      emit('is-finished-drag', bottomRollEditorRef.value.rows);
    };

    return {
      t,
      list,
      onDeleteItem,
      isFinishedDrag,
      bottomRollEditorRef,
    };
  },
});
</script>

<style scoped>
textarea {
  width: 906px;
  word-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;
}
</style>
