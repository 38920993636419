<template>
  <GSingleSelect
    v-model="model"
    :name="name"
    :item-label="itemLabel ? t('ArticleTypeSelect.형식') : ''"
    :options="options"
    track-by="id"
    label="label"
    :allow-empty="false"
    :searchable="false"
    :preselect-first="allOption"
    :placeholder="t('ArticleTypeSelect.형식')"
    :width="width"
    @select="onSelectValue"
  >
    <template #option="{ option }">
      <div class="flex items-center space-x-2">
        <ArticleTypeUI v-if="option.id" size="w-5 h-5" :item="option.id" />
        <div class="truncate">{{ option.name }}</div>
      </div>
    </template>
    <template #singleLabel="{ option }">
      <div class="flex items-center space-x-2">
        <ArticleTypeUI size="w-5 h-5" v-if="option.id" :item="option.id" />
        <div class="truncate">{{ option.name }}</div>
      </div>
    </template>
  </GSingleSelect>
</template>

<script>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { articleTypeCode } from '@/codes';
import { useModelWrapper } from '@/hooks/useModelWrapper';
import { GSingleSelect } from '@/components/ui';
import { cloneDeep } from 'lodash';
import { ArticleTypeUI } from '@/components/article/commonUI';

export default {
  name: 'ArticleTypeSelect',
  components: {
    GSingleSelect,
    ArticleTypeUI,
  },
  props: {
    modelValue: {
      type: [Object, String, Number],
      default: () => {
        return '';
      },
    },
    allOption: {
      type: Boolean,
      default: false,
    },
    itemLabel: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: 'w-34',
    },
    name: {
      type: String,
      default: 'type',
    },
  },
  emits: ['update:modelValue', 'select'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const model = useModelWrapper(props, emit, 'modelValue');

    const options = computed(() => {
      const list = cloneDeep(articleTypeCode.values);

      if (props.allOption) {
        list.unshift({
          id: '',
          label: t('ArticleTypeSelect.형식_전체'),
          name: t('ArticleTypeSelect.형식_전체'),
        });
      }

      return list;
    });

    const onSelectValue = value => {
      emit('select', value);
    };

    return {
      t,
      model,
      options,
      onSelectValue,
    };
  },
};
</script>
