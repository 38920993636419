import dayjs from 'dayjs';
import 'dayjs/locale/ko';
dayjs.locale('ko');

/**
 * 0~11인 달의 값을 1~12로 표시되도록 포멧
 * @param {number} month - 0으로 시작하는 값
 */
const monthFormat = (month: number) => {
  let mon: string = '';
  if (month >= 9) {
    mon = month + 1 + '';
  } else {
    mon = `0${month + 1}`;
  }

  return mon;
};

/**
 * 자릿수에 맞춰 원하는 문자열을 추가해서 새로운 문자열을 만든다.
 * @param {string} type - 문자열을 앞에 추가할지 뒤에 추가할지
 * @param {number, string} arg - 문자열
 * @param {number} digit - 자릿수
 * @param {string} addStr - 새롭게 추가할 문자열
 */
const paddingFormat = (
  type: string,
  arg: number | string,
  digit: number,
  addStr: string,
) => {
  if (type === 'start') {
    return arg.toString().padStart(digit, addStr);
  } else {
    return arg.toString().padEnd(digit, addStr);
  }
};

/**
 * 기본 날짜
 * @param {string | null | Date} date
 */
const defaultDate = (date: string | null | Date) => {
  if (date) {
    return dayjs(date).format('YYYY-MM-DD');
  }

  return dayjs().format('YYYY-MM-DD');
};

/**
 * 기본 날짜시간
 * @param {string | null | Date} date
 * @returns 'YYYY-MM-DD HH:mm:ss'
 */
const defaultDateTime = (dateTime: string | null | Date) => {
  if (dateTime) {
    return dayjs(dateTime).format('YYYY-MM-DD HH:mm:ss');
  }
  return dayjs().format('YYYY-MM-DD 00:00:00');
};

/**
 * 기본 날짜시간
 * @param {string | null | Date} date
 * @returns 'YYYY-MM-DD HH:mm'
 */
const defaultDateTimeHm = (dateTime: string | null | Date) => {
  if (dateTime) {
    return dayjs(dateTime).format('YYYY-MM-DD HH:mm');
  }
  return dayjs().format('YYYY-MM-DD 00:00');
};

/**
 * 날짜 표시 포멧
 * @param {string | null | Date} date
 * @returns 'YY-MM-DD HH:mm'
 */
const displayDateTimeHm = (dateTime: string | null | Date) => {
  if (dateTime) {
    return dayjs(dateTime).format('YY-MM-DD HH:mm');
  }
  return dayjs().format('YY-MM-DD 00:00');
};

/**
 * 시분초 포멧
 * @param {number} num 시분초에 해당하는 숫자
 * @return {string} 00:00:00
 */
const secondToHms = (num: number) => {
  if (!num) {
    return '00:00:00';
  }

  let hour: string | number = Math.floor(num / 3600);
  let minute: string | number = Math.floor((num % 3600) / 60);
  let second: string | number = Math.floor(num % 60);

  hour = hour.toString().padStart(2, '0');
  minute = minute.toString().padStart(2, '0');
  second = second.toString().padStart(2, '0');

  return `${hour}:${minute}:${second}`;
};

/**
 * 분초 포멧
 * @param {number} num 초 단위
 * @return {string} 00:00
 */
const secondToMs = (num: number) => {
  if (!num) {
    return '00:00';
  }

  let minute: string | number = Math.floor(Math.abs(num) / 60);
  let second: string | number = Math.floor(Math.abs(num) % 60);

  minute = minute.toString().padStart(2, '0');
  second = second.toString().padStart(2, '0');

  return num >= 0 ? `${minute}:${second}` : `-${minute}:${second}`;
};

/**
 * string -> byte 변경
 * @param {string} str 문자열
 * @return {number}
 */
const stringToByte = (str: string) => {
  if (!str) {
    return 0;
  }

  let character;
  let charBytes = 0;

  for (let i = 0; i < str.length; i++) {
    character = str.charAt(i);

    if (escape(character).length > 4) {
      charBytes = charBytes + 2;
    } else {
      charBytes = charBytes + 1;
    }
  }

  return charBytes;
};

// 요일 표시
const getWeekday = (date: any) => {
  let day = dayjs(date).locale('ko').format('dddd');
  return day;
};

// , 표시
const toComma = (target: number, locale = 'ko-KR') => {
  if (!target) {
    return target;
  }
  return target.toLocaleString(locale);
};

export {
  monthFormat,
  paddingFormat,
  defaultDate,
  defaultDateTime,
  defaultDateTimeHm,
  displayDateTimeHm,
  secondToHms,
  stringToByte,
  secondToMs,
  getWeekday,
  toComma,
};
