<template>
  <GModal
    :changed="false"
    :dialog-overlay="false"
    min-width="min-w-[calc(100vw-710px)]"
    max-width="sm:max-w-full"
    :width="width"
    height="h-[calc(100vh-64px)]"
    @close="onClose"
  >
    <!-- title -->
    <template #title>
      <span class="text-white font-D2CodingBold">{{ setTitle }}</span>
    </template>

    <!-- 내용 -->
    <template #content>
      <!-- 템플릿 생성 -->
      <CueSheetTemplateEditor
        v-if="mode === 'create'"
        :template-id="templateId"
        @close="onClose"
      />
      <!-- 템플릿 수정 -->
      <EditCueSheetTemplate
        v-if="!cuesheetItemId && mode === 'edit'"
        :template-id="templateId"
        :template-item-id="templateItemId"
        @close="onClose"
      />

      <!-- 큐시트 아이템(항목, 템플릿, 기사)-->
      <EditCueSheetItem
        v-if="cuesheetItemId"
        :cuesheet-id="cuesheetId"
        :cuesheet-item-id="cuesheetItemId"
        :template-id="templateId"
        :mode="mode"
        :is-cuesheet="true"
        @close="onClose"
      />
    </template>
  </GModal>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue';
import { GModal } from '@/components/ui';
import {
  CueSheetTemplateEditor,
  EditCueSheetTemplate,
  EditCueSheetItem,
} from '@/components/cueSheet';
// import EditCueSheetTemplate from '@/components/cueSheet/EditCueSheetTemplate.vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  width: {
    type: String,
    default: '',
  },
  templateId: {
    type: [String, Number],
    default: '',
  },
  templateItemId: {
    type: [String, Number],
    default: '',
  },
  cuesheetId: {
    type: [String, Number],
    default: '',
  },
  cuesheetItemId: {
    type: [String, Number],
    default: '',
  },
  mode: {
    type: String,
    default: '',
  },
});
const emit = defineEmits(['close']);

const { t } = useI18n();
const onClose = () => {
  emit('close');
};

const setTitle = computed(() => {
  let actionType;

  if (props.mode === 'create') {
    actionType = '템플릿_작성';
  } else if (props.mode === 'edit') {
    actionType = props.cuesheetItemId ? '아이템_수정' : '템플릿_수정';
  } else {
    actionType = props.cuesheetItemId ? '아이템_보기' : '템플릿_보기';
  }

  return t(`Modal.Cuesheet.CuesheetTemplateModal.${actionType}`);
});
</script>

<style lang="scss" scoped></style>
