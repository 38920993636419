<template>
  <svg
    height="32"
    id="icon"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28,16v6H4V6h7V4H4A2,2,0,0,0,2,6V22a2,2,0,0,0,2,2h8v4H8v2H24V28H20V24h8a2,2,0,0,0,2-2V16ZM18,28H14V24h4Z"
      transform="translate(0 0)"
    />
    <path
      d="M18,18h-.01a1,1,0,0,1-.9511-.7253L15.2456,11H11V9h5a1,1,0,0,1,.9615.7252l1.0742,3.7589,3.0088-9.7783A1.0142,1.0142,0,0,1,22,3a.98.98,0,0,1,.9487.6838L24.7207,9H30v2H24a1,1,0,0,1-.9487-.6838l-1.0132-3.04L18.9556,17.2942A1,1,0,0,1,18,18Z"
      transform="translate(0 0)"
    />
    <rect
      class="cls-1"
      data-name="&lt;Transparent Rectangle&gt;"
      height="32"
      id="_Transparent_Rectangle_"
      width="32"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MonitoringIcon',
  props: {},
  setup() {
    return {};
  },
});
</script>

<style scoped>
.cls-1 {
  fill: none;
}
</style>
