<template>
  <div class="flex space-x-2 p-2 rounded-md bg-gray-100 items-center">
    <div class="text-xs">
      <GInput
        v-model="title"
        name="title"
        :title="title"
        :disabled="true"
        placeholder="제목을 입력하세요."
        text-size="text-[15px]"
      />
    </div>
    <!-- 프로그램 -->
    <ProgramSelect
      v-model="program"
      :company-code="companyId"
      :disabled="true"
    />
    <GRadioButton
      v-model="option"
      name="option"
      :label="`2단`"
      :value="`2단`"
      font-size="text-sm"
      :disabled="true"
      :checked="option === '2단'"
    />
    <GRadioButton
      v-model="option"
      name="option"
      :label="`3단`"
      :value="`3단`"
      font-size="text-sm"
      :disabled="true"
      :checked="option === '3단'"
    />
  </div>
  <div ref="externalTable"></div>
</template>

<script setup>
import { defineProps, onMounted, ref, defineExpose } from 'vue';
import { /*GButton,*/ GRadioButton, GInput } from '@/components/ui';
import { ProgramSelect } from '@/components/select';
import { useUserInfo } from '@/hooks/use-user-info';
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import 'tabulator-tables/dist/css/tabulator.min.css';
// xlsx파일 다운로드하기 위해 import 필요
import XLSX from 'xlsx/dist/xlsx.full.min.js';

window.XLSX = XLSX;

const props = defineProps({
  tableData: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

const option = ref(`2단`);
const title = ref();
const program = ref();
const { companyId } = useUserInfo();

const tabulator = ref();
// 데이터 준비
const tableData = ref();
// 테이블 헤더
const tableColumns = ref();

// Tabulator
const externalTable = ref(null);

const setTable = () => {
  // Tabulator 옵션 설정
  const tableOptions = {
    data: tableData.value,
    columns: tableColumns.value,
    layout: 'fitColumns',
    height: 'calc(100vh - 197px)',
    backgroundColor: '#fff',
    printAsHtml: true, //enable html table printing
    printHeader: `<div style="text-align: center; font-weight: bold; font-size: 20px; padding-top: 4px; padding-bottom: 4px;">${props.tableData.monitoringTitle}<div>
                  <div style="text-align: center; font-weight: bold; font-size: 14px; padding-bottom: 4px;">${props.tableData.programName}<div>`,
    printConfig: {
      printVisibleRows: true,
      columnCalcs: false, //인쇄된 테이블에 열 계산을 포함하지 않음
    },
    rowContextMenu: [
      {
        disabled: false,
        label: '내보내기',
        menu: [
          {
            label: 'CSV',
            action: function () {
              tabulator.value.download('csv', `${title.value}.csv`, {
                bom: true,
              });
            },
          },
          {
            label: 'Excel',
            action: function () {
              tabulator.value.download('xlsx', `${title.value}.xlsx`, {}); //download a xlsx file that has a sheet name of "MyData"
            },
          },
        ],
      },
    ],
  };

  tabulator.value = new Tabulator(externalTable.value, tableOptions);
};

const onPrint = () => {
  tabulator.value.print(`all`);
};

onMounted(() => {
  // 데이터 설정
  title.value = props.tableData.monitoringTitle;
  program.value = props.tableData.programId;
  option.value = props.tableData.monitoringType;
  tableColumns.value = JSON.parse(
    props.tableData.monitoringContent,
  ).columns.map(item => {
    return {
      ...item,
      editor: false,
      editableTitle: false,
      headerContextMenu: [],
    };
  });
  tableData.value = JSON.parse(props.tableData.monitoringContent).rows;

  // tabulator 세팅
  setTable();
});

defineExpose({
  onPrint,
});
</script>

<style scoped>
/* @import 'tabulator-tables'; */
/* Simple theme CDN을 사용하여 스타일시트를 불러옴 */
@import url('https://cdnjs.cloudflare.com/ajax/libs/tabulator/5.5.0/css/tabulator_simple.min.css');

.tabulator {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
</style>
