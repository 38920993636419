import { AxiosInstance } from 'axios';

const PREFIX = '/program';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 프로그램 목록 조회
     * @param {string} delYn 삭제 여부
     * @param {number} page 가져올 페이지 번호
     * @param {string} searchValue 검색 키워드
     * @param {number} size 총 가져올 데이터 수
     */
    findAll: async (params: Object) => {
      return $axios
        .get(`${PREFIX}`, { params })
        .then(response => response.data);
    },

    /**
     * 프로그램 상세 조회
     * @param id - 방송 프로그램 아이디
     */
    findInfo: async (id: String) => {
      return $axios.get(`${PREFIX}/${id}`).then(response => response.data);
    },

    /**
     * 프로그램 추가
     * @param {object} params 프로그램 내용
     */
    create: async (params: Object) => {
      return $axios.post(`${PREFIX}`, params).then(response => response.data);
    },

    /**
     * 프로그램 수정
     * @param id - 방송 프로그램 아이디
     * @param params - 수정 내용
     */
    update: async (id: number, params: Object) => {
      return $axios
        .put(`${PREFIX}/${id}`, params)
        .then(response => response.data);
    },

    /**
     * 프로그램 삭제
     * @param id - 방송 프로그램 아이디
     */
    delete: async (id: number) => {
      return $axios.delete(`${PREFIX}/${id}`).then(response => response.data);
    },

    /**
     * 프로그램 복구
     * @param id - 방송 프로그램 아이디
     */
    recovery: async (id: number) => {
      return $axios
        .put(`${PREFIX}/${id}/recovery`)
        .then(response => response.data);
    },
  };
};
