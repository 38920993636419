import { AxiosInstance } from 'axios';
import authApi from './authApi';
import tokenApi from './tokenApi';

export default ($axios: AxiosInstance) => {
  return {
    userAuth: authApi($axios),
    tokenApi: tokenApi($axios),
  };
};
