<script setup lang="ts"></script>

<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="30" height="30" rx="15" fill="#F1F5F9" />
    <g clip-path="url(#clip0_349_3483)">
      <path
        d="M21.3333 19.7501C22.2042 19.7501 22.9088 19.0376 22.9088 18.1667L22.9167 10.2501C22.9167 9.37925 22.2042 8.66675 21.3333 8.66675H8.66667C7.79583 8.66675 7.08333 9.37925 7.08333 10.2501V18.1667C7.08333 19.0376 7.79583 19.7501 8.66667 19.7501H5.5V21.3334H24.5V19.7501H21.3333ZM8.66667 10.2501H21.3333V18.1667H8.66667V10.2501Z"
        fill="#0061FF"
      />
      <path
        opacity="0.3"
        d="M8.66699 10.3335H21.3337V18.1113H8.66699V10.3335Z"
        fill="#F1F5F9"
      />
    </g>
    <defs>
      <clipPath id="clip0_349_3483">
        <rect
          width="19"
          height="18.6667"
          fill="white"
          transform="translate(5.5 5.66675)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped></style>
