<template>
  <div class="border rounded-md bg-white overflow-auto">
    <GTable
      :list="historyList"
      :draggable="false"
      :multiselect="false"
      :is-loading="isLoading"
      :pagination="{ isShow: false }"
      :height="height"
      :no-data-message="t('CuesheetHistoryList.데이터가_없습니다')"
    >
      <template #header>
        <th class="p-3">{{ t('CuesheetHistoryList.순번') }}</th>
        <th class="p-2">{{ t('CuesheetHistoryList.생성일') }}</th>
        <th class="p-2">{{ t('CuesheetHistoryList.수정자') }}</th>
        <th class="p-2">{{ t('CuesheetHistoryList.수정이력') }}</th>
        <th class="p-2">{{ t('CuesheetHistoryList.제목') }}</th>
      </template>

      <template #list="{ row: row, index }">
        <td class="px-3 w-10">{{ index + 1 }}</td>
        <td class="p-2 w-36">{{ row.inputDateTime }}</td>
        <td class="p-2 w-24">{{ row.inputerName }}</td>
        <td class="p-2">{{ row.historyMessage }}</td>
        <td class="p-2">{{ row.historyTitle }}</td>
      </template>
    </GTable>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { GTable } from '@/components/ui';
import { useI18n } from 'vue-i18n';

defineProps({
  historyList: {
    type: Array,
    default: () => [],
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  height: {
    type: String,
    default: '',
  },
});

const { t } = useI18n();
</script>

<style scoped></style>
