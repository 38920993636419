import { AxiosInstance } from 'axios';

const PREFIX = '/schedule';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 일정 목록 조회
     * @param {Object} option - 목록 조회 query object
     * @param {String} option.startDate - 검색 시작 날짜
     * @param {String} option.endDate - 검색 종료 날짜
     */
    findAll: async (params: any) => {
      return $axios
        .get(`${PREFIX}`, { params })
        .then(response => response.data);
    },

    /**
     * 일정 정보 상세 조회
     * @param {Number} scheduleId - 일정 ID
     */
    findOne: async (scheduleId: number) => {
      return $axios
        .get(`${PREFIX}/${scheduleId}`)
        .then(response => response.data);
    },

    /**
     * 일정 생성
     * @param {Object} options - 생성에 필요한 request body
     * @param {String} options.schdMngTitl - 일정 제목
     * @param {String} options.schdMngCtt - 일정 내용
     * @param {String} options.startDtm - 일정 시작 날짜
     * @param {String} options.endDtm - 일정 종료 날짜
     */
    create: async (params: any) => {
      return $axios.post(`${PREFIX}`, params).then(response => response.data);
    },

    /**
     * 일정 수정
     * @param {Number} scheduleId - 일정 ID
     * @param {Object} options - 수정에 필요한 request body
     * @param {String} options.schdMngTitl - 일정 제목
     * @param {String} options.schdMngCtt - 일정 내용
     * @param {String} options.startDtm - 일정 시작 날짜
     * @param {String} options.endDtm - 일정 종료 날짜
     */
    update: async (scheduleId: number, params: any) => {
      return $axios
        .put(`${PREFIX}/${scheduleId}`, params)
        .then(response => response.data);
    },

    /**
     * 일정 삭제
     * @param {Number} scheduleId - 일정 ID
     */
    delete: async (scheduleId: number) => {
      return $axios
        .delete(`${PREFIX}/${scheduleId}`)
        .then(response => response.data);
    },
  };
};
