/**
 * VideoPlayers.vue, PartialControlBox.vue 사용
 */

/**
 * 타임(초) 형식의 문자열을 타임(초) 시간으로 반환
 *
 * @param (string)point
 */
export function parse(timeString) {
  if (
    !timeString ||
    timeString === '' ||
    timeString === '00:00:00' ||
    timeString === '00:00'
  ) {
    return 0;
  }
  const timeParts = timeString.split(':');
  let time = 0;

  if (timeParts.length === 3) {
    time = (
      Number(timeParts[0]) * 3600 +
      Number(timeParts[1]) * 60 +
      Number(timeParts[2])
    ).toFixed(0);
  } else if (timeParts.length === 2) {
    let min = parseInt((Number(timeParts[0]) * 60).toFixed());
    let sec = parseInt(Number(timeParts[1]).toFixed());

    time = min + sec;
  }

  return time;
}

/**
 * 타임(초) 형식을 문자열로 반환
 *
 * @param (datatime)times
 * @param (string)type : '00:00' -> 2, '00:00:00' -> null/undefined
 */
export function toTimeString(times, type) {
  if (!times) {
    if (type === '2') {
      return '00:00';
    }
    return '00:00:00';
  }

  let time = 0;
  let hour = 0;
  let min = 0;
  let sec = 0;

  if (type === '2') {
    time = parseInt(times, 10);
    hour = parseInt(time / 3600, 10);
    min = parseInt((time % 3600) / 60, 10);
    sec = time % 60;

    if (hour.toString().length === 1) {
      hour = `0${hour}`;
    }
    if (min.toString().length === 1) {
      min = `0${min}`;
    }
    if (sec.toString().length === 1) {
      sec = `0${sec}`;
    }

    if (hour === '00') {
      return `${min}:${sec}`;
    } else {
      return `${hour}:${min}:${sec}`;
    }
  } else {
    time = parseInt(times, 10);
    hour = parseInt(time / 3600, 10);
    min = parseInt((time % 3600) / 60, 10);
    sec = time % 60;

    if (hour.toString().length === 1) {
      hour = `0${hour}`;
    }
    if (min.toString().length === 1) {
      min = `0${min}`;
    }
    if (sec.toString().length === 1) {
      sec = `0${sec}`;
    }

    return `${hour}:${min}:${sec}`;
  }
}

/**
 * 타임(초) 형식만 가능
 *
 * @param (string)point
 */
export function toTimeFormat(value, format) {
  let num = value;
  num = num.replace(/[^0-9]/g, '');

  // '00:00' 형식
  if (format === '2') {
    return num.replace(/(\d{2})(\d{2})/, '$1:$2');
  }
  return num.replace(/(\d{2})(\d{2})(\d{2})/, '$1:$2:$3');
}

/**
 * 타임(초) 형식의 문자열을 타임(초) 시간으로 반환
 *
 * @param (string)point
 */
export function textTimeParse(timeString) {
  if (!timeString || timeString === '' || timeString === '00:00:00') return 0;

  const timeParts = timeString.split(':');
  const length = timeParts.length - 1;
  let time = 0;
  if (length <= 2) {
    time = 0;
    let x = 0;
    let second = 0;
    let number = 0;
    for (let i = length; i >= 0; i -= 1) {
      second = 60 * x;
      number = Number(timeParts[i]);
      if (second > 0) time += number * second;
      else time += number;

      x += 1;
    }
  }
  return time.toFixed(0);
}
