<template>
  <div>
    <GMovableModal
      max-height="[650px]"
      bottom="90px"
      right="132px"
      :disable="isDisable"
      @close="onClose"
    >
      <template #title>
        <!-- title -->
        <span class="text-white font-D2CodingBold text-sm">
          {{ t('Modal.Article.ArticleLogModal.기사_이력') }} [
          {{ actionStatus }} {{ updateDate }} ]
        </span>
      </template>

      <template #content>
        <div class="">
          <!-- 내용 -->
          <div class="flex justify-center py-2 h-[650px]">
            <div v-if="isLoading">
              <PageLoader />
            </div>
            <div v-if="!isLoading" class="flex flex-col h-full p-2 space-y-10">
              <div>
                <div class="flex space-x-1 text-xs pb-1.5">
                  <GInput
                    v-model="title"
                    name="title"
                    :title="title"
                    :disabled="true"
                    text-size="text-[15px]"
                  />

                  <!-- 기사 복사 -->
                  <GButton
                    v-tooltip="'자막 복사'"
                    icon="clipboard-document-list"
                    @click="onClickedCaptionCopy"
                  />

                  <!-- 기사 복사 -->
                  <GButton
                    v-tooltip="'기사 복사'"
                    icon="clipboard-document"
                    @click="onClickedArticleCopy"
                  />
                </div>

                <div class="overflow-y-auto h-[510px] rounded-sm">
                  <ArticleEditor
                    v-if="type"
                    ref="articleEditor"
                    :type-id="type"
                    v-model:reporter-text-array="
                      articleEditorState.reporterTextArray
                    "
                    v-model:anchor-text-array="
                      articleEditorState.anchorTextArray
                    "
                    v-model:caption-array="
                      articleEditorState.reporterCaptionArray
                    "
                    :reporter-editor-read-only="true"
                    :reporter-caption-read-only="true"
                    :anchor-editor-read-only="true"
                    :anchor-caption-read-only="true"
                  />
                </div>
              </div>

              <!-- 버튼 -->
              <div class="space-x-2 flex justify-end mt-2 pr-2">
                <GButton @click="onClose">{{
                  t('Modal.Article.ArticleLogModal.확인')
                }}</GButton>
              </div>
            </div>
          </div>
        </div>
      </template>
    </GMovableModal>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, onMounted, watch } from 'vue';
import { GButton, GInput, GMovableModal } from '@/components/ui';
import { useI18n } from 'vue-i18n';
import { useMarsLApi } from '@/apis/mars-l-api';
import ArticleEditor from '@/components/article/ArticleEditor';
import { setEditorValues } from '@/utils/editor-helper';
import PageLoader from '@/components/common/PageLoader.vue';
import { displayDateTimeHm } from '@/utils/format.ts';

const { t } = useI18n();
const marsLApi = useMarsLApi();
const isLoading = ref(false);

const props = defineProps({
  articleId: {
    type: [String, Number],
    default: () => {
      return 0;
    },
  },
  actionId: {
    type: [String, Number],
    default: () => {
      return 0;
    },
  },
});

const emit = defineEmits(['close']);

const type = ref(null);
const title = ref(null);
const actionStatus = ref(null);
const updateDate = ref(null);
const articleEditorState = ref({
  anchorTextArray: [''],
  anchorCaptionArray: [[]], // c/t형태: [[{}],[{}],[{}]...]
  reporterTextArray: [''],
  reporterCaptionArray: [[]],
});
const isDisable = ref(false);

const articleEditor = ref();

const setStatus = type => {
  let message = ' ';
  switch (type) {
    case 'create':
      message = '생성';
      break;
    case 'update':
      message = '수정';
      break;
    case 'copy':
      message = '복사';
      break;
    case 'forced unlock':
      message = '잠금 해제';
      break;
    case 'approval_article':
      message = '기사 출고';
      break;
    case 'approval_article_and_caption':
      message = '기사+자막 출고';
      break;
    case 'approval_none':
      message = '출고취소';
      break;
    case 'match':
      message = '큐시트 매칭';
      break;
  }

  return message;
};

// 액션로그 상세 가져오기
const getActionLogDetail = async () => {
  try {
    isLoading.value = true;
    const response = await marsLApi.coverDesk.log.findOne(
      props.articleId,
      props.actionId,
    );

    if (response.success) {
      const {
        artclTitl,
        artclTypCd,
        ancMentCtt,
        artclCtt,
        ancCapInfo,
        artclCapInfo,
        artclAction,
        artclUpdtDtm,
      } = response.data;

      type.value = artclTypCd;
      title.value = artclTitl;
      actionStatus.value = setStatus(artclAction);
      updateDate.value = displayDateTimeHm(artclUpdtDtm);
      articleEditorState.value = setEditorValues({
        artclTypCd,
        anchorCapList: JSON.parse(ancCapInfo) || [],
        articleCapList: JSON.parse(artclCapInfo) || [],
        ancMentCtt: ancMentCtt || JSON.stringify(['\n']),
        artclCtt: artclCtt || JSON.stringify(['\n']),
      });
    }
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

const onClickedCaptionCopy = () => {
  articleEditor.value.onCopyToCaption();
};

const onClickedArticleCopy = () => {
  articleEditor.value.onCopyToArticle();
};

watch(
  () => props.actionId,
  (newActionId, oldActionId) => {
    if (newActionId && oldActionId && oldActionId !== newActionId) {
      getActionLogDetail();
    }
  },
);

// 모달 닫기
const onClose = () => {
  isDisable.value = false;
  emit('close');
};

onMounted(() => {
  getActionLogDetail();
});
</script>
