import {
  AxiosError as AxiosErrorType,
  InternalAxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import axios from 'axios';
import { captureException } from '@sentry/vue';

/**
 * Axios 에러 커스텀
 */
export class AxiosError<T = unknown> extends AxiosErrorType {
  config: InternalAxiosRequestConfig;

  code?: string;

  request?: any;

  response?: AxiosResponse<T>;

  isAxiosError: boolean;

  toJSON: () => any;

  constructor(error: AxiosError<T>, message?: string) {
    super(message ?? error.message);
    const errorStatus = error.response?.status || 0;

    this.name = getErrorName(errorStatus);
    this.stack = error.stack;
    this.config = error.config;
    this.code = error.code;
    this.request = error.request;
    this.response = error.response;
    this.isAxiosError = error.isAxiosError;
    this.toJSON = error.toJSON;
  }
}

/**
 * HTTP 표준응답코드에 따라 에러 이름을 분기해서 반환하는 함수
 * @param status HTTP 표준응답 코드
 */
const getErrorName = (status: number) => {
  let name = 'Api Error';
  switch (status) {
    case 400:
      name = 'Bad Request';
      break;
    case 401:
      name = 'Unauthorized';
      break;
    case 403:
      name = 'Forbidden';
      break;
    case 404:
      name = 'Not Found';
      break;
    case 405:
      name = 'Method Not Allowed';
      break;
    case 500:
      name = 'Internal Server Error';
      break;
    case 503:
      name = 'Time Out';
      break;
    default:
      name = `[${status}] Api Error`;
  }

  return name;
};

/**
 * Axios 에러를 Sentry에 기록합니다.
 * @param { ApiError } error AxiosError를 상속받아 만든 커스텀 에러 클래스 인스턴스입니다.
 */
export const logAxiosError = (error: AxiosError) => {
  captureException(error, {
    level: 'error',
    extra: {
      header: error.config.headers || 'no value',
      params: error.config.params || 'no value',
      request: error.request || 'no value',
      response: error.response?.data || 'no value',
      type: 'network',
    },
  });
};

/**
 * Axios 에러를 slack에 전송 (web hook)
 */
export const sendAxiosErrorMessageToSlack = async (error: AxiosError) => {
  // 커스텀 에러 클래스 인스턴스
  const ApiErrorInstance = new AxiosError(error);

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    // 'Content-Type': 'application/json',
  };
  const errorMessage = `
          *Method*: ${ApiErrorInstance.request.__sentry_xhr_v2__.method}\n
          *URL*: ${ApiErrorInstance.request.__sentry_xhr_v2__.url}\n
          *Body*: ${ApiErrorInstance.request.__sentry_xhr_v2__.body}\n
          *ReleaseDate*: ${process.env.VUE_APP_RELEASE_DATE}\n
          `;

  const payload = {
    attachments: [
      {
        color: 'danger',
        pretext: `<https://gemiso-br.sentry.io/issues/?referrer=sidebar&statsPeriod=14d|Sentry Issues>`,
        text: ApiErrorInstance.name,
        fields: [
          {
            title: ApiErrorInstance.message,
            value: errorMessage,
            short: false,
          },
        ],
        // ts: dayjs().format('YYYY-MM-DD HH:mm'),
      },
    ],
  };
  await axios({
    method: 'POST',
    url: process.env.VUE_APP_SENTRY_WEB_HOOK_URL,
    headers: headers,
    data: JSON.stringify(payload), // event.message 등
  });
};
