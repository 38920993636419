import /*User ,*/ '@/types/models';
import { Module } from 'vuex';
import { RootState } from '..';
import jwtHelper from '@/utils/jwt-helper';
import { useMarsLApi } from '@/apis/mars-l-api';

export type AuthState = {
  accessToken: string;
  refreshTokenRemainingTime: number | null;
  appAuthorityCodes: string[];
};

export const AuthModule: Module<AuthState, RootState> = {
  namespaced: true,
  state: () => ({
    accessToken: '',
    refreshTokenRemainingTime: null,
    appAuthorityCodes: [],
  }),
  getters: {
    isAuthenticated: state => {
      return !!state.accessToken;
    },
    getPayload: state => {
      if (!state.accessToken) {
        return {};
      }

      return jwtHelper.decodeJwt(state.accessToken);
    },
    getToken: state => {
      return state.accessToken;
    },
    getAuthorityCodes: state => {
      return state.appAuthorityCodes;
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    SET_TOKEN: ({ dispatch, commit }, accessToken) => {
      localStorage.setItem('accessToken', accessToken);
      commit('SET_TOKEN', accessToken);
    },
    CLEAR_TOKEN: ({ commit }) => {
      localStorage.removeItem('accessToken');
      commit('SET_TOKEN', '');
    },
    FETCH_APP_AUTHORITIES: async ({ commit }) => {
      const marsLApi = useMarsLApi();

      try {
        const { data } = await marsLApi.myInfo.authority.findAllCode();
        commit('SET_APP_AUTHORITY_CODES', data);
      } catch (e) {
        console.error(e);
      }
    },
    FETCH_REFRESH_TOKEN_REMAINING_TIME: async ({ commit }) => {
      const marsLApi = useMarsLApi();

      try {
        const { data } =
          await marsLApi.auth.tokenApi.getRemainingTimeOfRefreshToken();
        commit('SET_REFRESH_TOKEN_REMAINING_TIME', data);
      } catch (e) {
        console.error(e);
      }
    },
  },
  mutations: {
    SET_TOKEN(state, accessToken) {
      state.accessToken = accessToken;
    },
    SET_APP_AUTHORITY_CODES(state, codes) {
      state.appAuthorityCodes = codes;
    },
    SET_REFRESH_TOKEN_REMAINING_TIME(state, remainingTime) {
      state.refreshTokenRemainingTime = remainingTime;
    },
  },
};
