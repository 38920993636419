<template>
  <div class="" :class="!isModal ? 'px-2 py-1' : ''">
    <!-- 버튼 -->
    <div
      class="flex border-b py-1 px-1.5 justify-end items-center space-x-1.5"
      :class="!isModal ? 'pb-2' : ''"
    >
      <!-- 출고상태 -->
      <div class="pr-2">
        <ArticleStatusUI
          :approve-code="approveTypeCd"
          :is-cuesheet-matching="isMatchingCuesheet"
        />
      </div>
    </div>
    <!-- 내용 -->
    <div v-if="isLoading" class="pt-20"><PageLoader /></div>
    <div
      v-if="!isLoading"
      class="flex h-[calc(100vh-144px)] justify-center border-gray-200 bg-slate-100"
    >
      <div class="flex">
        <!-- 기사 작성 영역 -->
        <div class="flex-1 border-r border-gray-300 bg-white">
          <div class="p-1.5 space-y-1">
            <!-- 제목 -->
            <div class="text-xs pb-1">
              <GInput
                v-model="title"
                name="title"
                :title="title"
                :disabled="true"
                placeholder="제목을 입력하세요."
                text-size="text-[15px]"
              />
            </div>

            <!-- 작성기 영역 -->
            <div class="overflow-y-auto h-[calc(100vh-200px)]">
              <ArticleEditor
                ref="articleEditor"
                v-if="type"
                :type-id="type"
                v-model:reporter-text-array="
                  articleEditorState.reporterTextArray
                "
                v-model:anchor-text-array="articleEditorState.anchorTextArray"
                v-model:caption-array="articleEditorState.reporterCaptionArray"
                :reporter-editor-read-only="true"
                :reporter-caption-read-only="true"
                :anchor-editor-read-only="true"
                :anchor-caption-read-only="true"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- 메타 데이터 영역 -->
      <div class="w-[27.438rem] bg-slate-200">
        <GTabPanel :tabs="tabs" :selected-tab-id="selectedTabId">
          <TabPanel>
            <template v-slot="{ selected }">
              <ArticleMetaData
                v-if="selected"
                v-model:article-type="articleType"
                v-model:article-reporter="reporter"
                v-model:article-program="program"
                v-model:total-article-time="totalArticleTime"
                v-model:article-division="division"
                v-model:article-embargo="embargo"
                v-model:article-category="category"
                v-model:article-tag="tag"
                v-model:article-extra-time="extraTime"
                v-model:article-inputer="inputer"
                v-model:article-transfer-date="transferDate"
                v-model:article-approve-date="approveDate"
                v-model:article-updateDate="updateDate"
                :article-channel-id="articleChannelId"
                article-mode="view"
              />
            </template>
          </TabPanel>
        </GTabPanel>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { RouterName } from '@/types/router';
import { GTabPanel, GInput } from '@/components/ui';
import { useI18n } from 'vue-i18n';
import { TabPanel } from '@headlessui/vue';
import { ArticleMetaData } from '@/components/article';
import ArticleEditor from '@/components/article/ArticleEditor';
import {
  articleTypeCode,
  articleDivisionCode,
  articleApproveCode,
} from '@/codes';
// import { useMarsLApi } from '@/apis/mars-l-api';
import { setEditorValues } from '@/utils/editor-helper';
import {
  defaultDate,
  defaultDateTime,
  displayDateTimeHm,
  secondToMs,
} from '@/utils/format';
import PageLoader from '@/components/common/PageLoader.vue';
import { cloneDeep } from 'lodash';
import { ArticleStatusUI } from '@/components/article/commonUI';
import { isMyCompany } from '@/utils/compare-helper';
import { convertKeysToCamelCase } from '@/utils/str';

export default defineComponent({
  name: RouterName.ViewArticle,
  components: {
    ArticleEditor,
    GTabPanel,
    TabPanel,
    GInput,
    ArticleMetaData,
    PageLoader,
    ArticleStatusUI,
  },
  props: {
    id: {
      type: [String, Number],
      default: 0,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    isCuesheet: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const route = useRoute();
    // const marsLApi = useMarsLApi();

    const isLoading = ref(false);
    const articleEditor = ref();
    const article = ref(null);
    const articleEditorState = ref({
      anchorTextArray: [''],
      anchorCaptionArray: [[]], // c/t형태: [[{}],[{}],[{}]...]
      reporterTextArray: [''],
      reporterCaptionArray: [[]],
    });
    const title = ref(); // 제목
    const type = ref(null); // 초기값은 null로 설정해야 한다.
    const articleType = ref(null); // 형식
    const reporter = ref(); // 기자
    const program = ref(); // 프로그램
    const division = ref(); // 그분
    const category = ref(); // 카테고리
    const embargo = ref(); // 엠바고
    const tag = ref([]); // 태그
    const extraTime = ref('00:00');
    const inputer = ref(null); // 작성자
    const transferDate = ref(null); // 송고일시
    const approveDate = ref(null); // 출고일시
    const updateDate = ref(); // 작성일시
    const lock = ref(null); // 락
    const approveTypeCd = ref(null);
    const isMatchingCuesheet = ref(false);

    const tabs = ref([
      { id: 1, name: '메타데이터' },
      // { id: 2, name: '첨부파일' },
      // { id: 3, name: '링크' },
      // { id: 4, name: '이력' },
    ]);
    let selectedTabId = ref(0);
    const isShowReferenceModal = ref(false);
    const isShowSpecialCharModal = ref(false);
    const totalArticleTime = ref(0);
    const articleChannelId = ref(0);

    const printData = ref([]);
    const isShowPrintOptionModal = ref(false);

    // 기사 구분에 따른 날짜 포멧
    const setEmbargoDate = (articleDiv, articleDivDate) => {
      let date = null;

      if (articleDiv === articleDivisionCode.expected.id) {
        date = defaultDate(articleDivDate);
      } else if (articleDiv === articleDivisionCode.embargo.id) {
        date = defaultDateTime(articleDivDate);
      }

      return date;
    };

    // 초기화
    const initData = async () => {
      try {
        isLoading.value = true;
        let anchorCapList = []; // 내기사, 취재데스크의 response CapList key값이 다름
        let articleCapList = [];
        console.log('props.id', props.id);
        // const response = await marsLApi.seoulMBC.article.findOne(props.id);
        const response = convertKeysToCamelCase({
          artcl_id: '20230705133453AE21488',
          artcl_titl: 'test',
          artcl_ctt: '',
          dept_id: '20210903102700DP01984',
          dept_nm: 'MARS CLIENT',
          artcl_st_cd: '02',
          artcl_st_nm: '출고',
          artcl_typ_cd: '02',
          artcl_typ_nm: '리포트',
          artcl_div_cd: '01',
          artcl_div_nm: '일반',
          attc_file_yn: 'N',
          cap_st_cd: '02',
          cap_st_nm: '출고',
          artcl_reqd_time: 1,
          rptr_id: 'gsG05',
          rptr_nm: '제미니G05',
          brdc_pgm_id: null,
          brdc_pgm_nm: '미지정',
          copy_ord: '0',
          embg_dtm: null,
          rmk: null,
          anc_ment_ctt: '',
          brdc_schd_dt: '20230705000000',
          inputr_id: 'gsG05',
          inputr_nm: '제미니G05',
          input_dtm: '20230705135102',
          updtr_id: 'gsG05',
          updtr_nm: '제미니G05',
          updt_dtm: '20230705135111',
          del_yn: null,
          delr_id: null,
          delr_nm: null,
          del_dtm: null,
          lck_yn: 'N',
          lck_user_id: null,
          lck_user_nm: null,
          lck_dtm: null,
          artcl_relse_dtm: '20230705135109',
          relser_id: null,
          cap_relse_dtm: '20230705135111',
          evaltin_score_cd: null,
          evaltinr_id: null,
          evaltinr_nm: null,
          evaltin_dtm: null,
          artcl_ctts: [
            '\r\nsssss\r\n\r\nxxxx\r\n\r\n\r\nvvvv\r\n\r\nbbb\r\n\r\n\r\n\r\n',
          ],
          anc_ments: ['test\r\n\r\n\r\n'],
          permit_yn: null,
          cap_permit_yn: null,
          permit_chk: false,
          artcl_cate_cd: null,
          artcl_cate_nm: null,
          video_yn: 'N',
          region_cmpy_cd: null,
          region_cmpy_nm: null,
          cap_fnsh_yn: 'N',
          snd_div_cd: null,
          snd_div_nm: null,
          caps: [
            {
              artcl_cap_id: '20230705135111AE12663',
              ln_no: 5,
              cap_ord: 0,
              cap_ctt: '1번 자막\r\n',
              cap_tmplt_id: null,
              artcl_id: '20230705133453AE21488',
              cap_rmk: '\r\n',
              cap_ord_ln_num: 15,
            },
            {
              artcl_cap_id: '20230705135111AE12664',
              ln_no: 7,
              cap_ord: 0,
              cap_ctt: '2번 자막\r\n',
              cap_tmplt_id: null,
              artcl_id: '20230705133453AE21488',
              cap_rmk: '\r\n',
              cap_ord_ln_num: 15,
            },
            {
              artcl_cap_id: '20230705135111AE12665',
              ln_no: 10,
              cap_ord: 0,
              cap_ctt: '3번 자막\r\n',
              cap_tmplt_id: null,
              artcl_id: '20230705133453AE21488',
              cap_rmk: '\r\n',
              cap_ord_ln_num: 15,
            },
            {
              artcl_cap_id: '20230705135111AE12666',
              ln_no: 12,
              cap_ord: 0,
              cap_ctt: '4번 자막\r\n',
              cap_tmplt_id: null,
              artcl_id: '20230705133453AE21488',
              cap_rmk: '\r\n',
              cap_ord_ln_num: 15,
            },
          ],
          cap_list: [
            {
              artcl_cap_id: '20230705135111AE12663',
              ln_no: 5,
              cap_ord: 0,
              cap_ctt: '1번 자막\r\n',
              cap_tmplt_id: null,
              artcl_id: '20230705133453AE21488',
              cap_rmk: '\r\n',
              cap_ord_ln_num: 15,
              total_lines: 15,
            },
            {
              artcl_cap_id: '20230705135111AE12664',
              ln_no: 7,
              cap_ord: 0,
              cap_ctt: '2번 자막\r\n',
              cap_tmplt_id: null,
              artcl_id: '20230705133453AE21488',
              cap_rmk: '\r\n',
              cap_ord_ln_num: 15,
              total_lines: 15,
            },
            {
              artcl_cap_id: '20230705135111AE12665',
              ln_no: 10,
              cap_ord: 0,
              cap_ctt: '3번 자막\r\n',
              cap_tmplt_id: null,
              artcl_id: '20230705133453AE21488',
              cap_rmk: '\r\n',
              cap_ord_ln_num: 15,
              total_lines: 15,
            },
            {
              artcl_cap_id: '20230705135111AE12666',
              ln_no: 12,
              cap_ord: 0,
              cap_ctt: '4번 자막\r\n',
              cap_tmplt_id: null,
              artcl_id: '20230705133453AE21488',
              cap_rmk: '\r\n',
              cap_ord_ln_num: 15,
              total_lines: 15,
            },
          ],
          cue_link_yn: 'Y',
          files: [],
          src_artcl_id: null,
          org_inputr_id: null,
          extra_code_1: null,
        });

        anchorCapList = response.capList.map(caption => {
          return {
            capCtt: caption.capCtt,
            capTypCd: 'cap_001',
            lnNo: caption.lnNo,
            lnOrd: caption.capOrd,
            icon: '',
          };
        });

        articleCapList = response.capList.map(caption => {
          return {
            capCtt: caption.capCtt,
            capTypCd: 'cap_002',
            lnNo: caption.lnNo,
            lnOrd: caption.capOrd,
            icon: '',
          };
        });

        transferDate.value = displayDateTimeHm(response?.inputDtm); // 송고일시
        approveDate.value = response?.apprvTypDtm
          ? displayDateTimeHm(response?.apprvTypDtm)
          : null; // 출고일시
        isMatchingCuesheet.value = response?.cueMatchCnt > 0 ? true : false;

        // 에디터에 값 대입
        articleEditorState.value = setEditorValues({
          artclTypCd: response.artclTypCd,
          anchorCapList,
          articleCapList,
          ancMentCtt: response.ancMents
            ? JSON.stringify([...response.ancMents])
            : JSON.stringify([[]]),
          artclCtt: response.artclCtts
            ? JSON.stringify([...response.artclCtts])
            : JSON.stringify([[]]),
        });

        type.value = null;
        if (response.artclTypCd === '02') {
          type.value = articleTypeCode.Report.id;
        }
        title.value = response.artclTitl; // 제목
        // TODO :: 형식 ArticleMEtadata 컴포넌트에서 response.artclTypCd만 보냈을때 감지 안되는건 나중에..
        articleType.value = articleTypeCode.values.find(
          item => item.id === response.artclTypCd,
        );
        // 기자 (내 지역사이면 id, 다른 지역사이면 이름을 보낸다.)
        reporter.value = isMyCompany(articleChannelId)
          ? response.rptrId
          : response.rptrNm;
        // 프로그램 (내 지역사이면 id, 다른 지역사이면 이름을 보낸다.)
        program.value = isMyCompany(articleChannelId)
          ? response.brdcPgmId
          : response.brdcPgmNm;
        // TODO :: 구분 ArticleMEtadata 컴포넌트에서 response.artclDivCd만 보냈을때 감지 안되는건 나중에..
        division.value = articleDivisionCode.values.find(
          item => item.id === response.artclDivCd,
        );
        // 엠바고
        embargo.value = setEmbargoDate(
          response.artclDivCd,
          response.artclDivDtm,
        );
        extraTime.value = secondToMs(0);
        category.value = response.artclCateNm; // 카테고리
        inputer.value = response.inputrNm; // 작성자
        updateDate.value = displayDateTimeHm(response.updtDtm); // 업데이트 일시
        // 기사 시간
        totalArticleTime.value = secondToMs(article?.artclReqdTime);
        lock.value = {
          lckYn: response.lckYn,
          lckrId: response.lckrId,
        };
        approveTypeCd.value = response.apprvTypCd;
        articleChannelId.value = response.chDivId;
        // 데이터 원본
        const cloneArticleEditor = cloneDeep(articleEditorState.value);
        printData.value = [{ ...response, ...cloneArticleEditor }];
      } catch (error) {
        console.error('error', error);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      initData();
    });

    return {
      t,
      route,
      isLoading,
      article,
      articleTypeCode,
      articleApproveCode,
      articleEditorState,
      articleEditor,
      tabs,
      selectedTabId,
      isShowReferenceModal,
      isShowSpecialCharModal,
      isShowPrintOptionModal,
      type,
      totalArticleTime,
      title,
      articleType,
      reporter,
      program,
      division,
      category,
      embargo,
      tag,
      extraTime,
      inputer,
      approveDate,
      transferDate,
      updateDate,
      approveTypeCd,
      isMatchingCuesheet,
      articleChannelId,
      isMyCompany,
      initData,
      printData,
    };
  },
});
</script>
