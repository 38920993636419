import { isEmpty } from 'lodash';

export const GETTER_NAMES = Object.freeze({
  GET_PROGRAM_CODES: 'GET_PROGRAM_CODES',
});

export const GETTER = {
  [GETTER_NAMES.GET_PROGRAM_CODES]: (state: any) => {
    if (!state.programs || isEmpty(state.programs)) {
      return [];
    }

    return state.programs.map((v: any) => {
      return {
        ...v,
        id: v.id,
        name: v.brdcPgmNm,
        label: v.brdcPgmNm,
      };
    });
  },
};
