import { AxiosInstance } from 'axios';

const PREFIX = '/my-ext-link';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 외부 링크 목록 조회
     * @param {String} search - 검색 (링크/제목/비고 내 검색)
     */
    findAll: async (params: any) => {
      return $axios
        .get(`${PREFIX}`, { params })
        .then(response => response.data);
    },

    /**
     * 외부 링크 상세 조회
     * @param {Number} externalLinkId - 외부 링크 ID
     */
    findOne: async (externalLinkId: number) => {
      return $axios
        .get(`${PREFIX}/${externalLinkId}`)
        .then(response => response.data);
    },

    /**
     * 외부 링크 생성
     * @param {Object} params - 생성에 필요한 값
     */
    create: async (params: any) => {
      return $axios.post(`${PREFIX}`, params).then(response => response.data);
    },

    /**
     * 외부 링크 수정
     * @param {Number} externalLinkId - 외부 링크 ID
     * @param {Object} params - 수정에 필요한 값
     */
    update: async (externalLinkId: number, params: any) => {
      return $axios
        .put(`${PREFIX}/${externalLinkId}`, params)
        .then(response => response.data);
    },

    /**
     * 외부 링크 삭제
     * @param {Number} externalLinkId - 외부 링크 ID
     */
    delete: async (externalLinkId: number) => {
      return $axios
        .delete(`${PREFIX}/${externalLinkId}`)
        .then(response => response.data);
    },
  };
};
