export class CaptionModel {
  text = '';
  type = '';
  lineNo = 0;
  icon = '';

  constructor(text, type, lineNo, icon) {
    this.text = text;
    this.type = type;
    this.lineNo = lineNo;
    this.icon = icon;
  }
}
