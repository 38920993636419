import { AxiosInstance } from 'axios';

const PREFIX = '/my-article';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 내기사 복사
     * @param {number} articleId 기사 ID
     * @param {Object} param request body
     * @param {string} param.changeScheduledDayYn 방송예정일자를 변경할 경우 Y
     * @param {string} param.scheduledDay 방송예정일자 (YYYY-MM-DD HH:mm:ss)
     */
    copy: async (articleId: number, params: object) => {
      return $axios
        .post(`${PREFIX}/${articleId}/copy`, params)
        .then(response => response.data);
    },
  };
};
