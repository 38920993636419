import { MUTATION_NAMES } from './mutation';

export const ACTION_NAMES = Object.freeze({
  FETCH_ZOOM_IN_OUT_TOGGLE: 'FETCH_ZOOM_IN_OUT_TOGGLE',
  FETCH_IS_SHOW_MENU: 'FETCH_IS_SHOW_MENU',
});

export const ACTIONS = {
  [ACTION_NAMES.FETCH_ZOOM_IN_OUT_TOGGLE]: async ({ state, commit }: any) => {
    commit(MUTATION_NAMES.SET_ZOOM, !state.zoom);
  },
  [ACTION_NAMES.FETCH_IS_SHOW_MENU]: async (
    { commit }: any,
    isShowMenu: boolean,
  ) => {
    commit(MUTATION_NAMES.SET_IS_SHOW_MENU, isShowMenu);
  },
};
