
import { defineComponent, ref, onMounted } from 'vue';
import { UserCircleIcon } from '@/components/ui/icon/index';
import { GChip } from '@/components/ui';
import { MenuItems, MenuItem, Menu, MenuButton } from '@headlessui/vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { RouterName } from '@/types/router';
import { useMarsLApi } from '@/apis/mars-l-api';
import { useI18n } from 'vue-i18n';
import {
  Cog8ToothIcon,
  ArrowRightOnRectangleIcon,
} from '@heroicons/vue/24/outline';
import PreferencesModal from '@/components/modal/preferences/PreferencesModal.vue';
import { getCompanyName } from '@/utils/get-data-helper';
import { useUserInfo } from '@/hooks/use-user-info';

interface Navigation {
  name: string;
  action?: () => void;
}

export default defineComponent({
  name: 'UserProfile',
  components: {
    UserCircleIcon,
    MenuItems,
    MenuItem,
    Menu,
    MenuButton,
    Cog8ToothIcon,
    ArrowRightOnRectangleIcon,
    PreferencesModal,
    GChip,
  },
  props: {},
  emits: [],

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const marsLApi = useMarsLApi();
    const { companyId } = useUserInfo();
    const releaseDate = process.env.VUE_APP_RELEASE_DATE;

    const isShowPreferencesModal = ref(false);
    const company = ref(null);

    // 내 정보 가져오기
    const user = ref(store.getters['user/getUserInfo']);

    const navigationItems: Navigation[] = [
      {
        name: t('UserProfile.환경설정'),
        action: async () => {
          isShowPreferencesModal.value = true;
        },
      },
      {
        name: t('UserProfile.로그아웃'),
        action: async () => {
          // 성공/실패 상관없이 무조건 로그인 화면으로
          await marsLApi.auth.userAuth.logout();
          // 로그인 페이지로 이동
          router.push({ name: RouterName.Login });
          // 토큰 삭제
          store.dispatch('auth/CLEAR_TOKEN');
          // 탭 전부 삭제
          store.dispatch('tab/DELETE_ALL_TAB');
        },
      },
    ];

    onMounted(async () => {
      company.value = await getCompanyName(companyId);
    });

    return {
      t,
      user,
      company,
      releaseDate,
      isShowPreferencesModal,
      navigationItems,
    };
  },
});
