export const isEmptyObj = (obj: any): boolean => {
  if (!obj) {
    return true;
  }
  if (obj.constructor === Object && Object.keys(obj).length === 0) {
    return true;
  }

  return false;
};

export const isEquals = (obj1: any, obj2: any): boolean => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const cloneObj = (src: any): any => {
  const json = JSON.stringify(src);
  return JSON.parse(json);
};
