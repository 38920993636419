<template>
  <div class="pt-5 space-y-5 px-11 h-[28.625rem]">
    <RangeDatePickerEx v-model="dates" width="w-56" @change="onChangeDate" />

    <div class="border rounded-md bg-white overflow-auto h-[23rem]">
      <GTable
        :list="logList"
        :draggable="false"
        :multiselect="false"
        :pagination="{ isShow: false }"
        :isLoading="isLoading"
      >
        <template #header>
          <th class="p-3">{{ t('UserLog.순번') }}</th>
          <th class="p-3">{{ t('UserLog.접속일시') }}</th>
          <th class="p-3">{{ t('UserLog.상태') }}</th>
          <th class="p-3">{{ t('UserLog.장비') }}</th>
          <th class="p-3">{{ t('UserLog.접속IP') }}</th>
        </template>
        <template #list="{ row, index }">
          <td class="px-3 py-2">{{ index + 1 }}</td>
          <td class="px-3">{{ displayDateTimeHm(row.loginDate) }}</td>
          <td class="px-3">{{ row.loginStateCode }}</td>
          <td class="px-3">{{ row.appDivisionCode }}</td>
          <td class="px-3">{{ row.loginIp }}</td>
        </template>
      </GTable>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref, onMounted } from 'vue';
import RangeDatePickerEx from '@/components/common/RangeDatePickerEx';
import { GTable } from '@/components/ui';
import { useI18n } from 'vue-i18n';
import { useMarsLApi } from '@/apis/mars-l-api';
import { defaultDate, displayDateTimeHm } from '@/utils/format.ts';

defineProps({});
defineEmits([]);

const { t } = useI18n();
const marsLApi = useMarsLApi();

const isLoading = ref(false);
const dates = ref([defaultDate(), defaultDate()]);
const logList = ref([]);

// 사용자 로그 가져오기
const getUserLogs = async () => {
  isLoading.value = true;
  const params = {
    fromDate: dates.value[0],
    toDate: dates.value[1],
  };
  try {
    const response = await marsLApi.myInfo.log.findAll(params);

    if (response.success) {
      console.log('response.data', response.data);
      logList.value = response.data;
    }
  } catch (error) {
    console.error('error', error);
  } finally {
    isLoading.value = false;
  }
};

// 날짜 변경
const onChangeDate = getDates => {
  dates.value = getDates;

  getUserLogs();
};

onMounted(() => {
  getUserLogs();
});
</script>
