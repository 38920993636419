<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <div v-show="false">
    <div id="printContent" class="print-content">
      <template v-for="(content, index) in printList" :key="index">
        <table style="width: 100%">
          <thead style="page-break-before: always">
            <tr>
              <div class="article-title-section">
                {{ content?.artclTitl }}
              </div>
              <div class="article-meta-section border-gray">
                <div class="article-meta-group">
                  <div class="article-meta">
                    기사형태 :
                    {{ setArticleTypeName(content?.artclTypCd) }}
                  </div>
                  <div class="article-meta">
                    기자 : {{ content?.setReporterName }}
                  </div>
                </div>

                <div class="article-meta-group">
                  <div class="article-meta">
                    작성일시 :
                    {{ defaultDateTimeHm(content?.updtDtm) }}
                  </div>
                  <div class="article-meta">
                    기사시간 : {{ setArticleTime(content) }}
                  </div>
                </div>

                <div class="article-meta-group">
                  <div class="article-meta">
                    출고일시 :
                    {{
                      content?.apprvTypDtm
                        ? defaultDateTimeHm(content?.apprvTypDtm)
                        : ''
                    }}
                  </div>
                  <div class="article-meta">
                    {{ setArticleDivName(content?.artclDivCd) }}
                    {{
                      content?.artclDivCd !== articleDivisionCode.basic.id
                        ? setEmbargoTime(content)
                        : ''
                    }}
                  </div>
                </div>

                <!-- <div class="article-meta-group">
                  <div class="article-meta">
                    송고일시 :
                    {{
                      content?.artclTrnsfDtm
                        ? defaultDateTimeHm(content?.artclTrnsfDtm)
                        : ''
                    }}
                  </div>
                </div> -->

                <!-- <div class="article-meta-group">
                  <div class="article-meta">
                    자막출고 :
                    {{ content?.capFnshYn === 'Y' ? '완' : '' }}
                  </div>
                </div> -->
              </div>
            </tr>
          </thead>

          <tbody style="">
            <div class="content-wrap">
              <div v-for="(textArr, arrIdx) in content?.textList" :key="arrIdx">
                <div
                  style="padding: 5px 5px 5px 0"
                  :style="setStyle(content, arrIdx)"
                >
                  <div
                    v-for="(text, textArrIdx) in textArr"
                    :key="textArrIdx"
                    style="white-space: pre-wrap"
                  >
                    {{ text }}
                  </div>
                </div>
              </div>
            </div>
          </tbody>
        </table>
      </template>
    </div>
  </div>
</template>

<script setup>
import { defineProps, onMounted, ref } from 'vue';
import { useUserInfo } from '@/hooks/use-user-info';
import printJS from 'print-js';
import { articleDivisionCode, articleTypeCode } from '@/codes';
import { defaultDateTimeHm, defaultDate } from '@/utils/format';
import { flattenDeep } from 'lodash';
import {
  concatArrays,
  getArticleLineCount,
  getReporterName,
} from '@/utils/print';
import { secondToMs } from '@/utils/format.ts';

const props = defineProps({
  printData: {
    type: Array,
    default: () => {
      return [];
    },
  },
});

const emit = defineEmits(['close-print']);

const { userId, userName } = useUserInfo();
const printList = ref([]);

const setStyle = (content, arrIdx) => {
  if (content.artclTypCd !== 'typ_001') {
    if ((arrIdx + 1) % 2 !== 0) {
      return 'border-bottom: 1px dotted black';
    }
    return 'border-bottom: 1px solid black; margin-bottom: 10px';
  } else {
    if ((arrIdx + 1) % 2 === 0) {
      return 'border-bottom: 1px solid black; margin-bottom: 10px';
    }
  }
};

const setPrintData = () => {
  printList.value = props.printData.map(item => {
    // 기사내용을 바이트기준으로 잘라내기
    let textList = flattenDeep(
      concatArrays(
        item.anchorTextArray,
        item.reporterTextArray,
        item.artclTypCd,
      ),
    ).map(item => getArticleLineCount(item));

    return {
      ...item,
      setReporterName: getReporterName(item),
      artclTitl: item?.artclTitl || item?.cueItemTitl,
      artclTypCd: item?.artclTypCd,
      inputrNm: item?.inputrNm,
      inputrUserId: item?.inputrUserId,
      rptrNm: item?.rptrNm,
      deptNm: item?.deptNm,
      inputDtm: item?.inputDtm,
      updtDtm: item?.updtDtm,
      artclDivCd: item?.artclDivCd,
      artclDivDtm: item?.artclDivDtm,
      ancMentCttTime: item?.ancMentCttTime,
      artclCttTime: item?.artclCttTime,
      artclExtTime: item?.artclExtTime,
      artclTrnsfYn: item?.artclTrnsfYn,
      artclTrnsfDtm: item?.artclTrnsfDtm,
      apprvTypDtm: item?.apprvTypDtm, // 뉴스지정 필요
      capFnshYn: item?.capFnshYn,
      textList,
    };
  });
};

const printContent = () => {
  printJS({
    printable: 'printContent', // 프린트할 요소의 ID
    type: 'html', // 프린트할 내용의 타입
    css: ['/print/common.css', '/print/compressed-minus.css'],
    documentTitle: `${userName}(${userId})`, // 인쇄될 문서의 제목
    scanStyles: false,
    // onPrintDialogClose: onClosePrint,
  });
  setTimeout(() => {
    window.addEventListener('mouseover', handler);
  }, 1000);
};

const handler = () => {
  // Make sure the event only happens once.
  window.removeEventListener('mouseover', handler);

  // Remove iframe from the DOM, by default 'printJS'
  const iframe = document.getElementById(`printJS`);

  emit('close-print');
  if (iframe) {
    iframe.remove();
  }
};

/**
 * 기사형식 이름
 */
const setArticleTypeName = articleTypCd => {
  let articleTypeName = '';
  switch (articleTypCd) {
    case articleTypeCode.Straight.id:
      articleTypeName = articleTypeCode.Straight.name;
      break;
    case articleTypeCode.Report.id:
      articleTypeName = articleTypeCode.Report.name;
      break;
    case articleTypeCode.CrossTalk.id:
      articleTypeName = articleTypeCode.CrossTalk.name;
      break;
    case articleTypeCode.BottomRole.id:
      articleTypeName = articleTypeCode.BottomRole.name;
      break;
  }
  return articleTypeName;
};

// 기사 구분 날짜
const setEmbargoTime = content => {
  let date = '';
  if (content?.artclDivCd === articleDivisionCode.expected.id) {
    date = `${defaultDate(content.artclDivDtm)}`;
  } else if (content?.artclDivCd === articleDivisionCode.embargo.id) {
    date = defaultDateTimeHm(content.artclDivDtm);
  }
  return date;
};

/**
 * 기사 구분 이름
 */
const setArticleDivName = artclDivCd => {
  let articleDivName = '';
  switch (artclDivCd) {
    case articleDivisionCode.basic.id:
      articleDivName = articleDivisionCode.basic.name;
      break;
    case articleDivisionCode.expected.id:
      articleDivName = articleDivisionCode.expected.name;
      break;
    case articleDivisionCode.embargo.id:
      articleDivName = articleDivisionCode.embargo.name;
      break;
  }
  return `${articleDivName} : `;
};

/**
 * 기사시간: 앵커멘트 + 리포트멘트 + 엑스트라 타임
 */
const setArticleTime = content => {
  return secondToMs(
    content.artclCttTime + content.ancMentCttTime + content.artclExtTime,
  );
};
setPrintData();

setPrintData();

onMounted(() => {
  printContent();
});
</script>
