import { computed } from 'vue';

export function useModelWrapper<
  T extends Record<string, unknown>,
  R extends string,
  // eslint-disable-next-line no-unused-vars
>(props: T, emit: (e: `update:${R}`, ...args: any[]) => void, name: R) {
  return computed({
    get: () => props[name],
    set: value => emit(`update:${name}`, value),
  });
}
