import { Code, EnumEntry } from './base';

interface ExtendsCode extends Code {
  order: number;
  seoulId: '01' | '02' | '03' | '04';
}

class articleApproveCode extends EnumEntry<ExtendsCode> {
  approveAll: ExtendsCode = {
    id: 'apprv_typ_001,apprv_typ_002',
    name: '출고',
    label: '출고',
    seoulId: '02',
    order: 1,
  };

  noneApprove: ExtendsCode = {
    id: 'apprv_typ_000',
    name: '미출고',
    label: '미출고',
    seoulId: '01',
    order: 2,
  };

  articleApprov: ExtendsCode = {
    id: 'apprv_typ_001',
    name: '기사',
    label: '기사',
    seoulId: '03',
    order: 3,
  };

  articleCaptionApprove: ExtendsCode = {
    id: 'apprv_typ_002',
    name: '기사+자막',
    label: '기사+자막',
    seoulId: '04',
    order: 4,
  };

  values: Array<ExtendsCode> = [
    this.noneApprove,
    this.articleApprov,
    this.articleCaptionApprove,
    this.approveAll,
  ];
}

export default new articleApproveCode();
