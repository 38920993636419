import { articleTypeCode } from '@/codes';
import jsonParser from '@/utils/json-parser';
import { isEmpty, sortBy } from 'lodash';

/**
 * c/t일 경우 lnOrd 값으로 그룹화
 * @param {Array} list - 작성기 형태에 맞게 만들어진 cap 리스트
 * @param {number} totalLineCount - 앵커,리포트 묶음의 총 개수
 * @returns list - [[{}], [{},{}], ...] 형태(length는 앵커,리포트 묶음의 총 개수)
 */
const customCTCaptionList = (list: any, totalLineCount: number) => {
  const tempList = [];
  for (let i = 0; i < totalLineCount; i++) {
    tempList.push([{}]);
  }

  if (list.length === 0) {
    return tempList;
  } else {
    return list.reduce((acc: any, curr: any) => {
      const { lnOrd } = curr;

      if (acc[lnOrd]) {
        // 첫 번째 항목이 비었을 경우는 초기값으로 넣은 값이므로 없애준다.
        if (isEmpty(acc[lnOrd][0])) {
          acc[lnOrd].splice(0, 1);
        }
        acc[lnOrd].push(curr);
      } else {
        acc[lnOrd] = [curr];
      }
      return acc;
    }, tempList);
  }
};

/**
 * 자막형태에 맞춰 데이터 변경
 * @param {Array} list - 자막 리스트
 * @returns list
 */
const makeCaptionForm = (list: any) => {
  return sortBy(
    list.map((caption: any) => {
      return {
        text: caption.capCtt,
        type: caption.capTypCd,
        lineNo: caption.lnNo,
        lineOrd: caption.lnOrd !== undefined ? caption.lnOrd : caption.capOrd,
        articleLineNo: caption.artclLnNo,
        totalLines: caption.totalLines,
        icon: '',
      };
    }),
    ['lineNo'],
  );
};

/**
 * JSON.parse 에러나는 문자열 처리
 */
const handelParseErrorText = (error: any, text: string) => {
  if (error) {
    return jsonParser(
      JSON.stringify([text.replace('["', '').replace(/"\]([^"]*)$/, '')]),
    );
  } else {
    console.error('error', error);
  }
};

/**
 * 에디터에 맞게 형태 변경
 * @param {Object} options - 에디터에 들어갈 데이터
 * @param {string} options.artclTypCd - 기사 형식: c/t 구분하기 위해 필요
 * @param {Array} options.anchorCapList - 앵커 자막 리스트
 * @param {Array} options.articleCapList - 리포트 자막 리스트
 * @param {string} options.ancMentCtt - 앵커 멘트
 * @param {string} options.artclCtt - 리포트 멘트
 */
const setEditorValues = (params: any) => {
  const { artclTypCd, anchorCapList, articleCapList, ancMentCtt, artclCtt } =
    params;

  let anchorCaptionArray = [];
  let reporterCaptionArray = [];
  let anchorTextArray: any = [];
  let reporterTextArray: any = [];

  try {
    anchorTextArray = jsonParser(ancMentCtt);
  } catch (error: any) {
    anchorTextArray = handelParseErrorText(error, ancMentCtt);
  }

  try {
    reporterTextArray = jsonParser(artclCtt);
  } catch (error: any) {
    reporterTextArray = handelParseErrorText(error, artclCtt);
  }

  // 자막: c/t가 아닐경우
  if (artclTypCd !== articleTypeCode.CrossTalk.id) {
    anchorCaptionArray = [makeCaptionForm(anchorCapList)];
    reporterCaptionArray = makeCaptionForm(articleCapList);
  } else {
    // 앵커/리포트 묶음의 총 개수
    const totalCount = reporterTextArray.length;

    anchorCaptionArray = [
      ...customCTCaptionList(makeCaptionForm(anchorCapList), totalCount),
    ];
    // reporterCaptionArray = [
    //   ...customCTCaptionList(makeCaptionForm(articleCapList), totalCount),
    // ];
    reporterCaptionArray = makeCaptionForm(articleCapList);
  }

  return {
    anchorTextArray,
    anchorCaptionArray,
    reporterTextArray,
    reporterCaptionArray,
  };
};

export { setEditorValues, customCTCaptionList };
