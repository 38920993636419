import { AxiosInstance } from 'axios';

const PREFIX = '/cuesheet-template';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 템플릿 생성
     * @param params - 생성 내용
     */
    create: async (params: Object) => {
      return $axios.post(`${PREFIX}`, params).then(response => response.data);
    },

    /**
     * 템플릿 수정
     * @param templateId - 템플릿 ID
     * @param params - 수정 내용
     */
    update: async (templateId: String, params: Object) => {
      return $axios
        .put(`${PREFIX}/${templateId}`, params)
        .then(response => response.data);
    },

    /**
     * 템플릿 삭제
     * @param templateId - 템플릿 ID
     */
    delete: async (templateId: String) => {
      return $axios
        .delete(`${PREFIX}/${templateId}`)
        .then(response => response.data);
    },

    /**
     * 해당 프로그램의 큐시트 템플릿 목록 조회
     * @param brdcPgmId 방송프로그램 ID
     * @returns
     */
    findAll: async (params: any) => {
      return $axios
        .get(`${PREFIX}`, { params })
        .then(response => response.data);
    },

    /**
     * 템플릿 상세조회
     * @param templateId - 템플릿 ID
     */
    findOne: async (templateId: String) => {
      return $axios
        .get(`${PREFIX}/${templateId}`)
        .then(response => response.data);
    },
  };
};
