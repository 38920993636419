import { Module } from 'vuex';
import { RootState } from '..';

export type SubmitState = {
  isSave: boolean;
  data: any;
};

export const SubmitModule: Module<SubmitState, RootState> = {
  namespaced: true,
  state: () => ({
    isSave: false,
    data: null,
  }),
  mutations: {
    ADD_SUBMIT_DATA(state, addData) {
      state.data = addData;
    },
    IS_SAVE(state, value) {
      state.isSave = value;
    },
    RESET_STATE(state) {
      state.data = null;
    },
  },
  getters: {
    submitData: state => {
      return state.data;
    },
    isSave: state => {
      return state.isSave;
    },
  },
  actions: {},
};
