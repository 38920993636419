import { CueSheetOpenInfo } from '@/types/models';
import { Module } from 'vuex';
import { RootState } from '..';

export type CueSheetWriteState = {
  cueSheet: CueSheetOpenInfo[];
};

export const CueSheetWriteModule: Module<CueSheetWriteState, RootState> = {
  namespaced: true,
  state: () => ({
    cueSheet: [],
    cueSheetId: '',
  }),
  mutations: {
    ADD_CUESHEET(state, cueSheet: CueSheetOpenInfo) {
      state.cueSheet.push(cueSheet);
    },
    DELETE_CUESHEET(state, cueSheetId: string) {
      const index = state.cueSheet.findIndex(
        cueSheet => cueSheet.id === cueSheetId,
      );
      state.cueSheet.splice(index, 1);
    },
  },
  getters: {
    writingcCueSheetes: state => {
      return state.cueSheet.filter(
        cueSheet => cueSheet.openMode === 'new' || cueSheet.openMode === 'edit',
      );
    },
    writingCueSheetCount: state => {
      return state.cueSheet.filter(
        cueSheet => cueSheet.openMode === 'new' || cueSheet.openMode === 'edit',
      ).length;
    },
  },
  actions: {},
};
