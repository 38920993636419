import { Module } from 'vuex';
import { RootState } from '..';

export type RefreshState = {
  myArticle: object;
  myRegionArticle: object;
  forceRefresh: object;
};

export const RefreshModule: Module<RefreshState, RootState> = {
  namespaced: true,
  state: () => ({
    myArticle: {
      isRefresh: false,
      id: 0,
      reason: null,
    },
    myRegionArticle: {
      isRefresh: false,
      id: 0,
      reason: null,
    },
    forceRefresh: {
      isRefresh: false,
      id: 0,
      reason: null,
    },
  }),
  getters: {
    myArticle: state => {
      return state.myArticle;
    },
    myRegionArticle: state => {
      return state.myRegionArticle;
    },
    forceRefresh: state => {
      return state.forceRefresh;
    },
  },
  actions: {
    REFRESH_MY_REGION_ARTICLE_STATUS({ commit }, payload) {
      commit('SET_MY_REGION_ARTICLE_STATUS', payload);
    },
    DELETE_MY_REGION_ARTICLE_REFRESH_STATUS({ commit }) {
      commit('DELETE_MY_REGION_ARTICLE_REFRESH_STATUS');
    },
    REFRESH_MY_ARTICLE_STATUS({ commit }, payload) {
      commit('SET_MY_ARTICLE_STATUS', payload);
    },
    DELETE_MY_ARTICLE_REFRESH_STATUS({ commit }) {
      commit('DELETE_MY_ARTICLE_REFRESH_STATUS');
    },
    // onActivated로 감지가 안되는 경우, watch에서 감지해서 사용
    FORCE_REFRESH({ commit }) {
      commit('SET_FORCE_REFRESH_STATUS');
    },
    DELETE_FORCE_UPDATE_STATUS({ commit }) {
      commit('DELETE_FORCE_REFRESH_STATUS');
    },
  },
  mutations: {
    SET_MY_REGION_ARTICLE_STATUS(state, payload) {
      const status = {
        isRefresh: true,
        id: new Date().getTime(),
        reason: payload.reason,
      };

      state.myRegionArticle = status;
    },
    DELETE_MY_REGION_ARTICLE_REFRESH_STATUS(state) {
      state.myRegionArticle = { isRefresh: false, id: 0, reason: null };
    },
    SET_MY_ARTICLE_STATUS(state, payload) {
      const status = {
        isRefresh: true,
        id: new Date().getTime(),
        reason: payload.reason,
      };

      state.myArticle = status;
    },
    DELETE_MY_ARTICLE_REFRESH_STATUS(state) {
      state.myArticle = { isRefresh: false, id: 0, reason: null };
    },
    SET_FORCE_REFRESH_STATUS(state) {
      const status = {
        isRefresh: true,
        id: new Date().getTime(),
        reason: 'forceRefresh',
      };

      state.forceRefresh = status;
    },
    DELETE_FORCE_REFRESH_STATUS(state) {
      state.forceRefresh = { isRefresh: false, id: 0, reason: null };
    },
  },
};
