import { AxiosInstance } from 'axios';

const PREFIX = '/group';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 그룹 사용자 목록 조회
     * @param groupId - 그룹 아이디
     */
    findAll: async (groupId: Number, params: any) => {
      return $axios
        .get(`${PREFIX}/${groupId}/user`, { params })
        .then(response => response.data);
    },

    /**
     * 그룹 사용자 추가
     * @param groupId - 그룹 아이디
     * @param {Array} params
     */
    create: async (groupId: Number, params: any) => {
      return $axios
        .post(`${PREFIX}/${groupId}/user`, params)
        .then(response => response.data);
    },

    /**
     * 그룹 사용자 삭제
     * @param {Number} groupId - 그룹 아이디
     * @param {Object} params - 삭제할 ID 리스트
     *
     */
    delete: async (groupId: Number, params: any) => {
      return $axios
        .delete(`${PREFIX}/${groupId}/user`, { data: params })
        .then(response => response.data);
    },
  };
};
