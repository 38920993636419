
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'TheLogo',
  props: {
    width: {
      type: String,
      default: 'w-32',
    },
    height: {
      type: String,
      default: 'h-32',
    },
    fontSize: {
      type: String,
      default: () => {
        return 'text-xs';
      },
    },
  },

  setup(props) {
    const setLogoSize = computed(() => {
      return [props.width, props.height];
    });

    return {
      setLogoSize,
    };
  },
});
