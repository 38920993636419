<template>
  <div class="flex flex-col space-y-1">
    <label
      v-if="label"
      class="block sm:text-xs text-xs font-medium text-gray-700"
      :for="name"
    >
      {{ label }}
      <span v-if="required" class="text-red-600">*</span>
    </label>
    <!-- form-input 기본 class="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" -->
    <input
      ref="input"
      class="shadow-sm border border-gray-200 focus:ring-primary block w-full p-2 text-xs sm:text-xs rounded-md"
      :class="[
        errorMessage || errors
          ? 'border-red-700 focus:border-red-700 focus:ring-red-700'
          : inputClass,
        disabled || readonly
          ? 'focus:ring-0 focus:border-gray-300 cursor-default'
          : '',
      ]"
      :type="type"
      :value="inputValue"
      :readonly="readonly"
      :disabled="disabled"
      :tabIndex="tabIndex"
      :placeholder="disabled ? '' : placeholder ? placeholder : label"
      :max="max"
      :maxlength="maxlength"
      step="2"
      autocomplete="new-password"
      @input="handleChange"
      @blur="handleBlurValue"
      @keyup.enter="$emit('on-submit')"
    />
    <p
      v-if="errorMessage || errors"
      class="mt-2 sm:text-xs text-xs text-red-600"
    >
      {{ errorMessage ? errorMessage : errors ? errors : '' }}
    </p>
  </div>
</template>

<script>
import { useField } from 'vee-validate';
import { defineComponent, computed, watch } from 'vue';
import { toTimeFormat } from '../../utils/videotime';

export default defineComponent({
  name: 'GTimeCodeInput',

  props: {
    modelValue: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    focus: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: null,
    },
    maxlength: {
      type: Number,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: String,
      default: '',
    },
    timeCodeType: {
      type: String,
      default: '',
    },
    // '00:00', '00:00:00' 를 구분하기 위함
    timeFormat: {
      type: String,
      default: '',
    },
    tabIndex: {
      type: Number,
      default: 0,
    },
  },

  emits: ['update:modelValue', 'on-submit', 'blur'],

  setup(props, { emit }) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.modelValue,
    });

    watch(
      () => props.modelValue,
      currentValue => {
        let tmpCurrentValue = currentValue;
        if (currentValue === undefined || currentValue === '') {
          tmpCurrentValue = null;
        }
        inputValue.value = tmpCurrentValue;
      },
    );

    watch(inputValue, currentValue => {
      if (currentValue) {
        let timeValue = '';
        const regex = /[^0-9]/g;
        const pattern = /(\d{2})(\d{2})(\d{2})(\d{2})/;

        if (props.timeCodeType === 'time_code') {
          // 시분초
          timeValue = toTimeFormat(currentValue);
          if (timeValue.length > 8) {
            timeValue = timeValue.slice(0, -1);
          }
          inputValue.value = timeValue;
        } else if (props.timeCodeType === 'time_code_drop') {
          // Drop
          timeValue = currentValue.replace(regex, '');
          timeValue = timeValue.replace(pattern, '$1:$2:$3;$4');
          if (timeValue.length > 11) {
            timeValue = timeValue.slice(0, -1);
          }
          inputValue.value = timeValue;
        } else if (props.timeCodeType === 'time_code_non_drop') {
          // Non-Drop
          timeValue = currentValue.replace(regex, '');
          timeValue = timeValue.replace(pattern, '$1:$2:$3:$4');
          if (timeValue.length > 11) {
            timeValue = timeValue.slice(0, -1);
          }
          inputValue.value = timeValue;
        } else if (props.timeCodeType === 'time') {
          timeValue = toTimeFormat(currentValue, props.timeFormat);
          if (timeValue.length > 8) {
            timeValue = timeValue.slice(0, -1);
          } else if (timeValue.length === 8) {
            const timeRegex =
              /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9]):([0-5]?[0-9])$/;
            const timeChecked = timeRegex.test(timeValue);
            // Write operation failed: computed value is readonly error
            if (!timeChecked) {
              // TODO: 시간안맞을때
            }
          }
          inputValue.value = timeValue;
        }
      } else {
        inputValue.value = null;
      }
      emit('update:modelValue', currentValue);
    });

    watch(
      () => props.timeCodeType,
      () => {
        inputValue.value = null;
      },
    );

    const inputClass = computed(() => {
      const cls = [];
      if (props.readonly) {
        cls.push('focus:ring-0');
        // cls.push('outline-0');
        cls.push('bg-gray-200');
      } else {
        cls.push('focus:ring-primary');
        cls.push('focus:border-primary');
      }
      return cls;
    });

    const handleBlurValue = event => {
      emit('blur', event, props.name);
    };

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
      inputClass,
      handleBlurValue,
    };
  },
});
</script>
<style></style>
