
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import LoginLayout from './LoginLayout.vue';
import AppLayout from './AppLayout.vue';
import AdminLayout from './AdminLayout.vue';

export default defineComponent({
  name: 'BaseLayout',
  components: {
    LoginLayout,
    AppLayout,
    AdminLayout,
  },
  setup() {
    const route = useRoute();
    const layout = computed(() => route.meta.layout);

    return {
      layout,
    };
  },
});
