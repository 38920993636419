import { ArticleOpenInfo /*, ArticleWritingInfo*/ } from '@/types/models';
import { Module } from 'vuex';
import { RootState } from '..';

export type ArticleWriteState = {
  articles: ArticleOpenInfo[];
};

export const ArticleWriteModule: Module<ArticleWriteState, RootState> = {
  namespaced: true,
  state: () => ({
    articles: [],
  }),
  getters: {
    articlesInTab: state => {
      return state.articles.filter(
        article =>
          article.openMode === 'new' ||
          article.openMode === 'edit' ||
          article.openMode === 'view',
      );
    },
    writingArticles: state => {
      return state.articles.filter(
        article => article.openMode === 'new' || article.openMode === 'edit',
      );
    },
    writingArticleCount: state => {
      return state.articles.filter(
        article => article.openMode === 'new' || article.openMode === 'edit',
      ).length;
    },
    nextSequence: state => {
      if (state.articles.length === 0) {
        return 1;
      }
      const tmpArr = [...state.articles];
      tmpArr.sort((a, b) => {
        return a.sequence - b.sequence;
      });

      let seq = 1;
      tmpArr.forEach(article => {
        if (article.sequence !== seq) {
          return false;
        }
        seq += 1;
      });
      return seq;
    },
  },
  actions: {
    ADD_ARTICLE({ commit }, article: ArticleOpenInfo) {
      commit('ADD_ARTICLE', article);
    },
    EDIT_ARTICLE({ commit }, article: ArticleOpenInfo) {
      commit('EDIT_ARTICLE', article);
    },
  },
  mutations: {
    ADD_ARTICLE(state, article) {
      state.articles.push(article);
    },
    EDIT_ARTICLE(state, article) {
      state.articles.push(article);
    },
    DELETE_ARTICLE(state, articleId: string) {
      const index = state.articles.findIndex(
        article => article.id === articleId,
      );
      state.articles.splice(index, 1);
    },
  },
};
