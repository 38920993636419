<template>
  <GButton
    v-if="buttonType === 'normal'"
    :icon="icon"
    :disabled="disabled"
    :type="type"
    @click="onDelete"
  />
  <GIconButton
    v-else-if="buttonType === 'icon'"
    :icon="icon"
    :disabled="disabled"
    :type="type"
    @click="onDelete"
  />
</template>

<script setup>
import { GButton, GIconButton } from '@/components/ui';
import Swal from 'sweetalert2';

const props = defineProps({
  icon: {
    type: String,
    default: 'trash',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'primary',
  },
  title: {
    type: String,
    default: '',
  },
  isNeedPw: {
    type: Boolean,
    default: false,
  },
  buttonType: {
    type: String,
    default: 'normal',
  },
});

const emit = defineEmits(['on-delete', 'on-close', 'on-deny']);
const color = '#ff0303'; // 빨간색

const onDelete = async () => {
  const {
    value: password,
    isConfirmed,
    isDenied,
    isDismissed,
  } = await Swal.fire({
    title: props.title,
    input: 'password',
    inputPlaceholder: '비밀번호를 입력하세요',
    inputAttributes: {
      minlength: 1,
      //   maxlength: 20,
      autocapitalize: 'off',
      autocorrect: 'off',
    },
    reverseButtons: true,
    showCancelButton: true,
    cancelButtonText: '취소',
    iconColor: color,
    iconHtml:
      '<svg class="h-24 w-24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.043 21H3.957c-1.538 0-2.5-1.664-1.734-2.997l8.043-13.988c.77-1.337 2.699-1.337 3.468 0l8.043 13.988C22.543 19.336 21.58 21 20.043 21zM12 9v4" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path><path d="M12 17.01l.01-.011" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>',
    html: `<div>을(를) 삭제하시겠습니까?</div>`,
    customClass: {
      icon: 'no-border',
    },
    confirmButtonColor: color,
    confirmButtonText: '삭제',
    inputValidator: value => {
      if (!value) {
        return '비밀번호를 입력하세요!';
      }
    },
  });

  if (isConfirmed) {
    return emit('on-delete', password);
  }
  // 거절 버튼 클릭
  else if (isDenied) {
    return emit('on-deny', password);
  }
  // 취소 버튼 클릭
  else if (isDismissed) {
    return emit('on-close');
  }
};
</script>
