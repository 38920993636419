<template>
  <div class="cursor-pointer" @click="toggleZoom">
    <magnifying-glass-plus-icon
      v-if="!isZoom"
      class="h-6 w-6 rounded-full px-px py-px"
    />
    <magnifying-glass-minus-icon
      v-if="isZoom"
      class="h-6 w-6 rounded-full px-px py-px"
    />
  </div>
</template>
<script>
import { defineComponent, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { MODULE_NAME as APP_MODULE_NAME } from '@/store/modules/app';
import { ACTION_NAMES as APP_ACTION_NAMES } from '@/store/modules/app/action';

import MagnifyingGlassPlusIcon from '@heroicons/vue/24/outline/MagnifyingGlassPlusIcon';
import MagnifyingGlassMinusIcon from '@heroicons/vue/24/outline/MagnifyingGlassMinusIcon';

export default defineComponent({
  name: 'zoomInOutButton',
  components: {
    MagnifyingGlassPlusIcon,
    MagnifyingGlassMinusIcon,
  },
  setup() {
    const store = useStore();

    const isZoom = computed(() => store.state[APP_MODULE_NAME].zoom);

    const toggleZoom = () => {
      store.dispatch(
        `${APP_MODULE_NAME}/${APP_ACTION_NAMES.FETCH_ZOOM_IN_OUT_TOGGLE}`,
      );
    };

    watch(isZoom, newValue => {
      // 줌 활성화일 경우 130% 확대
      if (newValue) {
        document.body.style.zoom = '120%';
      } else {
        document.body.style.zoom = '100%';
      }
    });

    return {
      isZoom,
      toggleZoom,
    };
  },
});
</script>
