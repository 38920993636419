import { AxiosInstance } from 'axios';
import myInfoApi from './myInfoApi';
import myLogApi from './myLogApi';
import mySystemApi from './mySystemApi';
import myAuthorityApi from './myAuthorityApi';

export default ($axios: AxiosInstance) => {
  return {
    basic: myInfoApi($axios),
    log: myLogApi($axios),
    system: mySystemApi($axios),
    authority: myAuthorityApi($axios),
  };
};
