import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex items-center space-x-2" }
const _hoisted_2 = { class: "sr-only" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SwitchLabel = _resolveComponent("SwitchLabel")!
  const _component_Switch = _resolveComponent("Switch")!
  const _component_SwitchGroup = _resolveComponent("SwitchGroup")!

  return (_openBlock(), _createBlock(_component_SwitchGroup, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.label)
          ? (_openBlock(), _createBlock(_component_SwitchLabel, {
              key: 0,
              class: "block text-sm font-medium text-gray-700"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.label), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_Switch, {
          modelValue: _ctx.enabled,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.enabled) = $event)),
          class: _normalizeClass([_ctx.enabled ? 'bg-primary' : 'bg-gray-300', "relative inline-flex items-center h-6 rounded-full border-transparent w-11 ease-in-out duration-200 outline-none focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"])
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1),
            _createElementVNode("span", {
              class: _normalizeClass([_ctx.enabled ? 'translate-x-6' : 'translate-x-1', "inline-block w-4 h-4 transform bg-white rounded-full shadow-lg ring-0 transition ease-in-out duration-200"])
            }, null, 2)
          ]),
          _: 1
        }, 8, ["modelValue", "class"])
      ])
    ]),
    _: 1
  }))
}