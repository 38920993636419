import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "inline-block px-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GSpinner = _resolveComponent("GSpinner")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("button", {
    type: "button",
    disabled: _ctx.disabled || _ctx.loading,
    class: _normalizeClass(["inline-flex items-center justify-center rounded-md text-xs leading-6 border shadow-sm transition ease-in-out duration-150 sm:text-xs sm:leading-5", _ctx.setCls])
  }, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_GSpinner, {
          key: 0,
          class: "text-white"
        }))
      : _createCommentVNode("", true),
    (!_ctx.loading && _ctx.icon)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.iconComponent), {
          key: 1,
          class: _normalizeClass(_ctx.iconClass),
          "aria-hidden": "true"
        }, null, 8, ["class"]))
      : _createCommentVNode("", true),
    (!_ctx.loading)
      ? _renderSlot(_ctx.$slots, "default", { key: 2 }, () => [
          (!!_ctx.text)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.text), 1))
            : _createCommentVNode("", true)
        ])
      : _createCommentVNode("", true)
  ], 10, _hoisted_1)), [
    [_directive_tooltip, _ctx.tooltip]
  ])
}