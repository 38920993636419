<template>
  <div class="px-2 py-1">
    <!-- 버튼 -->
    <div class="flex border-b py-1 px-1.5 justify-end space-x-1.5 pb-2">
      <GSaveButton
        v-tooltip="'저장'"
        icon="arrow-down-tray"
        :text="'저장'"
        :loading="isLoadingSubmit"
        @click="onSaveArticle"
      />
      <!-- <GButton v-tooltip="'취소'" icon="x-mark" @click="onClose" /> -->
    </div>

    <!-- 내용 -->
    <div class="border-gray-200 bg-gray-100">
      <div class="flex justify-center">
        <div class="flex">
          <!-- 기사 작성 영역 -->
          <div class="flex border-r border-gray-300 bg-white">
            <div class="p-1.5 space-y-1">
              <!-- 버튼 영역 -->
              <div class="flex justify-end items-center">
                <!-- 자막완료 여부 -->
                <!-- <GSwitch
                v-tooltip="'자막완료 여부'"
                v-model="isCompleteCap"
                @change="onChangeCapComplete"
              /> -->
                <div class="flex justify-end space-x-1.5">
                  <GIconButton
                    v-tooltip="'특수문자 입력'"
                    icon="special-key"
                    @click="onClickSpecialChar"
                  />
                  <GIconButton
                    v-tooltip="'앵커'"
                    icon="anchor"
                    @click="onInputMacro('anchor')"
                  />
                  <GIconButton
                    v-tooltip="'리포트'"
                    icon="camera"
                    @click="onInputMacro('reporter')"
                  />
                  <GIconButton
                    v-tooltip="'INT'"
                    icon="int"
                    @click="onInputMacro('int')"
                  />
                  <GIconButton
                    v-tooltip="'SYNC'"
                    icon="sync"
                    @click="onInputMacro('sync')"
                  />
                  <GIconButton
                    v-tooltip="'CG'"
                    icon="cg"
                    @click="onInputMacro('cg')"
                  />
                  <GIconButton
                    v-tooltip="'ST-UP'"
                    icon="stup"
                    @click="onInputMacro('st-up')"
                  />
                  <GIconButton
                    v-tooltip="'END'"
                    icon="end"
                    @click="onInputMacro('end')"
                  />
                </div>
              </div>

              <!-- 제목 -->
              <div class="text-xs pb-1">
                <GInput
                  v-model="templateTitle"
                  ref="templateTitleRef"
                  name="templateTitle"
                  :title="templateTitle"
                  placeholder="제목을 입력하세요."
                  text-size="text-[15px]"
                />
              </div>

              <!-- 작성기 영역 -->
              <div class="overflow-y-auto h-[calc(100vh-253px)]">
                <div v-if="!type" class="w-[704px]">
                  <PageLoader />
                </div>
                <ArticleEditor
                  ref="articleEditor"
                  v-if="type"
                  :type-id="type"
                  v-model:reporter-text-array="
                    articleEditorState.reporterTextArray
                  "
                  v-model:anchor-text-array="articleEditorState.anchorTextArray"
                  v-model:caption-array="
                    articleEditorState.reporterCaptionArray
                  "
                />
              </div>
            </div>
          </div>
        </div>

        <!-- 메타 데이터 영역 -->
        <div class="w-[30rem] bg-slate-200">
          <GTabPanel :tabs="tabs" :selected-tab-id="selectedTabId">
            <TabPanel :unmount="false">
              <ArticleMetaData
                ref="articleMetaDataRef"
                v-model:article-title="templateTitle"
                v-model:article-program="program"
                v-model:article-extra-time="extraTime"
                v-model:article-extra-time-type="extraTimeType"
                :total-article-time="totalArticleTime"
                is-cue-template
                :cuesheet-item-division-code="cuesheetItemCode.TEMPLATE.id"
                article-mode="create"
              />
            </TabPanel>
            <TabPanel :unmount="false">
              <!-- <ArticleAttachFile @files="onGetFiles" /> -->
            </TabPanel>
            <TabPanel :unmount="false">
              <!-- <ArticleLink /> -->
            </TabPanel>
          </GTabPanel>
        </div>
      </div>
    </div>
    <!-- 특수 문자 모달 -->
    <SpecialCharModal
      v-if="isShowSpecialCharModal"
      @selected-char="onSelectedChar"
      @close="isShowSpecialCharModal = false"
    />
  </div>
</template>

<script setup>
import {
  defineProps,
  defineEmits,
  ref,
  watch,
  onMounted,
  onUnmounted,
} from 'vue';
import {
  // GButton,
  GIconButton,
  GTabPanel,
  GInput,
  GSaveButton,
} from '@/components/ui';
import { TabPanel } from '@headlessui/vue';
import { ArticleMetaData } from '@/components/article';
import ArticleEditor from '@/components/article/ArticleEditor';
import { articleTypeCode, cuesheetItemCode } from '@/codes';
import { useStore } from 'vuex';
import { useMarsLApi } from '@/apis/mars-l-api';
import { setEditorValues } from '@/utils/editor-helper';
import {
  calculateSpeed,
  setMacro,
  changeByte,
  calculateArticleTime,
  makeDBCaptionForm,
} from '@/utils/article-helper';
import { secondToMs } from '@/utils/format';
import { useForm } from 'vee-validate';
import yup from '@/utils/yup';
import { parse } from '@/utils/videotime.js';
import SpecialCharModal from '@/components/modal/article/SpecialCharModal';
import PageLoader from '@/components/common/PageLoader.vue';
import { insertTextBetWeen } from '@/utils/str';

const props = defineProps({
  templateId: {
    type: [String, Number],
  },
  templateItemId: {
    type: [String, Number],
  },
});
defineEmits(['close', 'on-submit']);

const tabs = ref([
  { id: 1, name: '메타데이터' },
  //   { id: 2, name: '첨부파일' },
  //   { id: 3, name: '링크' },
]);
let selectedTabId = ref(0);
const type = ref(null);
const store = useStore();
const marsLApi = useMarsLApi();

const articleEditor = ref();

const articleEditorState = ref({
  anchorTextArray: [''],
  anchorCaptionArray: [[]],
  reporterTextArray: [''],
  reporterCaptionArray: [[]],
});
const templateTitle = ref();
const program = ref();
const isLoadingSubmit = ref(false);
const isShowSpecialCharModal = ref(false);
const totalArticleTime = ref(0);
const extraTime = ref('00:00');
const extraTimeType = ref('+');
let speed = null;
const articleMetaDataRef = ref(null);
const templateTitleRef = ref();
const cursorPostion = ref(0);

// 'title', 'editor'
let activeInputElement = null;
// 'title', 'editor'
let specialCharTarget = null;

// validation 적용
const { validate } = useForm({
  initialValues: {
    templateTitle: null,
  },
  validationSchema: yup.object().shape({
    templateTitle: yup.string().required(),
  }),
});

// 저장 버튼 클릭
const onSaveArticle = async valid => {
  try {
    isLoadingSubmit.value = true;
    const { errorMsg, extraTime, extraTimeType } = articleMetaDataRef.value;
    // 제목 필수값 체크
    const res = await validate();

    // 필수 값 확인이 false인 경우, 탭을 메타데이터로 변경 (extraTime 확인)
    if (!res.valid || !valid || errorMsg) {
      selectedTabId.value = tabs.value[0].id;
      isLoadingSubmit.value = false;
      return;
    }

    // 기사작성기 자막 형태를 DB형태로 변경
    // eslint-disable-next-line no-unused-vars
    const { articleAnchorCaps, articleCaps } = makeDBCaptionForm(
      articleTypeCode.Straight,
      articleEditorState.value,
    );

    const params = {
      cueTmpltId: props.templateId,
      cueItemTitl: templateTitle.value,
      brdcPgmId: program.value?.id,
      cueItemCtt: `${JSON.stringify(
        articleEditorState.value.reporterTextArray,
      )}`, // 리포트 내용
      cueItemTime: calculateSpeed(
        speed,
        changeByte(articleEditorState.value.reporterTextArray),
      ), // 리포트 시간
      artclExtTime:
        extraTimeType === '+' ? parse(extraTime) : -parse(extraTime), // 외부 시간
      cueTmpltItemCap: articleCaps, // 리포트 자막
    };

    const response = await marsLApi.template.detail.update(
      props.templateItemId,
      params,
    );

    if (response.success) {
      window.Notify.success({
        message: '템플릿 수정이 완료되었습니다.',
      });
      // emit('close');
    }
  } catch (error) {
    console.log('error', error);
  } finally {
    isLoadingSubmit.value = false;
    store.commit('submit/RESET_STATE');
  }
};

// 선택한 매크로 글자 넣기
const onInputMacro = type => {
  const char = setMacro(type);
  articleEditor.value.insertTextAtCursor(char);
};

// 선택한 특수문자 가져오기
const onSelectedChar = char => {
  if (specialCharTarget === 'editor') {
    articleEditor.value.insertTextAtCursor(char);
  } else if (specialCharTarget === 'title') {
    if (templateTitle.value === undefined) {
      templateTitle.value = char;
    } else {
      templateTitle.value = insertTextBetWeen(
        templateTitle.value,
        char,
        cursorPostion.value,
      );
      cursorPostion.value += char.length;
    }
  }
};

/**
 * 큐시트 템플릿 아이템 상세 조회
 */
const templateItemInfo = async () => {
  try {
    const response = await marsLApi.template.detail.findOne(
      props.templateItemId,
    );
    if (response.success) {
      const { cueItemTitl, cueItemCtt, cueTmpltItemCap, artclExtTime } =
        response.data;

      articleEditorState.value = setEditorValues({
        artclTypCd: articleTypeCode.Straight.id,
        anchorCapList: [],
        articleCapList: cueTmpltItemCap,
        ancMentCtt: JSON.stringify(['']),
        artclCtt: cueItemCtt || JSON.stringify(['\n']),
      });

      type.value = null;
      type.value = articleTypeCode.Straight.id;

      templateTitle.value = cueItemTitl;
      extraTime.value = secondToMs(Math.abs(artclExtTime));
      extraTimeType.value = artclExtTime >= 0 ? '+' : '-';
    }
  } catch (error) {
    console.log(error);
  }
};

const isArticleEditorElement = el => {
  return (
    (el.tagName === 'DIV' && el['spellcheck'] !== undefined) ||
    (el.tagName === 'TEXTAREA' && el.classList.contains('article-caption'))
  );
};

const isArticleTitleInputElement = el => {
  return el.tagName === 'INPUT' && el['id'] !== 'article_title_input';
};

const onDocumentMouseDown = () => {
  // 현재 포커스를 가지고 있는 요소
  const activeEl = document.activeElement;
  if (isArticleEditorElement(activeEl)) {
    activeInputElement = 'editor';
  } else if (isArticleTitleInputElement(activeEl)) {
    activeInputElement = 'title';
  } else {
    activeInputElement = null;
  }
};

const onClickSpecialChar = () => {
  specialCharTarget = null;
  if (activeInputElement) {
    specialCharTarget = activeInputElement;
    // 특수문자입력버튼 클릭시 타이틀 커서 위치 저장
    if (specialCharTarget === 'title') {
      cursorPostion.value = templateTitleRef.value.onselectionStart();
    }
  }
  isShowSpecialCharModal.value = true;
};

// 기사 작성기 감지해서 기사 길이 계산
watch(
  () => articleEditorState.value,
  newValue => {
    // 메타데이터 탭에 표시
    totalArticleTime.value = secondToMs(
      calculateArticleTime(
        newValue.anchorTextArray,
        newValue.reporterTextArray,
        speed,
      ),
    );
  },
  {
    deep: true,
  },
);

onMounted(async () => {
  templateItemInfo();
  await store.dispatch('systemInfo/FIND_MY_SYSTEM_INFO');
  speed = store.getters['systemInfo/readSpeed'];

  document.addEventListener('mousedown', onDocumentMouseDown);
});

onUnmounted(() => {
  document.removeEventListener('mousedown', onDocumentMouseDown);
});
</script>

<style lang="scss" scoped></style>
