import { AxiosInstance } from 'axios';
import myArticleApi from './myArticleApi';
import linkApi from './linkApi';
import referenceApi from './referenceApi';
import transferApi from './transferApi';
import copyApi from './copyApi';
import fileApi from './fileApi';

export default ($axios: AxiosInstance) => {
  return {
    basic: myArticleApi($axios),
    link: linkApi($axios),
    reference: referenceApi($axios),
    transfer: transferApi($axios),
    copy: copyApi($axios),
    file: fileApi($axios),
  };
};
