<template>
  <svg
    class="inline-block"
    :class="[height, width, cls]"
    stroke-width="1.5"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color="#000000"
  >
    <path
      d="M14 10H2M10 14H2M6 18H2M18 6H2M19 10v10m0 0l3-3m-3 3l-3-3"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SortDownIcon',
  props: {
    height: {
      type: String,
      default: 'h-6',
    },
    width: {
      type: String,
      default: 'h-6',
    },
    color: {
      type: String,
      default: '#000000', // 검정색
    },
    cls: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {};
  },
});
</script>
