import { useSweetAlert } from '@/hooks/use-sweet-alert';
import dayjs from 'dayjs';

/**
 * 엠바고 기사에 어떤 작업을 하려는 경우 알럿 띄움
 * @param {string} embargoDate - 엠바고 날짜
 * @param {string} type - 어떤 행위인가 (print, copy)
 */
export default function (embargoDate: string, type: string) {
  const { swalConfirm } = useSweetAlert();

  // 엠바고 날짜가 지나지 않았을 경우
  if (dayjs().isBefore(dayjs(embargoDate))) {
    let typeTxt = '';
    if (type === 'print') {
      typeTxt = '인쇄';
    } else if (type === 'copy') {
      typeTxt = type = '복사';
    } else if (type === 'approve') {
      typeTxt = type = '출고';
    }

    return swalConfirm({
      title: `<div class="space-y-1.5"><div><span class="text-red-600 font-D2CodingBold text-2xl">엠바고 기사</span> 입니다.</div><div>${typeTxt} 하시겠습니까?</div></div>`,
    }).then(response => {
      return response;
    });
  }

  return 'finished';
}
