<template>
  <div class="flex flex-col h-screen overflow-hidden relative">
    <!-- 헤더 영역 -->
    <div
      class="flex items-center justify-between h-12 shadow bg-white w-full px-4 2xl:px-8"
    >
      <div class="flex items-center space-x-4 2xl:space-x-8">
        <!-- 로고 -->
        <TheLogo class="h-8 mb-2" />
        <!-- 상단 메뉴 -->
        <TheAdminNav />
      </div>

      <!-- 사용자 프로필 -->
      <!-- <div class="flex items-center space-x-2">
        <UserProfile />
      </div> -->
    </div>

    <!-- 콘텐츠 영역 -->
    <main class="focus:outline-none mt-1 bg-gray-100 h-full">
      <router-view v-slot="{ Component }">
        <component :is="Component" :key="$route.path" />
      </router-view>
    </main>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import TheLogo from '@/components/TheLogo.vue';
import TheAdminNav from '@/components/TheAdminNav.vue';
// import UserProfile from '@/components/common/UserProfile.vue';

export default defineComponent({
  name: 'AdminLayout',
  components: {
    TheLogo,
    TheAdminNav,
    // UserProfile,
  },

  setup() {
    return {};
  },
});
</script>
