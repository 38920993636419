import { useMarsLApi } from '@/apis/mars-l-api';
import { MUTATION_NAMES } from './mutation';

export const ACTION_NAMES = Object.freeze({
  FETCH_PROGRAMS: 'FETCH_PROGRAMS',
});

export const ACTIONS = {
  [ACTION_NAMES.FETCH_PROGRAMS]: async ({ commit }: any, _params: any) => {
    const marsLApi = useMarsLApi();
    const params = {
      chDivId: 0,
      // delYn: 'N',
      // page: 1,
      // searchValue: '',
      // size: 50,
      ..._params,
      isUse: true,
    };

    try {
      const { data } = await marsLApi.program.basic.findAll(params);
      commit(MUTATION_NAMES.SET_PROGRAMS, data);
    } catch (e) {
      console.error(e);
    }
  },
};
