import { AxiosInstance } from 'axios';

const PREFIX = '/my-info';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 나의 로그 목록 조회
     * @param {Object} option - 검색 조건
     * @param {String} fromDate - 시작일
     * @param {String} toDate - 종료일
     */
    findAll: async (params: any) => {
      return $axios
        .get(`${PREFIX}/log`, { params })
        .then(response => response.data);
    },
  };
};
