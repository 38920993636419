
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
import BaseLayout from '@/layouts/BaseLayout.vue';

export default defineComponent({
  name: 'App',
  components: {
    BaseLayout,
  },
  setup() {
    const store = useStore();
    store.dispatch(
      'auth/SET_TOKEN',
      localStorage.getItem('accessToken') === 'null'
        ? ''
        : localStorage.getItem('accessToken'),
    );
    return {};
  },
});
