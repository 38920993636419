import { AxiosInstance } from 'axios';

const PREFIX = '/my-article';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 내기사 목록 조회
     * @param {string} startDate 시작 일자
     * @param {string} endDate 종료 일자
     * @param {string} searchValue 검색 키워드
     */
    findAll: async (params: any) => {
      return $axios
        .get(`${PREFIX}`, { params })
        .then(response => response.data.data);
    },
    /**
     * 내기사 등록
     * @param {object} params 기사 저장 데이터
     */
    create: async (params: any) => {
      return $axios.post(`${PREFIX}`, params).then(response => response.data);
    },
    /**
     * 내기사 상세 조회
     * @param {string} articleId 기사ID
     */
    findOne: async (articleId: any) => {
      return $axios
        .get(`${PREFIX}/${articleId}`)
        .then(response => response.data.data);
    },
    /**
     * 내기사 수정
     * @param {number} articleId 기사ID
     * @param {object} params 기사 저장 데이터
     */
    update: async (articleId: any, params: any) => {
      return $axios
        .put(`${PREFIX}/${articleId}`, params)
        .then(response => response.data);
    },
    /**
     * 내기사 삭제
     * @param {number} articleId 기사ID
     * @param {object} params 기사 저장 데이터
     */
    delete: async (articleId: any) => {
      return $axios
        .delete(`${PREFIX}/${articleId}`)
        .then(response => response.data.data);
    },
  };
};
