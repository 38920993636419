<template>
  <div
    class="flex py-2 space-x-2 justify-between rounded-md bg-gray-100 items-center"
  >
    <div class="flex space-x-2 items-center">
      <div class="text-xs flex-1">
        <GInput
          v-model="title"
          name="title"
          :title="title"
          placeholder="제목을 입력하세요."
          text-size="text-[15px]"
          width="w-96"
        />
      </div>
      <!-- 프로그램 -->
      <ProgramSelect
        v-model="program"
        :company-code="companyId"
        @select="onSelectedProgram"
      />
      <GRadioButton
        v-model="option"
        name="option"
        :label="`2단`"
        :value="'2단'"
        font-size="text-sm"
        :checked="option === '2단'"
        @update:modelValue="onChangeLevel"
      />
      <GRadioButton
        v-model="option"
        name="option"
        :label="`3단`"
        :value="'3단'"
        font-size="text-sm"
        :checked="option === '3단'"
        @update:modelValue="onChangeLevel"
      />
    </div>
    <div class="flex space-x-2 items-center">
      <GSaveButton
        v-tooltip="'저장'"
        icon="arrow-down-tray"
        text="저장"
        @click="onSaveData"
      />
      <GButton v-tooltip="'취소'" icon="x-mark" @click="onClose" />
    </div>
  </div>
  <div ref="externalTable"></div>
  <ImportCuesheetModal
    v-if="isShowCuesheetModal"
    :field="columnField"
    @import-cuesheet="onImportCuesheet"
    @close="isShowCuesheetModal = false"
  />
</template>

<script setup>
import { defineProps, defineEmits, onMounted, ref, defineExpose } from 'vue';
import { GRadioButton, GInput, GButton, GSaveButton } from '@/components/ui';
import { ProgramSelect } from '@/components/select';
import { useUserInfo } from '@/hooks/use-user-info';
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import 'tabulator-tables/dist/css/tabulator.min.css';
// xlsx파일 다운로드하기 위해 import 필요
import XLSX from 'xlsx/dist/xlsx.full.min.js';
import { cloneDeep } from 'lodash';
import { ImportCuesheetModal } from '@/components/modal/externalNews';
import { useMarsLApi } from '@/apis/mars-l-api';

window.XLSX = XLSX;

const props = defineProps({
  tableData: {
    type: Object,
    default: () => {
      return {};
    },
  },
  line: {
    type: Number,
    default: () => {
      return 23;
    },
  },
  mode: {
    type: String,
    default: () => {
      return 'create';
    },
  },
});

const emit = defineEmits(['close', 'on-save-data']);

const option = ref('3단');
const title = ref();
const program = ref();
const monitoringId = ref();
const columnField = ref();
const isShowCuesheetModal = ref(false);
const { companyId } = useUserInfo();
const marsLApi = useMarsLApi();

const tabulator = ref();
// 데이터 준비
const tableData = ref();
// 테이블 헤더
const tableColumns = ref();
// 헤더 컨텍스트 메뉴
const headerContextMenu = ref([
  {
    label: '큐시트 불러오기',
    action: (e, column) => {
      columnField.value = column._column.field;
      isShowCuesheetModal.value = true;

      //   // 불러온 데이터를 사용하여 초과된 개수만큼 행을 추가하여 데이터 입력
      //   const extraRowCount = Math.max(
      //     0,
      //     response.length - tabulator.value.getData().length,
      //   );
      //   for (let i = 0; i < extraRowCount; i++) {
      //     tabulator.value.addData({}, false);
      //   }

      //   let tempCells =
      //     tabulator.value.columnManager.columnsByField[
      //       column._column.field
      //     ].getCells();
      //   tempCells.forEach((item, index) => {
      //     if (index < response.length) {
      //       item.setValue(response[index].cuesheetNm);
      //     }
      //   });
    },
  },
]);

// Tabulator
const externalTable = ref(null);

const setTable = () => {
  // Tabulator 옵션 설정
  const tableOptions = {
    data: tableData.value,
    columns: tableColumns.value,
    layout: 'fitColumns',
    height: 'calc(100vh - 197px)',
    backgroundColor: '#fff',
    printAsHtml: true, //enable html table printing
    printConfig: {
      printVisibleRows: true,
      columnCalcs: false, //do not include column calcs in printed table
    },
    rowContextMenu: [
      {
        label: '위에 행 삽입',
        action: (e, row) => {
          console.log('row:', row.getCells());
          onAddRows(row.getData(), row.getIndex(), true);
        },
      },
      {
        disabled: false,
        label: '아래에 행 삽입',
        action: function (e, row) {
          onAddRows(row.getData(), row.getIndex(), false);
        },
      },
      {
        disabled: false,
        label: '선택한 행 삭제',
        action: function (e, row) {
          row.delete();
        },
      },
      {
        disabled: false,
        label: '내보내기',
        menu: [
          {
            label: 'CSV',
            action: function () {
              tabulator.value.download('csv', 'data.csv', { bom: true });
            },
          },
          {
            label: 'Excel',
            action: function () {
              tabulator.value.download('xlsx', 'data.xlsx', {}); //download a xlsx file that has a sheet name of "MyData"
            },
          },
        ],
      },
    ],
  };

  tabulator.value = new Tabulator(externalTable.value, tableOptions);
};

/**
 * 로우 추가
 * @param object 선택한 행 값
 * @param index 선택한 행 위치
 * @param isTopAdd 선택한 행의 위에 삽입할지, 아래에 삽입할지 여부 - true or false
 */
const onAddRows = (object, index, isTopAdd) => {
  let cloneObj = cloneDeep(object);
  for (const key in cloneObj) {
    cloneObj[key] = ``;
  }

  tabulator.value.addRow([cloneObj], isTopAdd, index);
  //   tabulator.value.addData([cloneObj], isTopAdd, index);

  let tempList = tabulator.value.getData().map((item, index) => {
    return {
      ...item,
      id: index,
    };
  });

  tabulator.value.replaceData(tempList);
};

// 데이터 내보내기
const onSaveData = () => {
  const obj = {
    columns: tabulator.value.getColumnDefinitions(),
    rows: tabulator.value.getData(),
  };

  const params = {
    monitoringId: monitoringId.value,
    programId: program.value?.id,
    monitoringTitle: title.value,
    monitoringType: option.value,
    monitoringContent: JSON.stringify(obj),
  };
  emit('on-save-data', params);
};

/**
 * 초기 데이터 세팅
 */
const setCreateData = () => {
  const maxColumn = option.value === '2단' ? 2 : 3;
  const headers = [];
  for (let i = 1; i <= maxColumn; i++) {
    headers.push({
      title: ' ',
      field: `${i}`,
      editor: 'input',
      headerSort: false, // 헤더 클릭으로 정렬 기 능 없애기
      editableTitle: true,
      headerContextMenu: headerContextMenu.value,
    });
  }
  const data = [];
  let datObj;

  for (let i = 0; i < props.line; i++) {
    datObj = {};
    datObj[`id`] = i;
    for (const key of headers) {
      datObj[key.field] = '';
    }

    data.push(datObj);
  }

  return {
    headers,
    data,
  };
};

/**
 * 2단 or 3단
 */
const onChangeLevel = val => {
  let tempList;
  tempList = tabulator.value.getColumnDefinitions();

  switch (val) {
    case `2단`:
      tempList.pop();
      tableColumns.value = tempList;
      tabulator.value.destroy();
      setTable();

      break;

    case `3단`:
      /**
       * tabulator에서 제공하는 addColumns로 컬럼을 추가하면
       * cell에서 탭이나 방향키로 이동시 에러가 나기때문에
       * 따로 컬럼배열을 추가해서 tabulator를 다시 로드해준다.
       * * 컬럼 추가시 기존에 editor에 있던 값들이 남아있어서 함께 초기화해준다.
       */
      tempList.push({
        title: ' ',
        field: '3',
        editor: 'input',
        headerSort: false, // 헤더 클릭으로 정렬 기능 없애기
        editableTitle: true,
        headerContextMenu: headerContextMenu.value,
      });
      tableColumns.value = tempList;

      tableData.value = tabulator.value.getData().map(item => {
        item[`3`] = ''; // 추가한 컬럼의 값을 초기화
        return item;
      });
      tabulator.value.destroy();
      setTable();
      break;
  }
};

const onPrint = () => {
  tabulator.value.print(`all`);
};

const onSelectedProgram = data => {
  program.value = data;
};

/**
 * 큐시트 불러오기
 */
const onImportCuesheet = async data => {
  console.log('rowData:: ', data);
  try {
    const params = {
      cueId: data.row.id,
    };
    const response = await marsLApi.cueSheet.detail.findAll(params);
    if (response.success) {
      // 불러온 데이터를 사용하여 초과된 개수만큼 행을 추가하여 데이터 입력
      const extraRowCount = Math.max(
        0,
        response.data.length - tabulator.value.getData().length,
      );
      for (let i = 0; i < extraRowCount; i++) {
        tabulator.value.addData({}, false);
      }
      let tempCells =
        tabulator.value.columnManager.columnsByField[data.field].getCells();
      tempCells.forEach((item, index) => {
        if (index < response.data.length) {
          item.setValue(response.data[index].cueItemTitl);
        }
      });
    }
  } catch (error) {
    console.log(error);
  } finally {
    isShowCuesheetModal.value = false;
  }
};

const onClose = () => {
  emit('close');
};

onMounted(() => {
  if (props.mode === `create`) {
    // 초기 데이터 설정
    tableData.value = setCreateData().data;
    tableColumns.value = setCreateData().headers;
  } else if (props.mode === `edit`) {
    // 데이터 설정
    monitoringId.value = props.tableData.monitoringId;
    title.value = props.tableData.monitoringTitle;
    program.value = props.tableData.programId;
    option.value = props.tableData.monitoringType;
    tableColumns.value = JSON.parse(props.tableData.monitoringContent).columns;
    for (const obj of tableColumns.value) {
      delete obj['width'];
    }
    tableData.value = JSON.parse(props.tableData.monitoringContent).rows;
  }
  // tabulator 세팅
  setTable();
});

defineExpose({
  onPrint,
});
</script>

<style scoped>
/* @import 'tabulator-tables'; */
/* Simple theme CDN을 사용하여 스타일시트를 불러옴 */
@import url('https://cdnjs.cloudflare.com/ajax/libs/tabulator/5.5.0/css/tabulator_simple.min.css');

.tabulator {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}
</style>
