<template>
  <div class="relative w-6 h-6">
    <svg
      class="absolute inset-0 -left-1.5 z-10 focus:outline-none"
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      fill="#ff0000"
      stroke="#ff0000"
    >
      <path
        d="M9.525 17.3 19.4 7.425l-.725-.725-9.15 9.175-4.225-4.25-.725.725Zm0 1.4-6.35-6.35L5.3 10.225l4.225 4.225L18.7 5.275 20.85 7.4Z"
      />
    </svg>
    <svg
      class="absolute inset-0 left-1"
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      fill="#00cc00"
      stroke="#00cc00"
    >
      <path
        d="M9.525 17.3 19.4 7.425l-.725-.725-9.15 9.175-4.225-4.25-.725.725Zm0 1.4-6.35-6.35L5.3 10.225l4.225 4.225L18.7 5.275 20.85 7.4Z"
      />
    </svg>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'DoneOutlineTwoIcon',
  props: {
    options: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const fill = ref(props.options?.fill || ''); // 테두리
    const stroke = ref(props.options?.stroke || ''); // 채워넣기

    return {
      fill,
      stroke,
    };
  },
});
</script>
