<script setup lang="ts"></script>

<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="30" height="30" rx="15" fill="#F9E5F9" />
    <path
      d="M11.6553 10.0469H14.9502C15.6976 10.0469 16.3288 10.1608 16.8438 10.3887C17.3633 10.6165 17.7575 10.9538 18.0264 11.4004C18.2998 11.8424 18.4365 12.387 18.4365 13.0342C18.4365 13.4899 18.3431 13.9069 18.1562 14.2852C17.974 14.6589 17.7096 14.9779 17.3633 15.2422C17.0215 15.502 16.6113 15.6956 16.1328 15.8232L15.7637 15.9668H12.667L12.6533 14.8936H14.9912C15.4652 14.8936 15.8594 14.8115 16.1738 14.6475C16.4883 14.4788 16.7253 14.2533 16.8848 13.9707C17.0443 13.6882 17.124 13.376 17.124 13.0342C17.124 12.6514 17.0488 12.3164 16.8984 12.0293C16.748 11.7422 16.5111 11.5212 16.1875 11.3662C15.8685 11.2067 15.4561 11.127 14.9502 11.127H12.9746V20H11.6553V10.0469ZM17.4727 20L15.0527 15.4883L16.4268 15.4814L18.8809 19.918V20H17.4727Z"
      fill="#9C2BAD"
    />
  </svg>
</template>

<style scoped></style>
