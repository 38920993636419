import { AxiosInstance } from 'axios';

const PREFIX = '/report';
const headers = {
  'Content-Type': 'multipart/form-data',
};

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 제보 목록 조회
     * @param {Object} params query string
     * @param {Array} params.chDivIdList 채널 아이디 리스트
     * @param {string} params.startDate 검색 시작 날짜
     * @param {string} params.endDate 검색 종료 날짜
     * @param {string} params.searchValue 검색 키워드
     * @param {number} params.page 검색할 페이지
     * @param {number} params.size 한 페이지에 표시할 개수
     */
    findAll: async (params: any) => {
      return $axios
        .get(`${PREFIX}`, { params })
        .then(response => response.data);
    },

    /**
     * 제보 상세 조회
     * @param {number} reportId 리포트 아이디
     */
    findOne: async (reportId: number) => {
      return $axios
        .get(`${PREFIX}/${reportId}`)
        .then(response => response.data);
    },

    /**
     * 제보 메모 수정
     * @param {number} reportId 리포트 아이디
     * @param {Object} params request body
     */
    update: async (reportId: number, params: any) => {
      return $axios
        .put(`${PREFIX}/${reportId}`, params)
        .then(response => response.data);
    },

    /**
     * 제보 목록 엑셀 다운로드
     * @param {object} option - request body
     * @param {boolean} option.startDate - 시작 시간
     * @param {boolean} option.endDate - 종료 시간
     * @param {boolean} option.chDivIds - 기역사 ID
     * @param {boolean} option.searchValue - 검색어
     * @returns
     */
    excelDownload: async (params: any) => {
      return $axios
        .get(`${PREFIX}/download`, {
          params,
          responseType: 'blob',
          headers,
        })
        .then(response => response.data);
    },
  };
};
