<template>
  <div class="flex flex-col space-y-1" :class="[width]">
    <label
      v-if="label"
      class="block sm:text-xs text-xs font-medium text-gray-700"
      :for="name"
      >{{ label }}
      <span v-if="required" class="text-red-600">*</span>
    </label>
    <div class="flex items-center">
      <!-- <div
        v-if="subLabel"
        class="px-2 text-xl font-semibold select-none"
        :class="subCls"
        @click="onClickedSubLabel"
      >
        {{ `${subLabel}` }}
      </div> -->
      <input
        ref="input"
        class="p-2 border border-gray-300 shadow-sm focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary block w-full rounded-md"
        :class="setCls"
        :type="type"
        :value="inputValue"
        :readonly="readonly"
        :disabled="disabled"
        :placeholder="placeholder || label"
        :tabindex="tabIndex"
        :title="title"
        autocomplete="off"
        v-maska
        :data-maska="maskFormat"
        :data-maska-tokens="dataMaskaTokens"
        @input="handleChange"
        @blur="handleChange"
        @maska="onMaska"
        @keydown.-="keyDownMinusHandler"
        @keydown.+="keyDownPlusHandler"
      />
      <RadioGroup
        v-model="selected"
        class="select-none px-0.5"
        :disabled="disabled"
      >
        <RadioGroupLabel class="sr-only">Server size</RadioGroupLabel>
        <div class="flex space-x-0.5">
          <RadioGroupOption
            as="template"
            v-for="plan in plans"
            :key="plan.name"
            :value="plan"
            v-slot="{ active, checked }"
          >
            <div
              :class="[
                active
                  ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300'
                  : '',
                checked ? 'bg-primary text-white ' : 'bg-white',
                disabled ? 'opacity-75' : '',
              ]"
              class="relative flex cursor-pointer rounded-lg px-2 py-2 shadow-md focus:outline-none"
            >
              <div class="flex w-full items-center justify-between">
                <div class="flex items-center">
                  <div class="text-sm">
                    <RadioGroupLabel
                      as="p"
                      :class="checked ? 'text-white' : 'text-gray-900'"
                      class="w-7 font-medium"
                    >
                      {{ plan.name }}
                    </RadioGroupLabel>
                  </div>
                </div>
              </div>
            </div>
          </RadioGroupOption>
        </div>
      </RadioGroup>
    </div>
    <p v-if="errorMessage || errors" class="mt-2 text-xs text-error">
      {{ errorMessage ? errorMessage : errors ? errors : '' }}
    </p>
  </div>
</template>

<script>
import { useField } from 'vee-validate';
import { vMaska } from 'maska';
import { ref, computed, watch, onMounted, nextTick } from 'vue';
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue';

export default {
  name: 'ExtraTimeInput',
  components: {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    maskFormat: {
      type: String,
    },
    label: {
      type: String,
      default: '',
    },
    subLabel: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    focus: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: String,
      default: '',
    },
    tabIndex: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: 'w-full',
    },
    bgColor: {
      type: String,
      default: '',
    },
    textSize: {
      type: String,
      default: 'text-xs',
    },
    dataMaskaTokens: {
      type: String,
      default: '',
    },
    subCls: {
      type: String,
      default: '',
    },
  },
  directives: { maska: vMaska },
  emits: ['update:modelValue', 'maska', 'on-sub-clicked', 'on-radio-selected'],

  setup(props, { emit }) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.modelValue,
    });

    const input = ref(null);

    const plans = [
      {
        id: '+',
        name: '초과',
      },
      {
        id: '-',
        name: '단축',
      },
    ];

    const selected = ref(plans[0]);

    watch(
      () => props.modelValue,
      currentValue => {
        let tmpCurrentValue = currentValue;

        if (currentValue === undefined) {
          tmpCurrentValue = '';
        }

        inputValue.value = tmpCurrentValue;
      },
    );

    watch(inputValue, currentValue => {
      // null인 상태가 들어오는 상황이 있는데 나중에 확인.
      // if (currentValue !== null) {
      //   return;
      // }
      emit('update:modelValue', currentValue);
    });

    const setCls = computed(() => {
      let cls = '';
      if (props.bgColor) {
        cls = props.bgColor;
      } else if (props.readonly || props.disabled) {
        cls = cls + 'focus:ring-0 bg-gray-200';
      }
      if (props.textSize) {
        cls = cls + ' ' + props.textSize;
      }
      if (errorMessage.value || props.errors) {
        cls = cls + ' border-error focus:border-error focus:ring-error';
      }

      return cls;
    });

    const setFocus = () => {
      input.value?.focus();
    };

    const onMaska = event => {
      emit('maska', {
        name: props.name,
        masked: event.detail.masked,
        unmasked: event.detail.unmasked,
        completed: event.detail.completed,
      });
    };

    const onClickedSubLabel = () => {
      emit('on-sub-clicked');
    };

    const keyDownMinusHandler = () => {
      selected.value = plans[1];
    };

    const keyDownPlusHandler = () => {
      selected.value = plans[0];
    };

    watch(selected, currentValue => {
      emit('on-radio-selected', currentValue.id);
    });

    watch(
      () => props.subLabel,
      () => {
        selected.value = plans.find(item => item.id === props.subLabel);
      },
      { immediate: true },
    );

    onMounted(() => {
      if (props.focus) {
        nextTick(() => {
          setFocus();
        });
      }
    });

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
      setCls,
      setFocus,
      input,
      onMaska,
      onClickedSubLabel,
      plans,
      selected,
      keyDownMinusHandler,
      keyDownPlusHandler,
    };
  },
};
</script>
<style scoped>
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
