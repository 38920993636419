export const RouterPath = {
  Login: 'login',
  MyArticle: 'my-article',
  TemporaryArticle: 'temporary-article',
  CreateArticle: 'article/:sequence/create',
  EditArticle: 'article/:id/edit',
  ViewArticle: 'article/:id/view',
  MyRegionArticle: 'my-region-article',
  AreaArticle: 'area-article',
  SeoulMBCArticle: 'seoul-article',
  Request: 'request',
  CueSheet: 'cue-sheet',
  ShowCueSheetDetail: 'cue-sheet/:id',
  AreaCueSheet: 'area-cuesheet',
  ShowAreaCueSheetDetail: 'area-cue-sheet/:id/detail',
  SeoulMBCCueSheet: 'seoul-cuesheet',
  ShowSeoulMBCCueSheetDetail: 'seoul-cuesheet/:id/detail',
  CueSheetTemplate: 'cuesheet-template',
  ShowCueSheetTemplateDetail: 'cuesheet-template/:id/detail',
  BottomRoll: 'bottom-roll',
  NewsSource: 'news-source',
  RequestCover: 'request-cover',
  Schedule: 'schedule',
  ContactUs: 'contact-us',
  ContactUsManagement: 'contact-us-management',
  Board: 'board',
  ShowBoardDetail: 'board/:subId/detail/:id/view',
  EditBoardDetail: 'board/:subId/detail/:id/edit',
  Notice: 'notice',
  ShowNoticeDetail: 'notice/:id/detail/view',
  EditNoticeDetail: 'notice/:id/detail/edit',
  Journalist: 'journalist',
  ShowJournalistDetail: 'journalist/:id/detail/view',
  EditJournalistDetail: 'journalist/:id/detail/edit',
  ExternalLink: 'external-link',
  Monitoring: 'monitoring',
  Admin: 'admin',
};

export const RouterName = {
  Login: 'login',
  MyArticle: 'myArticle',
  CreateMyArticle: 'createMyArticle',
  TemporaryArticle: 'temporaryArticle',
  CreateArticle: 'articleCreate',
  EditArticle: 'articleEdit',
  ViewArticle: 'articleView',
  MyRegionArticle: 'myRegionArticle',
  AreaArticle: 'areaArticle',
  SeoulMBCArticle: 'seoulArticle',
  Request: 'request',
  CueSheet: 'cueSheet',
  ShowCueSheetDetail: 'cueSheetDetail',
  AreaCueSheet: 'areaCueSheet',
  ShowAreaCueSheetDetail: 'areaCueSheetDetail',
  SeoulMBCCueSheet: 'seoulMBCCueSheet',
  ShowSeoulMBCCueSheetDetail: 'seoulMBCCueSheetDetail',
  CueSheetTemplate: 'cueSheetTemplate',
  ShowCueSheetTemplateDetail: 'cueSheetTemplateDetail',
  BottomRoll: 'bottomRoll',
  ContactUs: 'contactUs',
  ContactUsManagement: 'contactUsManagement',
  Board: 'board',
  ShowBoardDetail: 'boardDetail',
  EditBoardDetail: 'boardEdit',
  Notice: 'notice',
  ShowNoticeDetail: 'noticeDetail',
  EditNoticeDetail: 'noticeEdit',
  Journalist: 'journalist',
  ShowJournalistDetail: 'journalistDetail',
  EditJournalistDetail: 'journalistEdit',
  NewsSource: 'NewsSource',
  RequestCover: 'requestCover',
  Schedule: 'schedule',
  ExternalLink: 'externalLink',
  Monitoring: 'monitoring',
  Admin: 'admin',
};

export const parentRouterPath = {
  Article: 'article',
  CoverDesk: 'cover-desk',
  CueSheets: 'cue-sheets',
  ShareInfo: 'share-info',
  Board: 'board',
};

export const parentRouterName = {
  Article: 'article',
  CoverDesk: 'coverDesk',
  CueSheets: 'cueSheets',
  ShareInfo: 'shareInfo',
  Board: 'board',
};

export const RouterIcon = {
  MyArticle: 'NewspaperIcon',
  TemporaryArticle: 'InboxIcon',
  MyRegionArticle: 'FileCopyIcon',
  AreaArticle: 'BoundingBoxIcon',
  SeoulMBCArticle: 'JournalTextIcon',
  Request: 'UserVoiceIcon',
  CueSheet: 'HddNetworkIcon',
  AreaCueSheet: 'HddRackIcon',
  CueSheetTemplate: 'SquaresPlusIcon',
  BottomRoll: 'ComputerIcon',
  ContactUs: 'ChatBubbleBottomCenterTextIcon',
  Board: 'ChalkBoardIcon',
  RequestCover: 'CalendarDaysIcon',
  Schedule: 'CalendarMinusIcon',
  Monitoring: 'MonitoringIcon',
};

export const AdminRouterPath = {
  User: 'user',
  Group: 'group',
  CreateGroup: 'group/create',
  EditGroup: 'group/:id/edit',
  Program: 'program',
  Icon: 'icon',
  Category: 'category',
  ScrollNewsCategory: 'scrollNewsCategory',
  Department: 'department',
  Tag: 'tag',
  BoardManagement: 'boardManagement',
};

export const AdminRouterName = {
  User: 'user',
  Group: 'group',
  CreateGroup: 'createGroup',
  EditGroup: 'editGroup',
  Program: 'program',
  Icon: 'icon',
  Category: 'category',
  ScrollNewsCategory: 'scrollNewsCategory',
  Department: 'department',
  Tag: 'tag',
  BoardManagement: 'boardManagement',
};

export const AdminRouterIcon = {
  User: 'UsersIcon',
  MobileUser: 'DeviceMobileIcon',
  Group: 'UserGroupIcon',
  Program: 'CollectionIcon',
  BroadcastIcon: 'ViewGridIcon',
  Code: 'ServerIcon',
  Icon: 'ViewGridAddIcon',
  CGTemplate: 'TemplateIcon',
};
