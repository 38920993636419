// import UserGroup from '@/enums/UserGroup';
export interface User {
  id: number;
  chDivId: number;
  userId: string;
  userNm: string;
  chMqCd: string;
  deptId: number;
  editorType: string;
  groups: Array<any>;
  isAdmin: boolean;
  //   appAuthList: Map<string, boolean>;
  //   can(permission: string): boolean;
}

export const makeUser = (userInfo: any, userGroups: Array<any> = []): User => {
  return {
    id: userInfo?.userMngId,
    chDivId: userInfo?.chDivId,
    userId: userInfo?.userId,
    userNm: userInfo?.userNm,
    chMqCd: userInfo?.chMqCd,
    deptId: userInfo?.deptId, // 부서 아이디 현재 없음
    editorType: userInfo?.editorType, // 기사 형식
    groups: userGroups,
    isAdmin: false,
    // isAdmin: userGroups?.some(
    //   group => group.userGroup.userGrpId === UserGroup?.ADMIN,
    // ),
    // appAuthList: new Map(
    //   userInfo.appAuthUser.map(appAuth => [appAuth.appAuthCd, true]),
    // ),
    // can(permission: string): boolean {
    //   return this.appAuthList.get(permission) === true;
    // },
  };
};
