<template>
  <div class="">
    <div
      class="w-[372px] flex flex-col h-[calc(100vh-144px)] px-2 pt-1.5 space-y-2 border-r border-gray-300 bg-slate-200 rounded-bl-lg"
    >
      <!-- 버튼들 -->
      <div class="flex justify-end space-x-1.5">
        <GButton
          v-tooltip="t('Reference.참조_기사_불러오기')"
          icon="document-magnifying-glass"
          @click="isShowReferenceModal = true"
        />
        <GSaveButton
          v-if="isExportArticleButton"
          v-tooltip="t('Reference.송고원본_불러오기')"
          icon="document-magnifying-glass"
          text="송고원본"
          @click="onExportArticle"
        />
        <GButton
          v-tooltip="t('Reference.참조_기사_저장')"
          icon="arrow-down-tray"
          :disabled="!referenceText"
          @click="onSaveRefArticle"
        />
      </div>

      <!-- 입력 영역 -->
      <div class="space-y-2 flex flex-col h-full overflow-hidden">
        <!-- 참조 제목 -->
        <GInput
          v-model="refTitle"
          name="refTitle"
          bg-color="bg-amber-50"
          text-size="text-base"
          :disabled="checkDisabled"
          placeholder="참조 제목"
        />

        <div
          class="flex flex-col h-full bg-amber-50 rounded-md overflow-x-hidden overflow-auto"
        >
          <div class="flex flex-col h-full font-D2Coding w-[340px]">
            <codemirror
              v-model="referenceText"
              placeholder="참조 기사 내용"
              :indentWithTab="false"
              :extensions="extensions"
            />
            <!--          <textarea-->
            <!--            v-model="referenceText"-->
            <!--            class="bg-amber-50 w-[340px] rounded-md resize-none focus:outline-none h-[calc(100vh-250px)]"-->
            <!--            placeholder="참조 기사 내용"-->
            <!--            :disabled="checkDisabled"-->
            <!--            style="font-size: 15px; padding: 0 2px 0 6px"-->
            <!--          />-->
          </div>
        </div>

        <!-- :value="JSON.stringify(articleEditorState)" -->
      </div>
    </div>

    <!-- 참조 기사 모달 -->
    <ReferenceModal
      v-if="isShowReferenceModal"
      @selected-article="onSelectedArticleFromRef"
      @close="isShowReferenceModal = false"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { GButton, GInput, GSaveButton } from '@/components/ui';
import ReferenceModal from '@/components/modal/article/ReferenceModal';
import { isMySomething } from '@/utils/compare-helper';
import { useMarsLApi } from '@/apis/mars-l-api';
import { useStore } from 'vuex';
import { parentRouterName } from '@/types/router';
import { Codemirror } from 'vue-codemirror';
import { EditorView } from '@codemirror/view';

const props = defineProps({
  articleId: {
    type: [String, Number],
    default: 0,
  },
  isModal: {
    type: Boolean,
  },
  routeName: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['get-ref-data']);
// Codemirror Constant
const extensions = [EditorView.lineWrapping];
const { t } = useI18n();
const marsLApi = useMarsLApi();
const store = useStore();

const isShowReferenceModal = ref(false);
const refTitle = ref(null); // 참조 제목
const referenceText = ref(null);
const articleInputerId = ref(0);

// 내 기사 확인
const checkDisabled = computed(() => {
  if (articleInputerId.value) {
    return isMySomething(articleInputerId.value) ? false : true;
  }

  return false;
});

// 참조기사 저장
const onSaveRefArticle = async () => {
  // 취재데스크에서 저장한 참조기사 내용은 내기사로 저장된다.
  const params = {
    artclCtt: JSON.stringify([referenceText.value]),
    artclTitl: refTitle.value
      ? `${t('Reference.참조기사')} ${refTitle.value}`
      : `${t('Reference.참조기사_제목없음')}`,
  };

  try {
    await marsLApi.myArticle.reference.create(params);

    window.Notify.success({
      message: `${t('Reference.참조_기사_저장_완료되었습니다')}`,
    });
  } catch (error) {
    console.error('error', error);
  }
};

// 참조모달에서 선택한 기사 참조기사 영역에 표시
const onSelectedArticleFromRef = article => {
  isShowReferenceModal.value = false;
  const { ancMentCtt, artclCtt, inputrId, artclTitl } = article;
  articleInputerId.value = inputrId;

  // 참조기사 제목
  refTitle.value = artclTitl;
  // ancMentCtt, artclCtt 합쳐서 보여준다.
  const anchorArray = JSON.parse(ancMentCtt);
  const articleArray = JSON.parse(artclCtt);

  referenceText.value = '';
  if (anchorArray && articleArray) {
    for (let i = 0; i < anchorArray.length; i++) {
      referenceText.value =
        referenceText.value + `${anchorArray[i]}\n${articleArray[i]}\n`;
    }
  } else if (!anchorArray && articleArray) {
    for (let i = 0; i < articleArray.length; i++) {
      referenceText.value = referenceText.value + `${articleArray[i]}\n`;
    }
  } else if (anchorArray && !articleArray) {
    for (let i = 0; i < anchorArray.length; i++) {
      referenceText.value = referenceText.value + `${anchorArray[i]}\n`;
    }
  }
};

const isExportArticleButton = computed(() => {
  return props.routeName === parentRouterName.CoverDesk;
});

/**
 * 원본 기사 불러오기
 */
const onExportArticle = async () => {
  try {
    const response = await marsLApi.coverDesk.basic.findOne(
      parseInt(props.articleId),
    );
    console.log(response);

    refTitle.value = response.artclTitl;

    const anchorArray = JSON.parse(response.ancMentCtt);
    const articleArray = JSON.parse(response.artclCtt);

    referenceText.value = '';
    if (anchorArray && articleArray) {
      for (let i = 0; i < anchorArray.length; i++) {
        referenceText.value =
          referenceText.value + `${anchorArray[i]}\n${articleArray[i]}\n`;
      }
    } else if (!anchorArray && articleArray) {
      for (let i = 0; i < articleArray.length; i++) {
        referenceText.value = referenceText.value + `${articleArray[i]}\n`;
      }
    } else if (anchorArray && !articleArray) {
      for (let i = 0; i < anchorArray.length; i++) {
        referenceText.value = referenceText.value + `${anchorArray[i]}\n`;
      }
    }
  } catch (error) {
    console.log(error);
  }
};

watch(
  () => [refTitle.value, referenceText.value],
  ([newTitle, newContent]) => {
    emit('get-ref-data', { refTitle: newTitle, refContent: newContent });
  },
);

onMounted(() => {
  // 기사 신규생성할 때 작성했던 참조내용이 있었다면 표시
  const storeReference = store.getters['reference/findReference'](
    props.articleId,
  );

  if (storeReference) {
    const articleType = props.isModal ? 'myArticle' : 'myRegion';
    if (
      Number(props.articleId) === Number(storeReference?.articleId) &&
      articleType === storeReference?.articleType
    ) {
      refTitle.value = storeReference?.refTitle;
      referenceText.value = storeReference?.refContent;
    }

    // 화면에 표시 후 store에서 삭제
    store.dispatch('reference/DELETE_REFERENCE', { ...storeReference });
  } else {
    refTitle.value = null;
    referenceText.value = null;
  }
});
</script>

<style scoped>
::v-deep(.v-codemirror) {
  font-size: 15px;
}

::v-deep(.cm-scroller) {
  font-family: D2Coding, D2 coding;
}

::v-deep(.cm-gutters) {
  display: none;
}

::v-deep(.cm-activeLine),
::v-deep(.cm-activeLineGutter) {
  background-color: initial;
}

::v-deep(.cm-content) {
  padding-top: 0;
  padding-bottom: 0;
  white-space: pre-wrap;
  /* line-break: anywhere; */
}

::v-deep(.cm-content[contenteditable='false'] .cm-selectionMatch) {
  background-color: transparent;
}

/* 2023.09.08 hkkim 요기 주석 풀면 서울 MBC와 Wordwrap 기준이 달라짐 */
/* ::v-deep(.cm-lineWrapping) {
  word-break: break-all !important;
} */
</style>
