<template>
  <svg
    class="h-6 w-6 outline-none"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.86009 18C9.72297 17.9996 9.58741 17.971 9.46183 17.9159C9.33624 17.8609 9.22333 17.7806 9.13009 17.68L4.27009 12.51C4.08841 12.3164 3.99109 12.0586 3.99953 11.7932C4.00797 11.5278 4.12148 11.2767 4.31509 11.095C4.50869 10.9134 4.76654 10.816 5.03191 10.8245C5.29727 10.8329 5.54841 10.9464 5.73009 11.14L9.85009 15.53L18.2601 6.33003C18.3454 6.22375 18.4516 6.13605 18.5721 6.0723C18.6926 6.00854 18.8248 5.9701 18.9607 5.95931C19.0966 5.94853 19.2332 5.96564 19.3622 6.00958C19.4912 6.05353 19.6099 6.12339 19.711 6.21488C19.812 6.30636 19.8933 6.41754 19.9498 6.54158C20.0063 6.66561 20.0369 6.79989 20.0396 6.93617C20.0423 7.07245 20.0172 7.20784 19.9657 7.33404C19.9141 7.46024 19.8374 7.57458 19.7401 7.67003L10.6001 17.67C10.5077 17.7724 10.3952 17.8545 10.2696 17.9113C10.144 17.9681 10.0079 17.9983 9.87009 18H9.86009Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CheckIcon',
  props: {},
  setup() {
    return {};
  },
});
</script>
