<template>
  <i class="caption-done-icon" :class="[type]" />
</template>

<script>
export default {
  name: 'CaptionDoneIcon',
  props: {
    type: {
      type: String,
    },
  },
};
</script>

<style scoped>
i.caption-done-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.3rem;
  height: 1.3rem;
  font-style: normal;
  color: white;
  background-color: #22c55e;
  font-size: 0.7rem;
  font-weight: 500;
  border-radius: 50%;
}
i.caption-done-icon::before {
  content: '완';
}
</style>
