<template>
  <div class="flex flex-shrink-0">
    <router-link
      v-for="nav in navigation"
      :key="nav.name"
      :to="nav.path"
      :target="nav.target"
      class="h-12 px-4 relative group hover:bg-primary-bright text-white flex items-center cursor-pointer"
      :class="isActive(nav) ? 'bg-primary font-D2CodingBold' : ''"
    >
      <span class="">{{ nav.title }}</span>
      <div
        v-if="nav.children"
        class="w-40 absolute top-12 -left-[0.063rem] hidden group-hover:block border border-t-0 shadow-2xl bg-white text-black z-50"
      >
        <router-link
          v-for="child in nav.children"
          :key="child.name"
          :to="child.path"
          class="flex flex-col p-2.5 hover:bg-primary-bright hover:text-white hover:font-D2CodingBold"
          @click="onClickedSubMenu($event, child)"
        >
          <div class="flex items-center">
            <!-- <component
              :is="child?.meta?.menu?.icon"
              v-if="child?.meta?.menu?.icon"
              aria-hidden="true"
              class="h-5 w-5 mr-3.5"
            /> -->
            <div class="text-sm">
              {{ t(`Tab.${child.name}`) }}
            </div>
          </div>
        </router-link>
      </div>
    </router-link>

    <!-- 기사 생성 모달 -->
    <ArticleModal
      v-if="isShowArticleModal"
      mode="create"
      @close="onCloseArticleModal"
    />
  </div>
</template>

<script>
import { defineComponent, ref, watchEffect } from 'vue';
import { appRoutes } from '@/router/app-routes';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { RouterName } from '@/types/router';
import ArticleModal from '@/components/modal/article/ArticleModal.vue';
import { AppAuthorityEnum } from '@/enums/app-authority.ts';
import { useUserInfo } from '@/hooks/use-user-info';
import { useStore } from 'vuex';

// import {
//   NewspaperIcon,
//   InboxIcon,
//   ChatBubbleBottomCenterTextIcon,
//   SquaresPlusIcon,
//   ComputerDesktopIcon,
//   ServerIcon,
// } from '@heroicons/vue/24/outline';
// import {
//   CalendarDaysIcon,
//   FileCopyIcon,
//   CalendarMinusIcon,
//   CorporateFareIcon,
//   UserVoiceIcon,
//   BoundingBoxIcon,
//   HddRackIcon,
//   HddNetworkIcon,
//   JournalTextIcon,
//   ChalkBoardIcon,
//   ComputerIcon,
// } from '@/components/ui/icon';

export default defineComponent({
  name: 'TheNav',
  components: {
    ArticleModal,
    // NewspaperIcon,
    // InboxIcon,
    // ChatBubbleBottomCenterTextIcon,
    // SquaresPlusIcon,
    // ComputerDesktopIcon,
    // ServerIcon,
    // CalendarDaysIcon,
    // FileCopyIcon,
    // CalendarMinusIcon,
    // CorporateFareIcon,
    // UserVoiceIcon,
    // BoundingBoxIcon,
    // HddRackIcon,
    // HddNetworkIcon,
    // JournalTextIcon,
    // ChalkBoardIcon,
    // ComputerIcon,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const { can } = useUserInfo();

    const isShowArticleModal = ref(false);
    let navigation = ref([]);
    let children = [];
    let navObj = {
      name: '',
      title: '',
      children: [],
      path: '',
    };

    const menuRouterList = appRoutes.filter(routerItem => {
      return routerItem?.meta?.menu;
    });

    const isActive = item => {
      if (item.children.length) {
        return item.children[0].meta.parent === route.meta.parent;
      }

      return route?.name === item.name;
    };

    const onClickedSubMenu = (event, subMenu) => {
      // 내기사 하위 메뉴의 '기사 작성'을 누르면 기사생성 모달을 띄울 수 있도록
      if (subMenu.name === RouterName.CreateMyArticle) {
        isShowArticleModal.value = true;
      }
    };

    const onCloseArticleModal = () => {
      isShowArticleModal.value = false;
      window.EventBus.emit('refresh-my-article');
    };

    watchEffect(() => {
      const authorityCodes = store.getters['auth/getAuthorityCodes'];

      if (authorityCodes.length) {
        navigation.value = [];
        menuRouterList.forEach(route => {
          /**
           * 내기사: 기사작성 하위메뉴
           * 취재데스크: 내지역사조회, 지역사조회, 서울MBC조회
           * 큐시트: 큐시트조회, 지역사조회, 서울MBC조회, 큐시트 템플릿
           * 제보관리
           * 하단롤
           * 액션을 할 수 있는 권한이 있으면 하위 메뉴에 추가
           */
          if (
            (route.name === RouterName.CreateMyArticle &&
              !can(AppAuthorityEnum.MY_ARTICLE_WRITE.code)) ||
            (route.name === RouterName.MyRegionArticle &&
              !can(AppAuthorityEnum.ARTICLE_READ.code)) ||
            (route.name === RouterName.AreaArticle &&
              !can(AppAuthorityEnum.ARTICLE_CHANNEL_READ.code)) ||
            (route.name === RouterName.SeoulMBCArticle &&
              !can(AppAuthorityEnum.ARTICLE_SEOUL_READ.code)) ||
            (route.name === RouterName.CueSheet &&
              !can(AppAuthorityEnum.CUESHEET_READ.code)) ||
            (route.name === RouterName.AreaCueSheet &&
              !can(AppAuthorityEnum.CUESHEET_CHANNEL_READ.code)) ||
            (route.name === RouterName.SeoulMBCCueSheet &&
              !can(AppAuthorityEnum.CUESHEET_SEOUL_READ.code)) ||
            (route.name === RouterName.CueSheetTemplate &&
              !can(AppAuthorityEnum.CUESHEET_TEMPLATE_READ.code)) ||
            (route.name === RouterName.ContactUsManagement &&
              !can(AppAuthorityEnum.REPORT_READ.code)) ||
            (route.name === RouterName.BottomRoll &&
              !can(AppAuthorityEnum.SCROLLNEWS_READ.code)) ||
            (route.name === RouterName.Board &&
              !can(AppAuthorityEnum.BOARD_READ.code))
          ) {
            return;
          }

          if (route.meta?.parent) {
            if (navObj.name === route.meta?.parent) {
              children.push(route);
              navObj.children = children;
            } else {
              children = [];
              children.push(route);

              navObj = {
                name: route.meta?.parent,
                title: t(`Menu.${route.meta?.parent}`),
                children,
                path: children.find(item => item.meta.menu?.default)
                  ? children.find(item => item.meta.menu?.default).path
                  : children[0].path,
              };

              navigation.value.push(navObj);
            }
          } else {
            // 관리자 권한이 있으면 관리자메뉴 표시
            if (
              route.name === RouterName.Admin &&
              !can(AppAuthorityEnum.ADMIN_VIEW.code)
            ) {
              return;
            }

            navObj = {
              name: route.name,
              title: t(`Menu.${route.name}`),
              children: [],
              path: route.path,
              target: route.meta.menu.target || null,
            };

            navigation.value.push(navObj);
          }
        });
      }
    });

    return {
      t,
      navigation,
      isActive,
      isShowArticleModal,
      onClickedSubMenu,
      onCloseArticleModal,
    };
  },
});
</script>
