<template>
  <GModal
    :changed="false"
    :dialog-overlay="false"
    max-width="w-[1500px] sm:max-w-full"
    height="h-[calc(100vh-60px)]"
    @close="onClose"
  >
    <!-- title -->
    <template #title>
      <span class="text-white font-D2CodingBold">{{ setTitle }}</span>
    </template>

    <!-- 내용 -->
    <template #content>
      <CreateArticle
        v-if="articleMode === 'create'"
        ref="createArticleRef"
        :isModal="true"
        :is-clicked-close-button-of-modal="clickedCloseBtnArticleModal"
        @save-success="onSaveSuccess"
      />
      <EditArticle
        v-else-if="id && articleMode === 'edit'"
        ref="editArticleRef"
        :isModal="true"
        :id="id"
        @original-data="getOriginalData"
        @save-success="onSaveSuccessFromEdit"
        @success-transfer="onTransferSuccessFromEdit"
      />
      <ViewArticle
        v-else-if="id && articleMode === 'view'"
        :id="id"
        :is-modal="true"
        :is-cuesheet="isCuesheet"
      />
      <ViewSeoulMBCArticle
        v-else-if="id && articleMode === 'seoul'"
        :id="id"
        :is-modal="true"
        :is-cuesheet="isCuesheet"
      />
    </template>
  </GModal>
</template>

<script>
import { defineComponent, computed, ref } from 'vue';
import { GModal } from '@/components/ui';
import { useI18n } from 'vue-i18n';
import CreateArticle from '@/views/article/CreateArticle.vue';
import EditArticle from '@/views/article/EditArticle.vue';
import ViewArticle from '@/views/article/ViewArticle.vue';
import ViewSeoulMBCArticle from '@/views/article/ViewSeoulMBCArticle.vue';
import { useSweetAlert } from '@/hooks/use-sweet-alert';
import {
  isChangedDataOfCreate,
  isChangedDataOfEdit,
} from '@/utils/article-helper';

export default defineComponent({
  name: 'ArticleModal',
  components: {
    GModal,
    CreateArticle,
    EditArticle,
    ViewArticle,
    ViewSeoulMBCArticle,
  },
  props: {
    mode: {
      type: String,
      default: '',
    },
    articleId: {
      type: [String, Number],
      default: 0,
    },
    isCuesheet: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const { swalConfirm } = useSweetAlert();

    const articleMode = ref(props.mode);
    const id = ref(props.articleId);
    const createArticleRef = ref(null);
    const editArticleRef = ref(null);
    const originData = ref(null);
    const clickedCloseBtnArticleModal = ref(false);

    const setTitle = computed(() => {
      if (articleMode.value === 'create') {
        return t('Modal.Article.ArticleModal.기사_작성');
      } else if (articleMode.value === 'edit') {
        return t('Modal.Article.ArticleModal.기사_편집');
      } else {
        return t('Modal.Article.ArticleModal.기사_보기');
      }
    });

    // 모달 닫기
    const onClose = () => {
      const openComponentRef = editArticleRef.value || createArticleRef.value;
      if (!openComponentRef) {
        emit('close');
        return;
      }

      // createArticle.vue OR editArticle.vue 에 닫기 버튼 클릭했음 여부 보냄
      clickedCloseBtnArticleModal.value = true;

      if (articleMode.value === 'create') {
        // 데이터 변경 감지
        const isChanged = isChangedDataOfCreate({
          title: openComponentRef.title,
          anchorCaptionArray:
            openComponentRef.articleEditorState.anchorCaptionArray,
          reporterCaptionArray:
            openComponentRef.articleEditorState.reporterCaptionArray,
          anchorTextArray: openComponentRef.articleEditorState.anchorTextArray,
          reporterTextArray:
            openComponentRef.articleEditorState.reporterTextArray,
          type: openComponentRef.type,
        });

        if (isChanged) {
          swalConfirm({
            title:
              '<div><div>변경된 데이터가 있습니다.</div><div>저장하시겠습니까?</div></div>',
          }).then(response => {
            if (response === 'confirm') {
              createArticleRef.value.onSaveArticle();
            } else {
              emit('close');
            }
          });
        } else {
          emit('close');
        }
      } else if (articleMode.value === 'edit') {
        const changedData = {
          title: openComponentRef.title,
          anchorCaptionArray:
            openComponentRef.articleEditorState.anchorCaptionArray,
          reporterCaptionArray:
            openComponentRef.articleEditorState.reporterCaptionArray,
          anchorTextArray: openComponentRef.articleEditorState.anchorTextArray,
          reporterTextArray:
            openComponentRef.articleEditorState.reporterTextArray,
          type: openComponentRef.type,
        };

        const isChanged = isChangedDataOfEdit(originData.value, changedData);

        if (isChanged) {
          swalConfirm({
            title:
              '<div><div>변경된 데이터가 있습니다.</div><div>저장하시겠습니까?</div></div>',
          }).then(response => {
            if (response === 'confirm') {
              editArticleRef.value.onSaveArticle();
            } else {
              emit('close');
            }
          });
        } else {
          emit('close');
        }
      }
    };

    // EditArticle.vue에서 가져온 원본 데이터
    const getOriginalData = data => {
      originData.value = data;
    };

    // 저장 후 수정 모드로 변경
    const onSaveSuccess = articleId => {
      if (articleId) {
        articleMode.value = 'edit';
        id.value = articleId;
      } else {
        emit('close');
      }
    };

    // 기사 송고 후 모달 닫기
    const onTransferSuccessFromEdit = () => {
      emit('close');
    };

    // EditArticle.vue 로부터 저장이 완료되었을 경우 (모달에서 닫기로 저장한 경우)
    const onSaveSuccessFromEdit = () => {
      if (clickedCloseBtnArticleModal.value) {
        emit('close');
      }
    };

    return {
      t,
      createArticleRef,
      editArticleRef,
      id,
      articleMode,
      setTitle,
      clickedCloseBtnArticleModal,
      onClose,
      onSaveSuccess,
      getOriginalData,
      onSaveSuccessFromEdit,
      onTransferSuccessFromEdit,
    };
  },
});
</script>
