<template>
  <svg
    class="h-6 w-6"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.62 6.20999C16.5165 6.12803 16.3977 6.06738 16.2707 6.03155C16.1436 5.99572 16.0107 5.9854 15.8796 6.00121C15.7485 6.01701 15.6218 6.05862 15.5069 6.12363C15.392 6.18864 15.2911 6.27577 15.21 6.37999L8.20999 15.38L4.77999 11.2C4.69954 11.0929 4.59839 11.003 4.48255 10.9358C4.3667 10.8685 4.23853 10.8252 4.10563 10.8085C3.97273 10.7917 3.83782 10.8018 3.70891 10.8382C3.57999 10.8746 3.4597 10.9365 3.35516 11.0202C3.25063 11.104 3.16399 11.2079 3.10039 11.3258C3.03679 11.4437 2.99752 11.5731 2.98491 11.7065C2.9723 11.8399 2.98661 11.9744 3.027 12.1021C3.06738 12.2298 3.13301 12.3481 3.21999 12.45L7.38999 17.63C7.48411 17.746 7.60303 17.8395 7.73801 17.9035C7.87299 17.9675 8.0206 18.0005 8.16999 18C8.32857 18.0071 8.48656 17.9763 8.63088 17.9102C8.77521 17.8441 8.90174 17.7447 8.99999 17.62L16.83 7.61999C16.9098 7.51401 16.9678 7.39321 17.0004 7.26462C17.0331 7.13603 17.0399 7.00223 17.0203 6.871C17.0008 6.73977 16.9553 6.61374 16.8866 6.50026C16.8179 6.38677 16.7272 6.2881 16.62 6.20999Z"
      fill="#166534"
    />
    <path
      d="M21.6198 6.20999C21.5163 6.12803 21.3976 6.06738 21.2705 6.03155C21.1434 5.99572 21.0105 5.9854 20.8794 6.00121C20.7483 6.01701 20.6217 6.05862 20.5068 6.12363C20.3918 6.18864 20.2909 6.27577 20.2098 6.37999L13.2098 15.38L12.5998 14.63L11.3398 16.25L12.4398 17.62C12.534 17.736 12.6529 17.8295 12.7879 17.8935C12.9228 17.9575 13.0704 17.9905 13.2198 17.99C13.37 17.9893 13.5182 17.9548 13.6532 17.889C13.7882 17.8232 13.9067 17.7278 13.9998 17.61L21.8298 7.60999C21.9081 7.50432 21.9648 7.38426 21.9966 7.25667C22.0284 7.12908 22.0347 6.99647 22.0152 6.86643C21.9957 6.73639 21.9507 6.61147 21.8829 6.49883C21.8151 6.38619 21.7257 6.28804 21.6198 6.20999Z"
      fill="#0061FF"
    />
    <path
      d="M8.70982 13.06L9.99982 11.44L9.79982 11.2C9.72087 11.0906 9.62061 10.9984 9.50506 10.9287C9.38952 10.8591 9.26109 10.8136 9.12749 10.7949C8.9939 10.7762 8.8579 10.7848 8.72769 10.82C8.59748 10.8553 8.47575 10.9165 8.36982 11C8.26709 11.0825 8.18169 11.1845 8.11852 11.3002C8.05534 11.4158 8.01564 11.5428 8.0017 11.6738C7.98777 11.8049 7.99986 11.9373 8.03729 12.0637C8.07472 12.19 8.13675 12.3077 8.21982 12.41L8.70982 13.06Z"
      fill="#0061FF"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DoubleCheckIcon',
  props: {},
  setup() {
    return {};
  },
});
</script>
