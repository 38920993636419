<template>
  <svg class="icon icon-document-locked" viewBox="0 0 32 32">
    <path
      d="M18 29v0.997c0 0.554 0.446 1.003 0.998 1.003h7.005c0.551 0 0.998-0.438 0.998-1.003v-4.994c0-0.554-0.446-1.003-0.998-1.003h-0.002v-1.495c0-1.398-0.819-2.604-2-3.167v-10.338l-6-7h-10.997c-1.106 0-2.003 0.898-2.003 2.007v22.985c0 1.109 0.891 2.007 1.997 2.007h11.003zM18 28h-11c-0.545 0-1-0.446-1-0.995v-23.009c0-0.54 0.446-0.995 0.996-0.995h10.004v4.994c0 1.119 0.895 2.006 1.998 2.006h4.002v9.036c-0.163-0.023-0.33-0.036-0.5-0.036-1.933 0-3.5 1.568-3.5 3.505v1.495h-0.002c-0.551 0-0.998 0.438-0.998 1.003v2.997zM20 24v-1.491c0-1.397 1.119-2.509 2.5-2.509 1.39 0 2.5 1.123 2.5 2.509v1.491h-5zM18 3.5l4.7 5.5h-3.703c-0.546 0-0.997-0.452-0.997-1.009v-4.491zM19.503 25h5.993c0.271 0 0.503 0.223 0.503 0.498v4.005c0 0.283-0.225 0.498-0.503 0.498h-5.993c-0.271 0-0.503-0.223-0.503-0.498v-4.005c0-0.283 0.225-0.498 0.503-0.498z"
    ></path>
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LockFileIcon',
  props: {},
  setup() {
    return {};
  },
});
</script>
