import { AxiosInstance } from 'axios';

const PREFIX = '/app-auth';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 권한 목록 조회
     */
    findAll: async () => {
      return $axios.get(`${PREFIX}`).then(response => response.data);
    },
  };
};
