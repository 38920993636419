import { createStore } from 'vuex';
import { ArticleWriteModule, ArticleWriteState } from './modules/article-write';
import {
  CueSheetWriteModule,
  CueSheetWriteState,
} from './modules/cue-sheet-write';
import { TabModule, TabState } from './modules/tab';
import { IconModule, IconState } from './modules/icon';
import { UserModule, UserState } from './modules/user';
import { SubmitModule, SubmitState } from './modules/submit';
import { AuthModule, AuthState } from './modules/auth';
import { CompanyModule, CompanyState } from './modules/company';
import { WorkerModule, WorkerState } from './modules/worker';
import { RefreshModule, RefreshState } from './modules/refresh';
import ProgramsModule, {
  MODULE_NAME as PROGRAM_MODULE_NAME,
} from './modules/program/index';
import CategoryModule, {
  MODULE_NAME as CATEGORY_MODULE_NAME,
} from './modules/category/index';
import { ReferenceModule, ReferencesState } from './modules/reference';
import { SystemInfoModule, SystemInfoState } from './modules/system-info';
import { UnLockModule, UnLockState } from './modules/unLock';
import AppModule, { MODULE_NAME as APP_MODULE_NAME } from './modules/app';

export type RootState = {
  articleWrite: ArticleWriteState;
  cueSheetWrite: CueSheetWriteState;
  tab: TabState;
  icon: IconState;
  // program: ProgramState;
  user: UserState;
  submit: SubmitState;
  auth: AuthState;
  company: CompanyState;
  worker: WorkerState;
  reference: ReferencesState;
  systemInfo: SystemInfoState;
  refreshState: RefreshState;
  UnLockState: UnLockState;
};

export default createStore({
  modules: {
    articleWrite: ArticleWriteModule,
    cueSheetWrite: CueSheetWriteModule,
    tab: TabModule,
    icon: IconModule,
    [PROGRAM_MODULE_NAME]: ProgramsModule,
    [CATEGORY_MODULE_NAME]: CategoryModule,
    user: UserModule,
    submit: SubmitModule,
    auth: AuthModule,
    company: CompanyModule,
    worker: WorkerModule,
    reference: ReferenceModule,
    systemInfo: SystemInfoModule,
    refresh: RefreshModule,
    unLock: UnLockModule,
    [APP_MODULE_NAME]: AppModule,
  },
});
