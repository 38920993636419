<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    width="24"
    class="focus:outline-none"
    :fill="fill"
    :stroke="stroke"
  >
    <path
      d="M9.525 17.3 19.4 7.425l-.725-.725-9.15 9.175-4.225-4.25-.725.725Zm0 1.4-6.35-6.35L5.3 10.225l4.225 4.225L18.7 5.275 20.85 7.4Z"
    />
  </svg>
</template>

<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'DoneOutlineIcon',
  props: {
    options: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const fill = ref(props.options?.fill || ''); // 테두리
    const stroke = ref(props.options?.stroke || ''); // 채워넣기

    return {
      fill,
      stroke,
    };
  },
});
</script>
