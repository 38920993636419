<template>
  <div>
    <Splitpanes
      :horizontal="layout === 'horizontal'"
      class="default-theme"
      :style="[`height: ${height}`]"
    >
      <Pane
        v-for="slot in slots"
        :key="slot.name"
        :min-size="slot.minSize"
        :size="slot.size"
        :style="[`overflow: ${overflow}`]"
        class="p-2"
      >
        <slot :name="slot.name" />
      </Pane>
    </Splitpanes>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';

export default defineComponent({
  name: 'GSplitpanes',
  components: {
    Splitpanes,
    Pane,
  },
  props: {
    slots: {
      type: Array,
      default: () => {
        return [];
      },
    },
    layout: {
      type: String,
      default: 'vertical',
    },
    height: {
      type: String,
      default: '',
    },
    overflow: {
      type: String,
      default: 'hidden',
    },
  },

  setup() {
    return {};
  },
});
</script>
<style scoped>
.splitpanes {
  height: auto;
}
.splitpanes /deep/ .splitpanes__splitter {
  /* width: 10px; */
  background-color: rgb(229 231 235);
}
.splitpanes--horizontal .splitpanes__pane {
  transition: none;
}
</style>
