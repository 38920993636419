<template>
  <div
    class="text-sm bg-white space-y-2 py-3 px-4 rounded-md overflow-y-auto"
    :class="[height]"
  >
    <!-- 형식 -->
    <ArticleTypeSelect
      v-if="
        (articleMode === 'view' && !isCuesheet) ||
        (isCuesheet && cuesheetItemDivisionCode === cuesheetItemCode.ARTICLE.id)
      "
      v-model="type"
      item-label
      :all-option="false"
      :disabled="
        articleMode === 'view' ||
        articleApprove === articleApproveCode.articleApprov.id ||
        isCuesheet
      "
      width="w-full"
    />
    <!-- 제목 -->
    <!-- <GInput
      v-model="title"
      name="title"
      :required="true"
      :label="t('ArticleMetaData.제목')"
      :disabled="articleMode === 'view'"
      :title="title"
      placeholder="제목을 입력하세요."
    /> -->
    <!-- 기자: 내 지역사 -->
    <WorkerSelect
      v-if="
        (articleMode === 'edit' || articleMode === 'create') &&
        cuesheetItemDivisionCode === cuesheetItemCode.ARTICLE.id &&
        isSameChannel &&
        !isCuesheet
      "
      v-model="reporter"
      item-label
      width="w-full"
      required
      :company-code="companyId"
      :disabled="
        articleMode === 'view' ||
        articleApprove === articleApproveCode.articleApprov.id ||
        isCuesheet
      "
    />
    <!-- 기자: 다른 지역사 -->

    <GInput
      v-if="articleMode === 'view' || !isSameChannel || isCuesheet"
      v-model="reporter"
      name="reporter"
      :label="'기자'"
      :disabled="
        articleMode === 'view' ||
        articleApprove === articleApproveCode.articleApprov.id ||
        isCuesheet ||
        !isSameChannel
      "
    />

    <!-- 프로그램: 내 지역사 -->
    <ProgramSelect
      v-if="companyId === articleChannelId"
      v-model="program"
      item-label
      width="w-full"
      :company-code="companyId"
      :disabled="
        articleMode === 'view' ||
        articleApprove === articleApproveCode.articleApprov.id ||
        isCuesheet
      "
    />
    <!-- 프로그램: 다른 지역사 -->
    <GInput
      v-if="articleMode === 'view' && companyId !== articleChannelId"
      v-model="program"
      name="program"
      :label="'프로그램'"
      :disabled="
        articleMode === 'view' ||
        articleApprove === articleApproveCode.articleApprov.id ||
        isCuesheet
      "
    />

    <!-- 길이 -->
    <GInput
      v-model="articleTime"
      name="articleTime"
      :label="t('ArticleMetaData.길이')"
      :disabled="true"
    />

    <div class="space-y-1">
      <!--  ExtraTime -->
      <!-- ((articleMode === 'view' ||
            articleApprove === articleApproveCode.articleApprov.id) &&
            !isCuesheet) ||
          (articleMode === 'view' && companyId !== articleChannelId) -->
      <ExtraTimeInput
        v-model="extraTime"
        :label="t('ArticleMetaData.Extra_Time')"
        :sub-label="extraTimeType"
        sub-cls="cursor-pointer"
        :disabled="
          articleMode === 'view' ||
          articleApprove === articleApproveCode.articleApprov.id
        "
        name="extraTime"
        placeholder="00:00"
        mask-format="##:##"
        @maska="onSetTime"
        @on-sub-clicked="onToggleSign"
        @on-radio-selected="onSelectedSign"
      />
      <span class="mt-2 text-xs text-red-600">{{ errorMsg }}</span>
    </div>

    <!-- 구분 -->
    <ArticleDivisionSelect
      v-if="cuesheetItemDivisionCode === cuesheetItemCode.ARTICLE.id"
      v-model="division"
      item-label
      preselectFirst
      width="w-full"
      :company-code="companyId"
      :disabled="
        articleMode === 'view' ||
        articleApprove === articleApproveCode.articleApprov.id ||
        isCuesheet
      "
      @select="onSelectDivision"
    />
    <!-- 구분에 따른 시간 -->
    <DateTimePickerEx
      v-if="
        cuesheetItemDivisionCode === cuesheetItemCode.ARTICLE.id &&
        division?.id === articleDivisionCode.embargo.id
      "
      v-model="embargo"
      name="embargoDateTime"
      :label="t('ArticleMetaData.엠바고시간')"
      width="w-full"
      :disabled="
        articleMode === 'view' ||
        articleApprove === articleApproveCode.articleApprov.id ||
        isCuesheet
      "
      @change="onChangeDate"
    />
    <DatePickerEx
      v-if="
        cuesheetItemDivisionCode === cuesheetItemCode.ARTICLE.id &&
        (division?.id === articleDivisionCode.expected.id ||
          division?.id === articleDivisionCode.basic.id) &&
        embargo
      "
      v-model="embargo"
      name="embargoDate"
      :label="t('ArticleMetaData.예정시간')"
      width="w-full"
      :disabled="
        articleMode === 'view' ||
        articleApprove === articleApproveCode.articleApprov.id ||
        isCuesheet ||
        division?.id === articleDivisionCode.basic.id
      "
      @change="onChangeDate"
    />

    <!-- 분야: 내 지역사 -->
    <ArticleCategorySelect
      v-if="
        (articleMode === 'edit' || articleMode === 'create') &&
        companyId === articleChannelId &&
        cuesheetItemDivisionCode === cuesheetItemCode.ARTICLE.id &&
        !isCuesheet
      "
      v-model="category"
      item-label
      width="w-full"
      :company-code="companyId"
      :disabled="
        articleMode === 'view' ||
        articleApprove === articleApproveCode.articleApprov.id ||
        isCuesheet
      "
    />
    <!-- 분야: 다른 지역사 -->
    <GInput
      v-if="articleMode === 'view' || isCuesheet"
      v-model="category"
      name="category"
      :label="'분야'"
      :disabled="
        articleMode === 'view' ||
        articleApprove === articleApproveCode.articleApprov.id ||
        isCuesheet
      "
    />

    <!-- 태그 -->
    <div
      v-if="cuesheetItemDivisionCode === cuesheetItemCode.ARTICLE.id"
      class="space-y-1"
    >
      <div class="flex items-center space-x-1">
        <div class="text-xs">{{ t('ArticleMetaData.태그') }}</div>
        <!-- <Cog6ToothIcon
          v-if="articleMode !== 'view'"
          v-tooltip="t('ArticleMetaData.태그_편집')"
          class="relative w-6 h-6 text-gray-600 cursor-pointer rounded-md outline-none p-0.5 hover:bg-gray-200"
          @click="isShowTagManagementModal = true"
        /> -->
      </div>
      <GTagSelect
        v-model="tag"
        name="tag"
        :placeholder="t('ArticleMetaData.태그')"
        :disabled="
          articleMode === 'view' ||
          articleApprove === articleApproveCode.articleApprov.id ||
          isCuesheet
        "
      />
    </div>
    <div v-if="articleMode === 'view' || isCuesheet" class="space-y-1">
      <div class="w-full py-1">
        <hr />
      </div>
      <GInput
        v-model="writer"
        name="writer"
        :placeholder="t('ArticleMetaData.작성자')"
        :label="t('ArticleMetaData.작성자')"
        :disabled="true"
        :readonly="true"
      />
      <GInput
        v-model="writeDate"
        name="writeDate"
        :placeholder="t('ArticleMetaData.작성일시')"
        :label="t('ArticleMetaData.작성일시')"
        :disabled="true"
        :readonly="true"
      />
      <GInput
        v-model="transferDateTime"
        name="transferDateTime"
        :placeholder="t('ArticleMetaData.송고일시')"
        :label="t('ArticleMetaData.송고일시')"
        :disabled="true"
        :readonly="true"
      />
      <GInput
        v-if="route.name !== RouterName.MyArticle"
        v-model="approveDateTime"
        name="approveDateTime"
        :placeholder="t('ArticleMetaData.출고일시')"
        :label="t('ArticleMetaData.출고일시')"
        :disabled="true"
        :readonly="true"
      />
    </div>

    <!-- 태그 관리 -->
    <!-- <TagManagementModal
      v-if="isShowTagManagementModal"
      @on-close="isShowTagManagementModal = false"
    /> -->
  </div>
</template>

<script>
import { defineComponent, ref, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { RouterName } from '@/types/router';
import { GTagSelect, GInput } from '@/components/ui';
// import TimePickerEx from '@/components/common/TimePickerEx';
import DateTimePickerEx from '@/components/common/DateTimePickerEx';
import DatePickerEx from '@/components/common/DatePickerEx';
import {
  ArticleTypeSelect,
  ProgramSelect,
  ArticleDivisionSelect,
  ArticleCategorySelect,
  WorkerSelect,
} from '@/components/select';
import { useForm } from 'vee-validate';
import yup from '@/utils/yup';
import {
  articleTypeCode,
  articleDivisionCode,
  articleApproveCode,
  cuesheetItemCode,
} from '@/codes';
import { defaultDate, defaultDateTime } from '@/utils/format.ts';
import { useUserInfo } from '@/hooks/use-user-info';
// import { getWorkerInfo } from '@/utils/get-data-helper';
import { useModelWrapper } from '@/hooks/useModelWrapper';
// import { Cog6ToothIcon } from '@heroicons/vue/24/outline';
// import TagManagementModal from '@/components/modal/tag/TagManagementModal.vue';
import ExtraTimeInput from './ExtraTimeInput';

export default defineComponent({
  name: 'ArticleMetaData',
  components: {
    GTagSelect,
    GInput,
    ExtraTimeInput,
    // TimePickerEx,
    DateTimePickerEx,
    DatePickerEx,
    ArticleTypeSelect,
    ProgramSelect,
    ArticleDivisionSelect,
    WorkerSelect,
    ArticleCategorySelect,
    // Cog6ToothIcon,
    // TagManagementModal,
  },
  props: {
    // articleTitle: {
    //   type: String,
    //   default: '',
    // },
    height: {
      type: String,
    },
    articleType: {
      type: [Object, String],
      default: () => {
        return null;
      },
    },
    articleReporter: {
      type: [Object, Number, String],
      default: () => {
        return null;
      },
    },
    articleProgram: {
      type: [Object, Number, String],
      default: () => {
        return null;
      },
    },
    articleDivision: {
      type: [Object, String],
      default: () => {
        return null;
      },
    },
    articleCategory: {
      type: [Object, String, Number],
      default: () => {
        return null;
      },
    },
    articleEmbargo: {
      type: [Object, String],
      default: () => {
        return null;
      },
    },
    articleTag: {
      type: Array,
      default: () => {
        return null;
      },
    },
    articleMode: {
      type: String,
      default: '',
    },
    totalArticleTime: {
      type: [Number, String],
      default: 0,
    },
    isCuesheet: {
      type: Boolean,
      default: false,
    },
    isApprove: {
      type: Boolean,
      default: false,
    },
    isTransfer: {
      type: Boolean,
      default: false,
    },
    // 작성자
    articleInputer: {
      type: String,
      default: '',
    },
    // 송고일시
    articleTransferDate: {
      type: String,
      default: '',
    },
    // 출고일시
    articleApproveDate: {
      type: String,
      default: '',
    },
    // 작성일시
    articleUpdateDate: {
      type: String,
      default: '',
    },
    // 기사 채널 ID
    articleChannelId: {
      type: Number,
      default: 0,
    },
    // 출고 상태
    articleApprove: {
      type: String,
      default: '',
    },
    articleExtraTime: {
      type: String,
      default: '',
    },
    articleExtraTimeType: {
      type: String,
      default: '',
    },
    cuesheetItemDivisionCode: {
      type: String,
      default: cuesheetItemCode.ARTICLE.id,
    },
    isSameChannel: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'selected-type',
    'on-approve',
    'on-transfer',
    'error-msg',
    'update:articleDivision',
    'update:articleType',
    'update:articleReporter',
    'update:articleProgram',
    'update:articleCategory',
    'update:articleExtraTime',
    'update:articleExtraTimeType',
    'update:articleInputer',
    'update:articleUpdateDate',
    'update:articleTransferDate',
    'update:articleApproveDate',
    'update:articleEmbargo',
    'update:articleTag',
    'update:isTransfer',
  ],

  setup(props, { emit }) {
    const { t } = useI18n();
    const route = useRoute();
    const { companyId } = useUserInfo();
    const type = useModelWrapper(props, emit, 'articleType'); // 형식
    // type.value = articleTypeCode.Report.id;

    // const title = useModelWrapper(props, emit, 'articleTitle'); // 제목
    const reporter = useModelWrapper(props, emit, 'articleReporter'); // 기자
    // reporter.value = userMngId;

    const program = useModelWrapper(props, emit, 'articleProgram'); // 프로그램
    const division = useModelWrapper(props, emit, 'articleDivision'); // 구분
    const category = useModelWrapper(props, emit, 'articleCategory'); // 분야
    const embargo = useModelWrapper(props, emit, 'articleEmbargo');
    const tag = useModelWrapper(props, emit, 'articleTag');
    const extraTime = useModelWrapper(props, emit, 'articleExtraTime');
    const extraTimeType = useModelWrapper(props, emit, 'articleExtraTimeType');
    const isApprove = useModelWrapper(props, emit, 'isApprove');
    const isTransfer = useModelWrapper(props, emit, 'isTransfer');
    const writer = useModelWrapper(props, emit, 'articleInputer');
    const writeDate = useModelWrapper(props, emit, 'articleUpdateDate'); // 작성일시 (수정일시)
    // const extraTimeType = ref('+');

    // 송고일시
    const transferDateTime = useModelWrapper(
      props,
      emit,
      'articleTransferDate',
    );
    const approveDateTime = useModelWrapper(props, emit, 'articleApproveDate'); // 출고일시
    const typeList = articleTypeCode.values;
    const errorMsg = ref(null);

    const isShowTagManagementModal = ref(false);

    // 앵커멘트와 리포트멘트가 변경될때마다 시간 표시
    const articleTime = computed(() => {
      return props.totalArticleTime;
    });

    // validation 적용
    const { validate } = useForm({
      initialValues: {
        reporter: null,
        // title: null,
        // program: null,
      },
      validationSchema: yup.object().shape({
        reporter: yup.mixed().required(),
        // title: yup.string().required(),
        // program: yup.mixed().required(),
      }),
    });

    // 형식 감지
    watch(
      () => type.value,
      currentValue => {
        return currentValue ? emit('selected-type', currentValue.id) : null;
      },
    );

    // 출고 클릭 감지
    watch(
      () => isApprove.value,
      async approveCd => {
        if (!approveCd) {
          return;
        }

        // 필수값 체크
        const res = await validate();
        emit('on-approve', res.valid);
        isApprove.value = false;
      },
    );

    // 송고 클릭 감지
    watch(
      () => isTransfer.value,
      async transferYn => {
        if (!transferYn) {
          return;
        }

        // 필수값 체크
        const res = await validate();
        emit('on-transfer', res.valid);
        isTransfer.value = false;
      },
    );

    // 엠바고 날짜 변경
    const onChangeDate = date => {
      embargo.value =
        division.value.id === articleDivisionCode.expected.id
          ? defaultDate(date)
          : defaultDateTime(date);
    };

    /**
     * 분류 변경
     * @param {string} division - 구분코드
     * 엠바고나 예정으로 변경시 값을 초기화해주고, 일반으로 변경시 변경된 값을 유지한다.
     */
    const onSelectDivision = division => {
      if (division.id === articleDivisionCode.basic.id) {
        // embargo.value = null;
      } else if (division.id === articleDivisionCode.expected.id) {
        embargo.value = defaultDate();
      } else {
        embargo.value = defaultDateTime();
      }
    };

    // 시간 설정
    const onSetTime = maska => {
      if (!maska.completed) {
        errorMsg.value = '시간을 다시 확인해주세요.';
      } else {
        errorMsg.value = '';
      }

      emit('error-msg', errorMsg.value ? false : true);
    };

    // 엑스트라타임 양수, 음수 토글 기능(2023-10-04 : 사용하지 않음)
    const onToggleSign = () => {
      extraTimeType.value = extraTimeType.value === '+' ? '-' : '+';
    };

    // 엑스트라타임 양수, 음수 선택
    const onSelectedSign = select => {
      extraTimeType.value = select;
    };

    return {
      t,
      route,
      RouterName,
      // title,
      type,
      typeList,
      reporter,
      program,
      articleTime,
      division,
      embargo,
      onChangeDate,
      onSelectDivision,
      tag,
      extraTime,
      writer,
      writeDate,
      transferDateTime,
      approveDateTime,
      category,
      companyId,
      articleDivisionCode,
      isShowTagManagementModal,
      articleApproveCode,
      onSetTime,
      errorMsg,
      validate,
      cuesheetItemCode,
      extraTimeType,
      onToggleSign,
      onSelectedSign,
    };
  },
});
</script>
