import { AxiosInstance } from 'axios';

const PREFIX = '/article';
const headers = {
  'Content-Type': 'multipart/form-data',
};

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 전체 기사 목록 조회
     * @param params
     * @returns
     */
    findAll: async (params: any) => {
      return $axios
        .get(`${PREFIX}`, { params })
        .then(response => response.data.data);
    },

    /**
     * 전체 기사 목록 조회 (엘라스틱)
     * @param params
     * @returns
     */
    findAllElastic: async (params: any) => {
      return $axios
        .get(`${PREFIX}/es/search`, { params })
        .then(response => response.data.data);
    },

    /**
     * 기사 저장
     * @param params
     * @returns
     */
    create: async (params: any) => {
      return $axios.post(`${PREFIX}`, params).then(response => response.data);
    },

    /**
     * 기사 상세 조회
     * @param articleId
     * @returns
     */
    findOne: async (articleId: number) => {
      return $axios
        .get(`${PREFIX}/${articleId}`)
        .then(response => response.data.data);
    },

    /**
     * 기사 수정
     * @param articleId
     * @returns
     */
    update: async (articleId: string | number, params: any) => {
      return $axios
        .put(`${PREFIX}/${articleId}`, params)
        .then(response => response.data);
    },

    /**
     * 기사 삭제
     * @param articleId
     * @returns
     */
    delete: async (articleId: string | number) => {
      return $axios
        .delete(`${PREFIX}/${articleId}`)
        .then(response => response.data);
    },

    /**
     * 기사 복사
     * @param articleId
     * @returns
     */
    copy: async (articleId: string | number, params: any) => {
      return $axios
        .post(`${PREFIX}/${articleId}/copy`, params)
        .then(response => response.data);
    },

    /**
     * 기사 출고 상태 변경
     * @param params
     * @returns
     */
    updateApprove: async (articleId: any, params: any) => {
      return $axios
        .put(`${PREFIX}/${articleId}/approve`, params)
        .then(response => response.data);
    },

    /**
     * 기사 잠금
     * @param articleId 기사 아이디
     * @returns
     */
    lock: async (articleId: any) => {
      return $axios
        .put(`${PREFIX}/${articleId}/lock`)
        .then(response => response.data);
    },

    /**
     * 기사 잠금 해제
     * @param {number} articleId 기사 아이디
     * @param {object} option - request body
     * @param {boolean} option.isForce - 강제 잠금해제 여부(필수 아님)
     * @returns
     */
    unLock: async (articleId: number, params: any = { isForce: false }) => {
      return $axios
        .put(`${PREFIX}/${articleId}/unlock`, params)
        .then(response => response.data);
    },

    /**
     * 기사 목록 엑셀 다운로드
     * @param {object} option - request body
     * @param {boolean} option.startDate - 시작 시간
     * @param {boolean} option.endDate - 종료 시간
     * @param {boolean} option.apprvTypCds - 출고여부
     * @param {boolean} option.chDivIds - 기역사 ID
     * @param {boolean} option.artclTypCd - 기사 형식
     * @param {boolean} option.rptrId - 리포터 ID
     * @param {boolean} option.searchValue - 검색어
     * @param {boolean} option.page - 페이지
     * @param {boolean} option.size - 총 불러올 개수
     * @returns
     */
    excelDownload: async (params: any) => {
      return $axios
        .get(`${PREFIX}/download`, {
          params,
          responseType: 'blob',
          headers,
        })
        .then(response => response.data);
    },

    /**
     * 홈페이지 전송
     * @param articleId
     */
    sendHomePage: async (articleId: any) => {
      return $axios
        .put(`${PREFIX}/${articleId}/send`)
        .then(response => response.data);
    },

    /**
     * 홈페이지 전송 취소
     * @param articleId
     */
    unSendHomePage: async (articleId: any) => {
      return $axios
        .put(`${PREFIX}/${articleId}/unsend`)
        .then(response => response.data);
    },

    /**
     * 타 지역 송고
     * @param articleId
     * @param chDivId
     * */
    moveChannel: async (articleId: any, chDivId: any) => {
      const response = await $axios.post(
        `${PREFIX}/${articleId}/move`,
        {},
        {
          params: { moveChannel: chDivId },
        },
      );
      return response.data;
    },
  };
};
