<template>
  <td class="px-3">{{ row?.order }}</td>
  <td class="">
    <!-- 출고상태 -->
    <ArticleStatusUI
      :approve-code="row.apprvTypCd"
      :is-cuesheet-matching="row?.cueMatchCnt > 0"
    />
  </td>

  <td class="p-2">{{ setTransferDtm }}</td>
  <td class="p-2">{{ row.chDivCd }}</td>
  <td class="p-2">
    <ArticleTypeUI :item="row.artclTypCd" />
  </td>
  <td class="p-2">{{ row.artclTitl }}</td>
  <td class="p-2">{{ row.deptNm }}</td>
  <td class="p-2">{{ setReporterName }}</td>
  <td class="p-2">{{ row.brdcPgmNm }}</td>
  <td class="p-2">{{ setArticleTime }}</td>
  <td class="p-2">
    <PaperClipIcon
      v-if="
        row?.articleAttachmentFileList && row?.articleAttachmentFileList.length
      "
      class="w-4 h-4"
    />
  </td>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import { ArticleTypeUI, ArticleStatusUI } from '@/components/article/commonUI';
import { displayDateTimeHm, secondToMs } from '@/utils/format.ts';
import { PaperClipIcon } from '@heroicons/vue/24/outline';

const props = defineProps({
  index: {
    type: Number,
    default: () => {
      return 0;
    },
  },
  row: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

// 송고일시
const setTransferDtm = computed(() => {
  return displayDateTimeHm(props.row?.inputDtm);
});

/**
 * 기사 시간 : 앵커멘트 + 리포트 + extraTime
 */
const setArticleTime = computed(() => {
  return secondToMs(
    props.row.artclCttTime + props.row.ancMentCttTime + props.row.artclExtTime,
  );
});

const setReporterName = computed(() => {
  return props.row.isSameChannel
    ? props.row.rptrNm
    : `${props.row.orgRptrNm}(${props.row.orgChNm})`;
});
</script>

<style lang="scss" scoped></style>
