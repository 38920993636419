import { AxiosInstance } from 'axios';

const PREFIX = '/group';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 그룹 목록 조회
     * @param {string} groupName 그룹 이름
     */
    findAll: async (params: Object) => {
      return $axios
        .get(`${PREFIX}`, { params })
        .then(response => response.data);
    },

    /**
     * 그룹 생성
     * @param {Object} params 추가 내용
     */
    create: async (params: Object) => {
      return $axios.post(`${PREFIX}`, params).then(response => response.data);
    },

    /**
     * 그룹 수정
     * @param {number} groupId - 그룹 아이디
     * @param params - 수정 내용
     */
    update: async (groupId: Number, params: Object) => {
      return $axios
        .put(`${PREFIX}/${groupId}`, params)
        .then(response => response.data);
    },

    /**
     * 그룹 삭제
     * @param {number} groupId - 그룹 아이디
     *
     */
    delete: async (groupId: Number) => {
      return $axios
        .delete(`${PREFIX}/${groupId}`)
        .then(response => response.data);
    },

    /**
     * 그룹 상세 조회
     * @param {number} groupId - 그룹 아이디
     */
    findInfo: async (groupId: Number) => {
      return $axios.get(`${PREFIX}/${groupId}`).then(response => response.data);
    },
  };
};
