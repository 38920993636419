<template>
  <div>
    <div class="flex space-x-2 py-2">
      <RangeDatePickerEx
        v-model="dates"
        v-tooltip="`${dates[0]} ~ ${dates[1]}`"
        width="w-56"
        @change="onChangeDate"
      />
      <div>
        <!-- 프로그램 -->
        <ProgramSelect
          v-model="program"
          all-option
          :company-code="companyId"
          width="w-44"
          @select="onSearchArticles"
        />
      </div>
      <div class="flex space-x-2">
        <GInput
          v-model="searchInput"
          :placeholder="t('CueArticleSearch.검색어')"
          width="w-44"
          @keydown.enter="onSearchArticles"
        />
        <GButton
          v-tooltip="t('CueArticleSearch.검색')"
          icon="magnifying-glass"
          @click="onSearchArticles"
        />
      </div>
    </div>
    <div>
      <!-- <GSplitpanes
        :slots="slots"
        :layout="!viewMode ? `horizontal` : 'vertical'"
        height="40.438rem"
      > -->
      <!-- <template #article> -->
      <CueArticleSearchList
        :cue-article-search-list="cueArticleList"
        :group="group"
        :is-loading="isLoading"
        :pagination="paginationInfo"
        :draggable="mode === 'edit' ? true : false"
        :selected-row-index="selectedRowIndex"
        @on-row-clicked="onSelectedRow"
        @on-row-db-clicked="onRowDbClicked"
        @on-canvas-move="setLinkedArticle"
        @is-dragging="isDragging"
        @is-finished-drag="endDragging"
        @on-changed-page="onChangePage"
      />
      <!-- </template>
        <template #preview> -->
      <!-- 2023-09-12 고객요청사항 : 큐시트에서 기사 미리보기 빼주세요. -->
      <!-- <div v-if="isLoadingPreView" class="h-[calc(50vh-150px)]">
        <PageLoader />
      </div>
      <div
        v-else-if="!type"
        class="bg-white space-y-4 py-3 px-4 rounded-md h-[calc(50vh-150px)]"
      >
        <GNoData />
      </div>
      <div v-else-if="type" class="h-[calc(50vh-140px)]">
        <ArticleEditor
          v-if="type"
          :type-id="type"
          v-model:reporter-text-array="articleEditorState.reporterTextArray"
          v-model:anchor-text-array="articleEditorState.anchorTextArray"
          v-model:caption-array="articleEditorState.reporterCaptionArray"
          :reporter-editor-read-only="true"
          :reporter-caption-read-only="true"
          :anchor-editor-read-only="true"
          :anchor-caption-read-only="true"
        />
      </div> -->
      <!-- </template>
      </GSplitpanes> -->
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import RangeDatePickerEx from '@/components/common/RangeDatePickerEx';
import CueArticleSearchList from '@/components/cueSheet/CueArticleSearchList';
import { GInput, GButton /*, GSplitpanes*/ } from '@/components/ui';
import dayjs from 'dayjs';
import { ProgramSelect } from '@/components/select';
import { useUserInfo } from '@/hooks/use-user-info';
import { useMarsLApi } from '@/apis/mars-l-api';
import articleApproveCode from '@/codes/article-approve-code';
import { defaultDate } from '@/utils/format.ts';
import { PageConst } from '@/enums';
// import ArticleEditor from '@/components/article/ArticleEditor';
// import GNoData from '@/components/ui/GNoData.vue';
// import PageLoader from '@/components/common/PageLoader.vue';

export default defineComponent({
  name: 'CueArticleSearch',
  components: {
    RangeDatePickerEx,
    CueArticleSearchList,
    // GSingleSelect,
    GInput,
    GButton,
    // GSplitpanes,
    ProgramSelect,
    // ArticleEditor,
    // GNoData,
    // PageLoader,
  },
  props: {
    viewMode: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    group: {
      type: Object,
      default: () => {
        return null;
      },
    },
    //편집모드
    mode: {
      type: String,
      default: () => {
        return '';
      },
    },
    selectedRowIndex: {
      type: Number,
    },
  },
  emits: [
    'on-canvas-move',
    'is-dragging',
    'is-finished-drag',
    'on-row-db-clicked',
  ],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { companyId } = useUserInfo();
    const marsLApi = useMarsLApi();
    const dates = ref([
      defaultDate(dayjs().subtract(6, 'day')),
      defaultDate(dayjs().add(1, 'day')),
    ]);
    const type = ref(null);
    const articleEditorState = ref({
      anchorTextArray: [''],
      anchorCaptionArray: [[]], // c/t형태: [[{}],[{}],[{}]...]
      reporterTextArray: [''],
      reporterCaptionArray: [[]],
    });
    const searchInput = ref();
    const selectedRow = ref();

    const slots = ref([
      {
        name: 'article',
        minSize: '20',
        size: '50',
      },
      {
        name: 'preview',
        minSize: '20',
        size: '50',
      },
    ]);

    const cueArticleList = ref([]);

    const program = ref();
    const isLoading = ref(false);
    const isLoadingPreView = ref(false);
    const articleId = ref();
    const page = ref(1);
    const paginationInfo = ref(null);

    const getCueArticleList = async () => {
      try {
        isLoading.value = true;
        const params = {
          startDate: dates.value[0],
          endDate: dates.value[1],
          apprvTypCds: articleApproveCode.approveAll.id,
          apprvYn: '', // 출고여부
          brdcPgmId: program.value?.id, // 방송프로그램ID
          chDivIds: companyId,
          searchValue: searchInput.value, // 검색키워드
          page: page.value - 1, // page의 시작은 0
          size: PageConst.size,
        };

        // DB
        const response = await marsLApi.coverDesk.basic.findAllElastic(params);

        cueArticleList.value = response.content.map(item => {
          return {
            ...item,
            id: item.artclId ? item.artclId : item.id,
          };
        });
        selectedRow.value = null;

        paginationInfo.value = {
          isShow: true,
          page: page.value,
          totalPage: response.totalPages,
          totalCount: response.totalElements,
        };
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    // 페이지 변경
    const onChangePage = changePage => {
      page.value = changePage;
      getCueArticleList();
    };

    // 행 선택
    const onSelectedRow = row => {
      selectedRow.value = row;
    };

    const onRowDbClicked = row => {
      emit('on-row-db-clicked', row);
      // selectedRow.value = row;
    };

    const onSearchArticles = () => {
      page.value = 1;
      getCueArticleList();
    };
    // 날짜 변경
    const onChangeDate = date => {
      page.value = 1;
      dates.value = date;
      getCueArticleList();
    };

    const init = () => {
      getCueArticleList();
    };

    onMounted(() => {
      init();
    });

    const setLinkedArticle = (isdragging, event, row) => {
      emit('on-canvas-move', isdragging, event, row);
    };

    const isDragging = (parmas, e) => {
      emit('is-dragging', parmas, e);
    };
    const endDragging = parmas => {
      emit('is-finished-drag', parmas);
    };

    return {
      t,
      dates,
      cueArticleList,
      setLinkedArticle,
      program,
      isDragging,
      endDragging,
      isLoading,
      slots,
      onSearchArticles,
      companyId,
      searchInput,
      getCueArticleList,
      onSelectedRow,
      selectedRow,
      type,
      articleEditorState,
      isLoadingPreView,
      articleId,
      paginationInfo,
      onChangePage,
      onChangeDate,
      onRowDbClicked,
    };
  },
});
</script>

<style scoped></style>
