<template>
  <div class="w-full h-full scrollbar">
    <div
      class="w-full"
      v-for="(count, index) in editorCount"
      :key="`cross-talk-editor-[${count}]`"
    >
      <hr
        v-if="count > 1"
        class="h-[2px] mx-auto my-2 bg-gray-400 border-0 rounded"
      />

      <div class="w-full h-full flex flex-col">
        <div v-if="!hideAnchorEditor" class="flex">
          <CoreEditor
            :ref="`anchorEditors`"
            :key="`cross-talk-anchor-editor-[${count}]`"
            v-model:article-text="anchorTexts[index]"
            v-model:article-captions="anchorCaptions[index]"
            class="anchor-editor"
            :editor-read-only="anchorEditorReadOnly"
            :caption-read-only="anchorCaptionReadOnly"
            placeholder="앵커멘트 작성"
            @focusin="onAnchorEditorFocus(index)"
          />
        </div>
        <div v-if="!hideReporterEditor" class="flex">
          <CoreEditor
            :ref="`reporterEditors`"
            :key="`reporter-talk-anchor-editor-[${count}]`"
            v-model:article-text="reporterTexts[index]"
            v-model:article-captions="reporterCaptions[index]"
            class="mt-1 reporter-editor"
            :editor-read-only="reporterEditorReadOnly"
            :caption-read-only="reporterCaptionReadOnly"
            placeholder="리포터멘트 작성"
            @focusin="onReporterEditorFocus(index)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from 'vue';
import { isArray, isEmpty } from 'lodash';

import CoreEditor from './core/CoreEditor';
import { useModelWrapper } from '@/hooks/useModelWrapper';

export default {
  name: 'CrossTalkEditor',
  components: {
    CoreEditor,
  },
  props: {
    anchorTextArray: {
      type: Array,
      required: true,
      validator(value) {
        return isArray(value) && !isEmpty(value);
      },
    },
    anchorCaptionArray: {
      type: Array,
      required: true,
      validator(value) {
        return isArray(value) && !isEmpty(value) && isArray(value[0]);
      },
    },
    reporterTextArray: {
      type: Array,
      required: true,
      validator(value) {
        return isArray(value) && !isEmpty(value);
      },
    },
    reporterCaptionArray: {
      type: Array,
      required: true,
      validator(value) {
        return isArray(value) && !isEmpty(value) && isArray(value[0]);
      },
    },
    reporterEditorReadOnly: {
      type: Boolean,
      default: false,
    },
    reporterCaptionReadOnly: {
      type: Boolean,
      default: false,
    },
    anchorEditorReadOnly: {
      type: Boolean,
      default: false,
    },
    anchorCaptionReadOnly: {
      type: Boolean,
      default: false,
    },
    hideReporterEditor: {
      type: Boolean,
      default: false,
    },
    hideAnchorEditor: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit, expose }) {
    const anchorEditors = ref();
    const reporterEditors = ref();
    const lastFocusEditor = ref(null);
    const lastFocusEditorIndex = ref(0);

    //component states
    const editorCount = ref(2); // 초기에 보여줄 에디터 수 (기본 2개)
    const anchorTexts = useModelWrapper(props, emit, 'anchorTextArray');
    const anchorCaptions = useModelWrapper(props, emit, 'anchorCaptionArray');
    const reporterTexts = useModelWrapper(props, emit, 'reporterTextArray');
    const reporterCaptions = useModelWrapper(
      props,
      emit,
      'reporterCaptionArray',
    );

    const onAnchorEditorFocus = index => {
      lastFocusEditor.value = anchorEditors.value[index];
      lastFocusEditorIndex.value = index;
    };

    const onReporterEditorFocus = index => {
      lastFocusEditor.value = reporterEditors.value[index];
      lastFocusEditorIndex.value = index;
    };

    const insertTextAtCursor = insertText => {
      if (!lastFocusEditor.value) {
        return;
      }

      lastFocusEditor.value.insertTextAtCursor(insertText);
    };

    const getLastFocusEditorIndex = () => {
      return lastFocusEditorIndex.value;
    };

    expose({
      insertTextAtCursor,
      getLastFocusEditorIndex,
    });

    const init = () => {
      /** anchor, reporter 작성글과 자막 목록 순번(배열 갯수) 동기화 작업
       * 순번 계산 법 - 2(기본값) or props.anchorTextArray.length or props.reporterTextArray.length 중 가장 큰 값
       */
      editorCount.value = Math.max(
        2,
        props.anchorTextArray.length,
        props.reporterTextArray.length,
      );

      for (let i = 0; i < editorCount.value; i++) {
        anchorTexts.value[i] = anchorTexts.value[i] ?? '';
        reporterTexts.value[i] = reporterTexts.value[i] ?? '';
        anchorCaptions.value[i] = anchorCaptions.value[i] ?? [];
        reporterCaptions.value[i] = reporterCaptions.value[i] ?? [];
      }
    };

    watch(
      () => [anchorTexts.value.length, reporterTexts.value.length],
      ([newAnchorTextLength, newReporterTextLength]) => {
        editorCount.value = Math.max(
          2,
          newAnchorTextLength,
          newReporterTextLength,
        );
      },
    );

    onMounted(() => {
      init();
    });

    return {
      anchorEditors,
      reporterEditors,
      editorCount,
      anchorTexts,
      anchorCaptions,
      reporterTexts,
      reporterCaptions,
      onAnchorEditorFocus,
      onReporterEditorFocus,
    };
  },
};
</script>

<style scoped></style>
