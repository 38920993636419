
import { computed, defineComponent, PropType } from 'vue';
import { capitalize } from '../../utils/str';
import GSpinner from './GSpinner.vue';
import { IconType } from '../../types/ui';
import {
  XMarkIcon,
  PlusIcon,
  MagnifyingGlassIcon,
  PencilIcon,
  DocumentDuplicateIcon,
  TrashIcon,
  ArrowPathIcon,
  DocumentArrowUpIcon,
  ArrowDownTrayIcon,
  PrinterIcon,
  EyeIcon,
  DocumentMagnifyingGlassIcon,
  ArrowRightOnRectangleIcon,
  DocumentPlusIcon,
  DocumentMinusIcon,
  CheckIcon,
  PencilSquareIcon,
  DocumentTextIcon,
  ClipboardDocumentListIcon,
  InformationCircleIcon,
  DocumentIcon,
  ChatBubbleBottomCenterTextIcon,
  LinkIcon,
  ComputerDesktopIcon,
  ArrowUpOnSquareIcon,
  ClipboardDocumentCheckIcon,
  ArchiveBoxIcon,
  ArchiveBoxXMarkIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  ArrowLeftIcon,
  XCircleIcon,
  ArrowsRightLeftIcon,
  UsersIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ArrowUpCircleIcon,
  ClipboardDocumentIcon,
  ClockIcon,
  CloudIcon,
  CloudArrowUpIcon,
} from '@heroicons/vue/24/outline';
import {
  SpellcheckIcon,
  LockFileIcon,
  UnLockFileIcon,
  SongGoIcon,
  ChulGoIcon,
  DatabaseScriptIcon,
  RecoverIcon,
  ExcelIcon,
  MaterialFilterAltOffIcon,
} from '@/components/ui/icon';
export default defineComponent({
  name: 'GButton',
  components: {
    GSpinner,
    PlusIcon,
    XMarkIcon,
    MagnifyingGlassIcon,
    PencilIcon,
    DocumentDuplicateIcon,
    TrashIcon,
    ArrowPathIcon,
    DocumentArrowUpIcon,
    ArrowDownTrayIcon,
    PrinterIcon,
    EyeIcon,
    DocumentMagnifyingGlassIcon,
    ArrowRightOnRectangleIcon,
    DocumentPlusIcon,
    DocumentMinusIcon,
    CheckIcon,
    PencilSquareIcon,
    DocumentTextIcon,
    ClipboardDocumentListIcon,
    InformationCircleIcon,
    DocumentIcon,
    ChatBubbleBottomCenterTextIcon,
    LinkIcon,
    ComputerDesktopIcon,
    ArrowUpOnSquareIcon,
    SpellcheckIcon,
    LockFileIcon,
    UnLockFileIcon,
    SongGoIcon,
    ChulGoIcon,
    DatabaseScriptIcon,
    ClipboardDocumentCheckIcon,
    ArchiveBoxIcon,
    ArchiveBoxXMarkIcon,
    ChatBubbleOvalLeftEllipsisIcon,
    RecoverIcon,
    ArrowLeftIcon,
    XCircleIcon,
    ArrowsRightLeftIcon,
    UsersIcon,
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ExcelIcon,
    ArrowUpCircleIcon,
    MaterialFilterAltOffIcon,
    ClipboardDocumentIcon,
    ClockIcon,
    CloudIcon,
    CloudArrowUpIcon,
  },
  props: {
    type: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String as PropType<IconType>,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    text: {
      type: String,
    },
    customCls: {
      type: String,
    },
  },
  setup(props) {
    const setCls = computed(() => {
      let cls = '';

      if (props.icon) {
        cls = 'p-1.5';
      } else {
        cls = 'px-6 py-1.5';
      }

      if (props.type === 'primary') {
        cls =
          cls +
          ' bg-primary text-white outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary hover:bg-primary/80';
      } else if (props.type === 'secondary') {
        cls =
          cls +
          ' bg-white hover:bg-gray-200 text-gray-600 focus:ring-2 focus:ring-offset-2 focus:ring-primary shadow-sm';
      }

      if (props.customCls) {
        cls = cls + ` ${props.customCls}`;
      }

      if (props.disabled) {
        cls = cls + ' opacity-50 hover:opacity-50 cursor-not-allowed';
      }

      cls = cls + ` ${props.width}`;

      return cls;
    });

    const iconClass = computed(() => {
      let iconSize = 5;
      const cls = `w-${iconSize} h-${iconSize}`;

      return cls;
    });

    // 아이콘 컴포넌트 동적 생성
    const iconComponent = computed(() => {
      const icon = `${capitalize(props.icon)}Icon`;
      return icon;
    });

    return {
      iconComponent,
      iconClass,
      setCls,
    };
  },
});
