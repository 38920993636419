<script setup lang="ts"></script>

<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="30" height="30" rx="15" fill="#F0F7D2" />
    <path
      d="M11.1406 16.2871H12.2656C12.207 16.8262 12.0527 17.3086 11.8027 17.7344C11.5527 18.1602 11.1992 18.498 10.7422 18.748C10.2852 18.9941 9.71484 19.1172 9.03125 19.1172C8.53125 19.1172 8.07617 19.0234 7.66602 18.8359C7.25977 18.6484 6.91016 18.3828 6.61719 18.0391C6.32422 17.6914 6.09766 17.2754 5.9375 16.791C5.78125 16.3027 5.70312 15.7598 5.70312 15.1621V14.3125C5.70312 13.7148 5.78125 13.1738 5.9375 12.6895C6.09766 12.2012 6.32617 11.7832 6.62305 11.4355C6.92383 11.0879 7.28516 10.8203 7.70703 10.6328C8.12891 10.4453 8.60352 10.3516 9.13086 10.3516C9.77539 10.3516 10.3203 10.4727 10.7656 10.7148C11.2109 10.957 11.5566 11.293 11.8027 11.7227C12.0527 12.1484 12.207 12.6426 12.2656 13.2051H11.1406C11.0859 12.8066 10.9844 12.4648 10.8359 12.1797C10.6875 11.8906 10.4766 11.668 10.2031 11.5117C9.92969 11.3555 9.57227 11.2773 9.13086 11.2773C8.75195 11.2773 8.41797 11.3496 8.12891 11.4941C7.84375 11.6387 7.60352 11.8438 7.4082 12.1094C7.2168 12.375 7.07227 12.6934 6.97461 13.0645C6.87695 13.4355 6.82812 13.8477 6.82812 14.3008V15.1621C6.82812 15.5801 6.87109 15.9727 6.95703 16.3398C7.04688 16.707 7.18164 17.0293 7.36133 17.3066C7.54102 17.584 7.76953 17.8027 8.04688 17.9629C8.32422 18.1191 8.65234 18.1973 9.03125 18.1973C9.51172 18.1973 9.89453 18.1211 10.1797 17.9688C10.4648 17.8164 10.6797 17.5977 10.8242 17.3125C10.9727 17.0273 11.0781 16.6855 11.1406 16.2871ZM17.416 10.4688L13.8594 19.7324H12.9277L16.4902 10.4688H17.416ZM21.916 10.4688V19H20.8027V10.4688H21.916ZM24.6582 10.4688V11.3945H18.0664V10.4688H24.6582Z"
      fill="#A78D04"
    />
  </svg>
</template>

<style scoped></style>
