import { AxiosInstance } from 'axios';

const PREFIX = '/cuesheet';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 큐시트 생성
     * @param params - 생성 내용
     */
    create: async (params: Object) => {
      return $axios.post(`${PREFIX}`, params).then(response => response.data);
    },

    /**
     * 큐시트 수정
     * @param cuesheetId - 큐시트 ID
     * @param params - 수정 내용
     */
    update: async (cuesheetId: String | Number, params: Object) => {
      return $axios
        .put(`${PREFIX}/${cuesheetId}`, params)
        .then(response => response.data);
    },

    /**
     * 큐시트 삭제
     * @param cuesheetId - 큐시트 ID
     * @param params - 비밀번호
     */
    delete: async (cuesheetId: String, params: Object) => {
      return $axios
        .delete(`${PREFIX}/${cuesheetId}`, { data: params })
        .then(response => response.data);
    },

    /**
     * 큐시트 목록 조회
     * @param params
     * @returns
     */
    findAll: async (params: any) => {
      return $axios
        .get(`${PREFIX}`, { params })
        .then(response => response.data);
    },

    /**
     * 큐시트 정보 조회
     * @param cuesheetId - 큐시트 ID
     */
    findOne: async (cuesheetId: String) => {
      return $axios
        .get(`${PREFIX}/${cuesheetId}`)
        .then(response => response.data);
    },

    /**
     * 큐시트 복사
     * @param cuesheetId - 큐시트 ID
     * @param params - 복사 내용
     */
    copy: async (cuesheetId: String | Number, params: Object) => {
      return $axios
        .post(`${PREFIX}/${cuesheetId}/cuesheetcopy`, params)
        .then(response => response.data);
    },

    /**
     * 큐시트 오더락
     * @param cuesheetId - 큐시트 ID
     * @param params
     */
    orderLock: async (cuesheetId: String | Number, params: Object) => {
      return $axios
        .put(`${PREFIX}/${cuesheetId}/order-lock`, params)
        .then(response => response.data);
    },

    /**
     * 큐시트 락해제
     * @param cuesheetId - 큐시트 ID
     */
    unLock: async (cuesheetId: String | Number) => {
      return $axios
        .put(`${PREFIX}/${cuesheetId}/unLock`)
        .then(response => response.data);
    },
    /**
     * 큐시트 아이템 순서 편집
     * @param cuesheetId 큐시트 아이디
     * @param cuesheetItemId 큐시트 아이템 아이디
     * @param params
     * @returns
     */
    updateOrder: async (
      cuesheetId: String | Number,
      cuesheetItemId: String | Number,
      params: any,
    ) => {
      return $axios
        .put(`${PREFIX}/${cuesheetId}/item/${cuesheetItemId}/ord`, params)
        .then(response => response.data);
    },

    /**
     * 큐시트 확정
     * @param cuesheetId 큐시트 ID
     * @returns
     */
    confirm: async (cuesheetId: String) => {
      return $axios
        .put(`${PREFIX}/${cuesheetId}/confirm`)
        .then(response => response.data);
    },

    /**
     * 큐시트 확정 취소
     * @param cuesheetId 큐시트 ID
     * @returns
     */
    canceledConfirm: async (cuesheetId: String) => {
      return $axios
        .put(`${PREFIX}/${cuesheetId}/cancel`)
        .then(response => response.data);
    },

    /**
     * 동기화
     * @param cuesheetId  큐시트 아이디
     * @returns
     */
    synchronize: async (cuesheetId: String) => {
      return $axios
        .put(`${PREFIX}/${cuesheetId}/synchronization`)
        .then(response => response.data);
    },

    /**
     * 큐시트 장애인 자막 ftp 전송
     * @param cuesheetId  큐시트 아이디
     * @returns
     */
    transferFTP: async (cuesheetId: String) => {
      return $axios
        .put(`${PREFIX}/${cuesheetId}/transfer-ftp-cation`)
        .then(response => response.data);
    },

    /**
     * 큐시트 이력 조회
     * @param cuesheetId
     */
    finOneHistory: async (cuesheetId: any) => {
      return $axios
        .get(`${PREFIX}/${cuesheetId}/history`)
        .then(response => response.data);
    },
  };
};
