const SPECIALCHAR = ['◀', '▶'];

export const capitalize = (input: string, token = ' ') => {
  let str = '';
  if (input) {
    const words = input.split(token);
    const CapitalizedWords: string[] = [];
    words.forEach(element => {
      CapitalizedWords.push(
        element[0].toUpperCase() + element.slice(1, element.length),
      );
    });
    str = CapitalizedWords.join(token);
  }
  return str;
};

/**
 * 문자 바이트 값을 반환
 * 알파벳, 숫자, 특수문자(* , @, - , _ , + , . , /) - 1byte
 * 알파벳, 숫자, 특수문자(* , @, - , _ , + , . , /) 제외 - 2byte
 * @param target - 문자열
 * @param pos - 문자 인덱스
 */
export const getByte = (target: string, pos: number = 0) => {
  if (target.length <= pos) {
    throw new Error(`pos cannot be equal or higher than target length`);
  }

  if (pos < 0) {
    throw new Error('pos cannot be less than 0');
  }

  // return escape(target.charAt(pos)).length > 4 ? 2 : 1;
  return escape(target.charAt(pos)).length > 4 &&
    !SPECIALCHAR.includes(target.charAt(pos))
    ? 2
    : 1;
};

/**
 * 문자열 바이트 길이 계산
 * 알파벳, 숫자, 특수문자(* , @, - , _ , + , . , /) - 1byte
 * 알파벳, 숫자, 특수문자(* , @, - , _ , + , . , /) 제외 - 2byte
 * @param target - 문자열
 */
export const getBytes = (target: string) => {
  let charCount = 0;
  const targetLength = target.length;

  for (let k = 0; k < targetLength; k++) {
    charCount += getByte(target, k);
  }

  return charCount;
};

export const getByteArray = (target: string) => {
  let result = [];
  const targetLength = target.length;

  for (let i = 0; i < targetLength; i++) {
    result.push(getByte(target, i));
  }

  return result;
};

/**
 * 문자열을 바이트 기준으로 잘라서 반환 한다.
 * 문자 바이트 계산 방식
 * 알파벳, 숫자, 특수문자(* , @, - , _ , + , . , /) - 1byte
 * 알파벳, 숫자, 특수문자(* , @, - , _ , + , . , /) 제외 - 2byte
 * @param target - 문자열
 * @param sliceByte - 잘라낼 바이트 값
 * @returns {Array}
 */
export const sliceByByte = (target: string, sliceByte: number = 2) => {
  let result = [];
  let charCount = 0;
  let sliceStartIndex = 0;
  const targetLength = target.length;

  if (!targetLength) {
    return target;
  }

  for (let k = 0; k < targetLength; k++) {
    charCount += getByte(target, k);

    // 문자열바이트 합이 잘라낼 바이트 값보다 큰 경우 이전 값까지만 slice 처리
    if (charCount > sliceByte) {
      result.push(target.slice(sliceStartIndex, k));
      sliceStartIndex = k;
      charCount = getByte(target, k);

      // 마지막 문자일 경우 result에 해당 문자 push 처리
      if (targetLength === k + 1) {
        result.push(target.slice(sliceStartIndex, k + 1));
      }
    } else if (charCount === sliceByte) {
      result.push(target.slice(sliceStartIndex, k + 1));
      sliceStartIndex = k + 1;
      charCount = 0;
    } else if (targetLength === k + 1) {
      result.push(target.slice(sliceStartIndex, k + 1));
    }
  }

  return result;
};

/**
 * 문자열 앞뒤 \n을 빈문자열로 처리
 * @param {string} str - 문자열
 * @returns 앞뒤 공백 없는 문자열
 */
export const trimCarriageReturn = (str: string) => {
  return str.replace(/^\s+|\s+$/g, '');
};

/**
 * 문자열에서 원하는 문자열로 치환
 * @param {sting} str - 문자열
 * @param {sting} replaceStr - 변경할 문자열
 */
export const replaceText = (
  str: string,
  regExp: string,
  replaceStr: string,
) => {
  return str.replace(regExp, replaceStr);
};

/**
 * 배열 객체의 하이픈이 들어간 키값을 카멜케이스로 바꿔주는 함수
 * @param array
 * @returns
 */
export const convertArrayKeysToCamelCase = (array: any) => {
  if (!Array.isArray(array)) {
    return array;
  }

  return array.map(obj => {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }

    const camelCaseObj: any = {};
    for (const key in obj) {
      // if (obj.hasOwnProperty(key)) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const camelCaseKey = key.replace(/_./g, match =>
          match[1].toUpperCase(),
        );
        camelCaseObj[camelCaseKey] = convertArrayKeysToCamelCase(obj[key]);
      }
    }

    return camelCaseObj;
  });
};

/**
 * 하이픈이 들어간 키값을 카멜케이스로 바꿔주는 함수
 * @param obj
 * @returns
 */
export const convertKeysToCamelCase = (obj: any) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  const camelCaseObj: any = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const camelCaseKey = key.replace(/_./g, match => match[1].toUpperCase());
      camelCaseObj[camelCaseKey] = obj[key];

      if (Array.isArray(obj[key])) {
        camelCaseObj[camelCaseKey] = obj[key].map((item: any) => {
          return convertKeysToCamelCase(item);
        });
      }
    }
  }

  return camelCaseObj;
};

/**
 * 문자 사이에 문자 삽입
 * @param originalText
 * @param newText
 * @param position
 */
export const insertTextBetWeen = (
  originalText: string,
  newText: string,
  position: number,
) => {
  // 정규식을 생성합니다. position 위치 앞의 모든 문자열을 캡처합니다.
  const regex = new RegExp(`(.{${position}})`);

  // replace 메서드를 사용하여 정규식에 일치하는 부분을 찾아 새로운 텍스트로 대체합니다.
  return originalText.replace(regex, `$1${newText}`);
};
