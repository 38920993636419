<template>
  <div class="space-y-4">
    <GSingleSelect
      v-model="portalCategory"
      name="portalCategory"
      :options="portalCategoryList"
      track-by="id"
      label="label"
      width="w-1/2"
      required
      :allow-empty="false"
      :searchable="false"
      :item-label="t('Modal.Article.PortalNewsModal.카테고리')"
      :placeholder="t('Modal.Article.PortalNewsModal.카테고리')"
    />

    <div class="border border-gray-300 rounded-md p-3 space-y-3">
      <div class="space-y-1">
        <GInput
          v-model="thumbnailFileName"
          name="thumbnailFileName"
          :required="videoFileName || videoLink ? true : false"
          :label="t('Modal.Article.PortalNewsModal.썸네일_파일명')"
          :placeholder="t('Modal.Article.PortalNewsModal.썸네일_파일명')"
        />
        <div v-if="isShowErrorMsg" class="text-red-500 pb-1 font-D2CodingBold">
          {{ thumbnailFileNameError }}
        </div>
      </div>

      <div class="space-y-1">
        <GInput
          v-model="videoFileName"
          name="videoFileName"
          :placeholder="t('Modal.Article.PortalNewsModal.동영상_파일명')"
          :label="t('Modal.Article.PortalNewsModal.동영상_파일명')"
        />
        <div class="text-green-600">
          {{
            t(
              'Modal.Article.PortalNewsModal.외부_프로그램_FTP_를_이용하여_등록된_파일명_입력',
            )
          }}
        </div>
      </div>
    </div>

    <!-- 동영상 관련 내용은 하나만 보내는 걸로 협의 (2023-08-24) -->
    <div class="hidden border border-gray-300 rounded-md p-3 space-y-1">
      <div class="space-y-1">
        <GInput
          v-model="videoLink"
          name="videoLink"
          :placeholder="t('Modal.Article.PortalNewsModal.동영상_링크')"
          :label="t('Modal.Article.PortalNewsModal.동영상_링크')"
        />
        <div class="text-green-600">
          <span v-if="selectedTab?.id === 'naver'">
            {{ t('Modal.Article.PortalNewsModal.Naver_TV_링크만_추가_가능') }}
          </span>
          <span v-else-if="selectedTab?.id === 'kakao'">
            {{
              t(
                'Modal.Article.PortalNewsModal.카카오_TV_또는_유튜브_링크만_추가_가능',
              )
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { GInput, GSingleSelect } from '@/components/ui';
import { portalCategoryCode } from '@/codes';

const props = defineProps({
  selectedTab: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

const emit = defineEmits(['submit-data', 'is-valid']);

const { t } = useI18n();
const portalCategory = ref(null);
const portalCategoryList = ref(portalCategoryCode.values);
const thumbnailFileName = ref('');
const videoFileName = ref('');
const videoLink = ref('');
const thumbnailFileNameError = t(
  'Modal.Article.PortalNewsModal.동영상_정보가_있을_경우_썸네일_파일명은_필수입니다',
);

const isShowErrorMsg = computed(() => {
  let flag = false;
  if (!thumbnailFileName.value && (videoFileName.value || videoLink.value)) {
    flag = true;
  } else {
    flag = false;
  }
  emit('is-valid', { tab: props.selectedTab, invalid: flag });
  return flag;
});

watch(
  () => [
    portalCategory.value,
    thumbnailFileName.value,
    videoFileName.value,
    videoLink.value,
  ],
  ([
    newPortalCategory,
    newThumbnailFileName,
    newVideoFileName,
    newVideoLink,
  ]) => {
    emit('submit-data', {
      id: props.selectedTab?.id,
      portalCategory: newPortalCategory,
      thumbnailFileName: newThumbnailFileName,
      videoFileName: newVideoFileName,
      newVideoLink: newVideoLink,
    });
  },
);
</script>
