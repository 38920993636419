<template>
  <div ref="articleEditorRef" class="w-full h-full relative">
    <StraightEditor
      ref="straightEditor"
      v-if="!isEditorChanging && articleTypeCode.Straight.id === typeId"
      v-model:reporter-text="reporterTexts[0]"
      v-model:reporter-captions="reporterCaptions[0]"
      :editor-read-only="reporterEditorReadOnly"
      :caption-read-only="reporterCaptionReadOnly"
      :hideReporterEditor="hideReporterEditor"
    />
    <ReporterEditor
      ref="reporterEditor"
      v-if="!isEditorChanging && articleTypeCode.Report.id === typeId"
      v-model:anchor-text="anchorTexts[0]"
      v-model:anchor-captions="anchorCaptions[0]"
      v-model:reporter-text="reporterTexts[0]"
      v-model:reporter-captions="reporterCaptions[0]"
      :anchor-editor-read-only="anchorEditorReadOnly"
      :anchor-caption-read-only="anchorCaptionReadOnly"
      :reporter-editor-read-only="reporterEditorReadOnly"
      :reporter-caption-read-only="reporterCaptionReadOnly"
      :hide-anchor-editor="hideAnchorEditor"
      :hide-reporter-editor="hideReporterEditor"
    />
    <BottomRoleEditor
      ref="bottomRoleEditor"
      v-if="!isEditorChanging && articleTypeCode.BottomRole.id === typeId"
      v-model:reporter-text="reporterTexts[0]"
      :editor-read-only="reporterEditorReadOnly"
      :caption-read-only="reporterCaptionReadOnly"
      :hide-reporter-editor="hideReporterEditor"
    />
    <CrossTalkEditor
      ref="crossTalkEditor"
      v-if="!isEditorChanging && articleTypeCode.CrossTalk.id === typeId"
      v-model:anchor-text-array="anchorTexts"
      v-model:anchor-caption-array="anchorCaptions"
      v-model:reporter-text-array="reporterTexts"
      v-model:reporter-caption-array="reporterCaptions"
      :anchor-editor-read-only="anchorEditorReadOnly"
      :anchor-caption-read-only="anchorCaptionReadOnly"
      :reporter-editor-read-only="reporterEditorReadOnly"
      :reporter-caption-read-only="reporterCaptionReadOnly"
      :hide-anchor-editor="hideAnchorEditor"
      :hide-reporter-editor="hideReporterEditor"
    />
  </div>
</template>

<script>
import { defineComponent, nextTick, onMounted, ref, watch } from 'vue';
import {
  cloneDeep,
  compact,
  concat,
  flattenDeep,
  isArray,
  isEmpty,
  join,
  map,
  repeat,
} from 'lodash';

import { articleTypeCode } from '@/codes';

import StraightEditor from '@/components/article/StraightEditor';
import ReporterEditor from '@/components/article/ReporterEditor';
import BottomRoleEditor from '@/components/article/BottomRoleEditor';
import CrossTalkEditor from '@/components/article/CrossTalkEditor';
import { useModelWrapper } from '@/hooks/useModelWrapper';
import { getArticleLineCount, concatArrays } from '@/utils/print';
import { useScrollTop } from '@/hooks/use-scroll.ts';
import { useClipboard } from '@vueuse/core';

export default defineComponent({
  name: 'ArticleEditor',
  components: {
    StraightEditor,
    ReporterEditor,
    BottomRoleEditor,
    CrossTalkEditor,
  },
  props: {
    typeId: {
      type: [String, Number],
      required: true,
    },
    anchorTextArray: {
      type: Array,
      required: true,
      validator(value) {
        return isArray(value) && !isEmpty(value);
      },
    },
    reporterTextArray: {
      type: Array,
      required: true,
      validator(value) {
        return isArray(value) && !isEmpty(value);
      },
    },
    captionArray: {
      type: Array,
      required: true,
      // validator(value) {
      //   return isArray(value) && !isEmpty(value) && isArray(value[0]);
      // },
    },
    reporterEditorReadOnly: {
      type: Boolean,
      default: false,
    },
    reporterCaptionReadOnly: {
      type: Boolean,
      default: false,
    },
    anchorEditorReadOnly: {
      type: Boolean,
      default: false,
    },
    anchorCaptionReadOnly: {
      type: Boolean,
      default: false,
    },
    hideReporterEditor: {
      type: Boolean,
      default: false,
    },
    hideAnchorEditor: {
      type: Boolean,
      default: false,
    },
    defaultReporterRows: {
      type: Number,
      default: 5,
    },
    defaultAnchorRows: {
      type: Number,
      default: 3,
    },
  },
  emit: [],

  setup(props, { emit, expose }) {
    const straightEditor = ref();
    const reporterEditor = ref();
    const bottomRoleEditor = ref();
    const crossTalkEditor = ref();

    const contextMenuPosition = ref({ x: 0, y: 0 });
    const isContextMenuVisible = ref(false);
    const contextMenuItems = ref([
      { id: 'article', name: '전체 기사 복사' },
      { id: 'caption', name: '전체 자막 복사' },
    ]);

    const { copy } = useClipboard();
    const copyCaptionArray = ref([]);

    const isEditorChanging = ref(false);

    const anchorTexts = useModelWrapper(props, emit, 'anchorTextArray');
    const reporterTexts = useModelWrapper(props, emit, 'reporterTextArray');

    const anchorCaptions = ref([[]]);
    const reporterCaptions = ref([[]]);

    const articleEditorRef = ref(null);

    const initCaptions = () => {
      const editorArray = Array.from(
        {
          length: Math.max(
            anchorTexts.value?.length,
            reporterTexts.value?.length,
          ),
        },
        (_, idx) => idx,
      );

      anchorCaptions.value = editorArray.map(() => []);
      reporterCaptions.value = editorArray.map(() => []);

      let fromLineNo = 0;
      let endLineNo = 0;

      editorArray.forEach(idx => {
        const anchorTextLineCount = getArticleLineCount(
          anchorTexts.value[idx] ?? '',
        ).length;

        fromLineNo = 0;
        endLineNo = fromLineNo + anchorTextLineCount - 1;

        const findAnchorCaptions = props.captionArray
          ?.filter(
            v =>
              fromLineNo <= v.lineNo &&
              v.lineNo <= endLineNo &&
              v.lineOrd === idx &&
              (props.typeId === articleTypeCode.Report.id ||
                props.typeId === articleTypeCode.CrossTalk.id),
          )
          .map(caption => {
            const _caption = cloneDeep(caption);
            return {
              ..._caption,
            };
          });

        if (!isEmpty(findAnchorCaptions)) {
          anchorCaptions.value[idx] = findAnchorCaptions;
        }

        // 리포터 또는 CT 형식일 경우만 anchorEditor 영역이 존재 하여 endLineNo 증가
        if (
          props.typeId === articleTypeCode.Report.id ||
          props.typeId === articleTypeCode.CrossTalk.id
        ) {
          endLineNo++;
        }

        const reporterTextLineCount = getArticleLineCount(
          reporterTexts.value[idx] ?? '',
        ).length;

        fromLineNo = endLineNo;
        endLineNo += reporterTextLineCount;

        const findReporterCaptions = props.captionArray
          ?.filter(
            v =>
              fromLineNo <= v.lineNo &&
              v.lineNo <= endLineNo &&
              v.lineOrd === idx,
          )
          .map(caption => {
            const _caption = cloneDeep(caption);
            return {
              ..._caption,
              lineNo: _caption.lineNo - fromLineNo,
            };
          });

        if (!isEmpty(findReporterCaptions)) {
          reporterCaptions.value[idx] = findReporterCaptions;
        }
      });
    };

    function getInitialAnchorText(anchorText) {
      return anchorText || repeat('\n', props.defaultAnchorRows - 1);
    }

    function getInitialReportText(reportText) {
      return reportText || repeat('\n', props.defaultReporterRows - 1);
    }

    /**
     * typeId 별 text 초기화
     */
    const textInitializationByTypeId = typeId => {
      switch (typeId) {
        case articleTypeCode.Straight.id: {
          anchorTexts.value = [''];
          reporterTexts.value = [getInitialReportText(reporterTexts.value[0])];
          break;
        }
        case articleTypeCode.Report.id: {
          anchorTexts.value = [getInitialAnchorText(anchorTexts.value[0])];
          reporterTexts.value = [getInitialReportText(reporterTexts.value[0])];
          break;
        }
        case articleTypeCode.BottomRole.id: {
          anchorTexts.value = [''];
          reporterTexts.value = [getInitialReportText(reporterTexts.value[0])];
          break;
        }
        case articleTypeCode.CrossTalk.id: {
          const rows = Math.max(
            2,
            anchorTexts.value.length,
            reporterTexts.value.length,
          );

          let tempAnchorTexts = [];
          let tempReportTexts = [];

          for (let row = 0; row < rows; row++) {
            tempAnchorTexts.push(
              getInitialAnchorText(anchorTexts.value[row] || ''),
            );
            tempReportTexts.push(
              getInitialReportText(reporterTexts.value[row] || ''),
            );
          }

          anchorTexts.value = tempAnchorTexts;
          reporterTexts.value = tempReportTexts;
          break;
        }
      }
    };

    /**
     * 커서 위치에 문자열 추가
     * @param insertText
     */
    const insertTextAtCursor = insertText => {
      switch (props.typeId) {
        case articleTypeCode.Straight.id: {
          straightEditor.value.insertTextAtCursor(insertText);
          break;
        }
        case articleTypeCode.Report.id: {
          reporterEditor.value.insertTextAtCursor(insertText);
          break;
        }
        case articleTypeCode.BottomRole.id: {
          bottomRoleEditor.value.insertTextAtCursor(insertText);
          break;
        }
        case articleTypeCode.CrossTalk.id: {
          crossTalkEditor.value.insertTextAtCursor(insertText);
          break;
        }
      }
    };

    /**
     * 기사 작성 가능한 editor를 추가 한다.
     * crossTalk Editor 일때만 가능
     */
    const addEditor = () => {
      if (props.typeId !== articleTypeCode.CrossTalk.id) {
        return;
      }

      const focusEditorIndex = crossTalkEditor.value.getLastFocusEditorIndex();

      anchorTexts.value.splice(focusEditorIndex + 1, 0, getInitialAnchorText());
      anchorCaptions.value.splice(focusEditorIndex + 1, 0, []);
      reporterTexts.value.splice(
        focusEditorIndex + 1,
        0,
        getInitialReportText(),
      );
      reporterCaptions.value.splice(focusEditorIndex + 1, 0, []);
    };

    /**
     * 기사 작성 가능한 editor를 삭제 한다.
     * crossTalk Editor 일때만 가능
     * editor 수가 2개 이하로는 삭제 불가
     */
    const removeEditor = () => {
      if (props.typeId !== articleTypeCode.CrossTalk.id) {
        return;
      }

      if (anchorTexts.value.length <= 2 || reporterTexts.value.length <= 2) {
        return;
      }

      const focusEditorIndex = crossTalkEditor.value.getLastFocusEditorIndex();

      anchorTexts.value.splice(focusEditorIndex, 1);
      anchorCaptions.value.splice(focusEditorIndex, 1);
      reporterTexts.value.splice(focusEditorIndex, 1);
      reporterCaptions.value.splice(focusEditorIndex, 1);
    };

    /**
     * 기사 전체 자막 복사
     */
    const onCopyToCaption = () => {
      const copyText = copyCaptionArray.value.map(item => item.text).join('\n');
      copy(copyText);
    };

    /**
     * 기사 전체 내용 복사
     */
    const onCopyToArticle = () => {
      const copyText = concatArrays(
        anchorTexts.value,
        reporterTexts.value,
        props.typeId,
      ).join('\n');

      copy(copyText);
    };

    /**
     * editor 템플릿 변경시 데이터 처리 작업
     */
    watch(
      () => props.typeId,
      (newValue, oldValue) => {
        if (newValue === oldValue) {
          return;
        }

        isEditorChanging.value = true;

        /**
         * 이전 앵커, 리포터 텍스트 병합 처리
         */
        const margeArticleText = join(
          compact(concat(map(anchorTexts.value), map(reporterTexts.value))),
          '\n',
        );

        /**
         * 이전 앵커, 리포터 자막 병합 처리
         */
        const margeArticleCaptions = flattenDeep(
          concat(anchorCaptions.value, reporterCaptions.value),
        )
          .filter(v => !isEmpty(v))
          .map((v, index) => {
            return {
              ...v,
              lineNo: index,
            };
          });

        anchorTexts.value = [''];

        nextTick(() => {
          textInitializationByTypeId(newValue);

          const editorCount = newValue === articleTypeCode.CrossTalk.id ? 2 : 1;

          anchorCaptions.value = [];
          let newReportTexts = [];
          let newReportCaptions = [];
          for (let i = 0; i < editorCount; i++) {
            anchorCaptions.value.push([]);
            newReportTexts.push(getInitialAnchorText());
            newReportCaptions.push([]);
          }

          newReportTexts[0] = margeArticleText;
          newReportCaptions[0] = margeArticleCaptions;

          reporterTexts.value = newReportTexts;
          reporterCaptions.value = newReportCaptions;

          isEditorChanging.value = false;
        });
      },
    );

    watch(
      () => [
        anchorCaptions.value,
        reporterCaptions.value,
        anchorTexts.value,
        reporterTexts.value,
      ],
      ([newAnchorCaptions, newReporterCaptions]) => {
        nextTick(() => {
          const editorArray = Array.from(
            {
              length: Math.max(
                anchorTexts.value?.length,
                reporterTexts.value?.length,
              ),
            },
            (_, idx) => idx,
          );

          let articleStartLineNumber = 0;
          // 앵커멘트 editor 존재 여부
          const hasAnchorEditor =
            props.typeId === articleTypeCode.Report.id ||
            props.typeId === articleTypeCode.CrossTalk.id;

          const editorCaptions = flattenDeep(
            editorArray.map(idx => {
              // 에디터 자막 시작 위치
              let _startLineNumber = 0;

              const reporterTextLineCount = getArticleLineCount(
                reporterTexts.value[idx] ?? '',
              ).length;
              const anchorTextLineCount = getArticleLineCount(
                anchorTexts.value[idx] ?? '',
              ).length;

              const totalLines = hasAnchorEditor
                ? reporterTextLineCount + anchorTextLineCount
                : reporterTextLineCount;

              const _anchorCaptions = newAnchorCaptions[idx]?.map(v => {
                const { lineNo } = v;
                return {
                  ...v,
                  lineNo: lineNo + _startLineNumber,
                  articleLineNo: lineNo + articleStartLineNumber,
                  lineOrd: idx,
                  totalLines,
                };
              });

              _startLineNumber +=
                // 스트레이트 형식은 리포터 에디터만 존재 하기 때문에 0을 더해준다.
                hasAnchorEditor
                  ? Math.max(
                      ...newAnchorCaptions[idx]?.map(v => v.lineNo + 1),
                      anchorTextLineCount,
                    )
                  : 0;

              articleStartLineNumber += _startLineNumber;

              const _reporterCaptions = newReporterCaptions[idx]?.map(v => {
                const { lineNo } = v;
                return {
                  ...v,
                  lineNo: lineNo + _startLineNumber,
                  articleLineNo: lineNo + articleStartLineNumber,
                  lineOrd: idx,
                  totalLines,
                };
              });

              articleStartLineNumber += Math.max(
                ...newReporterCaptions[idx]?.map(v => v.lineNo + 1),
                reporterTextLineCount,
              );

              return concat(_anchorCaptions, _reporterCaptions);
            }),
          );
          copyCaptionArray.value = editorCaptions;
          emit('update:captionArray', editorCaptions);
        });
      },
      {
        deep: true,
      },
    );

    expose({
      insertTextAtCursor,
      addEditor,
      removeEditor,
      onCopyToCaption,
      onCopyToArticle,
    });

    onMounted(() => {
      textInitializationByTypeId(props.typeId);
      initCaptions();

      if (articleEditorRef.value) {
        useScrollTop(articleEditorRef.value);
      }
    });

    return {
      straightEditor,
      reporterEditor,
      bottomRoleEditor,
      crossTalkEditor,
      isEditorChanging,
      articleTypeCode,
      anchorTexts,
      anchorCaptions,
      reporterTexts,
      reporterCaptions,
      articleEditorRef,
      contextMenuPosition,
      isContextMenuVisible,
      contextMenuItems,
    };
  },
});
</script>
<style scoped></style>
