<template>
  <div class="space-y-1" :class="[width]">
    <label
      v-if="label"
      class="block sm:text-xs text-xs font-medium text-gray-700"
      >{{ label }}
      <span v-if="required" class="text-red-600">*</span>
    </label>
    <Datepicker
      v-model="dateTime"
      auto-apply
      locale="ko"
      :format-locale="ko"
      :format="format"
      :model-type="modelType"
      :placeholder="placeholder"
      :disabled="disabled"
      :week-start="0"
      :enable-time-picker="enableTimePicker"
      :text-input="textInput"
      :clearable="clearable"
      :input-class-name="inputCls"
      :disabled-dates="disabledDate"
      @update:modelValue="handleDate"
    >
    </Datepicker>
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/runtime-core';
import { ko } from 'date-fns/locale';
import { defaultDateTime } from '@/utils/format.ts';

export default defineComponent({
  name: 'DateTimePickerEx',
  props: {
    modelValue: {
      type: [String, Date],
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Select Date',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    enableTimePicker: {
      type: Boolean,
      default: true,
    },
    textInput: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: 'w-44',
    },
    // modelvalue 값 형태 설정
    modelType: {
      type: String,
      default: 'yyyy-MM-dd HH:mm:ss',
    },
    // input에서 보여지는 형태 설정
    format: {
      type: String,
      default: 'yyyy-MM-dd HH:mm',
    },
    disabledDate: {
      type: Function,
      default: () => {
        return null;
      },
    },
  },
  emits: ['change', 'update:modelValue'],

  setup(props, { emit }) {
    const dateTime = computed({
      get() {
        if (props.modelValue) {
          return defaultDateTime(props.modelValue);
        }
        emit('update:modelValue', defaultDateTime());
        return defaultDateTime();
      },
      set() {},
    });

    let handleDate = date => {
      emit('change', date, props.name);
    };

    const inputCls = computed(() => {
      let cls =
        'rdp-custom-input focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary h-[2.125rem] rounded-md shadow-sm';

      if (props.disabled) {
        cls = cls + ' bg-gray-300';
      }

      return cls;
    });

    return {
      ko,
      dateTime,
      inputCls,
      handleDate,
    };
  },
});
</script>
