
import { SpinnerIcon } from './icon';

export default {
  name: 'GSpinner',
  components: {
    SpinnerIcon,
  },
  props: {
    fillWhite: {
      type: Boolean,
      default: false,
    },
  },
};
