import { Code, EnumEntry } from './base';

class cuesheetStateCode extends EnumEntry<Code> {
  NoneConfirmed: Code = {
    id: 'cue_st_001',
    name: '미확정',
    label: '미확정',
  };
  Confirmed: Code = {
    id: 'cue_st_002',
    name: '확정',
    label: '확정',
  };
  OnAir: Code = {
    id: 'cue_st_003',
    name: '방송중',
    label: '방송중',
  };
  OffAir: Code = {
    id: 'cue_st_004',
    name: '방송완료',
    label: '방송완료',
  };

  values: Array<Code> = [
    this.NoneConfirmed,
    this.Confirmed,
    this.OnAir,
    this.OffAir,
  ];
}

export default new cuesheetStateCode();
