<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor">
    <path
      d="m14.1 21.65-3.9-3.9 1.05-1.05 2.85 2.85 5.675-5.675 1.05 1.05ZM3.375 16l4.8-13H9.9l4.775 13H12.95l-1.225-3.55h-5.45L5.025 16ZM6.8 11.05h4.45l-2.2-6.2h-.075Z"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SpellcheckIcon',
  props: {},
  setup() {
    return {};
  },
});
</script>
