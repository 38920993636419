<template>
  <div class="flex flex-col space-y-2">
    <label
      v-if="label"
      class="block text-xs font-medium text-gray-700"
      :for="name"
      >{{ label }} <span v-if="required" class="text-red-600">*</span></label
    >
    <textarea
      ref="input"
      class="p-2 border border-gray-300 shadow-sm focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary block w-full rounded-md"
      :class="[
        errorMessage
          ? 'border-red-700 focus:border-red-700 focus:ring-red-700'
          : inputClass,
        !resize ? 'resize-none' : '',
        fontSize,
      ]"
      :value="inputValue"
      :readonly="readonly"
      :disabled="disabled"
      :placeholder="placeholder ? placeholder : label"
      :style="customStyle"
      :rows="rows"
      :maxlength="maxlength"
      @input="handleChange"
      @blur="handleBlur"
    />
    <p v-if="errorMessage" class="mt-2 text-xs text-red-600">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import { useField } from 'vee-validate';
import { computed, watch, onMounted, ref } from 'vue';

export default {
  name: 'GTextarea',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: null,
    },
    maxlength: {
      type: Number,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: Boolean,
      default: false,
    },
    customStyle: {
      type: String,
      default: '',
    },
    resize: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: String,
      default: 'text-xs sm:text-xs',
    },
  },
  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.modelValue,
    });

    const input = ref(null);
    watch(
      () => props.modelValue,
      currentValue => {
        let tmpCurrentValue = currentValue;
        if (currentValue === undefined) {
          tmpCurrentValue = '';
        }
        inputValue.value = tmpCurrentValue;
      },
    );

    watch(inputValue, currentValue => {
      // null인 상태가 들어오는 상황이 있는데 나중에 확인.
      // if (currentValue !== null) {
      //   return;
      // }
      emit('update:modelValue', currentValue);
    });

    const inputClass = computed(() => {
      const cls = [];
      if (props.readonly || props.disabled) {
        cls.push('focus:ring-0');
        cls.push('bg-gray-200');
      }
      return cls;
    });

    const setFocus = () => {
      input.value?.focus();
    };

    onMounted(() => {
      if (props.focus) {
        setFocus();
      }
    });

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
      inputClass,
      input,
    };
  },
};
</script>
