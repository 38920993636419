import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { appRoutes } from '@/router/app-routes';
import { RouterName } from '@/types/router';
import store from '@/store';
import Swal from 'sweetalert2';

const routes: Array<RouteRecordRaw> = appRoutes;

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const checkRequiredAuth = (to: any) => {
  const isAuthenticated = store.getters['auth/isAuthenticated'];

  if (!isAuthenticated) {
    return router.push({
      name: RouterName.Login,
      query: { back_url: to.fullPath },
    });
  }
};

/**
 * 권한 목록 갱신
 */
const fetchAuthority = () => {
  store.dispatch('auth/FETCH_APP_AUTHORITIES');
};

/**
 * 권한 목록 갱신
 */
const fetchRefreshTokenRemainingTime = () => {
  store.dispatch('auth/FETCH_REFRESH_TOKEN_REMAINING_TIME');
};

router.beforeEach(async (to: any, from) => {
  // 인증 확인
  if (to.matched.some((record: any) => record.meta.requiresAuth)) {
    fetchAuthority();
    fetchRefreshTokenRemainingTime();
    checkRequiredAuth(to);

    // 관리자 페이지 안의 컴포넌트들은 탭이 필요 없다.
    if (to.meta.layout === 'AdminLayout') {
      return;
    }

    // 탭 리스트에 없는 메뉴를 클릭했을 경우 탭 15개 제한
    if (store.state.tab.tabs.length > 14) {
      let isExistsTab = false;
      if (to.name === RouterName.CreateArticle) {
        isExistsTab = store.state.tab.tabs.some(tab => {
          return parseInt(to?.params.sequence) === tab.sequence;
        });
      } else {
        isExistsTab = store.state.tab.tabs.some(tab => tab.path === to.path);
      }

      if (!isExistsTab && from.name) {
        Swal.fire({
          icon: 'warning',
          title: '',
          html: '<div>열려져있는 탭을 닫고 다시 시도해주세요.</div>',
          confirmButtonText: '확인',
          confirmButtonColor: '#2F7097', // primary-default
        });

        return router.push({ name: from.name });
      }
    }

    /**
     * 탭 생성
     * 새기사작성, 내기사목록 라우터가 name만 다를뿐 같은 주소이기 때문에
     * 내기사목록을 클릭하면 app-routes.ts에 작성된 첫번째 값이 선택되어서 탭명이 맨 처음 값인 새 기사 작성이 표시되어서
     * 강제로 올바른 이름으로 표시해준다.
     */
    let name = to.name;
    if (to.name === RouterName.CreateMyArticle) {
      name = RouterName.MyArticle;
    }

    await store.dispatch('tab/ADD_TAB', {
      name,
      icon: to.meta?.menu?.icon,
      params: to.params,
      path: to.path,
      query: to.query,
      route: to,
    });

    // 탭 활성화
    store.dispatch('tab/ACTIVE_TAB', { name, params: to.params });
  }
});

export default router;
