<template>
  <div class="border rounded-md bg-white overflow-auto" :class="[height]">
    <GTable
      :list="cuesheetTemplateList"
      :draggable="true"
      :group="group"
      :multiselect="false"
      :first-default-row="false"
      :selected-row-index="selectedRowIndex"
      :is-loading="isLoading"
      :pagination="{
        isShow: false,
      }"
      :no-data-message="'데이터가 없습니다.'"
      @on-row-clicked="onRowClicked"
      @on-row-db-clicked="onRowDbClicked"
      @is-finished-drag="endDragging"
    >
      <template #header>
        <th class="p-3">{{ t('CuesheetTemplateList.순번') }}</th>
        <th class="p-2">{{ t('CuesheetTemplateList.제목') }}</th>
        <!-- <th class="p-2">{{ t('CuesheetTemplateList.기사') }}</th> -->
        <th class="p-2">{{ t('CuesheetTemplateList.V') }}</th>
        <th class="p-2">{{ t('CuesheetTemplateList.A') }}</th>
        <th class="p-2">{{ t('CuesheetTemplateList.담당') }}</th>
        <!-- <th class="p-2">{{ t('CuesheetTemplateList.시간') }}</th> -->
        <th class="p-2">{{ t('CuesheetTemplateList.길이') }}</th>
        <!-- <th class="p-2">{{ t('CuesheetTemplateList.참조사항') }}</th> -->
      </template>

      <template #list="{ row: row, index }">
        <CuesheetTemplateListItem
          :template="row"
          :index="index"
          :icons="icons"
          @on-edit-icon="onEditIcon"
          @on-delete-icon="onDeleteIcon"
        />
      </template>
    </GTable>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { GTable } from '@/components/ui';
import { useI18n } from 'vue-i18n';
import CuesheetTemplateListItem from './CuesheetTemplateListItem.vue';

export default defineComponent({
  name: 'CuesheetTemplateList',
  components: {
    GTable,
    CuesheetTemplateListItem,
  },
  props: {
    cuesheetTemplateList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    icons: {
      type: Array,
      default: () => {
        return [];
      },
    },
    height: {
      type: String,
      default: 'h-[calc(100vh-200px)]',
    },
    group: {
      type: Object,
      default: () => {
        return null;
      },
    },
    selectedRowIndex: {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    const onRowClicked = rowData => {
      emit('on-row-clicked', rowData);
    };

    const onRowDbClicked = rowData => {
      emit('on-row-db-clicked', rowData?.row);
    };

    // 드래그 끝나는 시점
    const endDragging = parmas => {
      emit('is-finished-drag', parmas);
    };

    const onEditIcon = (row, type) => {
      emit('on-edit-icon', row, type);
    };

    const onDeleteIcon = icon => {
      emit('on-delete-icon', icon);
    };

    return {
      t,
      onRowClicked,
      onRowDbClicked,
      endDragging,
      onEditIcon,
      onDeleteIcon,
    };
  },
});
</script>

<style scoped></style>
