import { useMarsLApi } from '@/apis/mars-l-api';
import { MUTATION_NAMES } from './mutation';

export const ACTION_NAMES = Object.freeze({
  FETCH_CATEGORIES: 'FETCH_CATEGORIES',
});

export const ACTIONS = {
  [ACTION_NAMES.FETCH_CATEGORIES]: async ({ commit }: any, _params: any) => {
    const params = {
      ..._params,
    };

    try {
      const marsLApi = useMarsLApi();

      const response = await marsLApi.category.basic.findAll(params);
      commit(MUTATION_NAMES.SET_CATEGORIES, response.data);
    } catch (e) {
      console.error(e);
    }
  },
};
