<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_206_5)">
      <rect width="48" height="48" rx="10" fill="white" />
      <rect
        x="1.5"
        y="1.5"
        width="45"
        height="45"
        rx="8.5"
        stroke="black"
        stroke-width="3"
      />
      <path
        d="M11.6926 18.4545V33H8.17702V18.4545H11.6926ZM26.2789 18.4545V33H23.296L17.5076 24.6051H17.4153V33H13.8997V18.4545H16.9252L22.6497 26.8352H22.7704V18.4545H26.2789ZM28.0137 21.3097V18.4545H40.3077V21.3097H35.8972V33H32.4313V21.3097H28.0137Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_206_5">
        <rect width="48" height="48" rx="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IntIcon',
  props: {},
  setup() {
    return {};
  },
});
</script>
