<!-- eslint-disable vue/no-v-for-template-key -->

<template>
  <div v-show="false">
    <div id="printContent" class="print-cuesheet-list">
      <table class="border-collapse" style="width: 100%">
        <thead style="page-break-before: always">
          <tr>
            <div class="header">
              <h1 class="header-title">
                {{ cuesheetInfo.brdcPgmNm
                }}{{
                  cuesheetInfo.brdcDt ? `(${props.cuesheetInfo.brdcDt})` : ''
                }}
              </h1>
              <div class="info">
                <div>
                  ANC: {{ cuesheetInfo.anc1Nm
                  }}{{ cuesheetInfo.anc2Nm ? `, ${cuesheetInfo.anc2Nm}` : '' }}
                </div>
                <div>
                  PD: {{ cuesheetInfo.pd1Nm
                  }}{{ cuesheetInfo.pd2Nm ? `, ${cuesheetInfo.pd2Nm}` : '' }}
                </div>
                <div>확정일시: {{ setCuesheetVersionDate }}</div>
                <div>판 수: {{ cuesheetInfo.cueVer }}</div>
              </div>

              <tr
                class="border border-collapse border-gray bg-gray-200 text-center align-middle"
              >
                <th class="border border-gray text-center order">No</th>
                <th class="border border-gray text-center form-icon">형식</th>
                <th class="border border-gray text-center time">시간</th>
                <th class="border border-gray text-center acc">합계</th>
                <th class="border border-gray text-center item-title">제목</th>
                <th class="border border-gray text-center icon">V</th>
                <th class="border border-gray text-center icon">A</th>
                <th class="border border-gray text-center inputer">기자</th>
                <th class="border border-gray text-center remark">비고</th>
              </tr>
            </div>
          </tr>
        </thead>

        <!--  프린트 바디 -->
        <tbody style="">
          <table class="border-collapse">
            <!-- 테이블 바디 영역 -->
            <tr
              v-for="(cuesheetItem, index) in cuesheetList"
              :key="`page-${index}`"
              style="height: 30px"
            >
              <!-- No -->
              <td class="border border-gray order">{{ index + 1 }}</td>
              <!-- 형식 -->
              <td class="border border-gray form-icon">
                <div
                  v-if="
                    cuesheetItem.artclTypCd === articleTypeCode.BottomRole.id
                  "
                  class="align-middle items-center justify-center text-center rounded-full inline-flex h-6 w-6"
                  style="background-color: #f2f7fe; color: #475569"
                >
                  <ComputerIcon size="h-6 w-6" />
                </div>
                <div
                  v-else
                  class="align-middle items-center justify-center text-center rounded-full inline-flex"
                  :class="setArticleTypeCls(cuesheetItem.artclTypCd)"
                  style="width: 24px; height: 24px"
                >
                  <span>
                    {{ `${setArticleType(cuesheetItem.artclTypCd)}` }}
                  </span>
                </div>
                <!-- <div
                  class="text-center"
                  style="padding: 0 2px 0 2px; width: 16px; height: 16px"
                >
                  <cuesheetFormIcon
                    class="form"
                    :type="cuesheetItem.cueItemTypCd"
                  />
                </div> -->
              </td>
              <!-- 시간 -->
              <td class="border border-gray time">
                {{
                  secondToMs(
                    cuesheetItem?.cueItemTime + cuesheetItem?.artclExtTime || 0,
                  )
                }}
              </td>
              <!-- 합계 -->
              <td class="border border-gray acc">
                {{ secondToMs(cuesheetItem?.acc || 0) }}
              </td>
              <!-- 제목 -->
              <td class="border border-gray item-title">
                <div class="item-title-truncate">
                  {{
                    `${
                      cuesheetItem.cueItemTitl ? cuesheetItem.cueItemTitl : ''
                    }`
                  }}
                </div>
                <!-- {{ clampTextByByte(cuesheetItem.cueItemTitl, 48) }} -->
              </td>
              <!-- 비디오 아이콘 -->
              <td class="border border-gray icon">
                <img
                  v-if="cuesheetItem?.videoSymbol"
                  :src="cuesheetItem?.videoSymbol?.symbolUrl"
                  class="icon-symbol"
                />
              </td>
              <!-- 오디오 아이콘 -->
              <td class="border border-gray icon">
                <img
                  v-if="cuesheetItem?.audioSymbol"
                  :src="cuesheetItem?.audioSymbol?.symbolUrl"
                  class="icon-symbol"
                />
              </td>
              <!-- 담당 -->
              <td class="border border-gray inputer">
                <div class="inputer-truncate">
                  {{
                    cuesheetItem?.isSameChannel
                      ? cuesheetItem?.rptrNm
                      : cuesheetItem?.cueItemDivCd ===
                        cuesheetItemCode.ARTICLE.id
                      ? `${cuesheetItem?.orgRptrNm}(${cuesheetItem?.orgChNm})`
                      : ''
                  }}
                </div>
                <!-- {{ clampTextByByte(cuesheetItem?.chrgrNm, 9) }} -->
              </td>

              <!-- 비고 -->
              <td class="border border-gray remark">
                <div class="remark-truncate">
                  {{ cuesheetItem?.rmk }}
                </div>
                <!-- {{ clampTextByByte(cuesheetItem?.rmk, 12) }} -->
              </td>
            </tr>
          </table>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, onMounted, computed } from 'vue';
import {
  secondToMs,
  defaultDateTimeHm /*defaultDateTime*/,
} from '@/utils/format';
import { useUserInfo } from '@/hooks/use-user-info';
import printJS from 'print-js';
// import { clampTextByByte } from '@/utils/print';
// import cuesheetFormIcon from '@/components/cueSheet/cuesheet-form-icon.vue';
import { articleTypeCode, cuesheetItemCode } from '@/codes';
import { ComputerIcon } from '@/components/ui/icon';

const props = defineProps({
  cuesheetInfo: {
    type: Object,
    default: () => {
      return {};
    },
  },
  cuesheetList: {
    type: Array,
    default: () => {
      return [];
    },
  },
});

const emit = defineEmits(['close-print']);

const { userId, userName } = useUserInfo();
// const printDate = defaultDateTime(new Date());

const printContent = () => {
  printJS({
    printable: 'printContent', // 프린트할 요소의 ID
    type: 'html', // 프린트할 내용의 타입
    css: ['/print/cuesheet-list.css', '/print/common.css'],
    documentTitle: `${userName}(${userId})`, // 인쇄될 문서의 제목
    scanStyles: false,
    // onPrintDialogClose: onClosePrint,
  });
  setTimeout(() => {
    window.addEventListener('mouseover', handler);
  }, 1000);
};

const handler = () => {
  // Make sure the event only happens once.
  window.removeEventListener('mouseover', handler);

  // Remove iframe from the DOM, by default 'printJS'
  const iframe = document.getElementById(`printJS`);

  emit('close-print');
  if (iframe) {
    iframe.remove();
  }
};

// const onClosePrint = () => {
//   alert('!!!');
//   console.log('asd');
// };

/**
 * 큐시트 확정일시
 */
const setCuesheetVersionDate = computed(() => {
  let date = '';
  if (props.cuesheetInfo.cueVerUpdtDtm) {
    date = defaultDateTimeHm(props.cuesheetInfo.cueVerUpdtDtm);
  }
  return date;
});

const setArticleType = articleType => {
  let text = '';
  switch (articleType) {
    case articleTypeCode.Straight.id:
      text = 'S';
      break;

    case articleTypeCode.Report.id:
      text = 'R';
      break;

    case articleTypeCode.CrossTalk.id:
      text = 'C';
      break;
  }
  return `${text}`;
};

const setArticleTypeCls = articleType => {
  let cls = '';
  switch (articleType) {
    case articleTypeCode.Straight.id:
      cls = 'straight';
      break;

    case articleTypeCode.Report.id:
      cls = 'report';
      break;

    case articleTypeCode.CrossTalk.id:
      cls = 'crosstalk';
      break;
  }
  return `${cls}`;
};

onMounted(() => {
  printContent();
});
</script>

<style scoped>
@media print {
  @page {
    /* size: 8cm 13cm; */
    /*custom size*/
    size: A4;
    /* margin: 0; */
  }
  table {
    width: 100%;
  }

  body {
    /* 배경색 설정 */
    -webkit-print-color-adjust: exact; /* chrome, safari 적용*/
    print-color-adjust: exact;

    margin: 0 2cm 4cm 2cm;
    line-height: 1.3;
    background: #fff !important;
    color: #000;
    font-size: 9pt;
    font-family: Helvetica, Arial, sans-serif;
  }

  h1 {
    font-size: 20pt;
  }

  h2,
  h3,
  h4 {
    font-size: 14pt;
    /* margin-top: 25px; */
  }

  .table {
    display: table;
    width: 100%;
    border-width: 1px;
    border: 1pt solid;
    border-collapse: collapse;
    text-align: center;
  }

  .table-header-group {
    display: table-header-group;
    /* 크롬에서 인쇄창 배경 그래픽의 기본값이 false이때문에 따로 설정해준다. */
    -webkit-print-color-adjust: exact;
    background-color: rgb(229 231 235);
    font-weight: 600;
  }

  .table-header-group .table-row .table-cell {
    text-align: center !important;
    vertical-align: middle !important;
  }

  .table-row-group {
    display: table-row-group;
  }

  .table-row {
    display: table-row;
    /* border-bottom: 1pt solid; */
  }

  .table-cell {
    /* border-bottom: 1pt solid; */
    /* border-right: 1pt solid; */
    border: 1pt solid;
    border-collapse: collapse;
    display: table-cell;
    padding: 4px;
    vertical-align: bottom;
    /* height: 15px; */
  }

  .footer {
    display: flex;
    justify-content: space-between;
    padding-top: 4px;
  }
  .border {
    border: 1px solid;
  }
  .border-collapse {
    border-collapse: collapse;
  }
  .border-gray {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity)) !important;
  }

  .font-semibold {
    font-weight: 600;
  }
  .bg-gray-200 {
    background-color: rgb(229 231 235);
  }
  .print-cuesheet-list {
  }

  .print-cuesheet-list h1 {
    /* font-size: 9pt; */
  }

  .header {
    color: black;
    width: 100%;
    justify-content: space-between;
    text-align: center;
    padding-top: 2rem;
    /* padding-bottom: 0.5rem; */
  }

  .item-header {
    /* border-bottom: 1pt solid; */
    /* border-right: 1pt solid; */
    border: 1pt solid;
    border-collapse: collapse;
    padding: 4px;
    /* vertical-align: bottom; */
    /* height: 15px; */
  }
  .item-row {
    border-collapse: collapse;
    display: flex;
    height: 100%;
  }
  .item-cell {
    border: 1pt solid;
    border-collapse: collapse;
  }
  .print-cuesheet-list .header-title {
    font-weight: 400;
  }

  .print-cuesheet-list .header .info {
    font-size: 11pt;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
  }
  .item-title {
    width: 300px;
    text-align: left;
  }
  .item-title-truncate {
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .order {
    /* border-left: 1pt solid; */
    width: 18px;
    text-align: center;
  }
  .order-truncate {
    width: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .form-icon {
    width: 26px;
    height: 18px;
    text-align: center;
  }
  .icon {
    width: 45px;
    height: 18px;
    text-align: center;
  }

  .time {
    width: 45px;
    text-align: center;
  }
  .time-truncate {
    width: 45px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .inputer {
    width: 60px;
    text-align: center;
  }
  .inputer-truncate {
    width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .remark {
    text-align: center;
    width: 80px;
  }
  .remark-truncate {
    font-size: 7pt;
    width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    /* white-space: nowrap; */
  }

  /* 형식 아이콘 css */
  i.cuesheet-form-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.75rem;
    height: 1.75rem;
    font-style: normal;
  }

  .cuesheet-form-001 {
    color: white;
    background-color: #b77d4d;
    border-radius: 50%;
  }

  .cuesheet-form-001::before {
    content: '完';
  }

  .cuesheet-form-002 {
    color: black;
    background-color: white;
  }

  .cuesheet-form-002::before {
    content: '단';
  }

  .cuesheet-form-003 {
    color: white;
    background-color: #bf49b7;
  }

  .cuesheet-form-003::before {
    content: '출';
  }

  .cuesheet-form-004 {
    color: white;
    background-color: #e6791f;
  }

  .cuesheet-form-004::before {
    content: '중';
  }

  .cuesheet-form-005 {
    color: white;
    background-color: #435fcc;
  }

  .cuesheet-form-005::before {
    font-size: 1.5rem;
    content: '☏';
  }

  .cuesheet-form-006 {
    color: black;
    background-color: white;
  }

  .cuesheet-form-006::before {
    content: '타';
  }

  .cuesheet-form-007 {
    color: white;
    background-color: #e3ca53;
  }

  .cuesheet-form-007::before {
    content: '위';
  }

  .cuesheet-form-008 {
    color: white;
    background-color: #ff5555;
  }

  .cuesheet-form-008::before {
    content: '헬';
  }

  .cuesheet-form-009 {
    color: white;
    background-color: #0b4800;
  }

  .cuesheet-form-009::before {
    font-weight: 900;
    content: 'L';
  }

  .cuesheet-form-010 {
    color: white;
    background-color: #7bc9ff;
  }

  .cuesheet-form-010::before {
    content: '마';
  }

  .cuesheet-form-empty {
    border: dashed 1px black;
  }
}
</style>
