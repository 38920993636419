import { Code, EnumEntry } from './base';

interface IArticleTypeCode extends Code {
  seoulId: '01' | '02' | '03' | '04';
}

class ArticleTypeCode extends EnumEntry<IArticleTypeCode> {
  Straight: IArticleTypeCode = {
    id: 'typ_001',
    seoulId: '01',
    name: '스트레이트',
    label: '스트레이트',
  };

  Report: IArticleTypeCode = {
    id: 'typ_002',
    seoulId: '02',
    name: '리포트',
    label: '리포트',
  };

  CrossTalk: IArticleTypeCode = {
    id: 'typ_003',
    seoulId: '03',
    name: 'C/T',
    label: 'C/T',
  };

  BottomRole: IArticleTypeCode = {
    id: 'typ_004',
    seoulId: '04',
    name: '하단롤',
    label: '하단롤',
  };

  values: Array<IArticleTypeCode> = [
    this.Straight,
    this.Report,
    this.BottomRole,
    this.CrossTalk,
  ];
}

export default new ArticleTypeCode();
