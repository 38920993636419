<template>
  <div class="w-full relative">
    <div
      class="w-full relative flex flex-col caption-wrap"
      :class="[
        readOnly ? 'p-0 caption-viewer' : 'caption-editor p-1 bg-cyan-100',
      ]"
      @focusin="onFocus"
    >
      <div class="w-full flex mb-0 p-0">
        <textarea
          ref="captionEditor"
          class="article-caption w-full resize-none overflow-y-hidden outline-none focus:border-rose-500 anywhere white-space"
          :class="[readOnly ? 'pl-2 pr-3.5' : 'border border-transparent']"
          type="text"
          rows="1"
          :value="text"
          :readonly="readOnly"
          @input="onInputEditorHandler($event)"
          @dblclick.prevent="onFocus"
          @blur="onBlur"
        />
        <div v-if="!readOnly" class="ml-0.5">
          <div class="group">
            <cog6-tooth-icon
              class="h-3 w-3 mt-[0.15rem] group-hover:invisible"
            />
            <div
              class="absolute flex p-[0.15rem] top-[3px] right-0 bg-white border invisible group-hover:visible"
            >
              <div class="flex bg-transparent">
                <arrow-up-circle-icon
                  class="h-8 w-8 rounded-full hover:bg-gray-200 cursor-pointer"
                  @click="onArrowUpCircleIconClickHandler"
                />
                <arrow-down-circle-icon
                  class="h-8 w-8 rounded-full hover:bg-gray-200 cursor-pointer"
                  @click="onArrowDownCircleIconClickHandler"
                />
                <x-circle-icon
                  class="h-8 w-8 rounded-full hover:bg-gray-200 cursor-pointer"
                  @click="onCircleIconClickHandler"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, nextTick } from 'vue';
import XCircleIcon from '@heroicons/vue/24/outline/XCircleIcon';
import ArrowUpCircleIcon from '@heroicons/vue/24/outline/ArrowUpCircleIcon';
import ArrowDownCircleIcon from '@heroicons/vue/24/outline/ArrowDownCircleIcon';
import Cog6ToothIcon from '@heroicons/vue/24/outline/Cog6ToothIcon';

export default {
  name: 'Caption',
  components: {
    XCircleIcon,
    ArrowUpCircleIcon,
    ArrowDownCircleIcon,
    Cog6ToothIcon,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'close', 'blur', 'arrowUpClick', 'arrowDownClick'],
  setup(props, { emit, expose }) {
    // refs
    const captionEditor = ref();

    /**
     * caption editor focus
     */
    const onFocus = () => {
      // isFocus.value = true;
      nextTick(() => {
        captionEditor.value.focus();
      });
    };

    /**
     * caption editor blur
     */
    const onBlur = event => {
      // isFocus.value = false;
      emit('blur', event.target.value);
    };

    /**
     * caption editor 높이 자동 조절
     */
    const resize = () => {
      captionEditor.value.style.height = 'auto';
      captionEditor.value.style.height = `${captionEditor.value.scrollHeight}px`;
    };

    const onInputEditorHandler = event => {
      emit('input', event.target.value);
      resize();
    };

    const onCircleIconClickHandler = () => {
      emit('close');
    };

    const onArrowUpCircleIconClickHandler = () => {
      emit('arrowUpClick');
    };

    const onArrowDownCircleIconClickHandler = () => {
      emit('arrowDownClick');
    };

    const onSelectionStart = () => {
      return captionEditor.value.selectionStart;
    };

    onMounted(() => {
      resize();
    });

    expose({
      onFocus,
      onSelectionStart,
    });

    return {
      captionEditor,
      onFocus,
      onBlur,
      resize,
      onInputEditorHandler,
      onCircleIconClickHandler,
      onArrowUpCircleIconClickHandler,
      onArrowDownCircleIconClickHandler,
    };
  },
};
</script>

<style scoped>
.caption-wrap {
  font-family: D2Coding, D2 coding;
  font-size: 15px;
}

.caption-editor:after {
  content: '';
  position: absolute;
  right: -8px;
  top: 2px;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-left-color: rgb(207 250 254);
  border-right: 0;
}

.caption-viewer textarea {
  background-color: #f2f2f2;
  border: 1px solid rgb(209, 213, 219);
}

.caption-viewer textarea:focus-within,
.caption-viewer textarea:focus {
  border: 1px solid rgb(209, 213, 219);
  background-color: white;
}

.caption-viewer:after {
  content: '';
  position: absolute;
  right: -8px;
  top: 2px;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-left-color: #ecececff;
  border-right: 0;
}
</style>
