<template>
  <teleport to="body" :disable="disable">
    <div
      id="movable-modal"
      class="rounded-md border shadow-2xl fixed bg-gray-100 z-50"
      :class="[width, height, maxHeight ? `max-h-${maxHeight}` : '']"
      :style="[
        bottom ? `bottom: ${bottom}` : '',
        left ? `left: ${left}` : '',
        right ? `right: ${right}` : '',
        top ? `top: ${top}` : '',
      ]"
      v-shortkey="{ winEsc: ['esc'] }"
      @shortkey="shotKeyEvent"
    >
      <div
        class="bg-primary border-b border-gray-200 rounded-t-lg flex items-center justify-between select-none"
      >
        <div id="handle" class="flex-1 py-2 px-6 cursor-move">
          <slot name="title" />
        </div>

        <button
          id="close-button"
          class="m-1.5 mr-6 text-white rounded-md focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          @click="onClose"
        >
          <XMarkIcon class="w-6 h-6 cursor-pointer" aria-hidden="true" />
        </button>
      </div>
      <slot name="content" />
    </div>
  </teleport>
</template>

<script setup>
import { onMounted /*, defineEmits*/, defineProps } from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';

defineProps({
  width: {
    type: String,
    default: 'w-[750px]',
  },
  height: {
    type: String,
    default: 'h-[700px]',
  },
  maxHeight: {
    type: String,
    default: '',
  },
  left: {
    type: String,
  },
  right: {
    type: String,
  },
  top: {
    type: String,
  },
  bottom: {
    type: String,
  },
  disable: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['close']);

// 모달 movable
function dragElement(element) {
  var pos1 = 0,
    pos2 = 0,
    pos3 = 0,
    pos4 = 0;

  document.getElementById('handle').onmousedown = dragMouseDown;

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    // 모달의 x, y축을 가져온다. 윈도우 높이/너비에서 모달의 높이/너비를 뺀 값을 최대 높이/너비로 지정한다.
    const modalElem = document.getElementById('movable-modal');
    let modalRect = modalElem.getBoundingClientRect();

    const maxHeight = window.innerHeight - modalElem.offsetHeight;
    const maxWidth = window.innerWidth - modalElem.offsetWidth;

    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    // 상단으로 모달을 올렸을 경우
    if (modalRect.y < 0) {
      element.style.top = '0px';
    }
    // 하단으로 모달을 내렸을 경우
    else if (modalRect.y > maxHeight) {
      element.style.top = maxHeight + 'px';
    } else if (modalRect.y === maxHeight) {
      element.style.top = maxHeight - 3 + 'px';
    } else {
      element.style.top = element.offsetTop - pos2 + 'px';
    }

    // 왼쪽으로 모달을 옮겼을 경우
    if (modalRect.x < 0) {
      element.style.left = '0px';
    }
    // 오른쪽으로 모달을 옮겼을 경우
    else if (modalRect.x > maxWidth) {
      element.style.left = maxWidth + 'px';
    } else if (modalRect.x === maxWidth) {
      element.style.left = maxWidth - 1 + 'px';
    } else {
      element.style.left = element.offsetLeft - pos1 + 'px';
    }
  }

  function closeDragElement() {
    // stop moving when mouse button is released:
    document.onmouseup = null;
    document.onmousemove = null;
  }
}

const shotKeyEvent = event => {
  if (event.srcKey === 'winEsc') {
    const closeButton = document.getElementById('close-button');
    closeButton.focus();
    onClose();
  }
};

const onClose = () => {
  emit('close');
};

onMounted(() => {
  dragElement(document.getElementById('movable-modal'));
});
</script>
