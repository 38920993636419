<template>
  <GSingleSelect
    v-model="model"
    :name="name"
    :item-label="itemLabel ? itemLabelName : ''"
    :options="getWorkerSelectionOptions"
    track-by="id"
    label="label"
    :required="required"
    :allow-empty="false"
    :searchable="true"
    :preselect-first="allOption"
    :placeholder="placeholder"
    :is-loading="isLoading"
    :width="width"
    @select="onSelectValue"
  />
</template>

<script>
import { onMounted, computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { concat } from 'lodash';
import { useModelWrapper } from '@/hooks/useModelWrapper';
import { GSingleSelect } from '@/components/ui';

export default {
  name: 'WorkerSelect',
  components: {
    GSingleSelect,
  },
  props: {
    modelValue: {
      required: true,
    },
    companyCode: {
      type: [String, Number],
      required: true,
    },
    allOption: {
      type: Boolean,
      default: false,
    },
    itemLabel: {
      type: Boolean,
      default: false,
    },
    itemLabelName: {
      type: String,
      default: '기자',
    },
    width: {
      type: String,
      default: 'w-36',
    },
    placeholder: {
      type: String,
      default: '기자',
    },
    name: {
      type: String,
      default: 'reporter',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['select', 'update:modelValue'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const model = useModelWrapper(props, emit, 'modelValue');
    const isLoading = ref(false);

    /**
     * worker select options 정보 반환
     * @returns {*[]}
     */
    const getWorkerSelectionOptions = computed(() => {
      let options = [];
      if (props.allOption) {
        options.unshift({
          id: '',
          name: '',
          label: t('WorkerSelect.기자_전체'),
        });
      }

      return concat(options, store.getters['worker/findAllWorkers']);
    });

    /**
     * 지역사별 User 목록 조회
     * @param chDivId - 지역사 코드(ID)
     * @returns {Promise<void>}
     */
    const fetchWorkers = async chDivId => {
      try {
        isLoading.value = true;
        await store.dispatch('worker/FIND_ALL_WORKERS', {
          chDivId,
          useYn: 'Y',
          delYn: 'N',
          size: 10000,
        });
      } catch (e) {
        console.error(e);
      } finally {
        isLoading.value = false;
      }
    };

    const onSelectValue = value => {
      emit('select', value);
    };

    onMounted(async () => {
      await fetchWorkers(props.companyCode);
    });

    watch(
      () => props.companyCode,
      async newValue => {
        await fetchWorkers(newValue);
      },
    );

    return {
      t,
      model,
      getWorkerSelectionOptions,
      isLoading,
      onSelectValue,
    };
  },
};
</script>

<style scoped></style>
