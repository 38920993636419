<template>
  <GSingleSelect
    v-model="model"
    name="program"
    :item-label="itemLabel ? itemLabelName : ''"
    :options="getProgramSelectOptions"
    track-by="id"
    label="label"
    :allow-empty="allowEmpty"
    :searchable="false"
    :preselect-first="allOption"
    :placeholder="t('ProgramSelect.프로그램')"
    :is-loading="isLoading"
    :width="width"
    @select="onSelectValue"
  >
    <template #option="{ option }">
      <div class="flex items-center space-x-2">
        <div class="truncate">{{ option.label }}</div>
      </div>
    </template>
    <template #singleLabel="{ option }">
      <div class="flex items-center space-x-2">
        <div class="truncate">{{ option.label }}</div>
      </div>
    </template>
  </GSingleSelect>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { concat } from 'lodash';
import { useModelWrapper } from '@/hooks/useModelWrapper';
import { GSingleSelect } from '@/components/ui';
import { onMounted, computed, ref, watch } from 'vue';
import { MODULE_NAME as PROGRAM_MODULE_NAME } from '@/store/modules/program';
import { GETTER_NAMES as PROGRAM_GETTER_NAMES } from '@/store/modules/program/getter';
import { ACTION_NAMES as PROGRAM_ACTION_NAMES } from '@/store/modules/program/action';

export default {
  name: 'ProgramSelect',
  components: {
    GSingleSelect,
  },
  props: {
    companyCode: {
      type: [String, Number],
      required: true,
    },
    allOption: {
      type: Boolean,
      default: false,
    },
    itemLabel: {
      type: Boolean,
      default: false,
    },
    itemLabelName: {
      type: String,
      default: '프로그램',
    },
    width: {
      type: String,
      default: 'w-52',
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['select', 'update:modelValue'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const model = useModelWrapper(props, emit, 'modelValue');
    const isLoading = ref(false);

    // component computed
    /**
     * program select options 정보 반환
     * @returns {*[]}
     */
    const getProgramSelectOptions = computed(() => {
      let options = [];
      if (props.allOption) {
        options.push({
          id: '',
          label: t('ProgramSelect.프로그램_전체'),
        });
      }

      return concat(
        options,
        store.getters[
          `${PROGRAM_MODULE_NAME}/${PROGRAM_GETTER_NAMES.GET_PROGRAM_CODES}`
        ],
      );
    });

    // component method

    /**
     * 지역사별 프로그램 목록 조회
     * @param chDivId - 지역사 코드(ID)
     * @returns {Promise<void>}
     */
    const fetchPrograms = async chDivId => {
      try {
        isLoading.value = true;
        await store.dispatch(
          `${PROGRAM_MODULE_NAME}/${PROGRAM_ACTION_NAMES.FETCH_PROGRAMS}`,
          {
            chDivId,
          },
        );
      } catch (e) {
        console.error(e);
      } finally {
        isLoading.value = false;
      }
    };

    const onSelectValue = value => {
      emit('select', value);
    };

    onMounted(async () => {
      await fetchPrograms(props.companyCode);
    });

    watch(
      () => props.companyCode,
      async newValue => {
        await fetchPrograms(newValue);
      },
    );

    return {
      t,
      model,
      getProgramSelectOptions,
      isLoading,
      onSelectValue,
    };
  },
};
</script>

<style scoped></style>
