import { useUserInfo } from '@/hooks/use-user-info';

/**
 * 나의 mngId랑 비교 대상이랑 비교
 * @param {string | number} target - 비교 대상
 */
const isMySomething = (target: string | number) => {
  const { userMngId } = useUserInfo();

  if (userMngId == target) {
    return true;
  }
  return false;
};

/**
 * 나의 지역사랑 비교 대상이랑 비교
 * @param {string | number} target - 비교 대상
 */
const isMyCompany = (target: string | number) => {
  const { companyId } = useUserInfo();

  if (companyId == target) {
    return true;
  }
  return false;
};

/**
 * 원본과 수정본 비교
 */

export { isMySomething, isMyCompany };
