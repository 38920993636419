<template>
  <div class="space-y-1" :class="[width]">
    <label
      v-if="label"
      class="block sm:text-xs text-xs font-medium text-gray-700"
      >{{ label }}</label
    >
    <Datepicker
      v-model="dates"
      auto-apply
      range
      locale="ko"
      :format-locale="ko"
      :format="format"
      :model-type="modelType"
      :placeholder="placeholder"
      :disabled="disabled"
      :week-start="0"
      :enable-time-picker="enableTimePicker"
      :text-input="textInput"
      :clearable="clearable"
      :input-class-name="inputCls"
      :multi-calendars="multiCalendars"
      @update:modelValue="handleDate"
    >
    </Datepicker>
  </div>
</template>

<script>
import { computed, defineComponent } from '@vue/runtime-core';
import { ko } from 'date-fns/locale';
import { defaultDate } from '@/utils/format.ts';

export default defineComponent({
  name: 'RangeDatePickerEx',
  props: {
    modelValue: {
      type: Array,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Select Date',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    enableTimePicker: {
      type: Boolean,
      default: false,
    },
    textInput: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: 'w-52',
    },
    // modelvalue 값 형태 설정
    modelType: {
      type: String,
      default: 'yyyy-MM-dd',
    },
    // input에서 보여지는 형태 설정
    format: {
      type: String,
      default: 'yyyy-MM-dd',
    },
    multiCalendars: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['change', 'update:modelValue'],

  setup(props, { emit }) {
    const dates = computed({
      get() {
        if (props.modelValue) {
          return props.modelValue;
        }
        return [defaultDate(), defaultDate()];
      },
      set() {},
    });

    let handleDate = date => {
      emit('change', date);
      emit('update:modelValue', date);
    };

    const inputCls = computed(() => {
      let cls =
        'rdp-custom-input focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary h-[2.125rem] rounded-md shadow-sm';

      if (props.disabled) {
        cls = cls + ' bg-gray-300';
      }

      return cls;
    });

    return {
      ko,
      dates,
      inputCls,
      handleDate,
    };
  },
});
</script>
