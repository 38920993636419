<template>
  <i class="cuesheet-form-icon" :class="[type]" />
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'cuesheet-form-icon',
  props: {
    type: {
      type: String,
      default: 'cuesheet-form-empty',
    },
  },
});
</script>
<style>
i.cuesheet-form-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.75rem;
  height: 1.75rem;
  font-style: normal;
}

.cuesheet-form-001 {
  color: white;
  background-color: #b77d4d;
  border-radius: 50%;
}
.cuesheet-form-001::before {
  content: '完';
}

.cuesheet-form-002 {
  color: black;
  background-color: white;
}
.cuesheet-form-002::before {
  content: '단';
}

.cuesheet-form-003 {
  color: white;
  background-color: #bf49b7;
}
.cuesheet-form-003::before {
  content: '출';
}

.cuesheet-form-004 {
  color: white;
  background-color: #e6791f;
}
.cuesheet-form-004::before {
  content: '중';
}

.cuesheet-form-005 {
  color: white;
  background-color: #435fcc;
}
.cuesheet-form-005::before {
  font-size: 1.5rem;
  content: '☏';
}

.cuesheet-form-006 {
  color: black;
  background-color: white;
}
.cuesheet-form-006::before {
  content: '타';
}

.cuesheet-form-007 {
  color: white;
  background-color: #e3ca53;
}
.cuesheet-form-007::before {
  content: '위';
}

.cuesheet-form-008 {
  color: white;
  background-color: #ff5555;
}
.cuesheet-form-008::before {
  content: '헬';
}

.cuesheet-form-009 {
  color: white;
  background-color: #0b4800;
}
.cuesheet-form-009::before {
  font-weight: 900;
  content: 'L';
}

.cuesheet-form-010 {
  color: white;
  background-color: #7bc9ff;
}
.cuesheet-form-010::before {
  content: '마';
}

.cuesheet-form-empty {
  border: dashed 1px black;
}
</style>
