import { AxiosInstance } from 'axios';

const PREFIX = '/monitoring';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 외부 뉴스 목록 조회
     * @param {} params
     */
    findAll: async (params: any) => {
      return $axios
        .get(`${PREFIX}/news`, { params })
        .then(response => response.data);
    },

    /**
     * 외부 뉴스 상세 조회
     * @param {Number} monitoringId - 외부 뉴스 ID
     */
    findOne: async (monitoringId: number) => {
      return $axios
        .get(`${PREFIX}/news/${monitoringId}`)
        .then(response => response.data);
    },

    /**
     * 외부 뉴스 생성
     * @param {Object} params - 생성에 필요한 값
     */
    create: async (params: any) => {
      return $axios
        .post(`${PREFIX}/news`, params)
        .then(response => response.data);
    },

    /**
     * 외부 뉴스 수정
     * @param {Number} monitoringId - 외부 뉴스 ID
     * @param {Object} params - 수정에 필요한 값
     */
    update: async (monitoringId: number, params: any) => {
      return $axios
        .put(`${PREFIX}/news/${monitoringId}`, params)
        .then(response => response.data);
    },

    /**
     * 외부 뉴스 삭제
     * @param {Number} monitoringId - 외부 뉴스 ID
     */
    delete: async (monitoringId: number) => {
      return $axios
        .delete(`${PREFIX}/news/${monitoringId}`)
        .then(response => response.data);
    },
  };
};
