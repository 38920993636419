<template>
  <div
    ref="prompTemplate"
    class="h-screen fixed inset-0 z-50 w-full flex text-white bg-black select-none"
    :class="
      (rotateView && !isLandscape) || (!rotateView && isLandscape)
        ? 'prompTemplate'
        : ''
    "
  >
    <!-- 메뉴 -->
    <div
      v-if="isToggleMenuBtn"
      ref="slider"
      class="nav w-[230px] h-full px-2 bg-gray-800 z-10"
    >
      <div class="menu flex flex-col gap-5">
        <div class="text-right py-2">
          <button @click="isToggleMenuBtn = !isToggleMenuBtn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 20"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div class="menu-item flex justify-between px-1 items-center">
          <div class="menu-title">좌우 변경</div>
          <div class="menu-btn">
            <!-- <label class="switch">
              <input type="checkbox" name="text-left-right-rotate" />
              <span id="LRrotate" class="slider round LRrotate-btn"></span>
            </label> -->
            <Switch
              v-model="LRrotate"
              :class="LRrotate ? 'bg-blue-600' : 'bg-gray-300'"
              class="relative inline-flex h-6 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
              @click="onToggleLRrotate"
            >
              <span class="sr-only">Use</span>
              <span
                aria-hidden="true"
                :class="LRrotate ? 'translate-x-6' : 'translate-x-0'"
                class="pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
              />
            </Switch>
          </div>
        </div>
        <div class="menu-item flex justify-between px-1 items-center">
          <div class="menu-title">상하 변경</div>
          <div class="menu-btn">
            <!-- <label class="switch">
              <input type="checkbox" name="text-top-bottom-rotate" />
              <span id="UDrotate" class="slider round UDrotate-btn"></span>
            </label> -->
            <Switch
              v-model="UDrotate"
              :class="UDrotate ? 'bg-blue-600' : 'bg-gray-300'"
              class="relative inline-flex h-6 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            >
              <span class="sr-only">Use</span>
              <span
                aria-hidden="true"
                :class="UDrotate ? 'translate-x-6' : 'translate-x-0'"
                class="pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
              />
            </Switch>
          </div>
        </div>
        <div class="menu-item flex justify-between px-1 items-center">
          <div class="menu-title truncate" :title="'글자크기'">글자크기</div>
          <div class="menu-btn">
            <GSingleSelect
              v-model="fontSize"
              name="fontSize"
              track-by="value"
              label="name"
              :width="`w-20`"
              :options="fontSizeList"
              :searchable="false"
              :allow-empty="false"
              :show-pointer="false"
              :select-label="''"
              :placeholder="'speed'"
              @update:modelValue="onSetFontSize"
            />
          </div>
        </div>
        <div class="menu-item flex justify-between px-1 items-center">
          <div class="menu-title">글자속도</div>
          <div class="menu-btn">
            <GSingleSelect
              v-model="textSpeed"
              name="textSpeed"
              track-by="value"
              label="name"
              :width="`w-20`"
              :options="textSpeedList"
              :searchable="false"
              :allow-empty="false"
              :preselect-first="false"
              :show-pointer="false"
              :select-label="''"
              :placeholder="'speed'"
              @update:modelValue="onSetScrollSpeed"
            />
          </div>
        </div>
        <div class="menu-item flex justify-between px-1">
          <div class="menu-title">오토 스크롤</div>
          <div class="menu-btn">
            <Switch
              v-model="isAutoScroll"
              :class="isAutoScroll ? 'bg-blue-600' : 'bg-gray-300'"
              class="relative inline-flex h-6 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            >
              <span class="sr-only">Use</span>
              <span
                aria-hidden="true"
                :class="isAutoScroll ? 'translate-x-6' : 'translate-x-0'"
                class="pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
              />
            </Switch>
          </div>
        </div>
        <!-- <div class="hidden menu-item flex justify-between px-1">
          <div class="menu-title">화면회전</div>
          <div class="menu-btn">
            <Switch
              v-model="rotateView"
              :class="rotateView ? 'bg-blue-600' : 'bg-gray-300'"
              class="relative inline-flex h-6 w-12 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
              @click="onToggleRotateView"
            >
              <span class="sr-only">Use</span>
              <span
                aria-hidden="true"
                :class="rotateView ? 'translate-x-6' : 'translate-x-0'"
                class="pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
              />
            </Switch>
          </div>
        </div> -->
        <div
          v-if="
            mode === 'article' &&
            (promptList[promptIdx]?.artclTypCd === articleTypeCode.Report.id ||
              promptList[promptIdx]?.artclTypCd ===
                articleTypeCode.CrossTalk.id)
          "
          class="menu-item flex justify-between space-x-2"
        >
          <div class="menu-item-twin flex space-x-2">
            <div class="menu-title">Anchor</div>
            <input
              id="chk-anchor"
              v-model="checkedAnchorMent"
              type="checkbox"
              name="anchor-checkbox"
            />
            <label for="chk-anchor">
              <span></span>
            </label>
          </div>
          <div class="menu-item-twin flex space-x-2 text-right">
            <div class="menu-title">Report</div>
            <input
              id="chk-report"
              v-model="checkedReportMent"
              type="checkbox"
              name="report-checkbox"
            />
            <label for="chk-report">
              <span></span>
            </label>
          </div>
        </div>
        <div v-if="mode === 'cuesheet'" class="menu-item flex justify-between">
          <div class="menu-item-twin">
            <button
              class="move-article bg-gray-600 active:bg-gray-700 rounded-md p-1 truncate"
              @click="moveArticle('back')"
            >
              이전 기사
            </button>
          </div>
          <div class="menu-item-twin text-right">
            <button
              class="move-article bg-gray-600 active:bg-gray-700 rounded-md p-1 truncate"
              @click="moveArticle('next')"
            >
              다음 기사
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- 이전/다음 기사로 이동 클릭 영역 -->
    <div
      id="promp-container"
      class="promp-container flex flex-col relative w-full"
    >
      <div
        class="absolute left-0 h-full w-44"
        @click="moveArticle('back', $event)"
      />
      <div
        class="absolute right-0 h-full w-44"
        @click="moveArticle('next', $event)"
      />

      <!-- 헤더 영역 -->
      <div
        class="sticky flex justify-between p-2 items-center border-b-2 space-x-2 border-gray-700"
      >
        <button @click="isToggleMenuBtn = !isToggleMenuBtn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 lg:w-[3rem] lg:h-[3rem]"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </button>

        <!-- 타이틀 -->
        <div
          id="prompt_article_title"
          class="article_title_box flex space-x-2 truncate lg:text-5xl"
        >
          <div
            v-if="mode === 'cuesheet'"
            class="article_num font-D2CodingBold text-yellow-300"
          >
            {{ promptList[promptIdx]?.index }}
          </div>
          <div class="article_title font-D2CodingBold">
            {{ promptList[promptIdx]?.cueItemTitl }}
          </div>
        </div>
        <!-- jys : prompt close btn -->
        <div class="close-wrap items-center">
          <button @click="onClosePrompt">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 20"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 lg:w-[3rem] lg:h-[3rem] truncate"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>

      <!-- 기사 내용 영역 -->
      <div
        class="content overflow-y-auto overflow-x-hidden"
        @click="onClickedPrompter"
      >
        <div class="fixed-box">
          <!--
					<div class="middle-arrow">
						<i class="fas fa-play"></i>
					</div>
					<div class="overlay">
						<div class="top"></div>
						<div class="bottom"></div>
					</div>
					 -->
          <div class="function-wrap">
            <div class="box before-box">
              <!-- 2020-11-04 csh remove skip -->
              <!-- <i class="fas fa-chevron-left"></i> -->
            </div>
            <div class="box play-box">
              <i class="fas fa-play"></i>
            </div>
            <div class="box after-box">
              <!-- 2020-11-04 csh remove skip -->
              <!-- <i class="fas fa-chevron-right"></i> -->
            </div>
          </div>
          <div class="line-box">
            <div
              class="middle-line h-[2px] bg-[#00ff00] absolute top-1/2 w-full"
            ></div>
          </div>
        </div>
        <!-- 2023-03-13: 갤럭시s10 기준으로 리드선 수정 pt-[42vh] -> pt-[9vh] -->
        <div
          class="content-box pt-[9vh] pb-[50rem] px-11"
          :class="[
            LRrotate && LRrotate !== UDrotate ? 'LRrotate' : '',
            UDrotate && LRrotate !== UDrotate ? 'UDrotate' : '',
            LRrotate && UDrotate ? 'UDLRrotate' : '',
          ]"
        >
          <div id="top_content" class="pb-10" :style="setFontSize">
            <div
              v-for="(count, index) in promptList[promptIdx]?.editorCount"
              :key="`prompter-[${count}]`"
            >
              <br />
              <br />
              <br />
              <!-- 앵커멘트 내용 영역 -->
              <pre
                v-if="
                  promptList[promptIdx]?.cueItemDivCd ===
                    cuesheetItemCode.ARTICLE.id &&
                  checkedAnchorMent &&
                  promptList[promptIdx]?.anchorTextArray[index]
                "
                class="whitespace-pre-wrap break-normal"
                style="font-family: Helvetica, Arial, sans-serif"
                >{{
                  promptList[promptIdx]?.anchorTextArray[index].replace(
                    /^\s+/,
                    '',
                  )
                }}</pre
              >
              <div
                v-if="
                  promptList[promptIdx]?.anchorTextArray[index].replace(
                    /^\s+/,
                    '',
                  ) && checkedAnchorMent
                "
                class="boundary my-20"
              >
                &nbsp;
              </div>

              <!-- 리포트멘트 내용 영역 -->
              <pre
                v-if="
                  promptList[promptIdx]?.cueItemDivCd ===
                    cuesheetItemCode.ARTICLE.id &&
                  checkedReportMent &&
                  promptList[promptIdx]?.reporterTextArray[index]
                "
                class="whitespace-pre-wrap break-normal"
                :class="
                  promptList[promptIdx]?.artclTypCd ===
                    articleTypeCode.Report.id ||
                  promptList[promptIdx]?.artclTypCd ===
                    articleTypeCode.CrossTalk.id
                    ? 'text-[#61e2f3]'
                    : ''
                "
                style="font-family: Helvetica, Arial, sans-serif"
                >{{
                  promptList[promptIdx]?.reporterTextArray[index].replace(
                    /^\s+/,
                    '',
                  )
                }}</pre
              >

              <!-- 큐시트 템플릿 or 큐시트 아이템 -->
              <pre
                v-if="
                  promptList[promptIdx]?.cueItemDivCd !==
                    cuesheetItemCode.ARTICLE.id &&
                  checkedReportMent &&
                  promptList[promptIdx]?.cueItemCtt
                "
                class="whitespace-pre-wrap break-normal"
                style="font-family: Arial, Helvetica, sans-serif"
                >{{
                  promptList[promptIdx]?.reporterTextArray[index].replace(
                    /^\s+/,
                    '',
                  )
                }}</pre
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  onMounted,
  onBeforeMount,
  watchEffect,
  watch,
} from 'vue';
// import { useRouter } from 'vue-router';
// import $ from 'jquery';
import { Switch } from '@headlessui/vue';
import { GSingleSelect } from '@/components/ui';
import { articleTypeCode, cuesheetItemCode } from '@/codes';

export default defineComponent({
  name: 'CueSheetPromter',
  components: {
    Switch,
    GSingleSelect,
  },
  props: {
    cuesheetList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    mode: {
      type: String,
      default: 'cuesheet',
    },
    isShowPrompt: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close-prompter'],
  setup(props, { emit }) {
    // const router = useRouter();
    const isToggleMenuBtn = ref(false);
    const promptList = ref([]);
    const promptIdx = ref(0);

    const editorCount = ref(2); // 초기에 보여줄 에디터 수 (기본 2개)

    const LRrotate = ref(false);
    const UDrotate = ref(false);
    const isAutoScroll = ref(true);
    const rotateView = ref(false);
    const fontSize = ref();
    const setFontSize = ref();
    const fontSizeList = ref([]);
    const textSpeed = ref();
    const textSpeedList = ref([
      { name: '1', value: 60 },
      { name: '2', value: 65 },
      { name: '3', value: 70 },
      { name: '4', value: 65 },
      { name: '5', value: 80 },
      { name: '6', value: 85 },
      { name: '7', value: 90 },
      { name: '8', value: 95 },
      { name: '9', value: 100 },
    ]);

    const prompTemplate = ref();

    const isPlayPrompt = ref(false);

    const checkedAnchorMent = ref(true);
    const checkedReportMent = ref(true);

    const content = ref();

    //가로 모드 체크
    const isLandscape = ref(false);
    //프롬프터 가로모드 css
    const setPromtLandscapeStyle = ref('');

    const autoScroll = ref();

    const onClosePrompt = () => {
      console.log('onClosePrompt');
      document.removeEventListener('keyup', propmtKeyboardHandler);
      document.removeEventListener('keydown', preventKeyboardHandler);
      emit('close-prompter');
      // router.go(-1);
    };

    // 프롬프터 터치
    const onClickedPrompter = () => {
      if (isAutoScroll.value) {
        onClickedAutoScroll();
      } else {
        scrollDown(640);
      }
    };

    /**
     * 스크롤 다운 이벤트
     * 기본값: 160
     */
    const scrollUp = ({ size = 160 } = {}) => {
      if (!UDrotate.value) {
        if (content.value.scrollTop !== 0) {
          content.value.scrollTop = content.value.scrollTop - size;
        }
      } else {
        if (
          content.value.offsetHeight !==
          content.value.scrollHeight - content.value.scrollTop
        ) {
          content.value.scrollTop = content.value.scrollTop + size;
        }
      }
    };

    /**
     * 스크롤 다운 이벤트
     * 기본값: 160
     */
    const scrollDown = (size = 160) => {
      console.log('size', size);
      if (!UDrotate.value) {
        if (
          content.value.offsetHeight !==
          content.value.scrollHeight - content.value.scrollTop
        ) {
          content.value.scrollTop = content.value.scrollTop + size;
        }
      } else {
        if (content.value.scrollTop !== 0) {
          content.value.scrollTop = content.value.scrollTop - size;
        }
      }
    };

    const onClickedAutoScroll = () => {
      isPlayPrompt.value = !isPlayPrompt.value;
      // isPlayPrompt === true 면 재생
      if (isPlayPrompt.value) {
        playAutoScroll();
      } else {
        // clearTimeout(scrollDelay);
        clearInterval(autoScroll.value);
      }
    };

    //자동 스크롤 or 일시 정지
    const playAutoScroll = () => {
      //프롬프트 영역
      const content = document.querySelector('.content');
      //프롬프트 스크롤 속도
      let pageSpeed = 100 - textSpeed.value.value;

      //기본 자동 스크롤 && 좌우반전일때
      if (!UDrotate.value) {
        autoScroll.value = setInterval(() => {
          // console.log('scroll on');
          // console.log('content.scrollTop', content.scrollTop);
          // console.log('scrollHeight', content.scrollHeight);
          // console.log('offsetHeight', content.offsetHeight);
          content.scrollTop = content.scrollTop + 1;
          //스크롤이 제일 하단으로 가면 stop
          if (
            content.offsetHeight ===
            content.scrollHeight - content.scrollTop
          ) {
            isPlayPrompt.value = false;
            clearInterval(autoScroll.value);
          }
        }, pageSpeed);
      }
      //상하 반전
      else if (UDrotate.value) {
        autoScroll.value = setInterval(() => {
          content.scrollTop = content.scrollTop - 1;
          //스크롤이 제일 하단으로 가면 stop
          if (content.scrollTop === 0) {
            isPlayPrompt.value = false;
            clearInterval(autoScroll.value);
          }
        }, pageSpeed);
      }
    };

    //폰트 사이즈
    const onSetFontSize = () => {
      setFontSize.value = fontSize.value.value;
    };

    //스크롤 속도
    const onSetScrollSpeed = () => {
      if (isPlayPrompt.value) {
        /**
         * 변경된 속도를 적용하려면 setInterval을 clear했다가 재시작해야 적용됨
         */
        clearInterval(autoScroll.value);

        playAutoScroll();
      }
    };

    // 좌우 반전
    const onToggleLRrotate = () => {
      //재생 정지
      isPlayPrompt.value = false;

      clearInterval(autoScroll.value);
    };

    // 상하 반전
    const onToggleUDrotate = () => {
      //재생 정지
      isPlayPrompt.value = false;

      //프롬프트 영역
      const content = document.querySelector('.content');

      if (UDrotate.value) {
        /**
         * 상하 반전시 스크롤 초기화
         */
        content.scrollTop = content.scrollHeight;

        clearInterval(autoScroll.value);
        // playAutoScroll();
      } else {
        content.scrollTop = 0;
      }
    };

    // 화면 회전
    const onToggleRotateView = () => {
      // setScreenSize();
    };

    const moveArticle = (type, event) => {
      // 화면에서 왼쪽/오른쪽 영역을 클릭해서 이전/다음 기사를 보여줄때 스크롤 기능 작동하지 않도록 함
      if (event) {
        event.stopPropagation();
      }

      const content = document.querySelector('.content');
      //이전 기사
      if (type === 'back') {
        if (promptIdx.value > 0) {
          isPlayPrompt.value = false;
          promptIdx.value = promptIdx.value - 1;

          //스크롤 초기화
          if (UDrotate.value) {
            content.scrollTop = content.scrollHeight;
          } else {
            content.scrollTop = 0;
          }
          //스크롤 정지
          clearInterval(autoScroll.value);
        }
      }
      //다음 기사
      else {
        if (promptIdx.value < promptList.value.length - 1) {
          isPlayPrompt.value = false;
          promptIdx.value = promptIdx.value + 1;

          //스크롤 초기화
          if (UDrotate.value) {
            content.scrollTop = content.scrollHeight;
          } else {
            content.scrollTop = 0;
          }
          //스크롤 정지
          clearInterval(autoScroll.value);
        }
      }
    };

    const setScreenSize = () => {
      // let vh = window.innerHeight * 0.01;
      // document.documentElement.style.setProperty('--vh', `${vh}px`);
      // let vw = window.innerWidth * 0.01;
      // document.documentElement.style.setProperty('--vw', `${vw}px`);
    };

    const propmtKeyboardHandler = $event => {
      let e = $event;

      // const content = document.querySelector('.content');
      if (props.isShowPrompt) {
        // jys : 리모컨 연동 개발
        switch (e.keyCode) {
          case 37: //37 LEFT : 이전 기사
            moveArticle('back');
            break;
          case 38: //38 up : 이전 문단? 스크롤 up
            e.preventDefault();
            scrollUp();
            break;
          case 39: //39 right : 다음 기사
            // e.preventDefault();
            moveArticle('next');
            break;
          case 40: //40 down : 다음 문단? 스크롤 down
            e.preventDefault();
            scrollDown();
            break;
          case 13: // 13 ok button : 재생 | 일시정지
            isPlayPrompt.value = !isPlayPrompt.value;

            if (isPlayPrompt.value) {
              playAutoScroll();
            } else {
              // clearTimeout(scrollDelay);
              clearInterval(autoScroll.value);
            }
            break;
          case 27: // 27 back : 프롬프트 닫기
            onClosePrompt();
            break;
          default:
            // $('.content-box').focus();
            e.preventDefault();
            break;
        }
      }
    };

    const preventKeyboardHandler = $event => {
      console.log($event);
      /**
       * 프롬프터 화면일 경우 기존 키보드 이벤트 막기
       * F11(전체 화면)과 F12(개발자도구)키는 막지 않음
       *  */
      if (
        props.isShowPrompt &&
        $event.keyCode !== 122 &&
        $event.keyCode !== 123
      ) {
        $event.preventDefault();
      }
    };

    const init = () => {
      promptList.value = props.cuesheetList.map((item, index) => {
        return {
          index: index + 1,
          editorCount: Math.max(
            1,
            item.anchorTextArray.length,
            item.reporterTextArray.length,
          ),
          ...item,
        };
      });
    };

    watch(
      () => UDrotate.value,
      () => {
        onToggleUDrotate();
      },
    );

    watch(
      () => isAutoScroll.value,
      newValue => {
        /**
         * 오토 스크롤 토글 버튼 클릭시 off이면 오토 스크롤 멈춤
         */
        if (!newValue) {
          isPlayPrompt.value = false;
          clearInterval(autoScroll.value);
        }
      },
    );

    watchEffect(() => {
      window.addEventListener('resize', setScreenSize);
      if (window.matchMedia('(orientation: portrait)').matches) {
        //Portait 모드일 때 실행할 스크립트
        // 폭과 높이가 같으면 Portait 모드 실행
        isLandscape.value = false;
        rotateView.value = false;
      } else {
        // Landscape 모드일 때 실행할 스크립트
        isLandscape.value = true;
        rotateView.value = true;
      }
    });

    onBeforeMount(() => {
      //글자크기
      fontSizeList.value = [];
      for (let i = 5; i <= 100; i = i + 5) {
        fontSizeList.value.push({ name: i, value: `font-size: ${i}px` });
      }
    });

    onMounted(() => {
      /**
       * 모바일 상단 url바가 사라질떄 resize이벤트가 적용되지 않아서
       * 터치이벤트로 대체
       */
      // window.addEventListener('touchend', setScreenSize);

      /**
       * 모바일 화면회전 감지
       */
      window.addEventListener('orientationchange', () => {
        setTimeout(() => {
          if (window.matchMedia('(orientation: portrait)').matches) {
            //Portait 모드일 때 실행할 스크립트
            // 폭과 높이가 같으면 Portait 모드 실행
            isLandscape.value = false;
            rotateView.value = false;
            // setScreenSize();
          } else {
            // Landscape 모드일 때 실행할 스크립트
            isLandscape.value = true;
            rotateView.value = true;
            // setScreenSize();
          }
        }, 50);
      });

      // 프롬프터 데이터 가공
      init();

      // promptList.value = props.cuesheetList.map((item, index) => {
      //   return {
      //     ...item,
      //     index: index + 1,
      //   };
      // });

      //글자 크기 기본설정 45
      // 2022.10.27 default 45 => 80
      fontSize.value = fontSizeList.value.filter(item => item.name === 80)[0];
      setFontSize.value = fontSize.value.value;

      //속도 기본설정 5
      // 2022.10.27 default 5 => 8 / 2022.11.17 default 8 => 6
      // textSpeed.value = textSpeedList.value[7];
      textSpeed.value = textSpeedList.value.filter(
        item => item.name === '6',
      )[0];

      //
      content.value = document.querySelector('.content');

      //jys: 리모컨 연동
      // var i = false; // 재생 여부 확인 : true = 재생중	 | fasle = 정지상태
      /**
       * 리모컨 ok버튼은 클릭이벤트로 감지됨
       */
      document.addEventListener('keyup', propmtKeyboardHandler);

      /**
       * 기본 키보드 이벤트 막기
       */
      document.addEventListener('keydown', preventKeyboardHandler);
    });

    return {
      isToggleMenuBtn,
      onClosePrompt,
      promptList,
      promptIdx,
      moveArticle,
      LRrotate,
      UDrotate,
      textSpeed,
      textSpeedList,
      fontSize,
      fontSizeList,
      onClickedAutoScroll,
      onToggleLRrotate,
      onToggleUDrotate,
      onSetFontSize,
      setFontSize,
      onSetScrollSpeed,
      rotateView,
      prompTemplate,
      checkedAnchorMent,
      checkedReportMent,
      onToggleRotateView,
      isLandscape,
      setPromtLandscapeStyle,
      articleTypeCode,
      editorCount,
      cuesheetItemCode,
      isAutoScroll,
      onClickedPrompter,
    };
  },
});
</script>

<style scoped>
.LRrotate {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
}

.UDrotate {
  -webkit-transform: rotateX(180deg);
  -moz-transform: rotateX(180deg);
  -o-transform: rotateX(180deg);
  -ms-transform: rotateX(180deg);
}

.UDLRrotate {
  -webkit-transform: rotateX(180deg) rotateY(180deg);
  -moz-transform: rotateX(180deg) rotateY(180deg);
  -o-transform: rotateX(180deg) rotateY(180deg);
  -ms-transform: rotateX(180deg) rotateY(180deg);
}
.article_title {
  /* width: calc(100% - 37px); */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.prompTemplate {
  /* transform: rotate(-90deg);
  transform-origin: top left;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100vh;
  height: 100vw; */
  transform: rotate(90deg);
  transform-origin: top left;
  position: absolute;
  top: 0;
  left: 100%;
  width: calc(var(--vh, 1vh) * 100);
  height: 100vw;
}
/* .middle-line {
  width: 100%;
  height: 2px;
  background-color: #00ff00;
  position: absolute;
  top: 50%;
} */
.menu-item .menu-item-twin {
  width: 50%;
  /* float: left; */
}

.menu-item .menu-item-twin .menu-title {
  display: inline-block;
}
.menu-item-twin input[type='checkbox'] + label {
  /* font-size: 1.2em;
  display: inline-block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  line-height: 3em; */
}

.menu-item-twin input[type='checkbox'] + label span {
  content: ' ';
  display: inline-block;
  width: 1.5rem;
  /* 체크박스의 너비를 지정 */
  height: 1.5rem;
  /* 체크박스의 높이를 지정 */
  line-height: 21px;
  /* 세로정렬을 위해 높이값과 일치 */
  /* margin: -44px 8px 0 -28px; */
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
}

.menu-item-twin input[type='checkbox'] + label:active:before,
.menu-item-twin input[type='checkbox']:checked + label:active:before {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.menu-item-twin input[type='checkbox']:checked + label span:before {
  content: '\2714';
  /* 체크표시 유니코드 사용 */
  color: #fff;
  background: #ccc;
  border-color: #ccc;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
    inset 15px 10px -12px rgba(255, 255, 255, 0.1);
}
input[type='checkbox'] {
  @apply hidden;
}

.prompt-screen {
  /* width: calc(var(--vh, 1vh) * 100);
    height: calc(var(--vw, 1vw) * 100); */
  height: calc(var(--vh, 1vh) * 100);
  width: calc(var(--vw, 1vw) * 100);
}

/* 스크롤 hide */
.content {
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
}
.content::-webkit-scrollbar {
  display: none; /* 크롬, 사파리, 오페라, 엣지 */
}
</style>
