import store from '@/store';
import { useUserInfo } from '@/hooks/use-user-info';
import { MODULE_NAME as PROGRAM_MODULE_NAME } from '@/store/modules/program';
import { GETTER_NAMES as PROGRAM_GETTER_NAMES } from '@/store/modules/program/getter';
import { ACTION_NAMES as PROGRAM_ACTION_NAMES } from '@/store/modules/program/action';
import { useMarsLApi } from '@/apis/mars-l-api';

const fetchWorkers = async () => {
  try {
    const { companyId } = useUserInfo();
    await store.dispatch('worker/FIND_ALL_WORKERS', {
      chDivId: companyId,
      useYn: 'Y',
      delYn: 'N',
      size: 10000,
    });
  } catch (error) {
    console.log('error', error);
  }
};

const fetchPrograms = async () => {
  const { companyId } = useUserInfo();

  try {
    await store.dispatch(
      `${PROGRAM_MODULE_NAME}/${PROGRAM_ACTION_NAMES.FETCH_PROGRAMS}`,
      {
        companyId,
      },
    );
  } catch (error) {
    console.log('error', error);
  }
};

/**
 * 유저 목록 조회
 * @returns {Array} 유저 목록
 */
const getUsers = async () => {
  let workers = store.getters['worker/findAllWorkers'];

  if (!workers.length) {
    await fetchWorkers();
    workers = store.getters['worker/findAllWorkers'];
  }

  return workers;
};

/**
 * 사용자 정보 중 필요한 값 return
 * @param {Array} workers 사용자 리스트
 * @param {number} userMngId 사용자 고유ID
 * @param {string} type 리턴할 항목 (name, id)
 * @returns {string} 요청한 항목의 값
 */
const getWorkerInfo = async (userMngId: number, type: string) => {
  const workers = await getUsers();

  const user: any = workers.find((worker: any) => worker.id === userMngId);

  if (type === 'name') {
    return user ? user.userNm : '';
  } else if (type === 'id') {
    return user ? user.userId : '';
  }
};

/**
 * 프로그램 이름 return
 * @param {Array} programs 프로그램 리스트
 * @param {number} programId 프로그램ID
 * @returns {string} 프로그램 이름
 */
const returnProgramName = (programs: [], programId: number) => {
  const program: any = programs.find(
    (program: any) => program.id === programId,
  );

  return program ? program.name : '';
};

/**
 * 프로그램 이름 가져오기
 * @param {number} programId 프로그램 ID
 * @returns {string, ''} 프로그램 이름
 */
const getProgramName = async (programId: number) => {
  let programs =
    store.getters[
      `${PROGRAM_MODULE_NAME}/${PROGRAM_GETTER_NAMES.GET_PROGRAM_CODES}`
    ];

  if (!programs.length) {
    await fetchPrograms();
    programs =
      store.getters[
        `${PROGRAM_MODULE_NAME}/${PROGRAM_GETTER_NAMES.GET_PROGRAM_CODES}`
      ];
  }
  return returnProgramName(programs, programId);
};

// === 지역사 이름 가져오기 START ===
const returnCompanyName = (companies: [], companyId: number) => {
  const company: any = companies.find(
    (company: any) => company.chDivId === companyId,
  );

  return company ? company.chDivCd : '';
};

/**
 * 지역사 이름 가져오기
 * @returns {string, ''} 지역사 이름
 */
const getCompanyName = async (companyId: number) => {
  const marsLApi = useMarsLApi();
  const response = await marsLApi.company.basic.findAll();

  return returnCompanyName(response.data, companyId);
};
// === 지역사 이름 가져오기 END ===

export { getWorkerInfo, getProgramName, getCompanyName };
