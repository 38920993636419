<template>
  <div class="space-y-2">
    <!-- Filter 영역 -->
    <div class="flex items-center flex-wrap gap-1 p-1">
      <RangeDatePickerEx v-model="dates" @change="onChangeDate" />
      <!-- 지역사 -->
      <CompanyMultiChips
        v-model="companies"
        @child-async-done="getArticles"
        @hide-context-menu="getArticles"
      />
      <!-- 출고 여부 -->
      <!-- <ApproveSelect
        v-model="approve"
        all-option
        width="w-36"
        @select="getArticles"
      /> -->
      <!-- 검색어 -->
      <GInput
        v-model="areaSearchInput"
        name="areaSearchInput"
        :placeholder="t('RefAreaArticle.검색어')"
        width="w-64"
        @keyup.enter="getArticles"
      />
      <GButton icon="magnifying-glass" @click="getArticles" />
    </div>

    <!-- 테이블 영역 -->
    <AreaArticleList
      :is-loading="isLoading"
      :articles="articles"
      :pagination="paginationInfo"
      :selected-row-index="selectedRowIndex"
      height="h-[calc(100vh-367px)]"
      @on-row-clicked="onSelectedRow"
      @on-row-db-clicked="onRowDbClicked"
      @on-changed-page="onChangePage"
    />
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { GButton, GInput } from '@/components/ui';
import { CompanyMultiChips /*, ApproveSelect*/ } from '@/components/select';
import { AreaArticleList } from '@/components/coverDesk';
import RangeDatePickerEx from '@/components/common/RangeDatePickerEx';
import { defaultDate } from '@/utils/format';
import { makeCompanyCode } from '@/utils/company.ts';
import { useMarsLApi } from '@/apis/mars-l-api';
import { PageConst } from '@/enums';
import { articleApproveCode } from '@/codes';

export default defineComponent({
  name: 'RefAreaArticle',
  components: {
    GButton,
    GInput,
    AreaArticleList,
    RangeDatePickerEx,
    CompanyMultiChips,
    // ApproveSelect,
  },
  emits: ['on-row-clicked', 'on-row-db-clicked'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const marsLApi = useMarsLApi();

    const isLoading = ref(false);
    const dates = ref([
      defaultDate(new Date(new Date().setDate(new Date().getDate() - 6))),
      defaultDate(new Date(new Date().setDate(new Date().getDate() + 1))),
    ]);
    const approve = ref(null);
    const companies = ref([]);
    const articles = ref([]);
    const areaSearchInput = ref(null);
    const selectedArticle = ref(null);
    const paginationInfo = ref(null);
    const page = ref(1);
    const selectedRowIndex = ref(0);

    // 기사 가져오기
    const getArticles = async () => {
      isLoading.value = true;

      try {
        const params = {
          startDate: dates.value[0],
          endDate: dates.value[1],
          apprvTypCds: articleApproveCode.approveAll.id, // 출고여부
          chDivIds: makeCompanyCode(companies.value),
          searchValue: areaSearchInput.value, // 검색키워드
          page: page.value - 1,
          size: PageConst.size,
        };

        const response = await marsLApi.coverDesk.basic.findAllElastic(params);

        articles.value = [];
        articles.value = response.content;
        paginationInfo.value = {
          isShow: true,
          page: page.value,
          totalPage: response.totalPages,
          totalCount: response.totalElements,
        };
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    // 행 선택
    const onSelectedRow = rowData => {
      selectedRowIndex.value = rowData?.index;
      selectedArticle.value = rowData?.row;
      emit('on-row-clicked', rowData?.row);
    };

    // 행 더블 클릭
    const onRowDbClicked = rowData => {
      selectedRowIndex.value = rowData?.index;
      selectedArticle.value = rowData?.row;
      emit('on-row-db-clicked', rowData?.row);
    };

    // 날짜 변경
    const onChangeDate = date => {
      dates.value = date;
      getArticles();
    };

    // 페이지 변경
    const onChangePage = changePage => {
      page.value = changePage;
      getArticles();
    };

    // 새로고침
    const onRefresh = () => {
      getArticles();
    };

    onMounted(async () => {
      await store.dispatch('company/FIND_ALL_COMPANYS');
      companies.value = store.getters['company/findAllCompanys'];

      getArticles();
    });

    return {
      t,
      isLoading,
      articles,
      dates,
      selectedArticle,
      selectedRowIndex,
      approve,
      companies,
      paginationInfo,
      onRefresh,
      onSelectedRow,
      onRowDbClicked,
      onChangePage,
      onChangeDate,
      areaSearchInput,
      getArticles,
    };
  },
});
</script>
