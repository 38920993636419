<template>
  <div>
    <div class="space-y-1" :class="[width]">
      <span v-if="itemLabel" class="text-xs inline-block">{{ itemLabel }}</span>
      <VueMultiselect
        v-model="inputValue"
        :multiple="true"
        track-by="chDivId"
        label="chDivCd"
        :options="optionsList"
        :placeholder="disabled || readonly ? '' : placeholder"
        :loading="isLoading"
        :disabled="disabled || readonly"
        :allow-empty="allowEmpty"
        :select-label="selectLabel"
        :selected-label="allowEmpty ? selectedLabel : ''"
        :deselect-label="allowEmpty ? selectedLabel : deselectLabel"
        :select-group-label="
          allowEmpty ? i18n.global.t('GMultiSelect.선택') : ''
        "
        :deselect-group-label="allowEmpty ? selectedLabel : deselectLabel"
        :searchable="true"
        :close-on-select="false"
        group-values="companies"
        group-label="selectAll"
        :group-select="true"
        :style="[errorCls]"
        @remove="onRemove"
      >
        <template #selection="{ values, isOpen }">
          <div class="truncate" :title="setValues(values)">
            <span class="text-xs" v-if="values.length" v-show="!isOpen">
              {{ setValues(values) }}
            </span>
          </div>
        </template>
        <template #noOptions>{{
          i18n.global.t('GMultiSelect.데이터가_존재하지_않습니다')
        }}</template>
        <template #noResult>{{
          i18n.global.t('GMultiSelect.데이터가_존재하지_않습니다')
        }}</template>
      </VueMultiselect>
      <p v-if="errorMessage || errors" class="mt-2 text-xs text-error">
        {{ errorMessage ? errorMessage : errors ? errors : '' }}
      </p>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import VueMultiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';
import { useField } from 'vee-validate';
import i18n from '@/i18n';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useModelWrapper } from '@/hooks/useModelWrapper';

export default {
  name: 'CompanyMultiSelect',
  components: {
    VueMultiselect,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {
        return null;
      },
    },
    name: {
      type: String,
      default: '',
    },
    itemLabel: {
      type: String,
      default: '',
    },
    allOption: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: 'w-72',
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    selectLabel: {
      type: String,
      default: i18n.global.t('GMultiSelect.선택'),
    },
    selectedLabel: {
      type: String,
      default: i18n.global.t('GMultiSelect.취소'),
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: String,
      default: '',
    },
  },
  emits: ['select', 'update:modelValue'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const isLoading = ref(false);
    const model = useModelWrapper(props, emit, 'modelValue');
    const optionsList = ref([]);
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.modelValue,
    });

    const setValues = values => {
      let texts = values.map(item => {
        return item.chDivCd;
      });

      return texts.join(', ');
    };

    const onRemove = async delValues => {
      // 전체 삭제를 누르면 배열로 넘어옴
      if (Array.isArray(delValues)) {
        inputValue.value = [];
        emit('update:modelValue', inputValue.value);
      }
    };

    const errorCls = computed(() => {
      if (errorMessage.value || props.error) {
        return {
          '--custom-border': '1px solid red',
        };
      }
      return {
        '--custom-border': '1px solid #d1d5db',
      };
    });

    const fetchCompanies = async () => {
      try {
        isLoading.value = true;
        await store.dispatch('company/FIND_ALL_COMPANYS');
      } catch (error) {
        console.error('error', error);
      } finally {
        isLoading.value = false;
      }
    };

    // 셀렉트 박스를 하나씩 만들때마다 options에 값을 넣기 위해 mounted에서 작업
    onMounted(async () => {
      await fetchCompanies();

      if (props.allOption) {
        optionsList.value = [
          {
            selectAll: '전체 선택',
            companies: store.getters['company/findAllCompanys'],
          },
        ];
      } else {
        optionsList.value = store.getters['company/findAllCompanys'];
      }
    });

    return {
      t,
      model,
      optionsList,
      isLoading,
      i18n,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
      inputValue,
      errorCls,
      setValues,
      onRemove,
    };
  },
};
</script>
