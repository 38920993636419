<template>
  <div class="space-y-1" :class="[width]">
    <label
      v-if="label"
      class="block sm:text-xs text-xs font-medium text-gray-700"
      >{{ label }}</label
    >
    <Datepicker
      v-model="time"
      auto-apply
      time-picker
      locale="ko"
      :format-locale="ko"
      :format="format"
      :model-type="modelType"
      :placeholder="placeholder"
      :disabled="disabled"
      :text-input="textInput"
      :clearable="clearable"
      :input-class-name="inputCls"
      :enable-seconds="enableSeconds"
      :seconds-grid-increment="secondsGridIncrement"
      @update:modelValue="handleTime"
    >
      <template #input-icon>
        <ClockIcon class="h-5 w-5 ml-2" />
      </template>
    </Datepicker>
  </div>
</template>

<script>
import { defineComponent, computed, ref, watch } from '@vue/runtime-core';
import { ko } from 'date-fns/locale';
import { ClockIcon } from '@heroicons/vue/24/outline';

export default defineComponent({
  name: 'TimePickerEx',
  components: {
    ClockIcon,
  },
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Select Date',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    enableTimePicker: {
      type: Boolean,
      default: true,
    },
    textInput: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: 'min-w-full',
    },
    // modelvalue 값 형태 설정
    modelType: {
      type: String,
      default: 'HH:mm:ss',
    },
    // input에서 보여지는 형태 설정
    format: {
      type: String,
      default: 'HH:mm:ss',
    },
    disabledDate: {
      type: Function,
      default: () => {
        return null;
      },
    },
    enableSeconds: {
      type: Boolean,
      default: true,
    },
    secondsGridIncrement: {
      type: Number,
      default: 1,
    },
  },
  emits: ['change', 'update:modelValue'],

  setup(props, { emit }) {
    const time = ref({ hours: 0, minutes: 0, seconds: 0 });

    watch(
      () => props.modelValue,
      newTime => {
        if (typeof newTime === 'string') {
          const strs = props.modelValue.split(':');
          time.value = { hours: strs[0], minutes: strs[1], seconds: strs[2] };
        } else {
          time.value = newTime;
        }
      },
    );

    // 시간 포멧팅
    const customStr = num => {
      if (num < 10) {
        const str = num + '';

        return str.padStart(2, '0');
      }

      return num;
    };

    // 시간 변경
    let handleTime = timeObj => {
      const hour = customStr(timeObj.hours);
      const min = customStr(timeObj.minutes);
      const sec = customStr(timeObj.seconds);

      const time = `${hour}:${min}:${sec}`;
      emit('change', time, props.name);
    };

    const inputCls = computed(() => {
      let cls =
        'rdp-custom-input focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary h-[2.125rem] rounded-md shadow-sm';

      if (props.disabled) {
        cls = cls + ' bg-gray-300';
      }

      return cls;
    });

    return {
      ko,
      time,
      inputCls,
      handleTime,
    };
  },
});
</script>
