<template>
  <GTable
    id="cueSheetSearchDetailList"
    :list="cueSheetDetailList"
    :is-loading="isLoading"
    :multiselect="false"
    :draggable="draggable"
    :sort="false"
    :no-data-message="t('CueSheetSearchDetailList.데이터가_없습니다')"
    :group="{ name: 'cuesheet', pull: 'clone', put: false }"
    :pagination="{ isShow: false }"
    :selected-row-index="selectedRowIndex"
    :height="`h-[calc(100vh-340px)]`"
    :selected-background-color="`bg-teal-100`"
    @on-row-clicked="onRowClicked"
    @on-row-db-clicked="onRowDbClicked"
    @is-finished-drag="endDragging"
  >
    <template #header>
      <th class="p-3">{{ t('CueSheetSearchDetailList.순번') }}</th>
      <th class="p-2">{{ t('CueSheetSearchDetailList.앵커멘트') }}</th>
      <th class="p-2">{{ t('CueSheetSearchDetailList.상태') }}</th>
      <th class="p-2">{{ t('CueSheetSearchDetailList.형식') }}</th>
      <th class="p-2">{{ t('CueSheetSearchDetailList.시간') }}</th>
      <th class="p-2">{{ t('CueSheetSearchDetailList.합계') }}</th>
      <th class="p-2">{{ t('CueSheetSearchDetailList.제목') }}</th>
      <th class="p-2">{{ t('CueSheetSearchDetailList.자막') }}</th>
      <th class="p-2">{{ t('CueSheetSearchDetailList.V') }}</th>
      <th class="p-2">{{ t('CueSheetSearchDetailList.A') }}</th>
      <th class="p-2">{{ t('CueSheetSearchDetailList.담당') }}</th>
      <th class="p-2">{{ t('CueSheetSearchDetailList.참조사항') }}</th>
    </template>

    <template #list="{ row: row, index }">
      <CueSheetSearchDetailListItem
        :cuesheet-detail="row"
        :selected-row="selectedRow"
        :index="index"
        :is-confirmed="true"
      />
    </template>
  </GTable>
</template>

<script>
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { GTable } from '@/components/ui';
import CueSheetSearchDetailListItem from './CueSheetSearchDetailListItem';

export default defineComponent({
  name: 'CueSheetSearchDetailList',
  components: {
    GTable,
    CueSheetSearchDetailListItem,
  },
  props: {
    cueSheetDetailList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: () => {
        return null;
      },
    },
    selectedRow: {
      type: Object,
      default: () => {
        return null;
      },
    },
    selectedRowIndex: {
      type: Number,
    },
  },
  emits: ['on-row-clicked', 'on-row-db-clicked', 'is-finished-drag'],
  setup(props, { emit }) {
    const { t } = useI18n();

    const onRowClicked = rowData => {
      emit('on-row-clicked', rowData?.row);
    };

    const onRowDbClicked = rowData => {
      emit('on-row-db-clicked', rowData?.row);
    };

    // 드래그 끝나는 시점
    const endDragging = params => {
      emit('is-finished-drag', params);
    };

    return {
      t,
      onRowClicked,
      onRowDbClicked,
      endDragging,
    };
  },
});
</script>

<style scoped></style>
