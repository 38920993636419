import { Code, EnumEntry } from './base';

class cuesheetFormIconCode extends EnumEntry<Code> {
  CUESHEET_FORM_001: Code = {
    id: 'cuesheet-form-001',
    name: 'cuesheet-form-001',
    label: 'cuesheet-form-001',
  };

  CUESHEET_FORM_002: Code = {
    id: 'cuesheet-form-002',
    name: 'cuesheet-form-002',
    label: 'cuesheet-form-002',
  };
  CUESHEET_FORM_003: Code = {
    id: 'cuesheet-form-003',
    name: 'cuesheet-form-003',
    label: 'cuesheet-form-003',
  };

  CUESHEET_FORM_004: Code = {
    id: 'cuesheet-form-004',
    name: 'cuesheet-form-004',
    label: 'cuesheet-form-004',
  };
  CUESHEET_FORM_005: Code = {
    id: 'cuesheet-form-005',
    name: 'cuesheet-form-005',
    label: 'cuesheet-form-005',
  };

  CUESHEET_FORM_006: Code = {
    id: 'cuesheet-form-006',
    name: 'cuesheet-form-006',
    label: 'cuesheet-form-006',
  };
  CUESHEET_FORM_007: Code = {
    id: 'cuesheet-form-007',
    name: 'cuesheet-form-007',
    label: 'cuesheet-form-007',
  };

  CUESHEET_FORM_008: Code = {
    id: 'cuesheet-form-008',
    name: 'cuesheet-form-008',
    label: 'cuesheet-form-008',
  };

  CUESHEET_FORM_009: Code = {
    id: 'cuesheet-form-009',
    name: 'cuesheet-form-009',
    label: 'cuesheet-form-009',
  };

  CUESHEET_FORM_010: Code = {
    id: 'cuesheet-form-010',
    name: 'cuesheet-form-010',
    label: 'cuesheet-form-010',
  };

  values: Array<Code> = [
    this.CUESHEET_FORM_001,
    this.CUESHEET_FORM_002,
    this.CUESHEET_FORM_003,
    this.CUESHEET_FORM_004,
    this.CUESHEET_FORM_005,
    this.CUESHEET_FORM_006,
    this.CUESHEET_FORM_007,
    this.CUESHEET_FORM_008,
    this.CUESHEET_FORM_009,
    this.CUESHEET_FORM_010,
  ];
}

export default new cuesheetFormIconCode();
