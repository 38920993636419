import { resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["disabled", "tabindex"]
const _hoisted_2 = {
  key: 0,
  class: "inline-block px-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("button", {
    type: "button",
    disabled: _ctx.disabled,
    tabindex: _ctx.tabIndex,
    class: _normalizeClass(["inline-flex items-center p-1 justify-center rounded-md", _ctx.btnClass])
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.iconComponent), {
          key: 0,
          class: _normalizeClass(_ctx.iconClass),
          "aria-hidden": "true"
        }, null, 8, ["class"]))
      : _createCommentVNode("", true),
    (!_ctx.text)
      ? _renderSlot(_ctx.$slots, "default", { key: 1 }, () => [
          (!!_ctx.text)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.text), 1))
            : _createCommentVNode("", true)
        ])
      : _createCommentVNode("", true)
  ], 10, _hoisted_1)), [
    [_directive_tooltip, _ctx.tooltip]
  ])
}