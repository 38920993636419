<template>
  <div class="space-y-1" :class="[width]">
    <span v-if="itemLabel" class="text-xs inline-block">{{ itemLabel }}</span>
    <VueMultiselect
      v-model="inputValue"
      :multiple="true"
      :track-by="trackBy"
      :label="label"
      :options="optionsList"
      :placeholder="disabled || readonly ? '' : placeholder"
      :loading="isLoading"
      :disabled="disabled || readonly"
      :allow-empty="allowEmpty"
      :select-label="selectLabel"
      :selected-label="allowEmpty ? selectedLabel : ''"
      :deselect-label="allowEmpty ? selectedLabel : deselectLabel"
      :select-group-label="allowEmpty ? i18n.global.t('GMultiSelect.선택') : ''"
      :deselect-group-label="allowEmpty ? selectedLabel : deselectLabel"
      :searchable="searchable"
      :close-on-select="closeOnSelect"
      :group-values="groupValues"
      :group-label="groupLabel"
      :group-select="groupSelect"
      @remove="onRemove"
    >
      <template #selection="{ values, isOpen }">
        <div class="truncate" :title="setValues(values)">
          <span class="text-xs" v-if="values.length" v-show="!isOpen">
            {{ setValues(values) }}
          </span>
        </div>
      </template>
      <template #noOptions>{{
        i18n.global.t('GMultiSelect.데이터가_존재하지_않습니다')
      }}</template>
      <template #noResult>{{
        i18n.global.t('GMultiSelect.데이터가_존재하지_않습니다')
      }}</template>
    </VueMultiselect>
    <p v-if="errorMessage || errors" class="mt-2 text-xs text-error">
      {{ errorMessage ? errorMessage : errors ? errors : '' }}
    </p>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import VueMultiselect from 'vue-multiselect';
import '@/vue-multiselect.css';
import { useField } from 'vee-validate';
import i18n from '@/i18n';
// import { cloneDeep /*, uniqBy */ } from 'lodash';

export default defineComponent({
  name: 'GMultiSelect',
  components: {
    VueMultiselect,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {
        return null;
      },
    },
    name: {
      type: String,
      required: true,
    },
    itemLabel: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    trackBy: {
      type: String,
      default: 'value',
    },
    label: {
      type: String,
      default: 'description',
    },
    selectLabel: {
      type: String,
      default: i18n.global.t('GMultiSelect.선택'),
    },
    selectedLabel: {
      type: String,
      default: i18n.global.t('GMultiSelect.취소'),
    },
    deselectLabel: {
      type: String,
      default: '', // 선택되어 있는 아이템에 hover하면 나오는 값
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    errors: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: 'w-72',
    },
    closeOnSelect: {
      type: Boolean,
      default: false,
    },
    groupValues: {
      type: String,
      default: '',
    },
    groupLabel: {
      type: String,
      default: '',
    },
    groupSelect: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue', 'select'],

  setup(props, { emit }) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.modelValue,
    });

    const errorCls = computed(() => {
      if (errorMessage.value || props.error) {
        return {
          '--custom-border': '1px solid red',
        };
      }
      return {
        '--custom-border': '1px solid #d1d5db',
      };
    });

    const optionsList = computed(() => {
      return props.options;
    });

    const setValues = values => {
      let texts = values.map(item => {
        return item[props.label];
      });

      return texts.join(', ');
    };

    const onRemove = async delValues => {
      // 전체 삭제를 누르면 배열로 넘어옴
      if (Array.isArray(delValues)) {
        inputValue.value = [];
        console.log('inputValue.value', inputValue.value);

        emit('update:modelValue', inputValue.value);
      }
    };

    return {
      i18n,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
      inputValue,
      errorCls,
      setValues,
      optionsList,
      onRemove,
    };
  },
});
</script>
