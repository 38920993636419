<template>
  <div class="w-full h-full flex flex-col scrollbar">
    <div class="flex">
      <CoreEditor
        v-if="!hideAnchorEditor"
        ref="anchorEditor"
        v-model:article-text="anchorArticleText"
        v-model:article-captions="anchorArticleCaptions"
        class="anchor-editor"
        :editor-read-only="anchorEditorReadOnly"
        :caption-read-only="anchorCaptionReadOnly"
        placeholder="앵커멘트 작성"
        @focusin="onAnchorEditorFocus"
      />
    </div>
    <div class="flex">
      <CoreEditor
        v-if="!hideReporterEditor"
        ref="reporterEditor"
        v-model:article-text="reporterArticleText"
        v-model:article-captions="reporterArticleCaptions"
        class="mt-1 reporter-editor"
        :editor-read-only="reporterEditorReadOnly"
        :caption-read-only="reporterCaptionReadOnly"
        placeholder="리포터멘트 작성"
        @focusin="onReporterEditorFocus"
      />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useModelWrapper } from '@/hooks/useModelWrapper';
import CoreEditor from './core/CoreEditor';

export default {
  name: 'ReporterEditor',
  components: {
    CoreEditor,
  },
  props: {
    anchorText: {
      type: String,
      default: '',
    },
    anchorCaptions: {
      type: Array,
      default: () => [],
    },
    reporterText: {
      type: String,
      default: '',
    },
    reporterCaptions: {
      type: Array,
      default: () => [],
    },
    reporterEditorReadOnly: {
      type: Boolean,
      default: false,
    },
    reporterCaptionReadOnly: {
      type: Boolean,
      default: false,
    },
    anchorEditorReadOnly: {
      type: Boolean,
      default: false,
    },
    anchorCaptionReadOnly: {
      type: Boolean,
      default: false,
    },
    hideReporterEditor: {
      type: Boolean,
      default: false,
    },
    hideAnchorEditor: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit, expose }) {
    const anchorEditor = ref([]);
    const reporterEditor = ref([]);
    const lastFocusEditor = ref(null);
    const anchorArticleText = useModelWrapper(props, emit, 'anchorText');
    const anchorArticleCaptions = useModelWrapper(
      props,
      emit,
      'anchorCaptions',
    );
    const reporterArticleText = useModelWrapper(props, emit, 'reporterText');
    const reporterArticleCaptions = useModelWrapper(
      props,
      emit,
      'reporterCaptions',
    );

    const onAnchorEditorFocus = () => {
      lastFocusEditor.value = anchorEditor.value;
    };

    const onReporterEditorFocus = () => {
      lastFocusEditor.value = reporterEditor.value;
    };

    const insertTextAtCursor = insertText => {
      if (!lastFocusEditor.value) {
        return;
      }

      lastFocusEditor.value.insertTextAtCursor(insertText);
    };

    expose({
      insertTextAtCursor,
    });

    return {
      anchorEditor,
      reporterEditor,
      anchorArticleText,
      anchorArticleCaptions,
      reporterArticleText,
      reporterArticleCaptions,
      onAnchorEditorFocus,
      onReporterEditorFocus,
    };
  },
};
</script>

<style scoped></style>
