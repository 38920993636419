import { Code, EnumEntry } from './base';

class cuesheetItemCode extends EnumEntry<Code> {
  ITEM: Code = {
    id: 'cue_000',
    name: '아이템',
    label: '아이템',
  };
  TEMPLATE: Code = {
    id: 'cue_001',
    name: '템플릿',
    label: '템플릿',
  };
  ARTICLE: Code = {
    id: 'cue_002',
    name: '기사',
    label: '기사',
  };

  values: Array<Code> = [this.ITEM, this.TEMPLATE, this.ARTICLE];
}

export default new cuesheetItemCode();
