import { PageConst } from '@/enums';

/**
 * 테이블 데이터의 순서를 누적해서 표시 한다.
 * @param {Number} currentPage - 현재 페이지
 * @param {Number} rowIndex - 행의 인덱스
 */
let order = 0;
const setOrder = (currentPage: number, rowIndex: number) => {
  if (currentPage !== 1) {
    if (rowIndex === 0) {
      order = (currentPage - 1) * PageConst.size + 1;
    } else {
      order = order + 1;
    }
  } else {
    order = rowIndex + 1;
  }

  return order;
};

export { setOrder };
