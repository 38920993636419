<template>
  <div v-show="false">
    <div id="printContent">
      <table
        style="width: 100%"
        v-for="(article, index) in printList"
        :key="index"
      >
        <thead class="header" style="page-break-before: always">
          <tr>
            <div class="header-first">
              <div class="header-first-cell-one">자막출고:</div>
              <div class="header-first-cell-two">
                <div>별첨</div>
                <div>여부</div>
              </div>
              <div class="header-first-cell-three" />
            </div>
          </tr>

          <tr>
            <div class="header-second">
              <div class="header-second-cell-one">
                {{ article?.artclTitl }}
              </div>
              <div class="header-second-cell-two">
                <span class="truncate">
                  {{ article?.setReporterName }}
                </span>
              </div>
              <div class="header-second-cell-three">
                <div>방송</div>
                <div>여부</div>
              </div>
              <div class="header-second-cell-four" />
            </div>
          </tr>
        </thead>

        <tbody>
          <table class="content-table">
            <tr
              v-for="(caption, index) in article?.captionList"
              :key="index"
              class="content-tr"
            >
              <td class="content-td-one">
                {{ index + 1 }}
              </td>
              <td class="content-td-two">
                {{ caption }}
              </td>
              <td class="content-td-three" />
            </tr>
          </table>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { defineProps, onMounted, ref } from 'vue';
import { useUserInfo } from '@/hooks/use-user-info';
import printJS from 'print-js';
import { concatArrays, getReporterName } from '@/utils/print';
import { flattenDeep } from 'lodash';

const props = defineProps({
  printData: {
    type: Array,
    default: () => {
      return [];
    },
  },
});

const emit = defineEmits(['close-print']);

const { userId, userName } = useUserInfo();
const printList = ref([]);

const setPrintData = () => {
  printList.value = props.printData.map(item => {
    return {
      ...item,
      setReporterName: getReporterName(item),
      artclTitl: item?.artclTitl || item?.cueItemTitl,
      inputrNm: item?.inputrNm,
      inputrUserId: item?.inputrUserId,
      rptrNm: item?.rptrNm,
      captionList: flattenDeep(
        concatArrays(
          item.anchorCaptionArray,
          item.reporterCaptionArray,
          item.artclTypCd,
        ),
      )
        .filter(item => item.text)
        .map(item => item.text),
    };
  });
};

const printContent = () => {
  printJS({
    printable: 'printContent', // 프린트할 요소의 ID
    type: 'html', // 프린트할 내용의 타입
    css: ['/print/article-caption.css', '/print/common.css'],
    documentTitle: `${userName}(${userId})`, // 인쇄될 문서의 제목
    scanStyles: false,
    // onPrintDialogClose: onClosePrint,
  });

  setTimeout(() => {
    window.addEventListener('mouseover', handler);
  }, 1000);
};

const handler = () => {
  // Make sure the event only happens once.
  window.removeEventListener('mouseover', handler);

  // Remove iframe from the DOM, by default 'printJS'
  const iframe = document.getElementById(`printJS`);

  emit('close-print');
  if (iframe) {
    iframe.remove();
  }
};

setPrintData();

onMounted(() => {
  printContent();
});
</script>
