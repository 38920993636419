import { Module } from 'vuex';
import { RootState } from '..';
import { makeUser } from '@/types/models';

export type UserState = {
  user: object;
};

export const UserModule: Module<UserState, RootState> = {
  namespaced: true,
  state: () => ({
    user: {},
  }),
  getters: {
    getUserInfo: (state, getters, rootState, rootGetters) => {
      return makeUser(rootGetters['auth/getPayload']);
    },
    getChDivId: (state, getters) => {
      return getters['getUserInfo']?.chDivId ?? '';
    },
    getEditorType: (state, getters) => {
      return getters['getUserInfo']?.editorType ?? '';
    },
  },
  mutations: {},

  actions: {},
};
