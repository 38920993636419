<template>
  <svg
    v-bind="$attrs"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path
      d="M14.8 11.975 13.375 10.55 16.95 6H8.825L6.825 4H19Q19.625 4 19.9 4.55Q20.175 5.1 19.8 5.6ZM19.775 22.6 14 16.825V19Q14 19.425 13.713 19.712Q13.425 20 13 20H11Q10.575 20 10.288 19.712Q10 19.425 10 19V12.825L1.4 4.225L2.8 2.8L21.2 21.2ZM13.375 10.55Z"
    />
  </svg>
</template>
