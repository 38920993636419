<template>
  <div class="flex inset-0 justify-center">
    <div class="flex items-center justify-center my-4">
      <GSpinner fill-white class="text-primary" />
      <span class="sm:text-xs text-xs">로딩중...</span>
    </div>
  </div>
</template>

<script>
import GSpinner from '@/components/ui/GSpinner';

export default {
  name: 'PageLoader',
  components: {
    GSpinner,
  },
};
</script>
