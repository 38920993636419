<template>
  <div class="border rounded-md bg-white overflow-auto">
    <GTable
      :list="articles"
      :draggable="false"
      :multiselect="false"
      :isLoading="isLoading"
      :pagination="pagination"
      :height="height"
      :selected-row-index="selectedRowIndex"
      @on-row-clicked="onRowClicked"
      @on-row-db-clicked="onRowDoubleClicked"
      @on-changed-page="onChangedPage"
    >
      <template #header>
        <th class="p-3">{{ t('AreaArticleList.순번') }}</th>
        <th class="p-2"></th>
        <th class="p-2">{{ t('AreaArticleList.송고일시') }}</th>
        <th class="p-2">{{ t('AreaArticleList.지역') }}</th>
        <th class="p-2"></th>
        <th class="p-2">{{ t('AreaArticleList.제목') }}</th>
        <th class="p-2">{{ t('AreaArticleList.부서') }}</th>
        <th class="p-2">{{ t('AreaArticleList.기자') }}</th>
        <th class="p-2">{{ t('AreaArticleList.뉴스_프로그램') }}</th>
        <th class="p-2">{{ t('AreaArticleList.길이') }}</th>
        <th class="p-2">{{ t('AreaArticleList.첨부파일') }}</th>
      </template>
      <template #list="{ row, index }">
        <AreaArticleListItem :row="row" :index="index" />
      </template>
    </GTable>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { GTable /*GIconButton*/ } from '@/components/ui';
import { useI18n } from 'vue-i18n';
import AreaArticleListItem from './AreaArticleListItem.vue';

export default defineComponent({
  name: 'AreaArticleList',
  components: {
    GTable,
    AreaArticleListItem,
  },
  props: {
    articles: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Object,
      default: () => {
        return null;
      },
    },
    height: {
      type: String,
      default: '',
    },
    selectedRowIndex: {
      type: Number,
    },
  },
  emits: ['on-row-clicked', 'on-changed-page', 'on-row-db-clicked'],

  setup(props, { emit }) {
    const { t } = useI18n();

    // 행 클릭
    const onRowClicked = rowData => {
      emit('on-row-clicked', rowData);
    };

    // 행 더블클릭
    const onRowDoubleClicked = rowData => {
      emit('on-row-db-clicked', rowData);
    };

    // 페이지 변경
    const onChangedPage = changePage => {
      emit('on-changed-page', changePage);
    };

    return {
      t,
      onRowClicked,
      onChangedPage,
      onRowDoubleClicked,
    };
  },
});
</script>
