<template>
  <svg role="presentation" width="24px" height="24px" viewBox="0 0 24 24">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <mask fill="white">
        <path
          d="M10.3333333,18 C9.59695367,18 9,17.4030463 9,16.6666667 C9,15.930287 9.59695367,15.3333333 10.3333333,15.3333333 C11.069713,15.3333333 11.6666667,15.930287 11.6666667,16.6666667 C11.6666667,17.4030463 11.069713,18 10.3333333,18 Z M10.3333333,13.3333333 C9.59695367,13.3333333 9,12.7363797 9,12 C9,11.2636203 9.59695367,10.6666667 10.3333333,10.6666667 C11.069713,10.6666667 11.6666667,11.2636203 11.6666667,12 C11.6666667,12.7363797 11.069713,13.3333333 10.3333333,13.3333333 Z M10.3333333,8.66666667 C9.59695367,8.66666667 9,8.069713 9,7.33333333 C9,6.59695367 9.59695367,6 10.3333333,6 C11.069713,6 11.6666667,6.59695367 11.6666667,7.33333333 C11.6666667,8.069713 11.069713,8.66666667 10.3333333,8.66666667 Z M14.3333333,18 C13.5969537,18 13,17.4030463 13,16.6666667 C13,15.930287 13.5969537,15.3333333 14.3333333,15.3333333 C15.069713,15.3333333 15.6666667,15.930287 15.6666667,16.6666667 C15.6666667,17.4030463 15.069713,18 14.3333333,18 Z M14.3333333,13.3333333 C13.5969537,13.3333333 13,12.7363797 13,12 C13,11.2636203 13.5969537,10.6666667 14.3333333,10.6666667 C15.069713,10.6666667 15.6666667,11.2636203 15.6666667,12 C15.6666667,12.7363797 15.069713,13.3333333 14.3333333,13.3333333 Z M14.3333333,8.66666667 C13.5969537,8.66666667 13,8.069713 13,7.33333333 C13,6.59695367 13.5969537,6 14.3333333,6 C15.069713,6 15.6666667,6.59695367 15.6666667,7.33333333 C15.6666667,8.069713 15.069713,8.66666667 14.3333333,8.66666667 Z"
        ></path>
      </mask>
      <path
        d="M10.3333333,18 C9.59695367,18 9,17.4030463 9,16.6666667 C9,15.930287 9.59695367,15.3333333 10.3333333,15.3333333 C11.069713,15.3333333 11.6666667,15.930287 11.6666667,16.6666667 C11.6666667,17.4030463 11.069713,18 10.3333333,18 Z M10.3333333,13.3333333 C9.59695367,13.3333333 9,12.7363797 9,12 C9,11.2636203 9.59695367,10.6666667 10.3333333,10.6666667 C11.069713,10.6666667 11.6666667,11.2636203 11.6666667,12 C11.6666667,12.7363797 11.069713,13.3333333 10.3333333,13.3333333 Z M10.3333333,8.66666667 C9.59695367,8.66666667 9,8.069713 9,7.33333333 C9,6.59695367 9.59695367,6 10.3333333,6 C11.069713,6 11.6666667,6.59695367 11.6666667,7.33333333 C11.6666667,8.069713 11.069713,8.66666667 10.3333333,8.66666667 Z M14.3333333,18 C13.5969537,18 13,17.4030463 13,16.6666667 C13,15.930287 13.5969537,15.3333333 14.3333333,15.3333333 C15.069713,15.3333333 15.6666667,15.930287 15.6666667,16.6666667 C15.6666667,17.4030463 15.069713,18 14.3333333,18 Z M14.3333333,13.3333333 C13.5969537,13.3333333 13,12.7363797 13,12 C13,11.2636203 13.5969537,10.6666667 14.3333333,10.6666667 C15.069713,10.6666667 15.6666667,11.2636203 15.6666667,12 C15.6666667,12.7363797 15.069713,13.3333333 14.3333333,13.3333333 Z M14.3333333,8.66666667 C13.5969537,8.66666667 13,8.069713 13,7.33333333 C13,6.59695367 13.5969537,6 14.3333333,6 C15.069713,6 15.6666667,6.59695367 15.6666667,7.33333333 C15.6666667,8.069713 15.069713,8.66666667 14.3333333,8.66666667 Z"
        fill="#A5ADBA"
      ></path>
    </g>
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UserCircleIcon',
  props: {},
  setup() {
    return {};
  },
});
</script>
