<template>
  <GModal
    :changed="false"
    :dialog-overlay="false"
    max-width="w-[1830px] sm:max-w-full"
    @close="onClose"
  >
    <!-- title -->
    <template #title>
      <span class="text-white font-D2CodingBold">{{
        t('Modal.Article.ReferenceModal.참조_기사')
      }}</span>
    </template>

    <!-- 내용 -->
    <template #content>
      <div class="bg-gray-100 rounded-b-md">
        <!-- 탭 영역 -->
        <div class="text-sm bg-white select-none">
          <span
            v-for="tab in tabs"
            :key="tab.id"
            class="inline-block py-3 px-8 cursor-pointer hover:border-b-4 hover:border-b-primary-bright"
            :class="
              selectedTabId === tab.id
                ? 'border-b-4 border-b-primary-bright font-D2CodingBold'
                : 'text-gray-400'
            "
            @click="onClickedTab(tab)"
          >
            {{ tab.name }}
          </span>
        </div>

        <!-- 탭 내용 영역 -->
        <div class="py-3 px-4 space-y-5">
          <div class="flex space-x-2">
            <div class="w-[1040px] overflow-auto">
              <RefMyArticle
                v-if="selectedTabId === 1"
                @on-row-clicked="onRowClicked"
                @on-row-db-clicked="onRowDbClicked"
              />
              <RefRegionArticle
                v-if="selectedTabId === 2"
                @on-row-clicked="onRowClicked"
                @on-row-db-clicked="onRowDbClicked"
              />
              <RefAreaArticle
                v-if="selectedTabId === 3"
                @on-row-clicked="onRowClicked"
                @on-row-db-clicked="onRowDbClicked"
              />
              <!-- <RefSeoulMBCArticle
                v-show="selectedTabId === 4"
                @on-row-clicked="onRowClicked"
              /> -->
            </div>
            <div class="w-[750px]">
              <PreviewArticle
                :article-id="selectedArticle?.id"
                :item-type="articleType"
                height="h-[calc(100vh-260px)]"
                editor-height="h-[calc(100vh-405px)]"
                :show-action-log-tab="selectedTabId === 1 ? false : true"
              />
            </div>
          </div>

          <!-- 버튼 -->
          <div class="space-x-3 pb-2 flex justify-center">
            <GButton type="secondary" @click="onClose">{{
              t('Modal.Article.ReferenceModal.취소')
            }}</GButton>
            <GButton :disabled="!selectedArticle" @click="onArticleClicked">{{
              t('Modal.Article.ReferenceModal.선택')
            }}</GButton>
          </div>
        </div>
      </div>
    </template>
  </GModal>
</template>

<script>
import { defineComponent, onMounted, ref, computed, watch } from 'vue';
import { GModal, GButton } from '@/components/ui';
import { useI18n } from 'vue-i18n';
import { PreviewArticle } from '@/components/article';
import RefMyArticle from '@/components/article/reference/RefMyArticle';
import RefRegionArticle from '@/components/article/reference/RefRegionArticle';
import RefAreaArticle from '@/components/article/reference/RefAreaArticle';

export default defineComponent({
  name: 'ReferenceModal',
  components: {
    GModal,
    GButton,
    PreviewArticle,
    RefMyArticle,
    RefRegionArticle,
    // RefSeoulMBCArticle,
    RefAreaArticle,
  },
  props: {},
  emits: ['close', 'selected-article'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const isLoading = ref(false);
    const tabs = ref([
      { id: 1, name: '내기사', type: 'myArticle' },
      { id: 2, name: '전체 기사', type: 'coverDesk' },
      { id: 3, name: '지역사', type: 'coverDesk' },
      // { id: 4, name: '서울 MBC' },
    ]);
    const selectedTabId = ref(1);
    const selectedArticle = ref(null);

    const articleType = computed(() => {
      return tabs.value.find(tab => tab.id === selectedTabId.value).type;
    });

    // 탭 선택
    const onClickedTab = tab => {
      selectedTabId.value = tab.id;
    };

    // 탭을 변경했을 때, 미리보기 초기화를 위한 작업
    watch(
      () => selectedTabId.value,
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          selectedArticle.value = null;
        }
      },
    );

    // 행 선택
    const onRowClicked = row => {
      selectedArticle.value = row;
    };

    // 더행 선택
    const onRowDbClicked = row => {
      selectedArticle.value = row;
      onArticleClicked();
    };

    // 기사 선택
    const onArticleClicked = () => {
      emit('selected-article', selectedArticle.value);
      onClose();
    };

    // 모달 닫기
    const onClose = () => {
      emit('close');
    };

    // 초기화
    const initData = () => {};

    onMounted(() => {
      initData();
    });

    return {
      t,
      tabs,
      isLoading,
      selectedTabId,
      selectedArticle,
      articleType,
      onClose,
      onClickedTab,
      onRowClicked,
      onRowDbClicked,
      onArticleClicked,
    };
  },
});
</script>
