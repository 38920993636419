import { templateSettings, template } from 'lodash';

templateSettings.interpolate = /{{([\s\S]+?)}}/g;
const compiled = template(process.env.VUE_APP_WEBSOCKET_DEST);

/**
 * Rabbit MQ exchange
 */
interface Exchange {
  cuesheet: string;
  article: string;
}

/**
 * 큐시트 관련 이벤트 메시지
 */
interface ICueSheetEventBus {
  CREATE_CUESHEET_EMPTY_ITEM: string; // 큐시트 항목 아이템 추가
  CREATE_CUESHEET_ITEM_BY_ANOTHER_CUESHEET: string; // 다른 큐시트 아이템 추가
  CREATE_CUESHEET_ITEM_BY_ARTICLE: string; // 기사 아이템 추가
  CREATE_CUESHEET_ITEM_LIST_BY_TEMPLATE: string; // 템플릿 추가
  UPDATE_CUESHEET_ITEM: string; // 큐시트 아이템 수정
  DELETE_CUESHEET_ITEM: string; // 큐시트 아이템 삭제
  DELETE_CUESHEET_ITEM_SYMBOL: string; // 큐시트 아이템 심볼 삭제
  UPDATE_CUESHEET_ITEM_ORDER_NUMBER: string; // 큐시트 아이템 순서 편집
  UPDATE_CUESHEET_FORCE_UNLOCK: string; // 큐시트 강제 언락
  UPDATE_CUESHEET_LOCK: string; // 큐시트 락(수정 모드)
  UPDATE_CUESHEET_UNLOCK: string; // 큐시트 언락(보기 모드)
  CONFIRM_CUESHEET_ITEM_ANCHORMENT: string; // 큐시트 앵커멘트 확정
  UPDATE_CUESHEET_SYNCHRONIZE: string; // 큐시트 동기화
  UPDATE_CUESHEET_CONFIRM: string; // 큐시트 확정
  UPDATE_CUESHEET_CONFIRM_CANCEL: string; // 큐시트 확정
  CS_PROMPTER_UPDATE_CUESHEET_BROADCAST_STATUS: string; // cs 프롬프터 방송상태
  CS_PROMPTER_UPDATE_CUESHEET_ITEM_BROADCAST_STATUS_TO_COMPLETE: string; // 큐시트 아이템 방송 완료
}

/**
 * 기사 관련 이벤트 메시지
 */
interface IArticleEventBus {
  CREATE_ARTICLE: string; //취재 데스크 추가 or 내기사 송고
  CREATE_COPY_ARTICLE: string; // 기사 복사
  UPDATE_ARTICLE: string; // 기사 수정
  UPDATE_LOCK_ARTICLE: string; // 기사 락
  UPDATE_UNLOCK_ARTICLE: string; // 기사 언락
  UPDATE_FORCE_UNLOCK_ARTICLE: string; // 기사 강제 언락
  UPDATE_APPROVE_STATUS_ARTICLE: string; // 출고 상태 변경
  DELETE_ARTICLE: string; //기사 삭제
  UPDATE_ANCHORMENT_ARTICLE: string; // 앵커멘트 수정
}

// type eventBusType = CueSheetEventBusType | ArticleEventBusType;
interface IEventBus extends ICueSheetEventBus, IArticleEventBus {
  [key: string]: string;
}

/**
 * mq exchange
 */
const Exchanges: Exchange = Object.freeze({
  cuesheet: 'CUESHEET',
  article: 'ARTICLE',
});

/**
 * 기능 별 event ID
 */
const MqEventBusEmitEnum: IEventBus = Object.freeze({
  // cuesheet event list
  CREATE_CUESHEET_EMPTY_ITEM: 'CREATE_CUESHEET_EMPTY_ITEM',
  CREATE_CUESHEET_ITEM_BY_ANOTHER_CUESHEET:
    'CREATE_CUESHEET_ITEM_BY_ANOTHER_CUESHEET',
  CREATE_CUESHEET_ITEM_BY_ARTICLE: 'CREATE_CUESHEET_ITEM_BY_ARTICLE',
  CREATE_CUESHEET_ITEM_LIST_BY_TEMPLATE:
    'CREATE_CUESHEET_ITEM_LIST_BY_TEMPLATE',
  UPDATE_CUESHEET_ITEM: 'UPDATE_CUESHEET_ITEM',
  DELETE_CUESHEET_ITEM: 'DELETE_CUESHEET_ITEM',
  DELETE_CUESHEET_ITEM_SYMBOL: 'DELETE_CUESHEET_ITEM_SYMBOL',
  UPDATE_CUESHEET_ITEM_ORDER_NUMBER: 'UPDATE_CUESHEET_ITEM_ORDER_NUMBER',
  UPDATE_CUESHEET_FORCE_UNLOCK: 'UPDATE_CUESHEET_FORCE_UNLOCK',
  UPDATE_CUESHEET_LOCK: 'UPDATE_CUESHEET_LOCK',
  UPDATE_CUESHEET_UNLOCK: 'UPDATE_CUESHEET_UNLOCK',
  CONFIRM_CUESHEET_ITEM_ANCHORMENT: 'CONFIRM_CUESHEET_ITEM_ANCHORMENT',
  UPDATE_CUESHEET_SYNCHRONIZE: 'UPDATE_CUESHEET_SYNCHRONIZE',
  UPDATE_CUESHEET_CONFIRM: 'UPDATE_CUESHEET_CONFIRM',
  UPDATE_CUESHEET_CONFIRM_CANCEL: 'UPDATE_CUESHEET_CONFIRM_CANCEL',
  CS_PROMPTER_UPDATE_CUESHEET_BROADCAST_STATUS:
    'CS_PROMPTER_UPDATE_CUESHEET_BROADCAST_STATUS',
  CS_PROMPTER_UPDATE_CUESHEET_ITEM_BROADCAST_STATUS_TO_COMPLETE:
    'CS_PROMPTER_UPDATE_CUESHEET_ITEM_BROADCAST_STATUS_TO_COMPLETE',

  // article event list
  CREATE_ARTICLE: 'CREATE_ARTICLE',
  CREATE_COPY_ARTICLE: 'CREATE_COPY_ARTICLE',
  UPDATE_ARTICLE: 'UPDATE_ARTICLE',
  UPDATE_LOCK_ARTICLE: 'UPDATE_LOCK_ARTICLE',
  UPDATE_UNLOCK_ARTICLE: 'UPDATE_UNLOCK_ARTICLE',
  UPDATE_FORCE_UNLOCK_ARTICLE: 'UPDATE_FORCE_UNLOCK_ARTICLE',
  UPDATE_APPROVE_STATUS_ARTICLE: 'UPDATE_APPROVE_STATUS_ARTICLE',
  DELETE_ARTICLE: 'DELETE_ARTICLE',
  UPDATE_ANCHORMENT_ARTICLE: 'UPDATE_ANCHORMENT_ARTICLE',
});

/**
 * stomp dest url 설정
 * @param channel 지역사 채널 값
 * @returns stompjs dest url
 */
const getSubscribes = (channel: string) => {
  return Object.values(Exchanges).map(value => {
    return {
      url: compiled({ exchange: value, routingkey: channel }),
      // url: `/exchange/X.MARSL.${value}/MARSL.${channel}`,
      exchange: value,
    };
  });
};

/**
 * 기능별 eventBusName 조합
 * @param exchange MQ exchange 값 ex) CUESHEET or ARTICLE
 * @param eventId MQ 기능별 이벤트 ID
 * @returns exchange값과 eventId를 조합한 eventBusName
 */
const getMqEventBusEmitName = (
  exchange: string,
  // exchange: keyof Exchange,
  eventId: string,
): string => {
  return `${exchange}_NOTIFY_${MqEventBusEmitEnum[eventId]}`;
};

export default {
  Exchanges,
  getSubscribes,
  getMqEventBusEmitName,
  MqEventBusEmitEnum,
};
