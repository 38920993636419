import { AxiosInstance } from 'axios';

const PREFIX = '/department';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 부서 목록 조회
     * @param {String} cateNm - 카테고리 이름
     */
    findAll: async (params: any) => {
      return $axios
        .get(`${PREFIX}`, { params })
        .then(response => response.data);
    },

    /**
     * 부서 상세 조회
     * @param {Number} deptId - 부서 ID
     */
    findOne: async (deptId: number) => {
      return $axios.get(`${PREFIX}/${deptId}`).then(response => response.data);
    },

    /**
     * 부서 생성
     * @param {Object} options - 생성에 필요한 값
     * @param {String} options.deptNm - 부서 이름
     */
    create: async (params: any) => {
      return $axios.post(`${PREFIX}`, params).then(response => response.data);
    },

    /**
     * 부서 수정
     * @param {Number} deptId - 부서 ID
     * @param {Object} options - 수정에 필요한 값
     * @param {String} options.deptNm - 부서 이름
     * @param {String} options.useYn - 사용여부
     */
    update: async (deptId: number, params: any) => {
      return $axios
        .put(`${PREFIX}/${deptId}`, params)
        .then(response => response.data);
    },

    /**
     * 부서 삭제
     * @param {Number} deptId - 부서 ID
     */
    delete: async (deptId: number) => {
      return $axios
        .delete(`${PREFIX}/${deptId}`)
        .then(response => response.data);
    },

    /**
     * 부서 순서 변경
     * @param {Number} deptId - 부서 ID
     * @param {Object} options - Request body
     * @param {Number} options.ord - 변경할 순서
     */
    order: async (deptId: number, params: any) => {
      return $axios
        .put(`${PREFIX}/${deptId}/order-number`, params)
        .then(response => response.data);
    },
  };
};
