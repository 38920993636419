import { AxiosInstance } from 'axios';

const PREFIX = '/article';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 기사 로그 조회
     * @param {number} articleId 기사 아이디
     * @returns
     */
    findAll: async (articleId: number) => {
      return $axios
        .get(`${PREFIX}/${articleId}/log`)
        .then(response => response.data);
    },

    /**
     * 기사 로그 상세 조회
     * @param {number} articleId 기사 아이디
     * @param {number} articleLogId 기사 로그아이디
     * @returns
     */
    findOne: async (articleId: number, articleLogId: number) => {
      return $axios
        .get(`${PREFIX}/${articleId}/log/${articleLogId}`)
        .then(response => response.data);
    },

    /**
     * 타지역 송고 이력 조회
     * @param articleId
     */
    findAllChannelTransferLog: async (params: any) => {
      return $axios
        .get(`${PREFIX}/channel-transfer-log`, { params })
        .then(response => response.data);
    },
  };
};
