import { AxiosInstance } from 'axios';

const PREFIX = '/user';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 사용자 조회
     * @param {number} chDivId 채널 구분 아이디 chDivId(채널 코드) 필수값
     * @param {string} searchValue 검색 키워드
     * @param {string} delYn 삭제 여부
     */
    findAll: async (chDivId: number | string) => {
      return $axios
        .get(`${PREFIX}/channels/${chDivId}` /*, { params }*/)
        .then(response => response.data);
    },

    /**
     * 사용자 상세조회
     * @param {number} userMngId 사용자 고유 ID 필수값
     */
    findOne: async (userMngId: number) => {
      return $axios
        .get(`${PREFIX}/${userMngId}`)
        .then(response => response.data);
    },

    /**
     * 사용자 생성
     * @param {number} chDivId 채널 구분 아이디 chDivId(채널 코드) 필수값
     * @param {string} userId 사용자 ID
     * @param {string} userNm 사용자 이름
     * @param {string} pwd 비밀번호
     * @param {string} deptId 부서 ID
     * @param {string} email 이메일
     * @param {number} useEndDtm 사용만료일 use_end_dtm ????
     */
    create: async (params: any) => {
      return $axios.post(`${PREFIX}`, params).then(response => response.data);
    },

    /**
     * 사용자 수정
     * @param {number} userMngId 사용자 고유 ID 필수값
     */
    update: async (userMngId: number, params: any) => {
      return $axios
        .put(`${PREFIX}/${userMngId}`, params)
        .then(response => response.data);
    },

    /**
     * 사용자 삭제/복구
     * @param {number} userMngId 사용자 고유 ID 필수값
     * @param {string} delYn 삭제(Y) / 복구(N)
     */
    delete: async (userMngId: number, params: any) => {
      return $axios
        .delete(`${PREFIX}/${userMngId}`, { params })
        .then(response => response.data);
    },
  };
};
