<template>
  <svg
    width="34px"
    height="34px"
    stroke-width="1.5"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color="currentColor"
  >
    <path
      d="M22 14V6a3 3 0 00-3-3H9a3 3 0 00-3 3v7"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M12 21H6a4 4 0 010-8h12a4 4 0 104 4v-3"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DatabaseScriptIcon',
  props: {},
  setup() {
    return {};
  },
});
</script>
