/**
 * JSON stringify 형태를 원래 형태로 변환
 * @param {string} text
 */
const jsonParser = (text: string) => {
  let str = text
    .replace(/\\"/g, '\\"')
    .replace(/\\'/g, "\\'")
    .replace(/\\/g, '\\')
    .replace(/\//g, '\\/')
    // .replace(/\b/g, '\\b')
    .replace(/\f/g, '\\f')
    .replace(/\n/g, '\\n')
    .replace(/\r/g, '\\r')
    .replace(/\r\n/g, '\\r\\n')
    .replace(/\t/g, '\\t');

  return JSON.parse(str);
};

export default jsonParser;
