<template>
  <GModal :changed="false" :dialog-overlay="false" @close="onClose">
    <!-- title -->
    <template #title>
      <span class="text-white font-D2CodingBold">{{
        t('Modal.Article.CopyModal.기사_복사')
      }}</span>
    </template>

    <!-- 내용 -->
    <template #content>
      <div class="p-5 space-y-4 text-sm">
        <div v-if="isShowWriter" class="space-y-0.5">
          <div>{{ t('Modal.Article.CopyModal.작성자') }}</div>
          <div class="space-y-1.5 px-2 py-3 rounded-md bg-gray-100">
            <GRadioButton
              v-model="writerType"
              name="writerType"
              :label="t('Modal.Article.CopyModal.원본_작성자_유지')"
              value="originWriter"
              font-size="text-sm"
              :default="openType !== 'areaArticle' ? true : false"
              :disabled="openType === 'myArticle' || openType === 'areaArticle'"
            />
            <GRadioButton
              v-model="writerType"
              name="writerType"
              :label="t('Modal.Article.CopyModal.현_작업자명으로_변경')"
              value="changeWriter"
              font-size="text-sm"
              :default="openType === 'areaArticle' ? true : false"
              :disabled="openType === 'myArticle' || openType === 'areaArticle'"
            />
          </div>
        </div>

        <div class="space-y-0.5">
          <div>{{ t('Modal.Article.CopyModal.방송_예정일자') }}</div>
          <div class="space-y-1.5 px-2 py-3 rounded-md bg-gray-100">
            <GRadioButton
              v-model="sendingDateType"
              name="sendingDateType"
              :label="t('Modal.Article.CopyModal.원본_일자_유지')"
              value="originDate"
              font-size="text-sm"
              :default="true"
              @change="onChangeDateType"
            />
            <div class="flex items-center space-x-3">
              <GRadioButton
                v-model="sendingDateType"
                name="sendingDateType"
                :label="t('Modal.Article.CopyModal.다음_일자로_변경')"
                value="changeDate"
                font-size="text-sm"
                @change="onChangeDateType"
              />
              <DatePickerEx
                v-if="sendingDateType === 'changeDate'"
                v-model="sendingDate"
                name="sendingDate"
                width="w-40"
                @change="onChangeDate"
              />
            </div>
          </div>
        </div>

        <div v-if="openType !== 'myArticle'" class="space-y-0.5">
          <div>{{ t('Modal.Article.CopyModal.복사_위치') }}</div>
          <div class="space-y-1.5 px-2 py-3 rounded-md bg-gray-100">
            <GRadioButton
              v-model="copyType"
              name="copyType"
              :label="copyCode.arriveRegionArticle.label"
              :value="copyCode.arriveRegionArticle.id"
              :default="true"
              font-size="text-sm"
              @change="onChangeDateType"
            />
            <div class="flex items-center space-x-3">
              <GRadioButton
                v-model="copyType"
                name="copyType"
                :label="copyCode.arriveMyArticle.label"
                :value="copyCode.arriveMyArticle.id"
                font-size="text-sm"
                @change="onChangeDateType"
              />
            </div>
          </div>
        </div>

        <!-- 버튼 -->
        <div class="space-x-3 flex justify-end pt-2">
          <GButton type="secondary" @click="onClose">{{
            t('Modal.Article.CopyModal.취소')
          }}</GButton>
          <GButton @click="onCopyArticle">{{
            t('Modal.Article.CopyModal.복사')
          }}</GButton>
        </div>
      </div>
    </template>
  </GModal>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';
import { GModal, GRadioButton, GButton } from '@/components/ui';
import DatePickerEx from '@/components/common/DatePickerEx';
import { useI18n } from 'vue-i18n';
import { copyCode } from '@/codes';
import { defaultDate } from '@/utils/format';

export default defineComponent({
  name: 'CopyModal',
  components: {
    GModal,
    GButton,
    GRadioButton,
    DatePickerEx,
  },
  props: {
    article: {
      type: Object,
      default: () => {
        return {};
      },
    },
    openType: {
      type: String,
      default: '',
    },
  },
  emits: ['close', 'copy-article'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const sendingDateType = ref('originDate');
    const copyType = ref(copyCode.arriveRegionArticle.id);
    const sendingDate = ref(defaultDate());
    const writerType = ref('originWriter');

    // 기사 복사
    const onCopyArticle = () => {
      const emitData = {
        writerType:
          props.openType === 'areaArticle' ? 'changeWriter' : writerType.value,
        sendingDateType: sendingDateType.value,
        sendingDate: sendingDate.value,
        copyType: copyType.value,
      };

      emit('copy-article', emitData);
      onClose();
    };

    // 일자 변경
    const onChangeDate = date => {
      sendingDate.value = date;
    };

    // 발송 예정일자 변경
    const onChangeDateType = event => {
      if (event.target.value !== 'changeDate') {
        // 날짜 초기화
        sendingDate.value = defaultDate();
      }
    };

    // 모달 닫기
    const onClose = () => {
      emit('close');
    };

    const isShowWriter = computed(() => {
      return !(
        props.openType === 'myArticle' ||
        props.openType === 'areaArticle' ||
        props.openType === 'seoulMBCArticle'
      );
    });

    return {
      t,
      writerType,
      sendingDateType,
      sendingDate,
      copyCode,
      onCopyArticle,
      onChangeDate,
      onChangeDateType,
      onClose,
      copyType,
      isShowWriter,
    };
  },
});
</script>
