import { Module } from 'vuex';
import { RootState } from '..';
import { useMarsLApi } from '@/apis/mars-l-api';
import { useUserInfo } from '@/hooks/use-user-info';

export type CompanyState = {
  companys: any[];
};

export const CompanyModule: Module<CompanyState, RootState> = {
  namespaced: true,
  state: () => ({
    companys: [],
  }),
  getters: {
    findAllCompanys: state => {
      return state.companys;
    },
  },
  actions: {
    async FIND_ALL_COMPANYS({ commit }) {
      const marsLApi = useMarsLApi();
      const { companyId } = useUserInfo();

      try {
        let companyList = await marsLApi.company.basic.findAll();

        // 내 지역사는 제외
        companyList = await companyList.data.filter((company: any) => {
          return company.chDivId !== companyId;
        });

        commit('SET_COMPANYS', companyList);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mutations: {
    SET_COMPANYS(state, list) {
      state.companys = list;
    },
  },
};
