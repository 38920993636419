<template>
  <div>
    <!-- 경고 문구 -->
    <div
      v-if="warningText"
      class="bg-red-50 border-t-4 border-red-300 text-red-500 font-D2CodingBold px-2 py-1 rounded-b-lg mb-2"
      role="alert"
    >
      <div class="flex items-center">
        <div class="py-1">
          <svg
            class="fill-current h-6 w-6 text-orange-400 mr-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
            />
          </svg>
        </div>
        <div>
          <p class="text-xs font-D2CodingBold">{{ warningText }}</p>
        </div>
      </div>
    </div>

    <div
      class="text-xs bg-white space-y-4 rounded-md overflow-y-auto"
      :class="[!isReadonly ? 'p-2' : '', height]"
    >
      <div v-if="!isReadonly">
        <h3 class="text-sm">{{ t('ArticleLink.링크추가') }}</h3>
        <div class="mt-1 space-y-1 border rounded-md px-3 py-2.5 bg-slate-100">
          <GInput
            v-model="linkTitle"
            name="linkTitle"
            width="flex-1"
            :label="t('ArticleLink.제목')"
          />
          <GInput
            v-model="link"
            name="link"
            width="flex-1"
            :label="t('ArticleLink.링크')"
            :placeholder="t('ArticleLink.링크를_입력하세요')"
            @keyup.enter="onSubmit"
          />
          <div class="text-right pt-2">
            <GButton :disabled="!link" @click="onSubmit">{{
              t('ArticleLink.등록')
            }}</GButton>
          </div>
        </div>
      </div>

      <!-- 등록된 링크 목록 -->
      <h3 v-if="!isReadonly" class="text-sm">
        {{ t('ArticleLink.링크목록') }}
      </h3>
      <PageLoader v-if="isLoading" />
      <div v-if="linkList?.length > 0" class="space-y-1">
        <div v-if="isShowLabel" class="">
          {{ t('ArticleLink.등록된_링크') }}
        </div>
        <draggable
          v-if="!isLoading"
          :list="linkList"
          handle=".handle"
          item-key="link"
          class="space-y-2"
          @change="onPositionChange"
        >
          <template #item="{ element: item, index }">
            <div
              class="flex border rounded-md p-2 hover:bg-gray-100 items-center justify-between shadow"
            >
              <div class="flex space-x-2 items-center">
                <Bars3Icon
                  v-if="!isReadonly"
                  class="handle w-6 h-6 pr-1 text-gray-300 cursor-pointer"
                />
                <div
                  class="space-y-1.5"
                  :class="truncateWidth"
                  :title="`[${item.linkTitle}] - ${item.link}`"
                >
                  <div class="truncate font-D2CodingBold">
                    {{ item.linkTitle }}
                  </div>
                  <div class="truncate">
                    <a :href="setLink(item.link)" target="_blank">
                      {{ item.link }}</a
                    >
                  </div>
                </div>
              </div>
              <GIconButton
                v-if="!isReadonly"
                v-tooltip="t('ArticleLink.삭제')"
                icon="trash"
                @click="onDeleteLink(item, index)"
              />
            </div>
          </template>
        </draggable>
      </div>
      <!-- 등록된 링크가 없을 때 -->
      <GNoData v-if="!isLoading && linkList?.length === 0" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, onActivated } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { GInput, GButton, GIconButton } from '@/components/ui';
import PageLoader from '@/components/common/PageLoader.vue';
import { useMarsLApi } from '@/apis/mars-l-api';
import GNoData from '@/components/ui/GNoData.vue';
import { Bars3Icon } from '@heroicons/vue/24/outline';
import draggable from 'vuedraggable';
import { useSweetAlert } from '@/hooks/use-sweet-alert';
import { RouterName } from '@/types/router';

const props = defineProps({
  height: {
    type: String,
  },
  warningText: {
    type: String,
  },
  articleId: {
    type: [Number, String],
    default: 0,
  },
  isReadonly: {
    type: Boolean,
    default: false,
  },
  isShowLabel: {
    type: Boolean,
    default: true,
  },
  truncateWidth: {
    type: String,
  },
});

const emit = defineEmits(['links']);

const { t } = useI18n();
const marsLApi = useMarsLApi();
const { swalConfirm } = useSweetAlert();
const route = useRoute();
const store = useStore();

const isLoading = ref(false);
const linkTitle = ref(null);
const link = ref(null);
const linkList = ref([]);
const selectedLink = ref(null);

watch(
  () => props.articleId,
  id => {
    if (!id) {
      return;
    }

    getLinks();
  },
);

// 등록버튼 클릭
const onSubmit = async () => {
  const defaultTitle = t('ArticleLink.제목없음');
  const title = linkTitle.value || defaultTitle;
  linkList.value.push({ linkTitle: title, link: link.value });

  // 기사 생성일때는 기사 저장할 때 링크가 같이 저장된다.
  if (!props.articleId) {
    emit('links', linkList.value);
  }
  // 기사 수정일 때는 링크를 하나씩 바로 등록한다.
  else {
    try {
      const params = {
        titl: linkTitle.value || defaultTitle,
        link: link.value,
      };

      let response = null;
      if (
        route.name === RouterName.MyArticle ||
        route.name === RouterName.CreateMyArticle
      ) {
        response = await marsLApi.myArticle.link.create(
          props.articleId,
          params,
        );
      } else if (route.name === RouterName.EditArticle) {
        response = await marsLApi.coverDesk.link.create(
          props.articleId,
          params,
        );
      }

      if (response.success) {
        window.Notify.success({
          message: '[링크 등록] 완료되었습니다.',
        });
        getLinks({ isLoadingView: false });

        if (route.name === RouterName.EditArticle) {
          store.dispatch('refresh/REFRESH_MY_REGION_ARTICLE_STATUS', {
            reason: 'deleteFile',
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  linkTitle.value = null;
  link.value = null;
};

// 링크 삭제
const onDeleteLink = (link, index) => {
  selectedLink.value = link;

  // 신규 생성일때는 api 안타고 링크 목록에서만 삭제처리
  if (!props.articleId) {
    linkList.value.splice(index, 1);
    emit('links', linkList.value);
  }
  // 기사 수정일때는 바로 삭제처리
  else {
    const defaultColor = '#ff0303';

    swalConfirm({
      title: link.linkTitle,
      icon: undefined,
      iconColor: defaultColor,
      iconHtml:
        '<svg class="h-24 w-24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.043 21H3.957c-1.538 0-2.5-1.664-1.734-2.997l8.043-13.988c.77-1.337 2.699-1.337 3.468 0l8.043 13.988C22.543 19.336 21.58 21 20.043 21zM12 9v4" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path><path d="M12 17.01l.01-.011" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>',
      html: `<div>을(를) 삭제하시겠습니까?</div>`,
      customClass: {
        icon: 'no-border',
      },
      confirmButtonColor: defaultColor,
      confirmButtonText: '삭제',
    }).then(async res => {
      if (res === 'confirm') {
        try {
          let response = null;
          if (
            route.name === RouterName.MyArticle ||
            route.name === RouterName.CreateMyArticle
          ) {
            response = await marsLApi.myArticle.link.delete(
              props.articleId,
              link.id,
            );
          } else {
            response = await marsLApi.coverDesk.link.delete(
              props.articleId,
              link.id,
            );
          }

          if (response.success) {
            window.Notify.success({
              message: '[링크 삭제] 완료되었습니다.',
            });
            getLinks({ isLoadingView: false });

            if (route.name === RouterName.EditArticle) {
              store.dispatch('refresh/REFRESH_MY_REGION_ARTICLE_STATUS', {
                reason: 'deleteFile',
              });
            }
          }
        } catch (error) {
          console.error('error', error);
        }
      }
    });
  }
};

// 링크 조회
const getLinks = async ({ isLoadingView = true } = {}) => {
  if (!props.articleId) {
    return;
  }

  try {
    isLoading.value = isLoadingView;
    let response = null;

    if (
      route.name === RouterName.MyArticle ||
      route.name === RouterName.CreateMyArticle
    ) {
      response = await marsLApi.myArticle.link.findAll(props.articleId);
    } else if (
      route.name === RouterName.EditArticle ||
      route.name === RouterName.CreateArticle ||
      route.name === RouterName.MyRegionArticle ||
      route.name === RouterName.ViewArticle ||
      route.name === RouterName.AreaArticle ||
      route.name === RouterName.ShowCueSheetDetail ||
      route.name === RouterName.ShowAreaCueSheetDetail
    ) {
      response = await marsLApi.coverDesk.link.findAll(props.articleId);
    }

    if (response.success) {
      linkList.value = response.data.map(item => {
        return {
          id: item.id,
          order: item.ord,
          linkTitle: item.titl,
          link: item.link,
        };
      });
    }
  } catch (error) {
    console.error(error);
  } finally {
    isLoading.value = false;
  }
};

// 순서 변경
const onPositionChange = async event => {
  const { element, newIndex } = event?.moved;

  let response = null;
  const params = {
    ord: newIndex,
  };
  if (
    route.name === RouterName.MyArticle ||
    route.name === RouterName.CreateMyArticle
  ) {
    response = await marsLApi.myArticle.link.order(
      props.articleId,
      element.id,
      params,
    );
  } else if (route.name === RouterName.EditArticle) {
    response = await marsLApi.coverDesk.link.order(
      props.articleId,
      element.id,
      params,
    );
  }

  if (response.success) {
    if (response.success) {
      window.Notify.success({
        message: '[링크 순서 변경] 완료되었습니다.',
      });
      getLinks({ isLoadingView: false });
    }
  }
};

// 도메인 주소 앞에 표시되지 않고 링크가 열리도록
const setLink = link => {
  let url = '';
  if (link.includes('https://')) {
    url = link.replace('https://', '');
  } else if (link.includes('http://')) {
    url = link.replace('http://', '');
  } else {
    url = link;
  }

  return `//${url}`;
};

onActivated(async () => {
  const refreshStatus = store.getters['refresh/myRegionArticle'];

  if (refreshStatus?.isRefresh) {
    getLinks();

    if (props.routeName === 'coverDesk') {
      store.dispatch('refresh/DELETE_MY_REGION_ARTICLE_REFRESH_STATUS');
    }
  }
});

// 내기사의 경우 onActivated가 계속 살아있는 상태이기 때문에 여기서 처리함
watch(
  () => store.getters['refresh/myArticle'],
  async refreshStatus => {
    if (!refreshStatus?.isRefresh) {
      return;
    }

    getLinks();
    store.dispatch('refresh/DELETE_MY_ARTICLE_REFRESH_STATUS');
  },
);

onMounted(() => {
  getLinks();
});
</script>
