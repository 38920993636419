import { AxiosInstance } from 'axios';
import articleApi from './articleApi';
import cuesheetApi from './cuesheetApi';

export default ($axios: AxiosInstance) => {
  return {
    article: articleApi($axios),
    cuesheet: cuesheetApi($axios),
  };
};
