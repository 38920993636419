import { AxiosInstance } from 'axios';

const PREFIX = '/my-article';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 내기사 송고
     * @param {number} articleId 기사 ID
     */
    move: async (articleId: number) => {
      return $axios
        .put(`${PREFIX}/${articleId}/move`)
        .then(response => response.data);
    },
  };
};
