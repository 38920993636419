<template>
  <div class="inline-flex items-center">
    <input
      v-model="checkedValue"
      :id="label"
      type="checkbox"
      :name="name"
      :value="value"
      :checked="checked"
      :disabled="disabled || readonly"
      :class="[setCls]"
      @change="onChange"
    />
    <label :for="label" class="pl-1" :class="[setCls]">{{ label }}</label>
  </div>
</template>

<script setup>
import { computed, defineProps, ref, toRefs, onMounted, watch } from 'vue';

const props = defineProps({
  modelValue: null,
  value: {
    type: [String, Number, Array],
    default: null,
  },
  label: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  checked: {
    type: Boolean,
    default: false,
  },
  fontSize: {
    type: String,
    default: 'text-xs',
  },
});

const emit = defineEmits(['update:modelValue', 'change']);

const { disabled, checked, value, name, label, readonly } = toRefs(props);
const checkedValue = ref([]);

const onChange = () => {
  const checkGroup = document.getElementsByName(name.value);

  // 체크박스가 한개일 경우 boolean 값만 보낸다.
  if (checkGroup.length === 1) {
    emit('change', checkGroup[0].checked);
    emit('update:modelValue', checkGroup[0].checked);
  } else {
    checkedValue.value = Array.from(checkGroup)
      .filter(el => {
        return el.checked;
      })
      .map(el => el.value);

    emit('change', checkedValue.value);
    emit('update:modelValue', checkedValue.value);
  }
};

const setCls = computed(() => {
  let cls = props.fontSize;

  if (disabled.value) {
    cls = cls + ' cursor-default';
  } else {
    cls = cls + ' cursor-pointer';
  }

  return cls;
});

watch(
  () => props.modelValue,
  newVal => {
    checkedValue.value = newVal;
  },
);

onMounted(() => {
  if (checked.value) {
    checkedValue.value.push(value.value);
  }
});
</script>
