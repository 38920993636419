<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.71078 15.91V17.57H8.03078V15.91H6.71078ZM6.69078 12.79V13.07C6.69078 14.11 5.70078 15.17 3.62078 15.42L4.11078 16.46C6.47078 16.12 7.83078 14.78 7.83078 13.07V12.79H6.69078ZM6.91078 12.79V13.07C6.91078 14.82 8.27078 16.11 10.6308 16.46L11.1208 15.42C9.02078 15.17 8.05078 14.15 8.05078 13.07V12.79H6.91078ZM3.20078 17.05V18.09H11.5708V17.05H3.20078ZM7.37078 18.6C5.36078 18.6 4.15078 19.2 4.15078 20.25C4.15078 21.31 5.36078 21.89 7.37078 21.89C9.38078 21.89 10.5808 21.31 10.5808 20.25C10.5808 19.2 9.38078 18.6 7.37078 18.6ZM7.37078 19.6C8.62078 19.6 9.24078 19.8 9.24078 20.25C9.24078 20.7 8.62078 20.9 7.37078 20.9C6.12078 20.9 5.50078 20.7 5.50078 20.25C5.50078 19.8 6.12078 19.6 7.37078 19.6ZM13.26 13.41V14.48H19.13V13.41H13.26ZM12.41 19.7V20.78H20.78V19.7H12.41ZM15.37 16.52V20.23H16.7V16.52H15.37ZM18.65 13.41V14.3C18.65 15.41 18.65 16.75 18.28 18.59L19.61 18.74C19.98 16.76 19.98 15.46 19.98 14.3V13.41H18.65Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.125 10.0303V9.03027H4C1.79086 9.03027 0 10.8211 0 13.0303V21C0 23.2091 1.79086 25 4 25H20C22.2091 25 24 23.2091 24 21V13.0303C24 10.8211 22.2091 9.03027 20 9.03027H15.75V10.0303H20C21.6569 10.0303 23 11.3734 23 13.0303V21C23 22.6568 21.6569 24 20 24H4C2.34315 24 1 22.6568 1 21V13.0303C1 11.3734 2.34314 10.0303 4 10.0303H7.125Z"
      fill="currentColor"
    />
    <line x1="11.5" y1="12.5386" x2="11.5" y2="2.01324" stroke="currentColor" />
    <line
      y1="-0.5"
      x2="7.65901"
      y2="-0.5"
      transform="matrix(-0.759371 0.650658 -0.530905 -0.847431 11.4998 1)"
      stroke="currentColor"
    />
    <line
      y1="-0.5"
      x2="7.60809"
      y2="-0.5"
      transform="matrix(0.788635 0.614862 -0.495238 0.868757 11.0002 1.7998)"
      stroke="currentColor"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AnchorIcon',
  props: {},
  setup() {
    return {};
  },
});
</script>
