/**
 * 파일 다운로드
 * @param {string} response - api 호출 후 받은 response
 * @param {string} fileName - 다운로드 파일명
 * @param {string} downloadType - 다운로드 확장자
 */
export default function (
  response: string,
  fileName: string,
  downloadType?: string,
) {
  const downloadUrl = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = downloadUrl;

  let name = fileName;
  if (downloadType === 'excel') {
    name = name + '.xlsx';
  } else if (downloadType === 'abc') {
    name = name + '.abc';
  }

  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
