<template>
  <GSingleSelect
    v-model="model"
    name="approve"
    :item-label="itemLabel ? t('ApproveSelect.출고_여부') : ''"
    :options="options"
    track-by="id"
    label="label"
    :disabled="disabled"
    :allow-empty="false"
    :searchable="false"
    :preselect-first="true"
    :placeholder="t('ApproveSelect.출고_여부')"
    @select="onSelectValue"
  />
</template>

<script>
import { useI18n } from 'vue-i18n';
// import { useStore } from 'vuex';
import { useModelWrapper } from '@/hooks/useModelWrapper';
import { GSingleSelect } from '@/components/ui';
import { articleApproveCode } from '@/codes';

export default {
  name: 'ApproveSelect',
  components: {
    GSingleSelect,
  },
  props: {
    modelValue: {
      type: [String, Number, Object],
    },
    allOption: {
      type: Boolean,
      default: false,
    },
    itemLabel: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['select', 'update:modelValue'],

  setup(props, { emit }) {
    const { t } = useI18n();
    // const store = useStore();
    const model = useModelWrapper(props, emit, 'modelValue');
    let options = articleApproveCode.values
      .filter(
        item => item.id !== 'apprv_typ_001' && item.id !== 'apprv_typ_002',
      )
      .sort((a, b) => {
        return a.order - b.order;
      });

    if (props.allOption) {
      options.unshift({
        id: '',
        name: t('ApproveSelect.출고_여부_전체'),
        label: t('ApproveSelect.출고_여부_전체'),
      });
    }

    const onSelectValue = value => {
      emit('select', value);
    };

    return {
      t,
      model,
      options,
      onSelectValue,
    };
  },
};
</script>

<style scoped></style>
