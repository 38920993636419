<template>
  <!-- 항목 추가 -->
  <Menu as="div" class="flex-shrink-0 relative">
    <div>
      <!-- TODO :: 락정보 가져오면 아래 조건으로 변경 -->
      <!-- userInfo.userId !== lckrId -->
      <MenuButton
        class="focus:outline-none"
        :disabled="
          isEndOnAir || mode === 'view' || userInfo.userId === lckrId
            ? true
            : false
        "
      >
        <GButton
          v-tooltip="t('CueSheetItemMenu.New')"
          icon="plus"
          type="secondary"
          :disabled="
            isEndOnAir ||
            mode === 'view' ||
            userInfo.userId === lckrId ||
            isLoading
          "
        />
      </MenuButton>
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        v-if="!isEndOnAir || mode === 'edit' || isLock"
        class="origin-top-right absolute z-50 -right-10 mt-2 w-52 rounded-md shadow-xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <MenuItem
          v-for="(addMenuItem, idx) in addItemMenuItems"
          :key="idx"
          v-slot="{ active }"
        >
          <div
            v-show="addMenuItem.isShow"
            :class="[
              active ? 'bg-gray-100' : '',
              'p-2 sm:text-sm text-gray-700 flex items-center space-x-1 cursor-pointer',
            ]"
            @click="addMenuItem.action"
          >
            <!-- 큐시트 항목 -->
            <GIconButton
              v-if="
                addMenuItem.title === t('CueSheetItemMenu.큐시트_항목_추가')
              "
              icon="document"
              :not-outline="true"
            />

            <!-- 큐시트 템플릿 아이템 갯수 표시 -->
            <GIconButton
              v-if="
                addMenuItem.title === t('CueSheetItemMenu.큐시트_템플릿_추가')
              "
              :icon="
                cueSheetTemplateItemList.length
                  ? 'document-arrow-download'
                  : 'document'
              "
              :not-outline="true"
            />

            <!-- 큐시트 기사 -->
            <GIconButton
              v-if="
                addMenuItem.title === t('CueSheetItemMenu.큐시트_기사_추가')
              "
              icon="document-text"
              :not-outline="true"
            />

            <!-- 타이틀 표시 -->
            <div class="inline-block">{{ addMenuItem.title }}</div>
          </div>
        </MenuItem>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
import { defineProps, defineEmits, defineExpose, ref } from 'vue';
import { MenuItems, MenuItem, Menu, MenuButton } from '@headlessui/vue';
import { GButton, GIconButton } from '@/components/ui';
import cuesheetStateCode from '@/codes/cuesheet-state-code';
import { useI18n } from 'vue-i18n';
import { useUserInfo } from '@/hooks/use-user-info';
import { useMarsLApi } from '@/apis/mars-l-api';

const props = defineProps({
  mode: {
    type: String,
    default: 'view',
  },
  state: {
    type: String,
    default: '',
  },
  brdcPgmId: {
    type: String,
    default: '',
  },
  lckrId: {
    type: String,
    default: '',
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  isEndOnAir: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['on-add-cue-item', 'on-add-cue-template']);

const { t } = useI18n();

const userInfo = useUserInfo();
const marsLApi = useMarsLApi();
const cueSheetTemplateItemList = ref([]);

// ==================== 추가 메뉴 ====================
const addItemMenuItems = [
  {
    title: t('CueSheetItemMenu.큐시트_항목_추가'),
    isShow: () => {
      return true;
    },
    action: async () => {
      emit('on-add-cue-item');

      //   isShowCuesheetItemConfirmModal.value = true;
    },
  },
  {
    title: t('CueSheetItemMenu.큐시트_템플릿_추가'),
    isShow: () => {
      return true;
    },
    action: async () => {
      emit('on-add-cue-template');
      // 방송중일 경우 추가할 수 없다.
      if (props.state === cuesheetStateCode.OnAir) {
        // isShowCantAddItemModal.value = true;
        // confirmModalDatas.value = {
        //   type: 'confirm',
        //   icon: 'warning',
        //   cancelBtn: false,
        //   message: `<div><div><span class="text-red font-D2CodingBold">방송중</span>일 경우</div><div>큐시트에 템플릿을 추가할 수 없습니다.</div></div>`,
        // };
        return;
      } else {
        // isShowAddAllTemplateConfirmModal.value = true;
        // confirmModalDatas.value = {
        //   type: 'confirm',
        //   icon: 'warning',
        //   message: `<div>${t('템플릿을 추가하시겠습니까?')}</div>`,
        // };
      }
    },
  },
  //   {
  //     title: '큐시트 기사 추가',
  //     isShow: () => {
  //       return true;
  //     },
  //     action: async () => {
  //       // 방송중일 경우 추가할 수 없다.
  //       if (status.value === CuesheetStatus.ON_AIR) {
  //         isShowCantAddItemModal.value = true;
  //         confirmModalDatas.value = {
  //           type: 'confirm',
  //           icon: 'warning',
  //           cancelBtn: false,
  //           message: `<div><div><span class="text-red font-D2CodingBold">방송중</span>일 경우</div><div>큐시트에 기사를 추가할 수 없습니다.</div></div>`,
  //         };
  //         return;
  //       }

  //       cuesheetItemDiv.value = CuesheetItems.CUE_ARTICLE;
  //       isShowArticleModeModal.value = true;
  //       isCreateArticle.value = true;
  //     },
  //   },
];

// 큐시트 템플릿 조회
//brdcPgmId
const getCuesheetTemplate = async () => {
  try {
    const response = await marsLApi.template.basic.findAll(props.brdcPgmId);

    if (response.length) {
      getCuesheetTemplateItemList(response[0].cueTmpltId);
    }
  } catch (error) {
    console.log(error);
  }
};

// 큐시트 템플릿 아이템 목록 조회
const getCuesheetTemplateItemList = async cueTmpltId => {
  try {
    const response = await marsLApi.template.detail.findAll(cueTmpltId);
    console.log('template Item res : ', response);
    cueSheetTemplateItemList.value = [];
  } catch (error) {
    console.log(error);
  }
};

getCuesheetTemplate();

defineExpose({
  getCuesheetTemplate,
  cueSheetTemplateItemList,
});
</script>

<style></style>
