<template>
  <div class="w-full border-b mt-1 select-none px-1">
    <div class="flex items-center space-x-1">
      <div
        v-for="tab in tabs"
        :key="tab.name"
        class="max-w-[13.125rem] truncate flex items-center bg-white p-2 text-sm cursor-pointer border-b-2 border-transparent hover:border-primary-middle"
        :class="
          tab.active ? 'border-b-2 border-b-primary-middle' : 'text-gray-400'
        "
        :title="tab.title"
        @click="onRoute(tab)"
        @click.middle="tabs.length > 1 ? onCloseTab($event, tab) : false"
      >
        <!-- <component
          :is="tab.icon"
          v-if="tab.icon"
          aria-hidden="true"
          class="h-5 w-5 mr-2"
        /> -->
        <div class="truncate">{{ tab.title }}</div>
        <div :class="tabs.length > 1 ? 'w-4' : ''">
          <XMarkIcon
            v-if="tabs.length > 1"
            class="h-4 w-4 ml-1.5 rounded-full px-px py-px hover:bg-gray-200"
            @click="onCloseTab($event, tab)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { RouterName } from '@/types/router';
import {
  // NewspaperIcon,
  // InboxIcon,
  // ChatBubbleBottomCenterTextIcon,
  // SquaresPlusIcon,
  // ServerIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline';
// import {
//   CalendarDaysIcon,
//   FileCopyIcon,
//   CalendarMinusIcon,
//   CorporateFareIcon,
//   UserVoiceIcon,
//   BoundingBoxIcon,
//   HddRackIcon,
//   HddNetworkIcon,
//   JournalTextIcon,
//   ChalkBoardIcon,
//   ComputerIcon,
//   MonitoringIcon,
// } from '@/components/ui/icon';

export default defineComponent({
  name: 'TabList',
  components: {
    XMarkIcon,
    // GIconButton,
    // NewspaperIcon,
    // InboxIcon,
    // ChatBubbleBottomCenterTextIcon,
    // SquaresPlusIcon,
    // MonitoringIcon,
    // ServerIcon,
    // CalendarDaysIcon,
    // FileCopyIcon,
    // CalendarMinusIcon,
    // CorporateFareIcon,
    // UserVoiceIcon,
    // BoundingBoxIcon,
    // HddRackIcon,
    // HddNetworkIcon,
    // JournalTextIcon,
    // ChalkBoardIcon,
    // ComputerIcon,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const tabs = store.state.tab.tabs;

    // 탭 닫기
    const onCloseTab = async (event, tab) => {
      // 상위 이벤트가 실행되지 않도록 한다.
      if (event) {
        event.stopPropagation();
      }

      store.dispatch('tab/DELETE_TAB', tab);
    };

    // 선택한 탭 처리
    const onRoute = tab => {
      // 라우터 이동
      if (tab.name === RouterName.CreateArticle) {
        router.push({ name: tab.name, params: { sequence: tab.sequence } });
      } else if (
        tab.name === RouterName.EditArticle ||
        tab.name === RouterName.ViewArticle
      ) {
        router.push({
          name: tab.name,
          params: { id: tab.id },
          query: { target: tab?.query?.target, title: tab?.query?.title },
        });
      } else if (
        tab.name === RouterName.ShowAreaCueSheetDetail ||
        tab.name === RouterName.ShowSeoulMBCCueSheetDetail ||
        tab.name === RouterName.ShowCueSheetTemplateDetail ||
        tab.name === RouterName.ShowNoticeDetail ||
        tab.name === RouterName.EditNoticeDetail ||
        tab.name === RouterName.ShowJournalistDetail ||
        tab.name === RouterName.EditJournalistDetail
      ) {
        router.push({ name: tab.name, params: { id: tab.id } });
      } else if (
        tab.name === RouterName.ShowBoardDetail ||
        tab.name === RouterName.EditBoardDetail
      ) {
        router.push({
          name: tab.name,
          params: { id: tab.id, subId: tab.subId },
          query: tab.query,
        });
      } else if (tab.name === RouterName.ShowCueSheetDetail) {
        router.push({
          name: tab.name,
          params: { id: tab.id },
          query: tab.query,
        });
      } else {
        router.push({ name: tab.name });
      }
    };

    return {
      t,
      tabs,
      onCloseTab,
      onRoute,
    };
  },
});
</script>
