import { Code, EnumEntry } from './base';

class articleCategoryCode extends EnumEntry<Code> {
  // TODO :: 카테고리 코드 정해지면 수정해야함
  etc: Code = {
    id: 'cate_001',
    name: '기타',
    label: '기타',
  };

  politics: Code = {
    id: 'cate_002',
    name: '정치',
    label: '정치',
  };

  economy: Code = {
    id: 'cate_003',
    name: '경제',
    label: '경제',
  };
  society: Code = {
    id: 'cate_004',
    name: '사회',
    label: '사회',
  };
  culture: Code = {
    id: 'cate_005',
    name: '문화',
    label: '문화',
  };

  values: Array<Code> = [
    this.etc,
    this.politics,
    this.economy,
    this.society,
    this.culture,
  ];
}

export default new articleCategoryCode();
