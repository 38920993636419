<template>
  <GButton
    v-if="buttonType === 'normal'"
    ref="buttonRef"
    :icon="icon"
    :disabled="disabled"
    :loading="loading"
    :text="text"
    @click="onClicked"
  />
  <GIconButton
    v-else-if="buttonType === 'icon'"
    ref="buttonRef"
    :icon="icon"
    :disabled="disabled"
    :icon-size="iconSize"
    :icon-color="iconColor"
    :outline="outline"
    :hover="hover"
    :padding="padding"
    :text="text"
    @click="onClicked"
  />
</template>

<script setup>
import { ref } from 'vue';
import { GButton, GIconButton } from '@/components/ui';
import { useSweetAlert } from '@/hooks/use-sweet-alert';

const props = defineProps({
  icon: {
    type: String,
    default: '',
  },
  iconSize: {
    type: Number,
  },
  iconColor: {
    type: String,
  },
  buttonType: {
    type: String,
    default: 'normal',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  outline: {
    type: Boolean,
  },
  hover: {
    type: Boolean,
  },
  padding: {
    type: String,
  },
  title: {
    type: String,
  },
  html: {
    type: String,
  },
  showConfirmButton: {
    type: Boolean,
    default: true,
  },
  confirmButtonText: {
    type: String,
  },
  showDenyButton: {
    type: Boolean,
  },
  denyButtonText: {
    type: String,
  },
  denyButtonColor: {
    type: String,
  },
  confirmButtonColor: {
    type: String,
  },
  text: {
    type: String,
  },
  disabledButtons: {
    type: Array,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  input: {
    type: String,
    default: '',
  },
  inputPlaceholder: {
    type: String,
    default: '',
  },
  returnInputValueOnDeny: {
    type: Boolean,
    default: false,
  },
  inputValue: {
    type: [String, Number, Boolean],
  },
});

const emit = defineEmits(['on-confirm', 'on-cancel', 'on-deny']);
const { swalConfirm } = useSweetAlert();

const buttonRef = ref(null);

const onClicked = () => {
  // sweetAlert의 확인 버튼에 포커스가 가지 않는 경우가 있어서 GButton에 blur처리함
  buttonRef.value.$el.blur();

  swalConfirm({
    title: props.title,
    html: props.html,
    input: props.input,
    inputPlaceholder: props.inputPlaceholder,
    inputValue: props.inputValue,
    returnInputValueOnDeny: props.returnInputValueOnDeny,
    showConfirmButton: props.showConfirmButton,
    showDenyButton: props.showDenyButton,
    confirmButtonText: props.confirmButtonText,
    denyButtonText: props.denyButtonText,
    denyButtonColor: props.denyButtonColor,
    confirmButtonColor: props.confirmButtonColor,
    disabledButtons: props.disabledButtons,
  }).then(res => {
    if (res === 'confirm' || res.state === 'confirm') {
      emit('on-confirm', res.result);
    } else if (res === 'dismiss') {
      emit('on-cancel');
    } else if (res === 'deny' || res.state === 'deny') {
      emit('on-deny', res.result);
    }
  });
};
</script>
