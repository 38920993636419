<template>
  <div v-if="item" v-tooltip="setTooltip">
    <component :is="iconComponent" :class="size" />
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import {
  ReportTypeIcon,
  StraightTypeIcon,
  CrossTalkTypeIcon,
  BottomRoleTypeIcon,
} from '@/components/ui/icon';
import { articleTypeCode } from '@/codes';

export default defineComponent({
  name: 'ArticleTypeUI',
  components: {
    ReportTypeIcon,
    StraightTypeIcon,
    CrossTalkTypeIcon,
    BottomRoleTypeIcon,
  },
  props: {
    item: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const setTooltip = computed(() => {
      let tooltip = '';

      switch (props.item) {
        case articleTypeCode.Straight.id:
          tooltip = articleTypeCode.Straight.label;
          break;

        case articleTypeCode.Report.id:
          tooltip = articleTypeCode.Report.label;
          break;

        case articleTypeCode.CrossTalk.id:
          tooltip = articleTypeCode.CrossTalk.label;
          break;

        case articleTypeCode.BottomRole.id:
          tooltip = articleTypeCode.BottomRole.label;
          break;
      }

      return tooltip;
    });

    const iconComponent = computed(() => {
      let icon = '';

      switch (props.item) {
        case articleTypeCode.Straight.id:
          icon = 'StraightTypeIcon';
          break;

        case articleTypeCode.Report.id:
          icon = 'ReportTypeIcon';
          break;

        case articleTypeCode.CrossTalk.id:
          icon = 'CrossTalkTypeIcon';
          break;
        case articleTypeCode.BottomRole.id:
          icon = 'BottomRoleTypeIcon';
          break;
      }
      return icon;
    });

    return {
      articleTypeCode,
      setTooltip,
      iconComponent,
    };
  },
});
</script>
