<template>
  <GModal
    :changed="false"
    :dialog-overlay="false"
    max-width="w-[60rem]"
    @close="onClose"
  >
    <!-- title -->
    <template #title>
      <span class="text-white font-D2CodingBold">{{
        t('Modal.Article.PortalNewsModal.포탈_전송')
      }}</span>
    </template>

    <!-- 내용 -->
    <template #content>
      <!-- 경고 문구 -->
      <div
        v-if="article?.exportYn === 'Y'"
        class="px-5 bg-red-50 border-t-4 border-red-300 text-red-500 font-D2CodingBold py-1 rounded-b-lg mb-2"
        role="alert"
      >
        <div class="flex items-center">
          <div class="py-1">
            <svg
              class="fill-current h-6 w-6 text-orange-400 mr-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
              />
            </svg>
          </div>
          <div>
            <p class="text-xs font-D2CodingBold">
              {{ t('Modal.Article.PortalNewsModal.이미_전송된_기사입니다') }}
            </p>
          </div>
        </div>
      </div>

      <div class="px-5 pb-5 pt-3 space-y-4 text-xs">
        <div class="flex space-x-5">
          <!-- 왼쪽 -->
          <div class="flex-1 space-y-2.5">
            <div class="space-y-1">
              <div>{{ t('Modal.Article.PortalNewsModal.제목') }}</div>
              <GInput v-model="title" />
            </div>

            <div class="space-y-1">
              <div>{{ t('Modal.Article.PortalNewsModal.내용') }}</div>
              <GTextarea v-model="content" :rows="16" font-size="text-sm" />
            </div>

            <div>
              <div class="flex space-x-2">
                <WorkerSelect
                  v-model="reporter"
                  item-label
                  width="w-1/2"
                  required
                  :company-code="companyId"
                />
                <div class="w-1/2">
                  <GInput
                    v-model="reporterEmail"
                    required
                    :disabled="true"
                    :label="t('Modal.Article.PortalNewsModal.기자_이메일')"
                  />
                </div>
              </div>
              <div
                v-if="isNonEmail"
                class="text-red-500 pt-1 pb-2 font-D2CodingBold"
              >
                * {{ emailError }}
              </div>
            </div>

            <div class="space-y-1">
              <div>{{ t('Modal.Article.PortalNewsModal.배포') }}</div>
              <div class="space-y-1.5 px-2 py-3 rounded-md bg-gray-100">
                <GRadioButton
                  v-model="releaseType"
                  name="releaseType"
                  :label="t('Modal.Article.PortalNewsModal.즉시_배포')"
                  value="immediately"
                  font-size=""
                  :default="true"
                  @change="onChangeRadio"
                />
                <div class="flex items-center space-x-3">
                  <GRadioButton
                    v-model="releaseType"
                    name="releaseType"
                    :label="t('Modal.Article.PortalNewsModal.예약_배포')"
                    value="reservation"
                    font-size=""
                    @change="onChangeRadio"
                  />
                  <DateTimePickerEx
                    v-if="releaseType === 'reservation'"
                    v-model="dateTime"
                    name="dateTime"
                    width="w-40"
                    @change="onChangeDateTime"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- 오른쪽 -->
          <div class="flex-1">
            <GTabPanel v-if="tabs?.length" :tabs="tabs" @change="onChangedTab">
              <div class="pt-2">
                <TabPanel
                  v-for="(tab, index) in tabs"
                  :key="index"
                  :unmount="false"
                >
                  <PortalNews
                    :selected-tab="tab"
                    @submit-data="onSetSubmitData"
                    @is-valid="onCheckedValid"
                  />
                </TabPanel>
              </div>
            </GTabPanel>
          </div>
        </div>

        <!-- 버튼 -->
        <div class="space-x-3 flex justify-end pt-2">
          <GButton type="secondary" @click="onClose">{{
            t('Modal.Article.PortalNewsModal.닫기')
          }}</GButton>
          <GButton
            :disabled="isDisabled"
            :loading="saveLoading"
            @click="onSubmit"
            >{{ t('Modal.Article.PortalNewsModal.전송') }}</GButton
          >
          <GButton
            v-if="article?.exportYn === 'Y'"
            :loading="deleteLoading"
            :disabled="saveLoading"
            custom-cls="bg-red-500 hover:bg-red-700 outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            @click="onDelete"
            >{{ t('Modal.Article.PortalNewsModal.삭제') }}</GButton
          >
        </div>
      </div>
    </template>
  </GModal>
</template>

<script setup>
import { defineProps, defineEmits, ref, onMounted, computed, watch } from 'vue';
import {
  GModal,
  GButton,
  GInput,
  GTextarea,
  GRadioButton,
} from '@/components/ui';
import DateTimePickerEx from '@/components/common/DateTimePickerEx';
import { useI18n } from 'vue-i18n';
import { setEditorValues } from '@/utils/editor-helper';
import { concatArrays } from '@/utils/print';
import { useMarsLApi } from '@/apis/mars-l-api';
import { defaultDateTime, defaultDateTimeHm } from '@/utils/format';
import { useUserInfo } from '@/hooks/use-user-info';
import { GTabPanel } from '@/components/ui';
import { TabPanel } from '@headlessui/vue';
import PortalNews from '@/components/portalNews/PortalNews.vue';
import { WorkerSelect } from '@/components/select';
import { useSweetAlert } from '@/hooks/use-sweet-alert';

const props = defineProps({
  article: {
    type: Object,
    default: () => {
      return {};
    },
  },
  companyInfo: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

const emit = defineEmits(['close']);

const { t } = useI18n();
const marsLApi = useMarsLApi();
const { companyId } = useUserInfo();
const { swalConfirm } = useSweetAlert();

const saveLoading = ref(false);
const deleteLoading = ref(false);
const title = ref('');
const content = ref(null);
const reporter = ref(null);
const reporterEmail = ref(null);
const releaseType = ref('immediately');
const dateTime = ref(defaultDateTime());
const tabs = ref([]);
const selectedTab = ref(null);
const submitData = ref([]);
const emailError = t(
  'Modal.Article.PortalNewsModal.이메일이_없을_경우_포탈_전송을_할_수_없습니다',
);

const isNonEmail = computed(() => {
  return !reporterEmail.value ? true : false;
});
const invalidNewsTab = ref(null);

const isDisabled = computed(() => {
  if (!reporterEmail.value) {
    return true;
  } else if (!submitData.value.length) {
    return true;
  } else if (
    submitData.value.length &&
    submitData.value.some(item => {
      return !item.portalCategory;
    })
  ) {
    return true;
  } else if (invalidNewsTab.value) {
    if (invalidNewsTab.value.invalid) {
      return true;
    }
  }
  return false;
});

watch(
  () => reporter.value,
  (newVal, oldVal) => {
    if (newVal && oldVal && newVal !== oldVal) {
      reporterEmail.value = newVal.email;
    }
  },
);

const onSetSubmitData = data => {
  const dataIndex = submitData.value.findIndex(item => {
    return item.id === selectedTab.value?.id;
  });

  if (dataIndex < 0) {
    submitData.value.push(data);
  } else {
    submitData.value[dataIndex] = {
      ...data,
    };
  }
};

// 포탈 전송
const onSubmit = async () => {
  try {
    console.log('전송!');
    let params = {
      artclId: props.article.id,
      artclTitl: title.value,
      artclCtt: content.value,
      rptrId: reporter.value.id,
      reserve_time:
        releaseType.value === 'immediately'
          ? null
          : defaultDateTimeHm(dateTime.value),
      category: null,
      imageUrls: [],
      mediaUrls: [],
    };

    for (let i = 0, len = tabs.value.length; i < len; i++) {
      saveLoading.value = true;
      const findSubmitData = submitData.value.find(
        item => item.id === tabs.value[i].id,
      );

      const imageUrls = [findSubmitData?.thumbnailFileName].filter(
        item => item,
      );
      const mediaUrls = [
        findSubmitData?.videoFileName,
        findSubmitData?.newVideoLink,
      ].filter(item => item);
      params = {
        ...params,
        category: findSubmitData?.portalCategory.id,
        imageUrls,
        mediaUrls,
      };

      console.log('params', params);

      if (tabs.value[i].id === 'naver') {
        try {
          const response = await marsLApi.portalNews.basic.naverNews(params);
          if (response.success) {
            console.log('네이버 뉴스 전송 완료', response.data);
            window.Notify.success({
              message: '네이버 포탈 전송이 완료되었습니다.',
            });
          }
        } catch (error) {
          console.error('error', error);
          break;
        }
      } else if (tabs.value[i].id === 'kakao') {
        try {
          const response = await marsLApi.portalNews.basic.kakaoNews(params);
          if (response.success) {
            console.log('카카오 뉴스 전송 완료', response.data);
            window.Notify.success({
              message: '카카오 포탈 전송이 완료되었습니다.',
            });
          }
        } catch (error) {
          console.error('error', error);
          break;
        }
      }
    }
  } catch (error) {
    console.error('error', error);
  } finally {
    saveLoading.value = false;
  }
};

// 포탈 삭제
const onDelete = () => {
  swalConfirm({
    title: title.value,
    icon: undefined,
    iconColor: '#ff0303',
    iconHtml:
      '<svg class="h-24 w-24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.043 21H3.957c-1.538 0-2.5-1.664-1.734-2.997l8.043-13.988c.77-1.337 2.699-1.337 3.468 0l8.043 13.988C22.543 19.336 21.58 21 20.043 21zM12 9v4" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path><path d="M12 17.01l.01-.011" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>',
    html: `<div>을(를) 삭제하시겠습니까?</div>`,
    customClass: {
      icon: 'no-border',
    },
    confirmButtonColor: '#ff0303',
    confirmButtonText: '삭제',
  }).then(res => {
    if (res === 'confirm') {
      try {
        deleteLoading.value = true;
        console.log('삭제');
      } catch (error) {
        console.error('error', error);
      } finally {
        deleteLoading.value = false;
      }
    }
  });
};

const onChangeRadio = event => {
  releaseType.value = event.target.value;
};

const onChangeDateTime = dateTimeStr => {
  dateTime.value = dateTimeStr;
};

const onChangedTab = tabIndex => {
  selectedTab.value = tabs.value[tabIndex];
};

const onCheckedValid = validTab => {
  invalidNewsTab.value = validTab;
};

// 모달 닫기
const onClose = () => {
  emit('close');
};

const init = article => {
  console.log('선택한 기사', article);

  const { chKakaoYn, chNaverYn } = props.companyInfo;

  if (chNaverYn === 'Y') {
    tabs.value.push({ id: 'naver', name: '네이버' });
  }
  if (chKakaoYn === 'Y') {
    tabs.value.push({ id: 'kakao', name: '카카오' });
  }

  selectedTab.value = tabs.value[0];
  submitData.value = tabs.value.map(tab => {
    return {
      id: tab.id,
    };
  });

  title.value = props.article.artclTitl;
  reporter.value = props.article.rptrId;

  const articleEditor = setEditorValues({
    artclTypCd: props.article.artclTypCd,
    anchorCapList: [],
    articleCapList: [],
    ancMentCtt: props.article.ancMentCtt || JSON.stringify(['\n']),
    artclCtt: props.article.artclCtt || JSON.stringify(['\n']),
  });

  content.value = concatArrays(
    articleEditor.anchorTextArray,
    articleEditor.reporterTextArray,
    props.article.artclTypCd,
  ).join('\n');
};

onMounted(() => {
  init();
});
</script>
