<template>
  <div>
    <!-- 기사 출고 + 큐시트 매칭 아이콘 -->
    <div v-if="type === 'articleApprovAndCuesheet'" class="relative">
      <svg
        class="h-6 w-6"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.71019 11.29C9.52188 11.1017 9.26649 10.9959 9.00019 10.9959C8.73388 10.9959 8.47849 11.1017 8.29019 11.29C8.10188 11.4783 7.99609 11.7337 7.99609 12C7.99609 12.2663 8.10188 12.5217 8.29019 12.71L11.2902 15.71C11.3836 15.8027 11.4944 15.876 11.6163 15.9258C11.7381 15.9755 11.8686 16.0008 12.0002 16C12.1371 15.9957 12.2716 15.9632 12.3955 15.9047C12.5193 15.8463 12.6299 15.763 12.7202 15.66L19.7202 7.65999C19.8818 7.45932 19.9597 7.204 19.9377 6.94728C19.9157 6.69055 19.7955 6.45223 19.602 6.282C19.4086 6.11178 19.1569 6.02279 18.8995 6.0336C18.642 6.0444 18.3987 6.15416 18.2202 6.33999L12.0002 13.54L9.71019 11.29Z"
          fill="#0061FF"
        />
        <path
          d="M20.9998 11C20.7346 11 20.4802 11.1054 20.2927 11.2929C20.1052 11.4804 19.9998 11.7348 19.9998 12C19.9998 14.1217 19.157 16.1566 17.6567 17.6569C16.1564 19.1571 14.1215 20 11.9998 20C10.4199 19.9993 8.87558 19.5308 7.56158 18.6535C6.24757 17.7763 5.22274 16.5297 4.61629 15.0708C4.00985 13.6119 3.84896 12.0061 4.1539 10.4559C4.45885 8.90569 5.21597 7.48048 6.32981 6.36C7.07052 5.60938 7.95349 5.01404 8.92707 4.60881C9.90065 4.20358 10.9453 3.99661 11.9998 4C12.6393 4.004 13.2764 4.07777 13.8998 4.22C14.0303 4.26035 14.1676 4.27356 14.3034 4.2588C14.4392 4.24404 14.5705 4.20163 14.6892 4.13418C14.808 4.06673 14.9116 3.97567 14.9938 3.86662C15.076 3.75757 15.135 3.63284 15.1672 3.50011C15.1994 3.36739 15.204 3.22948 15.1808 3.0949C15.1576 2.96032 15.1071 2.83191 15.0324 2.71759C14.9577 2.60327 14.8604 2.50547 14.7464 2.43021C14.6324 2.35495 14.5043 2.30383 14.3698 2.28C13.593 2.09724 12.7979 2.00331 11.9998 2C10.0242 2.01026 8.0958 2.60552 6.45811 3.71065C4.82042 4.81578 3.54678 6.38126 2.79793 8.20953C2.04908 10.0378 1.85857 12.0469 2.25045 13.9834C2.64232 15.9198 3.59902 17.6968 4.99981 19.09C6.8565 20.9475 9.37347 21.9939 11.9998 22C14.652 22 17.1955 20.9464 19.0709 19.0711C20.9462 17.1957 21.9998 14.6522 21.9998 12C21.9998 11.7348 21.8945 11.4804 21.7069 11.2929C21.5194 11.1054 21.265 11 20.9998 11Z"
          fill="#166534"
        />
      </svg>
    </div>

    <!-- 기사,자막 출고 + 큐시트 매칭 아이콘 -->
    <div
      v-else-if="type === 'articleCaptionApproveAndCuesheet'"
      class="relative"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.0001 11C20.7348 11 20.4805 11.1054 20.2929 11.2929C20.1054 11.4804 20.0001 11.7348 20.0001 12C20.0001 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1218 20 12.0001 20C10.4201 19.9993 8.87582 19.5308 7.56182 18.6535C6.24782 17.7763 5.22298 16.5297 4.61654 15.0708C4.0101 13.6119 3.8492 12.0061 4.15415 10.4559C4.45909 8.90569 5.21622 7.48048 6.33006 6.36C7.07076 5.60938 7.95374 5.01404 8.92731 4.60881C9.90089 4.20358 10.9455 3.99661 12.0001 4C12.6395 4.004 13.2766 4.07777 13.9001 4.22C14.0305 4.26035 14.1679 4.27356 14.3036 4.2588C14.4394 4.24404 14.5707 4.20163 14.6895 4.13418C14.8082 4.06673 14.9119 3.97567 14.9941 3.86662C15.0763 3.75757 15.1353 3.63284 15.1674 3.50011C15.1996 3.36739 15.2042 3.22948 15.181 3.0949C15.1579 2.96032 15.1074 2.83191 15.0326 2.71759C14.9579 2.60327 14.8606 2.50547 14.7466 2.43021C14.6327 2.35495 14.5045 2.30383 14.3701 2.28C13.5932 2.09724 12.7981 2.00331 12.0001 2C10.0244 2.01026 8.09604 2.60552 6.45835 3.71065C4.82066 4.81578 3.54703 6.38126 2.79818 8.20953C2.04932 10.0378 1.85882 12.0469 2.25069 13.9834C2.64257 15.9198 3.59927 17.6968 5.00006 19.09C6.85675 20.9475 9.37371 21.9939 12.0001 22C14.6522 22 17.1958 20.9464 19.0711 19.0711C20.9465 17.1957 22.0001 14.6522 22.0001 12C22.0001 11.7348 21.8947 11.4804 21.7072 11.2929C21.5196 11.1054 21.2653 11 21.0001 11Z"
          fill="#166534"
        />
        <path
          d="M16.1576 6.91634C16.0756 6.85145 15.9816 6.80344 15.881 6.77507C15.7804 6.7467 15.6752 6.73854 15.5714 6.75105C15.4676 6.76357 15.3674 6.79651 15.2764 6.84797C15.1854 6.89944 15.1055 6.96842 15.0413 7.05092L9.49966 14.1759L6.78424 10.8668C6.72055 10.782 6.64047 10.7108 6.54876 10.6576C6.45705 10.6044 6.35558 10.5701 6.25037 10.5568C6.14516 10.5435 6.03836 10.5515 5.9363 10.5803C5.83424 10.6091 5.73901 10.6581 5.65625 10.7245C5.5735 10.7908 5.50491 10.873 5.45456 10.9663C5.4042 11.0597 5.37311 11.1622 5.36313 11.2677C5.35315 11.3733 5.36448 11.4798 5.39645 11.5809C5.42842 11.682 5.48038 11.7757 5.54924 11.8563L8.85049 15.9572C8.925 16.049 9.01915 16.123 9.12601 16.1737C9.23286 16.2244 9.34972 16.2505 9.46799 16.2501C9.59353 16.2557 9.7186 16.2313 9.83286 16.179C9.94712 16.1267 10.0473 16.0479 10.1251 15.9493L16.3238 8.03259C16.387 7.94869 16.4329 7.85306 16.4588 7.75126C16.4846 7.64946 16.49 7.54353 16.4745 7.43964C16.459 7.33575 16.4231 7.23597 16.3686 7.14613C16.3142 7.05629 16.2425 6.97817 16.1576 6.91634Z"
          fill="#166534"
        />
        <path
          d="M20.1159 6.91634C20.0339 6.85145 19.9399 6.80344 19.8393 6.77507C19.7387 6.7467 19.6335 6.73854 19.5297 6.75105C19.4259 6.76357 19.3257 6.79651 19.2347 6.84797C19.1437 6.89944 19.0638 6.96842 18.9996 7.05092L13.458 14.1759L12.975 13.5822L11.9775 14.8647L12.8484 15.9493C12.9229 16.0411 13.017 16.1151 13.1239 16.1658C13.2307 16.2165 13.3476 16.2426 13.4659 16.2422C13.5848 16.2416 13.702 16.2143 13.8089 16.1622C13.9159 16.1101 14.0096 16.0346 14.0834 15.9413L20.2821 8.02467C20.3441 7.94102 20.3889 7.84597 20.4141 7.74496C20.4393 7.64395 20.4443 7.53897 20.4289 7.43602C20.4134 7.33307 20.3778 7.23418 20.3241 7.14501C20.2704 7.05583 20.1996 6.97813 20.1159 6.91634Z"
          fill="#0061FF"
        />
        <path
          d="M9.89536 12.3392L10.9166 11.0567L10.7583 10.8667C10.6958 10.7801 10.6164 10.707 10.5249 10.6519C10.4335 10.5968 10.3318 10.5608 10.226 10.546C10.1202 10.5312 10.0126 10.5379 9.9095 10.5658C9.80642 10.5937 9.71005 10.6422 9.62619 10.7083C9.54486 10.7737 9.47725 10.8544 9.42724 10.946C9.37723 11.0375 9.3458 11.138 9.33476 11.2418C9.32373 11.3455 9.3333 11.4504 9.36294 11.5504C9.39257 11.6504 9.44168 11.7436 9.50744 11.8246L9.89536 12.3392Z"
          fill="#0061FF"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ApproveAndCuesheetMatchingIcon',
  props: {
    type: {
      type: String,
      default: '',
    },
  },

  setup() {
    return {};
  },
});
</script>
