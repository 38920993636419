import { AxiosInstance } from 'axios';

const PREFIX = '/article/portal-news';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 네이버 포탈뉴스 전송
     * @param {object} option request body
     * @param {number} option.artclId 기사ID
     * @param {string} option.artclTitl 기사 제목
     * @param {string} option.artclCtt 기사 내용
     * @param {number} option.rptrId 기자ID
     * @param {string} option.reserve_time 배포날짜
     * @param {string} option.category 카테고리
     * @param {array} option.imageUrls 썸네일 파일명
     * @param {array} option.mediaUrls 동영상 파일명, 링크
     */
    naverNews: async (params: any) => {
      return $axios
        .post(`${PREFIX}/naver-news`, params)
        .then(response => response.data);
    },

    /**
     * 카카오 포탈뉴스 전송
     * @param {object} option request body
     * @param {number} option.artclId 기사ID
     * @param {string} option.artclTitl 기사 제목
     * @param {string} option.artclCtt 기사 내용
     * @param {number} option.rptrId 기자ID
     * @param {string} option.reserve_time 배포날짜
     * @param {string} option.category 카테고리
     * @param {array} option.imageUrls 썸네일 파일명
     * @param {array} option.mediaUrls 동영상 파일명, 링크
     */
    kakaoNews: async (params: any) => {
      return $axios
        .post(`${PREFIX}/kakao-news`, params)
        .then(response => response.data);
    },
  };
};
