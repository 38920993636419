<template>
  <nav class="bg-white shadow">
    <div class="flex flex-col px-4">
      <div class="relative flex items-center justify-between">
        <div class="absolute inset-y-0 left-0 flex items-center md:hidden">
          <!-- Mobile menu button-->
          <button
            v-if="!isShowMenu"
            class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            @click="fetchMenuState(true)"
          >
            <Bars3Icon class="block h-6 w-6" />
          </button>
          <button
            v-else
            class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            @click="fetchMenuState(false)"
          >
            <XMarkIcon class="block h-6 w-6" />
          </button>
        </div>
        <div
          class="flex flex-1 items-center justify-center pl-[50px] md:pl-0 md:items-stretch md:justify-start"
        >
          <TheLogo class="h-8 mb-2 sm:py-1.5 sm:h-10" />
          <div class="hidden md:pl-6 md:block">
            <div
              class="flex flex-shrink-0 space-x-1 lg:space-x-2 xl:space-4 2xl:space-x-6"
            >
              <router-link
                v-for="nav in navigation"
                :key="nav.name"
                :to="nav.path"
                :target="nav.target"
                class="h-12 px-2 relative group hover:text-primary text-gray-900 flex items-center cursor-pointer whitespace-nowrap"
                :class="isActive(nav) ? 'text-primary' : ''"
              >
                <span>{{ nav.title }}</span>
                <div
                  v-if="nav.children"
                  class="w-40 absolute top-12 -left-[0.063rem] hidden group-hover:block border border-t-1 rounded-b-lg shadow-md bg-white text-gray-900 z-50"
                >
                  <router-link
                    v-for="child in nav.children"
                    :key="child.name"
                    :to="child.path"
                    class="flex flex-col p-2.5 hover:text-primary"
                    @click="onClickedSubMenu($event, child)"
                  >
                    <div class="flex items-center">
                      <div class="font-normal text-sm">
                        {{ t(`Tab.${child.name}`) }}
                      </div>
                    </div>
                  </router-link>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="flex flex-col">
          <div class="flex items-center">
            <ManualButton />
            <ZoomInOutButton />
            <WebSocketPing class="mx-2" />
            <UserProfile />
          </div>

          <div class="ml-auto mr-1">
            <vue-countdown
              :time="refreshTokenRemainingTime"
              :transform="transformSlotProps"
              v-slot="{ totalHours, minutes, seconds }"
              @progress="onRefreshTokenTimeRemaining"
            >
              <div class="flex items-center">
                <ArrowPathIcon
                  class="w-4 h-4 cursor-pointer"
                  @click="fetchRefreshTokenRenew"
                />
                <span class="ml-1 text-sm text-red-500">
                  {{ totalHours }}:{{ minutes }}:{{ seconds }}
                </span>
              </div>
            </vue-countdown>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isShowMenu"
      class="md:hidden absolute w-full bg-primary-darkest z-50"
    >
      <div class="space-y-1">
        <Disclosure
          v-slot="{ open }"
          as="div"
          v-for="nav in navigation"
          :key="nav.name"
          class="group"
        >
          <DisclosureButton
            v-if="nav.children !== null && nav.children.length > 0"
            class="flex w-full justify-between px-4 py-2 text-left text-sm font-medium text-white hover:bg-primary-bright hover:text-gray-700 hover:font-D2CodingBold"
            :class="
              open ? 'bg-primary-bright text-gray-700 font-D2CodingBold' : ''
            "
          >
            <span class="">{{ nav.title }}</span>
            <ChevronUpIcon
              :class="
                open
                  ? 'rotate-180 transform text-gray-700 font-D2CodingBold'
                  : 'group-hover:text-gray-700'
              "
              class="h-5 w-5 text-white"
            />
          </DisclosureButton>
          <router-link
            v-else
            :to="nav.path"
            :target="nav.target"
            class="flex w-full justify-between px-4 py-2 text-left text-sm font-medium text-white hover:bg-primary-bright hover:text-gray-700 hover:font-D2CodingBold"
            @click="onClickedSubMenu($event, nav)"
          >
            <div class="flex items-center">
              <div class="font-normal text-sm">{{ nav.title }}</div>
            </div>
          </router-link>

          <DisclosurePanel
            v-for="child in nav.children"
            :key="child.name"
            class="text-sm text-white"
          >
            <router-link
              :to="child.path"
              class="flex flex-col py-2 pl-6 hover:bg-primary-bright hover:text-gray-700 hover:font-D2CodingBold"
              :target="nav.target"
              @click="onClickedSubMenu($event, child)"
            >
              <div class="flex items-center">
                <div class="font-normal text-sm">
                  - {{ t(`Tab.${child.name}`) }}
                </div>
              </div>
            </router-link>
          </DisclosurePanel>
        </Disclosure>
      </div>
    </div>

    <!-- 기사 생성 모달 -->
    <ArticleModal
      v-if="isShowArticleModal"
      mode="create"
      @close="onCloseArticleModal"
    />

    <AlertModal
      :is-open="isShowRefreshModal"
      :alert-title="alertTitle"
      :alert-sub-content="alertSubContent"
      :confirm-button-text="'연장하기'"
      is-show-confirm-button
      @close="isShowRefreshModal = false"
      @on-confirm="onRefreshLogin"
    />
  </nav>
</template>

<script>
import { computed, defineComponent, ref, watch, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { MODULE_NAME as APP_MODULE_NAME } from '@/store/modules/app';
import { ACTION_NAMES as APP_ACTION_NAMES } from '@/store/modules/app/action';

import {
  Bars3Icon,
  XMarkIcon,
  ChevronUpIcon,
  ArrowPathIcon,
} from '@heroicons/vue/24/outline';
import TheLogo from '@/components/TheLogo.vue';
import UserProfile from '@/components/common/UserProfile.vue';
import ZoomInOutButton from '@/components/ZoomInOutButton.vue';
import WebSocketPing from '@/components/WebSocketPing.vue';
import ArticleModal from '@/components/modal/article/ArticleModal.vue';
import { useRoute } from 'vue-router';
import { useUserInfo } from '@/hooks/use-user-info';
import { useI18n } from 'vue-i18n';
import { appRoutes } from '@/router/app-routes';
import { RouterName } from '@/types/router';
import { AppAuthorityEnum } from '@/enums/app-authority';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import useBreakpoint from '@/hooks/use-breakpoint';
import ManualButton from '@/components/ManualButton.vue';
import { useMarsLApi } from '@/apis/mars-l-api';
import AlertModal from '@/components/modal/alert/AlertModal.vue';

export default defineComponent({
  components: {
    Disclosure,
    DisclosurePanel,
    DisclosureButton,
    ArticleModal,
    Bars3Icon,
    XMarkIcon,
    ChevronUpIcon,
    TheLogo,
    UserProfile,
    ZoomInOutButton,
    WebSocketPing,
    ManualButton,
    ArrowPathIcon,
    AlertModal,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const { can } = useUserInfo();
    const marsLApi = useMarsLApi();

    const isShowArticleModal = ref(false);
    const isShowRefreshModal = ref(false);
    const alertTitle = ref('');
    const alertSubContent = ref('');
    let alertCount = 0;

    let navigation = ref([]);
    let children = [];
    let navObj = {
      name: '',
      title: '',
      children: [],
      path: '',
    };

    const breakpoints = useBreakpoint();

    const isShowMenu = computed(() => store.state[APP_MODULE_NAME].isShowMenu);

    const refreshTokenRemainingTime = computed(
      () => store.state['auth'].refreshTokenRemainingTime,
    );

    const menuRouterList = appRoutes.filter(routerItem => {
      return routerItem?.meta?.menu;
    });

    const isActive = item => {
      if (item.children.length) {
        return item.children[0].meta.parent === route.meta.parent;
      }

      return route?.name === item.name;
    };

    const onClickedSubMenu = (event, subMenu) => {
      // 내기사 하위 메뉴의 '기사 작성'을 누르면 기사생성 모달을 띄울 수 있도록
      if (subMenu.name === RouterName.CreateMyArticle) {
        isShowArticleModal.value = true;
      }
      fetchMenuState(false);
    };

    const onCloseArticleModal = () => {
      isShowArticleModal.value = false;
      window.EventBus.emit('refresh-my-article');
    };

    const fetchMenuState = payload => {
      store.dispatch(
        `${APP_MODULE_NAME}/${APP_ACTION_NAMES.FETCH_IS_SHOW_MENU}`,
        payload,
      );
    };

    const fetchRefreshTokenRenew = async () => {
      await marsLApi.auth.tokenApi.refreshTokenRenew();
      await store.dispatch('auth/FETCH_REFRESH_TOKEN_REMAINING_TIME');
      window.Notify.success({
        message: '로그인 시간이 갱신되었습니다.',
      });
      alertCount = 0;
    };

    const transformSlotProps = item => {
      const formattedProps = {};

      Object.entries(item).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${value}` : String(value);
      });

      return formattedProps;
    };

    /**
     * 로그인 만료시간 체크: 로그인 만료 30분 남았을때 연장여부 모달 open
     * @param time
     */
    const onRefreshTokenTimeRemaining = time => {
      const formattedProps = transformSlotProps(time);
      const renewTime = 60 * 30; // 30분

      alertSubContent.value = `남은 시간 : ${formattedProps.minutes}:${formattedProps.seconds}`;
      if (time.totalSeconds < renewTime && !alertCount) {
        isShowRefreshModal.value = true;
        alertTitle.value = `자동로그아웃안내`;
        alertCount += 1;
      }
    };

    /**
     * 모달에서 로그인 연장 버튼 클릭
     */
    const onRefreshLogin = async () => {
      await fetchRefreshTokenRenew();

      isShowRefreshModal.value = false;
    };

    watchEffect(() => {
      const authorityCodes = store.getters['auth/getAuthorityCodes'];

      if (authorityCodes.length) {
        navigation.value = [];
        menuRouterList.forEach(route => {
          /**
           * 내기사: 기사작성 하위메뉴
           * 취재데스크: 내지역사조회, 지역사조회, 서울MBC조회
           * 큐시트: 큐시트조회, 지역사조회, 서울MBC조회, 큐시트 템플릿
           * 제보관리
           * 하단롤
           * 액션을 할 수 있는 권한이 있으면 하위 메뉴에 추가
           */
          if (
            (route.name === RouterName.CreateMyArticle &&
              !can(AppAuthorityEnum.MY_ARTICLE_WRITE.code)) ||
            (route.name === RouterName.MyRegionArticle &&
              !can(AppAuthorityEnum.ARTICLE_READ.code)) ||
            (route.name === RouterName.AreaArticle &&
              !can(AppAuthorityEnum.ARTICLE_CHANNEL_READ.code)) ||
            (route.name === RouterName.SeoulMBCArticle &&
              !can(AppAuthorityEnum.ARTICLE_SEOUL_READ.code)) ||
            (route.name === RouterName.CueSheet &&
              !can(AppAuthorityEnum.CUESHEET_READ.code)) ||
            (route.name === RouterName.AreaCueSheet &&
              !can(AppAuthorityEnum.CUESHEET_CHANNEL_READ.code)) ||
            (route.name === RouterName.SeoulMBCCueSheet &&
              !can(AppAuthorityEnum.CUESHEET_SEOUL_READ.code)) ||
            (route.name === RouterName.CueSheetTemplate &&
              !can(AppAuthorityEnum.CUESHEET_TEMPLATE_READ.code)) ||
            (route.name === RouterName.ContactUsManagement &&
              !can(AppAuthorityEnum.REPORT_READ.code)) ||
            (route.name === RouterName.BottomRoll &&
              !can(AppAuthorityEnum.SCROLLNEWS_READ.code)) ||
            (route.name === RouterName.Board &&
              !can(AppAuthorityEnum.BOARD_READ.code))
          ) {
            return;
          }

          if (route.meta?.parent) {
            if (navObj.name === route.meta?.parent) {
              children.push(route);
              navObj.children = children;
            } else {
              children = [];
              children.push(route);

              navObj = {
                name: route.meta?.parent,
                title: t(`Menu.${route.meta?.parent}`),
                children,
                path: children.find(item => item.meta.menu?.default)
                  ? children.find(item => item.meta.menu?.default).path
                  : children[0].path,
              };

              navigation.value.push(navObj);
            }
          } else {
            // 관리자 권한이 있으면 관리자메뉴 표시
            if (
              route.name === RouterName.Admin &&
              !can(AppAuthorityEnum.ADMIN_VIEW.code)
            ) {
              return;
            }

            navObj = {
              name: route.name,
              title: t(`Menu.${route.name}`),
              children: [],
              path: route.path,
              target: route.meta.menu.target || null,
            };

            navigation.value.push(navObj);
          }
        });
      }
    });

    watch(
      () => breakpoints,
      currentBreakpoints => {
        if (!currentBreakpoints.xs && !currentBreakpoints.sm) {
          if (isShowMenu.value) fetchMenuState(false);
        }
      },
      { deep: true },
    );

    return {
      t,
      navigation,
      isActive,
      isShowMenu,
      isShowArticleModal,
      onClickedSubMenu,
      onCloseArticleModal,
      fetchMenuState,
      refreshTokenRemainingTime,
      fetchRefreshTokenRenew,
      transformSlotProps,
      onRefreshTokenTimeRemaining,
      isShowRefreshModal,
      alertTitle,
      alertSubContent,
      onRefreshLogin,
    };
  },
});
</script>
