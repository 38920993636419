import { AxiosInstance } from 'axios';

const PREFIX = '/news-source';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 취재원 목록 조회
     * @param params
     * @returns
     */
    findAll: async (params: any) => {
      return $axios
        .get(`${PREFIX}`, { params })
        .then(response => response.data);
    },
    /**
     * 취재원 상세 조회
     * @param newsSourceId
     * @returns
     */
    findOne: async (newsSourceId: any) => {
      return $axios
        .get(`${PREFIX}/${newsSourceId}`)
        .then(response => response.data);
    },

    /**
     * 취재원 저장
     * @param params
     * @returns
     */
    create: async (params: any) => {
      return $axios.post(`${PREFIX}`, params).then(response => response.data);
    },

    /**
     * 취재원 수정
     * @param params
     * @returns
     */
    update: async (newsSourceId: any, params: any) => {
      return $axios
        .put(`${PREFIX}/${newsSourceId}`, params)
        .then(response => response.data);
    },

    /**
     * 취재원 삭제
     * @param newsSourceId
     * @returns
     */
    delete: async (newsSourceId: any) => {
      return $axios
        .delete(`${PREFIX}/${newsSourceId}`)
        .then(response => response.data);
    },
  };
};
