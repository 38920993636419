import { AxiosInstance } from 'axios';

const PREFIX = '/channel';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 채널 코드(지역사) 조회
     * @param params
     * @returns
     */
    findAll: async () => {
      return $axios.get(`${PREFIX}`).then(response => response.data);
    },

    /**
     * 채널 코드(지역사) 상세 조회
     * @param {number}channelId 채널 아이디
     */
    findOne: async (channelId: number) => {
      return $axios
        .get(`${PREFIX}/${channelId}`)
        .then(response => response.data);
    },
  };
};
