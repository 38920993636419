<template>
  <div class="border rounded-md bg-white p-2 overflow-hidden" :class="height">
    <GTabPanel v-if="tabs?.length" :tabs="tabs">
      <TabPanel>
        <div v-if="isLoading" class="h-[calc(100vh-226px)]">
          <PageLoader />
        </div>
        <div
          v-else-if="!articleId"
          class="bg-white space-y-4 py-3 px-4 rounded-md h-[calc(100vh-226px)]"
        >
          <GNoData />
        </div>
        <div v-else-if="articleId" class="mx-3">
          <div class="pb-3 space-y-1">
            <div class="flex space-x-2 items-center">
              <!-- 형식 -->
              <ArticleTypeUI :item="type" />
              <!-- 제목 -->
              <input
                class="text-base px-2 py-1.5 font-D2CodingBold w-full rounded-md bg-white border border-gray-300 truncate focus:outline-none"
                readonly
                :value="title"
              />
            </div>
            <!-- 기사 정보 -->
            <div class="flex space-x-2 justify-between">
              <div class="flex items-center text-sm space-x-5">
                <!-- 길이 -->
                <div
                  v-tooltip="t('PreviewArticle.길이')"
                  class="flex items-center space-x-2"
                >
                  <div
                    class="p-1 bg-primary-bright rounded-full text-primary-middle"
                  >
                    <ClockIcon class="h-5 w-5" />
                  </div>
                  <span>
                    {{ totalArticleTime }}
                  </span>
                </div>
                <!-- 작성자 -->
                <div
                  v-tooltip="t('PreviewArticle.작성자')"
                  class="flex items-center space-x-2"
                >
                  <div
                    class="p-1 bg-primary-bright rounded-full text-primary-middle"
                  >
                    <UserIcon class="h-5 w-5" />
                  </div>
                  <span>
                    {{ writer }}
                  </span>
                  <span v-if="itemType === 'myArticle' || isSameChannel"
                    >({{ writerId }})</span
                  >
                </div>
                <!-- 리포터 -->
                <!-- <div v-tooltip="t('PreviewArticle.리포터')">
                  <UserIcon class="h-5 w-5 inline-block" />
                  <span>{{ reporter }}</span>
                  <span v-if="itemType !== 'area'">({{ reporterId }})</span>
                </div> -->
                <!-- 송고일시 -->
                <div
                  v-if="transferDate"
                  v-tooltip="
                    itemType === 'seoul'
                      ? t('PreviewArticle.출고일시')
                      : t('PreviewArticle.송고일시')
                  "
                  class="flex items-center space-x-2"
                >
                  <div
                    class="p-1 bg-primary-bright rounded-full text-primary-middle"
                  >
                    <DocumentArrowUpIcon class="h-5 w-5" />
                  </div>
                  <span>
                    {{ transferDate }}
                  </span>
                </div>
              </div>
              <div class="flex space-x-1">
                <!-- 맞춤법 -->
                <GButton
                  v-tooltip="'맞춤법'"
                  icon="spellcheck"
                  :disabled="isLoading"
                  @click="isShowSpellCheckModal = true"
                />

                <!-- 기사 복사 -->
                <GButton
                  v-tooltip="'자막 복사'"
                  icon="clipboard-document-list"
                  @click="onClickedCaptionCopy"
                />

                <!-- 기사 복사 -->
                <GButton
                  v-tooltip="'기사 복사'"
                  icon="clipboard-document"
                  @click="onClickedArticleCopy"
                />
              </div>
            </div>
          </div>

          <div :class="[editorHeight]">
            <ArticleEditor
              v-if="type"
              ref="articleEditor"
              :type-id="type"
              v-model:reporter-text-array="articleEditorState.reporterTextArray"
              v-model:anchor-text-array="articleEditorState.anchorTextArray"
              v-model:caption-array="articleEditorState.reporterCaptionArray"
              :reporter-editor-read-only="true"
              :reporter-caption-read-only="true"
              :anchor-editor-read-only="true"
              :anchor-caption-read-only="true"
            />

            <!-- 맞춤법 검사 모달 -->
            <SpellCheckModal
              v-if="isShowSpellCheckModal"
              :editor-data="articleEditorState"
              :article-type="type"
              @close="isShowSpellCheckModal = false"
            />
          </div>
        </div>
      </TabPanel>
      <TabPanel v-if="showAttachFileTab" :unmount="false">
        <template v-slot="{ selected }">
          <div class="h-[calc(100vh-226px)] bg-white rounded-md">
            <ViewAttachFile
              v-if="selected"
              :route-name="itemType"
              :item-id="articleId"
              :label="false"
            >
              <template #noData>
                <GNoData />
              </template>
            </ViewAttachFile>
          </div>
        </template>
      </TabPanel>
      <TabPanel v-if="showLinkTab">
        <template v-slot="{ selected }">
          <ArticleLink
            v-if="selected"
            :article-id="articleId"
            height="h-[calc(100vh-226px)]"
            truncate-width="w-[40rem]"
            :is-show-label="false"
            isReadonly
          />
        </template>
      </TabPanel>
      <TabPanel v-if="showActionLogTab">
        <template v-slot="{ selected }">
          <!--  큐시트 상세일 경우 사본기사id를 prop으로 받는데 이력에선 원본의 이력이 필요하기때문에 원본기사의 정보가 필요할 경우 props을 통해 구분해준다      -->
          <ArticleActions
            v-if="selected"
            height="h-[calc(100vh-226px)]"
            :article-id="isOriginArticle ? originArticleId : articleId"
          />
        </template>
      </TabPanel>
    </GTabPanel>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onUpdated, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
// import { useStore } from 'vuex';
import { GButton, GTabPanel } from '@/components/ui';
import {
  ClockIcon,
  UserIcon,
  DocumentArrowUpIcon,
} from '@heroicons/vue/24/outline';
import { ArticleTypeUI } from '@/components/article/commonUI';
import { TabPanel } from '@headlessui/vue';
import ViewAttachFile from '@/components/common/ViewAttachFile.vue';
import ArticleLink from '@/components/article/ArticleLink';
import ArticleActions from '@/components/article/ArticleActions';
import { displayDateTimeHm, secondToMs } from '@/utils/format.ts';
import ArticleEditor from '@/components/article/ArticleEditor';
import { setEditorValues } from '@/utils/editor-helper.ts';
import GNoData from '@/components/ui/GNoData.vue';
import { useMarsLApi } from '@/apis/mars-l-api';
import PageLoader from '@/components/common/PageLoader.vue';
import { convertKeysToCamelCase } from '@/utils/str';
import { articleTypeCode } from '@/codes';
import { find } from 'lodash';
import SpellCheckModal from '../modal/article/SpellCheckModal.vue';

export default defineComponent({
  name: 'PreviewArticle',
  components: {
    SpellCheckModal,
    GButton,
    ClockIcon,
    UserIcon,
    DocumentArrowUpIcon,
    ArticleTypeUI,
    GTabPanel,
    TabPanel,
    ViewAttachFile,
    ArticleLink,
    ArticleActions,
    ArticleEditor,
    GNoData,
    PageLoader,
  },
  props: {
    articleId: {
      type: [String, Number],
      default: () => {
        return null;
      },
    },
    originArticleId: {
      type: [String, Number],
      default: () => {
        return null;
      },
    },
    isOriginArticle: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
    },
    editorHeight: {
      type: String,
    },
    /**
     * 내기사: myArticle
     * 취재데스크: coverDesk,
     */
    itemType: {
      type: String,
    },
    showAttachFileTab: {
      type: Boolean,
      default: true,
    },
    showLinkTab: {
      type: Boolean,
      default: true,
    },
    showActionLogTab: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const route = useRoute();
    // const store = useStore();
    const marsLApi = useMarsLApi();

    const isLoading = ref(false);
    const isSameChannel = ref(false);
    const title = ref(null);
    const transferDate = ref(null); // 송고 일시
    const totalArticleTime = ref(null); // 기사 시간
    const writer = ref(null);
    const writerId = ref(null);
    // const reporter = ref(null);
    // const reporterId = ref(null);
    const type = ref();
    const articleEditorState = ref({
      anchorTextArray: [''],
      anchorCaptionArray: [[]], // c/t형태: [[{}],[{}],[{}]...]
      reporterTextArray: [''],
      reporterCaptionArray: [[]],
    });

    const articleEditor = ref();

    const isShowSpellCheckModal = ref(false);

    // dynamic tabs
    const tabs = computed(() => {
      let tabList = [{ id: 1, name: t('PreviewArticle.기사') }];

      if (props.showAttachFileTab) {
        tabList.push({
          id: tabList.length + 1,
          name: t('PreviewArticle.첨부파일'),
        });
      }
      if (props.showLinkTab) {
        tabList.push({
          id: tabList.length + 1,
          name: t('PreviewArticle.링크'),
        });
      }
      if (props.showActionLogTab) {
        tabList.push({
          id: tabList.length + 1,
          name: t('PreviewArticle.이력'),
        });
      }

      return tabList;
    });

    // 작성기에 기사 내용 적용
    const setEditor = article => {
      // 내 기사
      if (props.itemType === 'myArticle') {
        articleEditorState.value = setEditorValues({
          artclTypCd: article?.artclTypCd,
          anchorCapList: article?.myArticleAnchorCapList,
          articleCapList: article?.myArticleCapList,
          ancMentCtt: article?.ancMentCtt || JSON.stringify(['']),
          artclCtt: article?.artclCtt || JSON.stringify(['']),
        });
      }
      // 취재데스크 OR 지역사 기사
      else if (props.itemType === 'coverDesk') {
        articleEditorState.value = setEditorValues({
          artclTypCd: article?.artclTypCd,
          anchorCapList: article?.articleAnchorCaps,
          articleCapList: article?.articleCaps,
          ancMentCtt: article?.ancMentCtt || JSON.stringify(['']),
          artclCtt: article?.artclCtt || JSON.stringify(['']),
        });
      }
      // 서울MBC 기사
      else if (props.itemType === 'seoul') {
        let artclTypCd = article.type;
        let anchorCapList = [];
        let articleCapList = [];

        // 앵커 자막 capList 인지 확인 필요
        anchorCapList = [''];

        // 기사 자막 capList 인지 확인 필요
        articleCapList = article.caps.map(caption => {
          return {
            capCtt: caption.capCtt,
            capTypCd: 'cap_002',
            lnNo: caption.lnNo,
            lnOrd: caption.capOrd,
            icon: '',
          };
        });

        articleEditorState.value = setEditorValues({
          artclTypCd,
          anchorCapList,
          articleCapList,
          ancMentCtt: article.ancMents
            ? JSON.stringify([...article.ancMents])
            : JSON.stringify(['']),
          artclCtt: article.artclCtts
            ? JSON.stringify([...article.artclCtts])
            : JSON.stringify(['']),
        });
      }
    };

    // 기사 상세 가져오기
    const getArticle = async () => {
      try {
        let response = null;
        isLoading.value = true;

        if (props.itemType === 'myArticle') {
          response = await marsLApi.myArticle.basic.findOne(
            parseInt(props.articleId),
          );
        } else if (props.itemType === 'coverDesk') {
          response = await marsLApi.coverDesk.basic.findOne(
            parseInt(props.articleId),
          );
        } else if (props.itemType === 'seoul') {
          response = await marsLApi.seoulMBC.article.findOne(props.articleId);
        }
        return response;
      } catch (error) {
        console.error('Get Article Error', error);
        throw error;
      } finally {
        isLoading.value = false;
      }
    };

    // 송고일시 표시
    const setTransferDate = response => {
      let date = '';

      // 내기사일 경우 송고일시
      if (props.itemType === 'myArticle') {
        date = response?.artclTrnsfDtm
          ? displayDateTimeHm(response?.artclTrnsfDtm)
          : '';
      }
      // 취재데스크, 지역사 기사일 경우 inputDate
      else if (props.itemType === 'coverDesk') {
        date = response?.inputDtm ? displayDateTimeHm(response?.inputDtm) : '';
      }
      // 서울일 경우 artclRelseDtm
      else if (props.itemType === 'seoul') {
        date = response?.inputDtm
          ? displayDateTimeHm(response?.artclRelseDtm)
          : '';
      }
      return date;
    };

    const setData = response => {
      isSameChannel.value = response?.isSameChannel || true;
      type.value = response?.artclTypCd;
      if (props.itemType === 'myArticle') {
        writerId.value = response?.inputrUserId;
        writer.value = response?.inputrNm;
      } else {
        writerId.value = response?.inputrUserId;
        writer.value = response?.isSameChannel
          ? response?.inputrNm
          : `${response?.orgInputrNm}(${response?.orgChNm})`;
      }
      totalArticleTime.value = secondToMs(
        response?.ancMentCttTime +
          response?.artclCttTime +
          response?.artclExtTime,
      );
      transferDate.value = setTransferDate(response);
      title.value = response?.artclTitl;
      setEditor(response);
    };

    const onClickedCaptionCopy = () => {
      articleEditor.value.onCopyToCaption();
    };

    const onClickedArticleCopy = () => {
      articleEditor.value.onCopyToArticle();
    };

    watchEffect(async () => {
      if (!props.articleId) {
        return;
      }

      try {
        let response = await getArticle();

        if (props.itemType === 'seoul') {
          let seoulNewsDetail = convertKeysToCamelCase(response.data);

          // 서울 기사 형식이 지역사 기사 형식에는 없는 경우 형식을 스트레이트로 보여준다.
          const findArticleType = find(
            articleTypeCode.values,
            v => v.seoulId === seoulNewsDetail.artclTypCd,
          );

          if (findArticleType) {
            seoulNewsDetail.type = findArticleType.id;
          } else {
            seoulNewsDetail.type = articleTypeCode.Straight.id;
          }

          // 타입을 mars-l 기준으로 변경처리
          type.value = seoulNewsDetail.type;
          writer.value = seoulNewsDetail?.inputrNm || seoulNewsDetail?.rptrNm;
          writerId.value = seoulNewsDetail?.inputrNm
            ? seoulNewsDetail?.inputrId
            : seoulNewsDetail?.rptrId;
          totalArticleTime.value = secondToMs(seoulNewsDetail?.artclReqdTime);
          transferDate.value = setTransferDate(seoulNewsDetail);
          title.value = seoulNewsDetail?.artclTitl;
          setEditor(seoulNewsDetail);
        } else {
          setData(response);
        }
      } catch (error) {
        console.error('기사 가공 실패', error);
      }
    });

    onUpdated(() => {
      type.value = null;
    });

    return {
      t,
      route,
      isLoading,
      title,
      transferDate,
      totalArticleTime,
      type,
      writer,
      writerId,
      // reporter,
      // reporterId,
      tabs,
      articleEditorState,
      isSameChannel,
      articleEditor,
      onClickedCaptionCopy,
      onClickedArticleCopy,
      isShowSpellCheckModal,
    };
  },
});
</script>
