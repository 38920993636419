import { AxiosInstance } from 'axios';

const PREFIX = '/my-article';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 내기사 참조 기사 등록
     * @param {Object} params request body
     * @param {string} params.artclCtt 참조 기사 내용
     */
    create: async (params: any) => {
      return $axios
        .post(`${PREFIX}/reference`, params)
        .then(response => response.data);
    },
  };
};
