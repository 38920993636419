import { Code, EnumEntry } from './base';

class speedCode extends EnumEntry<Code> {
  slow: Code = {
    id: '001',
    name: '느림',
    label: '느림',
  };

  normal: Code = {
    id: '002',
    name: '보통',
    label: '보통',
  };

  fast: Code = {
    id: '003',
    name: '빠름',
    label: '빠름',
  };

  values: Array<Code> = [this.slow, this.normal, this.fast];
}

export default new speedCode();
