import { Module } from 'vuex';
import { RootState } from '..';
import { useMarsLApi } from '@/apis/mars-l-api';

export type UnLockState = {
  article: string | number;
};

export const UnLockModule: Module<UnLockState, RootState> = {
  namespaced: true,
  state: () => ({
    article: 0,
  }),
  getters: {},
  actions: {
    UNLOCK_ARTICLE: async (commit, payload) => {
      const marsLApi = useMarsLApi();

      try {
        const response = await marsLApi.coverDesk.basic.unLock(
          parseInt(payload),
        );

        if (response.success) {
          console.log('');
        }
      } catch (error) {
        console.error('error', error);
      }
    },
  },
};
