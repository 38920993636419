interface IAppAuthorityItem {
  code: string;
  name: string;
  explain: string;
}
//
interface IAppAuthorityEnum {
  [key: string]: IAppAuthorityItem;
}

export const AppAuthorityEnum: IAppAuthorityEnum = {
  MY_ARTICLE_WRITE: {
    code: 'my_article_write',
    name: '작성',
    explain: '내기사 생성, 수정, 삭제',
  },
  MY_ARTICLE_SEND: {
    code: 'my_article_send',
    name: '송고',
    explain: '',
  },
  ARTICLE_READ: {
    code: 'article_read',
    name: '전체 조회',
    explain: '',
  },
  ARTICLE_WRITE: {
    code: 'article_write',
    name: '작성',
    explain: '작성, 수정',
  },
  ARTICLE_COPY: {
    code: 'article_copy',
    name: '복사',
    explain: '',
  },
  ARTICLE_RELEASE: {
    code: 'article_release',
    name: '출고',
    explain: '출고, 취소',
  },
  ARTICLE_HOMEPAGE_SEND: {
    code: 'article_homepage_send',
    name: '포탈 전송',
    explain: '',
  },
  ARTICLE_PRINT: {
    code: 'article_print',
    name: '인쇄',
    explain: '',
  },
  ARTICLE_CHANNEL_READ: {
    code: 'article_channel_read',
    name: '지역사 조회',
    explain: '',
  },
  ARTICLE_SEOUL_READ: {
    code: 'article_seoul_read',
    name: '서울 MBC 조회',
    explain: '',
  },
  CUESHEET_READ: {
    code: 'cuesheet_read',
    name: '전체 조회',
    explain: '',
  },
  CUESHEET_WRITE: {
    code: 'cuesheet_write',
    name: '작성',
    explain: '생성, 수정, 삭제',
  },
  CUESHEET_CHANNEL_READ: {
    code: 'cuesheet_channel_read',
    name: '지역사 조회',
    explain: '',
  },
  CUESHEET_SEOUL_READ: {
    code: 'cuesheet_seoul_read',
    name: '서울 MBC 조회',
    explain: '',
  },
  CUESHEET_DETAIL_ITEM_WRITE: {
    code: 'cuesheet_detail_item_write',
    name: '기사 아이템 작성',
    explain: '아이템 생성, 순서 변경',
  },
  CUESHEET_DETAIL_PRINT: {
    code: 'cuesheet_detail_print',
    name: '인쇄',
    explain: '',
  },
  CUESHEET_DETAIL_ANCHOR_WRITE: {
    code: 'cuesheet_detail_anchor_write',
    name: '앵커 멘트 수정',
    explain: '앵커 기사 수정, 확정',
  },
  CUESHEET_TEMPLATE_READ: {
    code: 'cuesheet_template_read',
    name: '큐시트 템플릿 조회',
    explain: '',
  },
  CUESHEET_TEMPLATE_WRITE: {
    code: 'cuesheet_template_write',
    name: '큐시트 템플릿 작성',
    explain: '큐시트 템플릿 생성, 수정, 삭제',
  },
  REPORT_READ: {
    code: 'report_read',
    name: '조회',
    explain: '',
  },
  REPORT_WRITE: {
    code: 'report_write',
    name: '작성',
    explain: '생성, 수정',
  },
  MONITORING_READ: {
    code: 'monitoring_read',
    name: '조회',
    explain: '',
  },
  MONITORING_WRITE: {
    code: 'monitoring_write',
    name: '작성',
    explain: '생성, 수정, 삭제',
  },
  BOARD_READ: {
    code: 'board_read',
    name: '조회',
    explain: '',
  },
  BOARD_WRITE: {
    code: 'board_write',
    name: '작성',
    explain: '생성, 수정, 삭제',
  },
  BOARD_REPLY_WRITE: {
    code: 'board_reply_write',
    name: '댓글 작성',
    explain: '생성, 수정, 삭제',
  },
  BOARD_NOTICE_WRITE: {
    code: 'board_notice_write',
    name: '공지 작성',
    explain: '생성, 수정, 삭제',
  },
  SCROLLNEWS_READ: {
    code: 'scrollnews_read',
    name: '조회',
    explain: '',
  },
  SCROLLNEWS_WRITE: {
    code: 'scrollnews_write',
    name: '생성, 수정, 삭제',
    explain: '',
  },
  SCROLLNEWS_SEND: {
    code: 'scrollnews_send',
    name: '',
    explain: '',
  },
  ADMIN_VIEW: {
    code: 'admin_view',
    name: '관리자 페이지 접근',
    explain: '화면 접근만 제어',
  },
};

export const compareAppAuthorityByCode = (
  enumObject1: IAppAuthorityItem,
  enumObject2: IAppAuthorityItem,
): boolean => {
  return enumObject1.code === enumObject2.code;
};

export const getAppAuthorityByCode = (
  code: string,
): IAppAuthorityItem | null => {
  for (const key in AppAuthorityEnum) {
    if (Object.prototype.hasOwnProperty.call(AppAuthorityEnum, key)) {
      const item = AppAuthorityEnum[key];
      if (item && item.code === code) {
        return item;
      }
    }
  }
  return null;
};

export default {
  AppAuthorityEnum,
  compareAppAuthorityByCode,
  getAppAuthorityByCode,
};
