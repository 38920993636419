<template>
  <div class="space-y-2 w-full">
    <!-- Filter 영역 -->
    <div class="flex items-center flex-wrap gap-1.5 p-1">
      <RangeDatePickerEx v-model="dates" @change="onChangeDate" />
      <GInput
        v-model="mySearchInput"
        name="mySearchInput"
        :placeholder="t('RefMyArticle.검색어')"
        width="w-72"
        @keyup.enter="onSearch"
      />
      <GButton icon="magnifying-glass" @click="onSearch" />
    </div>

    <!-- 테이블 영역 -->
    <MyArticleList
      :is-loading="isLoading"
      :articles="articles"
      :pagination="paginationInfo"
      :selected-row-index="selectedRowIndex"
      height="h-[calc(100vh-367px)]"
      @on-row-clicked="onRowClicked"
      @on-row-db-clicked="onRowDbClicked"
      @on-changed-page="onChangePage"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import { MyArticleList } from '@/components/article';
import RangeDatePickerEx from '@/components/common/RangeDatePickerEx';
import { GInput, GButton } from '@/components/ui';
import { useI18n } from 'vue-i18n';
import { useMarsLApi } from '@/apis/mars-l-api';
import { getProgramName } from '@/utils/get-data-helper';
import { defaultDate } from '@/utils/format.ts';
import dayjs from 'dayjs';
import { PageConst } from '@/enums';

export default defineComponent({
  name: 'RefMyArticle',
  components: {
    MyArticleList,
    RangeDatePickerEx,
    GInput,
    GButton,
  },
  props: {},
  emits: ['on-row-clicked', 'on-row-db-clicked'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const marsLApi = useMarsLApi();

    const isLoading = ref(false);
    const dates = ref([defaultDate(dayjs().subtract(7, 'day')), defaultDate()]);
    const mySearchInput = ref('');
    const articles = ref([]);
    const selectedArticle = ref(null);
    const page = ref(1);
    const paginationInfo = ref(null);
    const selectedRowIndex = ref(0);

    // 기사 가져오기
    const getArticles = async () => {
      try {
        isLoading.value = true;
        const params = {
          startDate: dates.value[0],
          endDate: dates.value[1],
          searchValue: mySearchInput.value,
          page: page.value - 1,
          size: PageConst.size,
        };

        const response = await marsLApi.myArticle.basic.findAll(params);
        let articleList = [];

        // 프로그램 이름 표시
        if (response.content.length) {
          for (const article of response.content) {
            article.brdcPgmNm = await getProgramName(article.brdcPgmId);
          }

          articleList = response.content;
        }
        articles.value = [];
        articles.value = articleList;
        paginationInfo.value = {
          isShow: true,
          page: page.value,
          totalPage: response.totalPages,
          totalCount: response.totalElements,
        };
      } catch (error) {
        console.log('error', error);
      } finally {
        isLoading.value = false;
      }
    };

    // 날짜 변경
    const onChangeDate = date => {
      dates.value = date;
      onSearch();
    };

    // 페이지 변경
    const onChangePage = changePage => {
      page.value = changePage;
      getArticles();
    };

    // 검색
    const onSearch = () => {
      getArticles();
    };

    // 행 선택
    const onRowClicked = rowData => {
      selectedRowIndex.value = rowData?.index;
      selectedArticle.value = rowData?.row;
      emit('on-row-clicked', rowData?.row);
    };

    // 행 더블 클릭
    const onRowDbClicked = rowData => {
      selectedRowIndex.value = rowData?.index;
      selectedArticle.value = rowData?.row;
      emit('on-row-db-clicked', rowData?.row);
    };

    // 초기화
    const initData = async () => {
      await getArticles();
    };

    onMounted(() => {
      initData();
    });

    return {
      t,
      isLoading,
      dates,
      mySearchInput,
      articles,
      selectedArticle,
      selectedRowIndex,
      paginationInfo,
      onChangeDate,
      onSearch,
      onRowClicked,
      onRowDbClicked,
      onChangePage,
    };
  },
});
</script>
