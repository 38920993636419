import { AxiosInstance } from 'axios';

const PREFIX = '/auth';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 토큰 갱신 (재발급)
     */
    tokenRenew: async () => {
      return $axios
        .post(`${PREFIX}/token-renew`)
        .then(response => response.data);
    },
    /**
     * 리플래시 토큰 갱신 (재발급)
     */
    refreshTokenRenew: async () => {
      return $axios
        .post(`${PREFIX}/renew/refresh-token`)
        .then(response => response.data);
    },
    /**
     * 리플래시 토큰 남은 시간 조회
     */
    getRemainingTimeOfRefreshToken: async () => {
      return $axios
        .get(`${PREFIX}/refresh-token/remaining-time`)
        .then(response => response.data);
    },
  };
};
