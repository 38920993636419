<template>
  <GButton
    v-if="buttonType === 'normal'"
    :icon="icon"
    :disabled="disabled"
    :type="type"
    :loading="loading"
    @click="onDelete"
  />
  <GIconButton
    v-else-if="buttonType === 'icon'"
    :icon="icon"
    :disabled="disabled"
    :type="type"
    :loading="loading"
    @click="onDelete"
  />
</template>

<script setup>
import { GButton, GIconButton } from '@/components/ui';
import { useSweetAlert } from '@/hooks/use-sweet-alert';

const props = defineProps({
  icon: {
    type: String,
    default: 'trash',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'primary',
  },
  title: {
    type: String,
    default: '',
  },
  isNeedPw: {
    type: Boolean,
    default: false,
  },
  buttonType: {
    type: String,
    default: 'normal',
  },
});

const emit = defineEmits(['on-delete', 'on-close', 'on-deny']);
const { swalConfirm } = useSweetAlert();
const color = '#ff0303'; // 빨간색

const onDelete = () => {
  swalConfirm({
    title: props.title,
    icon: undefined,
    iconColor: color,
    iconHtml:
      '<svg class="h-24 w-24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.043 21H3.957c-1.538 0-2.5-1.664-1.734-2.997l8.043-13.988c.77-1.337 2.699-1.337 3.468 0l8.043 13.988C22.543 19.336 21.58 21 20.043 21zM12 9v4" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path><path d="M12 17.01l.01-.011" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>',
    html: `<div>을(를) 삭제하시겠습니까?</div>`,
    customClass: {
      icon: 'no-border',
    },
    confirmButtonColor: color,
    confirmButtonText: '삭제',
  }).then(res => {
    if (res === 'confirm') {
      emit('on-delete');
    } else if (res === 'dismiss') {
      emit('on-close');
    } else if (res === 'deny') {
      emit('on-deny');
    }
  });
};
</script>
