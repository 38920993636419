import { ComputedRef, Ref, computed, ref, watch, onMounted } from 'vue';

/**
 * 폼의 내용이 변경되었는지 여부
 */
export function useFormChanged(
  meta: ComputedRef<{
    initialValues: Record<string, any>;
    dirty: boolean;
    valid: boolean;
  }>,
  applyValidation = false,
): Ref<boolean> {
  const isFormChanged = ref(false);

  onMounted(() => {
    watch(meta, (newValue, oldValue) => {
      if (
        Object.keys(oldValue.initialValues).length === 0 ||
        !newValue.dirty ||
        newValue.dirty === oldValue.dirty ||
        isFormChanged.value
      ) {
        return;
      }

      isFormChanged.value = newValue.dirty;
      if (applyValidation) {
        isFormChanged.value = isFormChanged.value && meta.value.valid;
      }

      isFormChanged.value = true;
    });
  });

  return isFormChanged;
}

/**
 * 폼이 submit 가능한지 여부
 */
export function useFormSubmit(
  meta: ComputedRef<{
    dirty: boolean;
    valid: boolean;
  }>,
  submitCount: Ref<number>,
  submitCountLimit = 20,
): ComputedRef<boolean> {
  const canSubmit = computed(() => {
    let canFormSubmit = meta.value.valid && meta.value.dirty;
    if (submitCountLimit > 0) {
      canFormSubmit = canFormSubmit && submitCount.value < submitCountLimit;
    }
    return canFormSubmit;
  });
  return canSubmit;
}
