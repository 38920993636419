import Swal from 'sweetalert2';
import './../../public/button/delete-button.css';

const CONFIRMBUTTONCOLOR = '#2F7097'; // primary-default
const DENYBUTTONCOLOR = '#dc3741'; // 빨간색
const CANCELBUTTONCOLOR = '#d1d5db';
const onFire = ({
  icon = undefined,
  title = '',
  html = '',
  input = undefined,
  inputPlaceholder = '',
  inputValue = 1,
  allowOutsideClick = false,
  showConfirmButton = true,
  showCancelButton = true,
  showDenyButton = false,
  returnInputValueOnDeny = false,
  confirmButtonText = '확인',
  cancelButtonText = '취소',
  denyButtonText = '거절',
  reverseButtons = true,
  confirmButtonColor = CONFIRMBUTTONCOLOR,
  denyButtonColor = DENYBUTTONCOLOR,
  cancelButtonColor = CANCELBUTTONCOLOR,
  iconHtml = '',
  iconColor = '',
  customClass = {
    cancelButton: 'order-1',
    confirmButton: 'order-2',
    denyButton: 'order-3',
  },
  disabledButtons = [], // 확인: confirm, 거절: deny
} = {}) => {
  return Swal.fire({
    icon,
    input,
    inputPlaceholder,
    inputValue,
    iconHtml,
    iconColor,
    title: title ? `<span class="text-xl inline-block">${title}</span>` : '',
    html,
    allowOutsideClick,
    showConfirmButton,
    showCancelButton, // 취소버튼
    showDenyButton, // 거절버튼
    returnInputValueOnDeny,
    confirmButtonText,
    cancelButtonText,
    denyButtonText,
    confirmButtonColor,
    denyButtonColor,
    cancelButtonColor,
    reverseButtons,
    showClass: {
      icon: 'none',
    },
    hideClass: {
      icon: 'none',
    },
    customClass,
    didOpen: element => {
      if (disabledButtons.length) {
        disabledButtons.forEach(button => {
          let confirmBtn = null;

          if (button === 'confirm') {
            confirmBtn = element.getElementsByClassName('swal2-confirm');
          } else if (button === 'deny') {
            confirmBtn = element.getElementsByClassName('swal2-deny');
          }

          if (confirmBtn?.length) {
            confirmBtn[0].setAttribute('disabled', 'true');
          }
        });
      }
    },
  });
};

export const useSweetAlert = () => {
  return {
    swalError: (data: any) => {
      const options = {
        icon: 'error',
        ...data,
      };
      return onFire(options);
    },
    swalSuccess: () => {},
    swalConfirm: (data: any) => {
      const options = {
        icon: data?.icon || 'warning',
        ...data,
      };
      return onFire(options).then(response => {
        const { isConfirmed, isDenied, isDismissed } = response;

        if (isConfirmed) {
          return response.value === 1
            ? { state: 'confirm', result: response.value }
            : 'confirm';
        }
        // 거절 버튼 클릭
        else if (isDenied) {
          return response.value === 1
            ? { state: 'deny', result: response.value }
            : 'deny';
        }
        // 취소 버튼 클릭
        else if (isDismissed) {
          return 'dismiss';
        }
      });
    },
  };
};
