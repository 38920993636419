<template>
  <div class="border rounded-md bg-white h-[calc(100vh-535px)] overflow-auto">
    <table>
      <thead
        class="rounded-lg text-left leading-4 font-D2CodingBold text-gray-600 uppercase tracking-wider sticky inset-0 bg-white z-40 w-full sm:text-xs select-none"
      >
        <th
          class="p-2 w-12 items-center text-center cursor-pointer"
          @click="toggleAllRowsSelection"
        >
          {{ t('Modal.Cuesheet.PrintItemList.순번') }}
        </th>
        <th class="p-2 w-16">{{ t('Modal.Cuesheet.PrintItemList.형식') }}</th>
        <th class="p-2">{{ t('Modal.Cuesheet.PrintItemList.제목') }}</th>
        <th class="p-2 w-16">{{ t('Modal.Cuesheet.PrintItemList.작성자') }}</th>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200">
        <tr
          v-for="(row, index) in list"
          :key="row.id"
          class="sm:text-xs font-D2CodingBold select-none"
          :class="[isSelected(row) ? 'bg-teal-100' : '', setRowStyle(row)]"
          @click="toggleRowSelection(row)"
        >
          <td class="p-2">{{ index + 1 }}</td>
          <td class="p-2">
            <ArticleTypeUI :item="row.artclTypCd" />
          </td>
          <td class="p-2">
            <div class="w-[25rem] truncate" :title="row.title">
              {{ row.title }}
            </div>
          </td>
          <td class="p-2">
            <div class="truncate" :title="row.reporterName">
              {{ row.reporterName }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { ArticleTypeUI } from '@/components/article/commonUI';
import { cuesheetItemCode, articleDivisionCode } from '@/codes';
import { sortBy } from 'lodash';
import dayjs from 'dayjs';

const props = defineProps({
  list: {
    type: Array,
    default: () => {
      return [];
    },
  },
  isLoading: {
    type: Boolean,
    default: () => {
      return false;
    },
  },
});

const emit = defineEmits(['on-selected-rows']);

const { t } = useI18n();

const setRowStyle = row => {
  let cls = 'font-D2CodingBold';
  if (
    row.cueItemDivCd === cuesheetItemCode.ITEM.id ||
    row.cueItemDivCd === cuesheetItemCode.TEMPLATE.id
  ) {
    cls += ' text-[#ff00ff]';
  } else if (
    row.artclDivCd === articleDivisionCode.embargo.id &&
    dayjs().isBefore(dayjs(row.artclDivDtm).format())
  ) {
    cls += ' text-red-500';
  }
  return cls;
};

const selectedRows = ref([]);

const isSelected = item => {
  return selectedRows.value.some(selectedItem => selectedItem.id === item.id);
};

const toggleRowSelection = item => {
  const index = selectedRows.value.findIndex(
    selectedItem => selectedItem.id === item.id,
  );
  if (index > -1) {
    selectedRows.value.splice(index, 1);
  } else {
    selectedRows.value.push(item);
  }

  emit(
    'on-selected-rows',
    sortBy(selectedRows.value, item => item.order),
  );
};

const toggleAllRowsSelection = () => {
  if (selectedRows.value.length === props.list.length) {
    selectedRows.value = [];
  } else {
    selectedRows.value = [...props.list];
  }
  emit('on-selected-rows', selectedRows.value);
};
</script>

<style scoped></style>
