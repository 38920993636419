import { AxiosInstance } from 'axios';
import cuesheetApi from './cuesheetApi';
import cuesheetItemApi from './cuesheetItemApi';

export default ($axios: AxiosInstance) => {
  return {
    basic: cuesheetApi($axios),
    detail: cuesheetItemApi($axios),
  };
};
