<template>
  <div class="border rounded-md bg-white overflow-auto">
    <GTable
      id="cueArticleSearchList"
      :list="cueArticleSearchList"
      :is-loading="isLoading"
      :multiselect="false"
      :draggable="draggable"
      :sort="false"
      :height="height"
      :pagination="pagination"
      :selected-row-index="selectedRowIndex"
      :no-data-message="t('CueArticleSearchList.데이터가_없습니다')"
      :group="{ name: 'cuesheet', pull: 'clone', put: false }"
      @on-row-clicked="onRowClicked"
      @on-row-db-clicked="onRowDbClicked"
      @is-dragging="isDragging"
      @is-finished-drag="endDragging"
      @on-changed-page="onChangedPage"
    >
      <template #header>
        <!-- <th>link</th> -->
        <th class="p-2 w-6">{{ t('CueArticleSearchList.순번') }}</th>
        <th class=""></th>
        <th class=""></th>
        <th class=""></th>
        <th class=""></th>
        <th class=""></th>
        <th class="p-2">{{ t('CueArticleSearchList.형식') }}</th>
        <th class="p-2">{{ t('CueArticleSearchList.제목') }}</th>
        <th class="p-2">{{ t('CueArticleSearchList.기자') }}</th>
        <th class="p-2">{{ t('CueArticleSearchList.작성일시') }}</th>
        <th class="p-2">{{ t('CueArticleSearchList.길이') }}</th>
      </template>

      <template #list="{ row: row, index }">
        <CueArticleSearchListItem :cue-article="row" :index="index" />
      </template>
    </GTable>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { GTable /*GIconButton*/ } from '@/components/ui';
import CueArticleSearchListItem from './CueArticleSearchListItem';

export default defineComponent({
  name: 'CueArticleSearchList',
  components: {
    GTable,
    // GIconButton,
    CueArticleSearchListItem,
  },
  props: {
    cueArticleSearchList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: () => {
        return null;
      },
    },
    height: {
      type: String,
      default: 'h-[calc(100vh-330px)]',
      // default: 'h-[calc(50vh-145px)]',
    },
    selectedRowIndex: {
      type: Number,
    },
    pagination: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  emits: [
    'on-row-clicked',
    'on-row-db-clicked',
    'is-dragging',
    'is-finished-drag',
    'on-changed-page',
  ],
  setup(props, { emit }) {
    const { t } = useI18n();

    // 행 클릭
    const onRowClicked = rowData => {
      emit('on-row-clicked', rowData?.row);
    };

    // 행 클릭
    const onRowDbClicked = rowData => {
      emit('on-row-db-clicked', rowData?.row);
    };

    // 드래그 시작 시점
    const isDragging = (isDragging, e) => {
      emit('is-dragging', isDragging, e);
    };

    // 드래그 끝나는 시점
    const endDragging = parmas => {
      emit('is-finished-drag', parmas);
    };

    // 페이지 변경
    const onChangedPage = changePage => {
      emit('on-changed-page', changePage);
    };

    return {
      t,
      endDragging,
      onRowClicked,
      onRowDbClicked,
      isDragging,
      onChangedPage,
    };
  },
});
</script>

<style scoped></style>
