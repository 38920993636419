import { useToast, POSITION } from 'vue-toastification';

const toast = useToast();

type params = {
  message: string;
  duration: number;
};

export default {
  /**
   * 성공 메세지
   *
   * @param {{message, duration}} config
   */
  success({ message, duration }: params) {
    toast.success(message, {
      timeout: duration || 1000,
      hideProgressBar: true,
      position: POSITION.BOTTOM_RIGHT,
    });
  },
  /**
   * 정보(info) 메세지
   *
   * @param {{message, duration}} config
   */
  info({ message, duration }: params) {
    toast.info(message, {
      timeout: duration || 1000,
      hideProgressBar: true,
      position: POSITION.BOTTOM_RIGHT,
    });
  },
  /**
   * 오류 메세지
   *
   * @param {{message, duration}} config
   */
  error({ message, duration }: params) {
    toast.error(message, {
      timeout: duration || 1000,
      hideProgressBar: true,
      position: POSITION.BOTTOM_RIGHT,
    });
  },
};
