<template>
  <div class="flex items-center space-x-2 lg:space-x-4">
    <router-link
      v-for="nav in navigation"
      :key="nav.name"
      :to="nav.path"
      :target="nav.target"
      class="h-12 px-2 relative group hover:text-primary text-gray-900 flex items-center cursor-pointer"
      :class="isActive(nav) ? 'text-primary' : ''"
    >
      <span class="whitespace-nowrap">{{ nav.title }}</span>
    </router-link>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { adminRoutes } from '@/router/admin-routes';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { AdminRouterName } from '@/types/router';

export default defineComponent({
  name: 'TheAdminNav',
  components: {},

  setup() {
    const { t } = useI18n();
    const route = useRoute();

    let navigation = [];

    navigation = adminRoutes
      .filter(route => route?.meta.menu)
      .map(route => {
        return {
          name: route.name,
          title: t(`Menu.${route.name}`),
          path: route.path,
          target: route.meta.menu.target || null,
        };
      });

    const isActive = item => {
      // 그룹 생성, 수정일 때는 그룹 메뉴 활성화
      if (
        (route?.name === AdminRouterName.CreateGroup ||
          route?.name === AdminRouterName.EditGroup) &&
        item.name === AdminRouterName.Group
      ) {
        return true;
      }
      return route?.name === item.name;
    };

    return {
      t,
      navigation,
      isActive,
    };
  },
});
</script>
