<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <div v-show="false">
    <div id="printContent" class="">
      <template v-for="(content, index) in printList" :key="`page-${index}`">
        <table class="main border-gray" style="width: 100%">
          <thead style="page-break-before: always">
            <tr>
              <!-- 제목 영역 -->
              <div class="article-title-section border-gray">
                <div class="ellipsis font-semibold">
                  {{ content.artclTitl || ' ' }}
                </div>
              </div>
              <!-- 메타 데이터 영역 -->
              <div class="article-meta-section border-gray">
                <div class="article-meta">
                  <span class="truncate">
                    {{ content?.setReporterName }}
                  </span>
                </div>
                <div class="article-meta">
                  출고:
                  {{
                    content.apprvTypDtm
                      ? defaultDateTimeHm(content?.apprvTypDtm)
                      : ''
                  }}
                </div>
                <div class="article-meta">
                  {{
                    content.artclDivCd !== articleDivisionCode.basic.id
                      ? setEmbargoTime(content)
                      : '일반 : '
                  }}
                </div>
                <div class="article-meta">
                  길이:{{ content.totalArticleTime }}
                </div>
              </div>
              <!-- 방송 시간영역 -->
              <div class="broadcast-time-section">
                <div class="broadcast-time border-gray">방송</div>
                <template v-for="number in 24" :key="`time-${number}`">
                  <div
                    v-if="number > 3"
                    class="broadcast-time border-gray"
                    :class="number === 24 ? 'broadcast-time-end' : ''"
                  >
                    {{ addLeadingZero(number) }}
                  </div>
                </template>
              </div>
            </tr>
          </thead>

          <tbody>
            <!-- 기사 영역 -->
            <div class="">
              <div
                v-for="(caption, number) in content.captions"
                :key="`content-line-${number}`"
                class="content-line"
              >
                <div class="caption border-gray">
                  {{ caption }}
                </div>
                <div
                  class="article border-gray"
                  :class="
                    number === content.captions.length - 1
                      ? 'article-line-end'
                      : ''
                  "
                >
                  {{ content.articles[number] }}
                </div>
              </div>
            </div>
          </tbody>
          <!-- <div class="footer">
              <div class="">{{ userName }}({{ userId }}) {{ printDate }}</div>
              <div class="page">
                {{ cruuentPage(1) }} /
                {{ printList[printList.length - 1].totalCount }}
              </div>
            </div> -->
        </table>
      </template>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, onMounted, ref } from 'vue';
import { useUserInfo } from '@/hooks/use-user-info';
import printJS from 'print-js';
// import { defaultDateTime } from '@/utils/format';
import { articleDivisionCode } from '@/codes';
import { defaultDate, defaultDateTimeHm } from '@/utils/format';
// import Caption from '@/components/article/core/Caption';
// import { find } from 'lodash';
import {
  addLeadingZero,
  concatArrays,
  concatenateArrays,
  getArticleLineCount,
  getCaptionLineCount,
  getInitialArticleText,
  processCaptions,
  getReporterName,
} from '@/utils/print';
import articleTypeCode from '@/codes/article-type-code';

const props = defineProps({
  printData: {
    type: Array,
    default: () => {
      return [];
    },
  },
});

const emit = defineEmits(['close-print']);

const { userId, userName } = useUserInfo();
// const printDate = defaultDateTime(new Date());
const printList = ref();
// let page = 0;
// const cruuentPage = index => {
//   page += index;
//   return page;
// };

// 엠바고 날짜
const setEmbargoTime = content => {
  let date = '일반 : ';
  if (content.artclDivCd === articleDivisionCode.expected.id) {
    date = `예정: ${defaultDate(content.artclDivDtm)}`;
  } else if (content.artclDivCd === articleDivisionCode.embargo.id) {
    date = `엠바고: ${defaultDateTimeHm(content.artclDivDtm)}`;
  }
  return date;
};

const printContent = () => {
  printJS({
    printable: 'printContent', // 프린트할 요소의 ID
    type: 'html', // 프린트할 내용의 타입
    css: ['/print/common.css', '/print/article-draft.css'],
    documentTitle: `${userName}(${userId})`, // 인쇄될 문서의 제목
    scanStyles: false,
    // onPrintDialogClose: onClosePrint,
  });
  setTimeout(() => {
    window.addEventListener('mouseover', handler);
  }, 1000);
};

const handler = () => {
  // Make sure the event only happens once.
  window.removeEventListener('mouseover', handler);

  // Remove iframe from the DOM, by default 'printJS'
  const iframe = document.getElementById(`printJS`);

  emit('close-print');
  if (iframe) {
    iframe.remove();
  }
};

const setDraftPrintList = articleList => {
  let list = [];
  // let sum = 0;
  list = articleList.map(item => {
    let anchorCaptionList = [];
    let reportCaptionList = [];
    if (
      articleTypeCode.Report.id === item.artclTypCd ||
      articleTypeCode.CrossTalk.id === item.artclTypCd
    ) {
      anchorCaptionList.push(
        ...item.anchorCaptionArray.map((caps, idx) => {
          return {
            captions: caps,
            articleLineCount: getCaptionLineCount(
              caps,
              getArticleLineCount(
                getInitialArticleText(item.anchorTextArray[idx], 3),
              ).length,
            ),
          };
        }),
      );
    }
    reportCaptionList.push(
      ...item.reporterCaptionArray.map((caps, idx) => {
        return {
          captions: caps,
          articleLineCount: getCaptionLineCount(
            caps,
            getArticleLineCount(
              getInitialArticleText(item.reporterTextArray[idx], 5),
            ).length,
          ),
        };
      }),
    );

    return {
      ...item,
      setReporterName: getReporterName(item),
      artclTitl: item?.artclTitl || item?.cueItemTitl,
      captions: processCaptions(
        concatArrays(anchorCaptionList, reportCaptionList, item.artclTypCd),
      ),
      articles: concatenateArrays(
        item.anchorTextArray,
        item.reporterTextArray,
        item.artclTypCd,
      ),

      // totalCount: (sum += concatenateArrays(
      //   item.anchorTextArray,

      //   item.reporterTextArray,
      //   item.artclTypCd,
      // ).length),
    };
  });
  return list;
};

printList.value = setDraftPrintList(props.printData);
console.log('draft :: ', printList.value);

onMounted(() => {
  printContent();
});
</script>

<style scoped></style>
