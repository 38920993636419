import { AxiosInstance } from 'axios';

const PREFIX = '/mbc-article';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 서울 MBC 기사 목록 조회
     * @param {Object} option query string
     * @param {string} option.sdate 검색 시작일
     * @param {string} option.edate 검색 종료일
     * @param {number} option.startPosition 페이지
     * @param {number} option.returnCount 데이터 개수
     * @param {string} option.search_word 검색 키워드
     */
    findAll: async (params: any) => {
      return $axios
        .get(`${PREFIX}`, { params })
        .then(response => response.data);
    },

    /**
     * 서울 기사 조회 (서울 네트워크팀 API)
     * @param params
     */
    findAllTransfer: async (params: any) => {
      return $axios
        .get(`${PREFIX}/transfer`, { params })
        .then(response => response.data);
    },

    /**
     * 서울 MBC 기사 상세 조회
     * @param {string} articleId 기사 ID
     */
    findOne: async (articleId: number) => {
      return $axios
        .get(`${PREFIX}/${articleId}`)
        .then(response => response.data);
    },

    /**
     * 서울 MBC 기사 송고
     * @param {string} articleId 기사 ID
     */
    transfer: async (articleId: number) => {
      return $axios
        .post(`${PREFIX}/${articleId}/transfer`)
        .then(response => response.data);
    },

    /**
     * 서울 MBC 기사 내 기사로 복사
     * @param articleId 기사 ID
     * @param params 복사 상태값
     */
    copyToMyArticle: async (articleId: number, params: any) => {
      return $axios
        .post(`${PREFIX}/${articleId}/copy/my-article`, params)
        .then(response => response.data);
    },

    /**
     * 서울 MBC 기사 취재 데스크로 복사
     * @param articleId 기사 ID
     * @param params 복사 상태값
     */
    copyToArticle: async (articleId: number, params: any) => {
      return $axios
        .post(`${PREFIX}/${articleId}/copy/article`, params)
        .then(response => response.data);
    },
  };
};
