import { Code, EnumEntry } from './base';

class symbolCode extends EnumEntry<Code> {
  typeSymbol: Code = {
    id: 'symbol_typ_001',
    name: '형식',
    label: '형식',
  };

  videoSymbol: Code = {
    id: 'symbol_typ_002',
    name: 'video',
    label: 'video',
  };

  audioSymbol: Code = {
    id: 'symbol_typ_003',
    name: ' audio',
    label: ' audio',
  };

  values: Array<Code> = [this.typeSymbol, this.videoSymbol, this.audioSymbol];
}

export default new symbolCode();
