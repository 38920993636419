import { AxiosInstance } from 'axios';

const PREFIX = '/spell-checker';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 맞춤법 검사
     * @param {String} - 문자열
     */
    findAll: async (params: string) => {
      return $axios.post(`${PREFIX}`, params).then(response => response.data);
    },
  };
};
