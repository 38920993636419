<template>
  <div class="space-y-2">
    <!-- Filter 영역 -->
    <div class="flex items-center flex-wrap gap-1 p-1">
      <RangeDatePickerEx v-model="dates" @change="onChangeDate" />
      <!-- 기자 -->
      <WorkerSelect
        v-model="user"
        all-option
        :company-code="companyId"
        @select="getArticles"
      />
      <!-- 출고 여부 -->
      <ApproveSelect
        v-model="approveType"
        all-option
        width="w-36"
        @select="getArticles"
      />
      <!-- 프로그램 -->
      <ProgramSelect
        v-model="program"
        all-option
        :company-code="companyId"
        width="w-36"
        @select="getArticles"
      />
      <!-- 형식 -->
      <ArticleTypeSelect
        v-model="type"
        all-option
        width="w-40"
        name="article-type"
        @select="getArticles"
      />
      <GInput
        v-model="regionSearchInput"
        name="regionSearchInput"
        :placeholder="t('RefRegionArticle.검색어')"
        width="w-36"
        @keyup.enter="getArticles"
      />
      <GButton icon="magnifying-glass" @click="getArticles" />
    </div>

    <!-- 테이블 영역 -->
    <div class="flex-1 overflow-auto space-y-1.5">
      <!-- 테이블 -->
      <MyRegionArticleList
        :is-loading="isLoading"
        :articles="articles"
        :pagination="paginationInfo"
        :selected-row-index="selectedRowIndex"
        height="h-[calc(100vh-367px)]"
        @on-row-clicked="onRowClicked"
        @on-row-db-clicked="onRowDbClicked"
        @on-changed-page="onChangePage"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { GButton, GInput } from '@/components/ui';
import RangeDatePickerEx from '@/components/common/RangeDatePickerEx';
import { MyRegionArticleList } from '@/components/coverDesk';
import {
  ArticleTypeSelect,
  ProgramSelect,
  WorkerSelect,
  ApproveSelect,
} from '@/components/select';
import { defaultDate } from '@/utils/format';
import { useUserInfo } from '@/hooks/use-user-info';
import { useMarsLApi } from '@/apis/mars-l-api';
import { PageConst } from '@/enums';

export default defineComponent({
  name: 'RefRegionArticle',
  components: {
    GButton,
    GInput,
    MyRegionArticleList,
    RangeDatePickerEx,
    ArticleTypeSelect,
    ProgramSelect,
    WorkerSelect,
    ApproveSelect,
  },
  emits: ['on-row-clicked', 'on-row-db-clicked'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const { companyId } = useUserInfo();
    const marsLApi = useMarsLApi();

    const isLoading = ref(false);
    const dates = ref([
      defaultDate(new Date(new Date().setDate(new Date().getDate() - 6))),
      defaultDate(new Date(new Date().setDate(new Date().getDate() + 1))),
    ]);
    const user = ref();
    const approveType = ref(null);
    const program = ref(null);
    const type = ref(null);
    const regionSearchInput = ref(null);
    const articles = ref([]);
    const selectedArticle = ref(null);
    const paginationInfo = ref(null);
    const page = ref(1);
    const selectedRowIndex = ref(0);

    const onSelectedRow = row => {
      selectedArticle.value = row;
    };

    // 기사 가져오기
    const getArticles = async () => {
      selectedArticle.value = null;

      try {
        isLoading.value = true;
        const params = {
          startDate: dates.value[0],
          endDate: dates.value[1],
          apprvTypCds: approveType.value?.id, // 출고여부
          brdcPgmId: program.value?.id, // 방송프로그램ID
          chDivIds: companyId,
          page: page.value - 1,
          size: PageConst.size,
          rptrId: user.value?.id,
          artclTypCd: type.value?.id,
          searchValue: regionSearchInput.value, // 검색키워드
        };

        const response = await marsLApi.coverDesk.basic.findAllElastic(params);

        articles.value = [];
        articles.value = response.content;
        paginationInfo.value = {
          isShow: true,
          page: page.value,
          totalPage: response.totalPages,
          totalCount: response.totalElements,
        };
      } catch (error) {
        console.log('error', error);
      } finally {
        isLoading.value = false;
      }
    };

    // 날짜 변경
    const onChangeDate = date => {
      dates.value = date;
      getArticles();
    };

    // 페이지 변경
    const onChangePage = changePage => {
      page.value = changePage;
      getArticles();
    };

    // 행 선택
    const onRowClicked = rowData => {
      selectedRowIndex.value = rowData.index;
      selectedArticle.value = rowData?.row;
      emit('on-row-clicked', rowData?.row);
    };

    // 행 더블 클릭
    const onRowDbClicked = rowData => {
      selectedRowIndex.value = rowData?.index;
      selectedArticle.value = rowData?.row;
      emit('on-row-db-clicked', rowData?.row);
    };

    // 초기화
    const init = async () => {
      await getArticles();
    };

    onMounted(() => {
      init();
    });

    return {
      t,
      companyId,
      isLoading,
      approveType,
      articles,
      dates,
      user,
      type,
      program,
      regionSearchInput,
      paginationInfo,
      selectedArticle,
      selectedRowIndex,
      onSelectedRow,
      onChangeDate,
      getArticles,
      onRowClicked,
      onRowDbClicked,
      onChangePage,
    };
  },
});
</script>
