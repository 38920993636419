<template>
  <GModal
    :changed="false"
    :dialog-overlay="false"
    :width="!pageOptions ? 'w-1/5' : ''"
    :max-width="!pageOptions ? 'w-1/5' : ''"
    @close="onClose"
  >
    <!-- title -->
    <template #title>
      <span class="text-white font-D2CodingBold">
        {{ t('Modal.Article.ArticlePrintModal.프린트_옵션') }}
      </span>
    </template>

    <!-- 내용 -->
    <template #content>
      <div class="p-6">
        <!-- 경고 문구 -->
        <div
          v-if="embargoMessage"
          class="bg-red-50 border-t-4 border-red-300 text-red-500 font-D2CodingBold px-2 py-1 rounded-b-lg mb-2"
          role="alert"
        >
          <div class="flex items-center">
            <div class="py-1">
              <svg
                class="fill-current h-6 w-6 text-orange-400 mr-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
                />
              </svg>
            </div>
            <div>
              <p class="text-xs font-D2CodingBold">{{ embargoMessage }}</p>
            </div>
          </div>
        </div>
        <div class="space-x-2 space-y-4" :class="pageOptions ? 'flex' : ''">
          <div class="space-y-0.5">
            <div class="flex justify-between">
              <div class="text-sm">
                {{ t('Modal.Article.ArticlePrintModal.출력형태') }}
              </div>
              <div class="text-right">
                <label
                  class="flex text-xs space-x-1 items-center text-gray-700"
                >
                  <span>{{
                    t('Modal.Cuesheet.CuesheetPrintModal.맞춤_설정')
                  }}</span>
                  <input
                    v-model="pageOptions"
                    type="checkbox"
                    name="print-options"
                  />
                  <span class="inline-block p-2.5"></span>
                </label>
              </div>
            </div>
            <div class="space-y-1.5 px-3 py-3 rounded-md bg-gray-100">
              <GRadioButton
                v-model="option"
                name="option"
                :label="t('Modal.Article.ArticlePrintModal.원고')"
                :value="printCode?.article.id"
                font-size="text-sm"
                :default="true"
              />
              <GRadioButton
                v-model="option"
                name="option"
                :label="t('Modal.Article.ArticlePrintModal.자막')"
                :value="printCode?.caption.id"
                font-size="text-sm"
              />
              <GRadioButton
                v-model="option"
                name="option"
                :label="t('Modal.Article.ArticlePrintModal.압축_플러스_자막')"
                :value="printCode?.compactPlus.id"
                font-size="text-sm"
              />
              <GRadioButton
                v-model="option"
                name="option"
                :label="t('Modal.Article.ArticlePrintModal.압축_마이너스_자막')"
                :value="printCode?.compactMinus.id"
                font-size="text-sm"
              />
            </div>

            <!-- 하단 Submit 버튼 세트 -->
            <div class="sm:flex pt-4 pb-2 justify-end">
              <div class="space-x-3 flex items-center">
                <GButton type="secondary" @click="onClose">{{
                  t('Modal.Article.ArticlePrintModal.취소')
                }}</GButton>
                <GButton
                  :disabled="printList.length === 0 && pageOptions"
                  @click="onPrint"
                >
                  {{ t('Modal.Article.ArticlePrintModal.인쇄') }}
                </GButton>
              </div>
            </div>
          </div>

          <div v-if="pageOptions" class="">
            <PrintItemList :list="list" @on-selected-rows="onSelectedRows" />
          </div>
        </div>
      </div>
    </template>
  </GModal>

  <!-- 원고 프린트 -->
  <ArticleDraftPage
    v-if="printType === printCode?.article.id"
    :print-data="setPrint"
    @close-print="printType = null"
  />

  <!-- 자막 프린트 -->
  <ArticleCaptionPrintPage
    v-if="printType === printCode?.caption.id"
    :print-data="setPrint"
    @close-print="printType = null"
  />

  <!-- 압축(자막+) 프린트 -->
  <ArticleCompressedPlusPrintPage
    v-if="printType === printCode?.compactPlus.id"
    :print-data="setPrint"
    @close-print="printType = null"
  />

  <!-- 압축(자막-) 프린트 -->
  <ArticleCompressedMinusPrintPage
    v-if="printType === printCode?.compactMinus.id"
    :print-data="setPrint"
    @close-print="printType = null"
  />
</template>

<script setup>
import { /*defineProps, defineEmits,*/ ref, watch, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import { GModal, GButton, GRadioButton } from '@/components/ui';
import {
  ArticleCaptionPrintPage,
  ArticleCompressedPlusPrintPage,
  ArticleCompressedMinusPrintPage,
  ArticleDraftPage,
} from '@/components/print';
import printCode from '@/codes/print-code';
import { PrintItemList } from '@/components/modal/cuesheet';
import { initCaptions, checkedEmbargo } from '@/utils/print';

const props = defineProps({
  printData: {
    type: Array,
    default: () => {
      return [];
    },
  },
  list: {
    type: Array,
    default: () => {
      return [];
    },
  },
});
const emit = defineEmits(['close']);

const { t } = useI18n();
const option = ref(printCode?.article.id);
const pageOptions = ref(false);
const printType = ref(null);
const printList = ref(props.list);
const setPrint = ref();
const embargoMessage = ref();

const onSelectedRows = list => {
  embargoMessage.value = checkedEmbargo(list)
    ? '엠바고 기사가 포함되었습니다.'
    : '';
  printList.value = list;
};

const onPrint = () => {
  if (pageOptions.value) {
    // printList.value
    setPrint.value = printList.value.map(item => {
      return initCaptions(item);
    });
  } else {
    setPrint.value = props.printData;
  }
  printType.value = option.value;
  nextTick(() => {
    emit('close');
  });
};
const onClose = () => {
  emit('close');
};

watch(
  () => pageOptions.value,
  () => {
    if (pageOptions.value) {
      printList.value = [];
      embargoMessage.value = '';
    } else {
      printList.value = props.list;
      embargoMessage.value = checkedEmbargo(props.printData)
        ? '엠바고 기사입니다.'
        : '';
    }
  },
  { immediate: true },
);
watch(
  () => props.printData,
  () => {
    embargoMessage.value = checkedEmbargo(props.printData)
      ? '엠바고 기사입니다.'
      : '';
  },
  { immediate: true },
);
</script>
