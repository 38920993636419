import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["inline-flex whitespace-nowrap items-center px-2.5 py-0.5 rounded-full text-xs font-medium", `bg-${_ctx.color}-100 text-${_ctx.color}-800`])
  }, [
    _createTextVNode(_toDisplayString(_ctx.text) + " ", 1),
    (!_ctx.text)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 })
      : _createCommentVNode("", true)
  ], 2))
}