<template>
  <div class="px-2 py-1">
    <!-- 버튼 -->
    <div class="flex border-b py-1 px-1.5 justify-end space-x-1.5 pb-2">
      <!-- v-if="mode !== 'view'" -->
      <GSaveButton
        v-tooltip="'저장'"
        icon="arrow-down-tray"
        :text="'저장'"
        :disabled="mode === 'view'"
        :loading="isLoadingSubmit"
        @click="onSaveArticle"
      />
      <!-- <GButton v-tooltip="'취소'" icon="x-mark" @click="onClose" /> -->
      <!-- 인쇄 -->
      <GButton
        v-tooltip="'인쇄'"
        icon="printer"
        :disabled="isLoadingSubmit"
        @click="isShowPrintOptionModal = true"
      />
    </div>

    <!-- 내용 -->
    <div v-if="isLoading" class="pt-20">
      <PageLoader />
    </div>
    <div
      v-if="!isLoading"
      class="flex justify-center border-gray-200 bg-slate-100"
    >
      <div class="flex">
        <!-- 기사 작성 영역 -->
        <div class="flex border-r border-gray-300 bg-white">
          <div class="p-1.5 space-y-1">
            <!-- 버튼 영역 -->
            <div
              v-if="mode !== 'view' && !isCuesheet"
              class="flex justify-end items-center"
            >
              <!-- 자막완료 여부 -->
              <!-- <GSwitch
                v-tooltip="'자막완료 여부'"
                v-model="isCompleteCap"
                @change="onChangeCapComplete"
              /> -->
              <div class="flex justify-end space-x-1.5">
                <GIconButton
                  v-tooltip="'특수문자 입력'"
                  icon="special-key"
                  @click="isShowSpecialCharModal = true"
                />
                <GIconButton
                  v-tooltip="'앵커'"
                  icon="anchor"
                  @click="onInputMacro('anchor')"
                />
                <GIconButton
                  v-tooltip="'리포트'"
                  icon="camera"
                  @click="onInputMacro('reporter')"
                />
                <GIconButton
                  v-tooltip="'INT'"
                  icon="int"
                  @click="onInputMacro('int')"
                />
                <GIconButton
                  v-tooltip="'SYNC'"
                  icon="sync"
                  @click="onInputMacro('sync')"
                />
                <GIconButton
                  v-tooltip="'CG'"
                  icon="cg"
                  @click="onInputMacro('cg')"
                />
                <GIconButton
                  v-tooltip="'ST-UP'"
                  icon="stup"
                  @click="onInputMacro('st-up')"
                />
                <GIconButton
                  v-tooltip="'END'"
                  icon="end"
                  @click="onInputMacro('end')"
                />
              </div>
            </div>

            <!-- 제목 -->
            <div class="flex space-x-1 text-xs pb-1">
              <GInput
                v-model="cueItemTitle"
                name="cueItemTitle"
                :title="cueItemTitle"
                :disabled="
                  mode === 'view' ||
                  cuesheetItemDivCode === cuesheetItemCode.ARTICLE.id
                "
                placeholder="제목을 입력하세요."
                text-size="text-[15px]"
              />

              <!-- 기사 복사 -->
              <GButton
                v-if="mode === 'view'"
                v-tooltip="'자막 복사'"
                icon="clipboard-document-list"
                @click="onClickedCaptionCopy"
              />

              <!-- 기사 복사 -->
              <GButton
                v-if="mode === 'view'"
                v-tooltip="'기사 복사'"
                icon="clipboard-document"
                @click="onClickedArticleCopy"
              />
            </div>

            <!-- 작성기 영역 -->
            <div
              class="overflow-y-auto"
              :class="
                mode === 'view'
                  ? 'h-[calc(100vh-220px)]'
                  : 'h-[calc(100vh-255px)]'
              "
            >
              <ArticleEditor
                ref="articleEditor"
                v-if="type"
                :type-id="type"
                v-model:reporter-text-array="
                  articleEditorState.reporterTextArray
                "
                v-model:anchor-text-array="articleEditorState.anchorTextArray"
                v-model:caption-array="articleEditorState.reporterCaptionArray"
                :reporter-editor-read-only="
                  mode === 'view' ||
                  cuesheetItemDivCode === cuesheetItemCode.ARTICLE.id
                    ? true
                    : false
                "
                :reporter-caption-read-only="
                  mode === 'view' ||
                  cuesheetItemDivCode === cuesheetItemCode.ARTICLE.id
                    ? true
                    : false
                "
                :anchor-editor-read-only="
                  mode === 'view' ||
                  cuesheetItemDivCode === cuesheetItemCode.ARTICLE.id
                    ? true
                    : false
                "
                :anchor-caption-read-only="
                  mode === 'view' ||
                  cuesheetItemDivCode === cuesheetItemCode.ARTICLE.id
                    ? true
                    : false
                "
              />
            </div>
          </div>
        </div>
      </div>

      <!-- 메타 데이터 영역 -->
      <div class="w-[30rem] bg-slate-200">
        <GTabPanel :tabs="tabs" :selected-tab-id="selectedTabId">
          <TabPanel :unmount="false">
            <div
              class="overflow-auto"
              :class="
                mode === 'view' || isCuesheet
                  ? 'h-[calc(100vh-220px)]'
                  : 'h-[calc(100vh-255px)]'
              "
            >
              <ArticleMetaData
                ref="articleMetaDataRef"
                v-model:article-type="articleType"
                v-model:article-reporter="reporter"
                v-model:article-program="program"
                v-model:total-article-time="totalArticleTime"
                v-model:article-division="division"
                v-model:article-embargo="embargo"
                v-model:article-category="category"
                v-model:article-tag="tag"
                v-model:article-extra-time="extraTime"
                v-model:article-extra-time-type="extraTimeType"
                v-model:article-inputer="inputer"
                v-model:article-transfer-date="transferDate"
                v-model:article-approve-date="approveDate"
                v-model:article-updateDate="updateDate"
                :article-channel-id="articleChannelId"
                :cuesheet-item-division-code="cuesheetItemDivCode"
                v-model:is-save="isSave"
                :is-cuesheet="isCuesheet"
                :article-mode="mode"
                :is-same-channel="isSameChannel"
              />
            </div>
          </TabPanel>
          <TabPanel :unmount="false">
            <!-- <ArticleAttachFile @files="onGetFiles" /> -->
          </TabPanel>
          <TabPanel :unmount="false">
            <!-- <ArticleLink /> -->
          </TabPanel>
        </GTabPanel>
      </div>
    </div>

    <!-- 특수 문자 모달 -->
    <SpecialCharModal
      v-if="isShowSpecialCharModal"
      @selected-char="onSelectedChar"
      @close="isShowSpecialCharModal = false"
    />

    <!-- 프린트 옵션 모달 -->
    <ArticlePrintModal
      v-if="isShowPrintOptionModal"
      :print-data="printData"
      @close="isShowPrintOptionModal = false"
    />
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, onMounted } from 'vue';
import {
  GButton,
  GIconButton,
  GTabPanel,
  GInput,
  GSaveButton,
} from '@/components/ui';
import { TabPanel } from '@headlessui/vue';
import { ArticleMetaData } from '@/components/article';
import ArticleEditor from '@/components/article/ArticleEditor';
import {
  articleTypeCode,
  cuesheetItemCode,
  articleDivisionCode,
} from '@/codes';
import { useStore } from 'vuex';
import { useMarsLApi } from '@/apis/mars-l-api';
import { setEditorValues } from '@/utils/editor-helper';
import {
  calculateSpeed,
  setMacro,
  changeByte,
  calculateArticleTime,
  makeDBCaptionForm,
} from '@/utils/article-helper';
import {
  secondToMs,
  displayDateTimeHm,
  defaultDate,
  defaultDateTime,
} from '@/utils/format';
import { useForm } from 'vee-validate';
import yup from '@/utils/yup';
import { parse } from '@/utils/videotime.js';
import SpecialCharModal from '@/components/modal/article/SpecialCharModal';
import { isMyCompany } from '@/utils/compare-helper';
import PageLoader from '@/components/common/PageLoader.vue';
import ArticlePrintModal from '@/components/modal/article/ArticlePrintModal.vue';
import { initCaptions } from '@/utils/print';
import { cloneDeep } from 'lodash';

const props = defineProps({
  cuesheetId: {
    type: [String, Number],
  },
  cuesheetItemId: {
    type: [String, Number],
  },
  templateId: {
    type: [String, Number],
  },
  mode: {
    type: String,
    default: 'view',
  },
  isCuesheet: {
    type: Boolean,
    default: false,
  },
});
defineEmits(['close', 'on-submit']);

const tabs = ref([
  { id: 1, name: '메타데이터' },
  //   { id: 2, name: '첨부파일' },
  //   { id: 3, name: '링크' },
]);
let selectedTabId = ref(0);
const type = ref(null);
const store = useStore();
const marsLApi = useMarsLApi();
const isShowPrintOptionModal = ref(false);
const printData = ref([]);
const articleEditor = ref();

const articleEditorState = ref({
  anchorTextArray: [''],
  anchorCaptionArray: [[]],
  reporterTextArray: [''],
  reporterCaptionArray: [[]],
});
const cueItemTitle = ref();
const program = ref();
const isSave = ref(false);
const isSameChannel = ref(false);
const isLoading = ref(false);
const isLoadingSubmit = ref(false);
const isShowSpecialCharModal = ref(false);
const cuesheetItemDivCode = ref();
const totalArticleTime = ref(0);
const extraTime = ref('00:00');
const extraTimeType = ref('+');
let speed = null;
const articleMetaDataRef = ref(null);
const transferDate = ref();
const approveDate = ref();
const articleType = ref();
const reporter = ref();
const division = ref();
const embargo = ref();
const approveTypeCd = ref();
const updateDate = ref();
const category = ref();
const inputer = ref(null); // 작성자
const tag = ref([]); // 태그
const articleChannelId = ref(0);
const isCompleteCap = ref(false);
const articleObj = ref();
const originArticleObj = ref();

// const onClose = () => {
//   emit('close');
// };

// validation 적용
const { validate } = useForm({
  initialValues: {
    cueItemTitle: null,
  },
  validationSchema: yup.object().shape({
    cueItemTitle: yup.string().required(),
  }),
});

// 저장 버튼 클릭
const onSaveArticle = async valid => {
  try {
    isLoadingSubmit.value = true;
    const { extraTime, errorMsg, extraTimeType } = articleMetaDataRef.value;
    // 큐시트 (항목, 템플릿) 아이템 저장
    if (cuesheetItemDivCode.value !== cuesheetItemCode.ARTICLE.id) {
      /**
       * 항목 아이템은 필수값 체크 제외
       */
      if (cuesheetItemDivCode.value !== cuesheetItemCode.ITEM.id) {
        const res = await validate();

        // 필수 값 확인이 false인 경우, 탭을 메타데이터로 변경
        if (!res.valid || !valid) {
          selectedTabId.value = tabs.value[0].id;
          isLoadingSubmit.value = false;
          return;
        }
      }

      // extraTime 확인
      if (errorMsg) {
        isLoadingSubmit.value = false;
        return;
      }

      // 기사작성기 자막 형태를 DB형태로 변경
      // eslint-disable-next-line no-unused-vars
      const { articleAnchorCaps, articleCaps } = makeDBCaptionForm(
        articleTypeCode.Straight,
        articleEditorState.value,
      );

      const params = {
        cueId: props.cuesheetId,
        cueItemTypCd: cuesheetItemDivCode.value,
        cueItemTitl: cueItemTitle.value,
        brdcPgmId: program.value?.id,
        cueItemCtt: `${JSON.stringify(
          articleEditorState.value.reporterTextArray,
        )}`, // 리포트 내용
        cueItemTime: calculateSpeed(
          speed,
          changeByte(articleEditorState.value.reporterTextArray),
        ), // 리포트 시간
        artclExtTime:
          extraTimeType === '+' ? parse(extraTime) : -parse(extraTime), // 외부 시간
        cueSheetItemCap: articleCaps, // 리포트 자막
      };
      const response = await marsLApi.cueSheet.detail.update(
        props.cuesheetItemId,
        params,
      );

      if (response.success) {
        window.Notify.success({
          message: '아이템 수정이 완료되었습니다.',
        });
        getCuesheetItemInfo({ isLoadingView: false });
      }
    } else {
      const { extraTime, errorMsg } = articleMetaDataRef.value;
      let dataValidate = false;
      // 제목 필수값 체크
      dataValidate = await validate();
      // extraTime 확인
      if (!dataValidate.valid || errorMsg) {
        return;
      }
      const params = {
        cueId: props.cuesheetId,
        artclExtTime:
          extraTimeType === '+' ? parse(extraTime) : -parse(extraTime), // 외부 시간
      };
      const response = await marsLApi.cueSheet.detail.update(
        props.cuesheetItemId,
        params,
      );

      if (response.success) {
        window.Notify.success({
          message: '아이템 수정이 완료되었습니다.',
        });
        // emit('close');
      }
    }
  } catch (error) {
    console.log('error', error);
  } finally {
    isLoadingSubmit.value = false;
    store.commit('submit/RESET_STATE');
  }
};

// 선택한 매크로 글자 넣기
const onInputMacro = type => {
  const char = setMacro(type);
  articleEditor.value.insertTextAtCursor(char);
};

// 선택한 특수문자 가져오기
const onSelectedChar = char => {
  articleEditor.value.insertTextAtCursor(char);
};

// 기사 작성기 감지해서 기사 길이 계산
// watch(
//   () => articleEditorState.value,
//   newValue => {
//     // 메타데이터 탭에 표시
//     totalArticleTime.value = secondToMs(
//       calculateArticleTime(
//         newValue.anchorTextArray,
//         newValue.reporterTextArray,
//         speed,
//       ),
//     );
//   },
//   {
//     deep: true,
//   },
// );

/**
 * 큐시트 템플릿 아이템 상세 조회
 */

const getCuesheetItemInfo = async ({ isLoadingView = true } = {}) => {
  try {
    isLoading.value = isLoadingView;
    const response = await marsLApi.cueSheet.detail.finOne(
      props.cuesheetItemId,
    );

    if (response.success) {
      const {
        cueItemTitl,
        cueItemCtt,
        cueSheetItemCap,
        artclExtTime,
        cueItemDivCd,
        article, // 사본 기사 데이터
        oriArticle, // 원본 기사 데이터
      } = response.data;

      articleObj.value = article;
      originArticleObj.value = oriArticle;

      cuesheetItemDivCode.value = cueItemDivCd;
      type.value = null;
      cueItemTitle.value = cueItemTitl;

      // 큐시트 템플릿 아이템 or 큐시트 항목 아이템
      if (cuesheetItemDivCode.value !== cuesheetItemCode.ARTICLE.id) {
        articleEditorState.value = setEditorValues({
          artclTypCd: articleTypeCode.Straight.id,
          anchorCapList: [],
          articleCapList: cueSheetItemCap,
          ancMentCtt: JSON.stringify(['']),
          artclCtt: cueItemCtt || JSON.stringify(['\n']),
        });

        const cloneArticleEditor = cloneDeep(articleEditorState.value);
        printData.value = [
          initCaptions({ ...response.data, ...cloneArticleEditor }),
        ];

        type.value = articleTypeCode.Straight.id;
        extraTime.value = secondToMs(Math.abs(artclExtTime));
        extraTimeType.value = artclExtTime >= 0 ? '+' : '-';
        articleChannelId.value = 0;
        division.value = null;
        reporter.value = null;
        program.value = null;
      } else {
        // 큐시트 기사 아이템

        isSameChannel.value = oriArticle.isSameChannel;

        // 에디터 값 대입입
        articleEditorState.value = setEditorValues({
          artclTypCd: article.artclTypCd,
          anchorCapList: [],
          articleCapList: article.articleCaps,
          ancMentCtt: article.ancMentCtt || JSON.stringify(['']),
          artclCtt: article.artclCtt || JSON.stringify(['\n']),
        });

        // 데이터 원본
        const cloneArticleEditor = cloneDeep(articleEditorState.value);
        printData.value = [initCaptions({ ...article, ...cloneArticleEditor })];

        articleChannelId.value = article.chDivId;
        type.value = article.artclTypCd;
        extraTime.value = secondToMs(Math.abs(article.artclExtTime));
        extraTimeType.value = article.artclExtTime >= 0 ? '+' : '-';

        articleType.value = articleTypeCode.values.find(
          item => item.id === article.artclTypCd,
        );
        // TODO :: 구분 ArticleMEtadata 컴포넌트에서 response.artclDivCd만 보냈을때 감지 안되는건 나중에..
        division.value = articleDivisionCode.values.find(
          item => item.id === article.artclDivCd,
        );
        // 기자 (내 지역사이면 id, 다른 지역사이면 이름을 보낸다.)
        reporter.value = article.isSameChannel
          ? article.rptrNm
          : `${article.orgRptrNm}(${article.orgChNm})`;

        // 프로그램 (내 지역사이면 id, 다른 지역사이면 이름을 보낸다.)
        program.value = isMyCompany(articleChannelId.value)
          ? article.brdcPgmId
          : article.brdcPgmNm;

        category.value = article.artclCateNm; // 카테고리
        isCompleteCap.value = article.capFnshYn === 'Y' ? true : false;

        // 작성자
        // inputer.value = oriArticle ? oriArticle?.inputrNm : '';
        inputer.value = oriArticle.isSameChannel
          ? oriArticle?.inputrNm
          : `${oriArticle?.orgInputrNm}(${oriArticle.orgChNm})`;
        // 송고일시
        transferDate.value = oriArticle
          ? displayDateTimeHm(oriArticle?.inputDtm)
          : '';
        // 출고일시
        approveDate.value =
          oriArticle && oriArticle?.apprvTypDtm
            ? displayDateTimeHm(oriArticle?.apprvTypDtm)
            : '';
        // 업데이트 일시
        updateDate.value = oriArticle
          ? displayDateTimeHm(oriArticle.updtDtm)
          : '';

        tag.value = article.articleTags?.map(tag => {
          return {
            ...tag.tag,
            tagName: tag.tag.tagNm,
          };
        });
        // 엠바고
        embargo.value = setEmbargoDate(article.artclDivCd, article.artclDivDtm);
        approveTypeCd.value = article.apprvTypCd;
      }

      totalArticleTime.value = secondToMs(
        calculateArticleTime(
          articleEditorState.value.anchorTextArray,
          articleEditorState.value.reporterTextArray,
          speed,
        ),
      );
    }
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
};

// 기사 구분에 따른 날짜 포멧
const setEmbargoDate = (articleDiv, articleDivDate) => {
  let date = null;

  if (articleDiv === articleDivisionCode.expected.id) {
    date = defaultDate(articleDivDate);
  } else if (articleDiv === articleDivisionCode.embargo.id) {
    date = defaultDateTime(articleDivDate);
  }

  return date;
};

const onClickedCaptionCopy = () => {
  articleEditor.value.onCopyToCaption();
};

const onClickedArticleCopy = () => {
  articleEditor.value.onCopyToArticle();
};

onMounted(async () => {
  await store.dispatch('systemInfo/FIND_MY_SYSTEM_INFO');
  speed = store.getters['systemInfo/readSpeed'];

  await getCuesheetItemInfo();
});
</script>

<style lang="scss" scoped></style>
