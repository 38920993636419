<template>
  <svg
    :class="setSize"
    stroke-width="0.8"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color="currentColor"
  >
    <path
      d="M2 15.5V2.6a.6.6 0 01.6-.6h18.8a.6.6 0 01.6.6v12.9m-20 0v1.9a.6.6 0 00.6.6h18.8a.6.6 0 00.6-.6v-1.9m-20 0h20M9 22h1.5m0 0v-4m0 4h3m0 0H15m-1.5 0v-4"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>

<script>
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'ComputerIcon',
  props: {
    size: {
      type: String,
      default: 'h-5 w-5',
    },
  },

  setup(props) {
    const setSize = computed(() => {
      return props.size;
    });

    return {
      setSize,
    };
  },
});
</script>
