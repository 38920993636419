import { onActivated } from 'vue';

export function useScrollTop(element: HTMLElement) {
  element.addEventListener('scroll', event => {
    element.dataset.scrollTop = `${(event.target as HTMLElement).scrollTop}`;
  });

  // 탭이 활성화되었을 때, 이전의 스크롤 위치로 지정
  onActivated(() => {
    if (element) {
      const scrollTop = element.dataset.scrollTop;
      if (scrollTop) {
        element.scrollTo({
          top: parseInt(scrollTop),
        });
      }
    }
  });
}
