<template>
  <div class="flex flex-col h-screen overflow-hidden w-full">
    <!-- 헤더 영역 -->
    <AppLayoutHeader />

    <!-- 탭 영역 -->
    <TabList />

    <!-- 콘텐츠 영역 -->
    <main
      class="focus:outline-none bg-gray-100 h-full overflow-y-auto text-gray-900"
    >
      <router-view v-slot="{ Component }">
        <keep-alive ref="keepAlive" :include="includeComp">
          <component :is="Component" :key="$route.path" />
        </keep-alive>
      </router-view>
    </main>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import TheLogo from '@/components/TheLogo.vue';
import TheNav from '@/components/TheNav.vue';
import TabList from '@/components/tab/TabList.vue';
import UserProfile from '@/components/common/UserProfile';
import { useStore } from 'vuex';
import WebSocketPing from '@/components/WebSocketPing.vue';
import ZoomInOutButton from '@/components/ZoomInOutButton';
import AppLayoutHeader from '@/components/AppLayoutHeader.vue';

export default defineComponent({
  name: 'AppLayout',
  components: {
    TheLogo,
    TheNav,
    TabList,
    UserProfile,
    WebSocketPing,
    ZoomInOutButton,
    AppLayoutHeader,
  },

  setup() {
    const store = useStore();
    const user = ref(null);

    // 탭으로 생성된 컴포넌트는 데이터가 유지될 수 있도록 한다.
    const includeComp = computed(() => {
      const tabs = store.getters['tab/tabs'];

      let tabNames = null;
      if (tabs.length) {
        tabNames = tabs.map(tab => {
          return tab.name;
        });

        tabNames.join(',');
      }

      return tabNames;
    });

    return {
      includeComp,
      user,
    };
  },
});
</script>
