<template>
  <svg
    width="34px"
    height="34px"
    stroke-width="1.5"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color="currentColor"
  >
    <path
      d="M3 20.4V3.6a.6.6 0 01.6-.6h16.8a.6.6 0 01.6.6v16.8a.6.6 0 01-.6.6H3.6a.6.6 0 01-.6-.6z"
      stroke="currentColor"
      stroke-width="1.5"
    ></path>
    <path
      d="M6 18h12M12 14V6m0 0l3.5 3.5M12 6L8.5 9.5"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>

  <!-- 출고 글자 있는 아이콘 -->
  <!-- <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.2487 17.706V18.912H8.4457V17.706H7.2487ZM4.0987 17.004V17.877H11.6047V17.004H4.0987ZM7.2487 13.458V14.466H8.4367V13.458H7.2487ZM7.1767 14.52V14.682C7.1767 15.258 6.4117 15.825 4.3777 15.906L4.7107 16.797C7.0147 16.671 8.2297 15.879 8.2297 14.682V14.52H7.1767ZM7.4557 14.52V14.682C7.4557 15.879 8.6707 16.671 10.9657 16.797L11.2987 15.906C9.2737 15.825 8.5087 15.258 8.5087 14.682V14.52H7.4557ZM4.8007 14.115V14.988H10.8937V14.115H4.8007ZM4.9447 18.381V19.245H9.5257V19.641H4.9537V21.234H6.1327V20.442H10.7047V18.381H4.9447ZM4.9537 20.874V21.747H10.9207V20.874H4.9537ZM13.134 14.169V15.132H18.417V14.169H13.134ZM12.369 19.83V20.802H19.902V19.83H12.369ZM15.033 16.968V20.307H16.23V16.968H15.033ZM17.985 14.169V14.97C17.985 15.969 17.985 17.175 17.652 18.831L18.849 18.966C19.182 17.184 19.182 16.014 19.182 14.97V14.169H17.985Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.125 10.0303V9.03027H4C1.79086 9.03027 0 10.8211 0 13.0303V21C0 23.2091 1.79086 25 4 25H20C22.2091 25 24 23.2091 24 21V13.0303C24 10.8211 22.2091 9.03027 20 9.03027H15.75V10.0303H20C21.6569 10.0303 23 11.3734 23 13.0303V21C23 22.6568 21.6569 24 20 24H4C2.34315 24 1 22.6568 1 21V13.0303C1 11.3734 2.34314 10.0303 4 10.0303H7.125Z"
      fill="currentColor"
    />
    <line x1="11.5" y1="12.5386" x2="11.5" y2="2.01324" stroke="currentColor" />
    <line
      y1="-0.5"
      x2="7.65901"
      y2="-0.5"
      transform="matrix(-0.759371 0.650658 -0.530905 -0.847431 11.4998 1)"
      stroke="currentColor"
    />
    <line
      y1="-0.5"
      x2="7.60809"
      y2="-0.5"
      transform="matrix(0.788635 0.614862 -0.495238 0.868757 11.0002 1.7998)"
      stroke="currentColor"
    />
  </svg> -->
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ChulGoIcon',
  props: {},
  setup() {
    return {};
  },
});
</script>
