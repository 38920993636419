<template>
  <div class="flex flex-col space-y-2 w-full">
    <span v-if="itemLabel" class="text-xs inline-block">{{ itemLabel }}</span>
    <VueMultiselect
      ref="select"
      v-model="inputValue"
      :multiple="isMultiple"
      :track-by="trackBy"
      :label="label"
      :options="options"
      :placeholder="disabled || readonly ? '' : placeholder"
      :loading="isLoading"
      :allow-empty="allowEmpty"
      :selected-label="allowEmpty ? selectedLabel : ''"
      :select-label="selectLabel"
      :deselect-label="allowEmpty ? deselectedLabel : ''"
      :searchable="searchable"
      :disabled="disabled"
      :readonly="readonly"
      :preserve-search="preserveSearch"
      :hide-selected="hideSelected"
      :close-on-select="closeOnSelect"
      :group-values="groupValues"
      :group-label="groupLabel"
      :group-select="groupSelect"
      :open-direction="openDirection"
      :max-height="maxHeight"
      :custom-label="onCustomLabel"
      :style="[errorCls]"
      @select="onSelectedData"
    >
      <!-- @search-change="onSearchData" -->
      <template #singleLabel>
        <div
          class="truncate"
          :title="
            inputValue ? `${inputValue[label]} [${inputValue[trackBy]}]` : ''
          "
        >
          {{
            inputValue[trackBy]
              ? `${inputValue[label]} [${inputValue[trackBy]}]`
              : (inputValue = null)
          }}
        </div>
      </template>
      <template #noResult>
        <span class="text-xs">검색 결과가 없습니다.</span>
      </template>
      <template #noOptions>
        <span class="text-xs">검색 결과가 없습니다.</span>
      </template>
    </VueMultiselect>

    <p v-if="errorMessage || errors" class="mt-2 text-xs text-red-600">
      {{ errorMessage ? errorMessage : errors ? errors : '' }}
    </p>
  </div>
</template>

<script>
import { defineComponent, ref, watch, computed } from 'vue';
import VueMultiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';
import _ from 'lodash';
import { useField } from 'vee-validate';
import { isEquals } from '@/utils/object';

export default defineComponent({
  name: 'GSearchSelectUser',
  components: {
    VueMultiselect,
  },

  props: {
    modelValue: {
      type: Object,
      default: () => {
        return null;
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 선택한거 취소할건지 여부
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    trackBy: {
      type: String,
      default: 'value',
    },
    itemLabel: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'description',
    },
    selectLabel: {
      type: String,
      default: '선택',
    },
    selectedLabel: {
      type: String,
      default: '취소',
    },
    deselectedLabel: {
      type: String,
      default: '취소',
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      default: () => {
        return '';
      },
    },
    groupValues: {
      type: String,
      default: () => {
        return '';
      },
    },
    groupLabel: {
      type: String,
      default: () => {
        return '';
      },
    },
    groupSelect: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: String,
      default: '',
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    hideSelected: {
      type: Boolean,
      default: false,
    },
    preserveSearch: {
      type: Boolean,
      default: true,
    },
    customLabel: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hasAllOption: {
      type: Boolean,
      default: false,
    },
    openDirection: {
      type: String,
      default: '',
    },
    maxHeight: {
      type: Number,
      default: 300,
    },
  },

  emits: ['on-search-data', 'update:modelValue', 'on-selected-data'],

  setup(props, { emit }) {
    let searchQuery = '';
    const select = ref(null);

    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.modelValue,
    });

    const selectedItem = ref({ ...inputValue.value });

    watch(
      () => props.modelValue,
      currentValue => {
        // console.log('modelValue GSearchSelect : ', currentValue);
        let tmpCurrentValue = currentValue;
        if (!currentValue || Object.keys(currentValue).length <= 0) {
          tmpCurrentValue = null;
        }
        if (!isEquals(tmpCurrentValue, selectedItem.value)) {
          if (!tmpCurrentValue) {
            selectedItem.value = null;
          } else {
            selectedItem.value = { ...tmpCurrentValue };
          }
        }
      },
    );

    watch(inputValue, currentValue => {
      emit('update:modelValue', currentValue, props.name);
    });

    watch(selectedItem, currentValue => {
      if (isEquals(inputValue.value, currentValue)) {
        return;
      }
      // console.log('selectedItem GSearchSelect : ', currentValue);
      if (selectedItem.value && Object.keys(selectedItem.value).length > 0) {
        inputValue.value = { ...currentValue };
      } else {
        inputValue.value = null;
      }
    });

    const debounceOnChange = _.debounce(() => {
      emit('on-search-data', searchQuery, props.name);
    }, 300);

    // 입력할때마다 검색
    const onSearchData = query => {
      // 특수문자 입력시 검색되지 않도록
      // if (!query) {
      //   return;
      // }

      searchQuery = query;
      debounceOnChange();
    };

    // 리스트에서 선택
    const onSelectedData = item => {
      emit('on-selected-data', item, props.name);
    };

    const onCustomLabel = ({ userId, userNm }) => {
      return `${userNm} [${userId}]`;
    };

    const errorCls = computed(() => {
      if (errorMessage.value || props.error) {
        return {
          '--custom-border': '1px solid red',
        };
      }
      return {
        '--custom-border': '1px solid #d1d5db',
      };
    });

    return {
      onSearchData,
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
      errorCls,
      select,
      onSelectedData,
      onCustomLabel,
    };
  },
});
</script>
