import { Code, EnumEntry } from './base';

class printCode extends EnumEntry<Code> {
  cuesheet: Code = {
    id: 'cuesheet',
    name: 'option',
    label: '큐시트',
  };
  article: Code = {
    id: 'article',
    name: 'option',
    label: '원고',
  };

  caption: Code = {
    id: 'caption',
    name: 'option',
    label: '자막',
  };

  compactPlus: Code = {
    id: 'compactPlus',
    name: 'option',
    label: '압축(+자막)',
  };

  compactMinus: Code = {
    id: 'compactMinus',
    name: 'option',
    label: '압축(-자막)',
  };

  values: Array<Code> = [
    this.cuesheet,
    this.article,
    this.caption,
    this.compactPlus,
    this.compactMinus,
  ];
}

export default new printCode();
