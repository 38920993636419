<template>
  <td class="hidden">
    <GInput
      v-model="item.order"
      name="order"
      :disabled="false"
      :readonly="false"
      :model-value="index"
    />
  </td>
  <td class="p-2">
    <GInput
      v-model="item.categoryName"
      name="category"
      :disabled="false"
      :readonly="false"
      width="w-28"
      :model-value="item?.categoryName"
    />
  </td>
  <td class="p-2">
    <textarea
      v-model="item.content"
      ref="textareaRef"
      class="p-2 border border-gray-300 shadow-sm focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary block w-full sm:text-xs text-xs rounded-md"
      @input="handleChange"
    />
  </td>
  <td class="p-2 py-1">
    <GIconButton
      v-tooltip="t('BottomRollEditor.삭제')"
      icon="trash"
      @click="onDeleteItem(index)"
    />
  </td>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import { GInput, GIconButton } from '@/components/ui';
import { useModelWrapper } from '@/hooks/useModelWrapper';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'BottomRollEditorItem',
  components: {
    GInput,
    GIconButton,
  },
  props: {
    row: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: () => {
        return 0;
      },
    },
  },
  emits: ['on-delete-item'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const item = useModelWrapper(props, emit, 'row');
    const textareaRef = ref();

    const onDeleteItem = index => {
      emit('on-delete-item', index);
    };

    const handleChange = e => {
      e.target.style.height = `${e.target.scrollHeight + 2}px`;
    };

    onMounted(() => {
      textareaRef.value.style.height = 'auto';
      textareaRef.value.style.height = `${
        textareaRef.value.scrollHeight + 2
      }px`;
    });

    return {
      t,
      item,
      onDeleteItem,
      handleChange,
      textareaRef,
    };
  },
});
</script>

<style scoped></style>
