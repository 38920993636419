import { onMounted, onUnmounted, reactive } from 'vue';

export default function useBreakpoint() {
  const breakpoints = reactive({
    w: 0,
    h: 0,
    is: 'xs',
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
  });

  const breakpointMapping = {
    xs: 320,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
  };

  const isXs = (val: number) =>
    val >= breakpointMapping.xs && val < breakpointMapping.sm;
  const isSm = (val: number) =>
    val >= breakpointMapping.sm && val < breakpointMapping.md;
  const isMd = (val: number) =>
    val >= breakpointMapping.md && val < breakpointMapping.lg;
  const isLg = (val: number) =>
    val >= breakpointMapping.lg && val < breakpointMapping.xl;
  const isXl = (val: number) => val >= breakpointMapping.xl;

  const getBreakpoint = (w: number) => {
    if (isXs(w)) return 'xs';
    else if (isSm(w)) return 'sm';
    else if (isMd(w)) return 'md';
    else if (isLg(w)) return 'lg';
    else if (isXl(w)) return 'xl';
    else return 'all';
  };

  const updateBreakpoints = () => {
    breakpoints.xs = isXs(window.innerWidth);
    breakpoints.sm = isSm(window.innerWidth);
    breakpoints.md = isMd(window.innerWidth);
    breakpoints.lg = isLg(window.innerWidth);
    breakpoints.xl = isXl(window.innerWidth);
    breakpoints.w = window.innerWidth;
    breakpoints.h = window.innerHeight;
    breakpoints.is = getBreakpoint(window.innerWidth);
  };

  onMounted(() => {
    window.addEventListener('resize', updateBreakpoints);
    updateBreakpoints();
  });

  onUnmounted(() => {
    window.removeEventListener('resize', updateBreakpoints);
  });

  return breakpoints;
}
