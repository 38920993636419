import { Module } from 'vuex';
import { RootState } from '..';
import { useMarsLApi } from '@/apis/mars-l-api';

export type SystemInfoState = {
  speed: '';
  isAutoSave: false;
};

export const SystemInfoModule: Module<SystemInfoState, RootState> = {
  namespaced: true,
  state: () => ({
    speed: '',
    isAutoSave: false,
  }),
  getters: {
    readSpeed: state => {
      return state.speed;
    },
    isAutoSave: state => {
      return state.isAutoSave;
    },
  },
  actions: {
    FIND_MY_SYSTEM_INFO: async ({ commit }) => {
      const marsLApi = useMarsLApi();

      try {
        const response = await marsLApi.myInfo.system.findAll();
        commit('SET_MY_SYSTEM_INFO', response.data);
      } catch (error) {
        console.error('error', error);
      }
    },
  },
  mutations: {
    async SET_MY_SYSTEM_INFO(state, data) {
      state.speed = data?.speedSetting?.code;
      state.isAutoSave = data?.isAutoSave;
    },
  },
};
