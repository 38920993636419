<template>
  <div class="space-y-4" :class="[height]">
    <div v-if="isLoading" class="bg-white rounded-md">
      <PageLoader />
    </div>
    <div :class="[isLoading ? 'hidden' : 'visible']">
      <!-- 경고 문구 -->
      <div
        v-if="warningText"
        class="bg-red-50 border-t-4 border-red-300 text-red-500 font-D2CodingBold px-2 py-1 rounded-b-lg mb-2"
        role="alert"
      >
        <div class="flex items-center">
          <div class="py-1">
            <svg
              class="fill-current h-6 w-6 text-orange-400 mr-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
              />
            </svg>
          </div>
          <div>
            <p class="text-xs font-D2CodingBold">{{ warningText }}</p>
          </div>
        </div>
      </div>

      <div class="dropzone w-full bg-white">
        <div id="dropzone-preview-list">
          <!-- This is used as the file preview template -->
          <div v-for="(file, index) in files" :key="index">
            <div class="mb-1.5 space-y-1">
              <div
                class="bg-gray-100 rounded-md text-xs flex items-center justify-between p-1"
              >
                <div class="flex space-x-2 items-center">
                  <PaperClipIcon class="h-4 w-4" />
                  <span
                    class="data-dz-name font-D2CodingBold"
                    data-dz-name="data-dz-name"
                    >{{ file.name }}</span
                  >
                  <span class="file-size"></span>
                </div>
                <div class="space-x-1 flex items-center">
                  <div v-if="itemMode !== 'create'" class="flex items-center">
                    <button
                      v-if="!isDownloadLoading || selectedRowIdx !== index"
                      v-tooltip="'다운로드'"
                      class="download-button"
                      @click="onDownloadFile(file, index)"
                    >
                      <InboxArrowDownIcon
                        class="download-icon h-5 w-5 outline-none cursor-pointer"
                      />
                    </button>
                    <div
                      v-if="isDownloadLoading && selectedRowIdx === index"
                      class="flex items-center justify-center"
                    >
                      <GSpinner fill-white class="text-primary" />
                    </div>
                  </div>
                  <button
                    v-tooltip="'삭제'"
                    class="delete-button"
                    data-dz-remove="data-dz-remove"
                    @click="onClickedDeleteFile(file)"
                  >
                    <XMarkIcon
                      class="delete-icon h-5 w-5 outline-none cursor-pointer"
                    />
                  </button>
                </div>
              </div>
              <div class="dz-error-message text-error">
                <span data-dz-errormessage></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-error space-y-1 text-xs pt-2">
        <div v-html="help" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
  computed,
  watch,
  nextTick,
} from 'vue';
import Dropzone from 'dropzone';
import 'dropzone/dist/dropzone.css';
import {
  XMarkIcon,
  PaperClipIcon,
  InboxArrowDownIcon,
} from '@heroicons/vue/24/outline';
import { useMarsLApi } from '@/apis/mars-l-api';
import { useSweetAlert } from '@/hooks/use-sweet-alert';
import PageLoader from '@/components/common/PageLoader.vue';
import GSpinner from '@/components/ui/GSpinner';
import fileDownload from '@/utils/file-download.ts';

export default defineComponent({
  name: 'AttachFile',
  components: {
    XMarkIcon,
    PaperClipIcon,
    InboxArrowDownIcon,
    PageLoader,
    GSpinner,
  },
  props: {
    help: {
      type: String,
      default: `<div class="space-y-1 font-D2CodingBold">
      <div>* 파일 하나당 최대 200MB까지 업로드할 수 있습니다.</div>
      <div>* 등록 개수는 최대 5개 입니다.</div>
      </div>`,
    },
    warningText: {
      type: String,
    },
    itemId: {
      type: [String, Number],
    },
    /**
     * 내기사: myArticle
     * 취재데스크: coverDesk,
     * 지역사 : area,
     */
    itemType: {
      type: String,
    },
    /**
     * 생성: create,
     * 수정: edit
     */
    itemMode: {
      type: String,
    },
    height: {
      type: String,
    },
    fileList: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emit: ['files', 'render-count', 'on-upload-file', 'on-delete-file'],

  setup(props, { emit }) {
    const marsLApi = useMarsLApi();
    const { swalError, swalConfirm } = useSweetAlert();

    let fileUpload = ref(null);
    const files = ref([]);
    const tempFileList = ref([]);
    const isDownloadLoading = ref(false);
    const selectedRowIdx = ref(null);

    const fileDivCode = computed(() => {
      /**
       * 003은 제보 코드
       */
      // 내기사일 경우
      if (props.itemType === 'myArticle') {
        return 'file_div_001';
      }
      // 취재데스크의 경우
      else if (props.itemType === 'coverDesk') {
        return 'file_div_002';
      }
      // 게시판의 경우
      else if (props.itemType === 'board') {
        return 'file_div_004';
      }

      return '';
    });

    watch(
      () => props.fileList,
      () => {
        files.value = props.fileList;
        tempFileList.value = [];
        toggleFileUploadMessageElement();
      },
    );

    watch(
      () => tempFileList.value,
      uploadFiles => {
        let tempList = uploadFiles;
        if (!tempList.length) {
          return;
        }

        const limitByte = 1048576 * 200; // 200MB를 Byte로 변경해서 비교 (1MB = 1048576Byte)

        /* 기사 생성의 경우 컴포넌트를 리로드하지 않기 때문에, 추가된 파일들이 tempFileList.value에 계속 쌓인다.
           따라서 validation을 통과한 파일의 목록이 필요하므로 아래와 같이 처리한다.
           기사 수정의 경우 validation을 통과하지 못한 파일들이 files.value에 들어가 있으므로
           fileId를 가진 파일 목록을 만들어서 사용해야 한다.
        */
        if (props.itemMode === 'create') {
          tempList = tempList.filter(item => !item.isReady);
          files.value = files.value.filter(item => item.isReady);
        } else {
          files.value = files.value.filter(item => item.fileId);
        }

        // 각 파일의 크기 확인
        if (tempList.some(item => item.upload.total >= limitByte)) {
          swalConfirm({
            title: '파일 최대 크기는 200MB 입니다.',
            showCancelButton: false,
          });
          tempFileList.value = [];
          fileUpload.value.files = files.value;
          return;
        }
        // 다섯 개 제한
        else if (files.value.length + tempList.length > 5) {
          swalConfirm({
            title: '파일 등록은 최대 5개까지 입니다.',
            showCancelButton: false,
          });
          tempFileList.value = [];
          fileUpload.value.files = files.value;
          return;
        }

        tempList.forEach(item => {
          item.isReady = true;
        });

        if (props.itemMode === 'create') {
          files.value.push(...tempList.filter(item => item.isReady));
          files.value.forEach((item, index) => {
            item.index = index;
          });
          emit('on-upload-file', {
            list: files.value,
            fileDivCode: fileDivCode.value,
          });
        } else {
          emit('on-upload-file', {
            list: tempList,
            fileDivCode: fileDivCode.value,
          });
        }

        toggleFileUploadMessageElement();
      },
      { deep: true },
    );

    // 파일이 있으면 드래그앤 드랍 또는 클릭으로 파일을 추가하세요. 글자 보이지 않도록 처리
    const toggleFileUploadMessageElement = () => {
      const dropzoneMsg = document.querySelector('.dz-message');

      if (dropzoneMsg) {
        if (files.value.length) {
          dropzoneMsg.classList.add('hidden');
        } else {
          dropzoneMsg.classList.remove('hidden');
        }
      }
    };

    // 파일 다운로드
    const onDownloadFile = async (file, index) => {
      selectedRowIdx.value = index;
      isDownloadLoading.value = true;
      const params = {
        fileId: file.fileId,
      };
      try {
        const response = await marsLApi.file.basic.download(params);
        fileDownload(response, file.name);
      } catch (error) {
        console.error('error', error);
        swalError({
          showCancelButton: false,
          title: '<div>첨부파일을 다운로드 할 수 없습니다.</div>',
        });
      } finally {
        isDownloadLoading.value = false;
      }
    };

    // 삭제 버튼 클릭
    const onClickedDeleteFile = delFile => {
      // option에 있는 removedfile 실행
      fileUpload.value.options.removedfile.call(fileUpload.value, delFile);
    };

    // 수정모드에서 파일 삭제
    const onDeleteFile = async file => {
      console.log('파일 삭제 fileUpload', fileUpload.value);

      emit('on-delete-file', file);
      nextTick(() => {
        toggleFileUploadMessageElement();
      });
    };

    onMounted(async () => {
      Dropzone.autoDiscover = false;
      fileUpload.value = new Dropzone('div.dropzone', {
        url: '#', // 파일을 업로드할 서버 주소 url.
        method: 'put', // 기본 post로 request 감. put으로도 할수있음

        autoProcessQueue: false, // 자동으로 보내기. true : 파일 업로드 되자마자 서버로 요청, false : 서버에는 올라가지 않은 상태. 따로 this.processQueue() 호출시 전송
        clickable: true, // 클릭 가능 여부
        autoQueue: false, // 드래그 드랍 후 바로 서버로 전송
        createImageThumbnails: false, //파일 업로드 썸네일 생성

        thumbnailHeight: 120, // Upload icon size
        thumbnailWidth: 120, // Upload icon size

        // maxFiles: 10, // 업로드 파일수
        maxFilesize: 200, // 최대업로드용량 : 200MB
        // paramName: 'image', // 서버에서 사용할 formdata 이름 설정 (default는 file)
        parallelUploads: 5, // 동시파일업로드 수(이걸 지정한 수 만큼 여러파일을 한번에 넘긴다.)
        uploadMultiple: true, // 다중업로드 기능
        timeout: 300000, //커넥션 타임아웃 설정 -> 데이터가 클 경우 꼭 넉넉히 설정해주자

        addRemoveLinks: false, // 업로드 후 파일 삭제버튼 표시 여부
        dictRemoveFile: '삭제', // 삭제버튼 표시 텍스트
        previewTemplate: document.getElementById('dropzone-preview-list')
          .innerHTML, // 커스텀한 업로드 영역 사용
        // acceptedFiles:
        //   'image/*, .pdf, .doc, .docx, .xls, .xlsx, .csv, .txt, .rtf .zip', // 이미지, 문서 파일
        removedfile: function (file) {
          // 기사 신규 작성일 때, 업로드한 파일을 삭제하면 리스트에서만 삭제처리
          if (props.itemMode === 'create') {
            const delIdx = files.value.findIndex(
              item => item.index === file.index,
            );
            files.value.splice(delIdx, 1);
            fileUpload.value.files = files.value;

            emit('on-upload-file', {
              list: files.value,
              fileDivCode: fileDivCode.value,
            });
            toggleFileUploadMessageElement();
          } else {
            const color = '#ff0303'; // 빨간색
            swalConfirm({
              title: file.fileName || file.name,
              icon: undefined,
              iconColor: color,
              iconHtml:
                '<svg class="h-24 w-24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.043 21H3.957c-1.538 0-2.5-1.664-1.734-2.997l8.043-13.988c.77-1.337 2.699-1.337 3.468 0l8.043 13.988C22.543 19.336 21.58 21 20.043 21zM12 9v4" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path><path d="M12 17.01l.01-.011" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>',
              html: `<div>을(를) 삭제하시겠습니까?</div>`,
              customClass: {
                icon: 'no-border',
              },
              confirmButtonColor: color,
              confirmButtonText: '삭제',
            }).then(res => {
              if (res === 'confirm') {
                onDeleteFile(file);
              }
            });
          }
        },
        // 파일 개수 또는 파일 크기 등 validation을 실행 한 후 통과된 파일만 화면에 표시하기 위해 option으로 사용함
        addedfile: file => {
          // file.value를 사용할 경우 화면에서 바로 변경되어 버리기 때문에 temp 변수 이용
          tempFileList.value.push(file);
        },
      });

      // 파일 업로드 영역 텍스트 변경
      const dropzoneBtn = document.querySelector('.dz-button');
      dropzoneBtn.innerText = '드래그앤 드랍 또는 클릭으로 파일을 추가하세요.';

      // 파일 업로드 영역 이미지 추가
      const dropzoneMsg = document.querySelector('.dz-message');
      const img = document.createElement('div');
      img.innerHTML = `<svg class="h-6 w-6 text-gray-500" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
  <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"></path>
</svg>`;
      dropzoneMsg.prepend(img);
      dropzoneMsg.classList.add(
        'flex',
        'flex-col',
        'items-center',
        'justify-center',
        'space-y-4',
        'text-xs',
      );
    });

    return {
      isDownloadLoading,
      files,
      selectedRowIdx,
      onDownloadFile,
      onClickedDeleteFile,
    };
  },
});
</script>
<style scoped>
.dropzone {
  min-height: 200px;
  padding: 12px 10px;
  border: 1px solid rgb(229 231 235);
}
.download-button,
.delete-button,
.download-icon,
.delete-icon {
  cursor: pointer !important;
  color: rgb(75 85 99) !important;
}

.dz-max-files-reached {
  pointer-events: none;
  cursor: default;
}
</style>
