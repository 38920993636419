<template>
  <div class="w-full h-full scrollbar">
    <CoreEditor
      v-if="!hideReporterEditor"
      ref="reporterEditor"
      v-model:article-text="articleText"
      v-model:article-captions="articleCaptions"
      class="reporter-editor"
      :editor-read-only="editorReadOnly"
      :caption-read-only="captionReadOnly"
      placeholder="리포터멘트 작성"
    />
  </div>
</template>

<script>
import { ref } from 'vue';
import { useModelWrapper } from '@/hooks/useModelWrapper';
import CoreEditor from './core/CoreEditor';

export default {
  name: 'StraightEditor',
  components: {
    CoreEditor,
  },
  props: {
    reporterText: {
      type: String,
      default: '',
    },
    reporterCaptions: {
      type: Array,
      default: () => [],
    },
    editorReadOnly: {
      type: Boolean,
      default: false,
    },
    captionReadOnly: {
      type: Boolean,
      default: false,
    },
    hideReporterEditor: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit, expose }) {
    const reporterEditor = ref();
    const articleText = useModelWrapper(props, emit, 'reporterText');
    const articleCaptions = useModelWrapper(props, emit, 'reporterCaptions');

    const insertTextAtCursor = insertText => {
      reporterEditor.value.insertTextAtCursor(insertText);
    };

    expose({
      insertTextAtCursor,
    });

    return {
      reporterEditor,
      articleText,
      articleCaptions,
    };
  },
};
</script>

<style scoped></style>
