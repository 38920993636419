import { Code, EnumEntry } from './base';

class copyCode extends EnumEntry<Code> {
  arriveMyArticle: Code = {
    id: 'class_000',
    name: '내기사',
    label: '내기사',
  };

  arriveRegionArticle: Code = {
    id: 'class_001',
    name: '데스크(송고)',
    label: '데스크(송고)',
  };

  copyCuesheet: Code = {
    id: 'class_002',
    name: '큐시트 복사',
    label: '큐시트 복사',
  };

  values: Array<Code> = [
    this.arriveMyArticle,
    this.arriveRegionArticle,
    this.copyCuesheet,
  ];
}

export default new copyCode();
