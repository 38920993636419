<template>
  <div class="cursor-pointer" @click="onMoveManualLink">
    <QuestionMarkCircleIcon
      v-tooltip="'퀵 메뉴얼'"
      class="h-6 w-6 rounded-full px-px py-px"
    />
  </div>
</template>
<script>
import { defineComponent } from 'vue';

import QuestionMarkCircleIcon from '@heroicons/vue/24/outline/QuestionMarkCircleIcon';

export default defineComponent({
  name: 'ManualButton',
  components: {
    QuestionMarkCircleIcon,
  },
  setup() {
    const onMoveManualLink = () => {
      window.open('https://www.youtube.com/watch?v=cs3S0wAGdhs', '_blank');
    };

    return { onMoveManualLink };
  },
});
</script>
