<template>
  <div
    class="inline-flex border scrollbar-hide py-[1px]"
    :class="[captionReadOnly ? 'bg-gray-50' : '']"
  >
    <div v-if="!noCaption" class="w-[364px] h-full border-r">
      <div
        v-for="lineNo in getCaptionLineCountArray"
        class="flex"
        :class="[captionReadOnly ? 'bg-gray-50' : '']"
        :key="`line-block-${lineNo}`"
        :style="[`height: ${getDocStyles.lineHeight}px`, 'line-height: 1.4']"
      >
        <div
          class="flex-1"
          :class="[{ 'border-b': getCaptionLineCount > lineNo }]"
          @dblclick.stop="onCaptionLineDBClickHandler(lineNo)"
        >
          <div class="flex" v-if="hasCaptionOnTheCurrentLine(lineNo)">
            <Caption
              :ref="`captionElements`"
              class="w-full grow-0 shrink-0 z-10 focus:z-50 focus-within:z-50"
              :key="`caption-${lineNo}`"
              :data-lineNo="lineNo"
              :read-only="captionReadOnly"
              :text="getCaptionInfoByLineNo(lineNo).text"
              @keydown.alt.up.stop="onCaptionKeyUpHandler(lineNo, -1)"
              @keydown.alt.down.stop="onCaptionKeyUpHandler(lineNo, 1)"
              @input="onCaptionInputHandler($event, lineNo)"
              @close="onCaptionCloseHandler(lineNo)"
              @blur="onCaptionBlurHandler($event, lineNo)"
              @arrowUpClick="onCaptionKeyUpHandler(lineNo, -1)"
              @arrowDownClick="onCaptionKeyUpHandler(lineNo, 1)"
              @focusin="onCaptionFocusIn(lineNo)"
            />
          </div>
        </div>
        <div
          class="border-l ml-auto px-[8px] cursor-pointer select-none"
          @dblclick.stop="onCaptionLineDBClickHandler(lineNo)"
        >
          -
        </div>
      </div>
    </div>
    <div
      class="h-full"
      :class="[
        noCaption ? 'w-[704px]' : 'w-[340px]',
        { 'bg-gray-50': editorReadOnly },
      ]"
      @click="onEditorDivClick"
    >
      <codemirror
        v-model="editorText"
        :placeholder="placeholder"
        :extensions="extensions"
        :disabled="editorReadOnly"
        :indentWithTab="false"
        @ready="onEditorReadyHandler"
        @update="onEditorUpdateHandler"
        @focusin="onCodeMirrorFocusIn"
      />
    </div>
  </div>
</template>

<script>
import {
  cloneDeep,
  find,
  flattenDeep,
  isEmpty,
  max,
  min,
  orderBy,
} from 'lodash';
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  onUpdated,
  ref,
  shallowRef,
  watch,
  watchEffect,
} from 'vue';
import { getByteArray, sliceByByte, insertTextBetWeen } from '@/utils/str';

import Caption from '@/components/article/core/Caption';

import { Codemirror } from 'vue-codemirror';
import { EditorView } from '@codemirror/view';
import { CaptionModel } from '@/models/article/caption';
import { trim } from 'lodash/string';
import { useModelWrapper } from '@/hooks/useModelWrapper';

export default defineComponent({
  name: 'CoreEditor',
  components: {
    Caption,
    Codemirror,
  },
  props: {
    articleText: {
      type: String,
      default: '',
    },
    articleCaptions: {
      type: Array,
      default: () => [],
    },
    linePerTextByte: {
      type: Number,
      default: 44,
    },
    placeholder: {
      type: String,
    },
    editorReadOnly: {
      type: Boolean,
      default: false,
    },
    captionReadOnly: {
      type: Boolean,
      default: false,
    },
    noCaption: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit, expose }) {
    // component states
    const isInitializing = ref(true);
    const captions = useModelWrapper(props, emit, 'articleCaptions');
    const cursorPosition = ref({
      prev: {
        from: 0,
        to: 0,
        line: 0,
      },
      from: 0,
      to: 0,
      line: 0,
    });

    const captionElements = ref([]);
    const lineNumber = ref(0);
    const cursorCaptionPosition = ref(0);

    // Codemirror Constant
    const extensions = [EditorView.lineWrapping];

    // Codemirror EditorView instance ref
    const editorText = useModelWrapper(props, emit, 'articleText');
    const codeMirrorView = shallowRef({});
    const codeMirrorState = shallowRef({});

    const focusCaption = ref(null);

    // private method

    /**
     * 커서 위치 정보 업데이트
     * @type {ComputedRef<unknown>}
     */
    const updateCursorPosition = editorState => {
      const { doc, selection } = editorState;
      const { main } = selection;
      const { from, to } = main;

      const codeMirrorLine = doc.lineAt(from).number;

      // code mirror editor 라인 안에 라인 구하기
      // let inLineCursor = 0;
      const currentEditorLineBlock =
        getEditorViewLineBlocks.value[codeMirrorLine - 1];

      const {
        from: prevFrom,
        to: prevTo,
        line: prevLine,
        isFront: prevIsFront,
      } = cursorPosition.value;

      cursorPosition.value = {
        prev: {
          from: prevFrom,
          to: prevTo,
          line: prevLine,
          isFront: prevIsFront,
        },
        from,
        to,
        isFront: currentEditorLineBlock.from === from,
        line: currentEditorLineBlock.line,
      };
    };

    /**
     * 자막 생성
     * @param lineNo - 자막 생성 라인 번호
     */
    const addCaption = lineNo => {
      if (hasCaptionOnTheCurrentLine(lineNo)) {
        return;
      }

      const { from, to } = cursorPosition.value;
      captions.value.push(
        new CaptionModel(
          codeMirrorState.value.sliceDoc(from, to),
          '',
          lineNo,
          '',
        ),
      );
    };

    /**
     * 자막 라인 변경
     * @param captionLineNo - 자막 라인 번호
     * @param offset - 이동 거리 (위: 음수, 아래: 양수)
     */
    const moveCaptionLine = (captionLineNo, offset) => {
      if (offset === 0) {
        return;
      }
      const targetCaptionIndex = captions.value.findIndex(
        v => v.lineNo === captionLineNo,
      );

      // 자막정보가 없을경우 이동 불가
      if (targetCaptionIndex < 0) {
        return;
      }

      const { lineNo } = captions.value[targetCaptionIndex];
      let moveLineNo = lineNo + offset;

      /**
       * 최대 이동가능한 거리 구하기
       * 자막 중복 불가 처리 로직
       */
      if (Math.sign(offset) === -1) {
        // 음수 처리
        const prevCaptionLine = captions.value[targetCaptionIndex - 1]?.lineNo;
        moveLineNo = max([
          moveLineNo,
          prevCaptionLine !== undefined ? prevCaptionLine + 1 : 0,
        ]);
      } else {
        // 양수 처리
        const nextCaptionLine = captions.value[targetCaptionIndex + 1]?.lineNo;
        moveLineNo = min([
          moveLineNo,
          (nextCaptionLine !== undefined
            ? nextCaptionLine
            : getCaptionLineCount.value) - 1,
        ]);
      }

      captions.value[targetCaptionIndex].lineNo = moveLineNo;
    };

    // editor computed
    /**
     * 에디터 라인별 view 정보
     * word-wrap으로 만들어진 라인수 정보 추가
     * @type {ComputedRef<unknown>}
     */
    const getEditorViewLineBlocks = computed(() => {
      if (!codeMirrorView.value || isEmpty(codeMirrorView.value)) {
        return [];
      }

      /** codemirror에서 라인수(강제줄바꿈 갯수)가 32개가 넘어가게 되면
       * state.doc.text 안에 string 배열로 되어 있던 값이 TextLeaf 객체의 배열로 변경됨
       * 에디터 라인을 계산 하기 위해서는 string 1차원 배열이 필요하여 상황에 맞게 데이터 변경 처리
       */
      const textBlockArray =
        codeMirrorView.value.state.doc.text ??
        flattenDeep(codeMirrorView.value.state.doc.children.map(v => v.text));

      let startFrom = 0;

      return flattenDeep(
        textBlockArray.map(v => {
          return sliceByByte(v, props.linePerTextByte);
        }),
      ).map((v, i) => {
        if (i !== 0) {
          startFrom++;
        }
        const _from = startFrom;
        const _to = startFrom + v.length;

        startFrom = _to;

        return {
          text: v,
          textByteArray: getByteArray(v),
          line: i,
          from: _from,
          to: _to,
        };
      });

      // return textBlockArray.map(v => {
      //   const textByline = sliceByByte(v, props.linePerTextByte);
      //   const viewLineCount = textByline.length || 1;
      //
      //   const startLineNumber = nextLineNumber;
      //   // 다음 시작 라인 번호 계산
      //   nextLineNumber += viewLineCount;
      //
      //   return {
      //     text: v,
      //     textByteArray: getByteArray(v),
      //     viewLineCount,
      //     startLineNumber,
      //   };
      // });
    });

    /**
     * 에디터 영역이 보여지는 라인 수
     * @type {ComputedRef<*>}
     */
    const getEditorViewLineCount = computed(() => {
      // return getEditorViewLineBlocks?.value?.reduce((acc, cur) => {
      //   return acc + cur.viewLineCount ?? 0;
      // }, 0);
      return getEditorViewLineBlocks.value.length;
    });

    /**
     * 자막 영역 line 수
     * @type {ComputedRef<number>}
     */
    const getCaptionLineCount = computed(() => {
      const editorViewLineCount = getEditorViewLineCount.value;

      const captionLineLastNumber =
        max(captions.value.map(v => v?.lineNo)) + 1 ?? 0;

      return Math.ceil(max([editorViewLineCount, captionLineLastNumber]));
    });

    const getCaptionLineCountArray = computed(() => {
      return Array.from({ length: getCaptionLineCount.value }, (_, i) => i);
    });

    /**
     * editor에 사용할 공통 Styles
     * @type {ComputedRef<unknown>}
     */
    const getDocStyles = computed(() => {
      if (!codeMirrorView.value || isEmpty(codeMirrorView.value)) {
        return {};
      }

      const view = codeMirrorView.value;

      return {
        lineHeight: view.defaultLineHeight,
      };
    });

    // editor method
    /**
     * codeMirror Editor mounted시 실행되는 함수
     * @param view - CodeMirror EditorView
     * @param state - CodeMirror EditorState`
     */
    const onEditorReadyHandler = ({ view, state }) => {
      codeMirrorView.value = cloneDeep(view);
      codeMirrorState.value = cloneDeep(state);
    };
    /**
     * codeMirror Editor update 이벤트 핸들러
     * input, focus, blur 발생시 실행
     * @param view - CodeMirror EditorView
     * @param state - CodeMirror EditorState
     */
    const onEditorUpdateHandler = ({ view, state }) => {
      codeMirrorView.value = cloneDeep(view);
      codeMirrorState.value = cloneDeep(state);
    };

    /**
     * 자막영역 더블클릭 이벤트
     * 기본 자막 정보를 생성
     * @param lineNo - 줄 번호
     */
    const onCaptionLineDBClickHandler = lineNo => {
      if (!props.captionReadOnly) {
        addCaption(lineNo);
      }

      nextTick(() => {
        getCaptionElementByLineNo(lineNo)?.onFocus();
      });
    };

    /**
     * 해당 라인에 자막정보 유무 체크
     * @param lineNo
     * @returns {boolean}
     */
    const hasCaptionOnTheCurrentLine = lineNo => {
      return !!getCaptionByLineNo(lineNo);
    };

    /**
     * 해당 라인 자막 정보 조회
     * @param lineNo - 라인번호
     */
    const getCaptionByLineNo = lineNo => {
      return find(captions.value, v => v.lineNo === lineNo);
    };

    /**
     * 자막 목록 정렬
     * 라인 번호 오름차순
     */
    const sortedCaptions = () => {
      captions.value.sort((a, b) => {
        if (a.lineNo > b.lineNo) {
          return 1;
        }

        if (a.lineNo < b.lineNo) {
          return -1;
        }

        return 0;
      });
    };

    /**
     * 자막 위치 방향키로 변경 처리
     * @param lineNo - 라인 번호
     * @param offset - 이동 거리
     * @param elementIndex - element 생성 번호
     */
    const onCaptionKeyUpHandler = (lineNo, offset) => {
      const targetCaptionIndex = captions.value.findIndex(
        v => v.lineNo === lineNo,
      );
      moveCaptionLine(lineNo, offset);
      nextTick(() => {
        const targetLineNo = captions.value[targetCaptionIndex].lineNo;
        const targetCaptionElement = getCaptionElementByLineNo(targetLineNo);

        targetCaptionElement?.onFocus();
      });
    };

    /**
     * 자막 텍스트 입력 이벤트 처리
     * 자막 입력시 captions정보에 내용 저장
     * @param text
     * @param lineNo
     */
    const onCaptionInputHandler = (text, lineNo) => {
      const findCaption = getCaptionInfoByLineNo(lineNo);

      if (!findCaption) {
        return;
      }

      findCaption.text = text;
    };

    /**
     * 자막 x 버튼 클릭 이벤트 처리
     * @param lineNo
     */
    const onCaptionCloseHandler = lineNo => {
      const targetIndex = captions.value.findIndex(v => v.lineNo === lineNo);

      captions.value.splice(targetIndex, 1);
      focusCaption.value = null;
    };
    /**
     * 자막 컴포넌트 focus가 사라졌을때 이벤트 처리
     * 입력값이 없다면 삭제 처리
     * @param text
     */
    const onCaptionBlurHandler = (text, lineNo) => {
      // 자막에 특수문자 입력시 커서 위치를 저장한다.
      cursorCaptionPosition.value = getCaptionElementByLineNo(
        lineNumber.value,
      ).onSelectionStart();
      if (!trim(text)) {
        onCaptionCloseHandler(lineNo);
      }
    };

    /**
     * 해당 라인의 자막 정보를 반환
     * @param lineNo - 라인 번호
     * @returns {UnwrapRefSimple<*>}
     */
    const getCaptionInfoByLineNo = lineNo => {
      return captions.value.find(v => v.lineNo === lineNo);
    };

    /**
     * 해당 라인의 자막 element 반환
     * @param lineNo - 라인 번호
     * @returns {UnwrapRefSimple<*>}
     */
    const getCaptionElementByLineNo = lineNo => {
      return captionElements.value.find(v => {
        const dataLineNo = +v.$el.getAttribute('data-lineNo') ?? -1;
        return dataLineNo === lineNo;
      });
    };

    /**
     * code mirror parent div click 이벤트
     * code mirror 에디터에 포커스
     */
    const onEditorDivClick = () => {
      codeMirrorView.value.focus();
    };

    /**
     * 커서위치에 문자열 추가
     * @param insertText - 추가할 텍스트
     */
    const insertTextAtCursor = insertText => {
      if (focusCaption.value) {
        console.log('a!');
        const text = focusCaption.value.text;

        focusCaption.value.text = insertTextBetWeen(
          text,
          insertText,
          cursorCaptionPosition.value,
        );
        cursorCaptionPosition.value += insertText.length;
      } else {
        console.log('aa');
        updateCursorPosition(codeMirrorView.value.state);

        const { from } = cursorPosition.value.prev;

        codeMirrorView.value.dispatch({
          changes: { from, insert: insertText },
          selection: {
            anchor: from + insertText.length,
            head: from + insertText.length,
          },
        });
      }
    };

    const onCodeMirrorFocusIn = () => {
      focusCaption.value = null;
    };

    const onCaptionFocusIn = lineNo => {
      lineNumber.value = lineNo;
      const findCaption = getCaptionByLineNo(lineNo);
      focusCaption.value = findCaption;
    };

    // watch methods
    watch(
      () => [codeMirrorState.value],
      ([newCodeMirrorState]) => {
        updateCursorPosition(newCodeMirrorState);
      },
    );

    watch(
      () => getEditorViewLineCount.value,
      (newValue, oldValue) => {
        if (isInitializing.value) {
          return;
        }

        const { prev } = cursorPosition.value;
        const offset = newValue - oldValue;

        if (offset === 0) {
          return;
        }

        const orderType = offset > -1 ? 'desc' : 'asc';
        const _captions = orderBy(
          cloneDeep(captions.value),
          ['lineNo'],
          [orderType],
        );

        _captions
          .filter(v => v.lineNo >= (prev.isFront ? prev.line : prev.line + 1))
          .forEach(v => {
            moveCaptionLine(v.lineNo, offset);
          });
      },
    );

    watch(
      () => captions.value,
      () => {
        sortedCaptions();
      },
      {
        deep: true,
      },
    );

    watchEffect(
      () => [props.articleText, props.articleCaptions],
      () => {
        isInitializing.value = true;
      },
    );

    onMounted(() => {
      isInitializing.value = true;
    });

    onUpdated(() => {
      isInitializing.value = false;
    });

    expose({
      insertTextAtCursor,
    });

    return {
      cursorPosition,
      captions,
      captionElements,
      extensions,
      editorText,
      getEditorViewLineBlocks,
      getDocStyles,
      onEditorReadyHandler,
      onEditorUpdateHandler,
      onCaptionLineDBClickHandler,
      hasCaptionOnTheCurrentLine,
      getCaptionByLineNo,
      getCaptionLineCount,
      getCaptionLineCountArray,
      onCaptionKeyUpHandler,
      onCaptionInputHandler,
      onCaptionCloseHandler,
      onCaptionBlurHandler,
      getCaptionInfoByLineNo,
      getCaptionElementByLineNo,
      onEditorDivClick,
      onCodeMirrorFocusIn,
      onCaptionFocusIn,
    };
  },
});
</script>
<style scoped>
::v-deep(.v-codemirror) {
  font-size: 15px;
}

::v-deep(.cm-scroller) {
  font-family: D2Coding, D2 coding;
}

::v-deep(.cm-gutters) {
  display: none;
}

::v-deep(.cm-activeLine),
::v-deep(.cm-activeLineGutter) {
  background-color: initial;
}

::v-deep(.cm-content) {
  padding-top: 0;
  padding-bottom: 0;
  white-space: pre-wrap;
  /* line-break: anywhere; */
}

::v-deep(.cm-content[contenteditable='false'] .cm-selectionMatch) {
  background-color: transparent;
}

/* 2023.09.08 hkkim 요기 주석 풀면 서울 MBC와 Wordwrap 기준이 달라짐 */
/* ::v-deep(.cm-lineWrapping) {
  word-break: break-all !important;
} */
</style>
