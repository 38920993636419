import { AxiosInstance } from 'axios';

const PREFIX = '/my-info';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 나의 정보 상세 조회
     */
    findOne: async () => {
      return $axios.get(`${PREFIX}/detail`).then(response => response.data);
    },

    /**
     * 나의 정보 수정
     * @param {Object} option request body
     * @param {string} option.userName 이름
     * @param {string} option.email 이메일
     * @param {string} option.changePassword 비밀번호
     */
    update: async (params: any) => {
      return $axios
        .put(`${PREFIX}/detail`, params)
        .then(response => response.data);
    },
  };
};
