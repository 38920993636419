<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_204_4)">
      <rect width="48" height="48" rx="10" fill="white" />
      <rect
        x="1.5"
        y="1.5"
        width="45"
        height="45"
        rx="8.5"
        stroke="black"
        stroke-width="3"
      />
      <path
        d="M4.32937 32V17.4545H14.4714V20.3097H7.84499V23.2926H13.9529V26.1548H7.84499V29.1449H14.4714V32H4.32937ZM29.0524 17.4545V32H26.0694L20.2811 23.6051H20.1887V32H16.6731V17.4545H19.6987L25.4231 25.8352H25.5439V17.4545H29.0524ZM36.6394 32H31.263V17.4545H36.6323C38.1143 17.4545 39.3903 17.7457 40.4604 18.3281C41.5352 18.9058 42.3638 19.7391 42.9462 20.8281C43.5286 21.9124 43.8198 23.2098 43.8198 24.7202C43.8198 26.2353 43.5286 27.5374 42.9462 28.6264C42.3685 29.7154 41.5423 30.5511 40.4675 31.1335C39.3927 31.7112 38.1167 32 36.6394 32ZM34.7786 29.0028H36.5044C37.3188 29.0028 38.0078 28.8655 38.5712 28.5909C39.1394 28.3116 39.5679 27.8594 39.8567 27.2344C40.1503 26.6046 40.2971 25.7666 40.2971 24.7202C40.2971 23.6738 40.1503 22.8404 39.8567 22.2202C39.5632 21.5952 39.1299 21.1454 38.557 20.8707C37.9888 20.5914 37.2881 20.4517 36.4547 20.4517H34.7786V29.0028Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_204_4">
        <rect width="48" height="48" rx="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'EndIcon',
  props: {},
  setup() {
    return {};
  },
});
</script>
