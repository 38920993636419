<template>
  <div class="flex space-x-2">
    <!-- 이전 달 -->
    <GIconButton
      v-if="isShowDateHandler"
      icon="ChevronLeft"
      @click="onClickedDateHandler('prev')"
    />

    <!-- DatePicker -->
    <div class="space-y-1" :class="[width]">
      <label
        v-if="label"
        class="block sm:text-xs text-xs font-medium text-gray-700"
        >{{ label }}</label
      >
      <Datepicker
        v-model="date"
        auto-apply
        locale="ko"
        :format-locale="ko"
        :format="format"
        :model-type="modelType"
        :placeholder="placeholder"
        :disabled="disabled"
        :week-start="0"
        :enable-time-picker="enableTimePicker"
        :text-input="textInput"
        :clearable="clearable"
        :input-class-name="inputCls"
        :disabled-dates="disabledDate"
        @update:modelValue="onHandleDate"
      >
      </Datepicker>
    </div>

    <!-- 다음 달 -->
    <GIconButton
      v-if="isShowDateHandler"
      icon="ChevronRight"
      @click="onClickedDateHandler('next')"
    />
  </div>
</template>

<script>
import { defineComponent, computed, ref, watch } from '@vue/runtime-core';
import { GIconButton } from '@/components/ui';
import { ko } from 'date-fns/locale';
import { defaultDate } from '@/utils/format.ts';
import dayjs from 'dayjs';

export default defineComponent({
  name: 'DatePickerEx',
  components: {
    GIconButton,
  },
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Select Date',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    enableTimePicker: {
      type: Boolean,
      default: false,
    },
    textInput: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: 'w-44',
    },
    // modelvalue 값 형태 설정
    modelType: {
      type: String,
      default: 'yyyy-MM-dd',
    },
    // input에서 보여지는 형태 설정
    format: {
      type: String,
      default: 'yyyy-MM-dd',
    },
    disabledDate: {
      type: Function,
      default: () => {
        return null;
      },
    },
    isShowDateHandler: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change', 'update:modelValue'],

  setup(props, { emit }) {
    const date = ref(defaultDate(props.modelValue));

    watch(
      () => props.modelValue,
      newDate => {
        if (newDate) {
          date.value = props.modelValue;
        } else {
          date.value = defaultDate();
        }
      },
    );

    // input 스타일 지정
    const inputCls = computed(() => {
      let cls =
        'rdp-custom-input focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary h-[2.125rem] rounded-md shadow-sm';

      if (props.disabled) {
        cls = cls + ' bg-gray-300';
      }

      return cls;
    });

    // 달력에서 날짜 선택
    let onHandleDate = date => {
      // 날짜가 다르면 emit
      emit('change', date);
    };

    // 이전, 다음 달 버튼 클릭
    const onClickedDateHandler = type => {
      if (type === 'prev') {
        date.value = defaultDate(dayjs(date.value).subtract(1, 'day'));
      } else {
        date.value = defaultDate(dayjs(date.value).add(1, 'day'));
      }

      emit('change', date.value);
    };

    return {
      ko,
      date,
      inputCls,
      onHandleDate,
      onClickedDateHandler,
    };
  },
});
</script>
