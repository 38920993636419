<template>
  <div class="border rounded-md bg-white overflow-auto">
    <GTable
      id="cueSheetDetailList"
      :list="cueSheetDetailList"
      :is-loading="isLoading"
      :multiselect="false"
      :height="height"
      :first-default-row="false"
      :selected-row-index="selectedRowIndex"
      :draggable="mode === 'edit' ? true : false"
      :no-data-message="t('CueSheetDetailList.데이터가_없습니다')"
      :group="group"
      :pagination="{ isShow: false }"
      :selected-background-color="`bg-teal-100`"
      @on-row-clicked="onRowClicked"
      @on-row-db-clicked="onRowDbClicked"
      @is-finished-drag="endDragging"
    >
      <template #header>
        <th class="p-3 w-6">{{ t('CueSheetDetailList.순번') }}</th>
        <th class="p-2 w-16">{{ t('CueSheetDetailList.앵커멘트') }}</th>
        <th class="p-2 w-20">{{ t('CueSheetDetailList.상태') }}</th>
        <th class="p-2 w-16">{{ t('CueSheetDetailList.형식') }}</th>
        <th class="p-2 w-24">{{ t('CueSheetDetailList.시간') }}</th>
        <th class="p-2 w-24">{{ t('CueSheetDetailList.합계') }}</th>
        <th class="w-7"></th>
        <th class="p-2">{{ t('CueSheetDetailList.제목') }}</th>
        <th class="p-2 w-16">{{ t('CueSheetDetailList.자막') }}</th>
        <th class="p-2 w-16">{{ t('CueSheetDetailList.V') }}</th>
        <th class="p-2 w-16">{{ t('CueSheetDetailList.A') }}</th>
        <th class="p-2 w-24">{{ t('CueSheetDetailList.기자') }}</th>
        <th class="p-2 w-32">{{ t('CueSheetDetailList.참조사항') }}</th>
      </template>

      <template #list="{ row: row, index }">
        <CueSheetDetailListItem
          :cuesheet-detail="row"
          :selected-row="selectedRow"
          :index="index"
          :icons="icons"
          :form-icons="formIcons"
          :is-confirmed="disabled"
          :temp-symbol="tempSymbol"
          :temp-form="tempForm"
          :mode="mode"
          @on-edit-caption="$emit('on-edit-caption', row)"
          @on-edit-form="$emit('on-edit-form', row)"
          @on-edit-icon="onEditIcon"
          @on-edit-anchor="$emit('on-edit-anchor', row)"
          @on-edit-item="onEditItem"
          @on-delete-icon="onDeleteIcon"
          @on-delete-form-icon="onDeleteFormIcon"
        />
      </template>
    </GTable>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { GTable } from '@/components/ui';
import CueSheetDetailListItem from './CueSheetDetailListItem';

export default defineComponent({
  name: 'CueSheetDetailList',
  components: {
    GTable,
    CueSheetDetailListItem,
  },
  props: {
    cueSheetDetailList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    icons: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: () => {
        return null;
      },
    },
    selectedRow: {
      type: Object,
      default: () => {
        return null;
      },
    },
    selectedRowIndex: {
      type: Number,
      default: () => {
        return null;
      },
    },
    mode: {
      type: String,
      default: () => {
        return 'view';
      },
    },
    formIcons: {
      type: Array,
      default: () => {
        return [];
      },
    },
    height: {
      type: String,
      default: 'h-[calc(100vh-180px)]',
    },
    tempSymbol: {
      type: Object,
      default: () => {
        return null;
      },
    },
    tempForm: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  emits: [
    'on-edit-caption',
    'on-edit-form',
    'on-edit-icon',
    'on-edit-anchor',
    'on-row-clicked',
    'on-row-db-clicked',
    'on-edit-item',
    'on-delete-icon',
    'on-delete-form-icon',
    'is-finished-drag',
  ],
  setup(props, { emit }) {
    const { t } = useI18n();

    const onRowClicked = rowData => {
      emit('on-row-clicked', rowData);
    };

    const onRowDbClicked = rowData => {
      emit('on-row-db-clicked', rowData?.row);
    };

    const onEditIcon = (row, type) => {
      emit('on-edit-icon', row, type);
    };

    const onDeleteIcon = icon => {
      emit('on-delete-icon', icon);
    };

    const onDeleteFormIcon = icon => {
      emit('on-delete-form-icon', icon);
    };

    // item update
    const onEditItem = row => {
      emit('on-edit-item', row);
    };

    // 드래그 끝나는 시점
    const endDragging = parmas => {
      emit('is-finished-drag', parmas);
    };

    return {
      t,
      onRowClicked,
      onRowDbClicked,
      onEditIcon,
      onDeleteIcon,
      onDeleteFormIcon,
      onEditItem,
      endDragging,
    };
  },
});
</script>

<style scoped></style>
