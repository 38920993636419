import { stringToByte } from '@/utils/format';
import { articleTypeCode, speedCode } from '@/codes';
import { trimCarriageReturn } from '@/utils/str';
import { cloneDeep, sortBy } from 'lodash';
import dayjs from 'dayjs';

/**
 * anchor, article 자막 리스트 전체 바이트 계산
 * @param {Array} list - anchorCap, articleCap 리스트
 */
const changeByte = (list: string[]) => {
  let byte: number = 0;
  list.forEach((reporter: string) => {
    // 텍스트 앞뒤 \n 공백처리
    const trimStr = trimCarriageReturn(reporter);
    byte = byte + stringToByte(trimStr);
  });

  return byte;
};

/**
 * 발음속도에 맞춰 글자 수 계산
 * @param {string} speed
 * @param {number} byte
 */
const calculateSpeed = (speed: string, byte: number) => {
  let length = 0;
  switch (speed) {
    // 느림
    case speedCode.slow.id:
      length = Math.round((byte / 680) * 60);
      break;
    // 보통
    case speedCode.normal.id:
      length = Math.round((byte / 760) * 60);
      break;
    // 빠름
    case speedCode.fast.id:
      length = Math.round((byte / 840) * 60);
      break;
  }

  return length;
};

/**
 * 기사 길이 계산
 * @param {Array} anchorTextArray - 앵커멘트 리스트
 * @param {Array} reporterTextArray - 리포트멘트 리스트
 */
const calculateArticleTime = (
  anchorTextArray: string[],
  reporterTextArray: string[],
  speed: string,
) => {
  const anchorBytes = changeByte(anchorTextArray);
  const reporterBytes = changeByte(reporterTextArray);

  const calculatedAnchorText = calculateSpeed(speed, anchorBytes);
  const calculatedReporterText = calculateSpeed(speed, reporterBytes);

  // 로그인 사용자의 발음속도로 계산
  const totalTime = calculatedAnchorText + calculatedReporterText;

  return totalTime;
};

/**
 * 선택한 매크로에 해당하는 문자열 리턴
 * @param {string} macroType - 매크로 타입
 * @returns {string} - 매크로에 해당하는 문자열
 *
 */
const setMacro = (macroType: string) => {
  let char = '';

  switch (macroType) {
    case 'anchor':
      char = '◀ 앵 커 ▶';
      break;
    case 'reporter':
      char = '◀ 리포트 ▶';
      break;
    case 'int':
      char = '◀ INT ▶';
      break;
    case 'sync':
      char = '◀ SYNC ▶';
      break;
    case 'cg':
      char = '[ CG ]';
      break;
    case 'st-up':
      char = '◀ st-up ▶';
      break;
    case 'end':
      char = '◀ END ▶';
      break;
  }

  return char;
};

/**
 * 자막 형태 변경
 * @param {Array} list - [[{}]] 또는 [[{}], [{},{}], ..] 형태의 자막 리스트
 */
const changeCaptionForm = (list: any) => {
  let captionList: any[] = [];

  // 내용이 들어있는 경우만 리스트로 만든다.
  list.forEach((captions: any) => {
    if (Array.isArray(captions) && captions.length) {
      captions.forEach((cap: any) => {
        if (cap.text) {
          const obj = {
            templateId: 0, // 추후 템플릿 들어갔을 경우
            lnNo: cap.lineNo,
            lnOrd: cap.lineOrd,
            artclLnNo: cap.articleLineNo,
            capCtt: cap.text,
            totalLines: cap.totalLines,
          };
          captionList.push(obj);
        }
      });
    } else if (captions.constructor === Object) {
      captionList.push({
        templateId: 0, // 추후 템플릿 들어갔을 경우
        lnNo: captions.lineNo,
        lnOrd: captions.lineOrd,
        artclLnNo: captions.articleLineNo,
        capCtt: captions.text,
        totalLines: captions.totalLines,
      });
    }
  });

  return captionList;
};

/**
 * 내기사 등록 API요청시 변경된 자막 정보 형태 변경
 * @param {Array} list - [[{}]] 또는 [[{}], [{},{}], ..] 형태의 자막 리스트
 */
const changeCaptionFormFullName = (list: any) => {
  let captionList: any[] = [];

  // 내용이 들어있는 경우만 리스트로 만든다.
  list.forEach((captions: any) => {
    if (Array.isArray(captions) && captions.length) {
      captions.forEach((cap: any) => {
        if (cap.text) {
          const obj = {
            templateId: 0, // 추후 템플릿 들어갔을 경우
            lineNo: cap.lineNo,
            lineOrder: cap.lineOrd,
            articleLineNo: cap.articleLineNo,
            capContent: cap.text,
            totalLines: cap.totalLines,
          };
          captionList.push(obj);
        }
      });
    } else if (captions.constructor === Object) {
      captionList.push({
        templateId: 0, // 추후 템플릿 들어갔을 경우
        lineNo: captions.lineNo,
        lineOrder: captions.lineOrd,
        articleLineNo: captions.articleLineNo,
        capContent: captions.text,
        totalLines: captions.totalLines,
      });
    }
  });

  return captionList;
};

/**
 * 앵커, 기사 자막 DB형태로 변경
 * @param {Object} articleType - 기사 형식
 * @param {Object} articleEditorState - 기사작성기 값
 */
const makeDBCaptionForm = (articleType: any, articleEditorState: any) => {
  let articleAnchorCaps: any[] = [];
  let articleCaps: any[] = [];
  let capList: any[] = [];

  // anchor 자막
  // articleAnchorCaps = changeCaptionForm(articleEditorState.anchorCaptionArray);
  // article 자막
  articleCaps = changeCaptionForm(articleEditorState.reporterCaptionArray);

  // 2023-10-26 내기사 등록 api 요청시 변경된 자막 정보값
  capList = changeCaptionFormFullName(articleEditorState.reporterCaptionArray);

  // lnNo 순번으로 sort
  // articleAnchorCaps = articleAnchorCaps.sort((a, b) => a.lnNo - b.lnNo);
  articleCaps = sortBy(articleCaps, ['lnOrd', 'lnNo']);

  return { articleAnchorCaps, articleCaps, capList };
};

/**
 * 제목, 자막, 멘트 변경 여부 (신규 생성)
 */
const isChangedDataOfCreate = (data: any) => {
  const {
    title,
    anchorCaptionArray,
    reporterCaptionArray,
    anchorTextArray,
    reporterTextArray,
    type,
  } = data;

  // C/T일 경우
  if (type === articleTypeCode.CrossTalk.id) {
    let tempTextArr: any = [];
    let isChangedArticleText = false;
    let isChangedAnchorText = false;
    let isChangedArticleCaption = false;
    let isChangedAnchorCaption = false;

    // 제목 변경 사항 검사
    if (title) {
      return true;
    }

    // 리포트 멘트 변경 검사
    reporterTextArray.forEach((text: string) => {
      const replaceText = text.replace(/\n/g, '');
      tempTextArr.push(replaceText);
    });
    isChangedArticleText = tempTextArr.some((text: string) => text);

    // 리포트 멘트 변경 사항 없을 경우 앵커 멘트 변경 검사
    if (!isChangedArticleText) {
      tempTextArr = [];
      anchorTextArray.forEach((text: string) => {
        const replaceText = text.replace(/\n/g, '');
        tempTextArr.push(replaceText);
      });
      isChangedAnchorText = tempTextArr.some((text: string) => text);
    } else {
      return true;
    }

    // 리포트 멘트, 앵커 멘트 모두 변경 사항이 없다면 리프토 자막 변경 검사
    if (!isChangedAnchorText) {
      // 자막 검사 (리포트 자막 검사하고 변경없으면 앵커 자막 검사)
      for (let i = 0; i < reporterCaptionArray.length; i++) {
        if (reporterCaptionArray[i].length) {
          for (let j = 0; j < reporterCaptionArray[i].length; j++) {
            if (reporterCaptionArray[i][j]?.text) {
              isChangedArticleCaption = true;
              break;
            }
          }
        }
      }
    } else {
      return true;
    }

    // 리포트 자막 변경 사항이 없다면 앵커 자막 변경 검사
    if (!isChangedArticleCaption) {
      for (let i = 0; i < anchorCaptionArray.length; i++) {
        if (anchorCaptionArray[i].length) {
          for (let j = 0; j < anchorCaptionArray[i].length; j++) {
            if (anchorCaptionArray[i][j]?.text) {
              isChangedAnchorCaption = true;
              break;
            }
          }
        }
      }
    } else {
      return true;
    }

    if (!isChangedAnchorCaption) {
      return false;
    } else {
      return true;
    }
  }
  // C/T 외의 경우
  else {
    // 제목 변경 사항 검사
    if (title) {
      return true;
    }

    const replaceReporterText = reporterTextArray[0].replace(/\n/g, '');
    let replaceAnchorText = '';
    let isChangedReporterCaption = false;
    let isChangedAnchorCaption = false;

    // 리포트 멘트 변경 사항 없을 경우 앵커 멘트 변경 검사
    if (!replaceReporterText) {
      replaceAnchorText = anchorTextArray[0].replace(/\n/g, '');
    } else {
      return true;
    }

    // 리포트 멘트, 앵커 멘트 모두 변경사항이 없을 경우 리포트 자막 변경 검사
    if (!replaceAnchorText) {
      isChangedReporterCaption =
        reporterCaptionArray[0]?.length > 0 ? true : false;
    } else {
      return true;
    }

    // 리포트 자막 변경 사항이 없다면 앵커 자막 변경 검사
    if (!isChangedReporterCaption) {
      isChangedAnchorCaption = anchorCaptionArray[0]?.length > 0 ? true : false;
    } else {
      return true;
    }

    if (!isChangedAnchorCaption) {
      return false;
    } else {
      return true;
    }
  }
};

/**
 * 제목, 자막, 멘트 변경 여부 (기사 수정)
 */
const isChangedDataOfEdit = (origin: any, change: any) => {
  const originData = cloneDeep(origin);
  const changeData = cloneDeep(change);

  // 1. 제목이 없을 경우
  // 2. 형식 또는 제목이 변경되었을 경우
  if (
    !changeData.title ||
    originData.title !== changeData.title ||
    originData.type !== changeData.type
  ) {
    return true;
  }

  if (originData.type === changeData.type) {
    // C/T 일 경우
    if (changeData.type === articleTypeCode.CrossTalk.id) {
      // anchorTextArray, reporterTextArray 앞뒤 \n 없애기
      originData.anchorTextArray = originData.anchorTextArray.map(
        (text: string) => {
          return trimCarriageReturn(text);
        },
      );
      originData.reporterTextArray = originData.reporterTextArray.map(
        (text: string) => {
          return trimCarriageReturn(text);
        },
      );
      changeData.anchorTextArray = changeData.anchorTextArray.map(
        (text: string) => {
          return trimCarriageReturn(text);
        },
      );
      changeData.reporterTextArray = changeData.reporterTextArray.map(
        (text: string) => {
          return trimCarriageReturn(text);
        },
      );

      // key값 순서가 같도록 오름차순 정렬 후 JSON string 형태로 변경
      const originalJsonData = JSON.stringify(
        Object.fromEntries(
          Object.entries(originData).sort(([a], [b]) => (a < b ? -1 : 1)),
        ),
      );
      const changeJsonData = JSON.stringify(
        Object.fromEntries(
          Object.entries(changeData).sort(([a], [b]) => (a < b ? -1 : 1)),
        ),
      );

      if (originalJsonData !== changeJsonData) {
        return true;
      } else {
        return false;
      }
    }
    // 그 외일 경우
    else {
      // anchorTextArray, reporterTextArray 앞뒤 \n 없애기
      originData.anchorTextArray = [
        trimCarriageReturn(originData.anchorTextArray[0]),
      ];
      originData.reporterTextArray = [
        trimCarriageReturn(originData.reporterTextArray[0]),
      ];
      changeData.anchorTextArray = [
        trimCarriageReturn(changeData.anchorTextArray[0]),
      ];
      changeData.reporterTextArray = [
        trimCarriageReturn(changeData.reporterTextArray[0]),
      ];

      // key값 순서가 같도록 오름차순 정렬 후 JSON string 형태로 변경
      const originalJsonData = JSON.stringify(
        Object.fromEntries(
          Object.entries(originData).sort(([a], [b]) => (a < b ? -1 : 1)),
        ),
      );
      const changeJsonData = JSON.stringify(
        Object.fromEntries(
          Object.entries(changeData).sort(([a], [b]) => (a < b ? -1 : 1)),
        ),
      );

      if (originalJsonData !== changeJsonData) {
        return true;
      } else {
        return false;
      }
    }
  }
};

const isValidEmbargo = (embargoDate: string) => {
  if (dayjs().isBefore(dayjs(embargoDate))) {
    return true;
  }

  return false;
};

// 태그 이름만 배열로 만들어주는 함수
const setTagNames = (tag: any) => {
  return tag.map((item: any) => item.tagName);
};

export {
  calculateSpeed,
  setMacro,
  changeByte,
  calculateArticleTime,
  makeDBCaptionForm,
  isChangedDataOfCreate,
  isChangedDataOfEdit,
  isValidEmbargo,
  setTagNames,
};
