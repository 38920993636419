import { AxiosInstance } from 'axios';

const PREFIX = '/board';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 게시판 조회
     * @param params
     * @returns
     */
    findAll: async (params: any) => {
      return $axios
        .get(`${PREFIX}`, { params })
        .then(response => response.data);
    },

    /**
     * 게시판 등록
     * @param params
     * @returns
     */
    create: async (params: any) => {
      return $axios.post(`${PREFIX}`, params).then(response => response.data);
    },

    /**
     * 게시판 수정
     * @param boardId 게시판 ID
     * @param params
     * @returns
     */
    update: async (boardId: string | number, params: any) => {
      return $axios
        .put(`${PREFIX}/${boardId}`, params)
        .then(response => response.data);
    },

    /**
     * 게시판 순서 편집
     * @param boardId 게시판 ID
     * @param params
     * @returns
     */
    order: async (boardId: string | number, params: any) => {
      return $axios
        .put(`${PREFIX}/${boardId}/order-number`, params)
        .then(response => response.data);
    },

    /**
     * 게시판 삭제
     * @param boardId 게시판 ID
     * @returns
     */
    delete: async (boardId: string | number) => {
      return $axios
        .delete(`${PREFIX}/${boardId}`)
        .then(response => response.data);
    },

    //==============================게시판의 게시글 API ===============================//

    /**
     * 게시판 게시글 목록조회
     * @param params
     * @returns
     */
    findAllPost: async (boardId: string | number, params: any) => {
      return $axios
        .get(`${PREFIX}/${boardId}/post`, { params })
        .then(response => response.data);
    },

    /**
     * 게시판 게시글 등록
     * @param params
     * @returns
     */
    createPost: async (boardId: string | number, params: any) => {
      return $axios
        .post(`${PREFIX}/${boardId}/post`, params)
        .then(response => response.data);
    },

    /**
     * 게시판 게시글 수정
     * @param params
     * @returns
     */
    updatePost: async (
      boardId: string | number,
      bbmId: string | number,
      params: any,
    ) => {
      return $axios
        .put(`${PREFIX}/${boardId}/post/${bbmId}`, params)
        .then(response => response.data);
    },

    /**
     * 게시판 게시글 상세 조회
     * @param boardId 게시판 id
     * @param bbmId  게시글 id
     * @returns
     */
    findOnePost: async (boardId: string | number, bbmId: string | number) => {
      return $axios
        .get(`${PREFIX}/${boardId}/post/${bbmId}`)
        .then(response => response.data);
    },

    /**
     * 게시판 게시글 삭제
     * @param boardId 게시판 ID
     * @param bbmId 게시글 ID
     * @returns
     */
    deletePost: async (boardId: string | number, bbmId: string | number) => {
      return $axios
        .delete(`${PREFIX}/${boardId}/post/${bbmId}`)
        .then(response => response.data);
    },

    //==============================게시글 코멘트 API ===============================//

    /**
     * 게시글 댓글 목록조회
     * @param boardId 게시판 ID
     * @param bbmId 게시글 ID
     * @returns
     */
    findAllComment: async (
      boardId: string | number,
      bbmId: string | number,
    ) => {
      return $axios
        .get(`${PREFIX}/${boardId}/post/${bbmId}/comment`)
        .then(response => response.data);
    },

    /**
     * 게시글 댓글 추가
     * @param boardId 게시판 ID
     * @param bbmId 게시글 ID
     * @returns
     */
    createComment: async (
      boardId: string | number,
      bbmId: string | number,
      params: any,
    ) => {
      return $axios
        .post(`${PREFIX}/${boardId}/post/${bbmId}/comment`, params)
        .then(response => response.data);
    },

    /**
     * 게시글 댓글 수정
     * @param boardId 게시판 ID
     * @param bbmId 게시글 ID
     * @param cmntId 댓글 ID
     * @returns
     */
    updateComment: async (
      boardId: string | number,
      bbmId: string | number,
      cmntId: string,
      params: any,
    ) => {
      return $axios
        .put(`${PREFIX}/${boardId}/post/${bbmId}/comment/${cmntId}`, params)
        .then(response => response.data);
    },

    /**
     * 게시글 댓글 삭제
     * @param boardId 게시판 ID
     * @param bbmId 게시글 ID
     * @param cmntId 댓글 ID
     * @returns
     */
    deleteComment: async (
      boardId: string | number,
      bbmId: string | number,
      cmntId: string,
    ) => {
      return $axios
        .delete(`${PREFIX}/${boardId}/post/${bbmId}/comment/${cmntId}`)
        .then(response => response.data);
    },
  };
};
