<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    class="w-5 h-5"
  >
    <path
      d="M13.34,20H7.9a1,1,0,0,1-1-.94L6.29,8H17.72l-.19,3.45a1,1,0,0,0,2,.11L19.73,8H21a1,1,0,0,0,0-2H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H3A1,1,0,0,0,3,8H4.28L4.9,19.17A3,3,0,0,0,7.9,22h5.44a1,1,0,1,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Z"
    />
    <path
      d="M18.83,15a1,1,0,0,0-.12-1.24,1,1,0,0,0-1.42,0l-1.5,1.5a1,1,0,0,0,0,1.42l1.5,1.5a1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29,1,1,0,0,0,.17-1.16A1.5,1.5,0,0,1,20,18.5a1.42,1.42,0,0,1-.21.75,1.48,1.48,0,0,1-2.58,0,1,1,0,0,0-1.74,1,3.49,3.49,0,0,0,6,0A3.39,3.39,0,0,0,22,18.5,3.5,3.5,0,0,0,18.83,15Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'RecoverIcon',
};
</script>
