import {
  AdminRouterName,
  AdminRouterIcon,
  AdminRouterPath,
} from '@/types/router';

const adminPath = 'admin';

export const adminRoutes = [
  {
    path: `/${adminPath}/${AdminRouterPath.User}`,
    name: AdminRouterName.User,
    component: () => import('@/views/admin/user/User.vue'),
    meta: {
      requiresAuth: true,
      layout: 'AdminLayout',
      menu: {
        default: true,
        icon: AdminRouterIcon.User,
      },
    },
  },
  {
    path: `/${adminPath}/${AdminRouterPath.Program}`,
    name: AdminRouterName.Program,
    component: () => import('@/views/admin/program/Program.vue'),
    meta: {
      requiresAuth: true,
      layout: 'AdminLayout',
      menu: {
        default: false,
        icon: AdminRouterIcon.Program,
      },
    },
  },
  {
    path: `/${adminPath}/${AdminRouterPath.Group}`,
    name: AdminRouterName.Group,
    component: () => import('@/views/admin/userGroup/UserGroup.vue'),
    meta: {
      requiresAuth: true,
      layout: 'AdminLayout',
      menu: {
        default: false,
        icon: AdminRouterIcon.Group,
      },
    },
  },
  {
    path: `/${adminPath}/${AdminRouterPath.CreateGroup}`,
    name: AdminRouterName.CreateGroup,
    component: () => import('@/views/admin/userGroup/UserGroupCreate.vue'),
    meta: {
      requiresAuth: true,
      layout: 'AdminLayout',
    },
  },
  {
    path: `/${adminPath}/${AdminRouterPath.EditGroup}`,
    name: AdminRouterName.EditGroup,
    component: () => import('@/views/admin/userGroup/UserGroupEdit.vue'),
    meta: {
      requiresAuth: true,
      layout: 'AdminLayout',
    },
    props: true,
  },
  {
    path: `/${adminPath}/${AdminRouterPath.Icon}`,
    name: AdminRouterName.Icon,
    component: () => import('@/views/admin/icon/Icon.vue'),
    meta: {
      requiresAuth: true,
      layout: 'AdminLayout',
      menu: {
        default: false,
        icon: AdminRouterIcon.Icon,
      },
    },
  },
  {
    path: `/${adminPath}/${AdminRouterPath.Category}`,
    name: AdminRouterName.Category,
    component: () => import('@/views/admin/category/Category.vue'),
    meta: {
      requiresAuth: true,
      layout: 'AdminLayout',
      menu: {
        default: false,
        icon: AdminRouterIcon.Icon,
      },
    },
  },
  {
    path: `/${adminPath}/${AdminRouterPath.ScrollNewsCategory}`,
    name: AdminRouterName.ScrollNewsCategory,
    component: () =>
      import('@/views/admin/scrollNewsCategory/ScrollNewsCategory.vue'),
    meta: {
      requiresAuth: true,
      layout: 'AdminLayout',
      menu: {
        default: false,
        icon: AdminRouterIcon.Icon,
      },
    },
  },
  {
    path: `/${adminPath}/${AdminRouterPath.Department}`,
    name: AdminRouterName.Department,
    component: () => import('@/views/admin/department/Department.vue'),
    meta: {
      requiresAuth: true,
      layout: 'AdminLayout',
      menu: {
        default: false,
        icon: AdminRouterIcon.Icon,
      },
    },
  },
  {
    path: `/${adminPath}/${AdminRouterPath.Tag}`,
    name: AdminRouterName.Tag,
    component: () => import('@/views/admin/tag/Tag.vue'),
    meta: {
      requiresAuth: true,
      layout: 'AdminLayout',
      menu: {
        default: false,
        icon: AdminRouterIcon.Icon,
      },
    },
  },
  {
    path: `/${adminPath}/${AdminRouterPath.BoardManagement}`,
    name: AdminRouterName.BoardManagement,
    component: () => import('@/views/admin/board/BoardManagement.vue'),
    meta: {
      requiresAuth: true,
      layout: 'AdminLayout',
      menu: {
        default: false,
        icon: AdminRouterIcon.Icon,
      },
    },
  },
];
