<template>
  <svg
    fill="currentColor"
    class="h-6 w-6"
    enable-background="new 0 0 500 500"
    id="Layer_1"
    version="1.1"
    viewBox="0 0 500 500"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <g>
        <path
          d="M400.6,402.8h-27.4c0-67.9-55.3-123.2-123.2-123.2c-67.9,0-123.2,55.3-123.2,123.2H99.4c0-83,67.5-150.6,150.6-150.6    S400.6,319.8,400.6,402.8z"
        />
      </g>
      <g>
        <path
          d="M250,279.7c-52.8,0-95.8-43-95.8-95.8S197.2,88,250,88c52.8,0,95.8,43,95.8,95.8S302.8,279.7,250,279.7z M250,115.4    c-37.7,0-68.4,30.7-68.4,68.4s30.7,68.4,68.4,68.4s68.4-30.7,68.4-68.4S287.7,115.4,250,115.4z"
        />
      </g>
      <g><polygon points="277.4,266 222.6,266 236.3,307 263.7,307   " /></g>
      <g>
        <polygon points="222.6,402.8 277.4,402.8 263.7,320.7 236.3,320.7   " />
      </g>
    </g>
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AnchorIcon',
  props: {},
  setup() {
    return {};
  },
});
</script>
