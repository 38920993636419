<template>
  <div class="flex space-x-2">
    <!-- 이전 달 -->
    <GIconButton
      v-if="isShowMonthHandler"
      icon="ChevronLeft"
      @click="onClickedMonthHandler('prev')"
    />

    <!-- DatePicker -->
    <div class="space-y-1" :class="[width]">
      <label
        v-if="label"
        class="block sm:text-xs text-xs font-medium text-gray-700"
        >{{ label }}</label
      >
      <Datepicker
        v-model="monthValue"
        auto-apply
        month-picker
        locale="ko"
        :format-locale="ko"
        :format="format"
        :model-type="modelType"
        :placeholder="placeholder"
        :disabled="disabled"
        :text-input="textInput"
        :clearable="clearable"
        :input-class-name="inputCls"
        :disabled-dates="disabledDate"
        @update:modelValue="onHandleDate"
      >
      </Datepicker>
    </div>

    <!-- 다음 달 -->
    <GIconButton
      v-if="isShowMonthHandler"
      icon="ChevronRight"
      @click="onClickedMonthHandler('next')"
    />
  </div>
</template>

<script>
import { defineComponent, computed, ref, watchEffect } from '@vue/runtime-core';
import { GIconButton } from '@/components/ui';
import { ko } from 'date-fns/locale';
import dayjs from 'dayjs';
import { monthFormat } from '@/utils/format';

export default defineComponent({
  name: 'MonthPickerEx',
  components: {
    GIconButton,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Select Month',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    textInput: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: 'min-w-full',
    },
    // modelvalue 값 형태 설정
    modelType: {
      type: String,
      default: 'yyyy-MM',
    },
    // input에서 보여지는 형태 설정
    format: {
      type: String,
      default: 'yy-MM',
    },
    disabledDate: {
      type: Function,
      default: () => {
        return null;
      },
    },
    isShowMonthHandler: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change', 'update:modelValue'],

  setup(props, { emit }) {
    const monthValue = ref({
      year: dayjs().year(),
      month: dayjs().month(), // 0부터 시작
    });

    watchEffect(() => {
      if (props.modelValue) {
        monthValue.value = {
          year: dayjs(props.modelValue).year(),
          month: dayjs(props.modelValue).month(),
        };
      }
      emit(
        'update:modelValue',
        `${monthValue.value.year}-${monthFormat(monthValue.value.month)}-01`,
      );
    });

    // input 스타일 지정
    const inputCls = computed(() => {
      let cls =
        'rdp-custom-input focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary h-[2.125rem] rounded-md shadow-sm';

      if (props.disabled) {
        cls = cls + ' bg-gray-300';
      }

      return cls;
    });

    // 달력에서 날짜 선택
    let onHandleDate = date => {
      emit('change', date);
    };

    // 이전, 다음 달 버튼 클릭
    const onClickedMonthHandler = type => {
      const month = monthFormat(monthValue.value.month);
      const currentDate = dayjs(`${monthValue.value.year}-${month}-01`);
      let calcDate = '';

      if (type === 'prev') {
        calcDate = dayjs(currentDate).subtract(1, 'month');
      } else {
        calcDate = dayjs(currentDate).add(1, 'month');
      }

      monthValue.value = {
        year: dayjs(calcDate).year(),
        month: dayjs(calcDate).month(),
      };

      emit(
        'change',
        `${monthValue.value.year}-${monthFormat(monthValue.value.month)}-01`,
      );
    };

    return {
      ko,
      monthValue,
      inputCls,
      onHandleDate,
      onClickedMonthHandler,
    };
  },
});
</script>
