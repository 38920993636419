import { AxiosInstance } from 'axios';
import articleApi from './articleApi';
import linkApi from './linkApi';
import fileApi from './fileApi';
import actionLogApi from './actionLogApi';
import logApi from './logApi';

export default ($axios: AxiosInstance) => {
  return {
    basic: articleApi($axios),
    link: linkApi($axios),
    file: fileApi($axios),
    actionLog: actionLogApi($axios),
    log: logApi($axios),
  };
};
