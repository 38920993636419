<template>
  <div class="pt-7 space-y-[5.375rem] h-[28.625rem]">
    <div class="space-y-3 px-11">
      <div class="flex items-center">
        <div class="w-32">{{ t('UserInfo.지역사_정보') }}</div>
        <GInput
          v-model="companyName"
          name="companyName"
          width="w-72"
          :disabled="true"
        />
      </div>
      <div class="flex items-center">
        <div class="w-32">
          {{ t('UserInfo.이름') }}<span class="text-red-500">*</span>
        </div>
        <GInput
          v-model="userNm"
          name="userNm"
          width="w-72"
          :placeholder="t('UserInfo.이름')"
        />
      </div>
      <div class="flex items-center">
        <div class="w-32">{{ t('UserInfo.아이디') }}</div>
        <GInput v-model="userId" name="userId" width="w-72" :disabled="true" />
      </div>
      <div class="flex items-center">
        <div class="w-32">{{ t('UserInfo.비밀번호') }}</div>
        <GInput
          v-model="userPwd"
          name="userPwd"
          width="w-72"
          type="password"
          :placeholder="t('UserInfo.비밀번호')"
        />
      </div>
      <div class="flex items-center">
        <div class="w-32">{{ t('UserInfo.비밀번호_확인') }}</div>
        <GInput
          v-model="userPwdConfirm"
          name="userPwdConfirm"
          width="w-72"
          type="password"
          :placeholder="t('UserInfo.비밀번호_확인')"
        />
      </div>
      <div class="flex items-center">
        <div class="w-32">{{ t('UserInfo.이메일') }}</div>
        <GInput
          v-model="email"
          name="email"
          width="w-72"
          :placeholder="t('UserInfo.이메일')"
        />
      </div>
      <div class="flex items-center">
        <div class="w-32">{{ t('UserInfo.부서') }}</div>
        <GInput
          v-model="department"
          name="department"
          width="w-72"
          :disabled="true"
        />
      </div>
    </div>

    <!-- 버튼들 -->
    <div class="flex px-8 justify-end space-x-3">
      <GButton type="secondary" @click="close">{{
        t('UserInfo.취소')
      }}</GButton>
      <GButton :loading="isSubmitting" :disabled="!canSubmit" @click="submit">{{
        t('UserInfo.수정')
      }}</GButton>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, ref, onMounted } from 'vue';
import { GInput, GButton } from '@/components/ui';
import { useForm } from 'vee-validate';
import { useFormSubmit } from '@/hooks/use-form';
import yup from '@/utils/yup';
import { useI18n } from 'vue-i18n';
import { useMarsLApi } from '@/apis/mars-l-api';
import { getCompanyName } from '@/utils/get-data-helper';

const emit = defineEmits(['close']);

const { t } = useI18n();
const marsLApi = useMarsLApi();

const companyName = ref(null);
const userId = ref(null);
const userNm = ref(null);
const userPwd = ref(null);
const userPwdConfirm = ref(null);
const email = ref(null);
const department = ref(null);

// validation 적용
const { handleSubmit, isSubmitting, meta, submitCount } = useForm({
  initialValues: {
    userNm: '',
    userPwd: '',
    userPwdConfirm: '',
  },
  validationSchema: yup.object().shape({
    userNm: yup.string().required(),
    userPwd: yup.string().max(20),
    userPwdConfirm: yup
      .string()
      .oneOf(
        [yup.ref('userPwd'), null],
        t('UserInfo.비밀번호가_일치하지_않습니다'),
      ),
    email: yup.string().email(),
  }),
});

// submit 할 수 있는지 여부
const canSubmit = useFormSubmit(meta, submitCount);

// 나의 정보 가져오기
const getMyInfo = async () => {
  userPwd.value = '';
  userPwdConfirm.value = '';

  try {
    const response = await marsLApi.myInfo.basic.findOne();

    if (response.success) {
      companyName.value = await getCompanyName(response.data.channelId);
      userId.value = response.data.userId;
      userNm.value = response.data.userName;
      email.value = response.data.email;
      department.value = response.data.departmentName;
    }
  } catch (error) {
    console.error('error', error);
  }
};

// 수정 버튼 클릭
const submit = handleSubmit(async values => {
  try {
    const params = {
      userName: values.userNm.trim(),
      email: values.email.trim(),
      changePassword: values.userPwd.trim(),
    };

    const response = await marsLApi.myInfo.basic.update(params);

    if (response.success) {
      window.Notify.success({
        message: '[사용자 정보 변경] 완료되었습니다.',
      });
      getMyInfo();
    }
  } catch (error) {
    console.error('error', error);
  }
});

// 닫기 버튼 클릭
const close = () => {
  emit('close');
};

onMounted(() => {
  getMyInfo();
});
</script>
