<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <div v-show="false">
    <div id="printContent" class="">
      <template v-for="(content, index) in printList" :key="`page-${index}`">
        <table class="main" style="width: 100%">
          <thead style="page-break-before: always">
            <tr>
              <div class="article-title-section">
                {{ content.artclTitl || ' ' }}
              </div>
              <div class="article-meta-section border-gray">
                <div class="article-meta-group">
                  <div class="article-meta">
                    기사형태 :
                    {{ setArticleTypeName(content.artclTypCd) }}
                  </div>
                  <div class="article-meta">
                    기자 : {{ content?.setReporterName }}
                  </div>
                </div>

                <div class="article-meta-group">
                  <div class="article-meta">
                    작성일시 :
                    {{
                      content.apprvTypDtm
                        ? defaultDateTimeHm(content?.inputDtm)
                        : ''
                    }}
                  </div>
                  <div class="article-meta">
                    기사시간 : {{ setArticleTime(content) }}
                  </div>
                </div>

                <div class="article-meta-group">
                  <div class="article-meta">
                    출고일시 :
                    {{
                      content.apprvTypDtm
                        ? defaultDateTimeHm(content?.apprvTypDtm)
                        : ''
                    }}
                  </div>
                  <div class="article-meta">
                    {{ setArticleDivName(content?.artclDivCd) }}
                    {{
                      content?.artclDivCd !== articleDivisionCode.basic.id
                        ? setEmbargoTime(content)
                        : ''
                    }}
                  </div>
                </div>
              </div>
            </tr>
          </thead>
          <tr style="">
            <td>
              <!-- 테이블 바디 영역 -->
              <table class="" style="width: 100%">
                <tbody style="">
                  <div
                    v-for="(editor, idx) in content.editorArray"
                    :key="`editor-${idx}`"
                    class="line"
                    :class="
                      idx === content.editorArray.length - 1 ? 'line-end' : ''
                    "
                  >
                    <div class="content-section">
                      <div class="caption-section">
                        <div
                          v-for="(caption, idx) in editor.captions"
                          :key="`caption-${idx}`"
                          class=""
                        >
                          <div class="caption">{{ caption }}</div>
                        </div>
                      </div>

                      <div class="article-section">
                        <div
                          v-for="(article, idx) in editor.articles"
                          :key="`article-${idx}`"
                          class=""
                        >
                          <div class="article">
                            {{ article }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- 한 태그안에 자막, 내용 동시에 출력 -->
                    <!-- <div
                      v-for="(caption, idx) in editor.captions"
                      :key="`caption-${idx}`"
                      class="content-section"
                    >
                      <div class="caption-section">
                        {{ caption }}
                      </div>
                      <div class="article-section">
                        {{ editor.articles[idx] }}
                      </div>
                    </div>
                  </div> -->
                  </div>
                </tbody>
              </table>
            </td>
          </tr>
        </table>
        <!-- <div class="footer">
              <div class="">{{ userName }}({{ userId }}) {{ printDate }}</div>
              <div class="page">
                {{ cruuentPage(1) }} /
                {{ printList[printList.length - 1].totalCount }}
              </div>
            </div> -->
      </template>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, onMounted, ref } from 'vue';
import { useUserInfo } from '@/hooks/use-user-info';
import printJS from 'print-js';
import { articleDivisionCode, articleTypeCode } from '@/codes';
import { defaultDateTimeHm, defaultDate } from '@/utils/format';
import { secondToMs } from '@/utils/format.ts';
import {
  concatArrays,
  setArticleCompressedPlusData,
  getReporterName,
} from '@/utils/print';

const props = defineProps({
  printData: {
    type: Array,
    default: () => {
      return [];
    },
  },
});

const emit = defineEmits(['close-print']);

const { userId, userName } = useUserInfo();
// const printDate = defaultDateTime(new Date());
const printList = ref();
// let page = 0;
// const cruuentPage = index => {
//   page += index;
//   return page;
// };

// 엠바고 날짜
// const setEmbargoTime = content => {
//   let date = '';
//   if (content.artclDivCd === articleDivisionCode.expected.id) {
//     date = `예정 : ${defaultDate(content.artclDivDtm)}`;
//   } else if (content.artclDivCd === articleDivisionCode.embargo.id) {
//     date = `엠바고 : ${defaultDateTimeHm(content.artclDivDtm)}`;
//   }
//   return date;
// };

const printContent = () => {
  printJS({
    printable: 'printContent', // 프린트할 요소의 ID
    type: 'html', // 프린트할 내용의 타입
    css: ['/print/common.css', '/print/compressed-plus.css'],
    documentTitle: `${userName}(${userId})`, // 인쇄될 문서의 제목
    scanStyles: false,
    // onPrintDialogClose: onClosePrint,
  });
  setTimeout(() => {
    window.addEventListener('mouseover', handler);
  }, 1000);
};

const handler = () => {
  // Make sure the event only happens once.
  window.removeEventListener('mouseover', handler);

  // Remove iframe from the DOM, by default 'printJS'
  const iframe = document.getElementById(`printJS`);

  emit('close-print');
  if (iframe) {
    iframe.remove();
  }
};

/**
 * 기사형식 이름
 */
const setArticleTypeName = articleTypCd => {
  let articleTypeName = '';
  switch (articleTypCd) {
    case articleTypeCode.Straight.id:
      articleTypeName = articleTypeCode.Straight.name;
      break;
    case articleTypeCode.Report.id:
      articleTypeName = articleTypeCode.Report.name;
      break;
    case articleTypeCode.CrossTalk.id:
      articleTypeName = articleTypeCode.CrossTalk.name;
      break;
    case articleTypeCode.BottomRole.id:
      articleTypeName = articleTypeCode.BottomRole.name;
      break;
  }
  return articleTypeName;
};

/**
 * 기사시간: 앵커멘트 + 리포트멘트 + 엑스트라 타임
 */
const setArticleTime = content => {
  return secondToMs(
    content.artclCttTime + content.ancMentCttTime + content.artclExtTime,
  );
};

/**
 * 기사 압축(자막+) 프린트 가공
 */
const setCompressedPlusPrintList = articleList => {
  let list = [];

  list = articleList.map(item => {
    // 앵커 + 자막 배열
    const editorArray = concatArrays(
      setArticleCompressedPlusData(
        item.anchorCaptionArray,
        item.anchorTextArray,
        'anchor',
      ),
      setArticleCompressedPlusData(
        item.reporterCaptionArray,
        item.reporterTextArray,
        'report',
      ),
      item.artclTypCd,
    );

    return {
      ...item,
      setReporterName: getReporterName(item),
      artclTitl: item?.artclTitl || item?.cueItemTitl,
      editorArray: editorArray,
    };
  });
  return list;
};

// 기사 구분 날짜
const setEmbargoTime = content => {
  let date = '';
  if (content?.artclDivCd === articleDivisionCode.expected.id) {
    date = `${defaultDate(content.artclDivDtm)}`;
  } else if (content?.artclDivCd === articleDivisionCode.embargo.id) {
    date = defaultDateTimeHm(content.artclDivDtm);
  }
  return date;
};
/**
 * 기사 구분 이름
 */
const setArticleDivName = artclDivCd => {
  let articleDivName = '';
  switch (artclDivCd) {
    case articleDivisionCode.basic.id:
      articleDivName = articleDivisionCode.basic.name;
      break;
    case articleDivisionCode.expected.id:
      articleDivName = articleDivisionCode.expected.name;
      break;
    case articleDivisionCode.embargo.id:
      articleDivName = articleDivisionCode.embargo.name;
      break;
  }
  return `${articleDivName} : `;
};
printList.value = setCompressedPlusPrintList(props.printData);
console.log('printList.value', printList.value);
onMounted(async () => {
  printContent();
});
</script>

<style scoped>
/* 개발할때 사용하는용도
실제 프린트시에 적용되는 css 아님 */
@page {
  /* size: 8cm 13cm; */
  /*custom size*/

  /* 정의된 페이지 크기와 여백을 사용하여 페이지 설정 */
  /* size: A4;
    margin: 1cm; */

  /* bottom-left 영역에 넣을 내용 정의 */
}

@media print {
  .table-wrapper {
    width: 100%;
    page-break-inside: auto;
  }

  .main {
    /* page-break-after: always; */
    font-size: 15px;
    /* width:100%; */
  }

  .table-wrapper {
    width: 100%;
    page-break-inside: auto;
  }

  .body-section {
    width: 100%;
    text-align: left;
    /* border: 1px solid;
    border-collapse: collapse; */
  }

  .article-title-section {
    text-decoration-line: underline;
    margin-bottom: 3px;
    font-weight: 600;
  }

  .article-meta-section {
    /* padding: 0.5rem; */
    /* font-size: 14px; */
    padding-bottom: 8px;
    border-bottom: 1px solid;
    /* border-style: dotted; */
    /* border-collapse: collapse; */
  }
  .article-meta-group {
    /* padding: 0.5rem; */
    /* font-size: 14px; */
    /* border-bottom: 1px solid;
        border-collapse: collapse; */
    display: flex;
    justify-content: stretch;
  }
  .article-meta-group .article-meta {
    width: 50%;
  }

  .content-section {
    /* padding-top: 8px;
        display: flex;
        justify-content: stretch;
        white-space: pre-wrap; */
  }
  .article-editor {
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    /* display: flex; */
    /* justify-content: stretch; */
    white-space: pre-wrap;
    border-bottom: 1px dashed;
    text-align: left;
  }
  .line-none {
    border-bottom: none !important;
  }
  .content-section {
    display: grid;
    grid-template-columns: repeat(20, minmax(0, 1fr));
    /* border-bottom: 1px dashed; */
  }
  .line {
    border-bottom: 1px dashed;
  }
  .line-end {
    border-bottom: none;
  }

  .caption-section {
    /* position: absolute; */
    grid-column: span 10 / span 10;
    padding: 0 4px 0 4px;
    content: '\A';
    white-space: pre-wrap;
  }
  .line {
    /* height: 25px;
        padding: 0 4px 0 4px; */
  }

  .caption {
    content: '\A';
    white-space: pre-wrap;
    width: 100%;
    min-height: 20px;
    /* font-size: 1px; */
    /* line-height: 6px; */
    /* font-size: 16x; */
    /* line-height: 25.5px; */
  }
  .caption:after {
    /* content: '';
        position: relative;
        right: -8px;
        top: 2px;
        width: 0;
        height: 0; */
  }

  .article {
    content: '\A';
    white-space: pre-wrap;
    width: 100%;
    min-height: 20px;
  }

  .article-section {
    /* width: 50%;  */
    grid-column: span 10 / span 10;
    padding: 0 4px 0 4px;
    content: '\A';
    white-space: pre-wrap;
  }
}
</style>
