import { isEmpty } from 'lodash';

export const GETTER_NAMES = Object.freeze({
  GET_CATEGORY_CODES: 'GET_CATEGORY_CODES',
});

export const GETTER = {
  [GETTER_NAMES.GET_CATEGORY_CODES]: (state: any) => {
    if (!state.category || isEmpty(state.category)) {
      return [];
    }

    return state.category.map((v: any) => {
      return {
        id: v.cateId,
        name: v.cateNm,
        label: v.cateNm,
      };
    });
  },
};
