<template>
  <div class="border rounded-md bg-white overflow-auto">
    <GTable
      :list="articles"
      :draggable="false"
      :multiselect="false"
      :isLoading="isLoading"
      :pagination="pagination"
      :height="height"
      :selected-row-index="selectedRowIndex"
      @on-row-clicked="onRowClicked"
      @on-row-db-clicked="onRowDoubleClicked"
      @on-changed-page="onChangedPage"
    >
      <template #header>
        <th class="p-3">{{ t('BottomRollArticleList.순번') }}</th>
        <th class="p-2">{{ t('BottomRollArticleList.출고일시') }}</th>
        <th class="p-2">{{ t('BottomRollArticleList.부서') }}</th>
        <th class="p-2">{{ t('BottomRollArticleList.제목') }}</th>
        <th class="p-2">{{ t('BottomRollArticleList.지정뉴스') }}</th>
      </template>
      <template #list="{ row, index }">
        <BottomRollArticleListItem :row="row" :index="index" />
      </template>
    </GTable>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, toRefs } from 'vue';
import { GTable /*GIconButton*/ } from '@/components/ui';
import { useI18n } from 'vue-i18n';
import BottomRollArticleListItem from './BottomRollArticleListItem.vue';

const { t } = useI18n();

const props = defineProps({
  articles: {
    type: Array,
    default: () => {
      return [];
    },
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  pagination: {
    type: Object,
    default: () => {
      return null;
    },
  },
  height: {
    type: String,
    default: '',
  },
  selectedRowIndex: {
    type: Number,
  },
});

const { articles } = toRefs(props);
const emit = defineEmits([
  'on-row-clicked',
  'on-row-db-clicked',
  'on-changed-page',
]);

// 행 클릭
const onRowClicked = rowData => {
  emit('on-row-clicked', rowData);
};

// 행 더블클릭
const onRowDoubleClicked = rowData => {
  emit('on-row-db-clicked', rowData?.row);
};

// 페이지 변경
const onChangedPage = changePage => {
  emit('on-changed-page', changePage);
};
</script>
