<template>
  <div v-tooltip="setTooltip">
    <div class="rounded-sm flex items-center justify-center">
      <CheckIcon
        v-if="
          !isCuesheetMatching &&
          approveCode === articleApproveCode.articleApprov.id
        "
        size="h-4 w-4"
        class="text-primary-middle"
      />
      <DoubleCheckIcon
        v-else-if="
          !isCuesheetMatching &&
          approveCode === articleApproveCode.articleCaptionApprove.id
        "
        size="h-4 w-4"
      />
      <!-- 큐시트에 매칭만된 상태 -->
      <CircleIcon
        v-else-if="
          isCuesheetMatching &&
          approveCode === articleApproveCode.noneApprove.id
        "
        class="text-primary-middle"
      />
      <!-- 기사출고 + 큐시트 매칭 OR 기사,자막출고 + 큐시트 매칭 -->
      <ApproveAndCuesheetMatchingIcon
        v-else-if="isCuesheetMatching"
        :type="setMatchingIcon"
      />
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import {
  CheckIcon,
  DoubleCheckIcon,
  ApproveAndCuesheetMatchingIcon,
  CircleIcon,
} from '@/components/ui/icon';
import articleApproveCode from '@/codes/article-approve-code';

export default defineComponent({
  name: 'ArticleStatusUI',
  components: {
    CheckIcon,
    DoubleCheckIcon,
    ApproveAndCuesheetMatchingIcon,
    CircleIcon,
  },
  props: {
    approveCode: {
      type: String,
      default: '',
    },
    isCuesheetMatching: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { t } = useI18n();

    const setIcon = computed(() => {
      let text = '';

      switch (props.approveCode) {
        case articleApproveCode.articleCaptionApprove.id:
          text = 'double-check';
          break;

        case '큐시트매칭':
          text = 'check-circle';
          break;
        default:
          text = 'check';
      }

      return text;
    });

    const setTooltip = computed(() => {
      let tooltip = '';

      if (props.isCuesheetMatching) {
        if (props.approveCode === articleApproveCode.articleApprov.id) {
          tooltip = t('ArticleStatusUI.큐시트_매칭_기사출고');
        } else if (
          props.approveCode === articleApproveCode.articleCaptionApprove.id
        ) {
          tooltip = t('ArticleStatusUI.큐시트_매칭_기사출고_자막출고');
        } else {
          tooltip = t('ArticleStatusUI.큐시트_매칭');
        }
      } else {
        if (props.approveCode === articleApproveCode.articleApprov.id) {
          tooltip = t('ArticleStatusUI.기사출고');
        } else if (
          props.approveCode === articleApproveCode.articleCaptionApprove.id
        ) {
          tooltip = t('ArticleStatusUI.기사출고_자막출고');
        }
      }

      return tooltip;
    });

    const setMatchingIcon = computed(() => {
      if (props.isCuesheetMatching) {
        if (props.approveCode === articleApproveCode.articleApprov.id) {
          return 'articleApprovAndCuesheet';
        } else if (
          props.approveCode === articleApproveCode.articleCaptionApprove.id
        ) {
          return 'articleCaptionApproveAndCuesheet';
        }
      }

      return '';
    });

    return {
      t,
      setTooltip,
      setIcon,
      articleApproveCode,
      setMatchingIcon,
    };
  },
});
</script>
