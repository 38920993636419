import useMarsLApiClient from '@/hooks/use-mars-l-api-client';

import cueSheetApi from './cuesheet';
import appAuthApi from './appAuth';
import authApi from './auth';
import templateApi from './cuesheetTemplate';
import myArticleApi from './myArticle';
import articleApi from './coverDesk';
import userApi from './user';
import programApi from './program';
import companyApi from './company';
import workerApi from './worker';
import categoryApi from './category';
import tagApi from './tag';
import iconApi from './icon';
import fileApi from './file';
import groupApi from './group';
import contactUsApi from './contactUs';
import scrollNewsApi from './scrollNews';
import departmentApi from './department';
import myInfoApi from './myInfo';
import boardApi from './board';
import spellCheckApi from './spellCheck';
import seoulMBC from './seoulMBC';
import noticeApi from './notice';
import journalistApi from './journalist';
import coverageApi from './coverage';
import externalLinkApi from './externalLink';
import scheduleApi from './schedule';
import externalNewsApi from './externalNews';
import portalNewsApi from './portalNews';
import scrollNewsCategoryApi from './scrollNewsCategory';
import newsSourceApi from './newsSoruce';

export const useMarsLApi = () => {
  const marsLApiClient = useMarsLApiClient();

  return {
    cueSheet: cueSheetApi(marsLApiClient),
    appAuth: appAuthApi(marsLApiClient),
    auth: authApi(marsLApiClient),
    template: templateApi(marsLApiClient),
    myArticle: myArticleApi(marsLApiClient),
    coverDesk: articleApi(marsLApiClient),
    user: userApi(marsLApiClient),
    program: programApi(marsLApiClient),
    company: companyApi(marsLApiClient),
    worker: workerApi(marsLApiClient),
    category: categoryApi(marsLApiClient),
    tag: tagApi(marsLApiClient),
    icon: iconApi(marsLApiClient),
    file: fileApi(marsLApiClient),
    group: groupApi(marsLApiClient),
    contactUs: contactUsApi(marsLApiClient),
    scroll: scrollNewsApi(marsLApiClient),
    department: departmentApi(marsLApiClient),
    myInfo: myInfoApi(marsLApiClient),
    board: boardApi(marsLApiClient),
    spellCheck: spellCheckApi(marsLApiClient),
    seoulMBC: seoulMBC(marsLApiClient),
    notice: noticeApi(marsLApiClient),
    journalist: journalistApi(marsLApiClient),
    coverage: coverageApi(marsLApiClient),
    externalLink: externalLinkApi(marsLApiClient),
    schedule: scheduleApi(marsLApiClient),
    externalNews: externalNewsApi(marsLApiClient),
    portalNews: portalNewsApi(marsLApiClient),
    scrollNewsCategory: scrollNewsCategoryApi(marsLApiClient),
    newsSource: newsSourceApi(marsLApiClient),
  };
};
