<template>
  <div class="max-w-md px-2 sm:px-0" :class="width">
    <TabGroup
      :defaultIndex="defaultIndex"
      :selected-index="selectedTabNumber"
      @change="onChangeTab"
    >
      <TabList
        class="flex space-x-2 items-center border border-gray-300 rounded-full bg-white px-2 py-2 max-w-min"
      >
        <Tab
          v-for="tab in tabs"
          as="template"
          :key="tab.id"
          v-slot="{ selected }"
        >
          <button
            :class="[
              'rounded-full px-1 lg:px-2 xl:px-4 py-1 text-sm font-medium leading-5 hover:text-white hover:bg-primary focus:outline-none',
              selected
                ? 'bg-primary text-white font-D2CodingBold'
                : 'bg-gray-100',
            ]"
          >
            <div class="truncate">{{ tab.name }}</div>
          </button>
        </Tab>
      </TabList>

      <TabPanels class="pt-2">
        <slot />
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import { TabGroup, TabList, Tab, TabPanels } from '@headlessui/vue';

export default defineComponent({
  name: 'GTabPanel',
  components: {
    TabGroup,
    TabList,
    Tab,
    TabPanels,
  },
  props: {
    tabs: {
      type: Array,
      default: () => {
        return [];
      },
    },
    width: {
      type: String,
      default: 'min-w-full',
    },
    defaultIndex: {
      type: Number,
      default: 0,
    },
    selectedTabId: {
      type: Number,
      default: 0,
    },
  },
  emits: ['change'],

  setup(props, { emit }) {
    const selectedTabNumber = ref(props.defaultIndex);

    // 탭을 누르지 않고 상위 컴포넌트에서 탭을 변경하고자 할 때
    watch(
      () => props.selectedTabId,
      (newVal, oldVal) => {
        // 초기화 시켜서 다시 감지되도록 하기 위한 처리
        if (newVal === 0) {
          return;
        }
        selectedTabNumber.value = oldVal - 1;
      },
    );

    // 탭이 변경 되었을 때
    const onChangeTab = index => {
      selectedTabNumber.value = index;
      emit('change', index);
    };

    return {
      onChangeTab,
      selectedTabNumber,
    };
  },
});
</script>
