<template>
  <GSingleSelect
    v-model="model"
    name="type"
    :item-label="itemLabel ? t('ProgramTypeSelect.구분') : ''"
    :options="options"
    track-by="id"
    label="label"
    :allow-empty="false"
    :searchable="false"
    :preselect-first="allOption || preselectFirst"
    :required="required"
    :placeholder="t('ProgramTypeSelect.프로그램_구분')"
    :width="width"
    @select="onSelectValue"
  >
    <template #option="{ option }">
      <div class="flex items-center space-x-2">
        <div>{{ option.name }}</div>
      </div>
    </template>
    <template #singleLabel="{ option }">
      <div class="flex items-center space-x-2">
        <div>{{ option.name }}</div>
      </div>
    </template>
  </GSingleSelect>
</template>

<script>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { programTypeCode } from '@/codes';
import { useModelWrapper } from '@/hooks/useModelWrapper';
import { GSingleSelect } from '@/components/ui';
import { cloneDeep } from 'lodash';

export default {
  name: 'ProgramTypeSelect',
  components: {
    GSingleSelect,
  },
  props: {
    modelValue: {
      type: [Object, String, Number],
      default: () => {
        return '';
      },
    },
    allOption: {
      type: Boolean,
      default: false,
    },
    itemLabel: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: 'w-34',
    },
    preselectFirst: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'select'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const model = useModelWrapper(props, emit, 'modelValue');

    const options = computed(() => {
      const list = cloneDeep(programTypeCode.filterValues());

      if (props.allOption) {
        list.unshift({
          id: '',
          label: t('ProgramTypeSelect.구분_전체'),
          name: t('ProgramTypeSelect.구분_전체'),
        });
      }

      return list;
    });

    const onSelectValue = value => {
      emit('select', value);
    };

    return {
      t,
      model,
      options,
      onSelectValue,
    };
  },
};
</script>
