import { Module } from 'vuex';
import { RootState } from '..';

export type IconState = {
  icons: any[];
};

export const IconModule: Module<IconState, RootState> = {
  namespaced: true,
  state: () => ({
    icons: [],
  }),
  mutations: {},
  getters: {
    getIcons: state => {
      state.icons = [];
      return state.icons;
    },
  },
  actions: {},
};
