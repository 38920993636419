<template>
  <div
    class="text-xs bg-white space-y-2 rounded-md h-[calc(100vh-210px)] overflow-y-auto"
    :class="[height]"
  >
    <div v-if="isLoading">
      <PageLoader />
    </div>
    <GNoData v-else-if="historyList.length === 0" />
    <div
      v-show="!isLoading"
      v-for="(item, index) in historyList"
      :key="index"
      class="flex items-center space-x-5 border rounded-lg p-2 bg-white border-gray-300 overflow-x-auto scrollbar-hide cursor-pointer"
      @click="onShowActionDetail($event, item)"
    >
      <div
        v-tooltip="item.cueId ? '큐시트로 이동' : ''"
        class="flex-none rounded-lg text-center px-2 py-1.5 w-28 font-D2CodingBold text-white select-none"
        :class="[item.color, item.cueId ? 'cursor-pointer' : '']"
        @click="onMoveCuesheet($event, item)"
      >
        {{ item.setMessage }}
      </div>
      <div class="flex-none space-x-1">
        <span class="inline-block"
          >{{ item.inputrNm }}({{ item?.inputrUserId }})</span
        >
        <span
          v-if="
            item.artclAction === 'copy' &&
            item?.chDivCd !== item?.inputrUserChDivCd
          "
          class="inline-block"
        >
          [{{ item.inputrUserChDivCd }}]
        </span>
        <span
          v-if="item.artclAction === 'channel_transfer'"
          class="inline-block"
        >
          [{{ item.receiverChannelCode }}]
        </span>
      </div>
      <div class="flex-none">{{ displayDateTimeHm(item.inputDtm) }}</div>
      <div v-if="item?.artclAction === 'match'" class="flex-none">
        {{ item?.cuesheetInfo }}
      </div>
    </div>

    <ActionLogModal
      v-if="isShowActionLogModal"
      :action-id="selectedActionLog?.id"
      :article-id="articleId"
      @close="isShowActionLogModal = false"
    />
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, watch, onActivated } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useMarsLApi } from '@/apis/mars-l-api';
import GNoData from '@/components/ui/GNoData.vue';
import PageLoader from '@/components/common/PageLoader.vue';
import { displayDateTimeHm } from '@/utils/format.ts';
import { goShowCueSheetDetail } from '@/utils/change-router.js';
import { useUserInfo } from '@/hooks/use-user-info';
import ActionLogModal from '@/components/modal/article/ActionLogModal.vue';
import { useSweetAlert } from '@/hooks/use-sweet-alert';

export default defineComponent({
  name: 'ArticleActions',
  components: {
    GNoData,
    PageLoader,
    ActionLogModal,
  },
  props: {
    height: {
      type: String,
      default: 'h-[calc(100vh-210px)]',
    },
    articleId: {
      type: [String, Number],
      default: 0,
    },
  },
  emit: [],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const marsLApi = useMarsLApi();
    const { userId } = useUserInfo();
    const { swalConfirm } = useSweetAlert();

    const historyList = ref([]);
    const isLoading = ref(false);
    const isShowActionLogModal = ref(false);
    const selectedActionLog = ref(null);

    // 이력 타입에 따른 배경색깔 지정
    const setColor = type => {
      let color = '';
      switch (type) {
        case 'create':
          color = 'bg-blue-400';
          break;
        case 'update':
          color = 'bg-green-500';
          break;
        case 'copy':
          color = 'bg-teal-400';
          break;
        case 'forced unlock':
          color = 'bg-gray-500';
          break;
        case 'approval_article':
          color = 'bg-red-500';
          break;
        case 'approval_article_and_caption':
          color = 'bg-primary';
          break;
        case 'approval_none':
          color = 'bg-yellow-500';
          break;
        case 'match':
          color = 'bg-stone-400';
          break;
        case 'seoul_mbc_transfer':
          color = 'bg-cyan-700';
          break;
        case 'channel_transfer':
          color = 'bg-cyan-700';
          break;
      }

      return color;
    };

    const setMessage = type => {
      let message = ' ';
      switch (type) {
        case 'create':
          message = '생성';
          break;
        case 'update':
          message = '수정';
          break;
        case 'copy':
          message = '복사';
          break;
        case 'forced unlock':
          message = '잠금 해제';
          break;
        case 'approval_article':
          message = '기사 출고';
          break;
        case 'approval_article_and_caption':
          message = '기사+자막 출고';
          break;
        case 'approval_none':
          message = '출고취소';
          break;
        case 'match':
          message = '큐시트 매칭';
          break;
        case 'seoul_mbc_transfer':
          message = '서울 송고';
          break;
        case 'channel_transfer':
          message = '지역 송고';
          break;
      }

      return message;
    };

    const setCuesheetInfo = async cuesheetId => {
      const response = await marsLApi.cueSheet.basic.findOne(cuesheetId);

      try {
        const broadcastDate = response.data?.brdcDt;
        const programStartTime = response.data?.brdcStartTime;
        const programName = response.data?.brdcPgmNm;
        const cuesheetName = response.data?.cueNm;
        let text = ` [${broadcastDate} ${programStartTime}]`;

        if (cuesheetName) {
          text = `${programName} (${cuesheetName})` + text;
        } else {
          text = programName + text;
        }

        return text;
      } catch (error) {
        console.error('error', error);
      }
    };

    const getActionLog = async () => {
      if (!props.articleId) {
        historyList.value = [];
        return;
      }

      try {
        isLoading.value = true;
        const response = await marsLApi.coverDesk.log.findAll(
          parseInt(props.articleId),
        );

        if (response.success) {
          historyList.value = response.data
            .filter(
              item =>
                item.artclAction !== 'unlock' && item.artclAction !== 'lock',
            )
            .map(item => {
              return {
                ...item,
                color: setColor(item.artclAction),
                setMessage: setMessage(item.artclAction),
              };
            });

          const tempList = historyList.value.filter(item => {
            return item?.artclAction === 'match';
          });
          tempList.forEach(async element => {
            element.cuesheetInfo = await setCuesheetInfo(element.cueId);
          });
        }
      } catch (error) {
        console.error(error);
        historyList.value = [];
      } finally {
        isLoading.value = false;
      }
    };

    const onMoveCuesheet = async (event, actionLog) => {
      event.stopPropagation();

      if (actionLog.cueId) {
        try {
          const response = await marsLApi.cueSheet.basic.findOne(
            actionLog.cueId,
          );

          const mode = response.data?.lckrUserId === userId ? 'edit' : 'view';

          goShowCueSheetDetail(
            response.data?.id,
            response.data?.brdcStartTime,
            mode,
            response.data?.cueNm
              ? `${response.data?.brdcPgmNm} (${response.data?.cueNm})`
              : `${response.data?.brdcPgmNm}`,
          );
        } catch (error) {
          swalConfirm({
            html: '큐시트를 찾을 수 없습니다.',
            showCancelButton: false,
          });
        }
      }
    };

    // 클릭으로 액션로그 기사 상세보기
    const onShowActionDetail = (event, log) => {
      if (log.artclAction === 'seoul_mbc_transfer') {
        event.stopPropagation();
        return;
      }
      selectedActionLog.value = log;
      isShowActionLogModal.value = true;
    };

    watch(
      () => props.articleId,
      newVal => {
        if (!newVal) {
          return;
        }
        getActionLog();
      },
    );

    onActivated(async () => {
      const refreshStatus = store.getters['refresh/myRegionArticle'];

      if (refreshStatus?.isRefresh) {
        getActionLog();
      }
    });

    // 취재데스크 기사 수정모드일 때, 수정하고 나면 이력을 새로고침
    watch(
      () => store.getters['refresh/forceRefresh'],
      refreshStatus => {
        if (!refreshStatus?.isRefresh) {
          return;
        }

        getActionLog();
        store.dispatch('refresh/DELETE_FORCE_UPDATE_STATUS');
      },
    );

    onMounted(() => {
      getActionLog();
    });

    return {
      t,
      isLoading,
      historyList,
      isShowActionLogModal,
      selectedActionLog,
      displayDateTimeHm,
      setCuesheetInfo,
      onMoveCuesheet,
      onShowActionDetail,
    };
  },
});
</script>
