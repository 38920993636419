import { AxiosInstance } from 'axios';
import templateApi from './templateApi';
import templateItemApi from './templateItemApi';

export default ($axios: AxiosInstance) => {
  return {
    basic: templateApi($axios),
    detail: templateItemApi($axios),
  };
};
