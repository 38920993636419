export const MUTATION_NAMES = Object.freeze({
  SET_ZOOM: 'SET_ZOOM',
  SET_IS_SHOW_MENU: 'SET_IS_SHOW_MENU',
});

export const MUTATION = {
  [MUTATION_NAMES.SET_ZOOM]: (state: any, zoom: boolean) => {
    state.zoom = zoom;
  },
  [MUTATION_NAMES.SET_IS_SHOW_MENU]: (state: any, isShowMenu: boolean) => {
    state.isShowMenu = isShowMenu;
  },
};
