<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    class="bi bi-calendar-minus h-[1.063rem] w-[1.063rem]"
    viewBox="0 0 16 16"
  >
    <path d="M5.5 9.5A.5.5 0 0 1 6 9h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5z" />
    <path
      d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CalendarMinusIcon',
  props: {},
  setup() {
    return {};
  },
});
</script>
