<template>
  <div
    class=""
    :class="!isModal ? 'px-2 py-1' : ''"
    v-shortkey="{
      macSave: ['meta', 's'],
      macMacro1: ['meta', '1'],
      macMacro2: ['meta', '2'],
      macMacro3: ['meta', '3'],
      macMacro4: ['meta', '4'],
      macMacro5: ['meta', '5'],
      macMacro6: ['meta', '6'],
      macMacro7: ['meta', '7'],
      winSave: ['ctrl', 's'],
      winMacro1: ['alt', '1'],
      winMacro2: ['alt', '2'],
      winMacro3: ['alt', '3'],
      winMacro4: ['alt', '4'],
      winMacro5: ['alt', '5'],
      winMacro6: ['alt', '6'],
      winMacro7: ['alt', '7'],
    }"
    @shortkey="shotKeyEvent"
  >
    <!-- 버튼 툴바 -->
    <div
      v-if="false"
      class="flex border-b py-1 px-1.5 justify-end space-x-1.5"
      :class="!isModal ? 'pb-2' : ''"
    >
      <!-- 저장 -->
      <GSaveButton
        v-tooltip="'기사 저장'"
        icon="arrow-down-tray"
        text="저장"
        :loading="isLoading"
        @click="onSaveArticle"
      />
      <!-- 맞춤법 -->
      <GButton
        v-tooltip="'맞춤법'"
        icon="spellcheck"
        @click="isShowSpellCheckModal = true"
      />
    </div>

    <!-- 내용 -->
    <div class="h-[calc(100vh-144px)] border-gray-200 bg-gray-100 rounded-b-lg">
      <div class="flex justify-center relative">
        <div>
          <div>
            <!-- 버튼 툴바 -->
            <div
              class="flex border-b py-1 px-1.5 justify-end space-x-1.5 border-r border-gray-300"
              :class="!isModal ? 'pb-2' : ''"
            >
              <!-- 저장 -->
              <GSaveButton
                v-tooltip="'기사 저장'"
                icon="arrow-down-tray"
                text="저장"
                :loading="isLoading"
                @click="onSaveArticle"
              />
              <!-- 맞춤법 -->
              <GButton
                v-tooltip="'맞춤법'"
                icon="spellcheck"
                @click="isShowSpellCheckModal = true"
              />
            </div>
          </div>
          <div class="flex">
            <!-- 참조기사 -->
            <Reference
              :article-id="createdArticleId"
              :is-modal="isModal"
              @get-ref-data="getRefData"
            />

            <!-- 기사 작성 영역 -->
            <div class="flex border-r border-gray-300 bg-white">
              <div class="p-1.5 px-2.5 space-y-1">
                <!-- 버튼 영역 -->
                <div class="flex justify-between items-center">
                  <div class="flex items-center space-x-2">
                    <!-- 형식 -->
                    <ArticleTypeSelect
                      v-model="articleType"
                      :all-option="false"
                      width="w-48"
                    />
                    <!-- 자막완료 여부 -->
                    <GSwitch
                      v-tooltip="'자막완료 여부'"
                      v-model="isCompleteCap"
                    />
                  </div>
                  <div class="flex justify-end space-x-1.5">
                    <GIconButton
                      v-tooltip="'특수문자 입력'"
                      icon="special-key"
                      @click="onClickSpecialChar"
                    />
                    <GIconButton
                      v-tooltip="'앵커'"
                      icon="anchor"
                      @click="onInputMacro('anchor')"
                    />
                    <GIconButton
                      v-tooltip="'리포트'"
                      icon="camera"
                      @click="onInputMacro('reporter')"
                    />
                    <GIconButton
                      v-tooltip="'INT'"
                      icon="int"
                      @click="onInputMacro('int')"
                    />
                    <GIconButton
                      v-tooltip="'SYNC'"
                      icon="sync"
                      @click="onInputMacro('sync')"
                    />
                    <GIconButton
                      v-tooltip="'CG'"
                      icon="cg"
                      @click="onInputMacro('cg')"
                    />
                    <GIconButton
                      v-tooltip="'ST-UP'"
                      icon="stup"
                      @click="onInputMacro('st-up')"
                    />
                    <GIconButton
                      v-tooltip="'END'"
                      icon="end"
                      @click="onInputMacro('end')"
                    />
                    <GIconButton
                      v-if="type === articleTypeCode.CrossTalk.id"
                      v-tooltip="'추가'"
                      icon="plus-small"
                      @click="addEditor"
                    />
                    <GIconButton
                      v-if="type === articleTypeCode.CrossTalk.id"
                      v-tooltip="'삭제'"
                      icon="minus-small"
                      @click="removeEditor"
                    />
                  </div>
                </div>

                <!-- 제목 -->
                <div class="text-xs pb-1">
                  <GInput
                    v-model="title"
                    ref="titleRef"
                    id="article_title_input"
                    name="title"
                    :title="title"
                    placeholder="제목을 입력하세요."
                    text-size="text-[15px]"
                  />
                </div>

                <!-- 작성기 영역 -->
                <div class="overflow-y-auto h-[calc(100vh-237px)]">
                  <ArticleEditor
                    ref="articleEditor"
                    :type-id="type"
                    v-model:reporter-text-array="
                      articleEditorState.reporterTextArray
                    "
                    v-model:anchor-text-array="
                      articleEditorState.anchorTextArray
                    "
                    v-model:caption-array="
                      articleEditorState.reporterCaptionArray
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 메타 데이터 영역 -->
        <div
          v-show="setIsWideScreen || isShowMetaPanel"
          class="right-0 h-full rounded-br-lg"
          :class="
            setIsWideScreen
              ? 'w-[26rem] flex bg-slate-200 p-2'
              : 'w-[18rem] absolute bg-white'
          "
        >
          <div
            class="flex h-10 items-center justify-end px-2"
            :class="setIsWideScreen ? 'hidden' : ''"
          >
            <GIconButton
              v-tooltip="'닫기'"
              icon="chevron-right"
              @click="isShowMetaPanel = false"
            />
          </div>
          <GTabPanel :tabs="tabs" :selected-tab-id="selectedTabId">
            <TabPanel :unmount="false">
              <ArticleMetaData
                ref="articleMetaDataRef"
                v-model:article-type="articleType"
                v-model:article-reporter="reporter"
                v-model:article-program="program"
                v-model:article-division="division"
                v-model:article-category="category"
                v-model:article-embargo="embargo"
                v-model:article-tag="tag"
                v-model:article-extra-time="extraTime"
                v-model:article-extra-time-type="extraTimeType"
                :total-article-time="totalArticleTime"
                article-mode="create"
                :article-channel-id="companyId"
                :height="setArticleMetaDataHeight"
                @selected-type="onSelectedType"
                @error-msg="isErrorMsg"
              />
            </TabPanel>
            <TabPanel :unmount="false">
              <ArticleAttachFile
                :item-type="currentRouteName"
                :is-modal="isModal"
                warning-text="기사 저장시 파일 첨부가 완료됩니다."
                item-mode="create"
                :height="setArticleAttachFileHeight"
                @on-upload-file="getUploadFiles"
              />
            </TabPanel>
            <TabPanel :unmount="false">
              <ArticleLink
                :height="setArticleLinkHeight"
                truncate-width="w-[18rem]"
                warning-text="기사 저장시 링크 등록이 완료됩니다."
                @links="getLinkLink"
              />
            </TabPanel>
          </GTabPanel>
        </div>
        <div
          class="w-full flex justify-end bg-gray-100"
          :class="setIsWideScreen ? 'hidden' : ''"
        >
          <div class="w-12 bg-white p-2 border-l border-gray-300 h-full">
            <GIconButton
              v-tooltip="'열기'"
              icon="chevron-left"
              @click="isShowMetaPanel = true"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- 특수 문자 모달 -->
    <SpecialCharModal
      v-if="isShowSpecialCharModal"
      @selected-char="onSelectedChar"
      @close="isShowSpecialCharModal = false"
    />

    <!-- 맞춤법 검사 모달 -->
    <SpellCheckModal
      v-if="isShowSpellCheckModal"
      :editor-data="articleEditorState"
      :article-type="type"
      @close="isShowSpellCheckModal = false"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  watch,
  onMounted,
  computed,
  onUnmounted,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { RouterName } from '@/types/router';
import {
  GButton,
  GSaveButton,
  GIconButton,
  GTabPanel,
  GSwitch,
  GInput,
} from '@/components/ui';
import { TabPanel } from '@headlessui/vue';
import { ArticleMetaData, ArticleLink } from '@/components/article';
import ArticleAttachFile from '@/components/common/ArticleAttachFile.vue';
import SpecialCharModal from '@/components/modal/article/SpecialCharModal';
import ArticleEditor from '@/components/article/ArticleEditor';
import { articleTypeCode, articleApproveCode } from '@/codes';
import { useMarsLApi } from '@/apis/mars-l-api';
import {
  calculateSpeed,
  setMacro,
  changeByte,
  calculateArticleTime,
  makeDBCaptionForm,
  isChangedDataOfCreate,
  setTagNames,
} from '@/utils/article-helper';
// import { editArticle } from '@/utils/change-router';
import { useStore } from 'vuex';
import { cloneDeep } from 'lodash';
import Reference from '@/components/article/reference/Reference.vue';
import { defaultDateTime, secondToMs } from '@/utils/format';
import { useUserInfo } from '@/hooks/use-user-info';
import { useForm } from 'vee-validate';
import yup from '@/utils/yup';
import { useSweetAlert } from '@/hooks/use-sweet-alert';
import { parse } from '@/utils/videotime.js';
import SpellCheckModal from '@/components/modal/article/SpellCheckModal.vue';
import { ArticleTypeSelect } from '@/components/select';
import { useWindowSize } from '@/hooks/use-window-size';
import { insertTextBetWeen } from '@/utils/str';

export default defineComponent({
  name: RouterName.CreateArticle,
  components: {
    ArticleEditor,
    GButton,
    GSaveButton,
    GIconButton,
    GSwitch,
    GInput,
    Reference,
    GTabPanel,
    TabPanel,
    ArticleMetaData,
    ArticleAttachFile,
    ArticleLink,
    SpecialCharModal,
    SpellCheckModal,
    ArticleTypeSelect,
  },
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
    isClickedCloseButtonOfModal: {
      type: Boolean,
      default: false,
    },
  },
  // emits: ['is-change-data'],
  setup(props, { emit }) {
    const { windowWidth } = useWindowSize();
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();
    const marsLApi = useMarsLApi();
    const { userMngId, editorType, companyId } = useUserInfo();
    const { swalError } = useSweetAlert();

    const articleEditor = ref();
    const articleEditorState = ref({
      anchorTextArray: [''],
      anchorCaptionArray: [[]], // c/t형태: [[{}],[{}],[{}]...]
      reporterTextArray: [''],
      reporterCaptionArray: [[]],
    });
    const isLoading = ref(false);
    const title = ref(); // 제목
    const articleType = ref(); // 형식
    const type = ref(editorType); // 형식
    const reporter = ref(userMngId); // 기자
    const program = ref(); // 프로그램
    const division = ref(); // 그분
    const category = ref(); // 카테고리
    const embargo = ref(); // 엠바고
    const tag = ref([]); // 태그
    const extraTime = ref('00:00');
    const extraTimeType = ref('+');

    const tabs = ref([
      { id: 1, name: '메타데이터' },
      { id: 2, name: '첨부파일' },
      { id: 3, name: '링크' },
    ]);
    let selectedTabId = ref(0);
    const isCompleteCap = ref(false);
    const isShowSpecialCharModal = ref(false);
    const totalArticleTime = ref(0);
    const refTitle = ref('');
    const refContent = ref('');
    const createdArticleId = ref(0);
    const isErrorExtraTime = ref(false);
    const isShowSpellCheckModal = ref(false);
    const titleRef = ref();
    const cursorPostion = ref(0);

    const isShowMetaPanel = ref(false);

    const links = ref([]);
    let speed = null;
    const articleMetaDataRef = ref(null);
    const uploadFileObj = ref(null);

    // 첨부파일 탭에서 가져오려는 첨부파일의 api를 사용하기 위함
    const currentRouteName = computed(() => {
      let name = '';

      if (
        route?.name === RouterName.MyArticle ||
        route?.name === RouterName.CreateMyArticle
      ) {
        name = 'myArticle';
      } else if (route?.name === RouterName.CreateArticle) {
        name = 'coverDesk';
      }

      return name;
    });

    // validation 적용
    const { validate } = useForm({
      initialValues: {
        title: null,
      },
      validationSchema: yup.object().shape({
        title: yup.string().required(),
      }),
    });

    // 메타데이터 탭에서 extraTime이 제대로 입력되어있지 않은 경우 false를 넘겨받는다.
    const isErrorMsg = isCorrectExtraTime => {
      isErrorExtraTime.value = isCorrectExtraTime;
    };

    // 선택한 특수문자 가져오기
    const onSelectedChar = char => {
      if (specialCharTarget === 'editor') {
        articleEditor.value.insertTextAtCursor(char);
      } else if (specialCharTarget === 'title') {
        if (title.value === undefined) {
          title.value = char;
        } else {
          title.value = insertTextBetWeen(
            title.value,
            char,
            cursorPostion.value,
          );
          cursorPostion.value += char.length;
        }
      }
    };

    // 선택한 매크로 글자 넣기
    const onInputMacro = type => {
      articleEditor.value.insertTextAtCursor(setMacro(type));
    };

    // 작성한 링크 목록 가져오기
    const getLinkLink = linkList => {
      links.value = linkList;
    };

    // 기사 저장
    const onSaveArticle = async () => {
      try {
        // validation 체크(제목, 기자, 엑스트라 타임 유효성 검사)
        const valid = await articleValidate();

        if (!valid) {
          window.Notify.error({ message: '필수 입력 항목을 확인해주세요.' });
          return;
        }

        // 기사작성기 자막 형태를 DB형태로 변경
        // eslint-disable-next-line no-unused-vars
        const { articleAnchorCaps, articleCaps, capList } = makeDBCaptionForm(
          articleType.value,
          articleEditorState.value,
        );

        let saveResponse = null;
        let tagIdList = [];
        isLoading.value = true;

        const createLinkList = links.value.map(item => {
          return {
            titl: item.linkTitle,
            link: item.link,
          };
        });

        // 서버에 파일 업로드

        const uploadFiles = await onUploadFile();

        if (tag.value.length) {
          const params = {
            tagNameList: setTagNames(tag.value),
          };
          const response = await marsLApi.tag.basic.create(params);
          if (response.success) {
            tagIdList = response.data.tagIdList;
          }
        }

        if (currentRouteName.value === 'myArticle') {
          const params = {
            title: title.value,
            // 리포트멘트 내용
            reporterMentContent: `${JSON.stringify(
              articleEditorState.value.reporterTextArray,
            )}`,
            // 리포티멘트 시간
            reporterMentContentTime: calculateSpeed(
              speed,
              changeByte(articleEditorState.value.reporterTextArray),
            ),
            // 앵커멘트 내용
            anchorMentContent: `${JSON.stringify(
              articleEditorState.value.anchorTextArray,
            )}`,
            // 앵커멘트 시간
            anchorMentContentTime: calculateSpeed(
              speed,
              changeByte(articleEditorState.value.anchorTextArray),
            ),
            articleTypeCode: articleType.value?.id, // 기사 형식
            articleDivisionCode: division.value?.id, // 기사 구분 코드
            articleDivisionDateTime: defaultDateTime(embargo.value), // 엠바고or예정 시간
            articleCategoryId: category.value?.id,
            isCapFinish: isCompleteCap.value, // 자막 완료 여부
            programId: program.value?.id, // 방송 프로그램 ID
            reporterId: reporter.value?.id, // 기자 ID
            articleExtraTime:
              extraTimeType.value === '+'
                ? parse(extraTime.value)
                : -parse(extraTime.value), // 엑스트라 타임
            tagIdList,
            capList, // 기사 자막 정보
            linkList: links.value.map(item => {
              return {
                titl: item.linkTitle,
                link: item.link,
              };
            }), // 최초 링크 생성시,
            attachmentFileIdList: uploadFiles.map(item => item.fileId), // 최초 첨부파일 등록시
          };
          saveResponse = await marsLApi.myArticle.basic.create(params);
        } else if (currentRouteName.value === 'coverDesk') {
          const params = {
            artclTypCd: articleType.value?.id, // 형식
            artclTitl: title.value,
            rptrId: reporter.value?.id,
            brdcPgmId: program.value?.id,
            artclDivCd: division.value?.id, // 일반or엠바고or예정
            artclDivDtm: defaultDateTime(embargo.value), // 엠바고or예정 시간
            artclCateId: category.value?.id,
            // artclClass: '', // 기사 종류 코드
            // 앵커 내용
            ancMentCtt: `${JSON.stringify(
              articleEditorState.value.anchorTextArray,
            )}`,
            // 앵커 시간
            ancMentCttTime: calculateSpeed(
              speed,
              changeByte(articleEditorState.value.anchorTextArray),
            ),
            // 리포트 내용
            artclCtt: `${JSON.stringify(
              articleEditorState.value.reporterTextArray,
            )}`,
            // 리포트 시간
            artclCttTime: calculateSpeed(
              speed,
              changeByte(articleEditorState.value.reporterTextArray),
            ),
            artclExtTime:
              extraTimeType.value === '+'
                ? parse(extraTime.value)
                : -parse(extraTime.value),
            attcFileYn: 'N',
            tagIdList,
            capFnshYn: isCompleteCap.value ? 'Y' : 'N',
            articleCaps, // 리포트 자막
            articleLinkCreateDtoList: createLinkList, // 최초 링크 생성시
            articleAttachmentFileCreateDtoList: uploadFiles, // 최초 첨부파일 등록시
          };
          saveResponse = await marsLApi.coverDesk.basic.create(params);
        }

        if (saveResponse.success) {
          window.Notify.success({
            message: '[기사 저장] 완료되었습니다.',
          });

          // isLoading.value = false;

          // 작성했던 참조내용이 있다면 store에 저장
          if (refTitle.value || refContent.value) {
            createdArticleId.value = saveResponse.data.id;
            const trimTitle = refTitle.value ? refTitle.value.trim() : '';
            const trimContent = refContent.value ? refContent.value.trim() : '';

            if (trimTitle || trimContent) {
              // store에 저장
              const refData = {
                articleType: props.isModal ? 'myArticle' : 'myRegion',
                articleId: saveResponse.data.id,
                refTitle: trimTitle,
                refContent: trimContent,
              };
              store.dispatch('reference/ADD_REFERENCE', refData);
            }
          }

          // 기사 생성 모달에서 기사를 저장하면 수정모드로 변경되도록
          if (props.isModal) {
            // aritlceModal에서 닫기 버튼으로 저장을 하는 경우
            if (props.isClickedCloseButtonOfModal) {
              emit('save-success');
            }
            // 저장 버튼, 단축기로 저장하는 경우
            else {
              emit('save-success', saveResponse.data.id);
            }
            // isLoading.value = false;
            return;
          }

          if (currentRouteName.value === 'coverDesk') {
            store.dispatch('refresh/REFRESH_MY_REGION_ARTICLE_STATUS', {
              reason: 'save',
            });
          }

          // 현재 활성화된 기사 생성 탭을 담아놓는다.
          const activeTab = cloneDeep(store.getters['tab/activeTab']);

          try {
            // 저장 버튼으로 기사를 저장한 경우만 기사 수정으로 이동(탭 닫기로 기사를 저장한 경우 탭이 닫힌다.)
            if (!activeTab.clickedTabClose) {
              // 기사 잠금
              const lockResponse = await marsLApi.coverDesk.basic.lock(
                parseInt(saveResponse.data.id),
              );

              if (lockResponse.success) {
                // 기사 생성 탭을 수정 탭으로 변경
                store.dispatch('tab/CHANGE_TAB_MODE', {
                  to: 'create',
                  mode: 'edit',
                  currentTab: activeTab,
                  title: title.value,
                  articleId: saveResponse.data.id,
                });
              }
            }
          } catch (error) {
            swalError({
              showCancelButton: false,
              title: '<div>기사를 잠금할 수 없습니다.</div>',
              html: `<div>${error?.response?.data?.message}</div>`,
            });
          }
        }
      } catch (error) {
        console.log('error', error);
        swalError({
          showCancelButton: false,
          title: '<div>기사를 저장할 수 없습니다.</div>',
          html: `<div>${error?.response?.data?.message}</div>`,
        });
      } finally {
        isLoading.value = false;
      }
    };

    // C/T 선택 여부에 따른 +/-버튼 토글
    const onSelectedType = value => {
      type.value = value;
    };

    // 작성한 참조기사 가져오기
    const getRefData = refData => {
      refTitle.value = refData?.refTitle;
      refContent.value = refData?.refContent;
    };

    const shotKeyEvent = event => {
      switch (event.srcKey) {
        case 'macSave':
        case 'winSave': {
          onSaveArticle();
          break;
        }
        case 'macMacro1':
        case 'winMacro1': {
          onInputMacro('anchor');
          break;
        }
        case 'macMacro2':
        case 'winMacro2': {
          onInputMacro('reporter');
          break;
        }
        case 'macMacro3':
        case 'winMacro3': {
          onInputMacro('int');
          break;
        }
        case 'macMacro4':
        case 'winMacro4': {
          onInputMacro('sync');
          break;
        }
        case 'macMacro5':
        case 'winMacro5': {
          onInputMacro('cg');
          break;
        }
        case 'macMacro6':
        case 'winMacro6': {
          onInputMacro('st-up');
          break;
        }
        case 'macMacro7':
        case 'winMacro7': {
          onInputMacro('end');
          break;
        }
      }
    };

    const addEditor = () => {
      articleEditor.value.addEditor();
    };

    const removeEditor = () => {
      articleEditor.value.removeEditor();
    };

    // 제목, 에디터 내용 변경 감지 및 기사 길이 계산
    watch(
      () => [title.value, articleEditorState.value, extraTime.value],
      ([newTitle, newEditorState]) => {
        // 기사 길이 계산 (메타데이터 탭에 표시)
        totalArticleTime.value = secondToMs(
          calculateArticleTime(
            newEditorState.anchorTextArray,
            newEditorState.reporterTextArray,
            speed,
          ),
        );

        // 모달에서 기사를 작성하는게 아닌 경우, 탭 닫기 버튼을 누르면 변경사항 확인
        if (!props.isModal) {
          const activeTab = store.getters['tab/activeTab'];

          const isChanged = isChangedDataOfCreate({
            title: newTitle,
            anchorCaptionArray: newEditorState.anchorCaptionArray,
            reporterCaptionArray: newEditorState.reporterCaptionArray,
            anchorTextArray: newEditorState.anchorTextArray,
            reporterTextArray: newEditorState.reporterTextArray,
            type: type.value,
          });
          if (isChanged) {
            // 변경된 내용이 있다면 탭의 canClose 값 변경
            activeTab.canClose = false;
          } else {
            activeTab.canClose = true;
          }
        }
      },
      {
        deep: true,
      },
    );

    // 'title', 'editor'
    let activeInputElement = null;
    // 'title', 'editor'
    let specialCharTarget = null;

    const isArticleEditorElement = el => {
      return (
        (el.tagName === 'DIV' && el['spellcheck'] !== undefined) ||
        (el.tagName === 'TEXTAREA' && el.classList.contains('article-caption'))
      );
    };

    const isArticleTitleInputElement = el => {
      return el.tagName === 'INPUT' && el['id'] !== 'article_title_input';
    };

    const onClickSpecialChar = () => {
      specialCharTarget = null;
      if (activeInputElement) {
        specialCharTarget = activeInputElement;
        // 특수문자입력버튼 클릭시 타이틀 커서 위치 저장
        if (specialCharTarget === 'title') {
          cursorPostion.value = titleRef.value.onselectionStart();
        }
      }
      isShowSpecialCharModal.value = true;
    };

    const onDocumentMouseDown = () => {
      // 현재 포커스를 가지고 있는 요소
      const activeEl = document.activeElement;
      if (isArticleEditorElement(activeEl)) {
        activeInputElement = 'editor';
      } else if (isArticleTitleInputElement(activeEl)) {
        activeInputElement = 'title';
      } else {
        activeInputElement = null;
      }
    };

    // 서버에 파일 업로드
    const onUploadFile = async () => {
      try {
        if (!uploadFileObj.value) {
          return [];
        }
        const { list, fileDivCode } = uploadFileObj.value;
        const successUploadFileIds = [];

        for await (let file of list) {
          const params = {
            fileName: file.name,
            fileDivisionCode: fileDivCode,
            file,
          };

          const uploadResponse = await marsLApi.file.basic.upload(params);
          successUploadFileIds.push({ fileId: uploadResponse.data.id });
        }

        return successUploadFileIds;
      } catch (error) {
        window.Notify.error({ message: '파일 업로드에 실패하였습니다.' });
      }
    };

    // 서버에 업로드 할 파일 가져오기
    const getUploadFiles = async fileData => {
      uploadFileObj.value = fileData;
    };

    /**
     * 기사 저장시 유효성 검사
     */
    const articleValidate = async () => {
      let dataValidate = false;
      // 제목 필수값 체크
      dataValidate =
        (await validate()).valid &&
        isErrorExtraTime.value &&
        (reporter.value ? true : false);

      return dataValidate;
    };

    /**
     * 윈도우 창이 1280px이상이면 true
     */
    const setIsWideScreen = computed(() => {
      return windowWidth.value >= 1280;
    });

    /**
     * ArticleMetaData 컴포넌트 height
     */
    const setArticleMetaDataHeight = computed(() => {
      let height = '';
      if (windowWidth.value > 1280) {
        height = props.isModal
          ? 'h-[calc(100vh-171px)]'
          : 'h-[calc(100vh-165px)]';
      } else {
        height = '';
      }
      return height;
    });

    /**
     * ArticleAttachFile 컴포넌트 height
     */
    const setArticleAttachFileHeight = computed(() => {
      let height = '';
      if (windowWidth.value > 1280) {
        height = props.isModal
          ? 'h-[calc(100vh-171px)]'
          : 'h-[calc(100vh-165px)]';
      } else {
        height = '';
      }
      return height;
    });

    /**
     * ArticleLink 컴포넌트 height
     */
    const setArticleLinkHeight = computed(() => {
      let height = '';
      if (windowWidth.value > 1280) {
        height = props.isModal
          ? 'h-[calc(100vh-223px)]'
          : 'h-[calc(100vh-220px)]';
      } else {
        height = 'h-[calc(100vh-257px)]';
      }
      return height;
    });

    onMounted(async () => {
      const activeTab = store.getters['tab/activeTab'];
      articleType.value = editorType;
      reporter.value = userMngId;

      await store.dispatch('systemInfo/FIND_MY_SYSTEM_INFO');
      speed = store.getters['systemInfo/readSpeed'];

      window.EventBus.off(
        `create-article-from-close-tab-${activeTab.sequence}`,
      );
      // 탭에서 닫기 버튼으로 저장한 경우
      window.EventBus.on(
        `create-article-from-close-tab-${activeTab.sequence}`,
        () => {
          onSaveArticle();
        },
      );

      document.addEventListener('mousedown', onDocumentMouseDown);
    });

    onUnmounted(() => {
      document.removeEventListener('mousedown', onDocumentMouseDown);
    });

    return {
      t,
      isLoading,
      route,
      articleTypeCode,
      articleApproveCode,
      articleEditorState,
      articleEditor,
      tabs,
      selectedTabId,
      isShowSpecialCharModal,
      onClickSpecialChar,
      type,
      isCompleteCap,
      totalArticleTime,
      onSelectedType,
      onSelectedChar,
      title,
      articleType,
      reporter,
      program,
      division,
      category,
      embargo,
      tag,
      extraTime,
      refTitle,
      refContent,
      onSaveArticle,
      onInputMacro,
      getRefData,
      createdArticleId,
      isErrorMsg,
      isShowSpellCheckModal,
      getLinkLink,
      currentRouteName,
      shotKeyEvent,
      addEditor,
      removeEditor,
      isShowMetaPanel,
      articleMetaDataRef,
      companyId,
      extraTimeType,
      getUploadFiles,
      windowWidth,
      setIsWideScreen,
      setArticleMetaDataHeight,
      setArticleAttachFileHeight,
      setArticleLinkHeight,
      titleRef,
    };
  },
});
</script>
