<template>
  <div class="border rounded-md bg-white overflow-auto">
    <GTable
      :list="articles"
      :draggable="false"
      :multiselect="false"
      :isLoading="isLoading"
      :pagination="pagination"
      :height="height"
      :selected-row-index="selectedRowIndex"
      @on-row-clicked="onRowClicked"
      @on-row-db-clicked="onRowDoubleClicked"
      @on-changed-page="onChangedPage"
    >
      <template #header>
        <th class="p-3">{{ t('MyArticleList.순번') }}</th>
        <th class="p-2">{{ t('MyArticleList.작성일시') }}</th>
        <th class="p-2">{{ t('MyArticleList.형식') }}</th>
        <th class="p-2">{{ t('MyArticleList.자막') }}</th>
        <th class="p-2">{{ t('MyArticleList.제목') }}</th>
        <th class="p-2">{{ t('MyArticleList.송고일시') }}</th>
        <th class="p-2">{{ t('MyArticleList.길이') }}</th>
        <th class="p-2">{{ t('MyArticleList.첨부파일') }}</th>
        <th class="p-2">{{ t('MyArticleList.엠바고') }}</th>
        <th class="p-2">{{ t('MyArticleList.뉴스_프로그램') }}</th>
        <!-- <th class="p-2">Actions</th> -->
      </template>
      <template #list="{ row, index }">
        <MyArticleListItem :row="row" :index="index" />
      </template>
    </GTable>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { GTable /*, GIconButton*/ } from '@/components/ui';
import { useI18n } from 'vue-i18n';
import MyArticleListItem from './MyArticleListItem.vue';

export default defineComponent({
  name: 'MyArticleList',
  components: {
    GTable,
    // ArticleTypeUI,
    // longArrowDownRightIcon,
    // GIconButton,
    MyArticleListItem,
  },
  props: {
    articles: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Object,
      default: () => {
        return null;
      },
    },
    height: {
      type: String,
      default: '',
    },
    selectedRowIndex: {
      type: Number,
    },
  },
  emits: ['on-row-clicked', 'on-row-db-clicked', 'on-changed-page'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const onRowClicked = rowData => {
      emit('on-row-clicked', rowData);
    };

    // 행 더블클릭
    const onRowDoubleClicked = rowData => {
      emit('on-row-db-clicked', rowData);
    };

    // 페이지 변경
    const onChangedPage = changePage => {
      emit('on-changed-page', changePage);
    };

    return {
      t,
      onRowClicked,
      onRowDoubleClicked,
      onChangedPage,
    };
  },
});
</script>
