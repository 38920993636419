<template>
  <!-- 순번 -->
  <td
    class="px-2 py-1.5"
    :title="`ARTICLE ID: ${cuesheetItem.artclId} \nORIGIN ID: ${cuesheetItem.orgArtclId} \nCUESHEET ITEM ID: ${cuesheetItem.id}`"
    :class="setRowStyle(cuesheetItem)"
  >
    {{ index + 1 }}
  </td>

  <!-- 앵커멘트 -->
  <td class="px-2 py-1.5" :class="setRowStyle(cuesheetItem)">
    <AnchorChip v-if="cuesheetItem.ancMentYn" :color="setAnchorColor" />
  </td>
  <td
    class="px-2 py-1.5"
    :class="setRowStyle(cuesheetItem)"
    :disabled="isConfirmed || mode === 'view'"
  >
    <GTaskChip
      v-if="cuesheetItem.brdcStNm"
      :background-color="setCuesheetStateColor"
      :text="setCuesheetState"
    />
  </td>

  <!-- 형식 -->
  <!-- TODO :: 형식 아이콘 정의되면 수정해야함 -->
  <td class="px-2 py-1.5" :class="setRowStyle(cuesheetItem)">
    <GIconContextMenu
      :icon-list="icons"
      :grid-size="5"
      :row="cuesheetItem"
      :symbol="cuesheetItem.videoSymbol"
      :disabled="true"
    />
  </td>

  <!-- 길이 -->
  <td class="px-2 py-1.5" :class="setRowStyle(cuesheetItem)">
    <span v-if="cuesheetItem?.cueItemDivCd !== cuesheetItemCode.ITEM.id">
      {{ articleTime }}
    </span>
  </td>

  <!-- 합계 -->
  <td class="px-2 py-1.5" :class="setRowStyle(cuesheetItem)">
    <span v-if="cuesheetItem?.cueItemDivCd !== cuesheetItemCode.ITEM.id">
      {{ accumulateTime }}
    </span>
  </td>

  <td>
    <ArticleTypeUI
      v-if="cuesheetItem.artclTypCd"
      :item="setArticleType(cuesheetItem.artclTypCd)"
    />
  </td>

  <!-- 제목 -->
  <td class="px-2 py-1.5" :class="setRowStyle(cuesheetItem)">
    <div v-if="cuesheetItem.cueItemDivCd === cuesheetItemCode.ITEM.id">
      <input
        v-model="cuesheetItem.cueItemTitl"
        class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-xs border-gray-200 rounded-md px-1 py-1.5 bg-white"
        :rows="2"
        :disabled="true"
      />
    </div>
    <div v-else class="w-96 truncate" :title="cuesheetItem.cueItemTitl">
      {{ cuesheetItem.cueItemTitl }}
    </div>
  </td>

  <!-- 자막여부 -->
  <td class="px-2 py-1.5" :class="setRowStyle(cuesheetItem)">
    <CheckCircleIcon
      v-if="cuesheetItem?.capFnshYn"
      class="w-5 h-5"
      :class="cuesheetItem?.capFnshYn ? `text-green-500` : 'text-yellow-500'"
    />
  </td>

  <!-- 비디오 아이콘 -->
  <td
    class="px-2 py-1.5"
    :class="setRowStyle(cuesheetItem)"
    :disabled="isConfirmed || mode === 'view'"
  >
    <GIconContextMenu
      :icon-list="icons"
      :grid-size="5"
      :row="cuesheetItem"
      :symbol="cuesheetItem.videoSymbol"
      :disabled="true"
    />
  </td>

  <!-- 오디오 아이콘 -->
  <td
    class="px-2 py-1.5"
    :class="setRowStyle(cuesheetItem)"
    :disabled="isConfirmed || mode === 'view'"
  >
    <GIconContextMenu
      :icon-list="icons"
      :grid-size="5"
      :row="cuesheetItem"
      :symbol="cuesheetItem.audioSymbol"
      :disabled="true"
    />
  </td>

  <!-- 담당(작성자) -->
  <td class="px-2 py-1.5" :class="setRowStyle(cuesheetItem)">
    <div class="flex flex-col text-left">
      <div class="truncate" :title="cuesheetItem?.chrgrNm">
        {{ cuesheetItem?.chrgrNm }}
      </div>
    </div>
  </td>

  <!-- 참조사항 -->
  <td class="px-2 py-1.5 w-24" :class="setRowStyle(cuesheetItem)">
    <textarea
      v-model="cuesheetItem.rmk"
      class="border shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-xs border-gray-200 bg-white rounded-md p-[0.1rem]"
      :rows="1"
      :disabled="true"
    />
  </td>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';
// import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
// import { CheckIcon } from '@/components/ui/icon/';
import { CheckCircleIcon } from '@heroicons/vue/24/outline';
import { AnchorChip } from '@/components/article/commonUI';
import { GIconContextMenu, GTaskChip } from '@/components/ui';
// import CuesheetIconMenu from '@/components/cueSheet/CuesheetIconMenu.vue';
import { useModelWrapper } from '@/hooks/useModelWrapper';
import { secondToMs } from '@/utils/format';
import {
  // cuesheetStateCode,
  cuesheetItemCode,
  articleApproveCode,
  articleTypeCode,
} from '@/codes';
import { ArticleTypeUI } from '@/components/article/commonUI';

export default defineComponent({
  name: 'SeoulMBCCuesheetDetailListItem',
  components: {
    // CheckIcon,
    AnchorChip,
    // CuesheetIconMenu,
    GIconContextMenu,
    CheckCircleIcon,
    GTaskChip,
    ArticleTypeUI,
  },

  props: {
    cuesheetDetail: {
      type: Object,
      default: () => {
        return {};
      },
    },
    icons: {
      type: Array,
      default: () => {
        return [];
      },
    },
    index: {
      type: Number,
      default: null,
    },
    isConfirmed: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: '',
    },
    selectedRow: {
      type: Object,
      default: () => {
        return null;
      },
    },
    formIcons: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const audioIcon = ref([]);
    const cuesheetItem = useModelWrapper(props, emit, 'cuesheetDetail'); // 큐시트 아이템

    /**
     *
     * 앵커멘트 상태 색상
     */
    const setAnchorColor = computed(() => {
      let color = '';
      // ancMentAssignYn는 앵커멘트 확정 여부
      if (props.cuesheetDetail.ancMentYn === 'N') {
        color = 'bg-yellow-400';
      } else if (props.cuesheetDetail.ancMentYn === 'Y') {
        color = 'bg-green-400';
      }
      return color;
    });

    /**
     * 기사 내용 시간
     */
    const articleTime = computed(() => {
      let sec = 0;
      sec = cuesheetItem.value.cueItemTime || 0;
      return secondToMs(sec);
    });

    /**
     * 기사 내용 시간 합계
     */
    const accumulateTime = computed(() => {
      let sec = 0;
      sec = cuesheetItem.value.acc || 0;
      return secondToMs(sec);
    });

    const setRowStyle = () => {
      let cls = 'font-D2CodingBold';
      // if (row.cueItemDivCd !== cuesheetItemCode.ARTICLE.id) {
      //   cls += ' text-[#ff00ff]';
      // }
      return cls;
    };

    /**
     * 큐시트 상태 표시
     */
    const setCuesheetState = computed(() => {
      // let status = '';

      // switch (cuesheetItem.value?.brdcStNm) {
      //   case cuesheetStateCode.NoneConfirmed.id:
      //     status = '';
      //     break;
      //   case cuesheetStateCode.Confirmed.id:
      //     status = '';
      //     break;
      //   case cuesheetStateCode.OnAir.id:
      //     // status = '방송중';
      //     status = '';
      //     break;
      //   case cuesheetStateCode.OffAir.id:
      //     status = '방송완료';
      //     break;
      // }
      return cuesheetItem.value?.brdcStNm;
    });

    const setCuesheetStateColor = computed(() => {
      let color = '';

      switch (cuesheetItem.value?.brdcStCd) {
        case `01`:
          color = 'bg-primary';
          break;
        case '02':
          color = 'bg-blue-400';
          break;
        case '03':
          color = 'bg-green-400';
          break;
        case '04':
          color = 'bg-gray-400';
          break;
      }
      return color;
    });

    // const setBackgroundColor = computed(() => {
    //   let color = '';

    //   switch (cuesheetItem.value?.brdcStCd) {
    //     case cuesheetStateCode.NoneConfirmed.id:
    //       color = '';
    //       // color = 'bg-primary';
    //       break;
    //     case cuesheetStateCode.Confirmed.id:
    //       // color = 'bg-blue-400';
    //       color = '';
    //       break;
    //     case cuesheetStateCode.OnAir.id:
    //       // color = 'bg-green-400';
    //       color = '';
    //       break;
    //     case cuesheetStateCode.OffAir.id:
    //       color = 'bg-gray-400';
    //       break;
    //   }
    //   return color;
    // });

    // 형식
    const setArticleType = articleTypeCd => {
      let typeCd = null;

      switch (articleTypeCd) {
        case articleTypeCode.Straight.seoulId:
          typeCd = articleTypeCode.Straight.id;
          break;
        case articleTypeCode.Report.seoulId:
          typeCd = articleTypeCode.Report.id;
          break;
        case articleTypeCode.CrossTalk.seoulId:
          typeCd = articleTypeCode.CrossTalk.id;
          break;
        case articleTypeCode.BottomRole.seoulId:
          typeCd = articleTypeCode.BottomRole.id;
          break;
        default:
          typeCd = articleTypeCode.Straight.id;
      }

      return typeCd;
    };

    return {
      t,
      setAnchorColor,
      audioIcon,
      cuesheetItemCode,
      setRowStyle,
      cuesheetItem,
      articleTime,
      accumulateTime,
      setCuesheetState,
      setCuesheetStateColor,
      articleApproveCode,
      setArticleType,
    };
  },
});
</script>
