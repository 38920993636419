<template>
  <div class="border rounded-md bg-white overflow-auto">
    <GTable
      :list="articles"
      :draggable="false"
      :multiselect="false"
      :isLoading="isLoading"
      :pagination="pagination"
      :height="height"
      :selected-row-index="selectedRowIndex"
      @on-row-clicked="onRowClicked"
      @on-row-db-clicked="onRowDoubleClicked"
      @on-changed-page="onChangedPage"
    >
      <template #header>
        <th class="p-3">{{ t('SeoulMBCArticleList.순번') }}</th>
        <th class="p-3"></th>
        <th class="p-2">{{ t('SeoulMBCArticleList.뉴스_프로그램') }}</th>
        <th class="p-2">{{ t('SeoulMBCArticleList.형식') }}</th>
        <th class="p-2">{{ t('SeoulMBCArticleList.제목') }}</th>
        <th class="p-2">{{ t('SeoulMBCArticleList.출고일시') }}</th>
        <th class="p-2">{{ t('SeoulMBCArticleList.부서') }}</th>
        <th class="p-2">{{ t('SeoulMBCArticleList.기자') }}</th>
        <th class="p-2">{{ t('SeoulMBCArticleList.길이') }}</th>
        <th class="p-2">{{ t('SeoulMBCArticleList.엠바고') }}</th>
      </template>
      <template #list="{ row }">
        <td class="px-3" :class="setStyle(row)">{{ row?.order }}</td>
        <td>
          <ArticleStatusUI
            :approve-code="setApproveCode(row)"
            :is-cuesheet-matching="row?.cueLinkYn === 'Y'"
          />
        </td>
        <td class="p-2" :class="setStyle(row)">{{ row.brdcPgmNm }}</td>
        <td class="p-2">
          <ArticleTypeUI :item="setArticleType(row.artclTypCd)" />
        </td>
        <td class="p-2" :class="setStyle(row)">
          {{ setTitle(row.artclTitl) }}
        </td>
        <td class="p-2" :class="setStyle(row)">
          {{ setReleaseDate(row.artclRelseDtm) }}
        </td>
        <td class="p-2" :class="setStyle(row)">{{ row.deptNm }}</td>
        <td class="p-2" :class="setStyle(row)">{{ row.rptrNm }}</td>
        <td class="p-2" :class="setStyle(row)">
          {{ setTotalArticleTime(row) }}
        </td>
        <td class="p-2" :class="setStyle(row)">{{ setEmbargoTime(row) }}</td>
      </template>
    </GTable>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import { GTable /*GIconButton*/ } from '@/components/ui';
import { ArticleStatusUI, ArticleTypeUI } from '@/components/article/commonUI';
import { useI18n } from 'vue-i18n';
import {
  articleTypeCode,
  articleApproveCode,
  articleDivisionCode,
} from '@/codes';
import {
  secondToMs,
  displayDateTimeHm,
  defaultDateTimeHm,
  defaultDate,
} from '@/utils/format';
import dayjs from 'dayjs';

defineProps({
  articles: {
    type: Array,
    default: () => {
      return [];
    },
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  pagination: {
    type: Object,
    default: () => {
      return null;
    },
  },
  height: {
    type: String,
    default: '',
  },
  selectedRowIndex: {
    type: Number,
  },
});

const emit = defineEmits([
  'on-row-clicked',
  'on-row-db-clicked',
  'on-changed-page',
]);

const { t } = useI18n();

// 행 클릭
const onRowClicked = rowData => {
  emit('on-row-clicked', rowData);
};

// 행 더블클릭
const onRowDoubleClicked = rowData => {
  emit('on-row-db-clicked', rowData);
};

// 페이지 변경
const onChangedPage = changePage => {
  emit('on-changed-page', changePage);
};

const setStyle = row => {
  if (row.artclDivCd === articleDivisionCode.expected.seoulId) {
    return 'text-cyan-500';
  } else if (
    row.artclDivCd === articleDivisionCode.embargo.seoulId &&
    dayjs().isBefore(dayjs(row.embgDtm).format())
  ) {
    return 'text-red-500';
  }

  return '';
};

// 날짜 포멧
const setReleaseDate = dateTime => {
  return dateTime ? displayDateTimeHm(dateTime) : '';
};

// 제목 포멧
const setTitle = str => {
  let customTitle = str;
  // "기사 출고 <mark class='highlight'>테스트</mark>3" 처럼 <mark> 태그가 있는 경우 replace 처리
  if (customTitle.includes("<mark class='highlight'>")) {
    customTitle = customTitle.replace("<mark class='highlight'>", '');
  }
  if (customTitle.includes('</mark>')) {
    customTitle = customTitle.replace('</mark>', '');
  }

  return customTitle;
};

// 출고상태
const setApproveCode = row => {
  // 기사 출고, 자막 출고
  if (row?.artclStCd === '02' && row?.capStCd === '02') {
    return articleApproveCode.articleCaptionApprove.id;
  }
  // 기사 출고, 자막 출고 X
  else if (row?.artclStCd === '02' && row?.capStCd === '01') {
    return articleApproveCode.articleApprov.id;
  }
  // 기사 출고 X, 자막 출고 X
  else if (row?.artclStCd === '01' && row?.capStCd === '01') {
    return articleApproveCode.noneApprove.id;
  }
};

// 형식
const setArticleType = articleTypeCd => {
  let typeCd = null;

  switch (articleTypeCd) {
    case articleTypeCode.Straight.seoulId:
      typeCd = articleTypeCode.Straight.id;
      break;
    case articleTypeCode.Report.seoulId:
      typeCd = articleTypeCode.Report.id;
      break;
    case articleTypeCode.CrossTalk.seoulId:
      typeCd = articleTypeCode.CrossTalk.id;
      break;
    case articleTypeCode.BottomRole.seoulId:
      typeCd = articleTypeCode.BottomRole.id;
      break;
    default:
      typeCd = articleTypeCode.Straight.id;
  }

  return typeCd;
};

// 기사 시간
const setTotalArticleTime = row => {
  return secondToMs(row?.artclReqdTime);
};

// 엠바고 날짜
const setEmbargoTime = row => {
  let date = '';
  if (row.artclDivCd === articleDivisionCode.expected.seoulId) {
    date = `예) ${defaultDate(row.brdcSchdDt)}`;
  } else if (row.artclDivCd === articleDivisionCode.embargo.seoulId) {
    date = defaultDateTimeHm(row.embgDtm);
  }
  return date;
};
</script>
