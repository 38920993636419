<template>
  <GSingleSelect
    v-model="model"
    name="division"
    :item-label="itemLabel ? t('ArticleDivisionSelect.구분') : ''"
    :options="options"
    track-by="id"
    label="label"
    :allow-empty="false"
    :searchable="false"
    :preselect-first="preselectFirst"
    :placeholder="t('ArticleDivisionSelect.구분')"
    :width="width"
    @select="onSelectValue"
  />
</template>

<script>
import { useI18n } from 'vue-i18n';
import { useModelWrapper } from '@/hooks/useModelWrapper';
import { GSingleSelect } from '@/components/ui';
import { articleDivisionCode } from '@/codes';

export default {
  name: 'ArticleDivisionSelect',
  components: {
    GSingleSelect,
  },
  props: {
    allOption: {
      type: Boolean,
      default: false,
    },
    itemLabel: {
      type: Boolean,
      default: false,
    },
    preselectFirst: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '',
    },
  },
  emits: ['select', 'update:modelValue'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const model = useModelWrapper(props, emit, 'modelValue');
    let options = articleDivisionCode.values;

    if (props.allOption) {
      options.unshift({
        id: '',
        name: t('ArticleDivisionSelect.구분'),
        label: t('ArticleDivisionSelect.구분'),
      });
    }

    const onSelectValue = value => {
      emit('select', value);
    };

    return { t, model, options, onSelectValue };
  },
};
</script>

<style scoped></style>
