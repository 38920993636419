<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_206_8)">
      <path
        d="M11.8452 25.8182L12.1747 21.6136L8.67472 24.0114L7.00426 21.0795L10.7997 19.2727L7.00426 17.4659L8.67472 14.5341L12.1747 16.9318L11.8452 12.7273H15.1974L14.8679 16.9318L18.3679 14.5341L20.0384 17.4659L16.2429 19.2727L20.0384 21.0795L18.3679 24.0114L14.8679 21.6136L15.1974 25.8182H11.8452ZM33.5156 36L37.3338 12.7273H41.4247L37.6065 36H33.5156ZM23.1406 30.4545L23.8338 26.3636H42.2884L41.5952 30.4545H23.1406ZM25.3338 36L29.152 12.7273H33.2429L29.4247 36H25.3338ZM24.4702 22.3636L25.1634 18.2727H43.6179L42.9247 22.3636H24.4702Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_206_8">
        <rect width="48" height="48" rx="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SpecialKeyIcon',
  props: {},
  setup() {
    return {};
  },
});
</script>
