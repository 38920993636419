import { AxiosInstance } from 'axios';

const PREFIX = '/notice';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 공지사항 조회
     * @param params
     * @returns
     */
    findAll: async (params: any) => {
      return $axios
        .get(`${PREFIX}`, { params })
        .then(response => response.data);
    },

    /**
     * 공지사항 상세 조회
     * @param params
     * @returns
     */
    findOne: async (noticeId: number | string) => {
      return $axios
        .get(`${PREFIX}/${noticeId}`)
        .then(response => response.data);
    },

    /**
     * 공지사항 등록
     * @param params
     * @returns
     */
    create: async (params: any) => {
      return $axios.post(`${PREFIX}`, params).then(response => response.data);
    },

    /**
     * 공지사항 수정
     * @param noticeId 공지사항 ID
     * @param params
     * @returns
     */
    update: async (noticeId: number | string, params: any) => {
      return $axios
        .put(`${PREFIX}/${noticeId}`, params)
        .then(response => response.data);
    },

    /**
     * 공지사항 삭제
     * @param noticeId 공지사항 ID
     * @returns
     */
    delete: async (noticeId: number) => {
      return $axios
        .delete(`${PREFIX}/${noticeId}`)
        .then(response => response.data);
    },
  };
};
