import { createI18n } from 'vue-i18n';
import enUS from './locales/en.json';
import koKR from './locales/ko.json';

export default createI18n({
  legacy: false,
  locale: process.env.VUE_APP_I18N_LOCALE || 'ko',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ko',
  messages: {
    en: enUS,
    ko: koKR,
  },
});
