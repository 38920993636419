import { AxiosInstance } from 'axios';

const PREFIX = '/coverage';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 취재 정보 목록 조회
     * @param {String} searchValue - 검색 내용(제목, 내용, 장소)
     * @param {String} startDate - 검색 시작일 (취재일시)
     * @param {String} endDate - 검색 종료일 (취재일시)
     */
    findAll: async (params: any) => {
      return $axios
        .get(`${PREFIX}`, { params })
        .then(response => response.data);
    },

    /**
     * 취재 정보 상세 조회
     * @param {Number} coverageId - 취재 정보 ID
     */
    findOne: async (coverageId: number) => {
      return $axios
        .get(`${PREFIX}/${coverageId}`)
        .then(response => response.data);
    },

    /**
     * 취재 정보 생성
     * @param {Object} params - 생성에 필요한 값
     */
    create: async (params: any) => {
      return $axios.post(`${PREFIX}`, params).then(response => response.data);
    },

    /**
     * 취재 정보 수정
     * @param {Number} coverageId - 취재 정보 ID
     * @param {Object} params - 수정에 필요한 값
     */
    update: async (coverageId: number, params: any) => {
      return $axios
        .put(`${PREFIX}/${coverageId}`, params)
        .then(response => response.data);
    },

    /**
     * 취재 정보 삭제
     * @param {Number} coverageId - 취재 정보 ID
     */
    delete: async (coverageId: number) => {
      return $axios
        .delete(`${PREFIX}/${coverageId}`)
        .then(response => response.data);
    },
  };
};
