
import { defineComponent, PropType } from 'vue';

type ChipColor =
  | 'gray'
  | 'red'
  | 'yellow'
  | 'blue'
  | 'green'
  | 'indigo'
  | 'purple'
  | 'pink';

export default defineComponent({
  name: 'GChip',
  props: {
    text: {
      type: String,
      default: '',
    },
    color: {
      type: String as PropType<ChipColor>,
      required: true,
    },
  },
});
