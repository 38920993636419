import { AxiosInstance } from 'axios';

const PREFIX = '/my-article';

export default ($axios: AxiosInstance) => {
  return {
    /**
     * 내기사 파일 조회
     * @params {number} articleId 기사 ID
     */
    findAll: async (articleId: number) => {
      return $axios
        .get(`${PREFIX}/${articleId}/attachment-file`)
        .then(response => response.data);
    },

    /**
     * 내기사 파일 등록
     * @param {number} articleId 기사 ID
     * @param {Object} option request body
     * @param {string} option.fileId 파일 ID
     */
    create: async (articleId: number, params: any) => {
      return $axios
        .post(`${PREFIX}/${articleId}/attachment-file`, params)
        .then(response => response.data);
    },

    /**
     * 내기사 파일 삭제
     * @param {number} articleId 기사 ID
     * @param {number} id 파일 ID
     */
    delete: async (articleId: number, id: number) => {
      return $axios
        .delete(`${PREFIX}/${articleId}/attachment-file/${id}`)
        .then(response => response.data);
    },
  };
};
